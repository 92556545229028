import React from 'react';

const SmartUI = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path d="M8 1V15" stroke="#666666" strokeWidth="1.25" />
      <path
        d="M9 2.5H13.5C13.7761 2.5 14 2.72386 14 3V13C14 13.2761 13.7761 13.5 13.5 13.5H9"
        stroke="#666666"
        strokeWidth="1.25"
      />
      <path
        d="M7 13.5H2.5C2.22386 13.5 2 13.2761 2 13V3C2 2.72386 2.22386 2.5 2.5 2.5H7"
        stroke="#666666"
        strokeWidth="1.25"
      />
      <path d="M7 12.5H3L5.5 10L7 8.5V12.5Z" fill="#666666" />
    </svg>
  );
};

export default SmartUI;
