import React from 'react';

const ExpandArrow = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      role="img"
      {...rest}
    >
      <path
        d="M6.21995 3.21934C6.36058 3.07889 6.5512 3 6.74995 3C6.9487 3 7.13933 3.07889 7.27995 3.21934L11.53 7.46934C11.6704 7.60997 11.7493 7.80059 11.7493 7.99934C11.7493 8.19809 11.6704 8.38871 11.53 8.52934L7.27995 12.7793C7.13769 12.9117 6.94964 12.9839 6.75532 12.9805C6.561 12.9771 6.37555 12.8986 6.23795 12.7613C6.10071 12.6237 6.02215 12.4383 6.0188 12.244C6.01544 12.0497 6.08754 11.8616 6.21995 11.7193L9.93995 7.99934L6.21995 4.27934C6.0795 4.13871 6.00061 3.94809 6.00061 3.74934C6.00061 3.55059 6.0795 3.35997 6.21995 3.21934Z"
        fill="#666666"
      />
    </svg>
  );
};

export { ExpandArrow };
