export function getPlanAlertType(type) {
  let alertType = '';
  switch (type) {
    case 'trial_popup':
      alertType = 'normal_trial_popup';
      break;
    case 'limit_exhausted':
      alertType = 'limit_exhausted';
      break;
    case 'plan_expired':
      alertType = 'plan_expired';
      break;
    case 'limit_exhausted_10':
      alertType = 'limit_exhausted_10';
      break;
    case 'limit_exhausted_60':
      alertType = 'limit_exhausted_60';
      break;
    case 'limit_exhausted_90':
      alertType = 'limit_exhausted_90';
      break;
    case 'paid_plan_expired':
      alertType = 'paid_plan_expired';
      break;
    case 'paid_plan_expired_ninty':
      alertType = 'paid_plan_expired_ninty';
      break;
    default:
      alertType = '';
      break;
  }
  return alertType;
}
