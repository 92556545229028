import React from 'react';

const Error = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M7.99954 6.00015V8.6668M7.99954 11.3335H8.00621M7.07641 2.59463L1.59314 12.0657C1.289 12.5911 1.13693 12.8537 1.1594 13.0693C1.17901 13.2573 1.27752 13.4282 1.43043 13.5393C1.60573 13.6668 1.90924 13.6668 2.51626 13.6668H13.4827C14.0898 13.6668 14.3933 13.6668 14.5686 13.5393C14.7215 13.4282 14.82 13.2573 14.8396 13.0693C14.8621 12.8537 14.71 12.5911 14.4059 12.0657L8.92261 2.59463C8.61961 2.07119 8.46808 1.80947 8.27041 1.72157C8.09794 1.64489 7.90108 1.64489 7.72868 1.72157C7.53094 1.80947 7.37941 2.07119 7.07641 2.59463Z"
        stroke="#F45C47"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Error;
