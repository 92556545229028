import Modal from 'components/Common/Modal';
import TrialMainComponent from 'components/Trial';
import CongratulationsModal from 'components/Common/CongratulationsModal';

import { getCookieData } from 'utils/cookieHandler';
import { getGitHubUrl } from 'utils/onboarding/framework';

import { sendAmplitudeEvents } from 'utils/common';
import { EventNames } from 'utils/events';
import { isEnterPressed } from 'utils/keyboard';
import LTButton from 'new-theme/components/LTComponents/LTButton/LTButton';
import usePlan from 'hooks/usePlan';

import history from 'utils/history';

import { Path } from 'constants/routes';
import Analytics from 'components/Analytics';
const queryString = require('query-string');

function AppBannerAndModals() {
  const {
    closePopup,
    commonUiVisual,
    handleContactSaleClick,
    handleUpgradeClick,
    isCustomTrial,
    runTestExternal,
    userData,
    userPlan,
    visual,
    showAnalytics,
    handleCreateDashboard,
    fetchingDashboard,
  } = usePlan();

  return (
    <>
      <Modal
        innerDivStyle={{
          minWidth: 'unset',
          marginRight: '24px',
          maxWidth: '572px',
          width: 'unset',
        }}
        isOpen={
          visual && visual.planExpired && !visual.planExpiredFirstTimeShow
        }
        setIsOpen={() => closePopup('PLAN_EXPIRED')}
        promoBannerHeight
        ariaLabelledBy="lt-modal-title"
      >
        <TrialMainComponent
          handleContactSaleClick={handleContactSaleClick}
          handleUpgradeClick={handleUpgradeClick}
          isCustomTrial={isCustomTrial}
          type="PLAN_EXPIRED"
          userPlan={userPlan}
        />
      </Modal>
      <Modal
        ariaLabelledBy="lt-modal-title"
        innerDivStyle={{
          minWidth: 'unset',
          marginRight: '24px',
          maxWidth: '572px',
          width: 'unset',
        }}
        isOpen={
          visual &&
          visual.limitExhausted &&
          !visual.limitExhaustedModalFirstTimeShow
        }
        setIsOpen={() => closePopup('LIMIT_EXHAUSTED')}
        promoBannerHeight
      >
        <TrialMainComponent
          type="LIMIT_EXHAUSTED"
          handleUpgradeClick={handleUpgradeClick}
          handleContactSaleClick={handleContactSaleClick}
          isCustomTrial={isCustomTrial}
          userPlan={userPlan}
        />
      </Modal>
      <Modal
        ariaLabelledBy="lt-modal-title"
        innerDivStyle={{
          minWidth: 'unset',
          marginRight: '24px',
          maxWidth: '572px',
          width: 'unset',
        }}
        isOpen={
          visual &&
          visual.isLimitExhoustedThresholdNinty &&
          !visual.nintyPercentageThresholdModalFirstTimeShow &&
          !getCookieData('NINTY_PER_LIMIT_EXHAUSTED_THRESHOLD')
        }
        setIsOpen={() => closePopup('NINTY_PER_LIMIT_EXHAUSTED_THRESHOLD')}
        promoBannerHeight
      >
        <TrialMainComponent
          type="LIMIT_EXHAUSTED_THRESHOLD"
          threshold={visual && visual.thresholdValue}
          handleUpgradeClick={handleUpgradeClick}
          handleContactSaleClick={handleContactSaleClick}
          isCustomTrial={isCustomTrial}
          userPlan={userPlan}
        />
      </Modal>
      <Modal
        ariaLabelledBy="lt-modal-title"
        innerDivStyle={{
          minWidth: 'unset',
          marginRight: '24px',
          maxWidth: '572px',
          width: 'unset',
        }}
        isOpen={
          visual &&
          visual.isLimitExhoustedThresholdSixty &&
          !visual.sixtyPercentageThresholdModalFirstTimeShow &&
          !getCookieData('SIXTY_PER_LIMIT_EXHAUSTED_THRESHOLD')
        }
        setIsOpen={() => closePopup('SIXTY_PER_LIMIT_EXHAUSTED_THRESHOLD')}
        promoBannerHeight
      >
        <TrialMainComponent
          type="LIMIT_EXHAUSTED_THRESHOLD"
          threshold={visual && visual.thresholdValue}
          handleUpgradeClick={handleUpgradeClick}
          handleContactSaleClick={handleContactSaleClick}
          isCustomTrial={isCustomTrial}
          userPlan={userPlan}
        />
      </Modal>
      <Modal
        ariaLabelledBy="lt-modal-title"
        innerDivStyle={{
          minWidth: 'unset',
          marginRight: '24px',
          maxWidth: '572px',
          width: 'unset',
        }}
        isOpen={
          visual &&
          visual.isLimitExhoustedThresholdTen &&
          !visual.tenPercentageThresholdModalFirstTimeShow &&
          !getCookieData('TEN_PER_LIMIT_EXHAUSTED_THRESHOLD')
        }
        setIsOpen={() => closePopup('TEN_PER_LIMIT_EXHAUSTED_THRESHOLD')}
        promoBannerHeight
      >
        <TrialMainComponent
          type="LIMIT_EXHAUSTED_THRESHOLD"
          threshold={visual && visual.thresholdValue}
          handleUpgradeClick={handleUpgradeClick}
          handleContactSaleClick={handleContactSaleClick}
          isCustomTrial={isCustomTrial}
          userPlan={userPlan}
        />
      </Modal>
      <Modal
        ariaLabelledBy="lt-modal-title"
        isOpen={visual && visual.trialFeatureModal}
        setIsOpen={() => closePopup('TRIAL_FEATURE')}
        promoBannerHeight
        innerDivStyle={{
          minWidth: 'unset',
          marginRight: '24px',
          maxWidth: '572px',
          width: 'unset',
        }}
      >
        <TrialMainComponent
          type="TRIAL_FEATURE"
          handleUpgradeClick={handleUpgradeClick}
          handleContactSaleClick={handleContactSaleClick}
          isCustomTrial={isCustomTrial}
          userPlan={userPlan}
        />
      </Modal>
      <Modal
        ariaLabelledBy="lt-modal-title"
        innerDivStyle={{
          minWidth: 'unset',
          marginRight: '24px',
          maxWidth: '572px',
          width: 'unset',
        }}
        isOpen={
          visual &&
          visual.isPaidPlanExpired &&
          !visual.paidPlanExpiredFirstTimeShow
        }
        setIsOpen={() => closePopup('PAID_PLAN_EXPIRED')}
        promoBannerHeight
      >
        <TrialMainComponent
          type="PAID_PLAN_EXPIRED"
          handleUpgradeClick={handleUpgradeClick}
          handleContactSaleClick={handleContactSaleClick}
        />
      </Modal>
      <Modal
        ariaLabelledBy="lt-modal-title"
        isOpen={
          visual &&
          visual.isPaidUsedPlanAboutToExpire &&
          !visual.isPaidUsedPlanAboutToExpireFirstTimeShow &&
          !getCookieData('PAID_NINTY_PER_LIMIT_EXHAUSTED_THRESHOLD')
        }
        innerDivStyle={{
          minWidth: 'unset',
          marginRight: '24px',
          maxWidth: '572px',
          width: 'unset',
        }}
        setIsOpen={() => closePopup('PAID_NINTY_PER_LIMIT_EXHAUSTED_THRESHOLD')}
        promoBannerHeight
      >
        <TrialMainComponent
          type="PAID_NINTY_PER_LIMIT_EXHAUSTED_THRESHOLD"
          handleUpgradeClick={handleUpgradeClick}
          handleContactSaleClick={handleContactSaleClick}
        />
      </Modal>
      <Modal
        ariaLabelledBy="lt-modal-title"
        isOpen={commonUiVisual && commonUiVisual.continueToGitpodModal}
        setIsOpen={() => runTestExternal({ continueToGitpodModal: false })}
        type="allowPopUp"
        promoBannerHeight
        style={{
          padding: '24px',
          minWidth: 'unset',
        }}
        innerDivStyle={{
          minWidth: 'unset',
          marginRight: '24px',
        }}
      >
        <span
          style={{ textAlign: 'center' }}
          className="ltw-leading-19 ltw-font-semibold ltw-text-16 ltw-text-secondary"
        >
          Do you want to continue to Gitpod?
        </span>
        <div className="ltw-flex ltw-justify-end ltw-items-center ltw-gap-20px ltw-mb-10px ltw-mt-15px lt-btn-focus-visible">
          <LTButton
            onClick={() => runTestExternal({ continueToGitpodModal: false })}
            onKeyDown={(e) =>
              isEnterPressed(e) &&
              runTestExternal({ continueToGitpodModal: false })
            }
            variant="invisible"
            label="Cancel"
            className="cancel-gitpod-proceed"
          />
          <LTButton
            variant="primary"
            onClick={() => {
              const parsed = queryString.parse(window.location.search);
              let framework = parsed.framework;
              let frameworkType = parsed.frameworkType || '';
              if (!framework) {
                framework = commonUiVisual && commonUiVisual.frameworkName;
              }
              window.open(
                `https://gitpod.io/#LT_USERNAME=${
                  userData && userData.username
                },LT_ACCESS_KEY=${userData && userData.apiToken}/${getGitHubUrl(
                  framework,
                  frameworkType
                )}`,
                '_blank'
              );
              runTestExternal({ continueToGitpodModal: false });
              sendAmplitudeEvents(EventNames.HYP_GITPOD_GITHUB, {
                framework: framework,
              });
            }}
            label="Proceed"
          />
        </div>
      </Modal>
      <Modal
        ariaLabelledBy="lt-modal-title"
        isOpen={commonUiVisual && commonUiVisual.existingUserGoToDashboard}
        setIsOpen={() => runTestExternal({ existingUserGoToDashboard: false })}
        type="allowPopUp"
        promoBannerHeight
        style={{
          padding: '24px',
          minWidth: 'unset',
        }}
        innerDivStyle={{
          minWidth: 'unset',
          marginRight: '24px',
        }}
      >
        <span
          style={{ textAlign: 'center' }}
          className="ltw-leading-19 ltw-font-semibold ltw-text-16 ltw-text-secondary"
        >
          We detected you ran a test. Do you want to go to the Dashboard?
        </span>
        <div className="ltw-flex ltw-justify-end ltw-items-center ltw-gap-20px ltw-mb-10px ltw-mt-15px lt-btn-focus-visible">
          <LTButton
            onClick={() =>
              runTestExternal({ existingUserGoToDashboard: false })
            }
            className="cancel-gitpod-proceed"
            variant="invisible"
            label="Cancel"
          />
          <LTButton
            onClick={() => {
              history.push(Path.ROOT);
              runTestExternal({
                existingUserGoToDashboard: false,
              });
            }}
            className="cancel-gitpod-proceed"
            variant="primary"
            label="Proceed"
          />
        </div>
      </Modal>
      <Modal
        isOpen={showAnalytics}
        style={{
          maxWidth: '480px',
        }}
        innerDivStyle={{
          minWidth: 'unset',
          maxWidth: '480px',
          width: 'unset',
        }}
        setIsOpen={() => closePopup('CLOSE_ANALYTICS_POPUP')}
        promoBannerHeight
        ariaLabelledBy="lt-modal-title"
      >
        <Analytics
          fetchingDashboard={fetchingDashboard}
          handleCreateDashboard={handleCreateDashboard}
        />
      </Modal>
      {visual.enableCongratulationModal && <CongratulationsModal />}
    </>
  );
}

export default AppBannerAndModals;
