const Stopped = ({ width = 20, height = 20, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...rest}
    >
      <circle cx="10" cy="10" r="8" fill="#A40E26" />
      <path
        d="M13 12C13 12.5523 12.5523 13 12 13H8C7.44772 13 7 12.5523 7 12V8C7 7.44772 7.44772 7 8 7H12C12.5523 7 13 7.44772 13 8V12Z"
        fill="white"
      />
    </svg>
  );
};

export default Stopped;
