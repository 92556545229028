/**
 * Component Name: Private Route
 * Author: Ajo John
 * Description: Component which will handles all the components that requires authentication.
 */
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ path, component: Component, ...rest }) => {
  const isAuthGranted = true; // Please delete me after your test work is done
  const redirectToLogin = () => {
    window.location.replace('https://accounts.lambdatest.com/login');
  };

  if (path.includes('/')) {
    return <Redirect to="/hyperexecute" />;
  }
  return (
    <Route
      path={path}
      {...rest}
      render={(props) =>
        isAuthGranted ? <Component {...props} /> : redirectToLogin()
      }
      exact={true}
    />
  );
};

export default PrivateRoute;
