import React from 'react';

const Initiated = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="7.99998" cy="7.99998" r="6.66667" fill="#409FF6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8764 4.05238C11.9986 4.07857 12.1034 4.18333 12.1296 4.30555C12.147 4.3754 12.505 5.96428 11.0645 7.38729L10.078 8.3738L9.97322 10.2595C9.96449 10.3555 9.92084 10.4428 9.851 10.504L8.98671 11.2286C8.9256 11.2809 8.84703 11.3071 8.76846 11.3071C8.67243 11.3071 8.59386 11.2809 8.52402 11.2111L7.64227 10.3294L5.84386 8.53094L4.96212 7.6492C4.8399 7.52698 4.83117 7.32618 4.94466 7.19523L5.66926 6.33095C5.73037 6.26111 5.81767 6.21746 5.91371 6.20873L7.79942 6.10396L8.78592 5.10873C8.80338 5.09563 8.81866 5.08036 8.83394 5.06508C8.84921 5.0498 8.86449 5.03452 8.88195 5.02143C9.82481 4.13968 10.8288 4 11.3526 4C11.6581 4 11.8502 4.04365 11.8764 4.05238ZM11.5008 4.68966C11.527 5.05633 11.4746 5.80712 10.8985 6.56664L9.62385 5.29204C10.2699 4.80315 10.9072 4.68093 11.3524 4.68093C11.4048 4.68093 11.4572 4.68093 11.5008 4.68966ZM9.11836 5.74602L10.4453 7.06428L7.88741 9.61348L6.56916 8.29523L9.11836 5.74602ZM8.79448 10.5214L9.30956 10.0936L9.35321 9.10712L8.36671 10.0936L8.79448 10.5214ZM5.92281 9.40396C5.79186 9.27301 5.57361 9.27301 5.44266 9.40396L4.09821 10.7484C3.96726 10.8794 3.96726 11.0976 4.09821 11.2286C4.16806 11.2984 4.25536 11.3333 4.34266 11.3333C4.42996 11.3333 4.51726 11.2984 4.5871 11.2373L5.93154 9.89285C6.05377 9.75317 6.05377 9.53492 5.92281 9.40396ZM6.29835 10.2595L5.85311 10.7048C5.72216 10.8357 5.72216 11.054 5.85311 11.1849C5.92295 11.2548 6.01025 11.2809 6.09756 11.2809C6.18486 11.2809 6.27216 11.246 6.342 11.1849L6.78724 10.7397C6.91819 10.6087 6.91819 10.3905 6.78724 10.2595C6.64755 10.1286 6.4293 10.1286 6.29835 10.2595ZM7.07516 6.81985L6.08865 6.87223L5.66087 7.38731L6.08865 7.81509L7.07516 6.81985Z"
        fill="white"
      />
    </svg>
  );
};

export default Initiated;
