import { CALL_API } from '../middleware/api';
import {
  HYPERTEST_TASK_LOG_ACTIONS_REQUEST,
  HYPERTEST_TASK_LOG_ACTIONS_SILENT_REQUEST,
  HYPERTEST_TASK_LOG_ACTIONS_RESPONSE,
  HYPERTEST_TASK_LOG_RESET,
  HYPERTEST_SET_ACTIVE_STAGE_LOG,
  HYPERTEST_SET_CURRENT_RUNNING_LOGTYPE,
} from '../constants/hypertestTaskLog';

const HYPERTEST_SENTINEL_API_ENDPOINT =
  process.env.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT || (window._env_ && window._env_.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT);
const HYPERTEST_LOGISTIC_API_ENDPOINT =
  process.env.REACT_APP_HYPERTEST_LOGISTIC_API_ENDPOINT ||
  (window._env_ && window._env_.REACT_APP_HYPERTEST_LOGISTIC_API_ENDPOINT);

export const getHypertestTaskLog = ({
  auth,
  taskId,
  search_text,
  isDex,
  jobId,
}) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;

  let endpoint = `${sentinelUrl}/v1.0/stage/${taskId}?search_text=${search_text}`;

  if (isDex) {
    let logisticUrl =
      (window.globalConfig && window.globalConfig.LOGISTICS_HOST) ||
      HYPERTEST_LOGISTIC_API_ENDPOINT;
    endpoint = `${logisticUrl}/v1.0/tosca-dex/job/${jobId}`;
  }
  return {
    [CALL_API]: {
      types: [
        HYPERTEST_TASK_LOG_ACTIONS_REQUEST,
        HYPERTEST_TASK_LOG_ACTIONS_RESPONSE,
      ],
      endpoint,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};


export const getHypertestTaskLog_SSE = ({
  auth,
  taskId,
  search_text,
  isDex,
  jobId,
}) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;

  let endpoint = `${sentinelUrl}/v1.0/stage/${taskId}?search_text=${search_text}`;

  if (isDex) {
    let logisticUrl =
      (window.globalConfig && window.globalConfig.LOGISTICS_HOST) ||
      HYPERTEST_LOGISTIC_API_ENDPOINT;
    endpoint = `${logisticUrl}/v1.0/tosca-dex/job/${jobId}`;
  }

  return {
    [CALL_API]: {
      types: [
        HYPERTEST_TASK_LOG_ACTIONS_SILENT_REQUEST,
        HYPERTEST_TASK_LOG_ACTIONS_RESPONSE,
      ],
      endpoint,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};

export const getDexTaskDetails = ({ auth, id }) => {
  const sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;

  const endpoint = `${sentinelUrl}/v1.0/tosca/execution-list`;

  return {
    [CALL_API]: {
      types: [''],
      endpoint,
      method: 'GET',
      query: { IDs: id },
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};

export function resetHypertestTaskLog() {
  return {
    type: HYPERTEST_TASK_LOG_RESET,
  };
}

export function setActiveStageLog(key) {
  return {
    type: HYPERTEST_SET_ACTIVE_STAGE_LOG,
    payload: key,
  };
}

export function setCurrentRunningLogType(key) {
  return {
    type: HYPERTEST_SET_CURRENT_RUNNING_LOGTYPE,
    payload: key,
  };
}
