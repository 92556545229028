import React from 'react';

const CloseSearchIcon = ({ width, height, className, onClick }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <g
        id="ML-EXPERIMENTS"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Build-View"
          transform="translate(-564.000000, -123.000000)"
          fill="#BBBBBB"
          fillRule="nonzero"
        >
          <g id="Cross" transform="translate(564.000000, 123.000000)">
            <path d="M6,0 C9.3137085,0 12,2.6862915 12,6 C12,9.3137085 9.3137085,12 6,12 C2.6862915,12 0,9.3137085 0,6 C0,2.6862915 2.6862915,0 6,0 Z M4.08229108,3.35128612 C3.87999296,3.149109 3.55253254,3.14964004 3.35088777,3.35247224 C3.149243,3.55530443 3.14977264,3.88362936 3.35207076,4.08580648 L3.35207076,4.08580648 L5.267,6 L3.35207076,7.91419352 C3.17225021,8.09390652 3.15185166,8.37329185 3.29116023,8.57561434 L3.35088777,8.64752776 C3.55253254,8.85035996 3.87999296,8.850891 4.08229108,8.64871388 L4.08229108,8.64871388 L6,6.731 L7.91770892,8.64871388 C8.09752947,8.82842688 8.37624369,8.8479754 8.57758174,8.7076453 L8.64911223,8.64752776 C8.850757,8.44469557 8.85022736,8.11637064 8.64792924,7.91419352 L8.64792924,7.91419352 L6.732,6 L8.64792924,4.08580648 C8.82774979,3.90609348 8.84814834,3.62670815 8.70883977,3.42438566 L8.64911223,3.35247224 C8.44746746,3.14964004 8.12000704,3.149109 7.91770892,3.35128612 L7.91770892,3.35128612 L5.999,5.268 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export { CloseSearchIcon };
