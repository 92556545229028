import React from 'react';

const CSharpIconColored = ({ width = 20, height = 20, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <rect width="20" height="20" fill="white" />
      <path
        d="M17.107 6.78312C17.1069 6.52225 17.0507 6.29172 16.937 6.09393C16.8253 5.89932 16.658 5.73626 16.4336 5.60733C14.5815 4.54766 12.7276 3.49123 10.876 2.43049C10.3769 2.14454 9.89291 2.15502 9.39745 2.44507C8.66025 2.87641 4.96933 4.97463 3.86948 5.60674C3.41653 5.86697 3.19613 6.26515 3.19602 6.78264C3.19531 8.9137 3.19602 11.0448 3.19531 13.1758C3.19531 13.431 3.24926 13.6571 3.35796 13.8519C3.46972 14.0523 3.63933 14.2199 3.86888 14.3517C4.96878 14.9838 8.66019 17.0819 9.39723 17.5134C9.89291 17.8036 10.3769 17.814 10.8762 17.528C12.7278 16.4672 14.5818 15.4107 16.4342 14.3511C16.6638 14.2193 16.8334 14.0516 16.9451 13.8513C17.0537 13.6565 17.1077 13.4304 17.1077 13.1752C17.1077 13.1752 17.1077 8.91424 17.107 6.78312Z"
        fill="#9A4892"
      />
      <path
        d="M10.1723 9.95898L3.35742 13.8524C3.46918 14.0528 3.63879 14.2204 3.86834 14.3522C4.96824 14.9843 8.65965 17.0824 9.39669 17.5139C9.89237 17.8041 10.3763 17.8145 10.8757 17.5285C12.7273 16.4677 14.5813 15.4113 16.4337 14.3516C16.6632 14.2198 16.8329 14.0521 16.9446 13.8519L10.1723 9.95898"
        fill="#6A1777"
      />
      <path
        d="M17.1061 6.78392C17.106 6.52304 17.0498 6.29252 16.936 6.09473L10.1719 9.95931L16.9442 13.8521C17.0528 13.6573 17.1067 13.4312 17.1068 13.176C17.1068 13.176 17.1068 8.91504 17.1061 6.78392"
        fill="#812F84"
      />
      <path
        d="M14.1841 8.50391V9.23167H14.9175V8.50391H15.2843V9.23167H16.0177V9.59555H15.2843V10.3233H16.0177V10.6871H15.2843V11.4149H14.9175V10.6871H14.1841V11.4149H13.8174V10.6871H13.084V10.3233H13.8174V9.59555H13.084V9.23167H13.8174V8.50391H14.1841ZM14.9175 9.5955H14.1841V10.3233H14.9175V9.59555V9.5955Z"
        fill="white"
      />
      <path
        d="M10.1869 4.84869C12.1 4.84869 13.7702 5.87957 14.6648 7.4119L14.6561 7.39715L12.4052 8.68308C11.9618 7.93808 11.1489 7.43528 10.2163 7.42491L10.1869 7.42475C8.76479 7.42475 7.61187 8.56873 7.61187 9.97981C7.6114 10.4246 7.7285 10.8617 7.95149 11.2475C8.39534 12.0163 9.22977 12.5349 10.1869 12.5349C11.15 12.5349 11.9892 12.0098 12.4308 11.2327L12.4201 11.2513L14.6675 12.5432C13.7827 14.0625 12.1353 15.0902 10.2445 15.1107L10.1869 15.111C8.26786 15.111 6.59291 14.0738 5.70052 12.5333C5.26488 11.7813 5.01562 10.9095 5.01562 9.97981C5.01562 7.14599 7.33082 4.84863 10.1869 4.84863L10.1869 4.84869Z"
        fill="white"
      />
    </svg>
  );
};

export default CSharpIconColored;
