import { ExcludeOnPremURLS } from 'constants/app';

export function isBrandURL() {
  // For Prod
  const origin = window.location.origin;

  // if (ExcludeOnPremURLS.includes(origin)) {
  //   return false;
  // }
  // don't add any branding for dev url
  if (isDevURL()) {
    return false;
  }

  let domainParts = origin.split('.');

  if (!isStageURL()) {
    return domainParts[1] !== 'lambdatest';
  }

  // For Stage
  // dashboard.hyperececute.cloud -> false
  // dashboard.cgi.hyperexecute.cloud -> true

  if (
    domainParts.length === 3 &&
    domainParts[1].includes('hyperexecute') &&
    domainParts[2].includes('cloud')
  ) {
    return false;
  }

  if (domainParts.length >= 4) {
    return true;
  }

  return domainParts[1] !== 'lambdatestinternal';
}

export function isURLContainsMultipleDots(url) {
  return url.match(/\./g).length >= 5;
}

export function isSpecialCaseForOnPrem() {
  const origin = window.location.origin;
  let domainParts = origin.split('.');

  const SpecialCaseURLS = ['beta', 'dashboard', 'beta-dashboard'];

  return SpecialCaseURLS.some((s) => domainParts[0].includes(s));
}

function isStageURL() {
  const origin = window.location.origin;

  if (origin.includes('//stage')) {
    return true;
  }

  return false;
}

function isDevURL() {
  const origin = window.location.origin;
  return origin.includes('dev') && origin.includes('lambdatestinternal');
}

function getBrandName() {
  const origin = window.location.origin;
  let domainParts = origin.split('.');

  return domainParts[1];
}

export function getBrandURL(url, skipSpecialCase = false) {
  const domainParts = url.split('.');

  const brandName = getBrandName();

  if (isURLContainsMultipleDots(url)) {
    return url;
  }

  if (isSpecialCaseForOnPrem() && !skipSpecialCase) {
    const firstLevelDomain = window.location.host.split('.')[0].split('-');

    if (firstLevelDomain.length === 1) {
      return url;
    }

    firstLevelDomain.pop();
    const domainToAppend = firstLevelDomain.join('-');
    // If the URL already has the brand name, don't append it again
    if (url.includes(`https://${domainToAppend}-`)) {
      return url;
    }
    const newUrl = url.replace('https://', `https://${domainToAppend}-`);
    return newUrl;
  }

  domainParts.splice(1, 0, brandName);
  return domainParts.join('.');
}

export const IS_PRIVATE_ON_PREM = process.env.REACT_APP_PRIVATE_ON_PREM;

export function isOnPremURL() {
  return (
    ExcludeOnPremURLS.includes(window.location.origin) || !!IS_PRIVATE_ON_PREM
  );
}
