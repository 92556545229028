import React from 'react';

const HistoryIcon = ({ width = 14, height = 14, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M9.80404 6.90148L7.93806 6.90108V3.70145C7.93806 3.44099 7.72682 3.23047 7.46708 3.23047C7.20733 3.23047 6.99609 3.4411 6.99609 3.70145V7.37217C6.99609 7.63252 7.20733 7.84315 7.46708 7.84315H9.80386C10.0638 7.84315 10.2747 7.63251 10.2747 7.37227C10.2746 7.11261 10.0637 6.90148 9.80404 6.90148Z"
        fill="#666666"
      />
      <path
        d="M11.955 2.74629C9.61097 0.402278 5.79667 0.402278 3.45178 2.74629C2.4173 3.78108 1.80355 5.13551 1.70501 6.58646L1.09239 5.92684C0.915105 5.73649 0.616575 5.72514 0.426135 5.90203C0.235186 6.07932 0.224237 6.37785 0.401325 6.5688L1.7657 8.03793C1.85861 8.13788 1.98477 8.1886 2.11133 8.1886C2.22613 8.1886 2.34115 8.14702 2.43206 8.06275L3.90158 6.69819C4.09253 6.52091 4.10348 6.22237 3.92639 6.03173C3.7493 5.84088 3.45078 5.82943 3.25993 6.00692L2.65744 6.5662C2.75829 5.3755 3.26684 4.26537 4.11863 3.41312C6.09531 1.43674 9.31137 1.43674 11.288 3.41312C13.2644 5.3897 13.2644 8.60612 11.288 10.5825C10.3307 11.54 9.05762 12.0673 7.7032 12.0673C6.34958 12.0673 5.07649 11.5402 4.11863 10.5825C3.93431 10.3983 3.63569 10.3983 3.45165 10.5825C3.26764 10.7667 3.26764 11.0655 3.45175 11.2494C4.58791 12.3851 6.09775 13.0103 7.70335 13.0103C9.30939 13.0103 10.8192 12.3849 11.955 11.2494C14.2993 8.90518 14.2993 5.09031 11.9551 2.74619L11.955 2.74629Z"
        fill="#666666"
      />
    </svg>
  );
};

export default HistoryIcon;
