import React from 'react';

const Skipped = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <rect
        x="1.33002"
        y="1.32999"
        width="13.33"
        height="13.33"
        rx="6.665"
        fill="#666666"
      />
      <g clipPath="url(#clip0_672_5564)">
        <path
          d="M7.99502 4.32999C8.96704 4.32999 9.89924 4.71612 10.5866 5.40344C11.2739 6.09076 11.66 7.02297 11.66 7.99499C11.66 8.967 11.2739 9.89921 10.5866 10.5865C9.89924 11.2739 8.96704 11.66 7.99502 11.66C7.023 11.66 6.09079 11.2739 5.40347 10.5865C4.71615 9.89921 4.33002 8.967 4.33002 7.99499C4.33002 7.02297 4.71615 6.09076 5.40347 5.40344C6.09079 4.71612 7.023 4.32999 7.99502 4.32999ZM5.0172 7.99499C5.0172 8.78475 5.33094 9.54217 5.88939 10.1006C6.44783 10.6591 7.20525 10.9728 7.99502 10.9728C8.78478 10.9728 9.5422 10.6591 10.1006 10.1006C10.6591 9.54217 10.9728 8.78475 10.9728 7.99499C10.9728 7.20522 10.6591 6.4478 10.1006 5.88936C9.5422 5.33091 8.78478 5.01717 7.99502 5.01717C7.20525 5.01717 6.44783 5.33091 5.88939 5.88936C5.33094 6.4478 5.0172 7.20522 5.0172 7.99499ZM8.22408 6.50608V7.87679L9.15316 8.24879C9.23503 8.28448 9.29979 8.35065 9.33371 8.43327C9.36763 8.5159 9.36804 8.60848 9.33486 8.6914C9.30168 8.77432 9.23751 8.84107 9.15596 8.87749C9.0744 8.91391 8.98188 8.91714 8.89798 8.8865L7.75267 8.42837C7.68901 8.40279 7.63445 8.35877 7.596 8.30195C7.55756 8.24514 7.53697 8.17812 7.53689 8.10952V6.50608C7.53689 6.41495 7.57309 6.32756 7.63753 6.26312C7.70196 6.19869 7.78936 6.16249 7.88049 6.16249C7.97161 6.16249 8.05901 6.19869 8.12344 6.26312C8.18788 6.32756 8.22408 6.41495 8.22408 6.50608Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_672_5564">
          <rect
            width="7.33"
            height="7.33"
            fill="white"
            transform="translate(4.33002 4.32999)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Skipped;
