const GenerateYamlIcon = ({ width = 20, height = 16, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
    >
      <g clipPath="url(#clip0_18868_131544)">
        <path
          d="M6.00025 12H6.96866L7.53822 13.0129L8.10778 12H9.07025L7.97299 13.7433V14.9997H7.09909V13.7433L6 12H6.00025ZM10.7164 14.5046H9.72285L9.58622 14.9998H8.6953L9.75575 12H10.7065L11.7677 14.9998H10.8551L10.7164 14.5046ZM10.5337 13.8558L10.2216 12.7774L9.91147 13.8558H10.5337ZM12.0369 12H13.1841L13.6269 13.825L14.0679 12H15.2111V14.9997H14.4989V12.7123L13.9464 14.9997H13.3019L12.7514 12.7123V14.9997H12.0371V12H12.0369ZM15.7669 12H16.6394V14.261H18V15H15.7668L15.7669 12Z"
          fill="#999999"
        />
        <path
          d="M6.47537 10H17.5246C18.2059 10 18.8247 10.2702 19.2732 10.7056C19.7217 11.1411 20 11.7419 20 12.4034V14.5966C20 15.2581 19.7217 15.8589 19.2732 16.2944C18.8247 16.7298 18.2059 17 17.5246 17H6.47537C5.79409 17 5.17532 16.7298 4.72678 16.2944C4.27825 15.8589 4 15.2581 4 14.5966V12.4034C4 11.7419 4.27827 11.1411 4.72678 10.7056C5.17531 10.2702 5.79409 10 6.47537 10ZM17.5246 10.8819H6.47537C6.04488 10.8819 5.65308 11.0532 5.36892 11.3291C5.08477 11.605 4.9083 11.9854 4.9083 12.4034V14.5966C4.9083 15.0146 5.08472 15.395 5.36892 15.6709C5.65313 15.9468 6.04491 16.1181 6.47537 16.1181H17.5246C17.9551 16.1181 18.3469 15.9468 18.6311 15.6709C18.9153 15.3949 19.0917 15.0145 19.0917 14.5966V12.4034C19.0917 11.9854 18.9153 11.605 18.6311 11.3291C18.3469 11.0532 17.9551 10.8819 17.5246 10.8819Z"
          fill="#999999"
        />
        <path
          d="M16 17.0527C16 17.5887 15.765 18.0755 15.3862 18.4283C15.0074 18.7811 14.4845 19 13.9093 19H4.09075C3.51527 19 2.99258 18.7811 2.61382 18.4283C2.23506 18.0756 2 17.5885 2 17.0527V2.94725C2 2.41127 2.23504 1.92446 2.61382 1.57169C2.99257 1.21893 3.51548 1 4.09075 1H13.9093C14.4847 1 15.0074 1.2189 15.3862 1.57169C15.7649 1.92445 16 2.41146 16 2.94725V10.7591H15.1677V2.94725C15.1677 2.62528 15.026 2.33221 14.7976 2.11968C14.5692 1.90697 14.2547 1.77499 13.9091 1.77499H4.09055C3.74485 1.77499 3.43018 1.90697 3.20199 2.11968C2.97361 2.33239 2.8319 2.62529 2.8319 2.94725V17.0527C2.8319 17.3747 2.97361 17.6678 3.20199 17.8803C3.43038 18.093 3.74486 18.225 4.09055 18.225H13.9091C14.2548 18.225 14.5694 18.093 14.7976 17.8803C15.026 17.6676 15.1677 17.3747 15.1677 17.0527H16Z"
          fill="#999999"
        />
        <path
          d="M6.16167 3.88672C5.99363 3.88672 5.85742 4.02293 5.85742 4.19097C5.85742 4.35902 5.99363 4.49522 6.16167 4.49522H11.8082C11.9763 4.49522 12.1125 4.35902 12.1125 4.19097C12.1125 4.02293 11.9763 3.88672 11.8082 3.88672H6.16167Z"
          fill="#999999"
        />
        <path
          d="M6.16167 5.61133C5.99363 5.61133 5.85742 5.74753 5.85742 5.91558C5.85742 6.08363 5.99363 6.21983 6.16167 6.21983H11.8082C11.9763 6.21983 12.1125 6.08363 12.1125 5.91558C12.1125 5.74753 11.9763 5.61133 11.8082 5.61133H6.16167Z"
          fill="#999999"
        />
        <path
          d="M6.16167 7.33789C5.99363 7.33789 5.85742 7.4741 5.85742 7.64214C5.85742 7.81019 5.99363 7.94639 6.16167 7.94639H11.8082C11.9763 7.94639 12.1125 7.81019 12.1125 7.64214C12.1125 7.4741 11.9763 7.33789 11.8082 7.33789H6.16167Z"
          fill="#999999"
        />
      </g>
      <defs>
        <clipPath id="clip0_18868_131544">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GenerateYamlIcon;
