import React from 'react';

const Created = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle
        cx="21.0077"
        cy="21.9923"
        r="19.5"
        transform="rotate(-0.024375 21.0077 21.9923)"
        fill="white"
        stroke="#5F6877"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0001 36.9846C29.2844 36.9846 36.0001 30.2689 36.0001 21.9846C36.0001 13.7004 29.2844 6.98464 21.0001 6.98464C12.7158 6.98464 6.0001 13.7004 6.0001 21.9846C6.0001 30.2689 12.7158 36.9846 21.0001 36.9846ZM33.0001 21.9846C33.0001 28.6121 27.6275 33.9846 21.0001 33.9846C14.3727 33.9846 9.0001 28.6121 9.0001 21.9846C9.0001 15.3572 14.3727 9.98464 21.0001 9.98464C27.6275 9.98464 33.0001 15.3572 33.0001 21.9846ZM19.8837 15L19.9573 15C20.4483 15 20.841 15.4976 20.841 16.1195L20.841 23.1789L25.5908 26.7552C25.6923 26.8289 25.7809 26.9277 25.8516 27.0458C25.9222 27.1639 25.9734 27.2987 26.002 27.4424C26.0306 27.586 26.0361 27.7355 26.0182 27.8819C26.0003 28.0283 25.9593 28.1686 25.8977 28.2945C25.6522 28.8232 25.1122 28.9787 24.6949 28.6677L19.6014 24.796C19.2209 24.5161 19 24.003 19 23.4587L19 16.1195C19 15.4976 19.3927 15 19.8837 15Z"
        fill="#5F6877"
      />
    </svg>
  );
};

export default Created;
