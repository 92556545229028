import React from 'react';

const PythonIconColored = ({ width = 20, height = 20, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <rect width="20" height="20" fill="white" />
      <path
        d="M9.93682 2.55078C6.16152 2.55078 6.39725 4.18798 6.39725 4.18798L6.40144 5.88416H10.0041V6.3934H4.9705C4.9705 6.3934 2.55469 6.11942 2.55469 9.92867C2.55469 13.738 4.66327 13.6029 4.66327 13.6029H5.92167V11.8352C5.92167 11.8352 5.85383 9.72666 7.99659 9.72666H11.5698C11.5698 9.72666 13.5774 9.75909 13.5774 7.78643V4.52473C13.5774 4.52473 13.8823 2.55078 9.93682 2.55078ZM7.9503 3.69143C8.03543 3.69135 8.11974 3.70807 8.19841 3.74061C8.27708 3.77315 8.34856 3.82089 8.40876 3.88109C8.46896 3.94129 8.5167 4.01277 8.54924 4.09144C8.58178 4.17011 8.5985 4.25442 8.59842 4.33955C8.5985 4.42469 8.58178 4.509 8.54924 4.58767C8.5167 4.66634 8.46896 4.73782 8.40876 4.79802C8.34856 4.85822 8.27708 4.90595 8.19841 4.9385C8.11974 4.97104 8.03543 4.98775 7.9503 4.98768C7.86516 4.98775 7.78085 4.97104 7.70218 4.9385C7.62351 4.90595 7.55203 4.85822 7.49183 4.79802C7.43163 4.73782 7.3839 4.66634 7.35135 4.58767C7.31881 4.509 7.3021 4.42469 7.30217 4.33955C7.3021 4.25442 7.31881 4.17011 7.35135 4.09144C7.3839 4.01277 7.43163 3.94129 7.49183 3.88109C7.55203 3.82089 7.62351 3.77315 7.70218 3.74061C7.78085 3.70807 7.86516 3.69135 7.9503 3.69143V3.69143Z"
        fill="url(#paint0_linear_726_10793)"
      />
      <path
        d="M10.0445 17.3447C13.8198 17.3447 13.5841 15.7075 13.5841 15.7075L13.5799 14.0114H9.97713V13.5022H15.0108C15.0108 13.5022 17.4266 13.7762 17.4266 9.96679C17.4266 6.15749 15.318 6.29258 15.318 6.29258H14.0596V8.06022C14.0596 8.06022 14.1275 10.1688 11.9847 10.1688H8.41145C8.41145 10.1688 6.4039 10.1364 6.4039 12.1091V15.3708C6.4039 15.3708 6.09905 17.3447 10.0445 17.3447H10.0445ZM12.031 16.2042C11.9459 16.2043 11.8616 16.1876 11.7829 16.155C11.7043 16.1225 11.6328 16.0747 11.5726 16.0145C11.5124 15.9543 11.4646 15.8829 11.4321 15.8042C11.3996 15.7255 11.3828 15.6412 11.3829 15.5561C11.3828 15.4709 11.3995 15.3866 11.4321 15.3079C11.4646 15.2293 11.5124 15.1578 11.5726 15.0976C11.6328 15.0374 11.7042 14.9896 11.7829 14.9571C11.8616 14.9245 11.9459 14.9078 12.031 14.9079C12.1162 14.9078 12.2005 14.9245 12.2792 14.9571C12.3578 14.9896 12.4293 15.0374 12.4895 15.0976C12.5497 15.1578 12.5974 15.2292 12.63 15.3079C12.6625 15.3866 12.6792 15.4709 12.6792 15.556C12.6792 15.6412 12.6625 15.7255 12.63 15.8041C12.5974 15.8828 12.5497 15.9543 12.4895 16.0145C12.4293 16.0747 12.3578 16.1224 12.2792 16.155C12.2005 16.1875 12.1162 16.2042 12.031 16.2041V16.2042Z"
        fill="url(#paint1_linear_726_10793)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_726_10793"
          x1="3.98383"
          y1="3.88142"
          x2="11.3537"
          y2="11.1777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#387EB8" />
          <stop offset="1" stop-color="#366994" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_726_10793"
          x1="8.5079"
          y1="8.56654"
          x2="16.4222"
          y2="16.0482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE052" />
          <stop offset="1" stop-color="#FFC331" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PythonIconColored;
