import React from 'react';

const WindowsLarge = ({
  width = '128',
  height = '128',
  className,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 128 128"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <g
        id="New-Designs-Coloured-Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Posters---Automation"
          transform="translate(-326.000000, -231.000000)"
          fill="#00ADEF"
        >
          <path
            d="M326,341.942149 L378.596364,349.225455 L378.596364,298.956364 L326,298.956364 L326,341.942149 Z M326,291.974545 L378.596364,291.974545 L378.596364,241.705455 L326,248.896825 L326,291.974545 Z M384.181818,349.463658 L454,359 L454,298.956364 L384.181818,298.956364 L384.181818,349.463658 Z M384.181818,291.974545 L454,291.974545 L454,231 L384.181818,240.582728 L384.181818,291.974545 L384.181818,291.974545 Z"
            id="Windows"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default WindowsLarge;
