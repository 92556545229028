import {
  TEST_MUTING_LOADING,
  TEST_MUTING_FETCH_SUCCESS,
  TEST_MUTING_RESET,
  TEST_MUTING_MARK_SUCCESS,
  TEST_UNMUTING_MARK_SUCCESS,
  TEST_UNMUTING_UPDATE_STATUS,
  TEST_MUTING_UPDATE_STATUS,
} from '../constants/stages';

/*
  mutingInfo = {
    id: {
      loading: true,
      data: [],
      error: true
    }
  }
*/
const initialState = {
  mutingInfo: {},
};

function stageReducer(state = initialState, actions) {
  const { type, payload, extra } = actions;
  switch (type) {
    case TEST_MUTING_FETCH_SUCCESS: {
      const muted = payload?.data?.current_muting_status === 'muted';
      if (state.mutingInfo[extra?.scenarioId]) {
        state.mutingInfo[extra?.scenarioId].loading = false;
        state.mutingInfo[extra?.scenarioId].muted = muted;
      } else {
        state.mutingInfo[extra?.scenarioId] = {};
        state.mutingInfo[extra?.scenarioId].loading = false;
        state.mutingInfo[extra?.scenarioId].muted = muted;
      }
      return {
        ...state,
        mutingInfo: { ...state.mutingInfo },
      };
    }

    case TEST_MUTING_LOADING: {
      if (state.mutingInfo[extra?.scenarioId]) {
        state.mutingInfo[extra?.scenarioId].loading = true;
      } else {
        state.mutingInfo[extra?.scenarioId] = {};
        state.mutingInfo[extra?.scenarioId].loading = true;
      }
      return {
        ...state,
        mutingInfo: { ...state.mutingInfo },
      };
    }
    case TEST_MUTING_UPDATE_STATUS: {
      if (state.mutingInfo[extra?.scenarioId]) {
        state.mutingInfo[extra?.scenarioId].isMuting = true;
      } else {
        state.mutingInfo[extra?.scenarioId] = {};
        state.mutingInfo[extra?.scenarioId].isMuting = true;
      }
      return {
        ...state,
        mutingInfo: { ...state.mutingInfo },
      };
    }
    case TEST_UNMUTING_UPDATE_STATUS: {
      if (state.mutingInfo[extra?.scenarioId]) {
        state.mutingInfo[extra?.scenarioId].isUnMuting = true;
      } else {
        state.mutingInfo[extra?.scenarioId] = {};
        state.mutingInfo[extra?.scenarioId].isUnMuting = true;
      }
      return {
        ...state,
        mutingInfo: { ...state.mutingInfo },
      };
    }
    case TEST_UNMUTING_MARK_SUCCESS: {
      const unmuted = payload?.data?.status === 'success';

      if (state.mutingInfo[extra?.scenarioId]) {
        state.mutingInfo[extra?.scenarioId].isUnMuting = false;
        state.mutingInfo[extra?.scenarioId].muted = !unmuted;
      } else {
        state.mutingInfo[extra?.scenarioId] = {};
        state.mutingInfo[extra?.scenarioId].isUnMuting = false;
        state.mutingInfo[extra?.scenarioId].muted = !unmuted;
      }
      return {
        ...state,
        mutingInfo: { ...state.mutingInfo },
      };
    }
    case TEST_MUTING_MARK_SUCCESS: {
      const muted = payload?.data?.status === 'success';

      if (state.mutingInfo[extra?.scenarioId]) {
        state.mutingInfo[extra?.scenarioId].isMuting = false;
        state.mutingInfo[extra?.scenarioId].muted = muted;
      } else {
        state.mutingInfo[extra?.scenarioId] = {};
        state.mutingInfo[extra?.scenarioId].isMuting = false;
        state.mutingInfo[extra?.scenarioId].muted = muted;
      }
      return {
        ...state,
        mutingInfo: { ...state.mutingInfo },
      };
    }
    case TEST_MUTING_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default stageReducer;
