import React from 'react';

const AutoSplit = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M9.90008 3.23333L10.8601 4.19333L8.94008 6.11333L9.88675 7.06L11.8067 5.14L12.7667 6.1C12.8137 6.14606 12.8733 6.17719 12.938 6.18945C13.0026 6.20171 13.0695 6.19456 13.1301 6.16889C13.1907 6.14323 13.2423 6.1002 13.2785 6.04523C13.3147 5.99026 13.3338 5.9258 13.3334 5.86V3C13.3334 2.81333 13.1867 2.66666 13.0001 2.66666H10.1401C10.0743 2.66628 10.0098 2.68538 9.95485 2.72156C9.89988 2.75775 9.85685 2.80939 9.83118 2.86999C9.80552 2.93059 9.79836 2.99743 9.81063 3.06209C9.82289 3.12675 9.85401 3.18633 9.90008 3.23333ZM5.86008 2.66666H3.00008C2.81341 2.66666 2.66675 2.81333 2.66675 3V5.86C2.66675 6.16 3.02675 6.30666 3.23341 6.09333L4.19341 5.13333L7.33341 8.26666V12.6667C7.33341 13.0333 7.63341 13.3333 8.00008 13.3333C8.36675 13.3333 8.66675 13.0333 8.66675 12.6667V8C8.66675 7.82666 8.59341 7.65333 8.47341 7.52666L5.14008 4.18666L6.10008 3.22666C6.30675 3.02666 6.16008 2.66666 5.86008 2.66666Z"
        fill="#666666"
      />
    </svg>
  );
};

export { AutoSplit };
