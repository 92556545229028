import React from 'react';
import './style.css';
import { Icon } from 'new-theme/components/Icons';
import { LTButton, LTText } from '@lambdatestincprivate/lt-components';

const Analytics = ({ handleCreateDashboard, fetchingDashboard }) => {
  return (
    <div className="hyp-analytics-modal">
      <Icon name="analytics-poster" />
      <div className="hyp-analytics-text-content">
        <LTText
          style={{ fontWeight: 600, fontSize: '16px' }}
          text="Analyze Your Tests with Insights"
        />
        <LTText
          style={{ fontWeight: 400, fontSize: '12px' }}
          sie
          text="To help you analyse your tests, we have curated a personalised Insights Dashboard for you."
        />
      </div>
      <LTButton
        loading={fetchingDashboard}
        onClick={handleCreateDashboard}
        size="large"
        className="hyp-analytics-modal-btn"
      >
        <Icon name="insight" /> Explore Insights
      </LTButton>
    </div>
  );
};

export default Analytics;
