function handleBackDropElementsAccessibility(accessible = true) {
  const elementToAriaAttributes = [
    '.main_section',
    '.main-section',
    '.product_main_content',
    '.zls-sptwndw',
    '.wrapper',
    '.announcekit-frame-wrapper',
    '.lt-project-listing',
    '.zls-sptwndw',
    '.home-page-conatiner-quickstart',
  ].map((ele) => {
    return document?.querySelector(ele);
  });

  const siqChat = document.getElementById('siqcht');

  [...elementToAriaAttributes, siqChat].forEach((ele) => {
    ele?.setAttribute('aria-hidden', accessible);
    ele?.setAttribute('aria-disabled', accessible);
  });
}

export default handleBackDropElementsAccessibility;
