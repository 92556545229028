const documentationTabDetails = [
  {
    name: 'Concepts',
    logo: 'onboarding/concepts',
    link: 'https://www.lambdatest.com/support/docs/hyperexecute-concepts/',
    eventName: 'concepts',
    description: 'Everything you need to know about HyperExecute concepts.',
  },
  {
    name: 'Features',
    logo: 'onboarding/feature',
    link: 'https://www.lambdatest.com/support/docs/key-features-of-hyperexecute/',
    eventName: 'features',
    description: 'Learn how HyperExecute can help you in your testing journey',
  },
  {
    name: 'Guided Walkthrough',
    logo: 'onboarding/guided-tour',
    link: 'https://www.lambdatest.com/support/docs/hyperexecute-guided-walkthrough/',
    eventName: 'guided walkthrough',
    description: 'Take the guided tour in our documentation at your own pace',
  },
  {
    name: 'Hyperexecute CLI',
    logo: 'onboarding/cli',
    link: 'https://www.lambdatest.com/support/docs/hyperexecute-cli-run-tests-on-hyperexecute-grid/',
    eventName: 'hypex cli',
    description: 'Learn about out command line interface required to run tests',
  },
  {
    name: 'HyperExecute YAML',
    logo: 'onboarding/yaml',
    link: 'https://www.lambdatest.com/support/docs/deep-dive-into-hyperexecute-yaml/',
    eventName: 'hypex yaml',
    description: 'Read more on how to configure HyperExecute via the YAML file',
  },
  {
    name: 'Integrations',
    logo: 'onboarding/integration',
    link: 'https://www.lambdatest.com/support/docs/integration-with-hyperexecute/',
    eventName: 'integrations',
    description: 'Integrate with other products, CI/CD pipelines and more',
  },
];

export default documentationTabDetails;
