import React from 'react';

const BackIcon = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 16"
      role="img"
      fill="none"
      aria-hidden="true"
    >
      <path
        d="M8 15L1 8L8 1"
        stroke="#111827"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { BackIcon };
