import React from 'react';

const TaskSearch = ({
  height = '15',
  width = '15',
  className = '',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M14.0026 14.0007L11.0119 11.0047L14.0026 14.0007ZM12.6693 7.00065C12.6693 8.50355 12.0723 9.94488 11.0095 11.0076C9.94684 12.0703 8.5055 12.6673 7.0026 12.6673C5.49971 12.6673 4.05837 12.0703 2.99567 11.0076C1.93296 9.94488 1.33594 8.50355 1.33594 7.00065C1.33594 5.49776 1.93296 4.05642 2.99567 2.99371C4.05837 1.93101 5.49971 1.33398 7.0026 1.33398C8.5055 1.33398 9.94684 1.93101 11.0095 2.99371C12.0723 4.05642 12.6693 5.49776 12.6693 7.00065V7.00065Z"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TaskSearch;
