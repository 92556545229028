import React from 'react';

const GitpodSupport = ({
  width = '16',
  height = '16',
  className,
  color,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      role="img"
      fill="none"
      aria-hidden="true"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M9.12944 1.69678C9.30097 2.01496 9.34643 2.39121 9.25599 2.74429C9.16556 3.09736 8.9465 3.39892 8.64611 3.58384L4.76834 5.94048C4.71755 5.97141 4.6754 6.01583 4.64615 6.06928C4.61689 6.12273 4.60155 6.18333 4.60167 6.24498V9.94444C4.60154 10.006 4.61687 10.0665 4.64614 10.1199C4.6754 10.1733 4.71755 10.2176 4.76834 10.2484L7.83722 12.1144C7.88669 12.1445 7.94284 12.1603 8 12.1603C8.05716 12.1603 8.11331 12.1445 8.16278 12.1144L11.2333 10.2484C11.2838 10.2174 11.3257 10.1731 11.3549 10.1199C11.384 10.0667 11.3994 10.0064 11.3994 9.94502V7.6438L8.63889 9.29928C8.49001 9.38838 8.32575 9.4455 8.15565 9.46731C7.98556 9.48913 7.81302 9.4752 7.6481 9.42635C7.48318 9.37749 7.32916 9.29468 7.195 9.18274C7.06085 9.07079 6.94924 8.93195 6.86667 8.77429C6.69709 8.45494 6.65389 8.07839 6.74639 7.72589C6.8389 7.37339 7.05968 7.07322 7.36111 6.89014L11.31 4.52067C12.5128 3.79967 14 4.72366 14 6.1919V10.225C14.0011 10.6874 13.8864 11.142 13.6675 11.5432C13.4486 11.9444 13.1332 12.2782 12.7528 12.5111L9.22778 14.6531C8.85459 14.8803 8.43085 15 7.99944 15C7.56804 15 7.1443 14.8803 6.77111 14.6531L3.24667 12.5111C2.86645 12.2781 2.55117 11.9444 2.33238 11.5433C2.1136 11.1422 1.99898 10.6878 2.00001 10.2256V5.96382C1.99904 5.50154 2.11374 5.0471 2.33262 4.64601C2.55151 4.24491 2.8669 3.91122 3.24723 3.67834L7.35445 1.1817C7.50287 1.09175 7.66686 1.03369 7.83686 1.01091C8.00685 0.988136 8.17947 1.00109 8.34465 1.04903C8.50984 1.09696 8.66429 1.17892 8.79902 1.29012C8.93376 1.40133 9.04608 1.53956 9.12944 1.69678Z"
        fill="#666666"
      />
    </svg>
  );
};

export { GitpodSupport };
