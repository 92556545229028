import React from 'react';

const FilterStatus = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M4.32464 6.68033C4.1479 6.54778 3.89718 6.58359 3.76464 6.76033C3.63209 6.93706 3.6679 7.18778 3.84464 7.32033L4.32464 6.68033ZM6.41797 8.75033L6.17797 9.07033C6.26413 9.13495 6.3727 9.16209 6.47914 9.14562C6.58557 9.12915 6.68086 9.07046 6.74346 8.98282L6.41797 8.75033ZM9.66013 4.89949C9.78853 4.71972 9.7469 4.4699 9.56713 4.3415C9.38737 4.2131 9.13755 4.25473 9.00914 4.4345L9.66013 4.89949ZM7.0013 13.2337C10.444 13.2337 13.2346 10.443 13.2346 7.00033H12.4346C12.4346 10.0012 10.0021 12.4337 7.0013 12.4337V13.2337ZM13.2346 7.00033C13.2346 3.55766 10.444 0.766992 7.0013 0.766992V1.56699C10.0021 1.56699 12.4346 3.99949 12.4346 7.00033H13.2346ZM7.0013 0.766992C3.55864 0.766992 0.767969 3.55766 0.767969 7.00033H1.56797C1.56797 3.99949 4.00047 1.56699 7.0013 1.56699V0.766992ZM0.767969 7.00033C0.767969 10.443 3.55864 13.2337 7.0013 13.2337V12.4337C4.00047 12.4337 1.56797 10.0012 1.56797 7.00033H0.767969ZM3.84464 7.32033L6.17797 9.07033L6.65797 8.43033L4.32464 6.68033L3.84464 7.32033ZM6.74346 8.98282L9.66013 4.89949L9.00914 4.4345L6.09248 8.51783L6.74346 8.98282Z"
        fill="#4A4A4A"
      />
    </svg>
  );
};

export { FilterStatus };
