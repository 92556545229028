import React from 'react';

const AlertIcon = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M2.24878 20.4249C1.11441 20.4249 0.59878 19.6342 1.11441 18.6789L10.2925 1.38398C10.8082 0.428646 11.6332 0.428646 12.1488 1.38398L21.3269 18.6789C21.8425 19.6342 21.3269 20.4249 20.1925 20.4249H2.24878Z"
        fill="#FFCE21"
      />
      <path
        d="M10.0835 8.78768L10.8701 13.7679C10.9543 14.2525 11.6004 14.2525 11.6847 13.7679L12.4432 8.78768C12.5836 6.84942 9.94309 6.84942 10.0835 8.78768Z"
        fill="#231F20"
      />
      <path
        d="M11.2618 16.9171C11.9134 16.9171 12.4416 16.4109 12.4416 15.7864C12.4416 15.162 11.9134 14.6558 11.2618 14.6558C10.6102 14.6558 10.082 15.162 10.082 15.7864C10.082 16.4109 10.6102 16.9171 11.2618 16.9171Z"
        fill="#231F20"
      />
    </svg>
  );
};

export default AlertIcon;
