import React from 'react';

const CustomProject = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      width={width}
      className={className}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      alt=""
      role="img"
      {...rest}
    >
      <rect y="4" width="16" height="8" rx="2" fill="#666666" />
      <path
        d="M12 8.3335C12.2761 8.3335 12.5 8.10964 12.5 7.8335C12.5 7.55735 12.2761 7.3335 12 7.3335C11.7239 7.3335 11.5 7.55735 11.5 7.8335C11.5 8.10964 11.7239 8.3335 12 8.3335Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 8.3335C8.27614 8.3335 8.5 8.10964 8.5 7.8335C8.5 7.55735 8.27614 7.3335 8 7.3335C7.72386 7.3335 7.5 7.55735 7.5 7.8335C7.5 8.10964 7.72386 8.3335 8 8.3335Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 8.3335C4.27614 8.3335 4.5 8.10964 4.5 7.8335C4.5 7.55735 4.27614 7.3335 4 7.3335C3.72386 7.3335 3.5 7.55735 3.5 7.8335C3.5 8.10964 3.72386 8.3335 4 8.3335Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CustomProject;
