import React from 'react';

const XCUI = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
      role="img"
      aria-hidden="true"
    >
      <path
        d="M21.0042 14.3776L14.3592 21.011C13.0382 22.3297 10.9167 22.3297 9.59568 21.011L2.99074 14.3776C1.66975 13.0589 1.66975 10.9411 2.99074 9.62238L9.6357 2.98901C10.9567 1.67033 13.0783 1.67033 14.3993 2.98901L21.0442 9.62238C22.3252 10.9411 22.3252 13.0989 21.0042 14.3776Z"
        fill="#66C649"
      />
      <path
        d="M6.31322 11.3407H9.39552L10.8766 13.8182L15.1198 7.18481L16.761 8.86313L10.8366 18.2537L6.31322 11.3407Z"
        fill="white"
      />
    </svg>
  );
};

export default XCUI;
