import {
  SET_JOB_FILTER,
  SET_JOB_FILTER_COUNT,
  RESET_JOB_FILTER,
  SET_JOB_SEAERCH,
  SET_IS_JOB_FILTER_USED_FIRST_TIME,
  RESET_USER_ACTION,
  DEFAULT_TEST_LIMIT,
  SET_HISTORY_LIMIT,
} from '../constants/filters';

const initialState = {
  filters: {
    // jobFilters: {
    //   range: [
    //     {
    //       startDate: new Date(),
    //       endDate: new Date(),
    //       key: 'selection',
    //     },
    //   ],
    //   job_label: [],
    //   status: [],
    //   job_type: [],
    //   user: [],
    //   search: '',
    // },
    // use null initially then set it once page loaded using query params if any
    jobFilters: null,
    testHistoryLimit: DEFAULT_TEST_LIMIT,
    jobSearch: '',
    enabledFilters: [],
    isJobFilterUsedFirstTime: false,
  },
};
export let filterTrack = {
  ...initialState,
};
export function filterReducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case SET_HISTORY_LIMIT: {
      return {
        ...state,
        filters: {
          ...state.filters,
          testHistoryLimit: payload,
        },
      };
    }
    case SET_JOB_FILTER: {
      const { isUserAction = false, ...rest } = payload;
      filterTrack = state;
      return {
        ...state,
        filters: {
          ...state.filters,
          isUserAction,
          jobFilters: {
            ...state?.filters?.jobFilters,
            ...rest,
          },
        },
      };
    }

    case RESET_USER_ACTION: {
      return {
        ...state,
        filters: {
          ...state.filters,
          isUserAction: false,
        },
      };
    }

    case SET_JOB_FILTER_COUNT:
      filterTrack = state;
      return {
        ...state,
        filters: {
          ...state.filters,
          enabledFilters: payload,
        },
      };
    case RESET_JOB_FILTER:
      filterTrack = initialState;
      return {
        ...state,
        filters: {
          ...state.filters,
          jobFilters: {
            search: state.filters.jobFilters.search,
            range: [
              {
                startDate: new Date(new Date().toUTCString()),
                endDate: new Date(new Date().toUTCString()),
                key: 'selection',
              },
            ],
            user: [],
            status: [],
            job_label: [],
            job_type: [],
          },
          enabledFilters: [],
          isJobFilterUsedFirstTime: true,
          isUserAction: payload,
        },
      };
    case SET_JOB_SEAERCH:
      filterTrack = state;
      return {
        ...state,
        filters: {
          ...state.filters,
          jobSearch: payload,
        },
      };
    case SET_IS_JOB_FILTER_USED_FIRST_TIME:
      return {
        ...state,
        filters: {
          ...state.filters,
          isJobFilterUsedFirstTime: payload,
        },
      };
    default:
      return state;
  }
}
