import React from 'react';

const GeneratingRCA = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <circle
        opacity="0.25"
        cx="8"
        cy="8"
        r="6.5"
        stroke="#333333"
        strokeWidth="2"
      />
      <mask id="path-2-inside-1_344_7290" fill="white">
        <path d="M15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96018 0.693993 8.98491 0.5 8 0.5V2.49335C8.72314 2.49335 9.43921 2.63578 10.1073 2.91251C10.7754 3.18925 11.3825 3.59487 11.8938 4.10621C12.4051 4.61755 12.8108 5.2246 13.0875 5.89269C13.3642 6.56079 13.5067 7.27686 13.5067 8H15.5Z" />
      </mask>
      <path
        d="M15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96018 0.693993 8.98491 0.5 8 0.5V2.49335C8.72314 2.49335 9.43921 2.63578 10.1073 2.91251C10.7754 3.18925 11.3825 3.59487 11.8938 4.10621C12.4051 4.61755 12.8108 5.2246 13.0875 5.89269C13.3642 6.56079 13.5067 7.27686 13.5067 8H15.5Z"
        stroke="#333333"
        strokeWidth="4"
        mask="url(#path-2-inside-1_344_7290)"
      />
    </svg>
  );
};

export default GeneratingRCA;
