import React from 'react';

const Run = ({ width = '10', height = '12', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M1 1V11L9.33333 6L1 1Z"
        stroke="#666666"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Run;
