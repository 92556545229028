import React from 'react';

const Abort = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="42" cy="42" r="42" fill="#F45C47" />
      <path
        d="M33.5 20L20 33.851V51.5L33.5 65H51.5L65 51.5V33.851L51.5 20H33.5Z"
        fill="white"
      />
      <path
        d="M51.5 42.5H33.5"
        stroke="#F45C47"
        strokeWidth="4.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { Abort };
