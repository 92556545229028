import React from 'react';

const FeatureCheckIcon = ({ width, height, className, onClick }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <circle cx="8" cy="8" r="8" fill="#2AB14F" />
      <path
        d="M5.21176 7.92624C4.92939 7.67984 4.50073 7.70901 4.25433 7.99139C4.00794 8.27377 4.03711 8.70242 4.31949 8.94882L5.21176 7.92624ZM7.1022 10.4764L7.54835 9.96508L7.54834 9.96508L7.1022 10.4764ZM7.16717 10.4722L7.67524 10.922L7.67525 10.922L7.16717 10.4722ZM11.8874 6.16403C12.1358 5.88343 12.1097 5.45457 11.8291 5.20616C11.5485 4.95774 11.1197 4.98383 10.8713 5.26444L11.8874 6.16403ZM4.31949 8.94882L6.65606 10.9877L7.54834 9.96508L5.21176 7.92624L4.31949 8.94882ZM7.67525 10.922L11.8874 6.16403L10.8713 5.26444L6.65909 10.0224L7.67525 10.922ZM6.65606 10.9877C6.95614 11.2495 7.41126 11.2202 7.67524 10.922L6.65909 10.0224C6.88942 9.76222 7.28652 9.73662 7.54835 9.96508L6.65606 10.9877Z"
        fill="white"
      />
    </svg>
  );
};

export { FeatureCheckIcon };
