export const HYPERTEST_JOB_LIST_ACTIONS_REQUEST =
  'HYPERTEST_JOB_LIST_ACTIONS_REQUEST';
export const HYPERTEST_JOB_LIST_ACTIONS_RESPONSE =
  'HYPERTEST_JOB_LIST_ACTIONS_RESPONSE';
export const HYPERTEST_JOB_ABORT_ACTION_REQUEST =
  'HYPERTEST_JOB_ABORT_ACTION_REQUEST';
export const HYPERTEST_JOB_ABORT_ACTION_RESPONSE =
  'HYPERTEST_JOB_ABORT_ACTION_RESPONSE';
export const HYPERTEST_UPDATE_JOB_LIST_ACTION =
  'HYPERTEST_UPDATE_JOB_LIST_ACTION';
export const HYPERTEST_CLEAR_JOB_LIST = 'HYPERTEST_CLEAR_JOB_LIST';
export const HYPERTEST_JOB_LIST_ACTIONS_REQUEST_SSE =
  'HYPERTEST_JOB_LIST_ACTIONS_REQUEST_SSE';
export const HYPERTEST_JOB_INSERT_INTO_JOB_LIST_ACTION =
  'HYPERTEST_JOB_INSERT_INTO_JOB_LIST_ACTION';
export const HYPERTEST_SET_JOB_LIST_VISUAL = 'HYPERTEST_SET_JOB_LIST_VISUAL';
export const HYPERTEST_JOB_TAGS_LIST_ACTIONS_REQUEST =
  'HYPERTEST_JOB_TAGS_LIST_ACTIONS_REQUEST';
export const HYPERTEST_JOB_TAGS_LIST_ACTIONS_RESPONSE =
  'HYPERTEST_JOB_TAGS_LIST_ACTIONS_RESPONSE';
export const HYPERTEST_SET_CURRENT_SELECTED_JOB_TAGS =
  'HYPERTEST_SET_CURRENT_SELECTED_JOB_TAGS';
export const HYPERTEST_REMOVE_JOB = 'HYPERTEST_REMOVE_JOB';
export const HYPERTEST_UPDATE_CLICKED_JOB_ID =
  'HYPERTEST_UPDATE_CLICKED_JOB_ID';
