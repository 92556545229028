import React from 'react';

const CheckMark = ({ width = '10', height = '7', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className={className}
      aria-hidden="true"
      {...rest}
    >
      <path d="M1 3L4 6L9 1" stroke="#333333" />
    </svg>
  );
};

export default CheckMark;
