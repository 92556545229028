import React from 'react';

const CSharpIcon = ({ width = 20, height = 20, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M17.1061 6.7841C17.106 6.52322 17.0498 6.2927 16.9361 6.0949C16.8244 5.9003 16.6571 5.73723 16.4327 5.60831C14.5806 4.54864 12.7266 3.49221 10.8751 2.43146C10.376 2.14552 9.89198 2.156 9.39652 2.44605C8.65932 2.87739 4.9684 4.97561 3.86855 5.60771C3.4156 5.86794 3.1952 6.26612 3.19509 6.78362C3.19438 8.91467 3.19509 11.0457 3.19438 13.1768C3.19438 13.4319 3.24833 13.658 3.35703 13.8529C3.46879 14.0533 3.6384 14.2208 3.86795 14.3527C4.96785 14.9848 8.65926 17.0829 9.3963 17.5144C9.89198 17.8046 10.376 17.8149 10.8753 17.5289C12.7269 16.4681 14.5809 15.4117 16.4333 14.352C16.6628 14.2203 16.8325 14.0526 16.9442 13.8523C17.0528 13.6574 17.1068 13.4314 17.1068 13.1762C17.1068 13.1762 17.1068 8.91521 17.1061 6.7841Z"
        fill="#999999"
      />
      <path
        d="M10.1714 9.95703L3.35652 13.8505C3.46828 14.0509 3.63789 14.2184 3.86744 14.3503C4.96734 14.9824 8.65875 17.0804 9.39579 17.512C9.89147 17.8022 10.3754 17.8125 10.8748 17.5265C12.7264 16.4657 14.5804 15.4093 16.4328 14.3496C16.6623 14.2178 16.832 14.0502 16.9437 13.8499L10.1714 9.95703Z"
        fill="#999999"
      />
      <path
        d="M17.1055 6.78295C17.1054 6.52207 17.0492 6.29154 16.9355 6.09375L10.1713 9.95833L16.9436 13.8512C17.0522 13.6563 17.1061 13.4302 17.1062 13.175C17.1062 13.175 17.1062 8.91406 17.1055 6.78295Z"
        fill="#999999"
      />
      <path
        d="M14.1838 8.50391V9.23167H14.9173V8.50391H15.284V9.23167H16.0174V9.59555H15.284V10.3233H16.0174V10.6871H15.284V11.4149H14.9173V10.6871H14.1838V11.4149H13.8171V10.6871H13.0837V10.3233H13.8171V9.59555H13.0837V9.23167H13.8171V8.50391H14.1838ZM14.9173 9.5955H14.1838V10.3233H14.9173V9.59555V9.5955Z"
        fill="white"
      />
      <path
        d="M10.1864 4.84771C12.0994 4.84771 13.7696 5.87859 14.6642 7.41092L14.6555 7.39617L12.4047 8.6821C11.9613 7.93711 11.1483 7.43431 10.2157 7.42394L10.1863 7.42377C8.76422 7.42377 7.6113 8.56776 7.6113 9.97883C7.61084 10.4236 7.72794 10.8607 7.95092 11.2465C8.39478 12.0154 9.2292 12.5339 10.1864 12.5339C11.1495 12.5339 11.9886 12.0088 12.4302 11.2317L12.4195 11.2503L14.6669 12.5422C13.7821 14.0616 12.1347 15.0893 10.244 15.1097L10.1864 15.1101C8.26729 15.1101 6.59235 14.0728 5.69995 12.5324C5.26431 11.7804 5.01506 10.9085 5.01506 9.97883C5.01506 7.14502 7.33026 4.84766 10.1863 4.84766L10.1864 4.84771Z"
        fill="white"
      />
    </svg>
  );
};

export default CSharpIcon;
