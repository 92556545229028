import { LAMBDATEST_PROMO_BANNER_HEIGHT } from '../constants/setting';

const initialState = {
  setting: {
    promoBannerHeight: 0,
  },
};

function settingReducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case LAMBDATEST_PROMO_BANNER_HEIGHT:
      return {
        ...state,
        setting: { ...state.setting, promoBannerHeight: payload },
      };
    default:
      return state;
  }
}

export default settingReducer;
