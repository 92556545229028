import React from 'react';

const Linux = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M2325 5110 c-123 -22 -244 -89 -346 -192 -122 -122 -168 -256 -176
-513 -6 -171 10 -635 28 -837 10 -116 10 -126 -10 -176 -39 -97 -140 -248
-382 -570 -71 -94 -138 -234 -234 -491 -53 -142 -108 -272 -141 -330 -64 -113
-83 -169 -91 -271 -6 -80 9 -160 30 -160 7 1 23 7 37 15 14 8 44 14 68 15 129
1 248 -124 549 -576 218 -327 263 -415 293 -577 28 -152 -28 -248 -173 -298
-102 -36 -175 -25 -497 69 -113 33 -317 83 -455 112 -264 55 -326 74 -345 110
-16 29 -8 62 37 168 52 120 52 183 -2 302 -22 49 -40 106 -43 136 -4 46 -2 53
23 72 24 19 40 22 130 22 137 0 196 18 256 79 51 50 69 97 68 171 -1 56 -17
103 -18 52 -1 -49 -25 -96 -71 -141 -58 -56 -111 -78 -215 -91 -149 -17 -164
-21 -195 -47 -27 -23 -30 -30 -30 -86 0 -34 9 -98 19 -143 38 -155 37 -166 -8
-301 -92 -272 -63 -302 381 -383 190 -35 288 -61 523 -140 236 -79 288 -92
362 -91 78 1 155 34 264 113 94 67 189 104 312 119 135 18 810 18 859 1 52
-19 84 -46 123 -108 43 -67 105 -108 196 -128 133 -31 294 -11 359 44 269 228
350 284 577 397 180 90 293 166 324 217 10 17 19 48 19 70 0 70 -38 110 -186
199 -64 39 -132 105 -157 153 -23 47 -47 127 -61 206 l-12 73 -18 -34 c-24
-46 -23 -166 2 -247 32 -102 71 -148 160 -189 138 -64 164 -80 193 -115 73
-87 7 -146 -288 -256 -189 -71 -277 -117 -388 -204 -207 -160 -267 -190 -377
-190 -70 0 -121 19 -166 63 -79 75 -97 160 -75 352 24 214 20 387 -12 535 -33
148 -31 244 5 329 49 113 123 91 198 -60 62 -125 118 -162 246 -161 108 0 182
37 263 129 52 60 111 120 184 187 36 35 50 86 56 217 11 230 -71 542 -228 865
-78 160 -133 243 -263 394 -238 278 -335 471 -402 799 -21 100 -28 173 -35
356 -10 279 -25 349 -117 530 -145 289 -383 430 -742 440 -74 2 -157 0 -185
-5z m608 -321 c75 -53 74 -79 -2 -79 -16 0 -47 -14 -71 -31 -31 -23 -45 -28
-54 -19 -8 8 -6 19 7 41 9 17 17 48 17 69 0 27 6 42 18 49 24 15 23 15 85 -30z
m-119 -411 c25 -8 59 -31 88 -59 62 -62 93 -155 93 -279 0 -119 -7 -137 -54
-150 -68 -18 -93 9 -66 73 19 46 19 92 0 149 -24 68 -63 100 -130 106 -51 4
-56 3 -89 -30 -19 -19 -39 -52 -45 -73 -12 -44 -14 -140 -2 -168 8 -21 146
-86 265 -126 56 -18 85 -34 99 -54 23 -30 24 -11 -15 -224 -16 -82 3 -143 76
-250 87 -128 142 -253 181 -413 14 -57 27 -85 55 -118 122 -138 196 -404 218
-778 14 -231 28 -256 138 -240 79 10 113 46 140 151 16 60 18 225 3 315 -24
151 -140 423 -250 588 -45 67 -47 71 -19 50 87 -64 219 -266 263 -403 35 -107
69 -300 69 -395 1 -83 -17 -201 -37 -252 -5 -13 4 -22 37 -39 51 -25 108 -67
108 -78 0 -5 -26 2 -58 15 -175 71 -371 16 -404 -113 -9 -38 -14 -43 -40 -43
-66 0 -121 -43 -155 -120 -26 -61 -28 -207 -3 -400 25 -200 24 -233 -21 -480
-39 -211 -38 -210 -57 -210 -25 0 -52 31 -52 60 0 15 16 80 36 146 42 139 57
304 32 338 -22 29 -52 12 -101 -57 -56 -78 -121 -139 -184 -170 -118 -60 -293
-98 -463 -100 -153 -1 -237 23 -324 92 -44 35 -65 38 -95 14 l-22 -18 26 -35
c38 -50 81 -139 89 -182 8 -42 -10 -93 -42 -121 -20 -19 -21 -19 -26 15 -3 18
-7 62 -10 97 -7 76 -35 140 -111 257 -30 46 -55 86 -55 89 0 2 17 9 38 15 43
14 87 52 109 94 20 39 14 133 -12 166 -10 14 -120 108 -243 210 -302 248 -458
392 -488 451 -75 149 -84 371 -19 454 14 18 15 15 8 -50 -12 -104 -6 -202 17
-276 28 -92 99 -177 136 -162 26 10 35 65 43 258 8 195 9 203 47 315 50 150
171 392 223 446 39 42 39 42 26 80 -34 94 -15 175 88 382 58 117 77 165 82
212 4 33 10 60 14 60 11 0 51 -46 106 -120 94 -127 145 -170 204 -170 41 0
135 51 271 147 63 45 160 106 215 135 84 46 100 59 100 79 0 20 -6 25 -33 27
-25 3 -60 -12 -150 -62 -196 -108 -294 -139 -394 -126 -61 9 -100 26 -147 66
-54 45 -44 51 84 46 70 -3 133 0 162 7 67 17 474 180 496 199 25 22 18 56 -13
60 -18 2 -34 -7 -58 -32 -37 -39 -127 -77 -299 -127 -147 -42 -317 -52 -385
-21 -25 11 -60 38 -77 60 -18 22 -52 54 -77 71 -24 18 -44 39 -44 48 0 36 35
86 84 117 28 18 82 64 120 102 l69 68 0 57 c-1 65 -31 142 -61 158 -55 29
-127 1 -152 -59 -26 -64 9 -194 56 -206 28 -7 30 -20 7 -46 -29 -32 -52 -22
-78 35 -54 118 -53 236 3 347 32 64 67 92 114 92 52 0 94 -26 123 -77 34 -58
46 -103 54 -200 l6 -80 90 -6 c50 -3 100 -11 112 -17 21 -12 21 -12 20 101 -2
102 0 117 24 168 52 110 173 163 293 127z m1 -239 c16 -20 25 -41 23 -57 -4
-37 -24 -38 -31 -2 -4 17 -18 41 -32 54 -24 23 -24 36 1 36 7 0 24 -14 39 -31z
m-679 -14 c16 -24 24 -89 12 -101 -14 -13 -28 9 -28 46 0 24 -5 40 -15 44 -19
7 -20 36 0 36 8 0 22 -11 31 -25z m1162 -620 c52 -11 71 -75 33 -109 -26 -23
-52 -14 -106 35 -56 51 -82 110 -66 145 l12 26 48 -45 c26 -24 62 -48 79 -52z
m-1783 -852 c-12 -49 -37 -94 -62 -110 -13 -9 -26 -14 -28 -11 -9 9 69 148 83
148 10 0 12 -7 7 -27z"
        />
        <path
          d="M2210 3937 c-14 -7 -26 -20 -28 -30 -5 -26 12 -20 36 13 13 17 21 30
20 30 -2 0 -14 -6 -28 -13z"
        />
        <path d="M2469 3930 c24 -20 51 -27 51 -12 0 16 -25 32 -49 32 l-26 -1 24 -19z" />
      </g>
    </svg>
  );
};

export { Linux };
