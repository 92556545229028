import React from 'react';

const Completed = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42ZM39 21C39 30.9411 30.9411 39 21 39C11.0589 39 3 30.9411 3 21C3 11.0589 11.0589 3 21 3C30.9411 3 39 11.0589 39 21ZM30.1192 16.9943C30.6683 16.374 30.6107 15.426 29.9904 14.8769C29.3701 14.3278 28.4221 14.3854 27.873 15.0057L17.955 26.2088L12.9823 21.8698C12.3581 21.3251 11.4105 21.3896 10.8659 22.0138C10.3212 22.638 10.3857 23.5856 11.0099 24.1302L17.0299 29.3831C17.6923 29.9612 18.6971 29.8964 19.2798 29.2381L30.1192 16.9943Z"
        fill="#818181"
      />
    </svg>
  );
};

export default Completed;
