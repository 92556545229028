import React from 'react';

const HandIcon = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M6.48822 5.38914L6.48822 1.43908C6.48822 1.31476 6.43854 1.19553 6.35012 1.10762C6.26169 1.01971 6.14176 0.970321 6.01671 0.970321C5.89165 0.970321 5.77172 1.01971 5.6833 1.10762C5.59487 1.19553 5.5452 1.31476 5.5452 1.43908L5.5452 4.72038L4.91651 4.72038L4.91651 0.501563C4.91651 0.440005 4.90432 0.37905 4.88062 0.322178C4.85693 0.265306 4.82219 0.21363 4.77841 0.170102C4.73463 0.126574 4.68265 0.0920456 4.62544 0.0684884C4.56823 0.0449311 4.50692 0.0328064 4.445 0.0328064C4.38308 0.0328064 4.32177 0.0449311 4.26456 0.0684884C4.20735 0.0920456 4.15538 0.126574 4.11159 0.170102C4.06781 0.21363 4.03308 0.265306 4.00938 0.322178C3.98569 0.37905 3.97349 0.440005 3.97349 0.501563L3.97349 4.72038L3.34481 4.72038L3.34481 1.43908C3.34481 1.31476 3.29513 1.19553 3.2067 1.10762C3.11828 1.01971 2.99835 0.970321 2.8733 0.970321C2.74824 0.970321 2.62831 1.01971 2.53989 1.10762C2.45146 1.19553 2.40178 1.31476 2.40178 1.43908L2.40178 5.34539L1.7731 5.34539L1.7731 2.6891C1.7731 2.56477 1.72342 2.44554 1.635 2.35764C1.54657 2.26973 1.42664 2.22034 1.30159 2.22034C1.17654 2.22034 1.05661 2.26973 0.968181 2.35764C0.879755 2.44554 0.830078 2.56477 0.830078 2.6891L0.830078 6.82603C0.830182 7.54156 1.07096 8.23651 1.51412 8.80033C1.95727 9.36414 2.57735 9.76443 3.2757 9.93754C3.97406 10.1106 4.71059 10.0466 5.36812 9.75562C6.02565 9.46463 6.56642 8.96341 6.90441 8.33168L8.7609 4.86069C8.79663 4.79536 8.81907 4.72367 8.82693 4.64972C8.83478 4.57576 8.82791 4.50099 8.80671 4.42968C8.7855 4.35837 8.75038 4.2919 8.70333 4.23409C8.65629 4.17627 8.59825 4.12823 8.53254 4.09271C8.46682 4.05719 8.39471 4.03489 8.32032 4.02708C8.24593 4.01926 8.17073 4.02609 8.09899 4.04717C8.02726 4.06826 7.96041 4.10318 7.90225 4.14995C7.84409 4.19671 7.79577 4.25441 7.76004 4.31975L7.07729 5.54133C6.92012 5.82321 6.48822 5.71195 6.48822 5.38945L6.48822 5.38914Z"
        fill="white"
      />
    </svg>
  );
};

export { HandIcon };
