import React from 'react';

const FilterUsers = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <filter>users icon</filter>
      <path
        d="M13.125 13.125H12.25V10.9375C12.2493 10.3576 12.0186 9.80156 11.6085 9.39147C11.1984 8.98139 10.6424 8.75069 10.0625 8.75V7.875C10.8744 7.87593 11.6529 8.19888 12.227 8.77301C12.8011 9.34714 13.1241 10.1256 13.125 10.9375V13.125Z"
        fill="#4A4A4A"
      />
      <path
        d="M9.625 13.125H8.75V10.9375C8.74931 10.3576 8.51861 9.80156 8.10853 9.39147C7.69844 8.98139 7.14245 8.75069 6.5625 8.75H3.9375C3.35755 8.75069 2.80156 8.98139 2.39147 9.39147C1.98139 9.80156 1.75069 10.3576 1.75 10.9375V13.125H0.875V10.9375C0.875926 10.1256 1.19888 9.34714 1.77301 8.77301C2.34714 8.19888 3.12556 7.87593 3.9375 7.875H6.5625C7.37444 7.87593 8.15286 8.19888 8.72699 8.77301C9.30112 9.34714 9.62407 10.1256 9.625 10.9375V13.125Z"
        fill="#4A4A4A"
      />
      <path
        d="M8.75 0.875V1.75C9.33016 1.75 9.88656 1.98047 10.2968 2.3907C10.707 2.80094 10.9375 3.35734 10.9375 3.9375C10.9375 4.51766 10.707 5.07406 10.2968 5.4843C9.88656 5.89453 9.33016 6.125 8.75 6.125V7C9.56223 7 10.3412 6.67734 10.9155 6.10301C11.4898 5.52868 11.8125 4.74973 11.8125 3.9375C11.8125 3.12527 11.4898 2.34632 10.9155 1.77199C10.3412 1.19766 9.56223 0.875 8.75 0.875Z"
        fill="#4A4A4A"
      />
      <path
        d="M5.25 1.75C5.68265 1.75 6.10558 1.87829 6.46531 2.11866C6.82504 2.35903 7.10542 2.70067 7.27099 3.10038C7.43655 3.50009 7.47987 3.93993 7.39547 4.36426C7.31106 4.78859 7.10272 5.17837 6.7968 5.4843C6.49087 5.79022 6.10109 5.99856 5.67676 6.08297C5.25243 6.16737 4.81259 6.12405 4.41288 5.95849C4.01317 5.79292 3.67153 5.51254 3.43116 5.15281C3.1908 4.79308 3.0625 4.37015 3.0625 3.9375C3.0625 3.35734 3.29297 2.80094 3.70321 2.3907C4.11344 1.98047 4.66984 1.75 5.25 1.75ZM5.25 0.875C4.6443 0.875 4.05219 1.05461 3.54857 1.39112C3.04494 1.72764 2.65241 2.20593 2.42062 2.76553C2.18883 3.32513 2.12818 3.9409 2.24635 4.53496C2.36451 5.12903 2.65619 5.67472 3.08449 6.10301C3.51279 6.53131 4.05847 6.82299 4.65254 6.94115C5.2466 7.05932 5.86237 6.99867 6.42197 6.76688C6.98157 6.53509 7.45987 6.14256 7.79638 5.63893C8.13289 5.13531 8.3125 4.54321 8.3125 3.9375C8.3125 3.12527 7.98985 2.34632 7.41552 1.77199C6.84119 1.19766 6.06223 0.875 5.25 0.875Z"
        fill="#4A4A4A"
      />
    </svg>
  );
};

export { FilterUsers };
