import React from 'react';

const WarningYellowOutlined = ({
  width = '16',
  height = '16',
  className,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 15"
      fill="none"
      role="presentation"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22056 1.75324C8.12638 1.57704 7.87377 1.57704 7.77959 1.75325L1.69795 13.1318C1.60894 13.2984 1.72961 13.4997 1.91843 13.4997H14.0817C14.2706 13.4997 14.3912 13.2984 14.3022 13.1318L8.22056 1.75324ZM6.4567 1.04618C7.11596 -0.187269 8.8842 -0.187271 9.54346 1.04618L15.6251 12.4248C16.2482 13.5905 15.4035 14.9997 14.0817 14.9997H1.91843C0.596638 14.9997 -0.248009 13.5905 0.375058 12.4248L6.4567 1.04618ZM9.00008 10.9997C9.00008 11.552 8.55237 11.9997 8.00008 11.9997C7.4478 11.9997 7.00008 11.552 7.00008 10.9997C7.00008 10.4474 7.4478 9.99966 8.00008 9.99966C8.55237 9.99966 9.00008 10.4474 9.00008 10.9997ZM8.75008 5.74966C8.75008 5.33545 8.41429 4.99966 8.00008 4.99966C7.58587 4.99966 7.25008 5.33545 7.25008 5.74966V8.24966C7.25008 8.66387 7.58587 8.99966 8.00008 8.99966C8.41429 8.99966 8.75008 8.66387 8.75008 8.24966V5.74966Z"
        fill="#9A6700"
      />
    </svg>
  );
};

export default WarningYellowOutlined;
