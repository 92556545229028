import React from 'react';

const CloseWhiteIcon = ({
  width,
  height,
  className,
  onClick,
  color = '#A7A7A7',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      viewBox="0 0 12 12"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      aria-hidden="true"
    >
      <path
        d="M11.3536 1.35355C11.5488 1.15829 11.5488 0.841709 11.3536 0.646447C11.1583 0.451184 10.8417 0.451184 10.6464 0.646447L11.3536 1.35355ZM0.646447 10.6464C0.451184 10.8417 0.451184 11.1583 0.646447 11.3536C0.841709 11.5488 1.15829 11.5488 1.35355 11.3536L0.646447 10.6464ZM1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L10.6464 11.3536ZM10.6464 0.646447L0.646447 10.6464L1.35355 11.3536L11.3536 1.35355L10.6464 0.646447ZM0.646447 1.35355L10.6464 11.3536L11.3536 10.6464L1.35355 0.646447L0.646447 1.35355Z"
        fill={color}
      />
    </svg>
  );
};

export { CloseWhiteIcon };
