import Typography from '../Atoms/Typography';
import { Icon } from '../Icons';
import PlanExpired from '../../assets/images/PlanExpired.svg';
import LimitExhausted from '../../assets/images/LimitExhausted.svg';
import hypertestFeature from '../../assets/images/onboarding/hypertest-feature.svg';
import tenPercentageThreshold from '../../assets/images/tenPercentageThreshold.svg';
import sixtyPercentageThreshold from '../../assets/images/sixtyPercentageThreshold.svg';
import ninetyPercentageThreshold from '../../assets/images/ninetyPercentageThreshold.svg';
import PlanAboutToExpire from '../../assets/images/PlanAboutToExpire.svg';
import {
  HYPERTEST_TRIAL_FEATURELIST,
  HYPERTEST_TRIAL_CUSTOM_FEATURELIST,
} from '../../utils/messages';
import { openZohoSalesIqChat } from '../../utils/common';

import { isEnterPressed } from 'utils/keyboard';
import useRemainingMinutes from 'hooks/useRemainingMinutes';

import './style.css';
import LTButton from 'new-theme/components/LTComponents/LTButton/LTButton';

function TrialMainComponent({
  type,
  threshold,
  handleUpgradeClick,
  handleContactSaleClick,
  isCustomTrial,
  userPlan,
}) {
  const { kFormatedMinutes } = useRemainingMinutes();
  let featureList = isCustomTrial
    ? HYPERTEST_TRIAL_CUSTOM_FEATURELIST
    : HYPERTEST_TRIAL_FEATURELIST;
  let thresholdLimitImage = LimitExhausted;
  if (threshold === 10) {
    thresholdLimitImage = tenPercentageThreshold;
  }
  if (threshold === 60) {
    thresholdLimitImage = sixtyPercentageThreshold;
  }
  if (threshold === 90) {
    thresholdLimitImage = ninetyPercentageThreshold;
  }

  let bodyComponent = (
    <div className="ltw-flex ltw-flex-col ltw-items-center ltw-justify-between  ltw-px-40px dialog-padding-24 lt-trial-comp lt-trial-f">
      <Typography
        variant="h2"
        color="ltw-text-black"
        fontType="ltw-font-bold"
        fontSize="ltw-text-32"
        lineHeight="ltw-leading-33"
        id="lt-modal-title"
      >
        You’re currently on Trial
      </Typography>
      <span className="ltw-font-normal ltw-text-13 ltw-leading-19 ltw-text-black-secondary-2 align-dialog trial-title-dailog">
        <Icon
          name={'thunder'}
          width="14"
          height="16"
          className="ltw-cursor-pointer ltw-inline-block ltw-my-6px "
          alt=""
        />
        Lightning fast Test Automation Cloud to speed up releases and cut down
        time to market without breaking the bank
      </span>
      <img
        src={hypertestFeature}
        width="321"
        height="229"
        role="presentation"
        alt=""
      />
      <ul className="trial-list">
        {featureList.map((feature, index) => {
          return (
            <li className="ltw-flex ltw-items-center ltw-gap-5px" key={index}>
              <span className="ltw-h-5px ltw-w-5px ltw ltw-rounded-full ltw-inline-block ltw-bg-black ltw-mx-5px"></span>
              <span className="ltw-text-15 ltw-text-secondary ltw-font-normal ltw-leading-30">
                {index === 1
                  ? `Blazing fast testing for ${kFormatedMinutes} minutes`
                  : feature}
              </span>
            </li>
          );
        })}
      </ul>
      <div className="ltw-flex ltw-justify-center ltw-gap-20px ltw-mb-20px ltw-mt-20px lt-trial-btn-g">
        <LTButton
          onClick={() => handleUpgradeClick('trial_popup')}
          variant="primary"
        >
          <span>Upgrade Now</span>
          <Icon
            name={'right-arrow'}
            width="14"
            height="16"
            className="ltw-cursor-pointer"
          />
        </LTButton>
        <LTButton
          label="Contact Sales"
          onClick={handleContactSaleClick('trial_popup')}
        ></LTButton>
      </div>
    </div>
  );
  if (type === 'PLAN_EXPIRED') {
    bodyComponent = (
      <div className="ltw-flex ltw-flex-col ltw-items-center ltw-justify-between ltw-gap-10px ltw-px-40px ltw-py-10px dialog-padding-24 lt-trial-comp">
        <img
          src={PlanExpired}
          width="415"
          height="327"
          role="presentation"
          alt=""
        />
        <Typography
          variant="h2"
          color="ltw-text-black"
          fontType="ltw-font-bold"
          fontSize="ltw-text-32"
          lineHeight="ltw-leading-33"
          id="lt-modal-title"
        >
          Your Plan has expired
        </Typography>
        <span
          style={{ textAlign: 'center' }}
          className="ltw-leading-19 ltw-text-13 ltw-font-normal ltw-text-black-secondary-2"
        >
          Hope you’ve enjoyed the blazing fast testing!<br></br> Please renew or
          upgrade your Hyperexecute plan to continue.
        </span>
        <div className="ltw-flex ltw-justify-center ltw-gap-20px ltw-mb-20px ltw-mt-15px lt-trial-btn-g">
          <LTButton
            onClick={() => handleUpgradeClick('plan_expired')}
            // onKeyDown={openZohoSalesIqChat}
            variant="primary"
            id="cancel212"
          >
            <span> Renew Subscription</span>
            <Icon
              name={'right-arrow'}
              width="14"
              height="16"
              className="ltw-cursor-pointer"
            />
          </LTButton>
          <LTButton
            onClick={handleContactSaleClick('plan_expired')}
            variant="outline"
            id="cancel"
          >
            Contact Sales
          </LTButton>
        </div>
      </div>
    );
  }
  if (type === 'PAID_NINTY_PER_LIMIT_EXHAUSTED_THRESHOLD') {
    bodyComponent = (
      <div className="ltw-flex ltw-flex-col ltw-items-center ltw-justify-between ltw-gap-10px ltw-px-40px ltw-py-10px dialog-padding-24 lt-trial-comp">
        <img
          src={PlanAboutToExpire}
          width="415"
          height="327"
          role="presentation"
          alt=""
        />
        <Typography
          variant="h2"
          color="ltw-text-black"
          fontType="ltw-font-bold"
          fontSize="ltw-text-28"
          lineHeight="ltw-leading-33"
          id="lt-modal-title"
        >
          Your plan is about to expire
        </Typography>
        <span
          style={{ textAlign: 'center' }}
          className="ltw-leading-19 ltw-text-13 ltw-font-normal ltw-text-black-secondary-2"
        >
          Hope you’re enjoying the blazing fast testing! <br></br> Please renew
          or upgrade your Hyperexecute plan now.
        </span>
        <div className="ltw-flex ltw-justify-center ltw-gap-20px ltw-mb-20px ltw-mt-15px lt-trial-btn-g">
          <LTButton
            onClick={() => handleUpgradeClick('paid_plan_expired_ninty')}
            variant="primary"
          >
            <span>Renew Subscription</span>
            <Icon
              name={'right-arrow'}
              width="14"
              height="16"
              className="ltw-cursor-pointer"
              role="presentation"
              alt=""
            />
          </LTButton>
          <LTButton
            onClick={handleContactSaleClick('paid_plan_expired_ninty')}
            onKeyDown={(e) => isEnterPressed(e) && openZohoSalesIqChat()}
            variant="outline"
          >
            Contact Sales
          </LTButton>
        </div>
      </div>
    );
  }
  if (type === 'PAID_PLAN_EXPIRED') {
    bodyComponent = (
      <div className="ltw-flex ltw-flex-col ltw-items-center ltw-justify-between ltw-gap-10px ltw-px-40px ltw-py-10px dialog-padding-24 lt-trial-comp">
        <img
          src={PlanExpired}
          width="415"
          height="327"
          role="presentation"
          alt=""
        />
        <Typography
          variant="h2"
          color="ltw-text-black"
          fontType="ltw-font-bold"
          fontSize="ltw-text-28"
          lineHeight="ltw-leading-33"
          id="lt-modal-title"
        >
          Your Current plan has expired
        </Typography>
        <span
          style={{ textAlign: 'center' }}
          className="ltw-leading-19 ltw-text-13 ltw-font-normal ltw-text-black-secondary-2"
        >
          Hope you’ve enjoyed the blazing fast testing!<br></br> Please renew or
          upgrade your Hyperexecute plan to continue.
        </span>
        <div className="ltw-flex ltw-justify-center ltw-gap-20px ltw-mb-20px ltw-mt-15px lt-trial-btn-g">
          <LTButton
            onClick={() => handleUpgradeClick('paid_plan_expired')}
            variant="primary"
          >
            <span> Renew Subscription</span>
            <Icon
              name={'right-arrow'}
              width="14"
              height="16"
              className="ltw-cursor-pointer"
              role="presentation"
              alt=""
            />
          </LTButton>
          <LTButton
            onClick={handleContactSaleClick('paid_plan_expired')}
            variant="outline"
          >
            Contact Sales
          </LTButton>
        </div>
      </div>
    );
  }
  if (type === 'LIMIT_EXHAUSTED_THRESHOLD') {
    bodyComponent = (
      <div className="ltw-flex ltw-flex-col ltw-items-center ltw-justify-between ltw-gap-10px ltw-px-40px ltw-py-10px dialog-padding-24 lt-trial-comp">
        <img
          src={thresholdLimitImage}
          width="458"
          height="339"
          role="presentation"
          alt=""
        />
        <Typography
          variant="h2"
          color="ltw-text-black"
          fontType="ltw-font-bold"
          fontSize="ltw-text-30"
          lineHeight="ltw-leading-33"
          id="lt-modal-title"
        >
          You've used {threshold}% of your limit
        </Typography>
        <span
          style={{ textAlign: 'center' }}
          className="ltw-leading-19 ltw-text-13 ltw-font-normal ltw-text-black-secondary-2"
        >
          Hope you’re enjoying the blazing fast testing! <br></br> Please
          upgrade your Hyperexecute plan now.
        </span>
        <div className="ltw-flex ltw-justify-center ltw-gap-20px ltw-mb-20px ltw-mt-15px lt-trial-btn-g">
          <LTButton
            onClick={() => handleUpgradeClick(`limit_exhausted_${threshold}`)}
            variant="primary"
          >
            <span> Upgrade Now</span>
            <Icon
              name={'right-arrow'}
              width="14"
              height="16"
              className="ltw-cursor-pointer"
              role="presentation"
              alt=""
            />
          </LTButton>
          <LTButton
            onClick={handleContactSaleClick(`limit_exhausted_${threshold}`)}
            variant="outline"
          >
            <span> Contact Sales</span>
          </LTButton>
        </div>
      </div>
    );
  }
  if (type === 'LIMIT_EXHAUSTED') {
    bodyComponent = (
      <div className="ltw-flex ltw-flex-col ltw-items-center ltw-justify-between ltw-gap-10px ltw-px-40px ltw-py-10px dialog-padding-24 lt-trial-comp">
        <img
          src={LimitExhausted}
          width="458"
          height="339"
          role="presentation"
          alt=""
        />
        <Typography
          variant="h2"
          color="ltw-text-black"
          fontType="ltw-font-bold"
          fontSize="ltw-text-32"
          lineHeight="ltw-leading-33"
          id="lt-modal-title"
        >
          Your limit is exhausted
        </Typography>
        <span
          style={{ textAlign: 'center' }}
          className="ltw-leading-19 ltw-text-13 ltw-font-normal ltw-text-black-secondary-2"
        >
          Hope you've enjoyed the trial run! <br></br> Please upgrade to a
          Hyperexecute plan to continue.
        </span>
        <div className="ltw-flex ltw-justify-center ltw-gap-20px ltw-mb-20px ltw-mt-15px lt-trial-btn-g">
          <LTButton
            onClick={() => handleUpgradeClick('limit_exhausted')}
            variant="primary"
          >
            <span>Upgrade Now</span>
            <Icon
              name={'right-arrow'}
              width="14"
              height="16"
              className="ltw-cursor-pointer"
              alt="Hypertest Feature"
            />
          </LTButton>
          <LTButton
            onClick={handleContactSaleClick('limit_exhausted')}
            variant="outline"
          >
            Contact Sales
          </LTButton>
        </div>
      </div>
    );
  }
  // if (type === 'TRIAL_FEATURE') {
  //   bodyComponent = bodyComponent;
  // }
  return bodyComponent;
}
export default TrialMainComponent;
