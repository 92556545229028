import { LTIconButton } from '@lambdatestincprivate/lt-components';
import usePlan from '../../hooks/usePlan';
import Typography from '../Atoms/Typography';
import { Icon } from '../Icons';
import { CircularProgressbar } from 'react-circular-progressbar';
import './style.css';

import 'react-circular-progressbar/dist/styles.css';
import { useEffect } from 'react';
function PlanInfo(props) {
  let { className = '', page } = props;
  let {
    openTrialFeaturePopUp,
    isTrialExpired,
    isLimitExhousted,
    isTrialUser,
    remainingMinutes,
    daysPercentage,
  } = usePlan(true);

  useEffect(() => {
    setTimeout(() => {
      const elms = document.getElementsByClassName('CircularProgressbar');

      [...elms].forEach((element) => {
        element.setAttribute('role', 'img');
        element.setAttribute('aria-hidden', 'true');
      });
    }, 500);
  }, [isTrialExpired, isLimitExhousted]);

  if (!isTrialUser) {
    return null;
  }
  let leftTime = remainingMinutes;
  let leftTimeType = 'mins';
  let trailColor = 'rgb(14, 186, 197)';
  if (daysPercentage >= 60) {
    trailColor = '#F45C47';
  }

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(0) + 'K'
      : Math.sign(num) * Math.abs(num);
  }

  return (
    <div
      className={`${
        page !== 'onboard'
          ? 'ltw-border-r ltw-border-l ltw-border-gray-1 ltw-px-20px ltw-py-12px'
          : 'ltw-flex ltw-gap-13px ltw-items-center ltw-bg-plan-info-bg ltw-rounded ltw-py-13px ltw-px-14px ltw-border ltw-border-plan-info-border plan-info-container'
      } ${className} hyp-common-header-outer-wrapper`}
    >
      <div className="ltw-flex ltw-items-center ltw-gap-8px hyp-common-header-inner-wrapper">
        {!isTrialExpired && !isLimitExhousted ? (
          <div
            style={{ width: 20, height: 20 }}
            className="circular-progress-bar-container"
          >
            <CircularProgressbar
              value={daysPercentage}
              strokeWidth={8}
              styles={{
                // Customize the root svg element
                root: {},
                // Customize the path, i.e. the "completed progress"
                path: {
                  // Path color
                  stroke: 'rgba(241, 240, 240)',
                },
                // Customize the circle behind the path, i.e. the "total progress"
                trail: {
                  // Trail color
                  stroke: trailColor,
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'round',
                },
                text: {
                  fill: trailColor,
                  fontSize: '20px',
                },
              }}
            />
          </div>
        ) : null}
        {isTrialExpired || isLimitExhousted ? (
          <Icon
            name={'alert'}
            width="20"
            height="19"
            className="ltw-text-black"
          />
        ) : null}

        {!isTrialExpired && !isLimitExhousted && (
          <div className="ltw-flex ltw-gap-5px ltw-items-center">
            <Typography
              variant="h2"
              color="ltw-text-plan-info"
              fontType="ltw-font-normal"
              fontSize="ltw-text-14"
              lineHeight="ltw-leading-17"
              className="hyp-common-header-mins-remaining-text-desktop"
            >
              {`${kFormatter(leftTime)}`} {leftTimeType} remaining
            </Typography>

            <LTIconButton
              className="plan-info-button-on-header"
              onClick={() => {
                openTrialFeaturePopUp();
              }}
              id="license-info-btn"
              ariaLabel="License Info"
              variant="invisible"
              size="small"
              icon={() => (
                <Icon
                  name={'info-gray'}
                  width="14"
                  height="14"
                  className="ltw-cursor-pointer ltw-mt-1px"
                />
              )}
            ></LTIconButton>
          </div>
        )}
        {isTrialExpired && (
          <Typography
            variant="h2"
            color="ltw-text-plan-info"
            fontType="ltw-font-medium"
            fontSize="ltw-text-16"
            lineHeight="ltw-leading-16"
            extraClasses="hyp-plan-info-in-header"
          >
            Hope you've enjoyed your trial run! Your trial has expired
          </Typography>
        )}
        {isLimitExhousted && !isTrialExpired && (
          <Typography
            variant="h2"
            color="ltw-text-plan-info"
            fontType="ltw-font-medium"
            fontSize="ltw-text-16"
            lineHeight="ltw-leading-16"
            extraClasses="hyp-plan-info-in-header"
          >
            Hope you've enjoyed your trial run! Your limit is exhausted
          </Typography>
        )}
      </div>
    </div>
  );
}
export default PlanInfo;
