import React from 'react';

const SelectedCircle = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456725 3.7039C0.00259972 4.80025 -0.11622 6.00665 0.115291 7.17054C0.346802 8.33443 0.918247 9.40352 1.75736 10.2426C2.59648 11.0818 3.66557 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0891 10.3295 10.3201 10.9888 9.33342C11.6481 8.34672 12 7.18669 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0Z"
        fill="#0EBAC5"
      />
      <path
        d="M5.00661 8.44444L8.76596 4.68544L8.77168 4.69117C8.80926 4.65357 8.83906 4.60894 8.85939 4.55982C8.87973 4.51069 8.89019 4.45805 8.89019 4.40488C8.89019 4.35171 8.87973 4.29906 8.85939 4.24994C8.83906 4.20082 8.80926 4.15618 8.77168 4.11859C8.73411 4.08099 8.68951 4.05117 8.64042 4.03082C8.59133 4.01047 8.53872 4 8.48558 4C8.43245 4 8.37984 4.01047 8.33075 4.03082C8.28166 4.05117 8.23706 4.08099 8.19948 4.11859L5.01233 7.30501L3.35868 5.65024C3.2828 5.57431 3.17988 5.53166 3.07258 5.53166C2.96527 5.53166 2.86235 5.57431 2.78648 5.65024C2.7106 5.72617 2.66797 5.82915 2.66797 5.93653C2.66797 6.04391 2.7106 6.1469 2.78648 6.22283L5.00661 8.44444Z"
        fill="white"
      />
    </svg>
  );
};

export { SelectedCircle };
