const LinkArrowIcon = ({ width = 8, height = 8, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M7.18946 6.17949L7.22266 1.10156L1.87874 1.07002M7.22266 1.10156L0.770123 7.15723L7.22266 1.10156Z"
        stroke="#0EBAC5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkArrowIcon;
