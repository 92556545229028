import React from 'react';

const PJError = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <g id="Group 1000002578">
        <path
          id=""
          d="M6 10.5C6 10.2239 6.22386 10 6.5 10H7.375C7.65114 10 7.875 10.2239 7.875 10.5V11.5C7.875 11.7761 7.65114 12 7.375 12H6.5C6.22386 12 6 11.7761 6 11.5V10.5ZM6 2.5C6 2.22386 6.22386 2 6.5 2H7.375C7.65114 2 7.875 2.22386 7.875 2.5V7.5C7.875 7.77614 7.65114 8 7.375 8H6.5C6.22386 8 6 7.77614 6 7.5V2.5Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default PJError;
