import React from 'react';

const ErrorStatus = ({ width = 20, height = 20, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      version="1.0"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="10" cy="10" r="8" fill="#CF222E" />
      <path
        d="M9 12.9C9 12.6239 9.22386 12.4 9.5 12.4H10C10.2761 12.4 10.5 12.6239 10.5 12.9V13.5C10.5 13.7761 10.2761 14 10 14H9.5C9.22386 14 9 13.7761 9 13.5V12.9ZM9 6.5C9 6.22386 9.22386 6 9.5 6H10C10.2761 6 10.5 6.22386 10.5 6.5V10.3C10.5 10.5761 10.2761 10.8 10 10.8H9.5C9.22386 10.8 9 10.5761 9 10.3V6.5Z"
        fill="white"
      />
    </svg>
  );
};

export default ErrorStatus;
