const Compare = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 12 12"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M1.4742 0.364746C0.859809 0.364746 0.367188 0.857367 0.367188 1.47176V7.56034C0.367188 8.17473 0.859809 8.66735 1.4742 8.66735H2.58122V7.56034H1.4742V1.47176H7.56278V2.57877H8.66979V1.47176C8.66979 0.857367 8.17717 0.364746 7.56278 0.364746H1.4742ZM4.79524 3.68579C4.18085 3.68579 3.68823 4.17841 3.68823 4.7928V5.89982H4.79524V4.7928H5.90226V3.68579H4.79524ZM7.00927 3.68579V4.7928H7.56278V5.34631H8.66979V3.68579H7.00927ZM9.77681 3.68579V4.7928H10.8838V10.8814H4.79524V9.77437H3.68823V10.8814C3.68823 11.4958 4.18085 11.9884 4.79524 11.9884H10.8838C11.4982 11.9884 11.9908 11.4958 11.9908 10.8814V4.7928C11.9908 4.17841 11.4982 3.68579 10.8838 3.68579H9.77681ZM7.56278 6.45332V7.56034H6.45576V8.66735H7.56278C8.17717 8.66735 8.66979 8.17473 8.66979 7.56034V6.45332H7.56278ZM3.68823 7.00683V8.66735H5.34875V7.56034H4.79524V7.00683H3.68823Z"
        fill={color ? color : '#666666'}
      />
    </svg>
  );
};

export default Compare;
