import React from 'react';

const Schedule = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      role="presentation"
      aria-hidden="true"
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.63636 3.6H12.3636C12.8835 3.6 13.4 4.08216 13.4 4.8V13.2C13.4 13.9179 12.8835 14.4 12.3636 14.4H3.63636C3.11653 14.4 2.6 13.9179 2.6 13.2V4.8C2.6 4.08217 3.11653 3.6 3.63636 3.6Z"
        stroke="#666666"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 6L13 6"
        stroke="#666666"
        stroke-width="1.2"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path
        d="M5 3.75V1.75"
        stroke="#666666"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 3.75V1.75"
        stroke="#666666"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 8.25V10.3269L9.17308 11.5"
        stroke="#666666"
        stroke-width="1.15"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Schedule;
