import {
  TEST_COMAPARISON_LIST_REQUEST,
  TEST_COMAPARISON_LIST_SUCCESS,
  TEST_COMAPARISON_LIST_FAILED,
  TEST_COMPARISON_RESULT_FAILED,
  TEST_COMPARISON_RESULT_SUCCESS,
  TEST_COMPARISON_RESULT_REQUEST,
  TEST_COMPARISON_RESULT_EMPTY,
  TEST_INFO_RESULT_EMPTY,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_FAILED,
  CHANGE_STATUS_REQUEST,
  TEST_FILTER_REQUEST,
  TEST_FILTER_SUCCESS,
  TEST_FILTER_FAILED,
  TEST_COMPARISON_RESULT_UNMOUNT,
} from '../constants/TestComaprison';

const TestComparisonDefaultState = {
  loading: true,
  favoriteloading: false,
  errorMsg: '',
  count: 0,
  data: [],
  metaData: {
    isDemoProject: true,
    projectId: '',
    projectName: '',
    buildName: '',
    buildId: '',
  },
};

export const GetTestComparisonListsReducer = (
  state = TestComparisonDefaultState,
  action
) => {
  switch (action.type) {
    case TEST_COMAPARISON_LIST_REQUEST:
      return { ...state, loading: true };
    case TEST_COMAPARISON_LIST_SUCCESS:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        metaData: action.payload.metaData,
      };
    case TEST_COMAPARISON_LIST_FAILED:
      return { ...state, loading: false, errorMsg: action.payload };
    case TEST_INFO_RESULT_EMPTY:
      return {
        ...state,
        loading: false,
        favoriteloading: false,
        errorMsg: '',
        count: 0,
        data: [],
        metaData: {
          isDemoProject: false,
          projectId: '',
          projectName: '',
          buildName: '',
          buildId: '',
        },
      };
    case CHANGE_STATUS_REQUEST:
      return { ...state };
    case CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.map((current) => {
          if (
            current.images.toBeCompared.screenshotID ===
            action.payload.tobecomparedid
          ) {
            return {
              ...current,
              testStatus: action.payload.status,
              approvedBy: action.payload.approvedBy,
            };
          } else {
            return {
              ...current,
            };
          }
        }),
      };
    case CHANGE_STATUS_FAILED:
      return { ...state, resultloading: false, errorMsg: action.payload };
    case TEST_FILTER_REQUEST:
      return { ...state, loading: true };
    case TEST_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case TEST_FILTER_FAILED:
      return { ...state, loading: false, errorMsg: action.payload };
    default:
      return state;
  }
};

const TestComaprisonListDefaultState = {
  resultloading: true,
  errorMsg: '',
  count: 0,
  data: {},
};

export const GetTestComparisonResultReducer = (
  state = TestComaprisonListDefaultState,
  action
) => {
  switch (action.type) {
    case TEST_COMPARISON_RESULT_REQUEST:
      return { ...state, resultloading: true };
    case TEST_COMPARISON_RESULT_SUCCESS:
      return { ...state, resultloading: false, data: action.payload };
    case TEST_COMPARISON_RESULT_FAILED:
      return { ...state, resultloading: false, errorMsg: action.payload };
    case TEST_COMPARISON_RESULT_EMPTY:
      return { ...state, resultloading: false, data: {}, errorMsg: '' };
    case TEST_COMPARISON_RESULT_UNMOUNT:
      return { ...state, resultloading: true, data: {}, errorMsg: '' };
    default:
      return state;
  }
};
