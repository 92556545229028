import React from 'react';

const GithubSupport = ({
  width = '16',
  height = '16',
  className,
  color,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M10 14.6673V12.0007C10.0928 11.1655 9.85329 10.3274 9.33334 9.66732C11.3333 9.66732 13.3333 8.33398 13.3333 6.00065C13.3867 5.16732 13.1533 4.34732 12.6667 3.66732C12.8533 2.90065 12.8533 2.10065 12.6667 1.33398C12.6667 1.33398 12 1.33398 10.6667 2.33398C8.90667 2.00065 7.09334 2.00065 5.33334 2.33398C4 1.33398 3.33334 1.33398 3.33334 1.33398C3.13334 2.10065 3.13334 2.90065 3.33334 3.66732C2.84792 4.34457 2.61232 5.16918 2.66667 6.00065C2.66667 8.33398 4.66667 9.66732 6.66667 9.66732C6.40667 9.99398 6.21334 10.3673 6.1 10.7673C5.98667 11.1673 5.95334 11.5873 6 12.0007V14.6673"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66667 12.3333C2.66 13.6667 2.33333 11 1 11"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { GithubSupport };
