import React from 'react';

const Duration = ({ width = 16, height = 16, className, color, ...rest }) => {
  return (
    <svg
      role="presentation"
      aria-hidden="true"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      color={color}
      {...rest}
    >
      <path
        d="M14.375 8C14.375 11.5208 11.5208 14.375 8 14.375C4.47918 14.375 1.625 11.5208 1.625 8C1.625 4.47918 4.47918 1.625 8 1.625C11.5208 1.625 14.375 4.47918 14.375 8Z"
        stroke="#666666"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
      <path
        d="M8 4.81836V8.00023L10.25 9.25024"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { Duration };
