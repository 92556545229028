import React from 'react';

const CrossBlack = ({ width = 16, height = 16, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      color={color}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.71934 3.72C3.85996 3.57955 4.05059 3.50066 4.24934 3.50066C4.44809 3.50066 4.63871 3.57955 4.77934 3.72L7.99934 6.94L11.2193 3.72C11.288 3.64631 11.3708 3.58721 11.4628 3.54622C11.5548 3.50523 11.6541 3.48319 11.7548 3.48141C11.8555 3.47963 11.9555 3.49816 12.0489 3.53588C12.1423 3.5736 12.2271 3.62974 12.2983 3.70096C12.3696 3.77218 12.4257 3.85702 12.4634 3.9504C12.5011 4.04379 12.5197 4.14382 12.5179 4.24452C12.5161 4.34523 12.4941 4.44454 12.4531 4.53654C12.4121 4.62854 12.353 4.71134 12.2793 4.78L9.05934 8L12.2793 11.22C12.353 11.2887 12.4121 11.3715 12.4531 11.4635C12.4941 11.5555 12.5161 11.6548 12.5179 11.7555C12.5197 11.8562 12.5011 11.9562 12.4634 12.0496C12.4257 12.143 12.3696 12.2278 12.2983 12.299C12.2271 12.3703 12.1423 12.4264 12.0489 12.4641C11.9555 12.5018 11.8555 12.5204 11.7548 12.5186C11.6541 12.5168 11.5548 12.4948 11.4628 12.4538C11.3708 12.4128 11.288 12.3537 11.2193 12.28L7.99934 9.06L4.77934 12.28C4.63716 12.4125 4.44911 12.4846 4.25481 12.4812C4.06051 12.4777 3.87513 12.399 3.73771 12.2616C3.6003 12.1242 3.52159 11.9388 3.51816 11.7445C3.51473 11.5502 3.58686 11.3622 3.71934 11.22L6.93934 8L3.71934 4.78C3.57889 4.63938 3.5 4.44875 3.5 4.25C3.5 4.05125 3.57889 3.86063 3.71934 3.72Z"
        fill="#666666"
      />
    </svg>
  );
};

export default CrossBlack;
