import React from 'react';

const DeleteIcon = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M2.67999 4.5L3.70464 14.1061C3.75886 14.6144 4.18777 15 4.699 15H11.861C12.3722 15 12.8011 14.6144 12.8553 14.1061L13.88 4.5"
        stroke="#666"
        strokeLinecap="round"
      />
      <path d="M6.03998 6L6.59998 11.5" stroke="#666" strokeLinecap="round" />
      <path d="M10.52 6L9.94727 11.4697" stroke="#666" strokeLinecap="round" />
      <path
        d="M1 3H15M6.04 2.5V2C6.04 1.44772 6.48772 1 7.04 1H9.52C10.0723 1 10.52 1.44772 10.52 2V2.5"
        stroke="#666"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { DeleteIcon };
