import { filterDataByStatus } from 'new-theme/utils/common';
import {
  TEST_HISTORY_REQUEST,
  TEST_HISTORY_FILTER_REQUEST,
  TEST_HISTORY_RESET,
  TEST_HISTORY_RESPONSE,
  TEST_HISTORY_FILTER_RESPONSE,
} from 'store/constants/testHistory';

const InitailState = {
  loading: false,
  data: [],
  summary: {},
  nextCursor: '',
};
function testHistoryReducer(state = InitailState, action) {
  switch (action.type) {
    case TEST_HISTORY_REQUEST:
      return { ...state, loading: true };
    case TEST_HISTORY_FILTER_REQUEST:
      return { ...state, loading: true };
    case TEST_HISTORY_RESPONSE:
      return {
        ...state,
        loading: false,
        data: action?.payload?.data,
        summary: action?.payload?.summary,
        nextCursor: action?.payload?.metadata?.next_cursor,
      };
    case TEST_HISTORY_FILTER_RESPONSE:
      return {
        ...state,
        loading: false,
        data: filterDataByStatus(action?.payload?.data, action?.status),
        summary: action?.payload?.summary,
        nextCursor: action?.payload?.metadata?.next_cursor,
      };

    case TEST_HISTORY_RESET:
      return { ...state, ...InitailState };
    default:
      return state;
  }
}

export default testHistoryReducer;
