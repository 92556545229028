import React from 'react';

const Failed = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="7.99998" cy="7.99998" r="6.66667" fill="#CF370E" />
      <path
        d="M5.77832 10.2229L10.2225 5.77841M5.77832 10.2229L10.2225 5.77841"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M10.3333 10.3333L5.99998 6L9.56447 9.56449"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Failed;
