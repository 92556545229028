import React from 'react';

const InfoRevert = ({
  width = '16',
  height = '16',
  className,
  onClick,
  color,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      aria-hidden="true"
      className={className}
      {...rest}
    >
      <g clipPath="url(#clip0_1743_62121)">
        <path
          d="M8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0ZM1.5 8C1.5 9.72391 2.18482 11.3772 3.40381 12.5962C4.62279 13.8152 6.27609 14.5 8 14.5C9.72391 14.5 11.3772 13.8152 12.5962 12.5962C13.8152 11.3772 14.5 9.72391 14.5 8C14.5 6.27609 13.8152 4.62279 12.5962 3.40381C11.3772 2.18482 9.72391 1.5 8 1.5C6.27609 1.5 4.62279 2.18482 3.40381 3.40381C2.18482 4.62279 1.5 6.27609 1.5 8Z"
          fill="#F19A5A"
        />
        <path
          d="M8.74963 8.75V4.5C8.74963 4.30109 8.67062 4.11032 8.52996 3.96967C8.38931 3.82902 8.19855 3.75 7.99963 3.75C7.80072 3.75 7.60996 3.82902 7.4693 3.96967C7.32865 4.11032 7.24963 4.30109 7.24963 4.5V8.75C7.24963 8.94891 7.32865 9.13968 7.4693 9.28033C7.60996 9.42098 7.80072 9.5 7.99963 9.5C8.19855 9.5 8.38931 9.42098 8.52996 9.28033C8.67062 9.13968 8.74963 8.94891 8.74963 8.75Z"
          fill="#F19A5A"
        />
        <path
          d="M8.70674 12.2071C8.89428 12.0196 8.99963 11.7652 8.99963 11.5C8.99963 11.2348 8.89428 10.9804 8.70674 10.7929C8.5192 10.6054 8.26485 10.5 7.99963 10.5C7.73442 10.5 7.48006 10.6054 7.29253 10.7929C7.10499 10.9804 6.99963 11.2348 6.99963 11.5C6.99963 11.7652 7.10499 12.0196 7.29253 12.2071C7.48006 12.3946 7.73442 12.5 7.99963 12.5C8.26485 12.5 8.5192 12.3946 8.70674 12.2071Z"
          fill="#F19A5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1743_62121">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoRevert;
