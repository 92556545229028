import React from 'react';

const Cancelled = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle
        cx="21"
        cy="21"
        r="19.5"
        fill="white"
        stroke="#FF964A"
        strokeWidth="3"
      />
      <path
        d="M21 9C14.3736 9 9 14.3736 9 21C9 27.6264 14.3736 33 21 33C27.6264 33 33 27.6264 33 21C33 14.3736 27.6264 9 21 9ZM21 30.9648C19.1163 30.9641 17.2713 30.4295 15.679 29.423C14.0867 28.4165 12.8123 26.9793 12.0034 25.2781C11.1946 23.5769 10.8844 21.6812 11.1089 19.8109C11.3335 17.9406 12.0835 16.1723 13.272 14.7108L27.2892 28.7268C25.5145 30.1774 23.2922 30.9687 21 30.966V30.9648ZM28.728 27.2892L14.7108 13.2732C16.6249 11.725 19.0442 10.9401 21.5026 11.0696C23.961 11.1992 26.2845 12.234 28.0252 13.9748C29.766 15.7155 30.8008 18.039 30.9304 20.4974C31.0599 22.9558 30.275 25.3751 28.7268 27.2892H28.728Z"
        fill="#FF964A"
      />
    </svg>
  );
};

export default Cancelled;
