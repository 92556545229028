import React from 'react';

const DownloadFileIcon = ({ width = 14, height = 14, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M0.5 9.25V12.25C0.5 12.8023 0.947715 13.25 1.5 13.25H12.5C13.0523 13.25 13.5 12.8023 13.5 12.25V9.25"
        stroke="#666"
        strokeLinecap="round"
      />
      <path
        d="M3.5 5.25L7 8.75L10.5 5.25M7 8.4V0.75"
        stroke="#666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { DownloadFileIcon };
