const PartiallyCompleted = ({
  width = 16,
  height = 16,
  className,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="8" cy="8" r="8" fill="#666666" />
      <path
        d="M4.20001 8.5L6.25898 10.9159C6.29737 10.9609 6.36626 10.9629 6.40724 10.9202L8.25 9"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="11.25" cy="5.5" r="0.75" fill="white" />
      <circle cx="9.75" cy="7.25" r="0.75" fill="white" />
    </svg>
  );
};

export default PartiallyCompleted;
