import React from 'react';

const ChevronDown = ({ width = 20, height = 20, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M6.175 7.14844L10 10.9734L13.825 7.14844L15 8.33177L10 13.3318L5 8.33177L6.175 7.14844Z"
        fill="#888888"
      />
    </svg>
  );
};

export { ChevronDown };
