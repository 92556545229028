import React from 'react';

const PJCancelled = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="8" cy="8" r="4.45" stroke="white" strokeWidth="1.1" />
      <path d="M4.875 5.50195L11.125 10.502" stroke="white" strokeWidth="1.1" />
    </svg>
  );
};

export default PJCancelled;
