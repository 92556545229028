import React from 'react';

const Accesskey = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      aria-hidden="true"
      className={className}
      {...rest}
    >
      <path
        d="M4 11C5.65685 11 7 9.65685 7 8C7 6.34315 5.65685 5 4 5C2.34315 5 1 6.34315 1 8C1 9.65685 2.34315 11 4 11Z"
        stroke="#666666"
        strokeWidth="1.5"
      />
      <path
        d="M7 8H11.4444M15 10V8.15C15 8.0672 14.9232 8 14.8286 8H12.7143H11.4444M11.4444 8V10"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { Accesskey };
