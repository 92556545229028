export const EventNames = {
  HYP_JOB_SEARCH: 'HYP: search a job - job view',
  HYP_JOB_CLICK: 'HYP: select a job - job view',
  HYP_JOB_DETAIL_PAGE: 'HYP: job details page',
  HYP_SUMMARY_TASK_VIEW: 'HYP: summary bar - click - task view',
  HYP_SUMMARY_JOB_VIEW: 'HYP: summary bar - click - job view',
  HYP_ABORT_BTN_CLICK: 'HYP: abort a job - job view',
  HYP_JOB_LIST_DASHBOARD_PAGE: 'HYP: job list page',
  HYP_FILTER_JOB_LIST_DASHBOARD: 'HYP: filter jobs - job view',
  HYP_COPY_ACCESS_KEY: 'HYP: copy credentials',
  HYP_GROUP_OR_TASK_CLICK: 'HYP: select a task - task view',
  HYP_DISCOVERY_TAB_CLICK: 'HYP: group discovery clicks',
  HYP_STAGE_TAB_CLICK: 'HYP: change stage tab',
  HYP_POST_TAB_CLICK: 'HYP: group poststeps clicks',
  HYP_DATA_SPLIT_TAB_CLICK: 'HYP: group data split clicks',
  HYP_ARTIFACT_TAB_CLICK: 'HYP: group artifacts clicks',
  HYP_DISCOVERY_LOG_CLICK: 'HYP: group discovery logs clicks',
  HYP_DISCOVERY_LOG_SEARCH: 'HYP: group discovery logs search clicks',
  HYP_PRESTEP_LOG_CLICK: 'HYP: expand a pre step - task view',
  HYP_BG_SERVICE_LOG_CLICK: 'HYP: expand a background service - modal view',
  HYP_PRESETP_LOG_SEARCH: 'HYP: search in pre step - task view',
  HYP_BG_SERVICE_LOG_SEARCH: 'HYP: search in background service - modal view',
  HYP_PRESETP_LOG_DOWNLOAD: 'HYP: download pre step logs - task view',
  HYP_BACKGROUND_LOG_DOWNLOAD:
    'HYP: download background service logs - modal view',
  HYP_STAGE_LOG_CLICK: 'HYP: expand a scenario - task view',
  HYP_ASSOCIATED_LINK_CLICK: 'HYP: view test - scenarios - task view',
  HYP_STAGE_LOG_SEARCH: 'HYP: search in scenarios - task view',
  HYP_STAGE_LOG_DOWNLOAD: 'HYP: download scenario logs - task view',
  HYP_POSTSTEP_LOG_CLICK: 'HYP: expand a post step - task view',
  HYP_POSTSTEP_LOG_SEARCH: 'HYP: search in post step - task view',
  HYP_POSTSTEP_LOG_DOWNLOAD: 'HYP: download post step logs - task view',
  HYP_ARTIFACT_LOG_CLICK: 'HYP: group artifect logs clicks',
  HYP_ARTIFACT_LOG_SEARCH: 'HYP: group artifact search clicks',
  HYP_DATA_SPLIT_LOG_CLICK: 'HYP: group poststeps logs clicks',
  HYP_DATA_SPLIT_LOG_SEARCH: 'HYP: group poststeps search clicks',
  HYP_DOCUMENTAION_LINK_CLICK: 'HYP: view docs',
  HYP_ONBOARDING_SUPPORT_CHAT_CLICK: 'HYP: onboarding talk',
  HYP_ONBOARDING_SUPPORT_CHAT_CLICK_SAMPLE_PAGE: 'HYP: sample talk',
  HYP_ONBOARDING_SUPPORT_CHAT_CLICK_YAML_PAGE: 'HYP: yaml talk',
  HYP_ONBOARDING_COPY_EXECUTE_CMD_YAML_PAGE:
    'HYP: copy execute command - generate yaml',
  HYP_ONBOARDING_VIEW_DOC_CLICK: 'HYP: view docs - quickstart',
  HYP_ONBOARDING_VIEW_DOC_CLICK_SAMPLE_PAGE: 'HYP: view docs - try an example',
  HYP_ONBOARDING_VIEW_DOC_CLICK_YAML_PAGE: 'HYP: view docs - generate yaml',
  HYP_ONBOARDING_TRY_SAMPLE: 'HYP: tab change - quickstart page',
  HYP_ONBOARDING_TRY_SAMPLE_YAML: 'HYP: onboarding sample yaml',
  HYP_ONBOARDING_LANGUAGE_SELECTION: 'HYP: language selected - quickstart page',
  HYP_ONBOARDING_FRAMEWORK_SELECTION:
    'HYP: framework selected - quickstart page',
  HYP_ONBOARDING_GET_STARTED_CLICK: 'HYP: get started - quickstart page',
  HYP_ONBOARDING_REDIRECT_SUCCESS_SAMPLE_PAGE:
    'HYP: view test results - try an example',
  HYP_ONBOARDING_REDIRECT_FAILURE_SAMPLE_PAGE:
    'HYP: onboarding view results sample failures',
  HYP_ONBOARDING_REDIRECT_SUCCESS_YAML_PAGE:
    'HYP: view test results - generate yaml',
  HYP_ONBOARDING_REDIRECT_FAILURE_YAML_PAGE:
    'HYP: view test results - generate yaml',
  HYP_ONBOARDING_REDIRECT_SUCCESS_AUTO_YAML_PAGE:
    'HYP: onboarding redirect success yaml',
  HYP_ONBOARDING_REDIRECT_SUCCESS_AUTO_SAMPLE_PAGE:
    'HYP: onboarding redirect success sample',
  HYP_ONBOARDING_AUTOMATIC_REDIRECT_SUCCESS: 'HYP: onboarding redirect success',
  HYP_ONBOARDING_OS_SELECTION_SAMPLE: 'HYP: onboarding os selection sample',
  HYP_ONBOARDING_OS_SELECTION_YAML: 'HYP: os selection - generate yaml',
  HYP_ONBOARDING_DOWNLOAD_REPO_SAMPLE:
    'HYP: download sample project - try an example',
  HYP_ONBOARDING_GIT_CLONE_SAMPLE: 'HYP: copy clone command - try an example',
  HYP_ONBOARDING_HYPEREXECUTE_CLI_DOWNLOAD_SAMPLE:
    'HYP: download hypex cli - try an example',
  HYP_ONBOARDING_HYPEREXECUTE_CLI_DOWNLOAD_YAML:
    'HYP: download cli - generate yaml',
  HYP_ONBOARDING_COPY_EXECUTE_CMD_SAMPLE:
    'HYP: copy execute command - try an example',
  HYP_ONBOARDING_COPY_EXECUTE_CMD_YAML: 'HYP: download onboarding yaml',
  HYP_ONBOARDING_DOWNLOAD_YAML_CODE_YAML:
    'HYP: download yaml file - generate yaml',
  HYP_ONBOARDING_COPY_YAML_CODE_YAML: 'HYP: get yaml file - generate yaml',
  HYP_ONBOARDING_COPY_YAML_CODE_SAMPLE: 'HYP: get yaml file - try an example',
  HYP_ONBOARDING_RESET_YAML_CODE_YAML: 'HYP: reset yaml file - generate yaml',
  HYP_ONBOARDING_UPGRADE_NOW_TRIAL: 'HYP: click cta - trial info',
  HYP_ONBOARDING_CONTACTSALES_TRIAL: 'HYP: trial info contact sales',
  HYP_ONBOARDING_UPGRADE_NOW_LIMIT_TEN: 'HYP: limit 10 upgrade',
  HYP_ONBOARDING_CONTACTSALES_LIMIT_TEN: 'HYP: limit 10 contact sales',
  HYP_ONBOARDING_UPGRADE_NOW_LIMIT_SIXTY: 'HYP: limit 60 upgrade',
  HYP_ONBOARDING_CONTACTSALES_LIMIT_SIXTY: 'HYP: limit 60 contact sales',
  HYP_ONBOARDING_UPGRADE_NOW_LIMIT_NINTY: 'HYP: limit 90 upgrade',
  HYP_ONBOARDING_CONTACTSALES_LIMIT_NINTY: 'HYP: limit 60 contact sales',
  HYP_ONBOARDING_UPGRADE_NOW_LIMIT_POPUP: 'HYP: limit upgrade',
  HYP_ONBOARDING_CONTACTSALES_LIMIT_POPUP: 'HYP: limit contact sales',
  HYP_ONBOARDING_TRIAL_INFO_CLICKED: 'HYP: click trial info',
  HYP_ONBOARDING_RENEW_PLAN_EXPIRE: 'HYP: plan expire renew',
  HYP_ONBOARDING_CONTACTSALES_PLAN_EXPIRE: 'HYP: plan expire contact sales',
  HYP_ONBOARDING_RENEW_PLAN_EXPIRE_NINTY: 'HYP: plan 90 expire renew',
  HYP_ONBOARDING_CONTACTSALES_PLAN_EXPIRE_NINTY:
    'HYP: plan 90 expire conact sales',
  HYP_ONBOARDING_RENEW_PLAN_EXPIRE_TRIAL: 'HYP: trial expires renew',
  HYP_ONBOARDING_CONTACTSALES_PLAN_EXPIRE_TRIAL:
    'HYP: trial expired contact sales',
  HYP_ONBOARDING_HOME_PAGE: 'HYP: quickstart page',
  HYP_HELP_GITPOD: 'HYP: run test on gitpod - help section',
  HYP_YAML_GENERATE: 'HYP: open generate yaml - help section',
  HYP_GITPOD_GITHUB: 'HYP: gitpod github',
  HYP_HELP_QUICKSTART: 'HYP: get help - help section',
  HYP_HELP_DOC: 'HYP: doc help',
  HYP_HELP_GITHUB_SAMPLE: 'HYP: github samples help',
  HYP_HELP_SUPPORT: 'HYP: support help',
  HYP_REPORT_DOWNLOAD: 'HYP: download a report - task view',
  HYP_REPORT_OPEN: 'HYP: open a report - task view',
  HYP_DOWNLOAD_ARTEFACT: 'HYP: download an artifact - task view',
  HYP_DOWNLOAD_ARTEFACT_FROM_MODAL: 'HYP: download an artifact - modal view',
  HYP_MORE_FILES_MESSAGE_SHOWN:
    'HYP: user has more artifact files - modal view',
  HYP_USER_PRESSED_BACK_ARTIFACT_MODAL:
    'HYP: user pressed back, to open another artifact folder from starting - modal view',
  HYP_USER_INTERACTED_WITH_FOLDER:
    'HYP: user interacted with artifact folder view - modal view',
  HYP_DELETE_ARTEFACT: 'HYP: delete an artifact - task view',
  HYP_TASK_FILTER: 'HYP: filter tasks  - task view',
  HYP_SECRET_CLICK: 'HYP: click secrets',
  HYP_SECRET_ADD_CLICK: 'HYP: add a secret',
  HYP_SECRETS_ADD: 'HYP: added a secret',
  HYP_SECRETS_ACTION: 'HYP: select a secret action',
  HYP_SECRETS_EDIT: 'HYP: edited a secret',
  HYP_SECRETS_DELETE: 'HYP: secrets delete',
  HYP_ONBOARDING_TRY_NOW: 'HYP: click cta - try now page',
  HYP_TRY_NOW_PAGE: 'HYP: view try now page',
  HYP_ONBOARDING_BOOK_DEMO: 'HYP: intermediary book',
  HYP_STAGE_LOGS_DOWNLOAD: 'HYP: logs download',
  HYP_STAGE_FULL_SCREEN: 'HYP: toggle logs view - task view',
  HYP_STAGE_EXIT_SCREEN: 'HYP logs exit full screen',
  EVENT_SCREENSHOT_SEARCH: 'HYP: screenshot search',
  HYP_ONBOARDING_VIEW_IN_GITHUB: 'HYP: view in github - try an example',
  HYP_ONBOARDING_VIEW_DOC_VIEW_TEST_RESULT:
    'HYP: view docs - view test results - try an example',
  HYP_ONBOARDING_VIEW_DOC_VIEW_TEST_RESULT_YAML:
    'HYP: view docs - view test results - generate yaml',
  HYP_ONBOARDING_SKIP_STEPS_SAMPLE: 'HYP: skip step - try an example',
  HYP_ONBOARDING_SKIP_STEPS_YAML: 'HYP: skip step - generate yaml',
  HYP_ONBOARDING_HYPEREXECUTE_UPDATE_CONCURENCY_YAML:
    'HYP: update concurrency - generate yaml',
  HYP_ONBOARDING_HYPEREXECUTE_UPDATE_RETRY_AND_TIMEOUT_YAML:
    'HYP: set retry and timeout - generate yaml',
  HYP_ONBOARDING_HYPEREXECUTE_UPDATE_DISCOVERY_YAML:
    'HYP: set test discovery path - generate yaml',
  HYP_ONBOARDING_HYPEREXECUTE_UPDATE_TEST_RUNNER_YAML:
    'HYP: set test runner command - generate yaml',
  HYP_ONBOARDING_HYPEREXECUTE_AUTOSPLIT_STRATIGY_CLICK:
    'HYP: auto splitting help - generate yaml',
  HYP_EVENT_APPROVE_TEST: 'HYP: vr test approved',
  HYP_EVENT_REPORT_BUG: 'HYP: vr report bug',
  HYP_EVENT_COMPARISON_SWITCH: 'HYP: vr comparison switch',
  HYP_ARTIFACT_BTN_CLICK: 'HYP: click artifacts - task view',
  HYP_JOB_LABEL_SEARCH: 'HYP: search label - filter jobs - job view',
  HYP_TOP_BANNER_CLOSE_BUTTON: 'HYP: close top banner',
  HYP_CLICK_OKAY_BUTTON_POPUP: 'HYP: clicked okay in popup - generate yaml',
  HYP_ONBOARDING_TRIGGER_TEST_BUTTON_CLICK: 'HYP: trigger test - quick run',
  HYP_ONBOARDING_DASHBOARD_BUTTON_CLICK: 'HYP: go to dashboard - quick run',
  HYP_NEGATIVE_TASK_TIME: 'HYP: negative task time',
  HYP_CONTINUE_TESTING_MODAL: 'HYP: continue testing - modal',
  HYP_CONTINUE_TOUR_MODAL: 'HYP: continue tour - modal',
  HYP_SELECT_A_CTA_MODAL: 'HYP: select a cta - modal',
  HYP_SKIP_FOR_NOW_MODAL: 'HYP: skip for now - modal',
  HYP_TALK_TO_EXPERT_MODAL: 'HYP: talk to expert - modal',
  HYP_ONBOARDING_VIEW_DOC_QUICK_RUN_PAGE: 'HYP: view docs - quick run',
  HYP_ONBOARDING_CLICK_DOC_LINK: 'HYP: click doc hyperlink - quick run',
  HYP_ONBOARDING_SKIP_LINK_QUICK_RUN: 'HYP: skip configure yaml - quick run',
  HYP_ONBOARDING_QUICK_RUN_JOB_INVOKED:
    'HYP: job invoked successfully - quick run',
  HYP_ONBOARDING_CUSTOMER_SUPPORT_QUICK_RUN:
    'HYP: click support hyperlink - invoke error - quick run',
  HYP_ONBOARDING_WAIT_ERROR_QUICK_RUN:
    'HYP: viewed invoke error message - quick run',
  HYP_TRY_NOW_MODAL_CTA: 'HYP: click cta - try now modal',
  HYP_TRY_NOW_MODAL_CLOSE: 'HYP: closed modal - try now modal',
  HYP_QUICK_RUN_UPDATE_YAML: 'HYP: update yaml parameters - quick run',
  HYP_TRY_NOW_MODAL: 'HYP: view try now modal',
  HYP_ADD_NEW_PROJECT: 'HYP: create project - projects',
  HYP_USER_INTERACTED_WITH_TOOL: 'HYP: user interacted with tool dropDown',
  HYP_USER_CLICKED_NEXT_STEP_1: 'HYP: continue step 1 - project setup',
  HYP_USER_CLICKED_NEXT_STEP_2: 'HYP: click save - project setup',
  HYP_USER_CLICKED_NEXT_STEP_3: 'HYP: click save - project setup with Dex',

  HYP_DISCOVERING_EXECUTION_LIST: 'HYP: discovering execution list - projects',
  HYP_USER_SELECTED_EXECUTION_LIST_ITEM:
    'HYP: user clicked execution list items',
  HYP_USER_CLICKED_RUN: 'HYP: click run - project view',

  HYP_USER_CLICKED_RUN_ON_CONCURRENCY_DIALOG:
    'HYP: click run - concurrency dialog - project view',
  HYP_USER_CLICKED_PROJECT_ITEM: 'HYP: select a project - projects list',
  HYP_USER_INTERACTED_WITH_CONNECTION_TOOLTIP:
    'HYP: user interacted with connection tooltip',
  HYP_USER_VERIFIED_CONNECTION_STRING: 'HYP: user verified connection string',
  HYP_BACK_PROJECT_VIEW: 'HYP: click back - project view',
  HYP_CLICK_GO_TO_JOBS_PROJECT: 'HYP: click go to jobs - projects',
  HYP_CLOSE_CONCURRENCY_DIALOG: 'HYP: close concurrency dialog - project view',
  HYP_CLOSE_PROJECT_SETUP: 'HYP: close dialog - project setup',

  HYP_USER_CLICKED_CREATE_A_SCHEDULE:
    'HYP: click create a workflow on no workflow screen',
  HYP_USER_CLICKED_CREATE_A_SCHEDULE_FROM_HEADER:
    'HYP: click create a workflow on from header',
  HYP_OPEN_CREATE_SCHEDULE_MODAL: 'HYP: open create workflow modal',
  HYP_CLICK_ON_CROSS_BUTTON_SCHEDULE_MODAL_CROSS_FIRST_STEP:
    'HYP: click on cross button on workflow modal first step',
  HYP_CLICK_ON_CROSS_BUTTON_SCHEDULE_MODAL_CROSS_SECOND_STEP:
    'HYP: click on cross button on workflow modal second step',
  HYP_CLICK_ON_CANCEL_BUTTON_SCHEDULE_MODAL_FIRST_STEP:
    'HYP: click on cancel button on workflow modal first step',
  HYP_CLICK_ON_CANCEL_BUTTON_SCHEDULE_MODAL_SECOND_STEP:
    'HYP: click on cancel button on workflow modal second step',
  HYP_CLICK_ON_CONTINUE_BUTTON_SCHEDULE_MODAL_FIRST_STEP:
    'HYP: click on continue button on workflow modal first step',
  HYP_CLICK_ON_BACK_BUTTON_SCHEDULE_MODAL_SECOND_STEP:
    'HYP: click on back button on workflow modal second step',
  HYP_CLICK_ON_SCHEDULE_JOB_BUTTON_SCHEDULE_MODAL_SECOND_STEP:
    'HYP: click on workflow job button on workflow modal second step',
  HYP_SCHEDULE_JOB_CREATED: 'HYP: workflow scheduled',
  HYP_SCHEDULE_JOB_FAILED: 'HYP: workflow creation failed',
  HYP_ERROR_OCCUR_IN_SCHEDULE_JOB_FIRST_STEP:
    'HYP: error occur in workflow  first step',
  HYP_ERROR_OCCUR_IN_SCHEDULE_JOB_SECOND_STEP:
    'HYP: error occur in workflow  second step',
  HYP_CLICK_ON_ABORT_JOB_BUTTON_JOBS_PAGE:
    'Hyp: clicked on abort job on job list page',
  HYP_SELECT_ABORT_OPTION: 'Hyp: select abort option',
  HYP_CANCEL_JOB_ABORT: 'Hyp: cancel job abort',
  HYP_CLOSE_SECRETS_MODAL: 'Hyp: close secrets modal',
  HYP_CLICK_ON_ACCESS_KEY: 'Hyp: click on access key',
  HYP_CLICK_ON_JOB_LIST_JOB_ACTIONS:
    'Hyp: click on job actions on job list page',
  HYP_CLICK_ON_ARCHIVE_A_JOB: 'Hyp: click on add to archive',
  HYP_CLICK_ON_UNARCHIVE_A_JOB: 'Hyp: click on remove from archive',
  HYP_CLICK_ON_REPORT: 'Hyp: click on report button',
  HYP_CLICK_ON_BACK_BUTTON_ON_JOB_DETAILS:
    'Hyp: click on back button on job details',
  HYP_CLICK_ON_CROSS_BUTTON_ON_RETRY_MODAL:
    'HYP: click on cross button on retry modal',
  HYP_CLICK_SHOW_TEST_RETRIES_ON_RETRY_MODAL:
    'HYP: click on show test retries on retry modal',
  HYP_CLICK_ON_HISTORY_BUTTON: 'HYP: click on history button',
  HYP_CLOSES_HISTORY_MODAL: 'HYP: closes history modal',
  HYP_OPEN_TEST_ON_HISTORY_MODAL: 'HYP: open test on history modal',
  HYP_CHANGES_JOB_LIMIT_ON_HISTORY_MODAL:
    'HYP: changes job limit on history modal',

  HYP_CLICKED_LEARN_MORE_BUTTON_IN_CONCURRENCY_DETAILS:
    'HYP: see how - concurrency banner',
  HYP_CLICKED_CROSS_BUTTON_IN_CONCURRENCY_DETAILS:
    'HYP: clicked on cross button on concurrency details',
  HYP_OPEN_CONCURRENCY_DETAILS_MODAL: 'HYP: modal opened - concurrency modal',
  HYP_CLOSE_CONCURRENCY_DETAILS_MODAL: 'HYP: modal closed - concurrency modal',
  HYP_CLICK_ON_UPGRADE_NOW_CONCURRENCY_DETAILS_MODAL:
    'HYP: clicked on upgrade now on concurrency details modal',
  HYP_CLICK_ON_SEE_HOW_CONCURRENCY_DETAILS_MODAL:
    'HYP: see how - concurrency modal',
  HYP_CLICK_ON_DISMISS_CONCURRENCY_DETAILS_MODAL:
    'HYP: clicked on dismiss on concurrency details modal',
  HYP_CLICK_ON_CROSS_CONCURRENCY_DETAILS_MODAL:
    'HYP: clicked on cross on concurrency details modal',
  HYP_CLICK_ON_ROW_CONCURRENCY_DETAILS_MODAL:
    'HYP: selected a concurrency on concurrency details modal',
  HYP_SEARCH_TEXT_ON_WORKFLOW_BUILD: 'HYP: search text on workflow',
  HYP_USER_PROVIDED_FEEDBACK: 'HYP: user clicked on feedBack',
  HYP_USER_SUBMITED_NEGATIVE_FEEDBACK: 'HYP: user submitted negative feedBack',
  HYP_USER_HOVER_ON_ERROR_CTA: 'HYP: user hovered error RCA CTA section',
  HYP_USER_CLICK_ON_GENERATE_RCA: 'HYP: user generated RCA',
  HYP_USER_CLICK_ON_RE_GENERATE_RCA: 'HYP: user re-generated RCA',
  HYP_RCA_GENERATION_SUCCESS: 'HYP: RCA generated successfully',
  HYP_RCA_GENERATION_FAILED: 'HYP: RCA generation failed',

  HYP_USER_CLICK_SEE_DETAILS_RCA:
    'HYP: user clicked see details of RCA section',
  HYP_USER_TOGGLED_SECTION_ON_RCA:
    'HYP: user switched error section in Failure Analysis',
  HYP_USER_CLOSED_FAILURE_ANALYSIS_DRAWER:
    'HYP: user close (RCA) failure analysis modal',
  HYP_USER_CHANGED_ERROR_IN_FAILURE_RCA:
    'HYP: user changed error in failure analysis under RCA',

  HYP_PROJECT_LIST_PAGE: 'HYP: projects list page',
  HYP_PROJECT_DETAIL_PAGE: 'HYP: projects detail page',
  HYP_WORKFLOWS_LIST_PAGE: 'HYP: workflows list page',

  HYP_HOVER_CONCURRENCY_BANNER: 'HYP: hover on - concurrency banner',

  HYP_MODAL_CLOSED_TIME_SPENT_RETIRES:
    'HYP: modal closed - time spent in retries',
  HYP_MODAL_OPENED_TIME_SPENT_RETRIES:
    'HYP: modal opened - time spent in retries',
  HYP_SEE_DETAILS_TIME_SPENT_RETRIES:
    'HYP: see details - time spent in retries',
  HYP_HOVER_ON_TIME_SPENT_RETIRES: 'HYP: hover on - time spent in retries',
  HYP_BG_SERVICE_CLICK:
    'HYP: user click on background service button - job detail',
  HYP_BG_SERVICE_MODAL_CLOSE: 'HYP: background service modal close',
  HYP_BG_SERVICE_MODAL_OPEN: 'HYP: background service modal open',
  HYP_BG_SERVICE_MOUSE_HOVER:
    'HYP: user hovered on background service button - job detail',
  HYP_BG_SERVICE_BUTTON_SHOW:
    'HYP: user job/task contain background service stage',
};
