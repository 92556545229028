import {
  HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION,
  HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION_YAML_BASED,
  HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION_QUICK_RUN,
  HYPERTEST_ONBOARDING_RESET_CONFIGURATION_YAML_BASED,
  HYPERTEST_SET_DOWNLOAD_CONCIERGE_ALERT_STATUS,
  HYPERTEST_ONBOARDING_UPADTE_HOMPAGE_VALUE,
  HYPERTEST_SET_ALERT_STATUS,
  HYPERTEST_ONBOARDING_UPADTE_TRIGGER_TEST_STATUS,
  HYPERTEST_SHOW_JOB_LIST_AFTER_QUICK_RUN,
} from '../constants/onboarding';
import {
  HYPERTEST_DEFAULT_RETRY,
  HYPERTEST_DEFAULT_CONCURRENCY,
  HYPERTEST_DEFAULT_TIMEOUT,
} from '../../utils/constant';
import {
  pyunitConfiguaration,
  pyunitConfiguarationYamlBased,
  pyunitConfiguarationQuickRun,
} from '../../utils/onboarding/languageBasedConfig';
const initialState = {
  currentConfiguration: {
    testType: 'quick-run',
    frameworkType: 'Selenium',
    framework: {
      language: 'Java',
      name: 'JUnit',
    },
    projectSetup: 'zip',
    frameworkConfiguration: pyunitConfiguaration,
    isConciergeAvialble: true,
    platform: 'win',
    systemPlatform: 'win',
    completedSteps: [],
    currentStep: 1,
  },
  currentConfigurationQuickRun: {
    testType: 'quick-run',
    frameworkType: 'Selenium',
    framework: {
      language: 'Java',
      name: 'JUnit',
    },
    projectSetup: 'zip',
    frameworkConfiguration: pyunitConfiguarationQuickRun,
    testRunner: pyunitConfiguarationQuickRun.testRunnerCmd,
    concurrency: 1,
    retry: HYPERTEST_DEFAULT_RETRY,
    timeout: HYPERTEST_DEFAULT_TIMEOUT,
    isConciergeAvialble: true,
    platform: 'win',
    systemPlatform: 'win',
    completedSteps: [],
    currentStep: 1,
    triggerTestStatus: {
      currentStep: 0,
      logs: '',
    },
    jobId: '',
    showJobListAfterQuickRun: false,
  },
  currentConfigurationYamlBased: {
    frameworkConfiguration: pyunitConfiguarationYamlBased,
    concurrency: HYPERTEST_DEFAULT_CONCURRENCY,
    retry: HYPERTEST_DEFAULT_RETRY,
    timeout: HYPERTEST_DEFAULT_TIMEOUT,
    retryOnFailure: true,
    testdiscovery: pyunitConfiguarationYamlBased.testDiscoveryCmd,
    testRunner: pyunitConfiguarationYamlBased.testRunnerCmd,
    completedSteps: [],
    currentStep: 1,
  },
  homePage: {
    completedSteps: [],
    currentStep: 1,
  },
  visual: {
    conciergeDownloadAlert: false,
    yamlCodeCopiedAlert: false,
    yamlCodeDownloadedAlert: false,
  },
};

function hypertestOnboardingReducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION:
      return {
        ...state,
        currentConfiguration: {
          ...state.currentConfiguration,
          ...payload,
        },
      };
    case HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION_QUICK_RUN:
      return {
        ...state,
        currentConfigurationQuickRun: {
          ...state.currentConfigurationQuickRun,
          ...payload,
        },
      };
    case HYPERTEST_SHOW_JOB_LIST_AFTER_QUICK_RUN:
      return {
        ...state,
        currentConfigurationQuickRun: {
          ...state.currentConfigurationQuickRun,
          showJobListAfterQuickRun: payload,
        },
      };
    case HYPERTEST_ONBOARDING_UPADTE_TRIGGER_TEST_STATUS:
      return {
        ...state,
        currentConfigurationQuickRun: {
          ...state.currentConfigurationQuickRun,
          ...payload,
        },
      };
    case HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION_YAML_BASED:
      return {
        ...state,
        currentConfigurationYamlBased: {
          ...state.currentConfigurationYamlBased,
          ...payload,
        },
      };
    case HYPERTEST_ONBOARDING_RESET_CONFIGURATION_YAML_BASED:
      return {
        ...state,
        currentConfigurationYamlBased: {
          ...state.currentConfigurationYamlBased,
          concurrency: initialState.currentConfigurationYamlBased.concurrency,
          retry: initialState.currentConfigurationYamlBased.retry,
          timeout: initialState.currentConfigurationYamlBased.timeout,
        },
      };
    case HYPERTEST_SET_DOWNLOAD_CONCIERGE_ALERT_STATUS:
      return {
        ...state,
        visual: {
          ...state.visual,
          conciergeDownloadAlert: payload,
        },
      };
    case HYPERTEST_SET_ALERT_STATUS:
      return {
        ...state,
        visual: {
          ...state.visual,
          ...payload,
        },
      };
    case HYPERTEST_ONBOARDING_UPADTE_HOMPAGE_VALUE:
      return {
        ...state,
        homePage: {
          ...state.homePage,
          ...payload,
        },
      };
    default:
      return state;
  }
}

export default hypertestOnboardingReducer;
