import React from 'react';

const Rescheduled = ({ width = '18', height = '18', className = '' }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <circle cx="9" cy="9" r="9" fill="#999999" />
      <g clipPath="url(#clip0_12253_36505)">
        <path
          d="M8.72608 6.7832C8.57754 6.7832 8.43505 6.84229 8.33008 6.94727C8.2251 7.05225 8.16602 7.19473 8.16602 7.34327V8.74327C8.16602 9.07744 8.29883 9.39797 8.53507 9.63419C8.77139 9.87052 9.09189 10.0032 9.42609 10.0032H10.4061C10.6062 10.0032 10.791 9.8965 10.891 9.72327C10.991 9.55003 10.991 9.33654 10.891 9.16332C10.791 8.98998 10.6061 8.88324 10.4061 8.88324H9.42609C9.34875 8.88324 9.28605 8.82064 9.28605 8.7433V7.3433C9.28605 7.19476 9.22707 7.05227 9.12209 6.9473C9.01701 6.84232 8.87463 6.78324 8.72609 6.78324"
          fill="white"
        />
        <path
          d="M5.08515 13.3634H6.3452C6.7536 13.3634 7.1453 13.2011 7.43408 12.9123C7.72295 12.6234 7.88515 12.2317 7.88515 11.8233V10.5634C7.88515 10.3633 7.77842 10.1784 7.60518 10.0783C7.43194 9.97832 7.21845 9.97832 7.04523 10.0783C6.87189 10.1784 6.76515 10.3633 6.76515 10.5634V11.528V11.5279C5.99973 10.8432 5.58948 9.8461 5.65138 8.82092C5.71339 7.79582 6.24073 6.8553 7.08313 6.26792C7.9254 5.68042 8.99025 5.5104 9.97363 5.8063C10.957 6.1023 11.7512 6.8318 12.1292 7.78667C12.5073 8.74155 12.428 9.81695 11.9139 10.7059C11.3997 11.595 10.5072 12.2001 9.49102 12.3485C9.35753 12.3664 9.23507 12.4316 9.1458 12.5324C9.05664 12.6332 9.00664 12.7628 9.00517 12.8973V12.9128V12.9127C9.00761 13.0734 9.079 13.2254 9.20117 13.3299C9.32324 13.4343 9.48447 13.4812 9.64354 13.4587C10.6456 13.3151 11.5695 12.8365 12.2648 12.1007C12.96 11.3648 13.3855 10.4152 13.4719 9.40667C13.5584 8.39807 13.3007 7.38997 12.741 6.54642C12.1811 5.70307 11.3522 5.07405 10.3892 4.76205C9.42614 4.45005 8.38589 4.47337 7.43797 4.82826C6.48992 5.18313 5.69002 5.84856 5.16854 6.71626C4.64707 7.58393 4.43484 8.60258 4.56649 9.60626C4.69803 10.61 5.16572 11.5396 5.89314 12.2435H5.08534C4.88524 12.2435 4.70039 12.3502 4.60039 12.5235C4.50039 12.6967 4.50039 12.9102 4.60039 13.0834C4.70039 13.2568 4.88527 13.3635 5.08534 13.3635"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_12253_36505">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { Rescheduled };
