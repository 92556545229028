let concurrency = 0;
function quickRunConcurrencyHandler(newValue) {
  if (newValue) {
    concurrency = newValue;
  }

  return concurrency;
}

export default quickRunConcurrencyHandler;
