const Skipped = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="8" cy="8" r="8" fill="#666666" />
      <path
        d="M10.8333 5V10.4167M9.38333 8.06833L5.62667 10.2596C5.56335 10.2965 5.49139 10.3161 5.41808 10.3164C5.34477 10.3166 5.27269 10.2975 5.20911 10.261C5.14554 10.2245 5.09273 10.1718 5.05602 10.1084C5.0193 10.0449 4.99998 9.9729 5 9.89958V5.51708C4.99998 5.44377 5.0193 5.37175 5.05602 5.30829C5.09273 5.24483 5.14554 5.19219 5.20911 5.15567C5.27269 5.11915 5.34477 5.10005 5.41808 5.1003C5.49139 5.10054 5.56335 5.12013 5.62667 5.15708L9.38292 7.34875C9.44577 7.38546 9.4979 7.43799 9.53414 7.50112C9.57037 7.56424 9.58944 7.63576 9.58944 7.70854C9.58944 7.78133 9.57037 7.85284 9.53414 7.91597C9.4979 7.97909 9.44618 8.03162 9.38333 8.06833Z"
        stroke="white"
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { Skipped };
