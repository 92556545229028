export const HYPERTEST_SECRET_LIST_ACTION_REQUEST =
  'HYPERTEST_SECRET_LIST_ACTION_REQUEST';
export const HYPERTEST_SECRET_LIST_ACTIONS_RESPONSE =
  'HYPERTEST_SECRET_LIST_ACTIONS_RESPONSE';
export const HYPERTEST_SECRET_ADD_ACTION_REQUEST =
  'HYPERTEST_SECRET_ADD_ACTION_REQUEST';
export const HYPERTEST_SECRET_ADD_ACTIONS_RESPONSE =
  'HYPERTEST_SECRET_ADD_ACTIONS_RESPONSE';
export const HYPERTEST_SECRET_UPDATE_ACTION_REQUEST =
  'HYPERTEST_SECRET_UPDATE_ACTION_REQUEST';
export const HYPERTEST_SECRET_UPDATE_ACTIONS_RESPONSE =
  'HYPERTEST_SECRET_UPDATE_ACTIONS_RESPONSE';
export const HYPERTEST_SECRET_DELETE_ACTION_REQUEST =
  'HYPERTEST_SECRET_DELETE_ACTION_REQUEST';
export const HYPERTEST_SECRET_DELETE_ACTIONS_RESPONSE =
  'HYPERTEST_SECRET_DELETE_ACTIONS_RESPONSE';
