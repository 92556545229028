import React from 'react';

const Reset = ({ width, height, className, color = '#fff', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M3.80922 1.44059C3.89124 1.52264 3.93731 1.6339 3.93731 1.74991C3.93731 1.86592 3.89124 1.97718 3.80922 2.05922L2.36853 3.49991H7.65616C10.1932 3.49991 12.2499 5.55659 12.2499 8.09366C12.2499 10.6307 10.1932 12.6874 7.65616 12.6874C5.11909 12.6874 3.06241 10.6307 3.06241 8.09366C3.06241 7.97762 3.1085 7.86634 3.19055 7.7843C3.27259 7.70225 3.38387 7.65616 3.49991 7.65616C3.61594 7.65616 3.72722 7.70225 3.80927 7.7843C3.89131 7.86634 3.93741 7.97762 3.93741 8.09366C3.93741 8.82916 4.15551 9.54814 4.56413 10.1597C4.97275 10.7712 5.55354 11.2479 6.23305 11.5293C6.91257 11.8108 7.66028 11.8844 8.38165 11.741C9.10302 11.5975 9.76563 11.2433 10.2857 10.7232C10.8058 10.2031 11.16 9.54052 11.3035 8.81915C11.4469 8.09778 11.3733 7.35007 11.0918 6.67055C10.8104 5.99104 10.3337 5.41025 9.72218 5.00163C9.11064 4.59301 8.39166 4.37491 7.65616 4.37491H2.36853L3.80922 5.81559C3.851 5.85595 3.88433 5.90423 3.90726 5.9576C3.93019 6.01098 3.94226 6.06839 3.94277 6.12648C3.94327 6.18457 3.9322 6.24218 3.9102 6.29595C3.88821 6.34972 3.85572 6.39856 3.81464 6.43964C3.77356 6.48072 3.72472 6.51321 3.67095 6.5352C3.61718 6.5572 3.55957 6.56827 3.50148 6.56777C3.44339 6.56726 3.38598 6.55519 3.3326 6.53226C3.27923 6.50933 3.23095 6.476 3.19059 6.43422L1.00309 4.24672C0.921075 4.16468 0.875 4.05342 0.875 3.93741C0.875 3.8214 0.921075 3.71014 1.00309 3.62809L3.19059 1.44059C3.27264 1.35858 3.3839 1.3125 3.49991 1.3125C3.61592 1.3125 3.72718 1.35858 3.80922 1.44059Z"
        fill={color}
      />
    </svg>
  );
};

export { Reset };
