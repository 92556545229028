import React from 'react';

const Video = ({ width = '12', height = '8', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M8 1.33333V6.66667H1.33333V1.33333H8ZM8.66667 0H0.666667C0.489856 0 0.320286 0.070238 0.195262 0.195262C0.0702379 0.320287 0 0.489856 0 0.666667V7.33333C0 7.51014 0.0702379 7.67971 0.195262 7.80474C0.320286 7.92976 0.489856 8 0.666667 8H8.66667C8.84348 8 9.01305 7.92976 9.13807 7.80474C9.2631 7.67971 9.33333 7.51014 9.33333 7.33333V5L12 7.66667V0.333333L9.33333 3V0.666667C9.33333 0.489856 9.2631 0.320287 9.13807 0.195262C9.01305 0.070238 8.84348 0 8.66667 0Z"
        fill="#0EBAC5"
      />
    </svg>
  );
};

export default Video;
