import React from 'react';

const Duration = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 9 9"
      role="img"
      {...rest}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M4.5 0C6.98535 0 9 2.01465 9 4.5C9 6.98535 6.98535 9 4.5 9C2.01465 9 0 6.98535 0 4.5C0 2.01465 2.01465 0 4.5 0ZM4.5 0.62775C3.47302 0.62775 2.48809 1.03572 1.76191 1.76191C1.03572 2.48809 0.62775 3.47302 0.62775 4.5C0.62775 5.52698 1.03572 6.51191 1.76191 7.23809C2.48809 7.96428 3.47302 8.37225 4.5 8.37225C5.52698 8.37225 6.51191 7.96428 7.23809 7.23809C7.96428 6.51191 8.37225 5.52698 8.37225 4.5C8.37225 3.47302 7.96428 2.48809 7.23809 1.76191C6.51191 1.03572 5.52698 0.62775 4.5 0.62775ZM4.0815 2.51145C4.25475 2.51145 4.39515 2.6523 4.39515 2.82555V5.02335H6.59295C6.67414 5.02642 6.75098 5.06084 6.80733 5.11937C6.86368 5.17789 6.89516 5.25598 6.89516 5.33722C6.89516 5.41847 6.86368 5.49656 6.80733 5.55508C6.75098 5.61361 6.67414 5.64803 6.59295 5.6511H4.0815C3.9982 5.6511 3.9183 5.61801 3.8594 5.5591C3.80049 5.5002 3.7674 5.4203 3.7674 5.337V2.826C3.7674 2.6523 3.9078 2.51145 4.0815 2.51145Z"
        fill={color ? color : '#666666'}
      />
    </svg>
  );
};

export { Duration };
