const Play = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden="true"
      role="img"
      {...rest}
    >
      <circle cx="8" cy="8" r="7.375" stroke="#666666" stroke-width="1.25" />
      <path
        d="M6.28516 5.59413V10.4063C6.28516 10.6026 6.50114 10.7224 6.66766 10.6183L10.5174 8.21221C10.6741 8.11429 10.6741 7.88613 10.5174 7.78821L6.66766 5.38213C6.50114 5.27806 6.28516 5.39777 6.28516 5.59413Z"
        fill="#666666"
      />
    </svg>
  );
};

export default Play;
