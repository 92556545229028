import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Switch } from 'react-router-dom';
import moment from 'moment';
import PrivateRoute from './components/Routes/PrivateRoute';
import PublicRoute from './components/Routes/PublicRoute';
import PlanInfo from 'components/Common/PlanInfo';
import Typography from 'components/Atoms/Typography';

import { useLocation } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';

import { Path } from 'constants/routes';

import {
  FF_WORKFLOW,
  FF_PROJECTS,
  FF_INSIGHTS,
  FF_DISABLE_NEW_THEME,
} from 'constants/feature-flags';

import './assets/styles/routes.css';
import useFeatureFlags from 'hooks/useFeatureFlags';
import history from 'utils/history';
import { SideBarMenuClasses } from 'constants/common';
import { useSelector } from 'react-redux';
import {
  hideSupportIconRecursively,
  showSupportIcon,
} from 'utils/handleSupportIconVisibility';

const PRIVATE_TYPE = 'private';
const PUBLIC_TYPE = 'public';

const SIDEBAR_HIDDEN_CLASS_NAME = 'ltch-hidden';

// const ConfigureTest = React.lazy(() =>
//   import('./components/Onboarding/ConfigureTest')
// );

// const ConfigureTestYamlBased = React.lazy(() =>
//   import('./components/Onboarding/ConfigureTestYamlBased')
// );

const ExcludeExactMatchRoutes = [Path.HYPEREXECUTE, Path.JOBS];

const ShowBookDemoButtonRoutes = [Path.JOBS, Path.QUICK_START];

const UrlToClassMapping = {
  [Path.JOBS]: SideBarMenuClasses.JOBS,
  [Path.TASK]: SideBarMenuClasses.JOBS,
  [Path.QUICK_START]: SideBarMenuClasses.JOBS,
  [Path.QUICK_START_CONFIGURE_TEST.Path]: SideBarMenuClasses.JOBS,
  [Path.QUICK_START_QUICK_RUN.Path]: SideBarMenuClasses.JOBS,
  [Path.QUICK_START_YAML_BASED_TEST.Path]: SideBarMenuClasses.JOBS,
  [Path.ONBOARD]: SideBarMenuClasses.JOBS,
  [Path.PROJECT_LISTING]: SideBarMenuClasses.PROJECTS,
  [Path.PROJECT_DETAILS]: SideBarMenuClasses.PROJECTS,
  [Path.WORKFLOW]: SideBarMenuClasses.WORKFLOW,
  [Path.INSIGHTS.Path]: SideBarMenuClasses.INSIGHTS,
  [Path.INSIGHTS_OVERVIEW]: SideBarMenuClasses.INSIGHTS,
};
const SideNavData = {
  Jobs: {
    CLASS_NAME: SideBarMenuClasses.JOBS,
    PATH: Path.JOBS,
  },
  Projects: {
    CLASS_NAME: SideBarMenuClasses.PROJECTS,
    PATH: Path.PROJECT_LISTING,
    FEATURE_FLAG: FF_PROJECTS,
  },
  Workflow: {
    CLASS_NAME: SideBarMenuClasses.WORKFLOW,
    PATH: Path.WORKFLOW,
    FEATURE_FLAG: FF_WORKFLOW,
  },
  Insights: {
    CLASS_NAME: SideBarMenuClasses.INSIGHTS,
    PATH: Path.INSIGHTS_OVERVIEW,
    FEATURE_FLAG: FF_INSIGHTS,
  },
};

const Routes = ({ children }) => {
  const featureFlags = useFeatureFlags();
  const visibleMenusList = getMenusToShow();
  const enabledMenusRef = useRef(visibleMenusList);
  const retryCount = useRef(0);
  const userDataRef = useRef(null);
  const location = useLocation();
  const loactionRef = useRef(location);
  const userInfo = useSelector((state) => state.userInfo?.userInfo);
  const userInfoVisual = useSelector((state) => state.userInfo?.visual);
  const filters = useSelector((state) => state.filters?.filters);
  const filterRef = useRef(null);
  // const userInfoVisual = useSelector((state) => state.userInfo?.visual);

  const { data: userInfoData } = userInfo;
  const isTrialUser = userInfoVisual.isTrialUser;

  const navItemRetryCount = useRef(0);

  useEffect(() => {
    filterRef.current = filters;
  }, [filters]);

  useEffect(() => {
    userDataRef.current = userInfoData;
  }, [userInfoData]);

  useEffect(() => {
    enabledMenusRef.current = visibleMenusList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags]);

  useEffect(() => {
    if (isTrialUser) {
      const matchingRoute = ShowBookDemoButtonRoutes.find((route) => {
        const regExp = pathToRegexp(route);
        return regExp.test(location.pathname);
      });

      if (matchingRoute) {
        hideSupportIconRecursively();
        typeof window?.renderBookADemoBtn === 'function' &&
          window?.renderBookADemoBtn('hyperexecute');
      } else {
        showSupportIcon();
        typeof window?.hideBookADemoBtn === 'function' &&
          window?.hideBookADemoBtn();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname, isTrialUser]);

  function getMenusToShow() {
    const arr = [];
    Object.keys(SideNavData).forEach((key) => {
      const data = SideNavData[key];
      if (data.FEATURE_FLAG && featureFlags[data.FEATURE_FLAG]) {
        arr.push(data);
      }
    });
    return arr;
  }

  function handleActiveClass(activeClass) {
    const subNavClasses = Object.values(SideBarMenuClasses);
    subNavClasses.forEach((classValue) => {
      const ele = document.getElementsByClassName(classValue)[0];
      if (classValue !== activeClass) {
        ele?.classList?.remove('active');
      } else {
        ele?.classList?.add('active');
      }
    });
  }
  function getCurrentFilterURLUsingFilterReducers(newData, key) {
    const jobFilters = filterRef?.current?.jobFilters;
    const enabledFilters = filterRef?.current?.enabledFilters;
    let lambdaTestFilterObject = jobFilters;
    let queryString = '';
    if (lambdaTestFilterObject) {
      Object.keys(lambdaTestFilterObject).forEach(function (k) {
        if (lambdaTestFilterObject[k]) {
          if (
            lambdaTestFilterObject[k] instanceof Array &&
            enabledFilters.includes(k)
          ) {
            if (k === 'range') {
              if (lambdaTestFilterObject[k].length > 0) {
                let dataRange = lambdaTestFilterObject[k][0];
                let startDate = moment(dataRange.startDate).format(
                  'YYYY-MM-DD'
                );
                let endDate = moment(dataRange.endDate).format('YYYY-MM-DD');
                queryString = `${queryString}&${k}=${startDate},${endDate}`;
              }
            } else {
              if (lambdaTestFilterObject[k].length > 0) {
                queryString = `${queryString}&${k}=${lambdaTestFilterObject[
                  k
                ].join(',')}`;
              }
            }
          }
          if (
            typeof lambdaTestFilterObject[k] === 'string' ||
            lambdaTestFilterObject[k] instanceof String
          ) {
            if (lambdaTestFilterObject[k].trim() !== '') {
              queryString = `${queryString}&${k}=${lambdaTestFilterObject[k]}`;
            }
          }
        }
      });
    }
    return queryString;
  }

  function handleNavItemClick(pathName, activeClass) {
    return (e) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (pathName === Path.JOBS) {
        const filterURL = getCurrentFilterURLUsingFilterReducers();
        history.push(`${Path.JOBS}?${filterURL}`);
      } else {
        history.push(pathName);
      }
      handleActiveClass(activeClass);
    };
  }

  function handleNavigation() {
    const menus = Object.values(SideNavData);
    for (let i = 0; i < menus.length; i++) {
      let ele = document.getElementsByClassName(menus[i].CLASS_NAME)[0];
      if (!ele && navItemRetryCount.current < 20) {
        navItemRetryCount.current = navItemRetryCount.current + 1;
        setTimeout(() => {
          handleNavigation();
        }, 10);
        break;
      }
      ele?.removeEventListener(
        'click',
        handleNavItemClick(menus[i].PATH, menus[i].CLASS_NAME)
      );
      ele?.addEventListener(
        'click',
        handleNavItemClick(menus[i].PATH, menus[i].CLASS_NAME)
      );
    }
  }

  function handleProductNavExpanded() {
    retryCount.current = 0;
    navItemRetryCount.current = 0;
    handleNavigation();
    showMenuInSidebar();
  }

  useEffect(() => {
    loactionRef.current = location;
  }, [location]);

  useEffect(() => {
    window.addEventListener('productNavExpanded', handleProductNavExpanded);
    return () => {
      retryCount.current = 0;
      navItemRetryCount.current = 0;
      window.removeEventListener('productNavExpanded', () => {});
      const menus = Object.values(SideNavData);
      for (let i = 0; i < menus.length; i++) {
        let ele = document.getElementsByClassName(menus[i].CLASS_NAME)[0];
        ele?.removeEventListener(
          'click',
          handleNavItemClick(menus[i].PATH, menus[i].CLASS_NAME)
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      retryCount.current = 0;
      showMenuInSidebar();
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    navItemRetryCount.current = 0;
    handleNavigation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function showMenuInSidebar(showProduct, showWorkflow) {
    for (let i = 0; i < enabledMenusRef.current.length; i++) {
      const value = enabledMenusRef.current[i];
      const ele = document.getElementsByClassName(value.CLASS_NAME)[0];
      if (!ele && retryCount.current < 20) {
        retryCount.current = retryCount?.current + 1;
        setTimeout(() => {
          showMenuInSidebar(showProduct, showWorkflow);
        }, 3);
        break;
      } else if (ele) {
        ele.classList.remove(SIDEBAR_HIDDEN_CLASS_NAME);
        window.setTimeout(() => {
          let activeClass = UrlToClassMapping[loactionRef.current?.pathname];
          if (!activeClass) {
            let baseLocationValue = loactionRef.current?.pathname.split('/');
            baseLocationValue.pop();
            activeClass = UrlToClassMapping[baseLocationValue?.join('/')];
          }
          handleActiveClass(activeClass);
        }, 2);
      }
    }

    Object.keys(SideNavData).forEach(function (key) {
      const nav = SideNavData[key];
      if (!nav?.PATH?.includes('job')) {
        let isShown = false;
        for (let i = 0; i < enabledMenusRef?.current?.length; i++) {
          const value = enabledMenusRef?.current[i];
          isShown = value?.PATH === nav?.PATH;
          if (isShown) {
            break;
          }
        }

        if (!isShown) {
          const ele = document.getElementsByClassName(nav.CLASS_NAME)[0];
          if (ele) {
            ele?.classList?.add(SIDEBAR_HIDDEN_CLASS_NAME);
          }
        }
      }
    });
  }

  return <>{children}</>;
};

const RouteWrapper = ({ forceOldTheme }) => {
  let showNewTheme = !useSelector(
    (state) => state?.featureFlag?.[FF_DISABLE_NEW_THEME]
  );

  const [routesList, setRouteList] = useState([]);
  const [showRoutes, setShowRoutes] = useState(false);

  useEffect(() => {
    (async function () {
      let list = [];
      if (!forceOldTheme && showNewTheme) {
        list = (await import('./newThemeRoutes')).default;
      } else {
        list = (await import('./oldthemeRoutes')).default;
      }
      setRouteList(list);
      setShowRoutes(true);
    })();
  }, [forceOldTheme, showNewTheme]);

  if (!showRoutes) {
    return null;
  }

  return (
    <>
      <HeaderInfo />
      <Routes>
        <Switch>
          {routesList?.map(({ path, key, Component, type, ...props }) => {
            if (type === PUBLIC_TYPE)
              return (
                <PublicRoute
                  key={key || path}
                  component={Component}
                  path={path}
                  exact={!ExcludeExactMatchRoutes.includes(path)}
                  {...props}
                />
              );
            if (type === PRIVATE_TYPE)
              return (
                <PrivateRoute
                  key={key || path}
                  component={Component}
                  path={path}
                  exact
                  {...props}
                />
              );
            return path;
          })}
        </Switch>
      </Routes>
    </>
  );
};

function HeaderInfo() {
  const [is, setIs] = useState(false);

  useEffect(() => {
    function check() {
      var ele = document.getElementsByClassName('navbar__header')[0];
      if (!ele) {
        setTimeout(check, 10);
      } else {
        setIs(true);
      }
    }

    check();
  }, []);

  if (!is) {
    return null;
  }

  var ele = document.getElementsByClassName('navbar__header')[0];
  return ReactDOM.createPortal(
    <div className="common-header-hyp-info">
      <Typography variant="h1" extraClasses="common-header-title">
        HyperExecute
      </Typography>
      <PlanInfo />
    </div>,
    ele
  );
}

export default RouteWrapper;
