import {
  checkTimeLimit,
  convertTimeTaken,
  sendAmplitudeEvents,
} from 'utils/common';
import { EventNames } from 'utils/events';
import { Path } from 'constants/routes';

export function handleJobClickAmplitudeEvents(
  { id, type, status, Tasks = [], executionTime, end_time, start_time },
  redirectToStatus,
  clickedFrom
) {
  let testDuration = '-';
  const regexHour = /h/i;
  const regexMinute = /m/i;
  if (executionTime) {
    testDuration = executionTime.replace(regexHour, 'h ');
    testDuration = testDuration.replace(regexMinute, 'm ');
  }

  let consumedTime = checkTimeLimit(
    convertTimeTaken((new Date(end_time) - new Date(start_time)) / 1000)
  )
    ? convertTimeTaken((new Date(end_time) - new Date(start_time)) / 1000)
    : '-';

  let eventName = '';
  let eventParam = {};

  if (clickedFrom === 'JOB_VIEW') {
    eventParam['status selected'] = redirectToStatus;
    eventName = EventNames.HYP_SUMMARY_JOB_VIEW;
  } else if (clickedFrom === 'TASK_VIEW') {
    eventParam['status selected'] = redirectToStatus;
    eventName = EventNames.HYP_SUMMARY_TASK_VIEW;
  } else {
    eventName = EventNames.HYP_JOB_CLICK;
  }
  sendAmplitudeEvents(eventName, {
    ...{
      mode: type,
      'job status': status,
      'number of tasks': Tasks,
      'job duration': consumedTime,
      'test duration': testDuration,
      'job id': id,
    },
    ...eventParam,
  });
}

export function getJobDetailPageUrl(
  jobId,
  redirectToStatus,
  redirectToCategory
) {
  let url = `${Path.TASK}?jobId=${jobId}`;
  if (redirectToStatus) {
    url += `&taskStatus=${redirectToStatus}`;
  }
  if (redirectToCategory) {
    // url += `&logType=${redirectToCategory}`;
    // ? TODO Use category in case we have to give open different tabs as well.
  }

  return url;
}
