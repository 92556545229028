export function formatDate(inputDate) {
  if (!inputDate) {
    return '';
  }
  const date = new Date(inputDate);

  // Extract components
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();

  // Convert to 12-hour time format
  const amPm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  // Build the formatted string
  const formattedDate = `${formattedHours}:${minutes
    .toString()
    .padStart(2, '0')} ${amPm}, ${day} ${month} ${year}`;

  return formattedDate;
}

function getFormattedDateForDexLogs(inputDateString) {
  // IP: 2023-09-19T15:01:16.4325036+05:30
  // OP: 12:11 pm, Thur 14/8/2023 [+5:30]
  if (!inputDateString) {
    return '--';
  }
  const inputDate = new Date(inputDateString);

  const options = {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
    weekday: 'short',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    timeZoneName: 'short',
    timeZoneOffset: 'short',
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(inputDate);

  const time = `${parts[8]?.value}:${parts[10]?.value} ${parts[12]?.value}`;
  const dayString = inputDate.toLocaleString('en-US', { weekday: 'short' });
  const day = parts[4]?.value;
  const month = parts[2]?.value;
  const year = parts[6]?.value;
  const timeZone = parts[14]?.value?.replace(/[A-Za-z]/g, '');

  const outputDateString = `${time.toLowerCase()}, ${dayString} ${day}/${month}/${year} [${timeZone}]`;

  return outputDateString;
}

export function getTransformedScenariosForDex(payload) {
  let dexData = [];
  if (payload?.data?.execution_list_results) {
    dexData = payload?.data?.execution_list_results.map((events, index) => {
      const row = {};

      let failedCount = 0;
      let passedCount = 0;
      let queuedCount = 0;

      const associatedData = [];
      events?.execution_entries_results?.forEach((d) => {
        let associatedObj = {};
        associatedObj.status = d?.result;
        associatedObj.name = d?.name;
        associatedObj.logs = d?.logs;
        associatedObj.startTime = getFormattedDateForDexLogs(d?.start_time);
        associatedObj.endTime = getFormattedDateForDexLogs(d?.end_time);

        associatedData.push(associatedObj);

        if (d?.result === 'passed') {
          passedCount += 1;
        }
        if (d?.result === 'failed') {
          failedCount += 1;
        }

        if (d?.result === 'queued') {
          queuedCount += 1;
        }
      });
      row.scenarioIndex = index;
      row.type = 'scenario';
      row.task = payload?.data?.task_id;
      row.name = events.name;
      row.status = events.result;
      row.created_at = events.start_time;
      row.link = events.link;
      row.failed = failedCount;
      row.passed = passedCount;
      row.eventId = events.id;
      row.queued = queuedCount;
      row.associatedData = associatedData;
      row.description = getDescription(events?.start_time, events?.end_time);

      return row;
    });
  }

  return dexData;
}

function getDescription(start, end) {
  let desc = '';
  if (start) {
    desc = `Ran from ${formatDate(start)}`;
  }

  if (end && start) {
    desc += `  to ${formatDate(end)}`;
  }

  return desc;
}
