import React from 'react';

const DocumentationIcon = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 15 14"
      role="img"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M11.3742 3.77813L8.53047 0.934375C8.44922 0.853125 8.36797 0.8125 8.24609 0.8125H4.18359C3.73672 0.8125 3.37109 1.17812 3.37109 1.625V11.375C3.37109 11.8219 3.73672 12.1875 4.18359 12.1875H10.6836C11.1305 12.1875 11.4961 11.8219 11.4961 11.375V4.0625C11.4961 3.94062 11.4555 3.85938 11.3742 3.77813ZM8.24609 1.7875L10.5211 4.0625H8.24609V1.7875ZM10.6836 11.375H4.18359V1.625H7.43359V4.0625C7.43359 4.50938 7.79922 4.875 8.24609 4.875H10.6836V11.375Z"
        fill="#666666"
      />
      <path d="M4.99609 8.9375H9.87109V9.75H4.99609V8.9375Z" fill="#666666" />
      <path d="M4.99609 6.5H9.87109V7.3125H4.99609V6.5Z" fill="#666666" />
    </svg>
  );
};

export { DocumentationIcon };
