import React from 'react';

const BackIcon = ({
  width = 16,
  height = 16,
  className,
  color = '#111827',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M9.78 12.7808C9.63937 12.9212 9.44875 13.0001 9.25 13.0001C9.05125 13.0001 8.86063 12.9212 8.72 12.7808L4.47 8.5308C4.32955 8.39017 4.25066 8.19955 4.25066 8.0008C4.25066 7.80205 4.32955 7.61142 4.47 7.4708L8.72 3.2208C8.86226 3.08839 9.05031 3.01629 9.24463 3.01964C9.43895 3.023 9.6244 3.10156 9.762 3.2388C9.89924 3.3764 9.9778 3.56185 9.98116 3.75617C9.98451 3.95049 9.91241 4.13854 9.78 4.2808L6.06 8.0008L9.78 11.7208C9.92045 11.8614 9.99934 12.052 9.99934 12.2508C9.99934 12.4495 9.92045 12.6402 9.78 12.7808Z"
        fill="#333333"
      />
    </svg>
  );
};

export { BackIcon };
