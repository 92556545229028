import React from 'react';

const AnalyticsPoster = ({ width = 240, height = 200, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      {...rest}
      viewBox="0 0 240 200"
      fill="none"
    >
      <path
        d="M83.5 155.904L99.904 172.32"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M99.904 155.904L83.5 172.32"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M83.5 172.32C83.5 172.32 89.86 171.432 94.444 166.848C99.028 162.264 99.916 155.904 99.916 155.904"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M91.7056 175.716C98.1143 175.716 103.31 170.521 103.31 164.112C103.31 157.703 98.1143 152.508 91.7056 152.508C85.2969 152.508 80.1016 157.703 80.1016 164.112C80.1016 170.521 85.2969 175.716 91.7056 175.716Z"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M83.5 172.32C83.5 172.32 90.796 175.956 97.18 169.584C103.552 163.212 99.916 155.904 99.916 155.904"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M83.5 172.32C83.5 172.32 84.388 165.96 88.972 161.376C93.556 156.792 99.916 155.904 99.916 155.904"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M83.4938 172.32C83.4938 172.32 79.8578 165.024 86.2298 158.64C92.6018 152.268 99.9098 155.904 99.9098 155.904"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M80.6875 160.453C81.9355 160.957 85.0915 162.505 89.2075 166.621C93.3235 170.737 94.8715 173.881 95.3755 175.141"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M80.125 164.893C81.385 165.349 83.605 166.489 86.473 169.345C89.317 172.189 90.457 174.397 90.913 175.657"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M88.0312 153.096C88.5352 154.344 90.0833 157.5 94.1993 161.616C98.3153 165.732 101.459 167.28 102.719 167.784"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M92.4766 152.531C92.9326 153.791 94.0726 156.011 96.9286 158.879C99.7726 161.723 101.981 162.863 103.241 163.319"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M27.0078 52.8365L140.084 34.7285L147.788 82.8845C148.1 84.8525 146.768 86.6885 144.8 87.0125L38.8278 103.981C36.8598 104.293 35.0238 102.961 34.6998 100.993L27.0078 52.8365Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M28.4005 38.7242L134.372 21.7562C136.34 21.4442 138.176 22.7763 138.5 24.7443L140.096 34.7402L27.0085 52.8362L25.4125 42.8402C25.1005 40.8842 26.4325 39.0362 28.4005 38.7242Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M34.0783 46.9338C35.2778 46.9338 36.2503 45.9614 36.2503 44.7618C36.2503 43.5623 35.2778 42.5898 34.0783 42.5898C32.8787 42.5898 31.9062 43.5623 31.9062 44.7618C31.9062 45.9614 32.8787 46.9338 34.0783 46.9338Z"
        fill="#888888"
      />
      <path
        d="M41.4767 45.7444C42.6763 45.7444 43.6487 44.772 43.6487 43.5724C43.6487 42.3728 42.6763 41.4004 41.4767 41.4004C40.2771 41.4004 39.3047 42.3728 39.3047 43.5724C39.3047 44.772 40.2771 45.7444 41.4767 45.7444Z"
        fill="#888888"
      />
      <path
        d="M48.8751 44.5686C50.0747 44.5686 51.0471 43.5962 51.0471 42.3966C51.0471 41.197 50.0747 40.2246 48.8751 40.2246C47.6756 40.2246 46.7031 41.197 46.7031 42.3966C46.7031 43.5962 47.6756 44.5686 48.8751 44.5686Z"
        fill="#888888"
      />
      <path
        d="M133.954 47.1621L36.7109 62.7324L37.0411 64.7941L134.284 49.2238L133.954 47.1621Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M129.069 50.0516L37.0391 64.7871L37.3692 66.8488L129.399 52.1132L129.069 50.0516Z"
        fill="#888888"
      />
      <path
        d="M125.937 57.0828L38.0547 71.1543L38.3848 73.216L126.267 59.1445L125.937 57.0828Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M119.018 60.3004L38.3984 73.209L38.7285 75.2707L119.348 62.3621L119.018 60.3004Z"
        fill="#888888"
      />
      <path
        d="M118.28 66.9445L39.4141 79.5723L39.7442 81.634L118.61 69.0062L118.28 66.9445Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M103.244 71.4638L39.7344 81.6328L40.0645 83.6945L103.574 73.5255L103.244 71.4638Z"
        fill="#888888"
      />
      <path
        d="M125.287 74.4732L40.7578 88.0078L41.0879 90.0695L125.617 76.5349L125.287 74.4732Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M123.632 76.8525L41.0938 90.0684L41.4239 92.13L123.963 78.9142L123.632 76.8525Z"
        fill="#888888"
      />
      <path
        d="M36.0547 58.6211L42.0787 96.2411"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M140.543 46.2812L221.471 51.4892L218.663 95.0492C218.531 97.0292 216.827 98.5412 214.835 98.4092L141.095 93.6572C139.115 93.5252 137.603 91.8212 137.735 89.8292L140.543 46.2812Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M145.015 32.8166L218.755 37.5566C220.735 37.6886 222.247 39.3926 222.115 41.3846L221.467 51.4886L140.539 46.2806L141.187 36.1766C141.319 34.1966 143.035 32.6846 145.015 32.8166Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M149.219 42.1292C150.418 42.1292 151.391 41.1567 151.391 39.9572C151.391 38.7576 150.418 37.7852 149.219 37.7852C148.019 37.7852 147.047 38.7576 147.047 39.9572C147.047 41.1567 148.019 42.1292 149.219 42.1292Z"
        fill="#888888"
      />
      <path
        d="M156.695 42.6213C157.895 42.6213 158.867 41.6489 158.867 40.4493C158.867 39.2498 157.895 38.2773 156.695 38.2773C155.496 38.2773 154.523 39.2498 154.523 40.4493C154.523 41.6489 155.496 42.6213 156.695 42.6213Z"
        fill="#888888"
      />
      <path
        d="M164.172 43.1018C165.372 43.1018 166.344 42.1294 166.344 40.9298C166.344 39.7302 165.372 38.7578 164.172 38.7578C162.972 38.7578 162 39.7302 162 40.9298C162 42.1294 162.972 43.1018 164.172 43.1018Z"
        fill="#888888"
      />
      <path
        d="M211.149 90.3546L145.797 86.1426"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M213.188 58.6612L147.836 54.4492"
        stroke="#888888"
        strokeWidth="0.24"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M212.52 69.2215L147.156 65.0215"
        stroke="#888888"
        strokeWidth="0.24"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.833 79.7921L146.469 75.5801"
        stroke="#888888"
        strokeWidth="0.24"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.281 69.0544C149.693 65.2384 152.645 60.1264 161.333 60.6904C175.241 61.5904 172.217 82.1464 185.417 82.9864C197.513 83.7664 198.869 60.2464 213.269 57.2344"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.938 75.3549C160.57 76.2309 159.178 53.7669 171.178 54.5349C183.046 55.3029 178.822 69.7989 191.938 70.6389C199.078 71.0949 207.118 66.3429 212.974 61.9029"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.523 108.576L205.195 105.096L209.095 166.404C209.227 168.384 207.715 170.1 205.735 170.22L158.239 173.244C156.259 173.376 154.543 171.864 154.423 169.884L150.523 108.576Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M153.251 94.6551L200.747 91.6311C202.727 91.4991 204.443 93.0111 204.563 94.9911L205.211 105.095L150.539 108.575L149.891 98.4711C149.759 96.4911 151.259 94.7751 153.251 94.6551Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M158.328 103.377C159.528 103.377 160.5 102.405 160.5 101.205C160.5 100.006 159.528 99.0332 158.328 99.0332C157.129 99.0332 156.156 100.006 156.156 101.205C156.156 102.405 157.129 103.377 158.328 103.377Z"
        fill="#888888"
      />
      <path
        d="M165.805 102.901C167.004 102.901 167.977 101.928 167.977 100.729C167.977 99.5291 167.004 98.5566 165.805 98.5566C164.605 98.5566 163.633 99.5291 163.633 100.729C163.633 101.928 164.605 102.901 165.805 102.901Z"
        fill="#888888"
      />
      <path
        d="M173.281 102.43C174.481 102.43 175.453 101.457 175.453 100.258C175.453 99.0584 174.481 98.0859 173.281 98.0859C172.082 98.0859 171.109 99.0584 171.109 100.258C171.109 101.457 172.082 102.43 173.281 102.43Z"
        fill="#888888"
      />
      <path
        d="M165.43 142.926L162.305 143.125L163.701 165.089L166.827 164.89L165.43 142.926Z"
        fill="#888888"
      />
      <path
        d="M169.36 131.489L166.234 131.688L168.34 164.801L171.465 164.602L169.36 131.489Z"
        fill="#888888"
      />
      <path
        d="M173.165 118.088L170.039 118.287L172.977 164.503L176.103 164.304L173.165 118.088Z"
        fill="#888888"
      />
      <path
        d="M178.298 125.401L175.172 125.6L177.627 164.222L180.753 164.023L178.298 125.401Z"
        fill="#888888"
      />
      <path
        d="M183.469 133.61L180.344 133.809L182.258 163.916L185.384 163.718L183.469 133.61Z"
        fill="#888888"
      />
      <path
        d="M187.665 126.309L184.539 126.508L186.899 163.621L190.024 163.423L187.665 126.309Z"
        fill="#888888"
      />
      <path
        d="M191.884 119.358L188.758 119.557L191.541 163.329L194.666 163.13L191.884 119.358Z"
        fill="#888888"
      />
      <path
        d="M197.055 127.329L193.93 127.527L196.187 163.036L199.313 162.837L197.055 127.329Z"
        fill="#888888"
      />
      <path
        d="M201.046 162.719L161.938 165.203"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M77.9363 87.7656L158.864 92.9736L154.592 159.406C154.46 161.386 152.756 162.898 150.764 162.766L77.0243 158.014C75.0443 157.882 73.5323 156.178 73.6643 154.186L77.9363 87.7656Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M82.4135 74.301L156.154 79.053C158.134 79.185 159.646 80.889 159.514 82.881L158.866 92.985L77.9375 87.777L78.5855 77.673C78.7175 75.681 80.4215 74.169 82.4135 74.301Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M86.6095 83.6253C87.8091 83.6253 88.7815 82.6528 88.7815 81.4532C88.7815 80.2537 87.8091 79.2812 86.6095 79.2812C85.4099 79.2812 84.4375 80.2537 84.4375 81.4532C84.4375 82.6528 85.4099 83.6253 86.6095 83.6253Z"
        fill="#888888"
      />
      <path
        d="M94.0861 84.1057C95.2856 84.1057 96.2581 83.1333 96.2581 81.9337C96.2581 80.7342 95.2856 79.7617 94.0861 79.7617C92.8865 79.7617 91.9141 80.7342 91.9141 81.9337C91.9141 83.1333 92.8865 84.1057 94.0861 84.1057Z"
        fill="#888888"
      />
      <path
        d="M101.563 84.5862C102.762 84.5862 103.735 83.6137 103.735 82.4142C103.735 81.2146 102.762 80.2422 101.563 80.2422C100.363 80.2422 99.3906 81.2146 99.3906 82.4142C99.3906 83.6137 100.363 84.5862 101.563 84.5862Z"
        fill="#888888"
      />
      <path
        d="M113.562 126.334L124.854 145.546"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.845 126.142L113.561 126.334L132.389 114.406L132.293 114.262C127.145 106.282 117.233 102.19 107.597 104.866C95.7413 108.154 88.7933 120.442 92.0813 132.298C95.3693 144.154 107.657 151.102 119.513 147.814C129.449 145.042 135.941 135.982 135.845 126.142Z"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.634 104.256L113.562 126.336"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.104 111.249L124.312 123.165L146.596 122.973C146.584 121.065 146.309 119.133 145.781 117.201C145.181 115.029 144.281 113.037 143.129 111.225L143.104 111.249Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.3595 111.021C92.1875 116.505 89.9195 124.485 92.0795 132.285L113.547 126.321L97.3595 111.021Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3672 113.277L83.0392 109.797L86.9392 171.105C87.0712 173.085 85.5592 174.801 83.5792 174.921L36.0832 177.945C34.1032 178.077 32.3872 176.565 32.2672 174.585L28.3672 113.277Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M31.0946 99.3445L78.5906 96.3205C80.5706 96.1885 82.2866 97.7005 82.4066 99.6805L83.0546 109.785L28.3826 113.265L27.7346 103.161C27.6026 101.181 29.1146 99.4765 31.0946 99.3445Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M36.172 108.067C37.3716 108.067 38.344 107.094 38.344 105.895C38.344 104.695 37.3716 103.723 36.172 103.723C34.9724 103.723 34 104.695 34 105.895C34 107.094 34.9724 108.067 36.172 108.067Z"
        fill="#888888"
      />
      <path
        d="M43.6486 107.602C44.8481 107.602 45.8206 106.629 45.8206 105.43C45.8206 104.23 44.8481 103.258 43.6486 103.258C42.449 103.258 41.4766 104.23 41.4766 105.43C41.4766 106.629 42.449 107.602 43.6486 107.602Z"
        fill="#888888"
      />
      <path
        d="M51.1251 107.121C52.3247 107.121 53.2971 106.149 53.2971 104.949C53.2971 103.75 52.3247 102.777 51.1251 102.777C49.9256 102.777 48.9531 103.75 48.9531 104.949C48.9531 106.149 49.9256 107.121 51.1251 107.121Z"
        fill="#888888"
      />
      <path
        d="M39.0424 138.462L37.7969 138.541L38.4608 148.984L39.7063 148.905L39.0424 138.462Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M38.3783 128.013L37.1328 128.092L37.7967 138.535L39.0423 138.456L38.3783 128.013Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M42.0814 143.589L40.8359 143.668L41.4999 154.111L42.7454 154.032L42.0814 143.589Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M41.4174 133.14L40.1719 133.219L40.8358 143.662L42.0813 143.583L41.4174 133.14Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M45.0893 148.268L43.8438 148.348L44.5077 158.791L45.7532 158.712L45.0893 148.268Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M44.4252 137.815L43.1797 137.895L43.8436 148.338L45.0891 148.258L44.4252 137.815Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M47.3549 141.2L46.1094 141.279L46.7733 151.722L48.0188 151.643L47.3549 141.2Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M46.6986 130.763L45.4531 130.842L46.1171 141.285L47.3626 141.206L46.6986 130.763Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M50.4643 147.308L49.2188 147.387L49.8827 157.83L51.1282 157.751L50.4643 147.308Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M49.8002 136.858L48.5547 136.938L49.2186 147.381L50.4641 147.301L49.8002 136.858Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M53.4252 151.183L52.1797 151.262L52.8436 161.705L54.0891 161.626L53.4252 151.183Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M52.7533 140.745L51.5078 140.824L52.1717 151.267L53.4173 151.188L52.7533 140.745Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M56.3783 154.915L55.1328 154.994L55.7967 165.437L57.0423 165.358L56.3783 154.915Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M55.7064 144.477L54.4609 144.557L55.1249 155L56.3704 154.921L55.7064 144.477Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M59.2299 157.005L57.9844 157.084L58.6483 167.527L59.8938 167.448L59.2299 157.005Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M58.5658 146.556L57.3203 146.635L57.9842 157.078L59.2298 156.999L58.5658 146.556Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M61.5736 151.354L60.3281 151.434L60.9921 161.877L62.2376 161.797L61.5736 151.354Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M60.9174 140.913L59.6719 140.992L60.3358 151.435L61.5813 151.356L60.9174 140.913Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M64.4486 153.739L63.2031 153.818L63.8671 164.261L65.1126 164.182L64.4486 153.739Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M63.7846 143.29L62.5391 143.369L63.203 153.812L64.4485 153.733L63.7846 143.29Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M66.6752 146.036L65.4297 146.115L66.0936 156.558L67.3391 156.479L66.6752 146.036Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M66.0189 135.599L64.7734 135.678L65.4374 146.121L66.6829 146.042L66.0189 135.599Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M69.1361 142.101L67.8906 142.18L68.5546 152.623L69.8001 152.544L69.1361 142.101Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M68.4721 131.651L67.2266 131.73L67.8905 142.174L69.136 142.094L68.4721 131.651Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M71.6908 139.497L70.4453 139.576L71.1092 150.019L72.3548 149.94L71.6908 139.497Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M71.0189 129.048L69.7734 129.127L70.4374 139.57L71.6829 139.491L71.0189 129.048Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M74.6049 142.602L73.3594 142.682L74.0233 153.125L75.2688 153.046L74.6049 142.602Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M73.933 132.165L72.6875 132.244L73.3514 142.687L74.5969 142.608L73.933 132.165Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M76.6908 132.811L75.4453 132.891L76.1092 143.334L77.3548 143.255L76.6908 132.811Z"
        fill="#888888"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M76.0346 122.374L74.7891 122.453L75.453 132.896L76.6985 132.817L76.0346 122.374Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M113.564 14.0391L114.992 19.7631L119.096 15.5271L117.476 21.1911L123.14 19.5711L118.904 23.6751L124.628 25.1031L118.904 26.5311L123.14 30.6351L117.476 29.0151L119.096 34.6791L114.992 30.4431L113.564 36.1671L112.136 30.4431L108.032 34.6791L109.652 29.0151L103.988 30.6351L108.224 26.5311L102.5 25.1031L108.224 23.6751L103.988 19.5711L109.652 21.1911L108.032 15.5271L112.136 19.7631L113.564 14.0391Z"
        fill="white"
        stroke="#888888"
        strokeWidth="0.6"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M128.857 172.715L129.529 174.455L131.257 175.127L129.529 175.799L128.857 177.539L128.185 175.799L126.445 175.127L128.185 174.455L128.857 172.715Z"
        fill="#888888"
      />
      <path
        d="M20.2792 89.9277L20.9392 91.6677L22.6792 92.3397L20.9392 93.0117L20.2792 94.7517L19.6072 93.0117L17.8672 92.3397L19.6072 91.6677L20.2792 89.9277Z"
        fill="#888888"
      />
      <path
        d="M218.451 112.465L219.123 114.205L220.851 114.877L219.123 115.549L218.451 117.289L217.779 115.549L216.039 114.877L217.779 114.205L218.451 112.465Z"
        fill="#888888"
      />
      <path
        d="M171.111 20.4121L171.783 22.1521L173.523 22.8241L171.783 23.4841L171.111 25.2241L170.451 23.4841L168.711 22.8241L170.451 22.1521L171.111 20.4121Z"
        fill="#888888"
      />
      <path
        d="M92.4703 96.1562L93.1423 97.8962L94.8823 98.5683L93.1423 99.2283L92.4703 100.968L91.8103 99.2283L90.0703 98.5683L91.8103 97.8962L92.4703 96.1562Z"
        fill="#888888"
      />
      <path
        d="M49.5136 24.3965L50.1736 26.1365L51.9136 26.8085L50.1736 27.4685L49.5136 29.2085L48.8416 27.4685L47.1016 26.8085L48.8416 26.1365L49.5136 24.3965Z"
        fill="#888888"
      />
    </svg>
  );
};

export default AnalyticsPoster;
