import React from 'react';

const Refresh = ({ width = '14', height = '12', className = '', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M4.16333 3.15198C3.0977 4.21761 2.75216 5.75838 3.14088 7.11198L3.91843 5.67198L4.94088 6.21921L3.27056 9.34401L0.160156 7.67358L0.707382 6.65113L1.98898 7.34231C1.52813 5.61433 1.97462 3.68471 3.32813 2.33111C5.05611 0.603133 7.70573 0.358333 9.70733 1.58228L8.85776 2.43186C7.34583 1.63993 5.43056 1.88473 4.16336 3.15196L4.16333 3.15198Z"
        fill="#666666"
      />
      <path
        d="M4.2793 10.4093L5.12887 9.55974C6.6409 10.3518 8.55607 10.107 9.82327 8.83974C10.8889 7.77411 11.2344 6.23334 10.8457 4.87973L10.0682 6.31974L9.04572 5.77251L10.7161 2.66211L13.8265 4.33254L13.2793 5.35499L11.9977 4.66381C12.4586 6.39179 12.0121 8.32141 10.6586 9.67501C8.9305 11.3742 6.28097 11.6333 4.27937 10.4094L4.2793 10.4093Z"
        fill="#666666"
      />
    </svg>
  );
};

export default Refresh;
