import * as action from '../constants/projectListing';
import cloneDeep from 'lodash/cloneDeep';
import { uniqBy } from 'lodash';

const initialState = {
  isLoading: true,
  projects: [],
  error: '',
  addProjectError: '',
  searchedText: '',
  projectId: '',
  nextCursor: '',
  isFetchingMore: false,
};

function projectListingReducer(state = initialState, actions) {
  const { type, payload, extra } = actions;
  const oldState = cloneDeep(state);
  switch (type) {
    case action.HYP_REMOVE_DELETED_PROJECT:
      const newProjects = oldState.projects.filter((p) => p.id !== payload);
      return {
        ...oldState,
        projects: newProjects,
      };
    case action.HYP_UPDATE_LISTING_FETCH_STATUS:
      return {
        ...oldState,
        isLoading: payload,
      };
    case action.HYP_UPDATE_LISTING_PROJECTS:
      if (
        !payload ||
        payload?.error ||
        (payload?.response?.status !== 200 && payload?.status !== 'success')
      ) {
        return {
          ...oldState,
          error: payload,
          ...(payload?.message !== 'canceled' ? { isLoading: false } : {}),
          ...(extra?.clearExistingProjects ? { projects: [] } : {}),
        };
      }
      let projects = [...oldState.projects, ...(payload?.data || [])];
      if (extra?.clearExistingProjects) {
        projects = payload?.data || [];
      }

      projects = uniqBy(projects, 'id');

      return {
        ...oldState,
        projects,
        nextCursor: payload?.metadata?.next_cursor,
        isFetchingMore: false,
        isLoading: false,
      };
    case action.HYP_INFINITE_SCROLL_LOADING:
      return {
        ...oldState,
        isFetchingMore: true,
      };
    case action.HYP_UPDATE_ADD_PROJECT_SUCCESS:
      if (
        !payload ||
        payload?.error ||
        (payload?.response?.status !== 200 && payload?.status !== 'success')
      ) {
        let error = '';
        if (
          payload?.response?.data?.error?.message === 'resource already exits'
        ) {
          error = 'Project name already exists.';
        } else {
          error = 'Something went wrong. Please try again after some time.';
        }
        return { ...oldState, error: payload, addProjectError: error };
      }

      return {
        ...oldState,
        projectId: payload?.data?.id,
        projects: [{ ...payload.data }, ...oldState.projects],
      };
    case action.HYP_UPDATE_LISTING_ERROR:
      return {
        ...oldState,
        error: payload,
      };

    case action.HYP_UPDATE_LISTING_SEARCH_TEXT:
      return {
        ...oldState,
        searchedText: payload,
      };

    case action.HYP_UPDATED_EDITED_PROJECT:
      const oldProjects = structuredClone(oldState.projects);
      if (oldProjects?.[payload?.index]?.name) {
        oldProjects[payload.index].name = payload?.name;
      }

      return {
        ...oldState,
        projects: oldProjects,
      };
    case action.HYP_RESET_PROJECT_LISTING:
      return {
        ...oldState,
        isLoading: true,
        projects: [],
        projectId: '',
        nextCursor: '',
        isFetchingMore: false,
      };
    case action.HYP_RESET_ADD_PROJECT_ERROR:
      return {
        ...oldState,
        addProjectError: '',
      };
    case action.HYP_SHOW_PROJECT_LOADING:
      return {
        ...oldState,
        isLoading: true,
      };
    default:
      return oldState;
  }
}

export default projectListingReducer;
