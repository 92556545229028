/**
 * Component Name: Public
 * Author: Ajo John
 * Description: Component which handles the rendering of all the public components
 */

import { Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      render={(props) => {
        return <Component {...props} />;
      }}
      {...rest}
    ></Route>
  );
};

export default PublicRoute;
