import React from 'react';

const ChevronUp = ({ width = 20, height = 20, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M13.825 12.8496L10 9.02461L6.175 12.8496L5 11.6663L10 6.66628L15 11.6663L13.825 12.8496Z"
        fill="#888888"
      />
    </svg>
  );
};

export { ChevronUp };
