import { CALL_API } from '../middleware/api';
import { USER_LIST_LOADING, USER_LIST_SUCCESS } from 'store/constants/userList';
import { getCookieData } from 'utils/cookieHandler';

let cookieName = process.env.REACT_APP_COOKIE_NAME;
let access_token = getCookieData(cookieName);

const header = {
  Authorization: `Bearer ${access_token}`,
  Accept: 'application/json',
};

export const getUserList = () => {
  const authURL = process.env.REACT_APP_LUMS_AUTH_URL || (window._env_ && window._env_.REACT_APP_LUMS_AUTH_URL);
  const endpoint = `${authURL}/api/user/organization/team?allUsers=true&includeServiceAccounts=true`;
  return {
    [CALL_API]: {
      types: [USER_LIST_LOADING, USER_LIST_SUCCESS],
      endpoint: endpoint,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      headers: header,
    },
  };
};

export default getUserList;
