import React from 'react';

const DownloadFileIcon = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 15 14"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M7.07336 12.8334H2.99338C2.83879 12.8334 2.69054 12.7719 2.58124 12.6625C2.47193 12.5531 2.41052 12.4047 2.41052 12.25V1.75002C2.41052 1.59531 2.47193 1.44694 2.58124 1.33754C2.69054 1.22815 2.83879 1.16669 2.99338 1.16669H11.1533C11.3079 1.16669 11.4562 1.22815 11.5655 1.33754C11.6748 1.44694 11.7362 1.59531 11.7362 1.75002V7.29169M10.4248 12.8334V9.04169"
        stroke="#666666"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7362 11.9584L11.2991 12.3959L10.4248 13.2709L9.5505 12.3959L9.11336 11.9584"
        stroke="#666666"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.74194 7.00002H7.07336M4.74194 4.66669H9.40479H4.74194Z"
        stroke="#666666"
        strokeWidth="0.8"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { DownloadFileIcon };
