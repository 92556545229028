import React from 'react';

const Test = ({ height = 16, width = 16, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      role="presentation"
      aria-hidden="true"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path
        d="M5.33203 14H13.332C13.6857 14 14.0248 13.8595 14.2748 13.6095C14.5249 13.3594 14.6654 13.0203 14.6654 12.6667V11.3333H6.66536V12.6667C6.66536 13.0203 6.52489 13.3594 6.27484 13.6095C6.02479 13.8595 5.68565 14 5.33203 14ZM5.33203 14C4.97841 14 4.63927 13.8595 4.38922 13.6095C4.13917 13.3594 3.9987 13.0203 3.9987 12.6667V3.33333C3.9987 2.97971 3.85822 2.64057 3.60817 2.39052C3.35813 2.14048 3.01899 2 2.66536 2C2.31174 2 1.9726 2.14048 1.72256 2.39052C1.47251 2.64057 1.33203 2.97971 1.33203 3.33333V5.33333H3.9987"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.668 11.3333V3.33333C12.668 2.97971 12.5275 2.64057 12.2774 2.39052C12.0274 2.14048 11.6883 2 11.3346 2H2.66797"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0013 5.33594H6.66797"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0013 8H6.66797"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Test;
