const SupportedIntegrations = ({ width = 33, height = 20, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 33 20"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
    >
      <path
        d="M13.6336 8.82477H12.8492V3.54097C12.8498 3.3252 12.6674 3.14545 12.431 3.12927C12.3558 3.12358 12.2803 3.12358 12.205 3.12927H12.0988C9.69806 3.12927 6.04538 5.87935 5.93223 9.30469C4.46309 9.45522 3.18131 10.2812 2.53664 11.4927C1.89198 12.7043 1.97454 14.1322 2.75523 15.2755C3.53609 16.4187 4.90591 17.1177 6.3843 17.1269H12.3974C12.5173 17.1269 12.6324 17.0835 12.7171 17.0063C12.8019 16.9291 12.8495 16.8244 12.8495 16.7152V12.3512H13.6339C14.1588 12.3676 14.6686 12.1891 15.0463 11.8566C15.4241 11.5243 15.6374 11.0665 15.6374 10.5881C15.6374 10.1099 15.4241 9.65202 15.0463 9.31956C14.6686 8.98726 14.1588 8.80882 13.6339 8.82496L13.6336 8.82477ZM13.6336 11.5276H12.3971C12.1475 11.5276 11.945 11.712 11.945 11.9393V16.3033H6.38192C5.16488 16.3066 4.0383 15.7184 3.42655 14.7601C2.815 13.802 2.81118 12.6195 3.41646 11.6581C4.02196 10.6966 5.1445 10.1023 6.36155 10.099C6.49354 10.1061 6.62251 10.0602 6.71414 9.97342C6.79911 9.89219 6.84412 9.78283 6.83846 9.6709V9.46505C6.83402 6.54809 9.9105 4.07774 11.9449 3.95258V9.23639C11.9449 9.34556 11.9926 9.45032 12.0773 9.52751C12.1621 9.60471 12.2771 9.64808 12.397 9.64808H13.6335C13.9156 9.63614 14.1907 9.72969 14.3949 9.90723C14.5994 10.0848 14.7148 10.3307 14.7148 10.5878C14.7148 10.8449 14.5994 11.0908 14.3949 11.2684C14.1907 11.4459 13.9156 11.5395 13.6335 11.5276L13.6336 11.5276Z"
        fill="url(#paint0_linear_18995_132900)"
      />
      <path
        d="M27.8371 10.1609C27.8723 9.96421 27.8896 9.76536 27.889 9.5661C27.8789 8.52049 27.4135 7.52142 26.5951 6.78808C25.7767 6.05475 24.6723 5.64729 23.524 5.65501C22.9953 5.65464 22.4707 5.7412 21.9756 5.91011C20.8423 4.3532 19.0095 3.33389 16.9708 3.12715C16.8431 3.1152 16.7155 3.15343 16.6205 3.2321C16.5252 3.31039 16.4709 3.42196 16.4713 3.53884V5.74764C16.4679 5.77485 16.4679 5.8026 16.4713 5.82998V9.23453C16.4713 9.34371 16.519 9.44847 16.6037 9.52566C16.6885 9.60285 16.8035 9.64623 16.9234 9.64623H18.1598C18.442 9.63428 18.7171 9.72783 18.9213 9.90537C19.1258 10.0829 19.2412 10.3288 19.2412 10.586C19.2412 10.8431 19.1258 11.089 18.9213 11.2666C18.7171 11.4441 18.442 11.5376 18.1598 11.5257H16.9234C16.6738 11.5257 16.4713 11.71 16.4713 11.9374V15.0663C16.4679 15.0944 16.4679 15.1227 16.4713 15.1506V16.713C16.4713 16.8222 16.519 16.927 16.6037 17.0042C16.6885 17.0814 16.8035 17.1247 16.9234 17.1247H27.0049C28.3017 17.1455 29.5235 16.5723 30.2578 15.5987C30.9918 14.625 31.1404 13.3807 30.6534 12.286C30.1662 11.1914 29.1082 10.3924 27.8368 10.1588L27.8371 10.1609ZM27.0052 16.3015H17.3753V13.0717C17.3787 13.0437 17.3787 13.0154 17.3753 12.9873V12.3492H18.1596C18.6845 12.3656 19.1943 12.1871 19.572 11.8546C19.9498 11.5223 20.1631 11.0645 20.1631 10.5861C20.1631 10.1078 19.9498 9.65 19.572 9.31754C19.1944 8.98524 18.6845 8.8068 18.1596 8.82294H17.3753V8.41124C17.3787 8.38404 17.3787 8.35629 17.3753 8.3289V4.01863C19.0696 4.33199 20.5385 5.28495 21.4171 6.64096C21.538 6.82107 21.7889 6.88871 21.998 6.79755C22.4735 6.58729 22.9952 6.47811 23.5238 6.47849C24.432 6.47132 25.3061 6.79222 25.9548 7.37099C26.6035 7.94976 26.9739 8.73913 26.9846 9.56619C26.9834 9.84464 26.9402 10.1218 26.8558 10.3896C26.8187 10.5083 26.8423 10.6359 26.9198 10.7373C26.9973 10.8386 27.1206 10.9029 27.2558 10.9123C28.313 10.9531 29.2658 11.5045 29.7557 12.3585C30.2455 13.2126 30.1977 14.2398 29.6301 15.0531C29.0628 15.8664 28.0621 16.3422 27.0049 16.3014L27.0052 16.3015Z"
        fill="url(#paint1_linear_18995_132900)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_18995_132900"
          x1="2.91094"
          y1="3.125"
          x2="20.65"
          y2="8.73683"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#215D8B" />
          <stop offset="1" stopColor="#14A5B9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_18995_132900"
          x1="17.3299"
          y1="3.125"
          x2="36.0512"
          y2="9.45772"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#215D8B" />
          <stop offset="1" stopColor="#14A5B9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SupportedIntegrations;
