import {
  HYPERTEST_ASSOCIATED_TEST_ACTIONS_REQUEST,
  HYPERTEST_ASSOCIATED_TEST_ACTIONS_RESPONSE,
  HYPERTEST_ASSOCIATED_TEST_DETAIL_ACTIONS_REQUEST,
  HYPERTEST_ASSOCIATED_TEST_DETAIL_ACTIONS_RESPONSE,
  HYPERTEST_ASSOCIATED_TEST_DETAIL_REAL_DEVICE_ACTIONS_RESPONSE,
  HYPERTEST_ASSOCIATED_TEST_DETAIL_MORE_ACTIONS_REQUEST,
  HYPERTEST_ASSOCIATED_TEST_DETAIL_MORE_ACTIONS_RESPONSE,
  RESET_HYPERTEST_ASSOCIATED_TEST_DETAIL,
} from '../constants/associatedTest';

const initialState = {
  associatedTest: {
    data: null,
    loading: false,
    error: false,
  },
  associatedTestDetail: {
    data: null,
    loading: false,
    error: false,
  },
  associatedTestDetailMore: {
    data: [],
    loading: false,
    error: false,
  },
};

function associatedTestReducer(state = initialState, actions) {
  const { type, payload, error } = actions;
  switch (type) {
    case HYPERTEST_ASSOCIATED_TEST_ACTIONS_REQUEST:
      return {
        ...state,
        associatedTest: {
          ...state.associatedTest,
          loading: true,
        },
        associatedTestDetail: {
          data: [],
          loading: false,
          error: false,
        },
      };
    case HYPERTEST_ASSOCIATED_TEST_ACTIONS_RESPONSE:
      return {
        ...state,
        associatedTest: {
          ...state.associatedTest,
          data: payload,
          loading: false,
          error: error === undefined ? false : error,
        },
      };
    case HYPERTEST_ASSOCIATED_TEST_DETAIL_ACTIONS_REQUEST:
      return {
        ...state,
        associatedTestDetail: {
          ...state.associatedTestDetail,
          loading: true,
        },
      };
    case HYPERTEST_ASSOCIATED_TEST_DETAIL_ACTIONS_RESPONSE:
      return {
        ...state,
        associatedTestDetail: {
          ...state.associatedTestDetail,
          data: payload,
          loading: false,
          error: error === undefined ? false : error,
        },
      };
    case HYPERTEST_ASSOCIATED_TEST_DETAIL_REAL_DEVICE_ACTIONS_RESPONSE:
      const data = payload?.data?.data;
      const transformed = {};
      if (data && data.length) {
        const {
          End_timestamp,
          Start_timestamp,
          testId,
          test_status = '',
          test_result = '',
          test_name,
        } = data[0];
        transformed['EndTimestamp'] = End_timestamp;
        transformed['StartTimestamp'] = Start_timestamp;
        transformed['Id'] = testId;
        transformed['TestResult'] = test_result ? test_result : test_status;
        transformed['Name'] = test_name;
      }
      return {
        ...state,
        associatedTestDetail: {
          ...state.associatedTestDetail,
          data: [transformed],
          loading: false,
          error: error === undefined ? false : error,
        },
      };
    case HYPERTEST_ASSOCIATED_TEST_DETAIL_MORE_ACTIONS_REQUEST:
      return {
        ...state,
        associatedTestDetailMore: {
          ...state.associatedTestDetailMore,
          loading: true,
        },
      };

    case HYPERTEST_ASSOCIATED_TEST_DETAIL_MORE_ACTIONS_RESPONSE:
      let apiResponse = payload || [];
      let updatedList = [
        ...state.associatedTestDetailMore.data,
        ...apiResponse,
      ];
      let hasMore = apiResponse && apiResponse.length >= 20 ? true : false;
      return {
        ...state,
        associatedTestDetailMore: {
          ...state.associatedTestDetailMore,
          data: updatedList,
          loading: false,
          error: error === undefined ? false : error,
          hasMore: hasMore,
        },
      };
    case RESET_HYPERTEST_ASSOCIATED_TEST_DETAIL:
      return {
        ...state,
        associatedTestDetail: {
          data: [],
          loading: false,
          error: false,
        },
        associatedTest: {
          data: null,
          loading: false,
          error: false,
        },
        associatedTestDetailMore: {
          data: [],
          loading: false,
          error: false,
        },
      };
    default:
      return state;
  }
}

export default associatedTestReducer;
