import React from 'react';

const Help = ({ width, height, className, color = '#333333', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      viewBox="0 0 20 20"
      fill="none"
      aria-hidden="true"
      aria-hidden="true"
    >
      <path
        d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656Z"
        fill={color}
      />
      <path
        d="M12.1797 6.18555C11.5937 5.67188 10.8203 5.39062 10 5.39062C9.17969 5.39062 8.40625 5.67383 7.82031 6.18555C7.21094 6.71875 6.875 7.43555 6.875 8.20312V8.35156C6.875 8.4375 6.94531 8.50781 7.03125 8.50781H7.96875C8.05469 8.50781 8.125 8.4375 8.125 8.35156V8.20312C8.125 7.3418 8.9668 6.64062 10 6.64062C11.0332 6.64062 11.875 7.3418 11.875 8.20312C11.875 8.81055 11.4453 9.36719 10.7793 9.62305C10.3652 9.78125 10.0137 10.0586 9.76172 10.4219C9.50586 10.793 9.37305 11.2383 9.37305 11.6895V12.1094C9.37305 12.1953 9.44336 12.2656 9.5293 12.2656H10.4668C10.5527 12.2656 10.623 12.1953 10.623 12.1094V11.666C10.6241 11.4764 10.6822 11.2915 10.7898 11.1354C10.8975 10.9793 11.0497 10.8593 11.2266 10.791C12.3789 10.3477 13.123 9.33203 13.123 8.20312C13.125 7.43555 12.7891 6.71875 12.1797 6.18555ZM9.21875 14.2969C9.21875 14.5041 9.30106 14.7028 9.44757 14.8493C9.59409 14.9958 9.7928 15.0781 10 15.0781C10.2072 15.0781 10.4059 14.9958 10.5524 14.8493C10.6989 14.7028 10.7812 14.5041 10.7812 14.2969C10.7812 14.0897 10.6989 13.891 10.5524 13.7444C10.4059 13.5979 10.2072 13.5156 10 13.5156C9.7928 13.5156 9.59409 13.5979 9.44757 13.7444C9.30106 13.891 9.21875 14.0897 9.21875 14.2969Z"
        fill={color}
      />
    </svg>
  );
};

export { Help };
