import React from 'react';

const AppleLarge = ({ width = '101', height = '128', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 101 128"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <g
        id="New-Designs-Coloured-Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Posters---Automation"
          transform="translate(-340.000000, -765.000000)"
          fill="#B5B8BD"
          fillRule="nonzero"
        >
          <g id="Apple" transform="translate(70.000000, 604.000000)">
            <path
              d="M338.99894,180.290855 C343.801667,175.177742 347.050185,168.078857 346.161002,161 C339.241922,161.245555 330.856193,165.039028 325.889727,170.151706 C321.437681,174.677925 317.539898,181.911778 318.580124,188.851749 C326.300826,189.371158 334.188332,185.395696 338.99894,180.290855 Z M353.609953,229.711081 C353.461656,213.696074 366.309338,206.004249 366.878322,205.632553 C359.655351,194.757146 348.406369,193.269044 344.400159,193.101209 C334.821604,192.097278 325.723583,198.892815 320.860597,198.892815 C316.017413,198.892815 308.526012,193.233017 300.5818,193.385034 C290.138108,193.556823 280.515108,199.628738 275.151346,209.251977 C264.302368,228.61137 272.370674,257.289527 282.94242,272.99259 C288.110361,280.680021 294.263571,289.303723 302.343758,288.991779 C310.143634,288.679836 313.084044,283.812198 322.4945,283.812198 C331.908917,283.812198 334.544813,288.991779 342.781217,288.835807 C351.161958,288.679836 356.465873,281.000313 361.589809,273.288716 C367.518594,264.373282 369.962628,255.741672 370.102564,255.30583 C369.922584,255.201702 353.782012,248.862218 353.609953,229.711081 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AppleLarge;
