import React, { memo } from 'react';
import AbortButton from './AbortButton';
import { UpArrow } from './UpArrow';
import { Matrix } from './Matrix';
import { AutoSplit } from './AutoSplit';
import { SearchIcon } from './SearchIcon.js';
import { Passed } from './Status/Passed.js';
import { CompletedIcon } from './Status/CompletedIcon.js';
import { FailFast } from './Status/FailFast.js';
import { Failed } from './Status/Failed.js';
import { Running } from './Status/Running.js';
import { Pending } from './Status/Pending.js';
import Initiated from './Status/Initated';
import { Checkbox } from './Checkbox';
import { CheckboxChecked } from './CheckboxChecked';
import ThreeDots from './ThreeDots';
import AbortedGray from './Status/AbortedGray';
import LambdaErrorGray from './Status/LambdaErrorGray';
import TimeoutGray from './Status/TimeoutGray';
import FailedGray from './Status/FailedGray';
import CancelledGray from './Status/CancelledGray';
import CompletedGray from './Status/CompletedGray';
import QueuedGray from './Status/QueuedGray';
import InitiatedGray from './Status/InititatedGray';
import RunningGray from './Status/RunningGray';
import SmartUI from './SmartUI';
import LeftChevron from './LeftChevron';
import VisibilityEye from './VisibilityEye';
import Tosca from './Tosca';
import Katalon from './Katalon';
import Refresh from './Refresh';
import ToscaServer from './ToscaServer';

import AbortInfo from './AbortInfo';
import AbortArrow from './AbortArrow';
import { Cancelled } from './Status/Cancelled';
// import { Aborted } from './Status/Aborted';
// import { Queued } from './Status/Queued';
import { Windows } from './Plateform/Windows';
import { MacOS } from './Plateform/MacOS';
import { Linux } from './Plateform/Linux';
import { DownloadFileIcon } from './DownloadFileIcon';
import { ExpandArrow } from './ExpandArrow';
import { ExpandExternal } from './ExpandExternal';
import { LogAvailable } from './Status/LogAvailable';
import { FilterIcon } from './FilterIcon';
import { CloseIcon } from './CloseIcon';
import { CloseWhiteIcon } from './CloseWhiteIcon';
import { DeleteIcon } from './Delete';
import { Artifect } from './Artifect';
import { Queued } from './Status/Queued';
import { Abort } from './Status/Abort';
import { Timeout } from './Status/Timeout';
import { LambdaError } from './Status/LambdaError';
import StageAbort from './Status/Stage/Abort';
import StageTimeout from './Status/Stage/Timeout';
import StageLambdaError from './Status/Stage/LambdaError';
import StageCreated from './Status/Stage/Created';
import StageRunning from './Status/Stage/Running';
import StageCancelled from './Status/Stage/Cancelled';
import StageQueued from './Status/Stage/Queued';
import StageCompleted from './Status/Stage/Completed';
import { CompletedFilled } from './Status/CompletedFilled';
import { Clock } from './Status/Clock';
import { RetryClock } from './Status/RetryClock';
import StagePassed from './Status/Stage/Passed';
import StageFailed from './Status/Stage/Failed';
import { StageSkipped } from './Status/Stage/Skipped';
import { Success } from './Toast/Success';
import { Warning } from './Toast/Warning';
import { Error } from './Toast/Error';
import { Robot } from './Robot';
import { CloseSearchIcon } from './CloseSearchIcon';
import { Duration } from './DurationIcon';
import { DocumentationIcon } from './DocumentationIcon';
import { Rescheduled } from './Rescheduled';
import { HandIcon } from './HandIcon';
import { RobotOld } from './RobotOld';
import { OnboardingLaunchImage } from './OnboardingLaunchImage';
import { Retry } from './Retry';
import { CompletedGreen } from './Status/CompletedGreen';
import { Key } from './Key';
import { Edit } from './Edit';
import { ManSecret } from './ManSecret';
import { Info } from './Info';
import { InfoGray } from './InfoGray';
import { InfoBlue } from './InfoBlue';
import { Hybrid } from './Hybrid';
import { HeadPhone } from './Onboarding/HeadPhone';
import { BlueCheck } from './Onboarding/BlueCheck';
import { DownloadIcon } from './DownloadIcon';
import { Copy } from './Copy';
import { Reset } from './Onboarding/Reset';
import { OnboardWindow } from './Onboarding/platform/Window';
import { OnboardWindowDark } from './Onboarding/platform/WindowDark';
import { OnboardLinuxDark } from './Onboarding/platform/LinuxDark';
import { OnboardLinux } from './Onboarding/platform//Linux';
import { OnboardMacos } from './Onboarding/platform/Macos';
import { Github } from './Onboarding/Github';
import { Help } from './Help';
import { QuickStart } from './Onboarding/QuickStart';
import { SupportIconLarge } from './Onboarding/SupportIconLarge';
import { DownloadCodeIcon } from './DownloadCodeIcon';
import { RediretCircle } from './Onboarding/RedirectCircle';
import { RedirectHorizontalArrow } from './Onboarding/RedirectHorizontalArrow';
import { BackIcon } from './Onboarding/BackIcon';
import { FeatureCheckIcon } from './FeatureCheckIcon';
import RightArrow from './Trial/RightArrow';
import SandTimer from './SandTimer';
import AlertIcon from './AlertIcon';
import { Thunder } from './Thunder';
import { Accesskey } from './Accesskey';
import { ScrollUpArrow } from './ScrollUpArrow';
import { ScrollDownArrow } from './ScrollDownArrow';
import { ThunderLTColor } from './ThunderLTColor';
import { Star } from './Star';
import { UserDemark } from './UserDemark';
import { Skipped } from './Status/Skipped';
import BuildErrors from './VRScreenshots/BuildErrors';
import ChangesFound from './VRScreenshots/ChangesFound';
import SystemApproved from './VRScreenshots/SystemApproved';
import UserApproved from './VRScreenshots/UserApproved';
import UnderReview from './VRScreenshots/UnderReview';
import Compare from './VRScreenshots/Compare';
import Bug from './VRScreenshots/Bug';
import Approve from './VRScreenshots/Approve';
import { FilterCalender } from './FilterIcons/Calender';
import { FilterStatus } from './FilterIcons/Status';
import { FilterUsers } from './FilterIcons/Users';
import { FilterLable } from './FilterIcons/Lable';
import { FilterType } from './FilterIcons/Type';
import { FilterRightArrow } from './FilterIcons/RightArrow';
import { SelectedCircle } from './FilterIcons/SelectedCircle';
import { Tunnel } from './Tunnel';
import { FullScreenArrow } from './FullScreenArrow';
import { ExitFullScreenArrow } from './ExitFullScreenArrow';
import { Docs } from './support/Docs';
import { GithubSupport } from './support/Github';
import { Quickstart } from './support/Quickstart';
import { GitpodSupport } from './support/Gitpod';
import { ChatSupport } from './support/Chat';
import Video from './Video';
import PlayLarge from './PlayLarge';
import LinuxLarge from './LinuxLarge';
import WindowsLarge from './WindowsLarge';
import AndroidLarge from './AndroidLarge';
import AppleLarge from './AppleLarge';
import TaskSearch from './TaskSearch';
import { ChevronUp } from './Onboarding/CheveronUp';
import { ChevronDown } from './Onboarding/ChevronDown';
import { CompletedStep } from './Onboarding/CompletedStep';
import { CheckBoldIcon } from './Onboarding/CheckBoldIcon';
import { RocketIcon } from './Onboarding/RocketIcon';
import GuidedTour from './Onboarding/GuidedTour';
import ConceptsIcon from './Onboarding/ConceptsIcon';
import FeaturesIcon from './Onboarding/FeaturesIcon';
import HyperExecuteCliIcon from './Onboarding/HyperExecuteCliIcon';
import HyperExecuteYamlIcon from './Onboarding/HyperExecuteYamlIcon';
import IntegrationIcon from './Onboarding/IntegrationIcon';
import HyperExecuteYamlCli from './HyperexecuteCli';
import CrossBoldIcon from './CrossBoldIcon';
import HistoryIcon from './HistoryIcon';
import RedirectIcon from './RedirectIcon';
import ChromeIcon from './browser/ChromeIcon';
import DesktopIcon from './DesktopIcon';
import SafariIcon from './browser/SafariIcon';
import EdgeIcon from './browser/EdgeIcon';
import ExplorerIcon from './browser/ExplorerIcon';
import OperaIcon from './browser/OperaIcon';
import FirefoxIcon from './browser/FirefoxIcon';
import TestStatus from './VRScreenshots/TestStatus';
import GenerateYamlIcon from './support/GenerateYamlIcon';
import Plus from './Plus';
import Run from './Run';
import ViewJobs from './ViewJobs';
import ThreeDots2 from './ThreeDots2';
import ThunderLogo from './Trial/ThunderLogo';
import FirstTestIcon from './Trial/FirstTestIcon';
import ConfigureHYEIcon from './Trial/ConfigureHYEIcon';
import SupportedIntegrations from './Trial/SupportedIntegrations';
import LinkArrowIcon from './Trial/LinkArrowIcon';
import Minus from './Minus';
import ViewIcon from './FilterIcons/View';

import ThumbsUp from './Feedback/ThumbsUp';
import ThumbsDown from './Feedback/ThumbsDown';

import CheckMark from './CheckMark';

import InsightAborted from './insight/overview/Aborted';
import InsightCancelled from './insight/overview/Cancelled';
import InsightCreated from './insight/overview/Created';
import InsightFailed from './insight/overview/Failed';
import InsightInitiated from './insight/overview/Initiated';
import InsightLambdaError from './insight/overview/LambdaError';
import InsightRunning from './insight/overview/Running';
import InsightSkipped from './insight/overview/Skipped';
import InsightTimeout from './insight/overview/Timeout';

import ErrorSymbol from './Error';
import LambdaTest from './LambdaTest';
import UpArrow2 from './UpArrow2';
import GeneratingRCA from './GenerativeRCA';
import RDAndroid from './RealDevice/Android';
import RDAppium from './RealDevice/Appium';
import RDEspresso from './RealDevice/Espresso';
import RDXcui from './RealDevice/Xcui';
import AssociatedError from './AError';
import AssociatedIgnored from './AIgnored';
import ThreeDots3 from './ThreeDots3';

export const Icon = memo((props) => {
  switch (props.name.toLowerCase()) {
    case 'a-error':
      return <AssociatedError {...props} />;
    case 'a-ignored':
      return <AssociatedIgnored {...props} />;
    case 'tosca-server':
      return <ToscaServer {...props} />;
    case 'rd/xcui':
      return <RDXcui {...props} />;
    case 'rd/android':
      return <RDAndroid {...props} />;
    case 'rd/appium':
      return <RDAppium {...props} />;
    case 'rd/espresso':
      return <RDEspresso {...props} />;
    case 'up-arrow-2':
      return <UpArrow2 {...props} />;
    case 'spinner':
      return <GeneratingRCA {...props} />;
    case 'lambda-test':
      return <LambdaTest {...props} />;
    case 'error-symbol':
      return <ErrorSymbol {...props} />;
    case 'insight-aborted':
      return <InsightAborted {...props} />;
    case 'insight-cancelled':
      return <InsightCancelled {...props} />;
    case 'insight-created':
      return <InsightCreated {...props} />;
    case 'insight-failed':
      return <InsightFailed {...props} />;
    case 'insight-initiated':
      return <InsightInitiated {...props} />;
    case 'insight-lambda-error':
      return <InsightLambdaError {...props} />;
    case 'insight-running':
      return <InsightRunning {...props} />;
    case 'insight-skipped':
      return <InsightSkipped {...props} />;
    case 'insight-timeout':
      return <InsightTimeout {...props} />;
    case 'plus':
      return <Plus {...props} />;
    case 'check-mark':
      return <CheckMark {...props} />;
    case 'refresh':
      return <Refresh {...props} />;
    case 'tosca':
      return <Tosca {...props} />;
    case 'katalon':
      return <Katalon {...props} />;
    case 'minus':
      return <Minus {...props} />;
    case 'run':
      return <Run {...props} />;
    case 'view-jobs':
      return <ViewJobs {...props} />;
    case 'three-dots2':
      return <ThreeDots2 {...props} />;
    case 'three-dots3':
      return <ThreeDots3 {...props} />;
    case 'play-large':
      return <PlayLarge {...props} />;
    case 'visibility-eye':
      return <VisibilityEye {...props} />;
    case 'smart-ui':
      return <SmartUI {...props} />;
    case 'left-chevron':
      return <LeftChevron {...props} />;
    case 'task-search':
      return <TaskSearch {...props} />;
    case 'linux-large':
      return <LinuxLarge {...props} />;
    case 'windows-large':
      return <WindowsLarge {...props} />;
    case 'android-large':
      return <AndroidLarge {...props} />;
    case 'apple-large':
      return <AppleLarge {...props} />;
    case 'three-dots':
      return <ThreeDots {...props} />;
    case 'video':
      return <Video {...props} />;
    case 'abort-gray':
      return <AbortedGray {...props} />;
    case 'running-gray':
      return <RunningGray {...props} />;
    case 'lambda-error-gray':
      return <LambdaErrorGray {...props} />;
    case 'timeout-gray':
      return <TimeoutGray {...props} />;
    case 'completed-gray':
    case 'skipped-gray':
      return <CompletedGray {...props} />;
    case 'initiated-gray':
      return <InitiatedGray {...props} />;
    case 'queued-gray':
      return <QueuedGray {...props} />;
    case 'failed-gray':
      return <FailedGray {...props} />;
    case 'cancelled-gray':
      return <CancelledGray {...props} />;
    case 'up-arrow':
      return <UpArrow {...props} />;
    case 'passed-status':
      return <Passed {...props} />;
    case 'completed-status':
      return <CompletedIcon {...props} />;
    case 'fail-fast':
      return <FailFast {...props} />;
    case 'failed-status':
      return <Failed {...props} />;
    case 'running-status':
      return <Running {...props} />;
    case 'completed-green':
      return <CompletedGreen {...props} />;
    case 'log-available':
      return <LogAvailable {...props} />;
    case 'completed-filled':
      return <CompletedFilled {...props} />;
    case 'clock':
      return <Clock {...props} />;
    case 'checkbox':
      return <Checkbox {...props} />;
    case 'checkbox-checked':
      return <CheckboxChecked {...props} />;
    case 'retry-clock':
      return <RetryClock {...props} />;
    case 'timeout':
      return <Timeout {...props} />;
    case 'skipped':
      return <Skipped {...props} />;
    case 'initiated-status':
      return <Initiated {...props} />;
    case 'lambda_error':
    case 'lambda error':
      return <LambdaError {...props} />;
    case 'cancelled-status':
      return <Cancelled {...props} />;
    case 'pending-status':
      return <Pending {...props} />;
    case 'aborted-status':
      return <Abort {...props} />;
    case 'queued-status':
      return <Queued {...props} />;
    case 'search':
      return <SearchIcon {...props} />;
    case 'matrix':
      return <Matrix {...props} />;
    case 'autosplit':
      return <AutoSplit {...props} />;
    case 'hybrid':
      return <Hybrid {...props} />;
    case 'win':
      return <Windows {...props} />;
    case 'mac':
      return <MacOS {...props} />;
    case 'linux':
      return <Linux {...props} />;
    case 'download-file-icon':
      return <DownloadFileIcon {...props} />;
    case 'download-code-icon':
      return <DownloadCodeIcon {...props} />;
    case 'expand-arrow':
      return <ExpandArrow {...props} />;
    case 'expand':
      return <ExpandExternal {...props} />;
    case 'filter':
      return <FilterIcon {...props} />;
    case 'close':
      return <CloseIcon {...props} />;
    case 'close-white':
      return <CloseWhiteIcon {...props} />;
    case 'delete':
      return <DeleteIcon {...props} />;
    case 'artifect':
      return <Artifect {...props} />;
    case 'stage-passed':
      return <StagePassed {...props} />;
    case 'stage-completed':
      return <StageCompleted {...props} />;
    case 'stage-failed':
      return <StageFailed {...props} />;
    case 'stage-running':
      return <StageRunning {...props} />;
    case 'stage-cancelled':
      return <StageCancelled {...props} />;
    case 'stage-pending':
      return <StageQueued {...props} />;
    case 'stage-aborted':
      return <StageAbort {...props} />;
    case 'stage-created':
      return <StageCreated {...props} />;
    case 'stage-timeout':
      return <StageTimeout {...props} />;
    case 'stage-skipped':
      return <StageSkipped {...props} />;
    case 'stage-lambda_error':
      return <StageLambdaError {...props} />;
    case 'check':
      return <Success {...props} />;
    case 'warning':
      return <Warning {...props} />;
    case 'error':
      return <Error {...props} />;
    case 'robot':
      return <Robot {...props} />;
    case 'robot-old':
      return <RobotOld {...props} />;
    case 'clear-search':
      return <CloseSearchIcon {...props} />;
    case 'duration':
      return <Duration {...props} />;
    case 'documentation':
      return <DocumentationIcon {...props} />;
    case 'hand':
      return <HandIcon {...props} />;
    case 'onboarding-launch-image':
      return <OnboardingLaunchImage {...props} />;
    case 'abort-button':
      return <AbortButton {...props} />;
    case 'abort-arrow':
      return <AbortArrow {...props} />;
    case 'abort-info':
      return <AbortInfo {...props} />;
    case 'retry':
      return <Retry {...props} />;
    case 'key':
      return <Key {...props} />;
    case 'edit':
      return <Edit {...props} />;
    case 'man-secret':
      return <ManSecret {...props} />;
    case 'info':
      return <Info {...props} />;
    case 're-scheduled':
      return <Rescheduled {...props} />;
    case 'info-gray':
      return <InfoGray {...props} />;
    case 'info-blue':
      return <InfoBlue {...props} />;
    case 'headphone':
      return <HeadPhone {...props} />;
    case 'blue-check':
      return <BlueCheck {...props} />;
    case 'download-icon':
      return <DownloadIcon {...props} />;
    case 'copy-icon':
      return <Copy {...props} />;
    case 'reset-icon':
      return <Reset {...props} />;
    case 'onboard/windows':
      return <OnboardWindow {...props} />;
    case 'onboard/windows-dark':
      return <OnboardWindowDark {...props} />;
    case 'onboard/linux-dark':
      return <OnboardLinuxDark {...props} />;
    case 'onboard/macos':
      return <OnboardMacos {...props} />;
    case 'onboard/linux':
      return <OnboardLinux {...props} />;
    case 'github':
      return <Github {...props} />;
    case 'help':
      return <Help {...props} />;
    case 'quick-start':
      return <QuickStart {...props} />;
    case 'support-icon':
      return <SupportIconLarge {...props} />;
    case 'redirect-circle':
      return <RediretCircle {...props} />;
    case 'redirect-horizontal-arrow':
      return <RedirectHorizontalArrow {...props} />;
    case 'back-icon':
      return <BackIcon {...props} />;
    case 'feature-check-icon':
      return <FeatureCheckIcon {...props} />;
    case 'right-arrow':
      return <RightArrow {...props} />;
    case 'sand-timer':
      return <SandTimer {...props} />;
    case 'alert':
      return <AlertIcon {...props} />;
    case 'thunder':
      return <Thunder {...props} />;
    case 'accesskey':
      return <Accesskey {...props} />;
    case 'scroll-up-arrow':
      return <ScrollUpArrow {...props} />;
    case 'scroll-down-arrow':
      return <ScrollDownArrow {...props} />;
    case 'thunder-lt-color':
      return <ThunderLTColor {...props} />;
    case 'star':
      return <Star {...props} />;
    case 'user-demarcation':
      return <UserDemark {...props} />;
    case 'vr-build-errors':
      return <BuildErrors {...props} />;
    case 'vr-changes-found':
      return <ChangesFound {...props} />;
    case 'vr-system-approved':
      return <SystemApproved {...props} />;
    case 'vr-user-approved':
      return <UserApproved {...props} />;
    case 'vr-test-status':
      return <TestStatus {...props} />;
    case 'vr-under-review':
      return <UnderReview {...props} />;
    case 'vr-compare':
      return <Compare {...props} />;
    case 'vr-bug':
      return <Bug {...props} />;
    case 'vr-approve':
      return <Approve {...props} />;
    case 'filter/calender':
      return <FilterCalender {...props} />;
    case 'filter/user':
      return <FilterUsers {...props} />;
    case 'filter/lable':
      return <FilterLable {...props} />;
    case 'filter/type':
      return <FilterType {...props} />;
    case 'filter/status':
      return <FilterStatus {...props} />;
    case 'filter/right-arrow':
      return <FilterRightArrow {...props} />;
    case 'filter/selected-circle':
      return <SelectedCircle {...props} />;
    case 'tunnel':
      return <Tunnel {...props} />;
    case 'full-screen':
      return <FullScreenArrow {...props} />;
    case 'exit-full-screen':
      return <ExitFullScreenArrow {...props} />;
    case 'support/docs':
      return <Docs {...props} />;
    case 'support/github':
      return <GithubSupport {...props} />;
    case 'support/gitpod':
      return <GitpodSupport {...props} />;
    case 'support/quickstart':
      return <Quickstart {...props} />;
    case 'support/chat':
      return <ChatSupport {...props} />;
    case 'chevron/up':
      return <ChevronUp {...props} />;
    case 'chevron/down':
      return <ChevronDown {...props} />;
    case 'completed-step':
      return <CompletedStep {...props} />;
    case 'check-bold':
      return <CheckBoldIcon {...props} />;
    case 'rocket-icon':
      return <RocketIcon {...props} />;
    case 'onboarding/guided-tour':
      return <GuidedTour {...props} />;
    case 'onboarding/concepts':
      return <ConceptsIcon {...props} />;
    case 'onboarding/feature':
      return <FeaturesIcon {...props} />;
    case 'onboarding/cli':
      return <HyperExecuteCliIcon {...props} />;
    case 'onboarding/yaml':
      return <HyperExecuteYamlIcon {...props} />;
    case 'onboarding/integration':
      return <IntegrationIcon {...props} />;
    case 'support/cli':
      return <HyperExecuteYamlCli {...props} />;
    case 'support/yaml':
      return <GenerateYamlIcon {...props} />;
    case 'onboard/crossbold':
      return <CrossBoldIcon {...props} />;
    case 'history-icon':
      return <HistoryIcon {...props} />;
    case 'redirect-icon':
      return <RedirectIcon {...props} />;
    case 'chrome':
      return <ChromeIcon {...props} />;
    case 'safari':
      return <SafariIcon {...props} />;
    case 'edge':
      return <EdgeIcon {...props} />;
    case 'microsoftedge':
      return <EdgeIcon {...props} />;
    case 'internet explorer':
      return <ExplorerIcon {...props} />;
    case 'opera':
      return <OperaIcon {...props} />;
    case 'firefox':
      return <FirefoxIcon {...props} />;
    case 'desktop-icon':
      return <DesktopIcon {...props} />;
    case 'trial-thunder-logo':
      return <ThunderLogo {...props} />;
    case 'trial-first-test':
      return <FirstTestIcon {...props} />;
    case 'trial-configuration':
      return <ConfigureHYEIcon {...props} />;
    case 'trial-integration':
      return <SupportedIntegrations {...props} />;
    case 'trial-link-arrow':
      return <LinkArrowIcon {...props} />;
    case 'view-icon':
      return <ViewIcon {...props} />;
    case 'feedback/thumbs-up':
      return <ThumbsUp {...props} />;
    case 'feedback/thumbs-down':
      return <ThumbsDown {...props} />;
    default:
      return null;
  }
});
