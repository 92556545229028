import React from 'react';

const Star = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <g clipPath="url(#clip0_661_940)">
        <path
          d="M14.7649 4.44303L10.3568 8.44551L4.44271 7.75609L8.44519 12.1642L7.75577 18.0783L12.1639 14.0758L18.0779 14.7652L14.0755 10.3571L14.7649 4.44303Z"
          fill="#313131"
        />
      </g>
      <defs>
        <clipPath id="clip0_661_940">
          <rect
            width="16.725"
            height="16.725"
            fill="white"
            transform="translate(7.64648) rotate(27.2052)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { Star };
