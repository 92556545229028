const TestStatus = ({
  width = '12px',
  height = '12px',
  className,
  color = '#CF370E',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
    >
      <path d="M6.83319 1.72195H11.0001V10.3867H6.83319" stroke={color} />
      <path d="M5.16675 10.3862H0.999849V1.72141H5.16675" stroke={color} />
      <path d="M6.00037 0.5L6.00037 11.5" stroke={color} />
      <path
        d="M5.16675 9.6642L1.83323 9.6642L3.91668 7.85905L5.16675 6.77595L5.16675 9.6642Z"
        fill={color}
      />
    </svg>
  );
};

export default TestStatus;
