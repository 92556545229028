import React from 'react';

const CompletedFilled = ({ width = 18, height = 18, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <circle cx="7" cy="7" r="7" fill="#2AB14F" />
      <path
        d="M4.16797 7.21687L6.08992 8.89391C6.17448 8.9677 6.30274 8.95944 6.37714 8.8754L9.95497 4.83398"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { CompletedFilled };
