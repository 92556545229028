import { useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce, get } from 'lodash';

import {
  SETTING_UP_ENVIRONMENT,
  PREPARING_FOR_TEST_DISCOVERY,
  DETECTING_TEST_DETAILS,
  FINALIZING_SETUP,
  FirstLoadInProgress,
  FirstLoadCompleted,
  FirstLoadFailed,
} from 'constants/project-detail';

import { fetchRCA } from 'store/actions/rca';

import {
  fetchOrgPreferences,
  fetchUserInfo,
  fetchUserTeamMember,
  updateCommonUiVisual,
} from '../store/actions/userInfo';
import {
  getHypertestTaskLog_SSE,
  setCurrentRunningLogType,
} from '../store/actions/hypertestTaskLog';
import {
  getHypertestJobDetail_SSE,
  setReportDownloadButtonStatus,
  checkReportIsCreated,
  setArtefectIsOpenStatus,
} from '../store/actions/hypertestJobDetail';
import {
  getHypertestJobDetailToUpdateJobList_SSE,
  insertNewJobIntoJoblist,
} from '../store/actions/hypertestJobList';
import { getHypertestConcurrency } from '../store/actions/concurrency';
import useFeatureFlag from 'hooks/useFeatureFlag';

import {
  addChunkNumberInTrackingWithId,
  emitEvent,
  getChunkNumberForSSEEvent,
  isChunkNumberAlreadyExecuted,
} from '../utils/eventEmitter';
import { getHypertestSSEEvent } from '../store/actions/hypertestTasksList';
import { setPromoBannerHeight } from '../store/actions/setting';
import { setCurrentArtefactClick } from 'store/actions/artifacts';

import { Path } from 'constants/routes';
import { sendAmplitudeEvents } from 'utils/common';
import { EventNames } from 'utils/events';
import { updateTriggerTestStatus } from 'store/actions/onboarding';
import {
  setProjectDetailFetch,
  setProjectStages,
} from 'store/actions/projectDetail';
import { redirectToLogin } from 'utils/redirectToLogin';
import getUserList from 'store/actions/userList';
import { fetchPreferences } from 'store/actions/preference';

const queryString = require('query-string');

const SSE_Event = {
  CONCURRENCY_UPDATED: 'CONCURRENCY_UPDATED',
  ERROR: 'error',
  JOB_CREATED: 'JOB_CREATED',
  JOB_UPDATED: 'JOB_UPDATED',
  REPORT_UPDATED: 'REPORT_UPDATED', // done
  STAGE_CREATED: 'STAGE_CREATED', // Done
  STAGE_LOG_AVAILABLE: 'STAGE_LOG_AVAILABLE',
  STAGE_UPDATED: 'STAGE_UPDATED', // Done
  TASK_UPDATED: 'TASK_UPDATED',
  QUICK_RUN_TASK_TRIGGRED: 'CLI-STARTED',
  QUICK_RUN_TASK_COMPLETED: 'CLI-COMPLETED',
  QUICK_RUN_TASK_LOG_UPDATED: 'CLI-LOG-STREAM',
  DISCOVERY_RESULT: 'DISCOVERY_RESULT',
  CATEGORIZED_ERROR_LOG_AVAILABLE: 'CATEGORIZED_ERROR_LOG_AVAILABLE',
};

const SSE_DEBOUNCE_TIME = 200;

// let isSSEEventsStoped = true;
const useApp = () => {
  //let location = useLocation();
  const userInfo = useSelector((state) => state.userInfo?.userInfo);
  const projectDiscoverJobId = useSelector(
    (state) => state.projectDetail?.discoveryJobId
  );
  let promoBannerHeight =
    useSelector((state) => state.appSetting?.setting?.promoBannerHeight) || 0;

  const orgPreference = useSelector((state) => state.userInfo?.orgPreference);
  // const visual = useSelector((state) => state.userInfo?.visual);
  // const filters = useSelector((state) => state.filters?.filters);
  const hypertestJobList = useSelector(
    (state) => state.hypertestJobList?.hypertestJobList
  );

  const featureFlag = useFeatureFlag();

  const triggerTestStatus = useSelector(
    (state) => state.onboarding?.currentConfigurationQuickRun?.triggerTestStatus
  );

  const framework = useSelector(
    (state) => state?.projectDetail?.project?.framework
  );

  const frameworks = useSelector(
    (state) =>
      state.hypertestJobDetail?.hypertestJobDetail?.data?.data?.Frameworks
  );

  const jobDetail = useSelector(
    (state) => state.hypertestJobDetail?.hypertestJobDetail?.data?.data
  );

  const isDex = frameworks?.includes('tosca-dex') || framework?.includes('dex');

  let stageUpdatedRef = useRef({});
  let jobSchedulerTimeoutRef = useRef('');

  let dispatch = useDispatch();
  let intervalRef = useRef('');
  let discoveryJobIdRef = useRef('');
  let quickRunStarted = useRef(false);
  let promoBannerIntervalRef = useRef(0);
  let promoBannerIntervalRetries = useRef(0);
  let jobStageTrack = {};
  let taskSSEAPICallRef = useRef({});
  const jobDetaulSSEAPITimeoutRef = useRef(null);
  let taskUpdaterRunningRef = useRef(false);
  let isDexRef = useRef(false);
  let jobDetailSSEAPICallLastMade = useRef(null);
  const concurrencyAPICallLastMade = useRef(null);
  const stageUpdatedSSEAPICallLastMade = useRef(null);
  let authRef = useRef({});

  let jobUpdatedRef = useRef({});
  //let jobTaskSchedulerTimeoutRef = useRef(null);
  let reportUpdatedTimeoutRef = useRef(null);

  let jobDetailRef = useRef(null);

  const userId = get(userInfo, ['data', 'id'], undefined);
  const orgData = get(orgPreference, ['data'], undefined);

  const auth = useMemo(() => {
    authRef.current = {
      username: userInfo?.data?.username,
      password: userInfo?.data?.apiToken,
    };
    return {
      username: userInfo?.data?.username,
      password: userInfo?.data?.apiToken,
    };
  }, [userInfo?.data?.username, userInfo?.data?.apiToken]);

  useEffect(() => {
    discoveryJobIdRef.current = projectDiscoverJobId;

    return () => {
      discoveryJobIdRef.current = '';
    };
  }, [projectDiscoverJobId]);

  useEffect(() => {
    isDexRef.current = isDex;

    return () => {
      isDexRef.current = false;
    };
  }, [isDex]);

  useEffect(() => {
    jobDetailRef.current = jobDetail;
  }, [jobDetail]);

  useEffect(() => {
    dispatch(fetchUserInfo())
      .then((res) => {
        dispatch(getUserList());
        let userData = get(res, 'payload', undefined);
        let token = get(userData, 'apiToken', undefined);
        if (!token) {
          redirectToLogin();
          return;
        }
        if (userData) {
          // localStorage.removeItem('featureFlags');

          recordUserForRUM(userData);
          let authInfo = {
            username: get(userData, 'username', undefined),
            password: get(userData, 'apiToken', undefined),
          };

          dispatch(
            fetchOrgPreferences({
              orgId: get(userData, ['organization', 'id'], ''),
              auth: authInfo,
            })
          ).then((res) => {
            updateWindowSSEConfig(res);

            dispatch(
              fetchPreferences({
                auth: authInfo,
              })
            );

            featureFlag.fetchFeatureFlags({
              userId: userData.id,
              auth: authInfo,
              orgId: get(userData, ['organization', 'id'], ''),
            });

            subscribeToHyperTestConcurrency(userData, authInfo);

            dispatch(fetchUserTeamMember(authInfo));
          });
        }
      })
      .catch(() => {
        redirectToLogin();
        return;
      });

    schedulerForLargeTasks();

    return () => {
      // clearTimeout(jobSchedulerTimeoutRef.current);
      // clearTimeout();
      // clearTimeout(jobStageSchedulerTimeoutRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let source;
    if (!!window.EventSource && userInfo.data && orgPreference.data) {
      // let access_token = getCookieData(cookieName);
      let SSE_URL =
        (window.globalConfig && window.globalConfig.LNS_HOST) ||
        (orgPreference.data && orgPreference.data.LNS_HOST) ||
        process.env.REACT_APP_HYPERTEST_SSE_URL ||
        (window._env_ && window._env_.REACT_APP_HYPERTEST_SSE_URL);

      if (window.location.href.includes('stage-eu')) {
        SSE_URL = 'https://stage-eu-hyperexecute-api.lambdatestinternal.com';
      }
      if (
        (window.globalConfig && window.globalConfig.LNS_HOST) ||
        (orgPreference.data && orgPreference.data.LNS_HOST) ||
        window?._env_?.REACT_APP_HYPERTEST_SSE_URL
      ) {
        source = new EventSource(
          `${SSE_URL}/api/v1/sse/hyperexecute?key=${btoa(
            userInfo.data.username + ':' + userInfo.data.apiToken
          )}`
        );
      } else {
        source = new EventSource(`${SSE_URL}/api/v1/sse/hyperexecute`, {
          withCredentials: true,
        });
      }

      source.addEventListener(
        SSE_Event.STAGE_CREATED,
        handleStageCreated,
        false
      );
      source.addEventListener(
        SSE_Event.STAGE_UPDATED,
        handleStageUpdated,
        false
      );
      source.addEventListener(
        SSE_Event.CATEGORIZED_ERROR_LOG_AVAILABLE,
        handleCategorizedError,
        false
      );
      source.addEventListener(SSE_Event.TASK_UPDATED, handleTaskUpdated, false);
      source.addEventListener(SSE_Event.JOB_CREATED, handleJobCreated, false);
      source.addEventListener(
        SSE_Event.JOB_UPDATED,
        debounce(handleJobUpdated, SSE_DEBOUNCE_TIME),
        false
      );
      source.addEventListener(
        SSE_Event.CONCURRENCY_UPDATED,
        debounce(handleConcurrencyUpdated, 200),
        false
      );
      source.addEventListener(
        SSE_Event.STAGE_LOG_AVAILABLE,
        handleStageLogAvailable,
        false
      );
      source.addEventListener(
        SSE_Event.REPORT_UPDATED,
        handleReportUpdated,
        false
      );
      source.addEventListener(
        SSE_Event.QUICK_RUN_TASK_LOG_UPDATED,
        handleQuickRunTaskLogCompleted,
        false
      );
      source.addEventListener(
        SSE_Event.DISCOVERY_RESULT,
        handleDiscoveryResult,
        false
      );
      source.addEventListener(SSE_Event.ERROR, handleSSEError, false);
    } else {
      // console.log('Your browser does not support SSE');
    }
    // Handling manual update if SSE is not working

    if (userInfo.data) {
      const parsed = queryString.parse(window.location.search);
      let framework = parsed && parsed.framework;
      if (!framework) {
        let state = window.location.href.split('state=');
        if (state && state.length > 1) {
          state = state[1].split('&');
          state = state && state.length && state[0];
        }
        let last_url = decodeURIComponent(state);
        if (last_url) {
          framework = last_url.split('framework=');
          framework = framework && framework.length && framework[1];
        }
      }
      if (framework) {
        dispatch(
          updateCommonUiVisual({
            continueToGitpodModal: true,
            frameworkName: framework,
          })
        );
      }
      if (parsed?.isArtifactOpen === 'true') {
        dispatch(setArtefectIsOpenStatus(true));
      }
      if (parsed?.selectedArtifact) {
        dispatch(
          setCurrentArtefactClick({
            name: parsed?.selectedArtifact,
            isArtifactDownloadable: parsed?.isArtifactDownloadable,
          })
        );
      }
    }
    window.addEventListener('TopPromoInitialized', handleTopPromoBanner);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (source) {
        console.info('Closing connection');
        source.close();
      }
      window.removeEventListener('TopPromoInitialized', handleTopPromoBanner);
      document.removeEventListener('closeTopPromo', closeTopPromo);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, orgData]);

  useEffect(() => {
    promoBannerIntervalRef.current = setInterval(intervalForPromoBanner, 500);
    return function () {
      clearInterval(promoBannerIntervalRef.current);
      promoBannerIntervalRetries.current = 0;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function makeConcurrencyAPI(orgId, authInfo) {
    if (preventConcurrencyAPI()) {
      return;
    }

    dispatch(
      getHypertestConcurrency({
        auth: authInfo,
        orgId,
      })
    );
  }

  function makeStageUpdatedSSEAPI({
    auth,
    jobId,
    dataTask,
    parsed,
    dataStage,
    onlyMakeAPI = false,
    taskId,
  }) {
    if (preventStageUpdatedSSEAPI()) {
      return;
    }

    if (onlyMakeAPI) {
      dispatch(
        getHypertestTaskLog_SSE({
          isDex: isDexRef.current,
          auth,
          jobId,
          taskId,
          search_text: parsed?.scenario_search_text || '',
        })
      );
    } else {
      if (dataTask.taskId === parsed.taskId || isProjectPage()) {
        dispatch(
          getHypertestTaskLog_SSE({
            isDex: isDexRef.current,
            auth,
            jobId,
            taskId: dataStage.taskId,
            search_text: parsed?.scenario_search_text || '',
          })
        ).then((response) => {
          if (get(response, ['payload', 'data'], []).length) {
            if (isProjectPage() && jobId === discoveryJobIdRef.current) {
              manageProjectFlow(response);
            }
            if (!isProjectPage()) {
              let logsData = response.payload.data.filter((log, ind) => {
                return log.status === 'in-progress';
              });
              if (logsData && logsData.length) {
                dispatch(setCurrentRunningLogType(logsData[0].type));
              } else {
                dispatch(setCurrentRunningLogType('-'));
              }
            }
          } else {
            if (!isProjectPage()) {
              dispatch(setCurrentRunningLogType('-'));
            }
          }
        });
      }
    }
  }

  function makeTaskUpdateSSEAPI({ dataTask }) {
    const taskId = dataTask?.taskId;

    taskSSEAPICallRef.current[taskId] = dataTask;

    if (!taskUpdaterRunningRef.current) {
      triggerTaskUpdater();
    }

    // clearTimeout(taskSSEAPICallRef.current[taskId]);
    // taskSSEAPICallRef.current[taskId] = setTimeout(() => {
    //   dispatch(
    //     getHypertestSSEEvent({
    //       eventData: dataTask,
    //     })
    //   );
    // }, 2000);
  }

  function triggerTaskUpdater() {
    const keys = Object.keys(taskSSEAPICallRef.current || {});
    console.log('task scheduler', keys);
    if (keys.length) {
      const key = keys[0];
      const dataTask = taskSSEAPICallRef.current[key];
      delete taskSSEAPICallRef.current[key];
      dispatch(
        getHypertestSSEEvent({
          eventData: dataTask,
        })
      );
      taskUpdaterRunningRef.current = true;

      setTimeout(() => {
        triggerTaskUpdater();
      }, 3000);
    } else {
      taskUpdaterRunningRef.current = false;
    }
  }

  function makeJobDetailSSEAPI({ auth, jobId }) {
    if (preventJobDetailSSEAPI()) {
      clearTimeout(jobDetaulSSEAPITimeoutRef.current);
      jobDetaulSSEAPITimeoutRef.current = setTimeout(() => {
        dispatch(
          getHypertestJobDetail_SSE({
            auth,
            jobId,
          })
        );
      }, 5000);
      return;
    }

    dispatch(
      getHypertestJobDetail_SSE({
        auth,
        jobId,
      })
    );
  }

  function subscribeToHyperTestConcurrency(userData, authInfo) {
    if (userData) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }

      intervalRef.current = setInterval(() => {
        makeConcurrencyAPI(get(userData, ['organization', 'id'], ''), authInfo);
      }, 30000);
    }
  }

  function preventConcurrencyAPI() {
    if (concurrencyAPICallLastMade.current) {
      const currentTime = new Date().getTime();
      const timeDiff =
        (currentTime - concurrencyAPICallLastMade.current) / 1000;
      if (timeDiff <= 5) {
        return true;
      } else {
        concurrencyAPICallLastMade.current = new Date().getTime();
        return false;
      }
    } else {
      concurrencyAPICallLastMade.current = new Date().getTime();
      return false;
    }
  }

  function preventStageUpdatedSSEAPI() {
    if (stageUpdatedSSEAPICallLastMade.current) {
      const currentTime = new Date().getTime();
      const timeDiff =
        (currentTime - stageUpdatedSSEAPICallLastMade.current) / 1000;
      if (timeDiff <= 5) {
        return true;
      } else {
        stageUpdatedSSEAPICallLastMade.current = new Date().getTime();
        return false;
      }
    } else {
      stageUpdatedSSEAPICallLastMade.current = new Date().getTime();
      return false;
    }
  }

  function preventJobDetailSSEAPI() {
    if (jobDetailSSEAPICallLastMade.current) {
      const currentTime = new Date().getTime();
      const timeDiff =
        (currentTime - jobDetailSSEAPICallLastMade.current) / 1000;
      if (timeDiff <= 5) {
        return true;
      } else {
        jobDetailSSEAPICallLastMade.current = new Date().getTime();
        return false;
      }
    } else {
      jobDetailSSEAPICallLastMade.current = new Date().getTime();
      return false;
    }
  }

  function resetPromoIntervalAndRetry() {
    clearInterval(promoBannerIntervalRef.current);
    promoBannerIntervalRetries.current = 0;
  }

  function recordUserForRUM({ id: userId = '', organization = {} } = {}) {
    const { id: orgId = '', subscription_type = '' } = organization;
    if (
      window?.sumoLogicOpenTelemetryRum?.setDefaultAttribute &&
      typeof window?.sumoLogicOpenTelemetryRum?.setDefaultAttribute ===
        'function'
    ) {
      try {
        window.sumoLogicOpenTelemetryRum.setDefaultAttribute('userId', userId);
        window.sumoLogicOpenTelemetryRum.setDefaultAttribute('orgId', orgId);
        window.sumoLogicOpenTelemetryRum.setDefaultAttribute(
          'subscriptionType',
          subscription_type
        );
      } catch (e) {
        console.log('Failed to set default attribute for SumoLogic', e);
      }
    }
  }

  function intervalForPromoBanner() {
    if (promoBannerIntervalRetries.current >= 7) {
      resetPromoIntervalAndRetry();
    } else {
      const bannerElCloseIcon = document.getElementById('close_top_promo');
      const topPromoElement = document.getElementById('top_promo');
      if (
        bannerElCloseIcon &&
        topPromoElement &&
        topPromoElement.style.display !== 'none' &&
        promoBannerHeight <= 0
      ) {
        document.removeEventListener('closeTopPromo', closeTopPromo);
        dispatch(setPromoBannerHeight(42));
        document.addEventListener('closeTopPromo', closeTopPromo);
        resetPromoIntervalAndRetry();
      } else if (promoBannerHeight > 0) {
        resetPromoIntervalAndRetry();
      } else {
        promoBannerIntervalRetries.current =
          promoBannerIntervalRetries.current + 1;
      }
    }
  }

  function handleTopPromoBanner() {
    const bannerElCloseIcon = document.getElementById('close_top_promo');
    const topPromoElement = document.getElementById('top_promo');
    if (
      bannerElCloseIcon &&
      topPromoElement &&
      topPromoElement.style.display !== 'none'
    ) {
      document.removeEventListener('closeTopPromo', closeTopPromo);
      dispatch(setPromoBannerHeight(42));
      document.addEventListener('closeTopPromo', closeTopPromo);
    }
  }

  function closeTopPromo() {
    console.log('Closing Top Promo Banner');
    sendAmplitudeEvents(EventNames.HYP_TOP_BANNER_CLOSE_BUTTON);
    dispatch(setPromoBannerHeight(0));
  }

  function updateWindowSSEConfig(res) {
    window.globalConfig = {};
    if (res && res.payload) {
      if (res.payload.SENTINEL_HOST) {
        window.globalConfig.SENTINEL_HOST = res.payload.SENTINEL_HOST;
      }
      if (res.payload.ORCHESTRATOR_HOST) {
        window.globalConfig.ORCHESTRATOR_HOST = res.payload.ORCHESTRATOR_HOST;
      }
      if (res.payload.LOGISTICS_HOST) {
        window.globalConfig.LOGISTICS_HOST = res.payload.LOGISTICS_HOST;
      }
      if (res.payload.LNS_HOST) {
        window.globalConfig.LNS_HOST = res.payload.LNS_HOST;
      }
      if (res.payload.DISPATCHER_HOST) {
        window.globalConfig.DISPATCHER_HOST = res.payload.DISPATCHER_HOST;
      }
      if (res.payload.RECEPTION_HOST) {
        window.globalConfig.RECEPTION_HOST = res.payload.RECEPTION_HOST;
      }
      if (res.payload.DISABLE_CDN_LOGS) {
        window.globalConfig.DISABLE_CDN_LOGS = res.payload.DISABLE_CDN_LOGS;
      }
      if (res.payload.LTMS_API_HOST) {
        window.globalConfig.LTMS_API_HOST = res.payload.LTMS_API_HOST;
      }
    }
    if (
      window?._env_?.REACT_APP_DISABLE_CDN_LOGS === 'true' &&
      !window.globalConfig.DISABLE_CDN_LOGS
    ) {
      window.globalConfig.DISABLE_CDN_LOGS =
        window._env_.REACT_APP_DISABLE_CDN_LOGS;
    }
    if (window?._env_?.REACT_APP_LNS_HOST && !window.globalConfig.LNS_HOST) {
      window.globalConfig.LNS_HOST = window._env_.REACT_APP_LNS_HOST;
    }
    if (window?._env_?.REACT_APP_HYPERTEST_ORCHESTRATOR_API_ENDPOINT) {
      window.globalConfig.ORCHESTRATOR_HOST =
        window._env_.REACT_APP_HYPERTEST_ORCHESTRATOR_API_ENDPOINT;
    }
  }

  function isHomePage() {
    return window.location.pathname.includes(Path.JOBS);
  }
  function isProjectPage() {
    return window.location.pathname.includes(Path.PROJECT_DETAILS);
  }
  function isQuickRunPage() {
    return window.location.pathname.includes(Path.QUICK_START_QUICK_RUN.Path);
  }

  function isJobDetails() {
    return window.location.pathname.includes(Path.TASK);
  }

  function handleTriggerButton(currentStep = 0, logs = '') {
    dispatch(
      updateTriggerTestStatus({
        triggerTestStatus: {
          currentStep: currentStep,
          logs: logs,
        },
      })
    );
  }

  function handleStageCreated(e) {
    if (isJobDetails()) {
      const parsed = queryString.parse(window.location.search);
      let data = JSON.parse(e.data);

      jobStageTrack[data.jobId] = jobStageTrack[data.jobId]
        ? jobStageTrack[data.jobId] + 1
        : 1;

      if (jobStageTrack[data.jobId] === 1 && parsed.jobId === data.jobId) {
        makeJobDetailSSEAPI({
          auth,
          jobId: data.jobId,
        });
        // @todo - verify with Ravi. Now we will not depend upon Tasks coming from job details.
        // .then((res) => {
        //   console.log('hellow')
        //   if (get(res, ['payload', 'data', 'Tasks'], []).length) {
        //     if (parsed.taskId) {
        //       // history.push(
        //       //   `?jobId=${parsed.jobId}&taskId=${parsed.taskId}`
        //       // );
        //     } else {
        //       history.push(
        //         `?jobId=${parsed.jobId}&taskId=${res.payload.data.Tasks[0].id}`
        //       );
        //     }
        //   }
        // });
      }

      if (
        (data.taskId === parsed.taskId || !parsed.taskId) &&
        parsed.jobId === data.jobId
      ) {
        makeStageUpdatedSSEAPI({
          auth,
          jobId: data.jobId,
          taskId: data.taskId,
          onlyMakeAPI: true,
        });
      }
    }
  }

  function handleCategorizedError(e) {
    console.log('categorizedError');
    if (isJobDetails()) {
      const parsed = queryString.parse(window.location.search);
      let dataTask = JSON.parse(e.data);

      console.log('isJobDetails', { dataTask, parsed });
      if (dataTask.taskId === parsed.taskId) {
        let iteration = 0;
        console.log('match found, making call');
        if (dataTask.link) {
          const arr = dataTask.link.split('.');
          if (arr?.length > 3) {
            iteration = arr[2];
          }
        }
        dispatch(
          fetchRCA({
            auth,
            order: dataTask.order,
            iteration,
            task: dataTask.taskId,
          })
        );
      } else {
        console.log('data not match');
      }
    }
  }

  function handleStageUpdated(e) {
    if (!isHomePage()) {
      let dataTask = JSON.parse(e.data);

      const { count, retry, order, taskId } = dataTask;
      const visibleStageId = `${taskId}${count}${retry}${order}`;

      if (!window?.visibleStage?.includes(visibleStageId) && !isJobDetails()) {
        return;
      }

      const parsed = queryString.parse(window.location.search);
      let dataStage = JSON.parse(e.data);
      const jobId = JSON.parse(e.data).jobId;
      delete jobStageTrack[dataStage.jobId];

      makeStageUpdatedSSEAPI({ auth, jobId, dataTask, parsed, dataStage });
    }

    if (isHomePage()) {
      const jobId = JSON.parse(e.data).jobId;
      clearTimeout(stageUpdatedRef.current[jobId]);
      stageUpdatedRef.current[jobId] = setTimeout(() => {
        manageJobUpdate(jobId);
      }, 5000);
    }
  }

  function manageProjectFlow(response = {}) {
    response?.payload?.data?.forEach(function (stage) {
      const completed =
        stage?.name?.toLowerCase() === 'discovery' &&
        (stage?.status?.toLowerCase() === 'running' ||
          stage?.status?.toLowerCase() === 'completed' ||
          stage?.status?.toLowerCase().includes('progress'));
      if (completed) {
        dispatch(
          setProjectStages({
            status: FirstLoadCompleted,
            stage: SETTING_UP_ENVIRONMENT,
          })
        );
        dispatch(
          setProjectStages({
            status: FirstLoadCompleted,
            stage: PREPARING_FOR_TEST_DISCOVERY,
          })
        );
        sendAmplitudeEvents(EventNames.HYP_DISCOVERING_EXECUTION_LIST, {
          stage: 'Preparing for test discovery',
          status: 'completed',
        });
        setTimeout(() => {
          dispatch(
            setProjectStages({
              status: FirstLoadInProgress,
              stage: DETECTING_TEST_DETAILS,
            })
          );
        }, 200);
      }
    });
  }

  async function manageJobUpdate(jobId) {
    const result = await dispatch(
      getHypertestJobDetailToUpdateJobList_SSE({
        auth,
        jobId,
      })
    );
    const needToInsert =
      result?.payload?.data && hypertestJobList && !hypertestJobList.loading;
    if (needToInsert) {
      dispatch(
        insertNewJobIntoJoblist({
          data: {
            executionTime: result?.payload?.executionTime,
            execution_time_sec: result?.payload?.execution_time_sec,
            ...result.payload.data,
          },
          isUpdateEvent: true,
        })
      );
    }
  }

  function handleTaskUpdated(e) {
    let dataTask = JSON.parse(e.data);
    const parsed = queryString.parse(window.location.search);

    if (window?.visibleTaskIds?.includes(dataTask.taskId) && isJobDetails()) {
      if (parsed.jobId === dataTask.jobId) {
        makeJobDetailSSEAPI({
          auth,
          jobId: dataTask.jobId,
        });
        makeTaskUpdateSSEAPI({ dataTask });

        if (dataTask.taskId === parsed.taskId) {
          makeStageUpdatedSSEAPI({
            auth,
            jobId: dataTask.jobId,
            taskId: dataTask.taskId,
            onlyMakeAPI: true,
          });
        }
      }
    }

    makeConcurrencyAPI(get(userInfo, ['data', 'organization', 'id'], ''), auth);
  }

  async function handleJobCreated(e) {
    let dataTask = JSON.parse(e?.data);

    const result = await dispatch(
      getHypertestJobDetailToUpdateJobList_SSE({
        auth,
        jobId: dataTask?.jobId,
      })
    );
    if (
      result?.payload?.data &&
      hypertestJobList &&
      !hypertestJobList.loading
    ) {
      dispatch(insertNewJobIntoJoblist({ data: result.payload.data }));
    }
    if (window.location.href.indexOf('quickstart') !== -1) {
      dispatch(
        updateCommonUiVisual({
          onboardingJobCreatedSSElisten: true,
        })
      );
    }
  }

  async function handleJobUpdated(e) {
    let dataTask = JSON.parse(e?.data);

    const dataInfRef = jobUpdatedRef.current[dataTask?.jobId];

    if (dataInfRef?.id === dataTask?.jobId) {
      clearTimeout(dataInfRef?.timeoutId);
    }

    const timeoutId = setTimeout(async () => {
      console.log('Job Updated Event');
      const result = await dispatch(
        getHypertestJobDetailToUpdateJobList_SSE({
          auth,
          jobId: dataTask?.jobId,
        })
      );
      const needToInsert =
        result?.payload?.data && hypertestJobList && !hypertestJobList.loading;
      if (needToInsert) {
        dispatch(
          insertNewJobIntoJoblist({
            data: {
              executionTime: result?.payload?.executionTime,
              execution_time_sec: result?.payload?.execution_time_sec,
              ...result.payload.data,
            },
            isUpdateEvent: true,
          })
        );
      }

      if (isProjectPage()) {
        let dataTask = JSON.parse(e.data);
        const jobId = dataTask.jobId;
        const status = dataTask.status;

        if (discoveryJobIdRef.current === jobId && status === 'running') {
          sendAmplitudeEvents(EventNames.HYP_DISCOVERING_EXECUTION_LIST, {
            stage: 'Setting up environment',
            status: 'completed',
          });
          dispatch(
            setProjectStages({
              stage: SETTING_UP_ENVIRONMENT,
              status: FirstLoadCompleted,
            })
          );
          setTimeout(
            dispatch(
              setProjectStages({
                stage: PREPARING_FOR_TEST_DISCOVERY,
                status: FirstLoadInProgress,
              })
            ),
            500
          );
        } else if (
          discoveryJobIdRef.current === jobId &&
          status === 'completed'
        ) {
          dispatch(
            setProjectStages({
              stage: DETECTING_TEST_DETAILS,
              status: FirstLoadCompleted,
            })
          );
          sendAmplitudeEvents(EventNames.HYP_DISCOVERING_EXECUTION_LIST, {
            stage: 'Finalizing Setup',
            status: 'completed',
          });
          dispatch(
            setProjectStages({
              stage: FINALIZING_SETUP,
              status: FirstLoadCompleted,
            })
          );
          setTimeout(() => {
            dispatch(setProjectDetailFetch(true));
          }, 2000);
        } else if (discoveryJobIdRef.current === jobId && status === 'failed') {
          dispatch(
            setProjectStages({
              stage: PREPARING_FOR_TEST_DISCOVERY,
              status: FirstLoadCompleted,
            })
          );

          dispatch(
            setProjectStages({
              stage: DETECTING_TEST_DETAILS,
              status: FirstLoadCompleted,
            })
          );
          sendAmplitudeEvents(EventNames.HYP_DISCOVERING_EXECUTION_LIST, {
            stage: 'Finalizing Setup',
            status: 'failed',
          });
          dispatch(
            setProjectStages({
              stage: FINALIZING_SETUP,
              status: FirstLoadFailed,
            })
          );
          setTimeout(() => {
            dispatch(setProjectDetailFetch(true));
          }, 2000);
        }
      }

      if (isJobDetails()) {
        let dataTask = JSON.parse(e.data);
        const parsed = queryString.parse(window.location.search);
        if (parsed.jobId === dataTask.jobId && dataTask.status === 'aborted') {
          makeJobDetailSSEAPI({ auth, jobId: dataTask.jobId });

          // Commenting because same call is made in scheduleer.
          // dispatch(
          //   getHypertestTaskLog_SSE({
          //     isDex: isDexRef.current,
          //     auth,
          //     jobId: dataTask.jobId,
          //     taskId: parsed.taskId,
          //     search_text: parsed?.scenario_search_text || '',
          //   })
          // );
        }
      }
    }, 2000);

    jobUpdatedRef.current[dataTask?.jobId] = { id: dataTask?.jobId, timeoutId };
  }

  function handleConcurrencyUpdated(e) {
    makeConcurrencyAPI(get(userInfo, ['data', 'organization', 'id'], ''), auth);
  }

  function handleStageLogAvailable(e) {
    if (!isHomePage() && !isProjectPage()) {
      let dataTask = JSON.parse(e.data);
      const parsed = queryString.parse(window.location.search);
      let splitChunk = dataTask.link.split('.');
      if (
        isQuickRunPage() &&
        JSON.parse(e?.data)?.chunk?.includes('discovery') &&
        triggerTestStatus.currentStep < 3
      ) {
        const logs = triggerTestStatus?.logs;
        if (JSON.parse(e?.data)?.jobId === window?.quickRunData?.jobId) {
          const nextStep = window?.quickRunData?.currentStep <= 3 ? 3 : 4;
          window.quickRunData.currentStep = nextStep;
          handleTriggerButton(nextStep, logs);
        }
      }

      if (
        (parseInt(parsed.order) === dataTask.order ||
          dataTask.chunk.indexOf('pre') !== -1 ||
          dataTask.chunk.indexOf('post') !== -1) &&
        dataTask.taskId === parsed.taskId &&
        (splitChunk[0] === 'chunk' ||
          splitChunk[0] === 'pre' ||
          splitChunk[0] === 'post')
      ) {
        let currentUId = window.currentId;
        const chunkNumber = getChunkNumberForSSEEvent(currentUId);
        if (isChunkNumberAlreadyExecuted(currentUId, chunkNumber)) {
          console.log(
            'chunk number already executed from useApp',
            chunkNumber,
            currentUId
          );
          return;
        }

        addChunkNumberInTrackingWithId(chunkNumber, currentUId);
        setTimeout(() => {
          emitEvent('newChunk', {
            auth,
            chunkNumber,
            chunkRetry: splitChunk[2],
            isSSEUpdate: true,
            iteration: dataTask.iteration ? dataTask.iteration : 0,
            logType: parsed.logType,
            order: dataTask.order,
            stageNumber: splitChunk[1],
            taskId: dataTask.taskId,
          });
        }, 600);
      }

      // TODO - after testing remove it. It is not clear if this is being used
      // if (
      //   parseInt(parsed.order) === dataTask.order &&
      //   dataTask.taskId === parsed.taskId &&
      //   (splitChunk[0] === 'pre' || splitChunk[0] === 'post')
      // ) {
      //   dispatch(
      //     stageLogByChunk({
      //       auth,
      //       iteration: dataTask.iteration,
      //       link: dataTask.link,
      //       logType: parsed.logType,
      //       taskId: dataTask.taskId,
      //     })
      //   );
      // }
    }
  }

  function handleReportUpdated(e) {
    if (isJobDetails()) {
      clearTimeout(reportUpdatedTimeoutRef.current);

      reportUpdatedTimeoutRef.current = setTimeout(() => {
        const parsed = queryString.parse(window.location.search);
        let jobReportData = JSON.parse(e.data);
        if (parsed.jobId === jobReportData.jobId) {
          dispatch(setReportDownloadButtonStatus(jobReportData.status));
          dispatch(
            checkReportIsCreated({
              auth: authRef.current,
              jobId: parsed.jobId,
            })
          );
        }
      }, 300);
    }
  }

  function handleSSEError(e) {
    console.error('**SSE ERROR** \n', e);
    // if (e.eventPhase === EventSource.CLOSED) source.close();
    // if (e.target.readyState === EventSource.CLOSED) {
    //   // console.log('Disconnected');
    // } else if (e.target.readyState === EventSource.CONNECTING) {
    //   // console.log('Connecting...');
    // }
  }

  var logsToRender = [];

  function similarity(a, b) {
    var equivalency = 0;
    var minLength = a.length > b.length ? b.length : a.length;
    var maxLength = a.length < b.length ? b.length : a.length;
    for (var i = 0; i < minLength; i++) {
      if (a[i] === b[i]) {
        equivalency++;
      }
    }

    var weight = equivalency / maxLength;
    return weight * 100;
  }

  function resetQuickRunData() {
    logsToRender = [];
    quickRunStarted.current = false;
    window.quickRunData = {
      currentStep: 0,
      logs: '',
      jobId: '',
    };
    dispatch(
      updateTriggerTestStatus({
        triggerTestStatus: {
          currentStep: 0,
          logs: '',
        },
      })
    );
  }

  function quickRunLogEngine() {
    let previousLine = '';

    if (!isQuickRunPage()) {
      resetQuickRunData();
      return;
    }

    const logs = window?.quickRunData?.logs || [];
    if (logs.length) {
      const currentLine = logs.shift().trim();

      if (logsToRender.length) {
        previousLine = logsToRender.at(-1);
      }

      if (similarity(previousLine, currentLine) >= 90) {
        // Add Logs
        logsToRender[logsToRender.length - 1] = currentLine;
      } else {
        // Replace logs
        currentLine.trim().length && logsToRender.push(currentLine);
      }

      // render in UI
      setTimeout(function () {
        let nextStep =
          window?.quickRunData?.currentStep >= 2
            ? window?.quickRunData?.currentStep
            : 2;
        window.quickRunData.currentStep = nextStep;
        handleTriggerButton(nextStep, logsToRender);
        if (logs.length > 0) {
          quickRunLogEngine();
        } else {
          setTimeout(function () {
            quickRunLogEngine();
          }, 200);
        }
      }, 70);
    } else if (isQuickRunPage() && quickRunStarted.current) {
      setTimeout(function () {
        quickRunLogEngine();
      }, 200);
    } else {
      quickRunStarted.current = false;
    }
  }

  function getLogsForWindow(newData) {
    if (window?.quickRunData?.logs?.length) {
      return [...window?.quickRunData?.logs, ...newData.split('\r\r')];
    } else {
      return newData.split('\r\r');
    }
  }

  function handleQuickRunTaskLogCompleted(e) {
    const newData = JSON.parse(e?.data).data;
    const dataJobId = JSON.parse(e?.data).jobId;
    // sometimes jobid was not been found when this event happens, thats why added timeout as quick fix, need to review this later
    window.setTimeout(() => {
      if (dataJobId === window?.quickRunData?.jobId) {
        window.quickRunData = {
          ...window.quickRunData,
          logs: getLogsForWindow(newData),
        };

        if (!quickRunStarted.current) {
          quickRunStarted.current = true;
          quickRunLogEngine();
        }
      }
    }, 500);
  }

  function handleDiscoveryResult(e) {
    const status = JSON.parse(e?.data).status;
    const dataJobId = JSON.parse(e?.data).jobId;
    if (
      discoveryJobIdRef.current === dataJobId &&
      (status === 'running' || status === 'completed')
    ) {
      dispatch(
        setProjectStages({
          stage: DETECTING_TEST_DETAILS,
          status: FirstLoadCompleted,
        })
      );
      sendAmplitudeEvents(EventNames.HYP_DISCOVERING_EXECUTION_LIST, {
        stage: 'Detecting test details',
        status: 'completed',
      });
      setTimeout(() => {
        dispatch(
          setProjectStages({
            status: FirstLoadInProgress,
            stage: FINALIZING_SETUP,
          })
        );
      }, 200);
    } else if (discoveryJobIdRef.current === dataJobId && status === 'failed') {
      dispatch(
        setProjectStages({
          stage: PREPARING_FOR_TEST_DISCOVERY,
          status: FirstLoadCompleted,
        })
      );

      dispatch(
        setProjectStages({
          stage: DETECTING_TEST_DETAILS,
          status: FirstLoadCompleted,
        })
      );

      sendAmplitudeEvents(EventNames.HYP_DISCOVERING_EXECUTION_LIST, {
        stage: 'Detecting test details',
        status: 'failed',
      });

      dispatch(
        setProjectStages({
          stage: DETECTING_TEST_DETAILS,
          status: FirstLoadFailed,
        })
      );
      setTimeout(() => {
        dispatch(setProjectDetailFetch(true));
      }, 1000);
    }
  }

  function schedulerForLargeTasks() {
    clearTimeout(jobSchedulerTimeoutRef.current);
    const parsed = queryString.parse(window.location.search);

    if (isJobDetails() && parsed?.taskId && parsed?.jobId) {
      if (
        ['running', 'in progress', 'in-progress'].includes(
          jobDetailRef?.current?.status
        )
      ) {
        dispatch(
          getHypertestTaskLog_SSE({
            isDex: isDexRef.current,
            auth: authRef.current,
            jobId: parsed.jobId,
            taskId: parsed.taskId,
            search_text: parsed?.scenario_search_text || '',
          })
        );
      }
      jobSchedulerTimeoutRef.current = setTimeout(schedulerForLargeTasks, 8000);
    } else {
      jobSchedulerTimeoutRef.current = setTimeout(schedulerForLargeTasks, 8000);
    }
  }

  return {
    userInfo,
    orgPreference,
  };
};

export default useApp;
