import React from 'react';

const Video = ({ width = '17', height = '12', className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 12"
      fill="none"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <g id="Group 1000002174">
        <rect
          id="Rectangle 2133"
          x="1.4"
          y="1.4"
          width="10.2"
          height="9.2"
          rx="1.1"
          stroke="#0366D6"
          strokeWidth="1.2"
        />
        <path
          id="Rectangle 2134"
          d="M12.1159 4.53907C11.9791 4.65306 11.9 4.82193 11.9 5V7C11.9 7.17807 11.9791 7.34694 12.1159 7.46093L15.1159 9.96093C15.2947 10.11 15.5437 10.1421 15.7545 10.0434C15.9653 9.94461 16.1 9.73281 16.1 9.5V2.5C16.1 2.26719 15.9653 2.05539 15.7545 1.95665C15.5437 1.8579 15.2947 1.89003 15.1159 2.03907L12.1159 4.53907Z"
          stroke="#0366D6"
          strokeWidth="1.2"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default Video;
