import React from 'react';

const FailedGray = ({ width = '12', height = '12', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="6" cy="6" r="5.5" stroke="#C2C2C2" />
      <path
        d="M8.2853 8.28627L3.71387 3.71484M8.2853 3.71484L3.71387 8.28627"
        stroke="#C2C2C2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default FailedGray;
