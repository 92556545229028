const IntegrationIcon = ({ width = 34, height = 35, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <g clipPath="url(#clip0_15323_107353)">
        <path
          d="M13.8179 21.1253L9.70784 21.1253C9.41171 21.1258 9.11839 21.0679 8.8447 20.9548C8.57101 20.8417 8.32234 20.6757 8.11294 20.4663C7.90354 20.2569 7.73754 20.0083 7.62446 19.7346C7.51137 19.4609 7.45342 19.1676 7.45394 18.8714V15.9546C7.45342 15.6585 7.51137 15.3652 7.62446 15.0915C7.73754 14.8178 7.90354 14.5691 8.11294 14.3597C8.32234 14.1503 8.57101 13.9843 8.8447 13.8712C9.11839 13.7581 9.41171 13.7002 9.70784 13.7007H13.8179"
          stroke="url(#paint0_linear_15323_107353)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.45335 17.4143H3.21071"
          stroke="url(#paint1_linear_15323_107353)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.7893 17.4143H26.5466"
          stroke="url(#paint2_linear_15323_107353)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.9992 19.5343H13.8172"
          stroke="url(#paint3_linear_15323_107353)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.9992 15.2922H13.8172"
          stroke="url(#paint4_linear_15323_107353)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.1821 21.1253L24.2922 21.1253C24.5883 21.1258 24.8816 21.0679 25.1553 20.9548C25.429 20.8417 25.6777 20.6757 25.8871 20.4663C26.0965 20.2569 26.2625 20.0083 26.3755 19.7346C26.4886 19.4609 26.5466 19.1676 26.5461 18.8714V15.9546C26.5466 15.6585 26.4886 15.3652 26.3755 15.0915C26.2625 14.8178 26.0965 14.5691 25.8871 14.3597C25.6777 14.1503 25.429 13.9843 25.1553 13.8712C24.8816 13.7581 24.5883 13.7002 24.2922 13.7007H20.1821"
          stroke="url(#paint5_linear_15323_107353)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.1816 12.6406V22.1866"
          stroke="url(#paint6_linear_15323_107353)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8184 12.6406V22.1866"
          stroke="url(#paint7_linear_15323_107353)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_15323_107353"
          x1="14.4126"
          y1="14.2954"
          x2="8.17728"
          y2="20.5307"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#225084" />
          <stop offset="1" stopColor="#10B4C1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15323_107353"
          x1="6.39269"
          y1="16.3536"
          x2="4.27137"
          y2="18.4749"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#225084" />
          <stop offset="1" stopColor="#10B4C1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_15323_107353"
          x1="29.7286"
          y1="16.3536"
          x2="27.6073"
          y2="18.4749"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#225084" />
          <stop offset="1" stopColor="#10B4C1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_15323_107353"
          x1="16.2037"
          y1="18.7389"
          x2="14.6127"
          y2="20.3298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#225084" />
          <stop offset="1" stopColor="#10B4C1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_15323_107353"
          x1="16.2037"
          y1="14.4967"
          x2="14.6127"
          y2="16.0877"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#225084" />
          <stop offset="1" stopColor="#10B4C1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_15323_107353"
          x1="25.8227"
          y1="14.2954"
          x2="19.5874"
          y2="20.5307"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#225084" />
          <stop offset="1" stopColor="#10B4C1" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_15323_107353"
          x1="22.5681"
          y1="15.0271"
          x2="17.7952"
          y2="19.8001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#225084" />
          <stop offset="1" stopColor="#10B4C1" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_15323_107353"
          x1="16.2048"
          y1="15.0271"
          x2="11.4319"
          y2="19.8001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#225084" />
          <stop offset="1" stopColor="#10B4C1" />
        </linearGradient>
        <clipPath id="clip0_15323_107353">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(17 0.443359) rotate(45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IntegrationIcon;
