import {
  HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION,
  HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION_YAML_BASED,
  HYPERTEST_ONBOARDING_RESET_CONFIGURATION_YAML_BASED,
  HYPERTEST_SET_DOWNLOAD_CONCIERGE_ALERT_STATUS,
  HYPERTEST_ONBOARDING_UPADTE_HOMPAGE_VALUE,
  HYPERTEST_SET_ALERT_STATUS,
  HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION_QUICK_RUN,
  HYPERTEST_ONBOARDING_UPADTE_TRIGGER_TEST_STATUS,
  HYPERTEST_SHOW_JOB_LIST_AFTER_QUICK_RUN,
} from '../constants/onboarding';

export const updateConfigurationValueQuickRun = (value) => {
  return {
    type: HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION_QUICK_RUN,
    payload: value,
  };
};
export const updateConfigurationValue = (value) => {
  return {
    type: HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION,
    payload: value,
  };
};

export const updateTriggerTestStatus = (value) => {
  return {
    type: HYPERTEST_ONBOARDING_UPADTE_TRIGGER_TEST_STATUS,
    payload: value,
  };
};

export const updateConfigurationValueYamlBased = (value) => {
  return {
    type: HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION_YAML_BASED,
    payload: value,
  };
};
export const resetYamlCodeValue = () => {
  return {
    type: HYPERTEST_ONBOARDING_RESET_CONFIGURATION_YAML_BASED,
  };
};

export const updateDownloadConciegeAlertStatus = (isEnabled) => {
  return {
    type: HYPERTEST_SET_DOWNLOAD_CONCIERGE_ALERT_STATUS,
    payload: isEnabled,
  };
};
export const updateAlertStatus = (payload) => {
  return {
    type: HYPERTEST_SET_ALERT_STATUS,
    payload: payload,
  };
};
export const updateHomePageStep = (value) => {
  return {
    type: HYPERTEST_ONBOARDING_UPADTE_HOMPAGE_VALUE,
    payload: value,
  };
};
export const showJobListAfterQuickRun = (value) => {
  return {
    type: HYPERTEST_SHOW_JOB_LIST_AFTER_QUICK_RUN,
    payload: value,
  };
};
