import React from 'react';

const CompletedGray = ({ width = 14, height = 14, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M5.00391 7.21674L6.71464 8.70948C6.75604 8.74561 6.81883 8.74156 6.85526 8.70042L9.98423 5.16602"
        stroke="#C2C2C2"
        strokeLinecap="round"
      />
      <circle cx="7.5" cy="7" r="6.5" stroke="#C2C2C2" />
    </svg>
  );
};

export default CompletedGray;
