export const HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION =
  'HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION';
export const HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION_QUICK_RUN =
  'HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION_QUICK_RUN';
export const HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION_YAML_BASED =
  'HYPERTEST_ONBOARDING_UPADTE_CONFIGURATION_YAML_BASED';
export const HYPERTEST_ONBOARDING_RESET_CONFIGURATION_YAML_BASED =
  'HYPERTEST_ONBOARDING_RESET_CONFIGURATION_YAML_BASED';
export const HYPERTEST_SET_DOWNLOAD_CONCIERGE_ALERT_STATUS =
  'HYPERTEST_SET_DOWNLOAD_CONCIERGE_ALERT_STATUS';
export const HYPERTEST_ONBOARDING_UPADTE_HOMPAGE_VALUE =
  'HYPERTEST_ONBOARDING_UPADTE_HOMPAGE_VALUE';
export const HYPERTEST_ONBOARDING_UPADTE_TRIGGER_TEST_STATUS =
  'HYPERTEST_ONBOARDING_UPADTE_TRIGGER_TEST_STATUS';
export const HYPERTEST_SET_ALERT_STATUS = 'HYPERTEST_SET_ALERT_STATUS';
export const HYPERTEST_SHOW_JOB_LIST_AFTER_QUICK_RUN = 'HYPERTEST_SHOW_JOB_LIST_AFTER_QUICK_RUN';
