import React from 'react';

const DownloadIcon = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 8 11"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M0.625691 9.75H7.37569C7.4694 9.74983 7.55978 9.78475 7.62903 9.84788C7.69828 9.91102 7.74138 9.99779 7.74985 10.0911C7.75831 10.1844 7.73153 10.2776 7.67478 10.3521C7.61802 10.4267 7.5354 10.4773 7.44319 10.494L7.37569 10.5H0.625691C0.531982 10.5002 0.441604 10.4653 0.372354 10.4021C0.303104 10.339 0.260002 10.2522 0.251535 10.1589C0.243068 10.0656 0.269849 9.97244 0.326606 9.89788C0.383363 9.82331 0.465981 9.7727 0.558191 9.756L0.625691 9.75H7.37569H0.625691ZM3.93319 0.756L4.00069 0.75C4.08846 0.749971 4.17346 0.780729 4.24089 0.836917C4.30831 0.893105 4.35389 0.971164 4.36969 1.0575L4.37569 1.125V7.719L6.38719 5.70825C6.44924 5.64613 6.53112 5.60775 6.61856 5.5998C6.706 5.59185 6.79346 5.61484 6.86569 5.66475L6.91819 5.70825C6.9802 5.77039 7.01844 5.85232 7.02625 5.93976C7.03406 6.02719 7.01095 6.1146 6.96094 6.18675L6.91744 6.2385L4.26619 8.8905C4.20405 8.95251 4.12212 8.99075 4.03469 8.99856C3.94725 9.00637 3.85984 8.98326 3.78769 8.93325L3.73519 8.8905L1.08394 6.2385C1.0175 6.17236 0.978249 6.08373 0.973914 5.99008C0.969579 5.89643 1.00048 5.80456 1.06052 5.73256C1.12057 5.66057 1.2054 5.61367 1.2983 5.60112C1.39121 5.58857 1.48544 5.61127 1.56244 5.66475L1.61419 5.70825L3.62569 7.719V1.125C3.62566 1.03723 3.65642 0.952232 3.71261 0.884806C3.7688 0.81738 3.84686 0.771798 3.93319 0.756L4.00069 0.75L3.93319 0.756Z"
        fill={color ? color : 'white'}
      />
    </svg>
  );
};

export { DownloadIcon };
