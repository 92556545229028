export const HYP_UPDATE_SCHEDULE_SEARCH_TEXT =
  'HYP_UPDATE_SCHEDULE_SEARCH_TEXT';
export const HYP_SCHEDULE_JOB_REQUEST = 'HYP_SCHEDULE_JOB_REQUEST';
export const HYP_SCHEDULE_JOB_RESPONSE = 'HYP_SCHEDULE_JOB_RESPONSE';
export const HYP_CREATE_SCHEDULE_JOB_REQUEST =
  'HYP_CREATE_SCHEDULE_JOB_REQUEST';
export const HYP_CREATE_SCHEDULE_JOB_RESPONSE =
  'HYP_CREATE_SCHEDULE_JOB_RESPONSE';
export const HYP_SCHEDULE_JOB_RESET = 'HYP_SCHEDULE_JOB_RESET';
export const HYP_SCHEDULE_SEARCH_TEXT = 'HYP_SCHEDULE_SEARCH_TEXT';
export const HYP_UPDATE_SCHEDULE_LIST = 'HYP_UPDATE_SCHEDULE_LIST';
export const HYP_VALIDATE_REPO_URL_REQUEST = 'HYP_VALIDATE_REPO_URL_REQUEST';
export const HYP_VALIDATE_REPO_URL_RESPONSE = 'HYP_VALIDATE_REPO_URL_RESPONSE';
export const HYP_VALIDATE_WORKFLOW_REQUEST = 'HYP_VALIDATE_WORKFLOW_REQUEST';
export const HYP_VALIDATE_WORKFLOW_RESPONSE = 'HYP_VALIDATE_WORKFLOW_RESPONSE';
export const HYP_VALIDATION_RESET_ERRORS = 'HYP_VALIDATION_RESET_ERRORS';
