import React from 'react';

const Copy = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 13"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M8.82349 0C9.47055 0 10 0.540012 10 1.20001V9H8.82349V1.20001H2.94116V0H8.82349ZM6.47058 2.39999C7.11764 2.39999 7.64709 2.94001 7.64709 3.60001V10.8C7.64709 11.46 7.11764 12 6.47058 12H1.17651C0.529453 12 0 11.46 0 10.8V3.60001C0 2.94001 0.529453 2.39999 1.17651 2.39999H6.47058ZM6.47058 3.60001H1.17651V10.8H6.47058V3.60001Z"
        fill={color ? color : 'white'}
      />
    </svg>
  );
};

export { Copy };
