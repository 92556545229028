import React from 'react';

const Retry = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      aria-hidden="true"
      role="img"
      className={className}
    >
      <path
        d="M10.5 5.25H13V2.75"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.304 9.45534C12.9704 10.6708 12.2296 11.7349 11.2053 12.4695C10.181 13.204 8.93553 13.5645 7.67727 13.4905C6.41901 13.4166 5.22433 12.9127 4.29317 12.0632C3.36201 11.2137 2.75089 10.0701 2.56207 8.82393C2.37324 7.57772 2.61819 6.30447 3.25591 5.21727C3.89363 4.13007 4.88542 3.29489 6.06529 2.85151C7.24517 2.40814 8.54153 2.38347 9.73742 2.78163C10.9333 3.1798 11.9562 3.97664 12.6348 5.03879"
        stroke="#666666"
      />
    </svg>
  );
};

export { Retry };
