import React from 'react';

const OnboardWindowDark = ({
  width = 12,
  height = 12,
  className,
  color = '#333333',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 9.62223L5.09831 10.1889V6.27779H1V9.62223ZM1 5.77221H5.09831V1.81111L1 2.37777V5.77221ZM5.54885 10.25L11 11V6.27779H5.54885V10.25ZM5.54885 5.77221H11V1L5.54885 1.74999V5.77221Z"
        fill={color}
      />
    </svg>
  );
};

export { OnboardWindowDark };
