import * as action from '../constants/insightOverview';
import cloneDeep from 'lodash/cloneDeep';
//  import { repo, repoDetail } from './repo';
 import { transformRepoDetail } from 'utils/transformations/insight-overview';

 const initialState = {
   duration: '',
   isLoadingRepo: true,
   isLoadingDetails: false,
   isLoadingMoreRepoDetails: false,
   repoError: '',
   repos: [],
   searchedText: '',
   selectedLabel: '',
   selectedRepo: '',
   overviewGrid: {},
   endDate: new Date().toJSON(),
   startDate: new Date(new Date().setDate(new Date().getDate() - 60)).toJSON(),
 };

 function insightOverview(state = initialState, actions) {
   const { type, payload, extra } = actions;
   const oldState = cloneDeep(state);
   switch (type) {
     case action.HYP_INSIGHT_OVERVIEW_REPO_LOADING:
       return {
         ...oldState,
         isLoadingRepo: payload,
         repoError: false,
       };
     case action.HYP_INSIGHT_OVERVIEW_UPDATE_SELECTED_REPO:
       return {
         ...oldState,
         selectedRepo: payload,
       };
     case action.HYP_INSIGHT_OVERVIEW_REPO_DETAIL_LOADING:
       return {
         ...oldState,
         isLoadingDetails: true,
       };
     case action.HYP_INSIGHT_OVERVIEW_LOADING_MORE_REPO_DETAILS:
       return {
         ...oldState,
         isLoadingMoreRepoDetails: true,
       };
     case action.HYP_INSIGHT_SET_REPO_LOADING_TO_TRUE:
       return {
         ...oldState,
         isLoadingRepo: true,
         repoError: false,
       };
     case action.HYP_INSIGHT_OVERVIEW_UPDATE_REPOS:
       if (!payload || payload?.error || payload?.message) {
         return {
           ...oldState,
           repos: [],
           repoError: true,
           isLoadingRepo: false,
         };
       }
       return {
         ...oldState,
         repos: (payload || []).map((r) => ({
           label: r.name || r.id,
           value: r.id,
         })),
         isLoadingRepo: false,
         repoError: false,
       };
     case action.HYP_INSIGHT_OVERVIEW_REPO_DETAIL_FETCH_SUCCESS:
       if (!payload || payload?.error) {
         return {
           ...oldState,
           isLoadingDetails: false,
           overviewGrid: {},
           nextCursor: '',
           isLoadingMoreRepoDetails: false,
         };
       }
       return {
         ...oldState,
         isLoadingDetails: false,
         overviewGrid: transformRepoDetail(
           payload?.data || [],
           oldState.overviewGrid,
           extra?.clearExistingItems
         ),
         nextCursor: payload?.metadata?.next_cursor,
         isLoadingMoreRepoDetails: false,
       };
     default:
       return state;
   }
 }

export default insightOverview;
