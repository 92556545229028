// import js from '../../assets/images/onboarding/js.svg';
// import java from '../../assets/images/onboarding/java.svg';
// import ruby from '../../assets/images/onboarding/ruby.svg';
// import python from '../../assets/images/onboarding/python.svg';
// import php from '../../assets/images/onboarding/php.svg';
// import csharp from '../../assets/images/onboarding/csharp.svg';
// png TODO:Use svg instead of png , but for now svg image rendering is slow
import js from '../../assets/images/onboarding/js.png';
import java from '../../assets/images/onboarding/java.png';
import ruby from '../../assets/images/onboarding/ruby.png';
import python from '../../assets/images/onboarding/python.png';
// import php from '../../assets/images/onboarding/php.svg';
import csharp from '../../assets/images/onboarding/csharp.png';
//png
import selenium from '../../assets/images/onboarding/selenium.svg';
import pupeteer from '../../assets/images/onboarding/puppeteer.svg';
import playwrite from '../../assets/images/onboarding/playwright.svg';
import cypress from '../../assets/images/onboarding/cypress.svg';
import taiko from '../../assets/images/onboarding/taiko.svg';
import jsGray from '../../assets/images/onboarding/language/gray/js.svg';
import javaGray from '../../assets/images/onboarding/language/gray/java.svg';
import rubyGray from '../../assets/images/onboarding/language/gray/ruby.svg';
import pythonGray from '../../assets/images/onboarding/language/gray/python.svg';
// import phpGray from '../../assets/images/onboarding/language/gray/php.svg';
import csharpGray from '../../assets/images/onboarding/language/gray/csharp.svg';
const javaFrameworks = [
  {
    name: 'JUnit',
    language: 'Java',
    githubLink:
      'https://github.com/LambdaTest/junit-selenium-hyperexecute-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/junit-with-selenium-running-junit-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'TestNG',
    language: 'Java',
    githubLink:
      'https://github.com/LambdaTest/testng-selenium-hyperexecute-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/testng-with-selenium-running-java-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'Cucumber',
    language: 'Java',
    githubLink:
      'https://github.com/LambdaTest/cucumber-selenium-hyperexecute-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/running-cucumber-scripts-with-testng-and-selenium/',
  },
  // {
  //   name: 'Selenide',
  //   githubLink: 'https://github.com/LambdaTest/selenide-testng-sample',
  //   docLink:
  //     'https://www.lambdatest.com/support/docs/selenide-tests-with-lambdatest-online-selenium-grid-for-automated-cross-browser-testing/',
  // },
  // {
  //   name: 'Serenity',
  //   githubLink: 'https://github.com/LambdaTest/Serenity-Selenium-Sample',
  //   docLink:
  //     'https://www.lambdatest.com/support/docs/serenity-test-on-selenium-grid/',
  // },
];
const javaFrameworksPlaywright = [
  {
    name: 'JUnit',
    language: 'Java',
    githubLink:
      'https://github.com/LambdaTest/hyperexecute-java-playwright-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/junit-with-selenium-running-junit-automation-scripts-on-lambdatest-selenium-grid/',
  },
];
const jsFrameworks = [
  {
    name: 'NightwatchJS',
    language: 'JavaScript',
    githubLink:
      'https://github.com/LambdaTest/Hyperexecute-Nightwatch-Sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/nightwatch-with-selenium-running-nightwatch-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'WebdriverIO',
    language: 'JavaScript',
    githubLink:
      'https://github.com/LambdaTest/WebdriverIO-HyperExecute-Sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/webdriverio-with-selenium-running-webdriverio-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'Protractor',
    language: 'JavaScript',
    githubLink:
      'https://github.com/LambdaTest/Protractor-HyperExecute-Sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/webdriverio-with-selenium-running-webdriverio-automation-scripts-on-lambdatest-selenium-grid/',
  },
  // {
  //   name: 'TestCafe',
  //   githubLink: 'https://github.com/LambdaTest/testcafe-sample',
  //   docLink:
  //     'https://www.lambdatest.com/support/docs/npm-plugin-for-testcafe-integration-with-lambdatest/',
  // },
  // {
  //   name: 'Jest',
  //   githubLink: 'https://github.com/LambdaTest/jest-selenium-webdriver-sample',
  //   docLink:
  //     'https://www.lambdatest.com/support/docs/automation-testing-with-selenium-and-jest/',
  // },
  // {
  //   name: 'Mocha',
  //   githubLink: 'https://github.com/LambdaTest/mocha-selenium-sample',
  //   docLink:
  //     'https://www.lambdatest.com/support/docs/automation-testing-with-mocha-and-selenium/',
  // },
  // {
  //   name: 'Karma',
  //   githubLink: 'https://github.com/LambdaTest/angular-karma-sample',
  //   docLink:
  //     'https://www.lambdatest.com/support/docs/angularjs-with-karma-running-angular-tests-on-lambdatest-selenium-grid/',
  // },
  // {
  //   name: 'Jasmine',
  //   githubLink: 'https://github.com/LambdaTest/karma-jasmine-sample',
  //   docLink:
  //     'https://www.lambdatest.com/support/docs/jasmine-with-karma-running-jasmine-tests-on-lambdatest-selenium-grid/',
  // },
];

const rubyFrameworks = [
  {
    name: 'Capybara',
    language: 'Ruby',
    githubLink:
      'https://github.com/LambdaTest/Capybara-HyperExecute-Sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/ruby-with-capybara-running-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'RSpec',
    language: 'Ruby',
    githubLink: 'https://github.com/LambdaTest/Ruby-HyperExecute-Sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/rspec-with-selenium-running-rspec-automation-scripts-on-lambdatest-selenium-grid/',
  },
  // {
  //   name: 'Test::Unit',
  //   githubLink: 'https://github.com/4DvAnCeBoY/Ruby-UnitTest-Selenium-1',
  //   docLink:
  //     'https://www.lambdatest.com/support/docs/testunit-with-selenium-running-testunit-automation-scripts-on-lambdatest-selenium-grid/',
  // },
];

const csharpFrameworks = [
  // {
  //   name: "MBUnit"
  // },
  {
    name: 'NUnit',
    language: 'C Sharp',
    githubLink:
      'https://github.com/LambdaTest/nunit-selenium-hyperexecute-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/nunit-with-selenium-running-nunit-automation-scripts-on-lambdatest-selenium-grid/',
  },
  // {
  //   name: "PNUnit"
  // },
  {
    name: 'SpecFlow',
    language: 'C Sharp',
    docLink:
      'https://www.lambdatest.com/support/docs/specflow-with-selenium-running-specflow-automation-scripts-on-lambdatest-selenium-grid/',
    githubLink:
      'https://github.com/LambdaTest/specflow-selenium-hyperexecute-sample.git',
  },
];

const pythonFrameworks = [
  {
    name: 'PyTest',
    language: 'Python',
    githubLink:
      'https://github.com/LambdaTest/pytest-selenium-hyperexecute-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/pytest-with-selenium-running-pytest-automation-script-on-lambdatest-selenium-grid/',
  },
  {
    name: 'PyUnit',
    language: 'Python',
    githubLink:
      'https://github.com/LambdaTest/pyunit-selenium-hyperexecute-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/getting-started-with-lambdatest-automation/',
  },
  {
    name: 'Robot',
    language: 'Python',
    githubLink:
      'https://github.com/LambdaTest/robot-selenium-hyperexecute-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/robot-with-selenium-running-robot-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'Behave',
    language: 'Python',
    githubLink:
      'https://github.com/LambdaTest/behave-selenium-hyperexecute-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/behave-with-selenium-running-behave-automation-scripts-on-lambdatest-selenium-grid/',
  },
];

export const languages = [
  {
    name: 'Java',
    imageColored: java,
    image: javaGray,
    frameworks: javaFrameworks,
    docLink:
      'https://www.lambdatest.com/support/docs/java-with-selenium-running-java-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'JavaScript',
    imageColored: js,
    image: jsGray,
    frameworks: jsFrameworks,
    docLink:
      'https://www.lambdatest.com/support/docs/javascript-with-selenium-running-javascript-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'C Sharp',
    imageColored: csharp,
    image: csharpGray,
    frameworks: csharpFrameworks,
    docLink:
      'https://www.lambdatest.com/support/docs/c-with-selenium-running-c-automation-scripts-on-lambdatest-selenium-grid/',
  },
  // {
  //   name: 'Php',
  //   image: php,
  //   frameworks: phpFrameworks,
  //   docLink:
  //     'https://www.lambdatest.com/support/docs/php-with-selenium-running-php-automation-scripts-on-lambdatest-selenium-grid/',
  // },
  {
    name: 'Python',
    imageColored: python,
    image: pythonGray,
    frameworks: pythonFrameworks,
    docLink:
      'https://www.lambdatest.com/support/docs/python-with-selenium-running-python-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'Ruby',
    imageColored: ruby,
    image: rubyGray,
    frameworks: rubyFrameworks,
    docLink:
      'https://www.lambdatest.com/support/docs/ruby-with-selenium-running-ruby-automation-scripts-on-lambdatest-selenium-grid/',
  },
];

const jsFrameworksPuppeteer = [
  {
    name: 'Mocha',
    language: 'JavaScript',
    githubLink:
      'https://github.com/LambdaTest/hyperexecute-puppeteer-mocha-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/nightwatch-with-selenium-running-nightwatch-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'CodeceptJS',
    language: 'JavaScript',
    githubLink:
      'https://github.com/LambdaTest/hyperexecute-puppeteer-codecept-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/nightwatch-with-selenium-running-nightwatch-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'Jest',
    language: 'JavaScript',
    githubLink:
      'https://github.com/LambdaTest/hyperexecute-puppeteer-jest-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/webdriverio-with-selenium-running-webdriverio-automation-scripts-on-lambdatest-selenium-grid/',
  },
];
export const languagesPuppeteer = [
  {
    name: 'JavaScript',
    imageColored: js,
    image: jsGray,
    frameworks: jsFrameworksPuppeteer,
    docLink:
      'https://www.lambdatest.com/support/docs/javascript-with-selenium-running-javascript-automation-scripts-on-lambdatest-selenium-grid/',
  },
];
const jsFrameworksPlaywrite = [
  {
    name: 'VanillaJS',
    language: 'JavaScript',
    githubLink:
      'https://github.com/LambdaTest/HyperExecute-Playwright-Vanilla-Javascript.git',
    docLink:
      'https://www.lambdatest.com/support/docs/nightwatch-with-selenium-running-nightwatch-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'CodeceptJS',
    language: 'JavaScript',
    githubLink:
      'https://github.com/LambdaTest/HyperExecute-Playwright-CodeceptJs.git',
    docLink:
      'https://www.lambdatest.com/support/docs/nightwatch-with-selenium-running-nightwatch-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'Jest',
    language: 'JavaScript',
    githubLink:
      'https://github.com/LambdaTest/HyperExecute-Playwright-Jest.git',
    docLink:
      'https://www.lambdatest.com/support/docs/webdriverio-with-selenium-running-webdriverio-automation-scripts-on-lambdatest-selenium-grid/',
  },
];
const jsFrameworksCypress = [
  {
    name: 'Cypress-v9',
    language: 'JavaScript',
    githubLink:
      'https://github.com/LambdaTest/hyperexecute-cypress-v9-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/nightwatch-with-selenium-running-nightwatch-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'Cypress-v10',
    language: 'JavaScript',
    githubLink:
      'https://github.com/LambdaTest/hyperexecute-cypress-v10-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/nightwatch-with-selenium-running-nightwatch-automation-scripts-on-lambdatest-selenium-grid/',
  },
];
const csharpFrameworksPlaywrite = [
  {
    name: '.Net',
    language: 'C Sharp',
    githubLink:
      'https://github.com/LambdaTest/dotnet_playwright_hyperexecute_sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/nightwatch-with-selenium-running-nightwatch-automation-scripts-on-lambdatest-selenium-grid/',
  },
];
const pythonFrameworksPlaywrite = [
  {
    name: 'Python',
    language: 'Python',
    githubLink:
      'https://github.com/LambdaTest/hyperexecute-playwright-python-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/robot-with-selenium-running-robot-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'PyTest',
    language: 'Python',
    githubLink:
      'https://github.com/LambdaTest/hyperexecute-playwright-pypi-sample.git',
    docLink:
      'https://www.lambdatest.com/support/docs/pytest-with-selenium-running-pytest-automation-script-on-lambdatest-selenium-grid/',
  },
];
export const languagesPlaywright = [
  {
    name: 'Java',
    imageColored: java,
    image: javaGray,
    frameworks: javaFrameworksPlaywright,
    docLink:
      'https://www.lambdatest.com/support/docs/java-with-selenium-running-java-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'JavaScript',
    imageColored: js,
    image: jsGray,
    frameworks: jsFrameworksPlaywrite,
    docLink:
      'https://www.lambdatest.com/support/docs/javascript-with-selenium-running-javascript-automation-scripts-on-lambdatest-selenium-grid/',
  },
  {
    name: 'C Sharp',
    imageColored: csharp,
    image: csharpGray,
    frameworks: csharpFrameworksPlaywrite,
  },
  {
    name: 'Python',
    imageColored: python,
    image: pythonGray,
    frameworks: pythonFrameworksPlaywrite,
    docLink:
      'https://www.lambdatest.com/support/docs/python-with-selenium-running-python-automation-scripts-on-lambdatest-selenium-grid/',
  },
];
export const languagesCypress = [
  {
    name: 'JavaScript',
    imageColored: js,
    image: jsGray,
    frameworks: jsFrameworksCypress,
    docLink:
      'https://www.lambdatest.com/support/docs/javascript-with-selenium-running-javascript-automation-scripts-on-lambdatest-selenium-grid/',
  },
];

export const getGitHubUrl = (type, frameworkType = '') => {
  let framework = '';
  switch (type) {
    case 'PyUnit':
      framework = pythonFrameworks.filter((value) => value.name === 'PyUnit');
      break;
    case 'Python':
      if (frameworkType === 'PlayWright') {
        framework = pythonFrameworksPlaywrite.filter(
          (value) => value.name === 'Python'
        );
      }
      break;
    case 'PyTest':
      if (frameworkType === 'PlayWright') {
        framework = pythonFrameworksPlaywrite.filter(
          (value) => value.name === 'PyTest'
        );
      } else {
        framework = pythonFrameworks.filter((value) => value.name === 'PyTest');
      }
      break;
    case 'Behave':
      framework = pythonFrameworks.filter((value) => value.name === 'Behave');
      break;
    case 'Robot':
      framework = pythonFrameworks.filter((value) => value.name === 'Robot');
      break;
    case 'JUnit':
      if (frameworkType === 'PlayWright') {
        framework = javaFrameworksPlaywright.filter(
          (value) => value.name === 'JUnit'
        );
      } else {
        framework = javaFrameworks.filter((value) => value.name === 'JUnit');
      }
      break;
    case 'TestNG':
      framework = javaFrameworks.filter((value) => value.name === 'TestNG');
      break;
    case 'Cucumber':
      framework = javaFrameworks.filter((value) => value.name === 'Cucumber');
      break;
    case 'NUnit':
      framework = csharpFrameworks.filter((value) => value.name === 'NUnit');
      break;
    case 'SpecFlow':
      framework = csharpFrameworks.filter((value) => value.name === 'SpecFlow');
      break;
    case 'Capybara':
      framework = rubyFrameworks.filter((value) => value.name === 'Capybara');
      break;
    case 'RSpec':
      framework = rubyFrameworks.filter((value) => value.name === 'RSpec');
      break;
    case 'NightwatchJS':
      framework = jsFrameworks.filter((value) => value.name === 'NightwatchJS');
      break;
    case 'WebdriverIO':
      framework = jsFrameworks.filter((value) => value.name === 'WebdriverIO');
      break;
    case 'Protractor':
      framework = jsFrameworks.filter((value) => value.name === 'Protractor');
      break;
    case 'Playwright-Jest':
    case 'Jest':
    case 'Puppeteer-Jest':
      if (frameworkType === 'PlayWright') {
        framework = jsFrameworksPlaywrite.filter(
          (value) => value.name === 'Jest'
        );
      } else if (frameworkType === 'Puppeteer') {
        framework = jsFrameworksPuppeteer.filter(
          (value) => value.name === 'Jest'
        );
      } else {
        framework = jsFrameworksPlaywrite.filter(
          (value) => value.name === 'Jest'
        );
      }
      break;
    case 'Playwright-JS':
    case 'VanillaJS':
      framework = jsFrameworksPlaywrite.filter(
        (value) => value.name === 'VanillaJS'
      );
      break;
    case 'Playwright-CodeceptJs':
    case 'CodeceptJS':
    case 'Puppeteer-CodeceptJs':
      if (frameworkType === 'PlayWright') {
        framework = jsFrameworksPlaywrite.filter(
          (value) => value.name === 'CodeceptJS'
        );
      } else if (frameworkType === 'Puppeteer') {
        framework = jsFrameworksPuppeteer.filter(
          (value) => value.name === 'CodeceptJS'
        );
      } else {
        framework = jsFrameworksPlaywrite.filter(
          (value) => value.name === 'CodeceptJS'
        );
      }
      break;
    case 'Puppeteer-Mocha':
    case 'Mocha':
      framework = jsFrameworksPuppeteer.filter(
        (value) => value.name === 'Mocha'
      );
      break;
    case 'Playwright-.Net':
    case '.Net':
      framework = csharpFrameworksPlaywrite.filter(
        (value) => value.name === '.Net'
      );
      break;
    case 'Cypress-v9':
      framework = jsFrameworksCypress.filter(
        (value) => value.name === 'Cypress-v9'
      );
      break;
    case 'Cypress-v10':
      framework = jsFrameworksCypress.filter(
        (value) => value.name === 'Cypress-v10'
      );
      break;
    default:
      framework = pythonFrameworks.filter((value) => value.name === 'PyUnit');
  }
  return framework && framework.length && framework[0].githubLink;
};
export function getDefaultFramework(frameworkType) {
  let framework = {};
  switch (frameworkType) {
    case 'Selenium':
      framework = javaFrameworks.filter((value) => value.name === 'JUnit');
      break;
    case 'PlayWright':
      framework = javaFrameworksPlaywright.filter(
        (value) => value.name === 'JUnit'
      );
      break;
    case 'Puppeteer':
      framework = jsFrameworksPuppeteer.filter(
        (value) => value.name === 'Mocha'
      );
      break;
    case 'Cypress':
      framework = jsFrameworksCypress.filter(
        (value) => value.name === 'Cypress-v9'
      );
      break;
    default:
      framework = javaFrameworks.filter((value) => value.name === 'JUnit');
  }
  return framework && framework.length && framework[0];
}

export const ONBOARDING_TESTING_FRAMEWORK = [
  {
    index: 0,
    name: 'Selenium',
    image: selenium,
  },
  {
    index: 1,
    name: 'PlayWright',
    image: playwrite,
  },
  {
    index: 2,
    name: 'Puppeteer',
    image: pupeteer,
  },
  {
    index: 3,
    name: 'Cypress',
    image: cypress,
  },
  {
    index: 4,
    name: 'Taiko',
    image: taiko,
  },
];

export const getLanguageByFramework = (framework) => {};
