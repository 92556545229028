import React from 'react';

const CancelledGray = ({ width = '13', height = '12', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="6.5" cy="6" r="5.5" stroke="#C2C2C2" />
      <g clipPath="url(#clip0_14016_83833)">
        <path
          d="M6.49986 2.57227C4.6066 2.57227 3.07129 4.10758 3.07129 6.00084C3.07129 7.89409 4.6066 9.42941 6.49986 9.42941C8.39312 9.42941 9.92843 7.89409 9.92843 6.00084C9.92843 4.10758 8.39312 2.57227 6.49986 2.57227ZM6.49986 8.84792C5.96165 8.84771 5.43452 8.69497 4.97958 8.4074C4.52464 8.11984 4.16051 7.70922 3.92941 7.22315C3.6983 6.73708 3.60969 6.19547 3.67384 5.6611C3.73799 5.12673 3.95228 4.62148 4.29186 4.20392L8.29677 8.20849C7.78971 8.62296 7.15476 8.84903 6.49986 8.84827L6.49986 8.84792ZM8.70786 7.79775L4.70295 3.79318C5.24982 3.35085 5.94106 3.12657 6.64346 3.16359C7.34586 3.2006 8.0097 3.49628 8.50706 3.99363C9.00442 4.49099 9.3001 5.15484 9.33711 5.85724C9.37412 6.55963 9.14985 7.25088 8.70752 7.79775L8.70786 7.79775Z"
          fill="#C2C2C2"
        />
      </g>
      <defs>
        <clipPath id="clip0_14016_83833">
          <rect
            width="6.85714"
            height="6.85714"
            fill="white"
            transform="translate(3.07129 2.57227)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CancelledGray;
