import {
  HYPERTEST_SECRET_LIST_ACTION_REQUEST,
  HYPERTEST_SECRET_LIST_ACTIONS_RESPONSE,
  HYPERTEST_SECRET_ADD_ACTION_REQUEST,
  HYPERTEST_SECRET_ADD_ACTIONS_RESPONSE,
  HYPERTEST_SECRET_UPDATE_ACTION_REQUEST,
  HYPERTEST_SECRET_UPDATE_ACTIONS_RESPONSE,
  HYPERTEST_SECRET_DELETE_ACTION_REQUEST,
  HYPERTEST_SECRET_DELETE_ACTIONS_RESPONSE,
} from '../constants/secrets';

const initialState = {
  secrets: {
    list: {
      data: null,
      loading: false,
      error: false,
    },
    add: {
      data: null,
      loading: false,
      error: false,
    },
    update: {
      data: null,
      loading: false,
      error: false,
    },
    delete: {
      data: null,
      loading: false,
      error: false,
    },
  },
};

function secretReducer(state = initialState, actions) {
  const { type, payload, error } = actions;
  switch (type) {
    case HYPERTEST_SECRET_LIST_ACTION_REQUEST:
      return {
        ...state,
        secrets: {
          ...state.secrets,
          list: {
            ...state.secrets.list,
            loading: true,
          },
        },
      };
    case HYPERTEST_SECRET_LIST_ACTIONS_RESPONSE:
      return {
        ...state,
        secrets: {
          ...state.secrets,
          list: {
            ...state.secrets.list,
            data: payload,
            loading: false,
            error: error === undefined ? false : error,
          },
        },
      };
    case HYPERTEST_SECRET_ADD_ACTION_REQUEST:
      return {
        ...state,
        secrets: {
          ...state.secrets,
          add: {
            ...state.secrets.add,
            loading: true,
          },
        },
      };
    case HYPERTEST_SECRET_ADD_ACTIONS_RESPONSE:
      return {
        ...state,
        secrets: {
          ...state.secrets,
          add: {
            ...state.secrets.add,
            data: payload,
            loading: false,
            error: error === undefined ? false : error,
          },
        },
      };
    case HYPERTEST_SECRET_UPDATE_ACTION_REQUEST:
      return {
        ...state,
        secrets: {
          ...state.secrets,
          update: {
            ...state.secrets.update,
            loading: true,
          },
        },
      };
    case HYPERTEST_SECRET_UPDATE_ACTIONS_RESPONSE:
      return {
        ...state,
        secrets: {
          ...state.secrets,
          update: {
            ...state.secrets.update,
            data: payload,
            loading: false,
            error: error === undefined ? false : error,
          },
        },
      };
    case HYPERTEST_SECRET_DELETE_ACTION_REQUEST:
      return {
        ...state,
        secrets: {
          ...state.secrets,
          delete: {
            ...state.secrets.delete,
            loading: true,
          },
        },
      };
    case HYPERTEST_SECRET_DELETE_ACTIONS_RESPONSE:
      return {
        ...state,
        secrets: {
          ...state.secrets,
          delete: {
            ...state.secrets.delete,
            data: payload,
            loading: false,
            error: error === undefined ? false : error,
          },
        },
      };
    default:
      return state;
  }
}

export default secretReducer;
