import { useSelector } from 'react-redux';

import ltIcon from 'assets/images/congratulation-modal/lt-logo-green.svg';
import thunderLogo from 'assets/images/congratulation-modal/thunder-logo.svg';

import './index.css';

const CongratulationsModalHeader = () => {
  let userInfo = useSelector((state) => state.userInfo?.userInfo);
  let userName = userInfo?.data?.name || '';
  return (
    <div className="modal-heading-section">
      <div className="content">
        <div className="heading-top">
          <img src={ltIcon} alt="lambdatest logo"></img>
          <div className="heading-greetings">Hello {userName}</div>
        </div>
        <div className="heading-bottom">
          <div className="heading-congratulations">Congratulations!</div>
          <div className="heading-description">
            You are all set to experience Hyperexecute! Please feel free to give
            us a shout if you need any further assistance.
          </div>
        </div>
      </div>
      <div className="image-conatiner">
        <div className="image-conatiner-internal">
          <img src={thunderLogo} alt="" role="presentation"></img>
        </div>
      </div>
    </div>
  );
};

export default CongratulationsModalHeader;
