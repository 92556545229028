export const ToolTipDirection = {
  TOP: 'e',
  LEFT: 'n',
  TOP_LEFT: 'ne',
  BOTTOM_LEFT: 'nw',
  RIGHT: 's',
  TOP_RIGHT: 'se',
  BOTTOM_RIGHT: 'sw',
  BOTTOM: 'w',
};
