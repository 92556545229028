import React from 'react';

const Timeout = ({
  width,
  height,
  className,
  id = 'clip0_1267_3295',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <circle
        cx="18.0077"
        cy="18.9923"
        r="18"
        transform="rotate(-0.024375 18.0077 18.9923)"
        fill="#FF5D2A"
      />
      <g clipPath={`url(#${id})`}>
        <path
          d="M18.5 27.25C22.8492 27.25 26.375 23.7242 26.375 19.375C26.375 15.0258 22.8492 11.5 18.5 11.5C14.1508 11.5 10.625 15.0258 10.625 19.375C10.625 23.7242 14.1508 27.25 18.5 27.25Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.5625 16.3125L18.5 19.375"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.625 24.625L25.5 27.25"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.375 24.625L11.5 27.25"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.75 12.375L12.375 9.75"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.625 9.75L27.25 12.375"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5 11.5L18.5 9.75"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect
            width="21"
            height="21"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { Timeout };
