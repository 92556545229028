import store from 'store';

import { CALL_API } from '../middleware/api';
import {
  HYPERTEST_JOB_LIST_ACTIONS_REQUEST,
  HYPERTEST_JOB_LIST_ACTIONS_RESPONSE,
  HYPERTEST_JOB_LIST_ACTIONS_REQUEST_SSE,
  HYPERTEST_JOB_ABORT_ACTION_REQUEST,
  HYPERTEST_JOB_ABORT_ACTION_RESPONSE,
  HYPERTEST_UPDATE_JOB_LIST_ACTION,
  HYPERTEST_CLEAR_JOB_LIST,
  HYPERTEST_JOB_INSERT_INTO_JOB_LIST_ACTION,
  HYPERTEST_SET_JOB_LIST_VISUAL,
  HYPERTEST_JOB_TAGS_LIST_ACTIONS_REQUEST,
  HYPERTEST_JOB_TAGS_LIST_ACTIONS_RESPONSE,
  HYPERTEST_SET_CURRENT_SELECTED_JOB_TAGS,
  HYPERTEST_REMOVE_JOB,
  HYPERTEST_UPDATE_CLICKED_JOB_ID,
} from '../constants/hypertestJobList';

import { HYPERTEST_JOB_LIST_DATA_API_CALL_LIMIT } from '../../utils/constant';
import moment from 'moment';
import {
  SHOW_ALL_JOBS_FILTER,
  SHOW_ONLY_ARCHIVED_JOBS_FILTER,
} from 'constants/filters';
import {
  ARCHIVE_JOB_REQUEST,
  ARCHIVE_JOB_RESPONSE,
  UNARCHIVE_JOB_REQUEST,
  UNARCHIVE_JOB_RESPONSE,
} from 'store/constants/archiveJobs';
import { getSentinelApi } from 'new-theme/utils/onpremEndpoints';

const HYPERTEST_SENTINEL_API_ENDPOINT =
  process.env.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT ||
  (window._env_ && window._env_.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT);

const HYPERTEST_ORCHESTRATOR_API_ENDPOINT =
  process.env.REACT_APP_HYPERTEST_ORCHESTRATOR_API_ENDPOINT ||
  (window._env_ && window._env_.HYPERTEST_ORCHESTRATOR_API_ENDPOINT);
export const getHypertestJobList = ({
  auth,
  filter,
  enabledFilters,
  cursor,
  isSSERequest = false,
  storeUpdae = true,
}) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;
  let queryString = '';
  if (filter) {
    Object.keys(filter).forEach(function (k) {
      if (filter[k]) {
        if (filter[k] instanceof Array && enabledFilters.includes(k)) {
          if (k === 'range') {
            if (filter[k].length > 0) {
              let dataRange = filter[k][0];
              let startDate = moment(dataRange.startDate).format('YYYY-MM-DD');
              let endDate = moment(dataRange.endDate).format('YYYY-MM-DD');
              queryString = `${queryString}&${k}=${startDate},${endDate}`;
            }
          }

          if (
            filter[k][0] === SHOW_ALL_JOBS_FILTER ||
            filter[k][0] === SHOW_ONLY_ARCHIVED_JOBS_FILTER
          ) {
            queryString = `${queryString}&${filter[k][0]}=${true}`;
          } else {
            if (filter[k].length > 0) {
              queryString = `${queryString}&${k}=${filter[k].join(',')}`;
            }
          }
        }
        if (typeof filter[k] === 'string' || filter[k] instanceof String) {
          if (
            filter[k] === SHOW_ALL_JOBS_FILTER ||
            filter[k] === SHOW_ONLY_ARCHIVED_JOBS_FILTER
          ) {
            queryString = `${queryString}&${filter[k]}=${true}`;
          } else if (filter[k].trim() !== '') {
            queryString = `${queryString}&${k}=${filter[k]}`;
          }
        }
      }
    });
  }
  let queryLimit = HYPERTEST_JOB_LIST_DATA_API_CALL_LIMIT;
  if (window?.screen?.availHeight > 1000) {
    queryLimit = 20;
  }
  if (window?.screen?.availHeight > 2060) {
    queryLimit = 30;
  }
  let queryParams = `${queryString}&limit=${queryLimit}`;
  if (window.ltWorkflowId) {
    queryParams = `${queryString}&workflow_id=${window.ltWorkflowId}`;
  }
  if (cursor) {
    queryParams += `&cursor=${cursor}`;
  }
  let types = [
    HYPERTEST_JOB_LIST_ACTIONS_REQUEST,
    HYPERTEST_JOB_LIST_ACTIONS_RESPONSE,
  ];
  if (isSSERequest) {
    types = [
      HYPERTEST_JOB_LIST_ACTIONS_REQUEST_SSE,
      HYPERTEST_JOB_LIST_ACTIONS_RESPONSE,
    ];
  }
  if (!storeUpdae) {
    types = [
      'HYPERTEST_JOB_LIST_ACTIONS_REQUEST_NO_STORE_UPDATE',
      'HYPERTEST_JOB_LIST_ACTIONS_RESPONSE_NO_STORE_UPDATE',
    ];
  }

  return {
    [CALL_API]: {
      types: types,
      endpoint: `${sentinelUrl}/v1.0/jobs?${queryParams}&is_cursor_base_pagination=true`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};

export const getHypertestJobTagsList = ({ auth, search, next_cursor }) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;
  let queryParams = '';
  if (search) {
    queryParams = 'search=' + search;
  }
  if (next_cursor) {
    queryParams += `&next_cursor=${next_cursor}`;
  }

  return {
    [CALL_API]: {
      types: [
        HYPERTEST_JOB_TAGS_LIST_ACTIONS_REQUEST,
        HYPERTEST_JOB_TAGS_LIST_ACTIONS_RESPONSE,
      ],
      endpoint: `${sentinelUrl}/v1.0/job/tags?${queryParams}`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
      extra: {
        isLoadMore: !!next_cursor,
      },
    },
  };
};

export const setAlreadySelectedTagIntoList = (labelObjs) => {
  return {
    type: HYPERTEST_SET_CURRENT_SELECTED_JOB_TAGS,
    payload: labelObjs,
  };
};

export const abortJob = ({ auth, jobId }) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.ORCHESTRATOR_HOST) ||
    HYPERTEST_ORCHESTRATOR_API_ENDPOINT;
  return {
    [CALL_API]: {
      types: [
        HYPERTEST_JOB_ABORT_ACTION_REQUEST,
        HYPERTEST_JOB_ABORT_ACTION_RESPONSE,
      ],
      endpoint: `${sentinelUrl}/v1.0/abort/${jobId}`,
      method: 'PUT',
      appendBaseUrl: false,
      type: 'json',
      body: {
        status: 'aborted',
        remark: 'job has been aborted by user',
      },
      auth: auth,
    },
  };
};

export const abortAllJob = ({ auth, jobIds = [] }) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.ORCHESTRATOR_HOST) ||
    HYPERTEST_ORCHESTRATOR_API_ENDPOINT;
  return {
    [CALL_API]: {
      types: [
        HYPERTEST_JOB_ABORT_ACTION_REQUEST,
        HYPERTEST_JOB_ABORT_ACTION_RESPONSE,
      ],
      endpoint: `${sentinelUrl}/v1.0/jobs/bulk-abort`,
      method: 'PUT',
      appendBaseUrl: false,
      type: 'json',
      body: {
        job_ids: jobIds,
      },
      auth: auth,
    },
  };
};

export const getHypertestJobDetailToUpdateJobList_SSE = ({ auth, jobId }) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;

  const storeState = store.getState();
  const showTestSummary = storeState?.preferences?.showTestSummary;

  return {
    [CALL_API]: {
      types: [
        'HYPERTEST_JOB_DETAIL_TO_UPDATE_JOB_LIST_ACTIONS_SILENT_REQUEST',
        'HYPERTEST_JOB_DETAIL_TO_UPDATE_JOB_LIST_ACTIONS_SILENT_RESPONSE',
      ],
      endpoint: `${sentinelUrl}/v1.0/job/${jobId}?show_test_summary=${showTestSummary}`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};
export const insertNewJobIntoJoblist = (jobPayload) => {
  return {
    type: HYPERTEST_JOB_INSERT_INTO_JOB_LIST_ACTION,
    payload: jobPayload,
  };
};
export const updateJobList = (jobPayload) => {
  return {
    type: HYPERTEST_UPDATE_JOB_LIST_ACTION,
    payload: jobPayload,
  };
};
export const removeJobFromList = (jobId) => {
  return {
    type: HYPERTEST_REMOVE_JOB,
    payload: jobId,
  };
};
export const clearJobList = () => {
  return {
    type: HYPERTEST_CLEAR_JOB_LIST,
  };
};
export const updateJobListScrollOffset = (jobId) => {
  return {
    type: HYPERTEST_UPDATE_CLICKED_JOB_ID,
    payload: jobId,
  };
};
export const setJoblistVisual = (payload) => {
  return {
    type: HYPERTEST_SET_JOB_LIST_VISUAL,
    payload: payload,
  };
};
export const archiveJobs = (jobIdList, auth) => {
  const endpoint = `${getSentinelApi()}/v1.0/jobs/archive`;

  return {
    [CALL_API]: {
      types: [ARCHIVE_JOB_REQUEST, ARCHIVE_JOB_RESPONSE],
      endpoint: endpoint,
      method: 'PUT',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
      body: jobIdList,
    },
  };
};
export const unArchiveJob = (jobIdList, auth) => {
  const endpoint = `${getSentinelApi()}/v1.0/jobs/unarchive`;

  return {
    [CALL_API]: {
      types: [UNARCHIVE_JOB_REQUEST, UNARCHIVE_JOB_RESPONSE],
      endpoint: endpoint,
      method: 'PUT',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
      body: jobIdList,
    },
  };
};
