import selenium from '../../src/assets/images/onboarding/selenium-2.svg';
import pupeteer from '../../src/assets/images/onboarding/puppeteer.svg';
import playwright from '../../src/assets/images/onboarding/playwright.svg';
import cypress from '../../src/assets/images/onboarding/cypress.svg';
import taiko from '../../src/assets/images/onboarding/taiko.svg';
import TOSCA_SVG from 'assets/images/tosca/tosca.svg';
import { HYPERTEST_DEFAULT_JOB_LABLE_COUNT } from './constant';
import store from './../store/index';
import { trim, uniqueId } from 'lodash';
import { isBrandURL, getBrandURL } from 'utils/brandUrl';
import { Path } from 'constants/routes';
import { Role } from 'constants/app';
import { EventNames } from 'utils/events';

const queryString = require('query-string');

function isAdminRole(userRole = '') {
  return userRole === Role.ADMIN;
}

function getTaskInactiveFilterIcon(status) {
  switch (status) {
    case 'rescheduling':
      return 're-scheduled';
    case 'passed':
      return 'completed-gray';
    case 'completed':
      return 'completed-gray';
    // return 'completed-status';
    case 'stage-failed':
      return 'failed-gray';
    case 'running':
      return 'running-gray';
    case 'cancelled':
      return 'cancelled-gray';
    case 'pending':
      return 'pending-status';
    case 'aborted':
    case 'stopped':
      return 'abort-gray';
    case 'failed':
    case 'error':
      return 'failed-gray';
    case 'queued':
      return 'queued-gray';
    case 'initiated':
    case 'created':
      return 'initiated-gray';
    case 'timeout':
    case 'idle_timeout':
    case 'queue_timeout':
      return 'timeout-gray';
    case 'lambda_error':
    case 'lambda error':
      return 'lambda-error-gray';
    case 'skipped':
      return 'skipped-gray';
    default:
      return 'passed-status';
  }
}

function getTaskActiveFilterIcon(status) {
  switch (status) {
    case 'rescheduling':
      return 're-scheduled';
    case 'passed':
      return 'passed-status';
    case 'completed':
      return 'completed-green';
    // return 'completed-status';
    case 'stage-failed':
      return 'failed-status';
    case 'running':
      return 'stage-running';
    case 'cancelled':
      return 'stage-cancelled';
    case 'pending':
      return 'pending-status';
    case 'aborted':
    case 'stopped':
      return 'stage-aborted';
    case 'failed':
    case 'error':
      return 'stage-failed';
    case 'queued':
      return 'queued-status';
    case 'initiated':
    case 'created':
      return 'initiated-status';
    case 'timeout':
    case 'idle_timeout':
    case 'queue_timeout':
      return 'stage-timeout';
    case 'lambda_error':
    case 'lambda error':
      return 'stage-lambda_error';
    case 'skipped':
      return 'stage-skipped';
    default:
      return 'passed-status';
  }
}

function getStatusIcon(status, isAssocitated) {
  if (isAssocitated) {
    if (status === 'error') {
      return 'a-error';
    } else if (status === 'ignored' || status === 'unknown') {
      return 'a-ignored';
    }
  }

  switch (status) {
    case 'rescheduling':
      return 're-scheduled';
    case 'passed':
      return 'passed-status';
    case 'completed':
      return 'passed-status';
    // return 'completed-status';
    case 'stage-failed':
      return 'failed-status';
    case 'running':
      return 'running-status';
    case 'cancelled':
      return 'cancelled-status';
    case 'pending':
      return 'pending-status';
    case 'aborted':
    case 'stopped':
      return 'aborted-status';
    case 'failed':
    case 'error':
      return 'failed-status';
    case 'queued':
      return 'queued-status';
    case 'initiated':
    case 'created':
      return 'initiated-status';
    case 'timeout':
    case 'idle_timeout':
    case 'queue_timeout':
      return 'timeout';
    case 'lambda_error':
    case 'lambda error':
      return 'lambda_error';
    case 'skipped':
      return 'skipped';
    default:
      return 'passed-status';
  }
}
function getStageStatusIcon(taskLog) {
  let status = taskLog.status;
  let iconMetadata = {};
  switch (status) {
    case 'completed':
      // iconMetadata['name'] = 'stage-completed';
      iconMetadata['name'] = 'stage-passed';
      iconMetadata['tooltip'] = 'Completed';
      iconMetadata['width'] = 15;
      iconMetadata['height'] = 15;
      break;
    case 'passed':
      iconMetadata['name'] = 'stage-passed';
      iconMetadata['tooltip'] = 'Passed';
      iconMetadata['width'] = 15;
      iconMetadata['height'] = 15;
      break;
    case 'failed':
      iconMetadata['name'] = 'stage-failed';
      iconMetadata['tooltip'] = 'Failed';
      iconMetadata['width'] = 15;
      iconMetadata['height'] = 15;
      if (
        taskLog.type === 'artefact' &&
        taskLog.remark === 'no file found at the given regex'
      ) {
        iconMetadata['tooltip'] = 'Failed - No File found';
      }
      break;
    case 'running':
    case 'in-progress':
    case 'in progress':
    case 'initiated':
      iconMetadata['name'] = 'stage-running';
      iconMetadata['tooltip'] = 'Running';
      iconMetadata['width'] = 15;
      iconMetadata['height'] = 15;
      break;
    case 'created':
    case 'pending':
      iconMetadata['name'] = 'stage-created';
      iconMetadata['tooltip'] = 'Created';
      iconMetadata['width'] = 15;
      iconMetadata['height'] = 15;
      break;
    case 'cancelled':
      iconMetadata['name'] = 'stage-cancelled';
      iconMetadata['tooltip'] = 'Cancelled';
      iconMetadata['width'] = 15.5;
      iconMetadata['height'] = 15.5;
      break;
    case 'aborted':
    case 'stopped':
      iconMetadata['name'] = 'stage-aborted';
      iconMetadata['tooltip'] = 'Aborted';
      iconMetadata['width'] = 15;
      iconMetadata['height'] = 15;
      break;
    case 'timeout':
      iconMetadata['name'] = 'stage-timeout';
      iconMetadata['tooltip'] = 'Timeout';
      iconMetadata['width'] = 16;
      iconMetadata['height'] = 16;
      break;
    case 'queued':
      iconMetadata['name'] = 'stage-queued';
      iconMetadata['tooltip'] = 'Queued';
      iconMetadata['width'] = 15;
      iconMetadata['height'] = 15;
      break;
    case 'idle_timeout':
    case 'idle timeout':
      iconMetadata['name'] = 'stage-timeout';
      iconMetadata['tooltip'] = 'Timeout';
      iconMetadata['width'] = 15;
      iconMetadata['height'] = 15;
      break;
    case 'lambda_error':
    case 'lambda error':
      iconMetadata['name'] = 'stage-lambda_error';
      iconMetadata['tooltip'] = 'Lambda Error';
      iconMetadata['width'] = 15;
      iconMetadata['height'] = 15;
      break;
    case 'skipped':
      iconMetadata['name'] = 'stage-skipped';
      iconMetadata['tooltip'] = 'Skipped';
      iconMetadata['width'] = 15;
      iconMetadata['height'] = 15;
      break;
    default:
      iconMetadata['name'] = 'stage-passed';
      iconMetadata['tooltip'] = 'Passed';
      iconMetadata['width'] = 15;
      iconMetadata['height'] = 15;
  }
  return iconMetadata;
}
function getVRScreenshotStatusIcon(status) {
  let iconMetadata = {};
  switch (status) {
    case 'build-errors':
      iconMetadata['name'] = 'vr-build-errors';
      iconMetadata['tooltip'] = 'Builds Errors';
      iconMetadata['width'] = 16;
      iconMetadata['height'] = 16;
      break;
    case 'Changes found':
      iconMetadata['name'] = 'vr-changes-found';
      iconMetadata['tooltip'] = 'Changes Found';
      iconMetadata['width'] = 16;
      iconMetadata['height'] = 16;
      break;
    // case 'Approved':
    //   iconMetadata['name'] = 'vr-system-approved';
    //   iconMetadata['tooltip'] = 'System Approved';
    //   iconMetadata['width'] = 16;
    //   iconMetadata['height'] = 16;
    //   break;
    case 'Approved':
      iconMetadata['name'] = 'vr-user-approved';
      iconMetadata['tooltip'] = 'User Approved';
      iconMetadata['width'] = 16;
      iconMetadata['height'] = 16;
      break;
    case 'Under Screening':
      iconMetadata['name'] = 'vr-under-review';
      iconMetadata['tooltip'] = 'Under Review';
      iconMetadata['width'] = 16;
      iconMetadata['height'] = 16;
      break;
    default:
      iconMetadata['name'] = 'vr-under-review';
      iconMetadata['tooltip'] = 'Under Review';
      iconMetadata['width'] = 16;
      iconMetadata['height'] = 16;
  }
  return iconMetadata;
}
function checkTimeLimit(val) {
  let dayflag = true;
  if (val[0] === '-') return false;
  for (let i = 0; i < val.length - 4; i++) {
    if (val[i] === 'd' && val[i + 1] === 'a' && val[i + 2] === 'y') {
      let daysCount = parseInt(val.slice(0, i));
      dayflag = false;
      if (daysCount > 0) return false;
    }
  }
  if (dayflag) {
    for (let i = 0; i < val.length; i++) {
      if (val[i] === 'h') {
        let hoursCount = parseInt(val.slice(0, i));
        if (hoursCount > 10) return false;
      }
    }
  }
  return true;
}
function getFramewWorkIcon(framework) {
  switch (framework) {
    case 'selenium':
      return selenium;
    case 'cypress':
      return cypress;
    case 'puppeteer':
      return pupeteer;
    case 'taiko':
      return taiko;
    case 'playwright':
      return playwright;
    case 'tosca':
    case 'ttaforsap':
    case 'tosca-dex':
      return TOSCA_SVG;
    default:
      return selenium;
  }
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function convertTimeTaken(seconds, milliseconds = false, showSeconds = false) {
  if (milliseconds) {
    if (seconds < 1000) {
      return `${seconds}ms`;
    }
    seconds = seconds / 1000;
  }
  if (seconds < 3600) {
    var a = Math.floor(seconds / 60); //minutes
    var b = seconds % 60; //seconds
    var days = 0;

    if (b !== 1) {
      // sec = "seconds";
    } else {
      // sec = "second";
    }

    if (a !== 1) {
      // min = "minutes";
    } else {
      // min = "minute";
    }

    if (!a) {
      return `${b.toFixed(0)}s`;
    }
    b = Math.round(b * 1000) / 1000;
    return `${a}m ${b.toFixed(0)}s`;
  } else {
    a = Math.floor(seconds / 3600); //hours
    var x = seconds % 3600;
    b = Math.floor(x / 60); //minutes
    var c = seconds % 60; //seconds

    if (c !== 1) {
      // sec = "seconds";
    } else {
      // sec = "second";
    }

    if (b !== 1) {
      // min = "minutes";
    } else {
      // min = "minute";
    }

    if (c !== 1) {
      // hour = "hours";
    } else {
      // hour = "hour";
    }

    if (Number.isNaN(a) || Number.isNaN(b)) {
      return false;
    }

    if (a > 24) {
      days = Math.floor(a / 24);
      a = a % 24;

      if (days === 1)
        return `${days}day ${a}h ${b}m${showSeconds ? `${c}s` : ''}`;
      return `${days}days ${a}h ${b}m${showSeconds ? ` ${c}s` : ''}`;
    }

    return `${a}h ${b}m${showSeconds ? ` ${c}s` : ''}`;
  }
}
function timeSince(
  date,
  currentDate = null,
  secondsOnly = false,
  buildView = false
) {
  var createdDate = new Date(date).getTime() / 1000;
  var seconds = Math.floor(new Date().getTime() / 1000 - createdDate),
    interval = Math.floor(seconds / 31536000);
  if (secondsOnly) {
    return seconds;
  }
  if (interval > 1) return interval + ' years ago';

  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    if (interval === 1) {
      return interval + ' month ago';
    } else {
      return interval + ' months ago';
    }
  }

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    if (interval === 1) {
      return interval + ' day ago';
    } else {
      return interval + ' days ago';
    }
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    if (interval === 1) {
      return interval + ' hr ago';
    }
    return interval + ' hrs ago';
  }

  interval = Math.floor(seconds / 60);

  if (interval === 1) return interval + ' min ago';
  if (interval > 1) return interval + ' mins ago';

  if (isNaN(parseInt(seconds))) {
    return '-';
  }
  return Math.floor(seconds) + ' seconds ago';
}
function jobCreatedAt(created_at) {
  let dayCreatedAt = new Date(created_at);
  let dayNow = new Date().getUTCDate();
  if (Math.abs(dayNow - dayCreatedAt.getUTCDate()) > 3) {
    let days = dayCreatedAt.getUTCDate();
    days = days < 10 ? `0${days}` : days;
    return `${days} ${
      monthNames[dayCreatedAt.getUTCMonth()]
    } ${dayCreatedAt.getUTCFullYear()}`;
  } else {
    return timeSince(created_at, Date.now(), false, true);
  }
}
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
function getJobType(type) {
  let jobType = {};
  switch (type) {
    case 'autosplit':
      jobType.name = 'AutoSplit';
      jobType.icon = 'autosplit';
      break;
    case 'matrix':
      jobType.name = 'Matrix';
      jobType.icon = 'matrix';
      break;
    case 'hybrid':
      jobType.name = 'Hybrid';
      jobType.icon = 'hybrid';
      break;
    default:
      jobType.name = '';
      jobType.icon = '';
  }
  return jobType;
}

function getJobLabel(label) {
  let jobLabelObj = {
    jobLabelToShow: [],
    remainingLabels: [],
  };
  let jobLabel = isJsonString(label) ? JSON.parse(label) : [];
  if (jobLabel.length <= HYPERTEST_DEFAULT_JOB_LABLE_COUNT) {
    jobLabelObj.jobLabelToShow = jobLabel;
  } else {
    jobLabelObj.jobLabelToShow = jobLabel.splice(
      0,
      HYPERTEST_DEFAULT_JOB_LABLE_COUNT
    );
    jobLabelObj.remainingLabels = jobLabel;
  }
  // console.log('jobLabelObj', jobLabelObj);
  return jobLabelObj;
}

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function getTaskEnvironment(task) {
  let environment = {};
  switch (task.os) {
    case 'android':
      environment.category = 'rd/android';
      environment.osName = 'Android';
      break;
    case 'mac':
      environment.category = 'mac';
      environment.osName = 'MacOS';
      break;
    case 'ios':
      environment.category = 'mac';
      environment.osName = 'iOS';
      break;
    case 'win':
      environment.category = 'win';
      environment.osName = 'Windows';
      break;
    case 'linux':
      environment.category = 'linux';
      environment.osName = 'Linux';
      break;
    default:
      environment.category = 'mac';
      environment.osName = 'MacOS';
      break;
  }
  return environment;
}

function isCombineTasksInMatrixMode(context) {
  let result = false;
  let contextParsed = isJsonString(context) ? JSON.parse(context) : {};
  if ((contextParsed._combineTasksInMatrixMode || '').toLowerCase() === 'yes') {
    result = true;
  }
  return result;
}

function getTaskContext(context) {
  let contextParsed = isJsonString(context) ? JSON.parse(context) : '';
  delete contextParsed._jobId;
  delete contextParsed._taskId;
  delete contextParsed._combineTasksInMatrixMode;

  let contextValues = [];
  for (let key in contextParsed) {
    contextValues.push(contextParsed[key]);
  }
  return contextValues.join(', ');
}
function capitalizeFirstLetterOfString(string) {
  if (!string) {
    return '-';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function timeDifference(startDate, endDate) {
  if (!startDate || !endDate) {
    return '-';
  }
  const diff =
    (new Date(endDate).getTime() - new Date(startDate).getTime()) / 1000;
  const date = new Date(null);
  date.setSeconds(diff); // specify value for SECONDS here
  return date.toISOString().substr(11, 8);
}
const openZohoSalesIqChat = () => {
  window?.$zoho?.salesiq?.floatbutton?.visible('show');
  window?.$zoho?.salesiq?.floatwindow?.visible('show');
};
const sendAmplitudeEvents = (eventName, data, pageType, stageTime) => {
  let eventPageType = eventName.split('-');
  if (eventPageType?.length) {
    eventPageType = trim(eventPageType[eventPageType.length - 1]);
  } else if (pageType) {
    eventPageType = pageType;
  }
  let commonProperties = getAmpliteEventCommonProperties(
    eventPageType,
    stageTime
  );

  if (window.amplitude) {
    window.amplitude.getInstance().logEvent(eventName, {
      ...data,
      ...commonProperties,
    });
  }
};
function getAmpliteEventCommonProperties(eventPageType, stageTime) {
  let parsed = queryString.parse(window.location.search);
  let properties = {};
  let storeObj = store?.getState();
  let onboard = storeObj?.onboarding;
  let jobDetail = storeObj?.hypertestJobDetail;
  let isFirstTestAvailable = storeObj?.hypertestJobList?.isFirstTestAvailable;
  const tasksListData = storeObj?.hypertestTasksList?.listData;
  // current task
  let currentTask = tasksListData?.data?.filter((task) => {
    return task.id === parsed?.taskId;
  });
  if (currentTask?.length) {
    currentTask = currentTask[0];
  }
  // current stage
  //const stageListData = storeObj?.hypertestTaskLog?.hypertestTaskLog;

  // let currentStage = stageListData?.data?.data?.filter((stage) => {
  //   return parseInt(stage.order) === parseInt(parsed.order);
  // });

  // if (currentStage?.length) {
  //   currentStage = currentStage[0];
  // }
  // current job
  let currentJob = jobDetail?.hypertestJobDetail?.data?.data;
  let consumedTime = checkTimeLimit(
    convertTimeTaken(
      (new Date(currentJob?.end_time) - new Date(currentJob?.start_time)) / 1000
    )
  )
    ? convertTimeTaken(
        (new Date(currentJob?.end_time) - new Date(currentJob?.start_time)) /
          1000
      )
    : '-';
  let taskTime = convertTimeTaken(
    (new Date(currentTask?.end_time) - new Date(currentTask?.start_time)) / 1000
  );
  // let stageTime = convertTimeTaken(
  //   (new Date(currentStage?.updated_at) -
  //     new Date(currentStage?.started_at || currentStage?.created_at)) /
  //     1000
  // );

  switch (eventPageType) {
    case 'quickstart':
    case 'try an example':
    case 'generate yaml':
      properties['testing framework'] =
        onboard?.currentConfiguration?.frameworkType;
      properties['language'] =
        onboard?.currentConfiguration?.framework?.language;
      properties['framework'] = onboard?.currentConfiguration?.framework?.name;
      properties['isFirstTest'] = !isFirstTestAvailable;
      break;
    case 'task view':
      properties['step duration'] = stageTime;
      // properties['step status'] = currentStage?.status;
      properties['task id'] = currentTask?.id;
      properties['task duration'] = taskTime;
      properties['task status'] = currentTask?.status;
      properties['job id'] = currentJob?.id;
      properties['job duration'] = consumedTime;
      properties['job status'] = currentJob?.status;
      break;

    default: {
      if (currentJob?.id) {
        properties['job id'] = currentJob?.id;
        properties['job status'] = currentJob?.status;
      }

      if (currentTask?.id || parsed?.taskId) {
        properties['task id'] = currentTask?.id || parsed?.taskId;
        properties['task status'] = currentTask?.status || parsed?.taskStatus;

        if (parsed?.stageId) {
          properties['stage id'] = parsed?.stageId;
        }
      }
      break;
    }
  }
  return properties;
}
const gettime = (olddatetime) => {
  //console.log(timeSince(olddatetime, Date.now(), false, true));

  return timeSince(olddatetime, Date.now(), false, true);
};
function VerifyUser(arr, name) {
  return arr.includes(name);
  //return false;
}
function redirectToAutomation(testId) {
  let baseUrl = process.env.REACT_APP_MAGICLEAP_PROD_URL || window._env_.REACT_APP_MAGICLEAP_PROD_URL;
  if (isBrandURL()) {
    baseUrl = getBrandURL(baseUrl);
  }
  window.open(`${baseUrl}test?testID=${testId}`, '_blank', 'noreferrer');
}
function getCurrentViewPage() {
  let pathname = window?.location?.pathname;
  // we  need this change as onboarding urls contain testname and framework

  if (pathname.includes(Path.QUICK_START_QUICK_RUN.Path)) {
    return 'quick-run';
  }
  switch (pathname) {
    case Path.TASK:
      return 'task view';
    case Path.JOBS:
      return 'job view';
    case Path.QUICK_START:
      return 'onboarding';
    default:
      return '';
  }
}
function getCurrentTab(tabType) {
  switch (tabType) {
    case 'sampleTest':
      return 'try an example';
    case 'yamlBased':
      return 'generate yaml for test scenarios';
    case 'gitpod':
      return 'run an example on gitpod';
    case 'quick-run':
      return 'quick run';
    default:
      return '';
  }
}
function displayStatusText(status) {
  switch (status) {
    case 'completed':
      return 'Completed';
    case 'passed':
      return 'Passed';
    case 'failed':
      return 'Failed';
    case 'running':
      return 'Running';
    case 'in-progress':
      return 'Running';
    case 'initiated':
      return 'Initiated';
    case 'created':
      return 'Created';
    case 'pending':
      return 'Created';
    case 'cancelled':
      return 'Cancelled';
    case 'aborted':
      return 'Aborted';
    case 'queued':
      return 'Queued';
    case 'timeout':
    case 'idle_timeout':
      return 'Timeout';
    case 'lambda_error':
      return 'Lambda Error';
    case 'skipped':
      return 'Skipped';
    default:
      return status;
  }
}
function getStatusDataList(testCount) {
  let list = [
    //   {
    //     count: 2,
    //     type: 'passed',
    //   },
    //   {
    //     count: 2,
    //     type: 'completed',
    //   },
    //   {
    //     count: 2,
    //     type: 'failed',
    //   },
    //   {
    //     count: 2,
    //     type: 'error',
    //   },
    //   // {
    //   //   count: 2,
    //   //   type: 'lambda error',
    //   // },
    //   {
    //     count: 2,
    //     type: 'lambda_error',
    //   },
    //   {
    //     count: 2,
    //     type: 'running',
    //   },
    //   {
    //     count: 2,
    //     type: 'initiated',
    //   },
    //   {
    //     count: 2,
    //     type: 'timeout',
    //   },
    //   {
    //     count: 2,
    //     type: 'idle_timeout',
    //   },
    //   {
    //     count: 2,
    //     type: 'queue_timeout',
    //   },
    //   {
    //     count: 2,
    //     type: 'queued',
    //   },
    //   {
    //     count: 2,
    //     type: 'stopped',
    //   },
    //   {
    //     count: 2,
    //     type: 'cancelled',
    //   },
  ];
  testCount &&
    Object.keys(testCount).forEach((val) => {
      let status = val;
      if (status === 'lambda error') {
        status = 'lambda_error';
      }
      list.push({ count: testCount[val], type: status });
    });
  return list;
}
function hideAndShowScrollToTopBtn(e) {
  let scrollTopBtn = document.getElementsByClassName('top-scroll-btn');
  let scrollBottomBtn = document.getElementsByClassName('bottom-scroll-btn');
  if (scrollTopBtn && scrollTopBtn[0]) {
    if (e.scrollTop > 50) {
      scrollTopBtn[0].style.display = 'block';
    } else {
      scrollTopBtn[0].style.display = 'none';
    }
  }
  if (scrollBottomBtn && scrollBottomBtn[0]) {
    const bottom =
      e.scrollHeight - e.scrollTop === e.clientHeight ||
      e.scrollHeight - e.scrollTop + 4 === e.clientHeight;
    if (bottom || e === null) {
      scrollBottomBtn[0].style.display = 'none';
    } else {
      scrollBottomBtn[0].style.display = 'block';
    }
  }
}
function getShorterString(str, allowedChars = 30) {
  if (str.length > allowedChars) {
    return `${str?.substring(0, allowedChars)}...`;
  }
  return str;
}
function capitalizeFirstChar(str) {
  if (str) {
    return `${str.charAt(0).toUpperCase()}${str?.substring(1, str.length)}`;
  }
  return str;
}
const incompleteStatusList = ['running', 'created', 'in-progress'];
const chunkBasedStageList = ['scenario', 'postrun', 'prerun'];
function getCustomizedStructure(artefectFolderStructure = {}) {
  let files = [];
  let hasMoreFiles = false;
  for (let key in artefectFolderStructure?.files) {
    if (files.length === 15) {
      hasMoreFiles = true;
      break;
    }
    files.push(artefectFolderStructure.files[key]);
  }

  for (let i = 0; i < files.length; i++) {
    files[i].key = uniqueId('key-');
  }

  artefectFolderStructure.files = files;
  if (artefectFolderStructure?.files?.length === 15 || files.length === 15) {
    hasMoreFiles = true;
  }
  return [artefectFolderStructure, hasMoreFiles];
}
function getMimeType(fileType) {
  let mimeType = 'application/json';
  switch (fileType) {
    case 'html':
      mimeType = 'text/html';
      break;
    case 'xml':
      mimeType = 'application/xml';
      break;
    case 'json':
      mimeType = 'application/json';
      break;
    case 'png':
      mimeType = 'image/png';
      break;
    case 'jpeg':
    case 'jpg':
      mimeType = 'image/jpeg';
      break;
    case 'pdf':
      mimeType = 'application/pdf';
      break;
    default:
      mimeType = 'application/json';
  }
  return mimeType;
}

function manageNegativeTime(time, taskLog = undefined) {
  if (time === '-' || time === '--') {
    return time;
  } else if (time.indexOf('-') >= 0 && taskLog) {
    sendAmplitudeEvents(EventNames.HYP_NEGATIVE_TASK_TIME, {
      updated_at: taskLog.updated_at,
      started_at: taskLog.started_at,
      name: taskLog.name,
    });
  }
  return time.replaceAll('-', '');
}

function convertTimeFromHMSFormat(timeString) {
  const timeInDHMSFormat = {};
  const time = {
    d: 36400,
    h: 3600,
    m: 60,
    s: 1,
  };

  let num = '';

  for (let x of timeString) {
    if (isNaN(x)) {
      timeInDHMSFormat[x] = num;
      num = '';
    } else {
      num += x;
    }
  }

  Object.keys(time).forEach((key) => {
    if (timeInDHMSFormat[key]) {
      timeInDHMSFormat[key] = parseInt(timeInDHMSFormat[key]) * time[key];
    }
  });
  let totalSum = 0;

  Object.keys(timeInDHMSFormat).forEach((key) => {
    totalSum += timeInDHMSFormat[key];
  });
  return totalSum;
}

export {
  manageNegativeTime,
  VerifyUser,
  capitalizeFirstChar,
  capitalizeFirstLetter,
  capitalizeFirstLetterOfString,
  checkTimeLimit,
  chunkBasedStageList,
  convertTimeTaken,
  convertTimeFromHMSFormat,
  displayStatusText,
  getCurrentTab,
  getCurrentViewPage,
  getCustomizedStructure,
  getFramewWorkIcon,
  getJobLabel,
  getJobType,
  getMimeType,
  getShorterString,
  getStageStatusIcon,
  getStatusDataList,
  getStatusIcon,
  getTaskActiveFilterIcon,
  getTaskContext,
  getTaskEnvironment,
  getTaskInactiveFilterIcon,
  getVRScreenshotStatusIcon,
  gettime,
  hideAndShowScrollToTopBtn,
  incompleteStatusList,
  isAdminRole,
  isCombineTasksInMatrixMode,
  isJsonString,
  jobCreatedAt,
  monthNames,
  openZohoSalesIqChat,
  redirectToAutomation,
  sendAmplitudeEvents,
  timeDifference,
  timeSince,
};
