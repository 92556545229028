import React from 'react';

const PJQueued = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M7.9983 12C9.98646 12 11.5982 10.3883 11.5982 8.40016C11.5982 6.41201 9.98646 4.80029 7.9983 4.80029C6.01015 4.80029 4.39844 6.41201 4.39844 8.40016C4.39844 10.3883 6.01015 12 7.9983 12Z"
        stroke="#333333"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.40054 7L8 8.40054"
        stroke="#333333"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 5.20047L5.20047 4"
        stroke="#333333"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7969 4L11.9973 5.20047"
        stroke="#333333"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.7997L8 4"
        stroke="#333333"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PJQueued;
