import React from 'react';

const BlueCheck = ({ width, height, className, color, bgColor, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0Z"
        fill={bgColor ? bgColor : color ? 'white' : '#0ebac5'}
      />
      <path
        d="M6.67678 11.2592L11.6893 6.24724L11.6969 6.25487C11.747 6.20474 11.7867 6.14523 11.8138 6.07974C11.8409 6.01424 11.8549 5.94404 11.8549 5.87315C11.8549 5.80226 11.8409 5.73206 11.8138 5.66656C11.7867 5.60107 11.747 5.54156 11.6969 5.49143C11.6468 5.4413 11.5873 5.40153 11.5219 5.37441C11.4564 5.34728 11.3863 5.33331 11.3154 5.33331C11.2446 5.33331 11.1744 5.34728 11.109 5.37441C11.0435 5.40153 10.984 5.4413 10.9339 5.49143L6.68441 9.73999L4.47954 7.53364C4.37836 7.4324 4.24115 7.37552 4.09807 7.37552C3.95499 7.37552 3.81777 7.4324 3.7166 7.53364C3.61543 7.63488 3.55859 7.77219 3.55859 7.91536C3.55859 8.05853 3.61543 8.19584 3.7166 8.29708L6.67678 11.2592Z"
        fill={color ? '#0ebac5' : 'white'}
      />
      {/* <path
        d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456725 3.7039C0.00259972 4.80025 -0.11622 6.00665 0.115291 7.17054C0.346802 8.33443 0.918247 9.40352 1.75736 10.2426C2.59648 11.0818 3.66557 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0891 10.3295 10.3201 10.9888 9.33342C11.6481 8.34672 12 7.18669 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0Z"
        fill="#0ebac5"
      />
      <path
        d="M5.00661 8.44444L8.76596 4.68544L8.77168 4.69117C8.80926 4.65357 8.83906 4.60894 8.85939 4.55982C8.87973 4.51069 8.89019 4.45805 8.89019 4.40488C8.89019 4.35171 8.87973 4.29906 8.85939 4.24994C8.83906 4.20082 8.80926 4.15618 8.77168 4.11859C8.73411 4.08099 8.68951 4.05117 8.64042 4.03082C8.59133 4.01047 8.53872 4 8.48558 4C8.43245 4 8.37984 4.01047 8.33075 4.03082C8.28166 4.05117 8.23706 4.08099 8.19948 4.11859L5.01233 7.30501L3.35868 5.65024C3.2828 5.57431 3.17988 5.53166 3.07258 5.53166C2.96527 5.53166 2.86235 5.57431 2.78648 5.65024C2.7106 5.72617 2.66797 5.82915 2.66797 5.93653C2.66797 6.04391 2.7106 6.1469 2.78648 6.22283L5.00661 8.44444Z"
        fill="white"
      /> */}
    </svg>
  );
};

export { BlueCheck };
