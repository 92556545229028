import React from 'react';

const QueuedGray = ({ width = '14', height = '14', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="7.5" cy="7" r="7" fill="#C2C2C2" />
      <path
        d="M4.49609 9.47461C4.49609 9.33654 4.60802 9.22461 4.74609 9.22461H10.2461C10.3842 9.22461 10.4961 9.33654 10.4961 9.47461V9.75025C10.4961 9.88832 10.3842 10.0003 10.2461 10.0003H4.74609C4.60802 10.0003 4.49609 9.88832 4.49609 9.75025V9.47461Z"
        fill="white"
      />
      <path
        d="M4.49609 7.95898C4.49609 7.82091 4.60802 7.70898 4.74609 7.70898H10.2461C10.3842 7.70898 10.4961 7.82091 10.4961 7.95898V8.23463C10.4961 8.3727 10.3842 8.48463 10.2461 8.48463H4.74609C4.60802 8.48463 4.49609 8.3727 4.49609 8.23463V7.95898Z"
        fill="white"
      />
      <path
        d="M4.49609 4.5C4.49609 4.22386 4.71995 4 4.99609 4H9.99609C10.2722 4 10.4961 4.22386 10.4961 4.5V6.21475C10.4961 6.49089 10.2722 6.71475 9.99609 6.71475H4.99609C4.71995 6.71475 4.49609 6.49089 4.49609 6.21475V4.5Z"
        fill="white"
      />
    </svg>
  );
};

export default QueuedGray;
