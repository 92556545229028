import React, { useEffect, useRef, useState } from 'react';
import { ThemeProvider } from '@primer/react';
import { Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import LTTheme from './LTTheme';

import LTCommonHeader from '@lambdatestincprivate/lt-common-header';
import Loader from 'new-theme/components/Loader';
import Routes from '../../routes';
import history from 'utils/history';

import CustomHeader from './components/custom-header';

import AppBannerAndModals from './modals';
import useFeatureFlags from 'hooks/useFeatureFlags';

// import ErrorBoundary from 'new-theme/components/ErrorBoundary';

import { toasterStyle, TOOLTIP_CONTAINER_ID } from './constants';
import {
  FF_DISABLE_NEW_THEME,
  FF_ACCESSIBILITY_TOOLS,
} from 'constants/feature-flags';

import './style.css';
import './high-contrast.css';
import useAppWithHeader from './useAppWithHeader';
import useApp from 'hooks/useApp';
import { LTToast } from '@lambdatestincprivate/lt-components';

function App() {
  const { userInfo = {}, orgPreference = {} } = useApp();
  const featureFlags = useFeatureFlags();

  const { data: userInfoData } = userInfo;

  const { error: opError } = orgPreference;

  const areFeatureFlagsLoading = featureFlags?.isLoading;

  const isThingsLoading =
    !userInfoData || opError === null || areFeatureFlagsLoading;

  const showNewTheme = !useSelector(
    (state) => state?.featureFlag?.[FF_DISABLE_NEW_THEME]
  );

  const wcagOn = useSelector(
    (state) => state?.featureFlag?.[FF_ACCESSIBILITY_TOOLS]
  );

  const timeRef = useRef(0);
  const clickRef = useRef(0);

  const [forceOldTheme, setForceOldTheme] = useState(false);

  function handleClick(e) {
    e.stopPropagation();
    const timeToMatch = timeRef.current;
    const curTime = new Date().getTime();
    const dif = curTime - timeToMatch;

    const header = document.getElementsByClassName('common-header-title')[0];
    let isRightElement = e.target.contains(header);

    if (clickRef.current >= 4 && dif <= 1000 && isRightElement) {
      setForceOldTheme((val) => !val);
      clickRef.current = 0;
      timeRef.current = 0;
    } else {
      timeRef.current = new Date().getTime();
      clickRef.current += 1;
    }
  }

  useEffect(() => {
    if (wcagOn && window?.toast?.extendDuration !== undefined) {
      window.toast.extendDuration = wcagOn;
    }
  }, [wcagOn]);

  useEffect(() => {
    let header;
    setTimeout(() => {
      header = document.getElementsByClassName('common-header-title')[0];
      header?.addEventListener('click', handleClick);
    }, 3000);

    return () => {
      header?.removeEventListener('click', handleClick);
      clickRef.current = 0;
      timeRef.current = 0;
    };
  }, []);

  if (isThingsLoading) {
    return <Loader isSuspenseLoader={true} />;
  }

  function sendNotification(addToast) {
    window.toast = {
      addToast,
      extendDuration: wcagOn,
      toastVariants: LTToast.ToastVariants,
    };
  }

  let appContent = (
    <>
      <ReactTooltip id={TOOLTIP_CONTAINER_ID} html={true} />
      <Toaster containerStyle={toasterStyle} position="top-right" />
      <LTToast sendNotification={sendNotification} />
      <AppWithHeader wcagOn={wcagOn} showNewTheme={showNewTheme}>
        <Content forceOldTheme={forceOldTheme} />
      </AppWithHeader>
    </>
  );

  if (showNewTheme && !forceOldTheme) {
    appContent = (
      <ThemeProvider
        dayScheme={wcagOn ? 'light_high_contrast' : 'light'}
        theme={LTTheme}
      >
        {appContent}
      </ThemeProvider>
    );
  }
  return appContent;
}

export default App;

function AppWithHeader({ children, showNewTheme, wcagOn }) {
  const { concurrencyToRender } = useAppWithHeader();

  // return <div className="wrapper"> {children}</div>;

  return (
    <LTCommonHeader
      customProductHeader={<CustomHeader />}
      isHighContrastThemeEnabled={wcagOn}
      concurrencyInfo={concurrencyToRender}
      showTunnel={true}
      isNewThemeEnabled={showNewTheme}
      onPremPrefixToUse={window?._env_?.REACT_APP_ONPREM_PREFIX || undefined}
    >
      {children}
    </LTCommonHeader>
  );
}

function Content({ forceOldTheme }) {
  let promoBannerHeight =
    useSelector((state) => state.appSetting?.setting?.promoBannerHeight) || 0;

  let showNewTheme = !useSelector(
    (state) => state?.featureFlag?.[FF_DISABLE_NEW_THEME]
  );

  const showHighContrast = useSelector(
    (state) => state?.featureFlag?.[FF_ACCESSIBILITY_TOOLS]
  );

  let className = 'main-section';

  if (showNewTheme && !forceOldTheme) {
    className += ' new-theme';
  }

  useEffect(() => {
    if (showHighContrast) {
      document
        .getElementsByTagName('body')[0]
        .classList?.add('lt-contrast-theme-body');
      removeAnnouncekit();
    } else {
      insertAnnouncekit();
    }
  }, [showHighContrast]);

  className +=
    promoBannerHeight > 0
      ? ' newHeaderContentWrapperWithBanner'
      : ' newHeaderContentWrapper special';

  if (showHighContrast) {
    className += ' lt-contrast-theme';
  }

  function insertAnnouncekit() {
    const script = document.createElement('script');
    script.src = '//cdn.announcekit.app/widget.js';
    script.id = 'lt-announcekit';
    script.type = 'text/javascript';
    script.async = true;
    document.head.appendChild(script);
  }

  function removeAnnouncekit() {
    const scriptElement = document.getElementById('lt-announcekit');
    if (scriptElement) {
      scriptElement.remove();
    }
  }

  return (
    <React.Suspense fallback={<Loader isSuspenseLoader={true} />}>
      <Router history={history}>
        <main className={className} id="app-main-section">
          <AppBannerAndModals />
          <Routes forceOldTheme={forceOldTheme} history={history} />
        </main>
      </Router>
    </React.Suspense>
  );
}
