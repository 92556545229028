import { USER_LIST_LOADING, USER_LIST_SUCCESS } from 'store/constants/userList';

const InitailState = {
  loading: false,
  data: [],
};

const formatListData = (data = []) => {
  const formatedData = {};
  data.forEach((user) => {
    const userName = user?.username;
    const name = user?.name;
    if (userName && name) {
      formatedData[userName] = name;
    }
  });
  return formatedData;
};

function userList(state = InitailState, action) {
  switch (action.type) {
    case USER_LIST_LOADING:
      return { ...state, loading: true };
    case USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: formatListData(action?.payload?.data),
      };
    default:
      return state;
  }
}

export default userList;
