export const USER_INFO_LOADING = 'USER_INFO_LOADING';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_SET_PLAN_EXPIRED_MODAL =
  'USER_INFO_SET_PLAN_EXPIRED_MODAL';
export const USER_INFO_SET_LIMIT_EXHAUSTED_MODAL =
  'USER_INFO_SET_LIMIT_EXHAUSTED_MODAL';
export const USER_INFO_SET_LIMIT_EXHAUSTED_THRESHOLD_MODAL =
  'USER_INFO_SET_LIMIT_EXHAUSTED_THRESHOLD_MODAL';
export const USER_INFO_SET_TRIAL_FEATURE_MODAL =
  'USER_INFO_SET_TRIAL_FEATURE_MODAL';
export const USER_CONSUMED_TIME_LOADING = 'USER_CONSUMED_TIME_LOADING';
export const USER_CONSUMED_TIME_SUCCESS = 'USER_CONSUMED_TIME_SUCCESS';
export const USER_INFO_SET_PLAN_INFO_VALUE = 'USER_INFO_SET_PLAN_INFO_VALUE';
export const USER_INFO_SET_COMMON_UI_VISUAL = 'USER_INFO_SET_COMMON_UI_VISUAL';
export const USER_ORG_PREFERENCE_LOADING = 'USER_ORG_PREFERENCE_LOADING';
export const USER_ORG_PREFERENCE_SUCCESS = 'USER_ORG_PREFERENCE_SUCCESS';
export const HYPERTEST_ONBOARDING_ENABLE_HYPEREXECUTE_PLAN_LOADING =
  'HYPERTEST_ONBOARDING_ENABLE_HYPEREXECUTE_PLAN_LOADING';
export const HYPERTEST_ONBOARDING_ENABLE_HYPEREXECUTE_PLAN_SUCCESS =
  'HYPERTEST_ONBOARDING_ENABLE_HYPEREXECUTE_PLAN_SUCCESS';
export const USER_TEAM_INFO_LOADING = 'USER_TEAM_INFO_LOADING';
export const USER_TEAM_INFO_SUCCESS = 'USER_TEAM_INFO_SUCCESS';
export const ENABLE_CONGRATULATION_MODAL = 'ENABLE_CONGRATULATION_MODAL';
