import React from 'react';

const FailFast = ({ width = '8', height = '12', className = '', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M2.66397 0.760134C1.80449 0.770491 0.9503 1.0162 0.167969 1.51209V11.0001V11.2561H0.679923V5.85607C1.43172 5.50624 2.93332 5.03822 4.64792 5.78009C6.8148 6.71759 7.84792 6.13204 7.84792 6.13204V1.50804C7.84792 1.50804 6.82577 2.06786 4.64792 1.15608C4.00408 0.886529 3.33238 0.752061 2.6639 0.760106L2.66397 0.760134Z"
        fill="#F45C47"
      />
    </svg>
  );
};

export { FailFast };
