import React from 'react';

const FilterLable = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M8.25717 11.5732C8.14831 11.6821 8.01907 11.7685 7.87683 11.8275C7.73458 11.8864 7.58212 11.9167 7.42815 11.9167C7.27417 11.9167 7.12171 11.8864 6.97946 11.8275C6.83722 11.7685 6.70798 11.6821 6.59912 11.5732L1.96842 6.94254C1.74858 6.72276 1.62505 6.42465 1.625 6.11379V2.79663C1.625 2.15042 2.15042 1.625 2.79717 1.625H6.11379C6.42417 1.625 6.72263 1.7485 6.94254 1.96842L11.5732 6.59912C11.6821 6.70798 11.7685 6.83722 11.8275 6.97946C11.8864 7.12171 11.9167 7.27417 11.9167 7.42815C11.9167 7.58212 11.8864 7.73458 11.8275 7.87683C11.7685 8.01907 11.6821 8.14831 11.5732 8.25717L8.25717 11.5732Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.51953 3.52051L3.79036 3.79134"
        stroke="#4A4A4A"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { FilterLable };
