import React from 'react';

const ScrollUpArrow = ({ width, height, className, onClick, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      viewBox="0 0 62 62"
      aria-hidden="true"
    >
      <circle
        cx="31"
        cy="31"
        r="29.5"
        fill="#222222"
        stroke="#464746"
        strokeWidth="3"
      />
      <path
        d="M20.4228 34.3976C20.4221 34.1486 20.4695 33.9025 20.5617 33.6775C20.6538 33.4525 20.7883 33.2543 20.9553 33.0974L29.5909 25.046C29.8484 24.8008 30.1714 24.6667 30.5048 24.6667C30.8382 24.6667 31.1612 24.8008 31.4187 25.046L40.0543 33.3808C40.3482 33.6637 40.533 34.0703 40.5681 34.5111C40.6032 34.9519 40.4857 35.3908 40.2414 35.7312C39.9971 36.0716 39.646 36.2857 39.2655 36.3264C38.8849 36.367 38.506 36.2309 38.212 35.9479L30.4976 28.4966L22.7832 35.6979C22.5719 35.9017 22.3147 36.0312 22.0419 36.071C21.7691 36.1108 21.4921 36.0593 21.2438 35.9225C20.9955 35.7857 20.7862 35.5694 20.6407 35.2992C20.4952 35.0289 20.4196 34.7161 20.4228 34.3976Z"
        fill="#919191"
      />
    </svg>
  );
};

export { ScrollUpArrow };
