import React from 'react';

const Windows = ({ width, height, className, color = '#666666' }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M12.0267 6.69156V1.81998L6.43091 2.63618V6.69156H12.0267Z"
        fill={color}
      />
      <path
        d="M6.03703 2.69376L1.97339 3.28652V6.6916H6.03703V2.69376Z"
        fill={color}
      />
      <path
        d="M1.97339 7.24841V10.6969L6.03703 11.2965V7.24841H1.97339Z"
        fill={color}
      />
      <path
        d="M6.43091 11.3547L12.0267 12.18V7.24841H6.43091V11.3547Z"
        fill={color}
      />
    </svg>
  );
};

export { Windows };
