import React from 'react';

const UpArrow = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <g clipPath="url(#clip0_106:2213)">
        <path
          d="M6.49219 3V9.98527"
          stroke="#B7B7B7"
          strokeWidth="0.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.40272 5.91053L6.49219 3L3.58166 5.91053"
          stroke="#B7B7B7"
          strokeWidth="0.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_106:2213">
          <rect
            width="9.87867"
            height="9.87867"
            fill="white"
            transform="translate(6.98535) rotate(45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { UpArrow };
