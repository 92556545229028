import React from 'react';

const Abort = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="48" cy="48" r="45" stroke="#F45C47" strokeWidth="6" />
      <path
        d="M39.5 26L26 39.851V57.5L39.5 71H57.5L71 57.5V39.851L57.5 26H39.5Z"
        fill="#F45C47"
      />
      <path
        d="M57.5 48.5H39.5"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Abort;
