import React from 'react';

const Passed = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 44 42"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...rest}
    >
      <circle
        cx="20.0039"
        cy="20"
        r="18.75"
        stroke="#2AB14F"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0039 42C32.6019 42 42.0039 32.598 42.0039 21C42.0039 9.40202 32.6019 0 21.0039 0C9.40593 0 0.00390625 9.40202 0.00390625 21C0.00390625 32.598 9.40593 42 21.0039 42ZM39.0039 21C39.0039 30.9411 30.945 39 21.0039 39C11.0628 39 3.00391 30.9411 3.00391 21C3.00391 11.0589 11.0628 3 21.0039 3C30.945 3 39.0039 11.0589 39.0039 21ZM30.1231 16.9943C30.6722 16.374 30.6146 15.426 29.9943 14.8769C29.374 14.3278 28.426 14.3854 27.8769 15.0057L17.9589 26.2088L12.9862 21.8698C12.362 21.3251 11.4144 21.3896 10.8698 22.0138C10.3251 22.638 10.3896 23.5856 11.0138 24.1302L17.0338 29.3831C17.6962 29.9612 18.701 29.8964 19.2837 29.2381L30.1231 16.9943Z"
        fill="#2AB14F"
      />
    </svg>
  );
};

export default Passed;
