import {
  HYPERTEST_CONCURRENCY_ACTIONS_REQUEST,
  HYPERTEST_CONCURRENCY_ACTIONS_RESPONSE,
} from '../constants/concurrency';

const initialState = {
  concurrency: {
    data: null,
    loading: false,
    error: false,
  },
};

function hypertestConcurrencyReducer(state = initialState, actions) {
  const { type, payload, error } = actions;
  switch (type) {
    case HYPERTEST_CONCURRENCY_ACTIONS_REQUEST:
      return {
        ...state,
        concurrency: {
          ...state.concurrency,
          loading: true,
        },
      };
    case HYPERTEST_CONCURRENCY_ACTIONS_RESPONSE:
      return {
        ...state,
        concurrency: {
          ...state.concurrency,
          data: payload,
          loading: false,
          error: error === undefined ? false : error,
        },
      };
    default:
      return state;
  }
}

export default hypertestConcurrencyReducer;
