import React from 'react';

const HeadPhone = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <g clipPath="url(#clip0_1383_2053)">
        <path
          d="M8 1C6.67392 1 5.40215 1.52678 4.46447 2.46447C3.52678 3.40215 3 4.67392 3 6V7H4C4.26522 7 4.51957 7.10536 4.70711 7.29289C4.89464 7.48043 5 7.73478 5 8V11C5 11.2652 4.89464 11.5196 4.70711 11.7071C4.51957 11.8946 4.26522 12 4 12H3C2.73478 12 2.48043 11.8946 2.29289 11.7071C2.10536 11.5196 2 11.2652 2 11V6C2 5.21207 2.15519 4.43185 2.45672 3.7039C2.75825 2.97595 3.20021 2.31451 3.75736 1.75736C4.31451 1.20021 4.97595 0.758251 5.7039 0.456723C6.43185 0.155195 7.21207 0 8 0C8.78793 0 9.56815 0.155195 10.2961 0.456723C11.0241 0.758251 11.6855 1.20021 12.2426 1.75736C12.7998 2.31451 13.2417 2.97595 13.5433 3.7039C13.8448 4.43185 14 5.21207 14 6V12C14 12.663 13.7366 13.2989 13.2678 13.7678C12.7989 14.2366 12.163 14.5 11.5 14.5H9.366C9.27823 14.652 9.152 14.7783 8.99999 14.866C8.84797 14.9538 8.67553 15 8.5 15H7.5C7.23478 15 6.98043 14.8946 6.79289 14.7071C6.60536 14.5196 6.5 14.2652 6.5 14C6.5 13.7348 6.60536 13.4804 6.79289 13.2929C6.98043 13.1054 7.23478 13 7.5 13H8.5C8.67553 13 8.84797 13.0462 8.99999 13.134C9.152 13.2217 9.27823 13.348 9.366 13.5H11.5C11.8978 13.5 12.2794 13.342 12.5607 13.0607C12.842 12.7794 13 12.3978 13 12H12C11.7348 12 11.4804 11.8946 11.2929 11.7071C11.1054 11.5196 11 11.2652 11 11V8C11 7.73478 11.1054 7.48043 11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7H13V6C13 5.34339 12.8707 4.69321 12.6194 4.08658C12.3681 3.47995 11.9998 2.92876 11.5355 2.46447C11.0712 2.00017 10.52 1.63188 9.91342 1.3806C9.30679 1.12933 8.65661 1 8 1Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1383_2053">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { HeadPhone };
