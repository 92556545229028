import React from 'react';

const RediretCircle = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <circle cx="6" cy="6" r="6" fill="#0EBAC5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6.5C3 6.42634 3.0316 6.3557 3.08786 6.30361C3.14412 6.25152 3.22042 6.22226 3.29997 6.22226L7.97536 6.22226L6.08732 4.47473C6.031 4.42258 5.99935 4.35184 5.99935 4.27809C5.99935 4.20434 6.031 4.1336 6.08732 4.08145C6.14365 4.0293 6.22005 4 6.2997 4C6.37936 4 6.45576 4.0293 6.51209 4.08145L8.91187 6.30336C8.93981 6.32916 8.96197 6.35981 8.97709 6.39355C8.99222 6.42729 9 6.46347 9 6.5C9 6.53653 8.99222 6.57271 8.97709 6.60645C8.96197 6.64019 8.93981 6.67084 8.91187 6.69664L6.51209 8.91855C6.45576 8.9707 6.37936 9 6.29971 9C6.22005 9 6.14365 8.9707 6.08732 8.91855C6.031 8.8664 5.99935 8.79566 5.99935 8.72191C5.99935 8.64816 6.031 8.57742 6.08732 8.52527L7.97536 6.77774L3.29997 6.77774C3.22042 6.77774 3.14412 6.74848 3.08786 6.69639C3.0316 6.6443 3 6.57366 3 6.5Z"
        fill="white"
      />
    </svg>
  );
};

export { RediretCircle };
