const ExitFullScreenArrow = ({
  width = 16,
  height = 16,
  className,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <rect
        x="1"
        y="1"
        width="24"
        height="24"
        rx="4"
        stroke="#999999"
        strokeWidth="0.8"
      />
      <path
        d="M8.42977 13.8544C8.16153 13.8544 7.94473 14.0712 7.94489 14.3393C7.94489 14.6076 8.16168 14.8243 8.42977 14.8242L10.0026 14.8243L6.71493 18.112C6.5257 18.3013 6.5257 18.6087 6.71493 18.7979C6.90417 18.9872 7.21162 18.9872 7.40085 18.7979L10.6885 15.5103L10.6885 17.0829C10.6885 17.3512 10.9053 17.568 11.1734 17.5678C11.3071 17.568 11.4286 17.5138 11.5164 17.4261C11.6041 17.3383 11.6583 17.2168 11.6585 17.0831L11.6585 14.3394C11.6585 14.0712 11.4417 13.8544 11.1736 13.8545L8.42977 13.8544Z"
        fill="#999999"
      />
      <path
        d="M18.2289 7.71492L14.9412 11.0026L14.9412 9.42992C14.9412 9.16167 14.7244 8.94487 14.4563 8.94503C14.1881 8.94503 13.9713 9.16183 13.9715 9.42992L13.9715 12.1736C13.9715 12.4418 14.1883 12.6586 14.4563 12.6585L17.2 12.6585C17.3337 12.6583 17.4553 12.6041 17.543 12.5164C17.6307 12.4287 17.6849 12.3071 17.6851 12.1734C17.6851 11.9052 17.4683 11.6884 17.2002 11.6886L15.6272 11.6886L18.9149 8.40088C19.1046 8.21118 19.1041 7.9042 18.9149 7.71496C18.7256 7.52572 18.4186 7.52526 18.2289 7.71496L18.2289 7.71492Z"
        fill="#999999"
      />
    </svg>
  );
};

export { ExitFullScreenArrow };
