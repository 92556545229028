import React, { memo } from 'react';
import AbortButton from './AbortButton';
import { UpArrow } from './UpArrow';
import { Matrix } from './Matrix';
import { AutoSplit } from './AutoSplit';
import { SearchIcon } from './SearchIcon.js';
import { Passed } from './Status/Passed.js';
import { CompletedIcon } from './Status/CompletedIcon.js';
import { FailFast } from './Status/FailFast.js';
import { Failed } from './Status/Failed.js';
import { Running } from './Status/Running.js';
import { Pending } from './Status/Pending.js';
import Initiated from './Status/Initated';
import { Checkbox } from './Checkbox';
import { CheckboxChecked } from './CheckboxChecked';
import ThreeDots from './ThreeDots';
import AbortedGray from './Status/AbortedGray';
import LambdaErrorGray from './Status/LambdaErrorGray';
import TimeoutGray from './Status/TimeoutGray';
import FailedGray from './Status/FailedGray';
import CancelledGray from './Status/CancelledGray';
import CompletedGray from './Status/CompletedGray';
import QueuedGray from './Status/QueuedGray';
import InitiatedGray from './Status/InititatedGray';
import RunningGray from './Status/RunningGray';
import SmartUI from './SmartUI';
import LeftChevron from './LeftChevron';
import VisibilityEye from './VisibilityEye';
import Tosca from './Tosca';
import Katalon from './Katalon';
import Refresh from './Refresh';
import ToscaServer from './ToscaServer';

import AbortInfo from './AbortInfo';
import AbortArrow from './AbortArrow';
import { Cancelled } from './Status/Cancelled';
import PartiallyCompleted from './Status/PartiallyCompleted';
// import { Aborted } from './Status/Aborted';
// import { Queued } from './Status/Queued';
import { Windows } from './Plateform/Windows';
import { MacOS } from './Plateform/MacOS';
import { Linux } from './Plateform/Linux';
import { DownloadFileIcon } from './DownloadFileIcon';
import { ExpandArrow } from './ExpandArrow';
import { ExpandExternal } from './ExpandExternal';
import { LogAvailable } from './Status/LogAvailable';
import { FilterIcon } from './FilterIcon';
import { CloseIcon } from './CloseIcon';
import { CloseWhiteIcon } from './CloseWhiteIcon';
import { DeleteIcon } from './Delete';
import { Artifect } from './Artifect';
import { Queued } from './Status/Queued';
import { Abort } from './Status/Abort';
import { Timeout } from './Status/Timeout';
import { LambdaError } from './Status/LambdaError';
import StageAbort from './Status/Stage/Abort';
import StageTimeout from './Status/Stage/Timeout';
import StageLambdaError from './Status/Stage/LambdaError';
import StageCreated from './Status/Stage/Created';
import StageRunning from './Status/Stage/Running';
import StageCancelled from './Status/Stage/Cancelled';
import StageQueued from './Status/Stage/Queued';
import StageCompleted from './Status/Stage/Completed';
import { CompletedFilled } from './Status/CompletedFilled';
import { Clock } from './Status/Clock';
import { RetryClock } from './Status/RetryClock';
import StagePassed from './Status/Stage/Passed';
import StageFailed from './Status/Stage/Failed';
import { StageSkipped } from './Status/Stage/Skipped';
import { Success } from './Toast/Success';
import { Warning } from './Toast/Warning';
import { Error } from './Toast/Error';
import { Robot } from './Robot';
import { CloseSearchIcon } from './CloseSearchIcon';
import { Duration } from './DurationIcon';
import { DocumentationIcon } from './DocumentationIcon';
import { Rescheduled } from './Rescheduled';
import { HandIcon } from './HandIcon';
import { RobotOld } from './RobotOld';
import { OnboardingLaunchImage } from './OnboardingLaunchImage';
import { Retry } from './Retry';
import { CompletedGreen } from './Status/CompletedGreen';
import { Key } from './Key';
import { Edit } from './Edit';
import { ManSecret } from './ManSecret';
import { Info } from './Info';
import { InfoGray } from './InfoGray';
import { InfoBlue } from './InfoBlue';
import { Hybrid } from './Hybrid';
import { HeadPhone } from './Onboarding/HeadPhone';
import { BlueCheck } from './Onboarding/BlueCheck';
import { DownloadIcon } from './DownloadIcon';
import { Copy } from './Copy';
import { Reset } from './Onboarding/Reset';
import { OnboardWindow } from './Onboarding/platform/Window';
import { OnboardWindowDark } from './Onboarding/platform/WindowDark';
import { OnboardLinuxDark } from './Onboarding/platform/LinuxDark';
import { OnboardLinux } from './Onboarding/platform//Linux';
import { OnboardMacos } from './Onboarding/platform/Macos';
import { Github } from './Onboarding/Github';
import { Help } from './Help';
import { QuickStart } from './Onboarding/QuickStart';
import { SupportIconLarge } from './Onboarding/SupportIconLarge';
import { DownloadCodeIcon } from './DownloadCodeIcon';
import { RediretCircle } from './Onboarding/RedirectCircle';
import { RedirectHorizontalArrow } from './Onboarding/RedirectHorizontalArrow';
import { BackIcon } from './Onboarding/BackIcon';
import { FeatureCheckIcon } from './FeatureCheckIcon';
import RightArrow from './Trial/RightArrow';
import SandTimer from './SandTimer';
import AlertIcon from './AlertIcon';
import { Thunder } from './Thunder';
import { Accesskey } from './Accesskey';
import { ScrollUpArrow } from './ScrollUpArrow';
import { ScrollDownArrow } from './ScrollDownArrow';
import { ThunderLTColor } from './ThunderLTColor';
import { Star } from './Star';
import { UserDemark } from './UserDemark';
import { Skipped } from './Status/Skipped';
import BuildErrors from './VRScreenshots/BuildErrors';
import ChangesFound from './VRScreenshots/ChangesFound';
import SystemApproved from './VRScreenshots/SystemApproved';
import UserApproved from './VRScreenshots/UserApproved';
import UnderReview from './VRScreenshots/UnderReview';
import Compare from './VRScreenshots/Compare';
import Bug from './VRScreenshots/Bug';
import Approve from './VRScreenshots/Approve';
import { FilterCalender } from './FilterIcons/Calender';
import { FilterStatus } from './FilterIcons/Status';
import { FilterUsers } from './FilterIcons/Users';
import { FilterLable } from './FilterIcons/Lable';
import { FilterType } from './FilterIcons/Type';
import { FilterRightArrow } from './FilterIcons/RightArrow';
import { SelectedCircle } from './FilterIcons/SelectedCircle';
import { Tunnel } from './Tunnel';
import { FullScreenArrow } from './FullScreenArrow';
import { ExitFullScreenArrow } from './ExitFullScreenArrow';
import { Docs } from './support/Docs';
import { GithubSupport } from './support/Github';
import { Quickstart } from './support/Quickstart';
import { GitpodSupport } from './support/Gitpod';
import { ChatSupport } from './support/Chat';
import Video from './Video';
import PlayLarge from './PlayLarge';
import LinuxLarge from './LinuxLarge';
import WindowsLarge from './WindowsLarge';
import AndroidLarge from './AndroidLarge';
import AppleLarge from './AppleLarge';
import TaskSearch from './TaskSearch';
import { ChevronUp } from './Onboarding/CheveronUp';
import { ChevronDown } from './Onboarding/ChevronDown';
import { CompletedStep } from './Onboarding/CompletedStep';
import { CheckBoldIcon } from './Onboarding/CheckBoldIcon';
import { RocketIcon } from './Onboarding/RocketIcon';
import GuidedTour from './Onboarding/GuidedTour';
import ConceptsIcon from './Onboarding/ConceptsIcon';
import FeaturesIcon from './Onboarding/FeaturesIcon';
import HyperExecuteCliIcon from './Onboarding/HyperExecuteCliIcon';
import HyperExecuteYamlIcon from './Onboarding/HyperExecuteYamlIcon';
import IntegrationIcon from './Onboarding/IntegrationIcon';
import HyperExecuteYamlCli from './HyperexecuteCli';
import CrossBoldIcon from './CrossBoldIcon';
import HistoryIcon from './HistoryIcon';
import RedirectIcon from './RedirectIcon';
import ChromeIcon from './browser/ChromeIcon';
import DesktopIcon from './DesktopIcon';
import SafariIcon from './browser/SafariIcon';
import EdgeIcon from './browser/EdgeIcon';
import ExplorerIcon from './browser/ExplorerIcon';
import OperaIcon from './browser/OperaIcon';
import FirefoxIcon from './browser/FirefoxIcon';
import TestStatus from './VRScreenshots/TestStatus';
import GenerateYamlIcon from './support/GenerateYamlIcon';
import Plus from './Plus';
import Run from './Run';
import ViewJobs from './ViewJobs';
import ThreeDots2 from './ThreeDots2';
import ThunderLogo from './Trial/ThunderLogo';
import FirstTestIcon from './Trial/FirstTestIcon';
import ConfigureHYEIcon from './Trial/ConfigureHYEIcon';
import SupportedIntegrations from './Trial/SupportedIntegrations';
import LinkArrowIcon from './Trial/LinkArrowIcon';
import Minus from './Minus';
import ViewIcon from './FilterIcons/View';
import CheckedCircle from './CheckedCircle';
import JavaIcon from './Languages/grey/JavaIcon';
import CSharpIcon from './Languages/grey/CSharpIcon';
import JavascriptIcon from './Languages/grey/JavascriptIcon';
import PythonIcon from './Languages/grey/PythonIcon';
import RubyIcon from './Languages/grey/RubyIcon';
import PointerArrowUp from './PointerArrowUp';
import PointerArrowDown from './PointerArrowDown';

import ThumbsUp from './Feedback/ThumbsUp';
import ThumbsDown from './Feedback/ThumbsDown';

import CheckMark from './CheckMark';

import InsightAborted from './insight/overview/Aborted';
import InsightCancelled from './insight/overview/Cancelled';
import InsightCreated from './insight/overview/Created';
import InsightFailed from './insight/overview/Failed';
import InsightInitiated from './insight/overview/Initiated';
import InsightLambdaError from './insight/overview/LambdaError';
import InsightRunning from './insight/overview/Running';
import InsightSkipped from './insight/overview/Skipped';
import InsightTimeout from './insight/overview/Timeout';

import ErrorSymbol from './Error';
import LambdaTest from './LambdaTest';
import UpArrow2 from './UpArrow2';
import GeneratingRCA from './GenerativeRCA';
import RDAndroid from './RealDevice/Android';
import RDAppium from './RealDevice/Appium';
import RDEspresso from './RealDevice/Espresso';
import RDXcui from './RealDevice/Xcui';
import TimelineIcon from './Timeline';
import TaskIcon from './Task';
import QuestionMarkInCircle from './QuestionMarkInCircle';
import EmptyStateIcon from './EmptyStateIcon';
import ArtifactIcon from './ArtifactIcon';
import ReportIcon from './ReportIcon';
import InfoRevert from './InfoRevert';
import BackgroundServiceIcon from './BackgroundService';
import RubyIconColored from './Languages/colored/RubyIconColored';
import PythonIconColored from './Languages/colored/PythonIconColored';
import JavascriptIconColored from './Languages/colored/JavascriptIconColored';
import JavaIconColored from './Languages/colored/JavaIconColored';
import CSharpIconColored from './Languages/colored/CSharpIconColored';
import FolderIcon from './Folder';
import File from './File';
import DropdownIcon from './DropdownIcon';
import AssociatedError from './AError';
import AssociatedIgnored from './AIgnored';
import Secret from './Secret';
import Muted from './Muted';
import Blocked from './Status/Blocked';
import VideoGrey from './VideoGrey';
import Archived from './Archived';
import PJBack from './Status/PJBack';
import PJCompleted from './Status/PJCompleted';
import PJDate from './Status/PJDate';
import PJJMeter from './Status/PJJMeter';
import PJUser from './Status/PJUser';
import PJAborted from './Status/PJAborted';
import PJBLocked from './Status/PJBlocked';
import PJCancelled from './Status/PJCancelled';
import PJError from './Status/PJError';
import PJFailed from './Status/PJFailed';
import PJTIimeout from './Status/PJTimeout';
import PJQueued from './Status/PJQueued';
import PJEmpty from './Status/PJEmpty';
import PJDownArrow from './Status/PJDownArrow';
import PJClose from './Status/PJClose';
import PJGoogle from './Status/PJGoogle';
import PJAzure from './Status/PJAzure';
import UnknownGray from './Status/UnknownGray';
import Upload from './Upload';
import CrossBlack from './CrossBlack';
import CheckBoldGreen from './CheckBoldGreen';
import PJPlay from './Status/PJPlay';
import CrossMedium from './crossMedium';
import WarningYellowOutlined from './WarningYellowOutline';
import SearchCross from './SearchCross';
import ChevronRight from './ChevronRight';
import Scenario from './Scenarios';
import Test from './Test.js';
import Stopped from './Status/Stopped';
import Ignored from './Status/Stage/Ignored';
import RCAWCAG from './RCAWCAG';
import Analysis from './Analysis';
import Switch from './Switch';
import JobError from './ErrorStatus';

import StageTimeoutV2 from './Status/Stage/StageTimeoutV2';
import RedirectGrey from './RedirectGrey';

import './styles.css';
import ViewSVG from './ViewSVG';

import QuestionMark from './QuestionMark';
import CustomProject from './CustomProject';
import CoOwner from './CoOwner';
import Branch from './Branch';
import Play from './Play';
import Question from './Question';
import Calendar2 from './Calendar2';
import Schedule from './Schedule';
import FileLocation from './FileLocation';

import Insight from './Insight';
import AnalyticsPoster from './analyticsPoster';

export const Icon = memo((props) => {
  switch (props.name.toLowerCase()) {
    case 'file-location':
      return <FileLocation {...props} />;
    case 'schedule':
      return <Schedule {...props} />;
    case 'calendar2':
      return <Calendar2 {...props} />;
    case 'question':
      return <Question {...props} />;
    case 'branch':
      return <Branch {...props} />;
    case 'play':
      return <Play {...props} />;
    case 'question-mark':
      return <QuestionMark {...props} />;
    case 'custom-project':
      return <CustomProject {...props} />;
    case 'co-owner':
      return <CoOwner {...props} />;
    case 'lambda-test-wcag':
      return <RCAWCAG {...props} />;
    case 'pj-play':
      return <PJPlay className="responsive-icon-size" {...props} />;
    case 'pj-google':
      return <PJGoogle className="responsive-icon-size" {...props} />;
    case 'pj-azure':
      return <PJAzure className="responsive-icon-size" {...props} />;
    case 'pj-close':
      return <PJClose className="responsive-icon-size" {...props} />;
    case 'pj-down':
      return <PJDownArrow className="responsive-icon-size" {...props} />;
    case 'pj-empty':
      return <PJEmpty className="responsive-icon-size" {...props} />;
    case 'pj-timeout':
      return <PJTIimeout className="responsive-icon-size" {...props} />;
    case 'pj-queued':
      return <PJQueued className="responsive-icon-size" {...props} />;
    case 'pj-failed':
      return <PJFailed className="responsive-icon-size" {...props} />;
    case 'pj-error':
      return <PJError className="responsive-icon-size" {...props} />;
    case 'pj-cancelled':
      return <PJCancelled className="responsive-icon-size" {...props} />;
    case 'pj-blocked':
      return <PJBLocked className="responsive-icon-size" {...props} />;
    case 'pj-aborted':
      return <PJAborted className="responsive-icon-size" {...props} />;
    case 'pj-user':
      return <PJUser className="responsive-icon-size" {...props} />;
    case 'pj-back':
      return <PJBack className="responsive-icon-size" {...props} />;
    case 'pj-completed':
      return <PJCompleted className="responsive-icon-size" {...props} />;
    case 'pj-date':
      return <PJDate className="responsive-icon-size" {...props} />;
    case 'pj-jmeter':
      return <PJJMeter className="responsive-icon-size" {...props} />;
    case 'partially-completed':
      return <PartiallyCompleted className="responsive-icon-size" {...props} />;
    case 'muted':
      return <Muted className="responsive-icon-size" {...props} />;
    case 'secret':
      return <Secret className="responsive-icon-size" {...props} />;
    case 'a-error':
      return <AssociatedError className="responsive-icon-size" {...props} />;
    case 'a-ignored':
      return <AssociatedIgnored className="responsive-icon-size" {...props} />;
    case 'tosca-server':
      return <ToscaServer className="responsive-icon-size" {...props} />;
    case 'rd/xcui':
      return <RDXcui className="responsive-icon-size" {...props} />;
    case 'rd/android':
      return <RDAndroid className="responsive-icon-size" {...props} />;
    case 'rd/appium':
      return <RDAppium className="responsive-icon-size" {...props} />;
    case 'rd/espresso':
      return <RDEspresso className="responsive-icon-size" {...props} />;
    case 'up-arrow-2':
      return <UpArrow2 className="responsive-icon-size" {...props} />;
    case 'spinner':
      return <GeneratingRCA className="responsive-icon-size" {...props} />;
    case 'lambda-test':
      return <LambdaTest className="responsive-icon-size" {...props} />;
    case 'error-symbol':
      return <ErrorSymbol className="responsive-icon-size" {...props} />;
    case 'insight-aborted':
      return <InsightAborted className="responsive-icon-size" {...props} />;
    case 'insight-cancelled':
      return <InsightCancelled className="responsive-icon-size" {...props} />;
    case 'insight-created':
      return <InsightCreated className="responsive-icon-size" {...props} />;
    case 'insight-failed':
      return <InsightFailed className="responsive-icon-size" {...props} />;
    case 'insight-initiated':
      return <InsightInitiated className="responsive-icon-size" {...props} />;
    case 'insight-lambda-error':
      return <InsightLambdaError className="responsive-icon-size" {...props} />;
    case 'insight-running':
      return <InsightRunning className="responsive-icon-size" {...props} />;
    case 'insight-skipped':
      return <InsightSkipped className="responsive-icon-size" {...props} />;
    case 'insight-timeout':
      return <InsightTimeout className="responsive-icon-size" {...props} />;
    case 'plus':
      return <Plus className="responsive-icon-size" {...props} />;
    case 'check-mark':
      return <CheckMark className="responsive-icon-size" {...props} />;
    case 'refresh':
      return <Refresh className="responsive-icon-size" {...props} />;
    case 'tosca':
      return <Tosca className="responsive-icon-size" {...props} />;
    case 'katalon':
      return <Katalon className="responsive-icon-size" {...props} />;
    case 'minus':
      return <Minus className="responsive-icon-size" {...props} />;
    case 'run':
      return <Run className="responsive-icon-size" {...props} />;
    case 'view-jobs':
      return <ViewJobs className="responsive-icon-size" {...props} />;
    case 'three-dots2':
      return <ThreeDots2 className="responsive-icon-size" {...props} />;
    case 'play-large':
      return <PlayLarge className="responsive-icon-size" {...props} />;
    case 'blocked':
      return <Blocked className="responsive-icon-size" {...props} />;
    case 'visibility-eye':
      return <VisibilityEye className="responsive-icon-size" {...props} />;
    case 'smart-ui':
      return <SmartUI className="responsive-icon-size" {...props} />;
    case 'left-chevron':
      return <LeftChevron className="responsive-icon-size" {...props} />;
    case 'task-search':
      return <TaskSearch className="responsive-icon-size" {...props} />;
    case 'linux-large':
      return <LinuxLarge className="responsive-icon-size" {...props} />;
    case 'windows-large':
      return <WindowsLarge className="responsive-icon-size" {...props} />;
    case 'android-large':
      return <AndroidLarge className="responsive-icon-size" {...props} />;
    case 'apple-large':
      return <AppleLarge className="responsive-icon-size" {...props} />;
    case 'three-dots':
      return <ThreeDots className="responsive-icon-size" {...props} />;
    case 'video':
      return <Video className="responsive-icon-size" {...props} />;
    case 'video-grey':
      return <VideoGrey className="responsive-icon-size" {...props} />;
    case 'abort-gray':
      return <AbortedGray className="responsive-icon-size" {...props} />;
    case 'running-gray':
      return <RunningGray className="responsive-icon-size" {...props} />;
    case 'lambda-error-gray':
      return <LambdaErrorGray className="responsive-icon-size" {...props} />;
    case 'timeout-gray':
      return <TimeoutGray className="responsive-icon-size" {...props} />;
    case 'completed-gray':
    case 'skipped-gray':
      return <CompletedGray className="responsive-icon-size" {...props} />;
    case 'initiated-gray':
      return <InitiatedGray className="responsive-icon-size" {...props} />;
    case 'queued-gray':
      return <QueuedGray className="responsive-icon-size" {...props} />;
    case 'failed-gray':
      return <FailedGray className="responsive-icon-size" {...props} />;
    case 'cancelled-gray':
      return <CancelledGray className="responsive-icon-size" {...props} />;
    case 'up-arrow':
      return <UpArrow className="responsive-icon-size" {...props} />;
    case 'passed':
    case 'passed-status':
      return <Passed className="responsive-icon-size" {...props} />;
    case 'completed-status':
      return <CompletedIcon className="responsive-icon-size" {...props} />;
    case 'fail-fast':
      return <FailFast className="responsive-icon-size" {...props} />;
    case 'failed':
    case 'failed-status':
      return <Failed className="responsive-icon-size" {...props} />;
    case 'running-status':
      return <Running className="responsive-icon-size" {...props} />;
    case 'completed-green':
      return <CompletedGreen className="responsive-icon-size" {...props} />;
    case 'unknown':
      return <UnknownGray className="responsive-icon-size" {...props} />;
    case 'log-available':
      return <LogAvailable className="responsive-icon-size" {...props} />;
    case 'completed-filled':
      return <CompletedFilled className="responsive-icon-size" {...props} />;
    case 'clock':
      return <Clock className="responsive-icon-size" {...props} />;
    case 'checkbox':
      return <Checkbox className="responsive-icon-size" {...props} />;
    case 'checkbox-checked':
      return <CheckboxChecked className="responsive-icon-size" {...props} />;
    case 'retry-clock':
      return <RetryClock className="responsive-icon-size" {...props} />;
    case 'timeout':
      return <Timeout className="responsive-icon-size" {...props} />;
    case 'skipped':
      return <Skipped className="responsive-icon-size" {...props} />;
    case 'initiated-status':
      return <Initiated className="responsive-icon-size" {...props} />;
    case 'lambda_error':
    case 'lambda error':
      return <LambdaError className="responsive-icon-size" {...props} />;
    case 'cancelled-status':
      return <Cancelled className="responsive-icon-size" {...props} />;
    case 'pending-status':
      return <Pending className="responsive-icon-size" {...props} />;
    case 'aborted-status':
      return <Abort className="responsive-icon-size" {...props} />;
    case 'stopped-status':
      return <Stopped className="responsive-icon-size" {...props} />;
    case 'queued-status':
      return <Queued className="responsive-icon-size" {...props} />;
    case 'search':
      return <SearchIcon className="responsive-icon-size" {...props} />;
    case 'search-cross':
      return <SearchCross className="responsive-icon-size" {...props} />;
    case 'matrix':
      return <Matrix className="responsive-icon-size" {...props} />;
    case 'autosplit':
      return <AutoSplit className="responsive-icon-size" {...props} />;
    case 'hybrid':
      return <Hybrid className="responsive-icon-size" {...props} />;
    case 'win':
      return <Windows className="responsive-icon-size" {...props} />;
    case 'mac':
      return <MacOS className="responsive-icon-size" {...props} />;
    case 'linux':
      return <Linux className="responsive-icon-size" {...props} />;
    case 'download-file-icon':
      return <DownloadFileIcon className="responsive-icon-size" {...props} />;
    case 'download-code-icon':
      return <DownloadCodeIcon className="responsive-icon-size" {...props} />;
    case 'expand-arrow':
      return <ExpandArrow className="responsive-icon-size" {...props} />;
    case 'expand':
      return <ExpandExternal className="responsive-icon-size" {...props} />;
    case 'filter':
      return <FilterIcon className="responsive-icon-size" {...props} />;
    case 'close':
      return <CloseIcon className="responsive-icon-size" {...props} />;
    case 'close-white':
      return <CloseWhiteIcon className="responsive-icon-size" {...props} />;
    case 'delete':
      return <DeleteIcon className="responsive-icon-size" {...props} />;
    case 'artifect':
      return <Artifect className="responsive-icon-size" {...props} />;
    case 'stage-passed':
      return <StagePassed className="responsive-icon-size" {...props} />;
    case 'stage-completed':
      return <StageCompleted className="responsive-icon-size" {...props} />;
    case 'stage-failed':
      return <StageFailed className="responsive-icon-size" {...props} />;
    case 'stage-running':
      return <StageRunning className="responsive-icon-size" {...props} />;
    case 'stage-cancelled':
      return <StageCancelled className="responsive-icon-size" {...props} />;
    case 'stage-pending':
      return <StageQueued className="responsive-icon-size" {...props} />;
    case 'stage-aborted':
      return <StageAbort className="responsive-icon-size" {...props} />;
    case 'stage-created':
      return <StageCreated className="responsive-icon-size" {...props} />;
    case 'stage-timeout':
      return <StageTimeout className="responsive-icon-size" {...props} />;
    case 'stage-skipped':
      return <StageSkipped className="responsive-icon-size" {...props} />;
    case 'stage-lambda_error':
      return <StageLambdaError className="responsive-icon-size" {...props} />;
    case 'ignored-status':
      return <Ignored className="responsive-icon-size" {...props} />;
    case 'check':
      return <Success className="responsive-icon-size" {...props} />;
    case 'warning':
      return <Warning className="responsive-icon-size" {...props} />;
    case 'warning-yellow-outlined':
      return (
        <WarningYellowOutlined className="responsive-icon-size" {...props} />
      );
    case 'error':
      return <Error className="responsive-icon-size" {...props} />;
    case 'robot':
      return <Robot {...props} />;
    case 'robot-old':
      return <RobotOld {...props} />;
    case 'clear-search':
      return <CloseSearchIcon className="responsive-icon-size" {...props} />;
    case 'duration':
      return <Duration className="responsive-icon-size" {...props} />;
    case 'documentation':
      return <DocumentationIcon className="responsive-icon-size" {...props} />;
    case 'hand':
      return <HandIcon className="responsive-icon-size" {...props} />;
    case 'onboarding-launch-image':
      return (
        <OnboardingLaunchImage className="responsive-icon-size" {...props} />
      );
    case 'abort-button':
      return <AbortButton className="responsive-icon-size" {...props} />;
    case 'abort-arrow':
      return <AbortArrow className="responsive-icon-size" {...props} />;
    case 'abort-info':
      return <AbortInfo className="responsive-icon-size" {...props} />;
    case 'retry':
      return <Retry className="responsive-icon-size" {...props} />;
    case 'key':
      return <Key className="responsive-icon-size" {...props} />;
    case 'edit':
      return <Edit className="responsive-icon-size" {...props} />;
    case 'man-secret':
      return <ManSecret className="responsive-icon-size" {...props} />;
    case 'info':
      return <Info className="responsive-icon-size" {...props} />;
    case 're-scheduled':
      return <Rescheduled className="responsive-icon-size" {...props} />;
    case 'info-gray':
      return <InfoGray className="responsive-icon-size" {...props} />;
    case 'info-blue':
      return <InfoBlue className="responsive-icon-size" {...props} />;
    case 'headphone':
      return <HeadPhone className="responsive-icon-size" {...props} />;
    case 'blue-check':
      return <BlueCheck className="responsive-icon-size" {...props} />;
    case 'download-icon':
      return <DownloadIcon className="responsive-icon-size" {...props} />;
    case 'copy-icon':
      return <Copy className="responsive-icon-size" {...props} />;
    case 'reset-icon':
      return <Reset className="responsive-icon-size" {...props} />;
    case 'onboard/windows':
      return <OnboardWindow className="responsive-icon-size" {...props} />;
    case 'onboard/windows-dark':
      return <OnboardWindowDark className="responsive-icon-size" {...props} />;
    case 'onboard/linux-dark':
      return <OnboardLinuxDark className="responsive-icon-size" {...props} />;
    case 'onboard/macos':
      return <OnboardMacos className="responsive-icon-size" {...props} />;
    case 'onboard/linux':
      return <OnboardLinux className="responsive-icon-size" {...props} />;
    case 'github':
      return <Github className="responsive-icon-size" {...props} />;
    case 'help':
      return <Help className="responsive-icon-size" {...props} />;
    case 'quick-start':
      return <QuickStart className="responsive-icon-size" {...props} />;
    case 'support-icon':
      return <SupportIconLarge className="responsive-icon-size" {...props} />;
    case 'redirect-circle':
      return <RediretCircle className="responsive-icon-size" {...props} />;
    case 'redirect-horizontal-arrow':
      return (
        <RedirectHorizontalArrow className="responsive-icon-size" {...props} />
      );
    case 'back-icon':
      return <BackIcon className="responsive-icon-size" {...props} />;
    case 'feature-check-icon':
      return <FeatureCheckIcon className="responsive-icon-size" {...props} />;
    case 'right-arrow':
      return <RightArrow className="responsive-icon-size" {...props} />;
    case 'sand-timer':
      return <SandTimer className="responsive-icon-size" {...props} />;
    case 'alert':
      return <AlertIcon className="responsive-icon-size" {...props} />;
    case 'thunder':
      return <Thunder className="responsive-icon-size" {...props} />;
    case 'accesskey':
      return <Accesskey className="responsive-icon-size" {...props} />;
    case 'scroll-up-arrow':
      return <ScrollUpArrow className="responsive-icon-size" {...props} />;
    case 'scroll-down-arrow':
      return <ScrollDownArrow className="responsive-icon-size" {...props} />;
    case 'thunder-lt-color':
      return <ThunderLTColor className="responsive-icon-size" {...props} />;
    case 'star':
      return <Star className="responsive-icon-size" {...props} />;
    case 'user-demarcation':
      return <UserDemark className="responsive-icon-size" {...props} />;
    case 'vr-build-errors':
      return <BuildErrors className="responsive-icon-size" {...props} />;
    case 'vr-changes-found':
      return <ChangesFound className="responsive-icon-size" {...props} />;
    case 'vr-system-approved':
      return <SystemApproved className="responsive-icon-size" {...props} />;
    case 'vr-user-approved':
      return <UserApproved className="responsive-icon-size" {...props} />;
    case 'vr-test-status':
      return <TestStatus className="responsive-icon-size" {...props} />;
    case 'vr-under-review':
      return <UnderReview className="responsive-icon-size" {...props} />;
    case 'vr-compare':
      return <Compare className="responsive-icon-size" {...props} />;
    case 'vr-bug':
      return <Bug className="responsive-icon-size" {...props} />;
    case 'vr-approve':
      return <Approve className="responsive-icon-size" {...props} />;
    case 'filter/calender':
      return <FilterCalender className="responsive-icon-size" {...props} />;
    case 'filter/user':
      return <FilterUsers className="responsive-icon-size" {...props} />;
    case 'filter/lable':
      return <FilterLable className="responsive-icon-size" {...props} />;
    case 'filter/type':
      return <FilterType className="responsive-icon-size" {...props} />;
    case 'filter/status':
      return <FilterStatus className="responsive-icon-size" {...props} />;
    case 'filter/right-arrow':
      return <FilterRightArrow className="responsive-icon-size" {...props} />;
    case 'filter/selected-circle':
      return <SelectedCircle className="responsive-icon-size" {...props} />;
    case 'tunnel':
      return <Tunnel className="responsive-icon-size" {...props} />;
    case 'full-screen':
      return <FullScreenArrow className="responsive-icon-size" {...props} />;
    case 'exit-full-screen':
      return (
        <ExitFullScreenArrow className="responsive-icon-size" {...props} />
      );
    case 'support/docs':
      return <Docs className="responsive-icon-size" {...props} />;
    case 'support/github':
      return <GithubSupport className="responsive-icon-size" {...props} />;
    case 'support/gitpod':
      return <GitpodSupport className="responsive-icon-size" {...props} />;
    case 'support/quickstart':
      return <Quickstart className="responsive-icon-size" {...props} />;
    case 'support/chat':
      return <ChatSupport className="responsive-icon-size" {...props} />;
    case 'archived':
      return <Archived className="responsive-icon-size" {...props} />;
    case 'chevron/up':
      return <ChevronUp className="responsive-icon-size" {...props} />;
    case 'chevron/down':
      return <ChevronDown className="responsive-icon-size" {...props} />;
    case 'completed-step':
      return <CompletedStep className="responsive-icon-size" {...props} />;
    case 'check-bold':
      return <CheckBoldIcon className="responsive-icon-size" {...props} />;
    case 'rocket-icon':
      return <RocketIcon className="responsive-icon-size" {...props} />;
    case 'onboarding/guided-tour':
      return <GuidedTour className="responsive-icon-size" {...props} />;
    case 'onboarding/concepts':
      return <ConceptsIcon className="responsive-icon-size" {...props} />;
    case 'onboarding/feature':
      return <FeaturesIcon className="responsive-icon-size" {...props} />;
    case 'onboarding/cli':
      return (
        <HyperExecuteCliIcon className="responsive-icon-size" {...props} />
      );
    case 'onboarding/yaml':
      return (
        <HyperExecuteYamlIcon className="responsive-icon-size" {...props} />
      );
    case 'onboarding/integration':
      return <IntegrationIcon className="responsive-icon-size" {...props} />;
    case 'support/cli':
      return (
        <HyperExecuteYamlCli className="responsive-icon-size" {...props} />
      );
    case 'support/yaml':
      return <GenerateYamlIcon className="responsive-icon-size" {...props} />;
    case 'onboard/crossbold':
      return <CrossBoldIcon className="responsive-icon-size" {...props} />;
    case 'cross-medium':
      return <CrossMedium className="responsive-icon-size" {...props} />;
    case 'history-icon':
      return <HistoryIcon className="responsive-icon-size" {...props} />;
    case 'redirect-icon':
      return <RedirectIcon className="responsive-icon-size" {...props} />;
    case 'chrome':
      return <ChromeIcon className="responsive-icon-size" {...props} />;
    case 'safari':
      return <SafariIcon className="responsive-icon-size" {...props} />;
    case 'edge':
      return <EdgeIcon className="responsive-icon-size" {...props} />;
    case 'microsoftedge':
      return <EdgeIcon className="responsive-icon-size" {...props} />;
    case 'internet explorer':
      return <ExplorerIcon className="responsive-icon-size" {...props} />;
    case 'opera':
      return <OperaIcon className="responsive-icon-size" {...props} />;
    case 'firefox':
      return <FirefoxIcon className="responsive-icon-size" {...props} />;
    case 'desktop-icon':
      return <DesktopIcon className="responsive-icon-size" {...props} />;
    case 'trial-thunder-logo':
      return <ThunderLogo className="responsive-icon-size" {...props} />;
    case 'trial-first-test':
      return <FirstTestIcon className="responsive-icon-size" {...props} />;
    case 'trial-configuration':
      return <ConfigureHYEIcon className="responsive-icon-size" {...props} />;
    case 'trial-integration':
      return (
        <SupportedIntegrations className="responsive-icon-size" {...props} />
      );
    case 'trial-link-arrow':
      return <LinkArrowIcon className="responsive-icon-size" {...props} />;
    case 'view-icon':
      return <ViewIcon className="responsive-icon-size" {...props} />;
    case 'feedback/thumbs-up':
      return <ThumbsUp className="responsive-icon-size" {...props} />;
    case 'feedback/thumbs-down':
      return <ThumbsDown className="responsive-icon-size" {...props} />;
    case 'checked-circle':
      return <CheckedCircle className="responsive-icon-size" {...props} />;
    case 'java-icon':
      return <JavaIcon className="responsive-icon-size" {...props} />;
    case 'c-sharp-icon':
      return <CSharpIcon className="responsive-icon-size" {...props} />;
    case 'javascript-icon':
      return <JavascriptIcon className="responsive-icon-size" {...props} />;
    case 'python-icon':
      return <PythonIcon className="responsive-icon-size" {...props} />;
    case 'ruby-icon':
      return <RubyIcon className="responsive-icon-size" {...props} />;
    case 'pointer-up':
      return <PointerArrowUp className="responsive-icon-size" {...props} />;
    case 'pointer-down':
      return <PointerArrowDown className="responsive-icon-size" {...props} />;
    case 'timeline':
      return <TimelineIcon className="responsive-icon-size" {...props} />;
    case 'task':
      return <TaskIcon className="responsive-icon-size" {...props} />;
    case 'questionmarkincircle':
      return (
        <QuestionMarkInCircle className="responsive-icon-size" {...props} />
      );
    case 'emptystateicon':
      return <EmptyStateIcon className="responsive-icon-size" {...props} />;
    case 'artifacticon':
      return <ArtifactIcon className="responsive-icon-size" {...props} />;
    case 'reporticon':
      return <ReportIcon className="responsive-icon-size" {...props} />;
    case 'info-revert':
      return <InfoRevert className="responsive-icon-size" {...props} />;
    case 'background-service':
      return (
        <BackgroundServiceIcon className="responsive-icon-size" {...props} />
      );
    case 'ruby-colored':
      return <RubyIconColored className="responsive-icon-size" {...props} />;
    case 'python-colored':
      return <PythonIconColored className="responsive-icon-size" {...props} />;
    case 'js-colored':
      return (
        <JavascriptIconColored className="responsive-icon-size" {...props} />
      );
    case 'java-colored':
      return <JavaIconColored className="responsive-icon-size" {...props} />;
    case 'c-sharp-colored':
      return <CSharpIconColored className="responsive-icon-size" {...props} />;
    case 'folder':
      return <FolderIcon className="responsive-icon-size" {...props} />;
    case 'file':
      return <File className="responsive-icon-size" {...props} />;
    case 'dropdown':
      return <DropdownIcon className="responsive-icon-size" {...props} />;
    case 'upload':
      return <Upload className="responsive-icon-size" {...props} />;
    case 'cross-black':
      return <CrossBlack className="responsive-icon-size" {...props} />;
    case 'check-bold-green':
      return <CheckBoldGreen className="responsive-icon-size" {...props} />;
    case 'chevron-right':
      return <ChevronRight className="responsive-icon-size" {...props} />;
    case 'scenario':
      return <Scenario className="responsive-icon-size" {...props} />;
    case 'test':
      return <Test className="responsive-icon-size" {...props} />;
    case 'analysis':
      return <Analysis lassName="responsive-icon-size" {...props} />;
    case 'switch':
      return <Switch lassName="responsive-icon-size" {...props} />;
    case 'error-status':
      return <JobError className="responsive-icon-size" {...props} />;
    case 'stage-timeout-v2':
      return <StageTimeoutV2 className="responsive-icon-size" {...props} />;
    case 'redirect-grey':
      return <RedirectGrey className="responsive-icon-size" {...props} />;
    case 'view-svg':
      return <ViewSVG className="responsive-icon-size" {...props} />;
    case 'insight':
      return <Insight className="responsive-icon-size" {...props} />;
    case 'analytics-poster':
      return <AnalyticsPoster {...props} />;
    default:
      return null;
  }
});
