import React from 'react';

const ExplorerIcon = ({ width = 12, height = 12, className, id, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      viewBox="0 0 22 21"
      role="img"
      aria-hidden="true"
    >
      <defs>
        <linearGradient id={`a-${id}`} x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#66F0FB" />
          <stop offset="100%" stopColor="#42B4F2" />
        </linearGradient>
      </defs>
      <path
        fill={`url(#a-${id})`}
        fillRule="nonzero"
        d="M16.377 12.179h5.266c.04-.366.057-.74.057-1.121 0-1.79-.48-3.47-1.32-4.917.868-2.306.837-4.264-.324-5.433-1.104-1.1-4.066-.922-7.415.562a9.935 9.935 0 0 0-.75-.028c-4.596 0-8.453 3.165-9.518 7.43C3.814 6.825 5.33 5.485 7.358 4.51c-.185.173-1.26 1.242-1.44 1.423C.573 11.28-1.113 18.263.701 20.077c1.378 1.38 3.876 1.147 6.745-.26a9.758 9.758 0 0 0 4.444 1.064c4.308 0 7.959-2.775 9.281-6.64h-5.306a4.318 4.318 0 0 1-3.797 2.265 4.318 4.318 0 0 1-3.796-2.265 4.343 4.343 0 0 1-.512-2.046v-.016h8.616zM7.77 9.589a4.135 4.135 0 0 1 4.122-3.898c2.198 0 4 1.73 4.121 3.897H7.77zm12.239-7.791c.748.756.73 2.148.09 3.884a9.847 9.847 0 0 0-4.569-3.742c2.01-.862 3.645-.977 4.479-.142zM2.085 19.733c-.954-.955-.666-2.96.563-5.376a9.85 9.85 0 0 0 4.179 5.116c-2.129.967-3.871 1.13-4.742.26z"
      />
    </svg>
  );
};

export default ExplorerIcon;
