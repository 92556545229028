import React from 'react';

const CloseIcon = ({
  width = '16px',
  height = '16px',
  color = '#666666',
  className,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path d="M12 4L4 12" stroke={color} strokeLinecap="round" />
      <path d="M4 4L12 12" stroke={color} strokeLinecap="round" />
    </svg>
  );
};

export { CloseIcon };
