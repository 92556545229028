import React from 'react';

const ChromeIcon = ({ width = 12, height = 12, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M5.98424 0.00107343C5.98424 0.00107343 9.52057 -0.157816 11.3948 3.39063C10.4047 3.39063 5.68359 3.39063 5.68359 3.39063C5.68359 3.39063 4.605 3.35519 3.68554 4.66184C3.42032 5.20909 3.13748 5.77407 3.45574 6.8862C2.99606 6.10956 1.01562 2.66686 1.01562 2.66686C1.01562 2.66686 2.41258 0.142246 5.98424 0.00107343Z"
        fill="#E16B5A"
      />
      <path
        d="M5.98434 0.00107517C5.92333 0.00351242 5.86448 0.00829317 5.80469 0.0120428C6.75948 0.0815981 9.33905 0.538582 10.8455 3.39063C11.0781 3.39063 11.2676 3.39063 11.3947 3.39063C9.52058 -0.157908 5.98434 0.00107517 5.98434 0.00107517Z"
        fill="#CC6152"
      />
      <path
        d="M11.216 9.00855C11.216 9.00855 9.58535 12.1454 5.57031 11.9911C6.06561 11.1351 8.42663 7.05322 8.42663 7.05322C8.42663 7.05322 8.99662 6.13822 8.32354 4.68994C7.98138 4.18693 6.94487 3.65973 5.82101 3.3787C6.72482 3.3697 11.3889 3.37926 11.3889 3.37926C11.3889 3.37926 12.8797 5.84979 11.216 9.00855Z"
        fill="#FFD464"
      />
      <path
        d="M11.3889 3.3792C11.3889 3.3792 9.00983 3.37432 7.32067 3.37488C8.94114 3.37526 10.8822 3.3792 10.8822 3.3792C10.8822 3.3792 12.3732 5.84963 10.7095 9.00839C10.7095 9.00839 9.21526 11.8772 5.57031 11.9909C9.58535 12.1452 11.2161 9.00839 11.2161 9.00839C12.8798 5.84963 11.3889 3.3792 11.3889 3.3792Z"
        fill="#F2C95F"
      />
      <path
        d="M0.780964 9.03727C0.780964 9.03727 -1.1244 6.05858 1.01713 2.66406C1.51205 3.52038 3.87176 7.60305 3.87176 7.60305C3.87176 7.60305 4.38009 8.55339 5.973 8.6955C6.5802 8.65125 7.21185 8.61366 8.01754 7.78266C7.57332 8.56848 5.57686 12.0016 5.57686 12.0016C5.57686 12.0016 2.68868 12.0554 0.780964 9.03727Z"
        fill="#74B340"
      />
      <ellipse cx="5.9678" cy="6.04644" rx="2.7178" ry="2.71847" fill="white" />
      <ellipse
        cx="5.968"
        cy="6.04647"
        rx="2.1555"
        ry="2.15603"
        fill="#2995CC"
      />
      <path
        d="M5.96349 3.89044C5.92056 3.89044 5.87811 3.89204 5.83594 3.89447C6.96692 3.96056 7.86379 4.89862 7.86379 6.04637C7.86379 7.19432 6.96692 8.13247 5.83594 8.19856C5.8782 8.2009 5.92066 8.20259 5.96349 8.20259C7.15379 8.20259 8.11899 7.23716 8.11899 6.04637C8.11899 4.85578 7.15379 3.89044 5.96349 3.89044Z"
        fill="#268CBF"
      />
    </svg>
  );
};

export default ChromeIcon;
