import React from 'react';

const ThreeDots2 = ({ width = '14', height = '5', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 5"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M10.5 2.5835C10.5 2.11937 10.6844 1.67425 11.0126 1.34606C11.3408 1.01787 11.7859 0.833496 12.25 0.833496C12.7141 0.833496 13.1592 1.01787 13.4874 1.34606C13.8156 1.67425 14 2.11937 14 2.5835C14 3.04763 13.8156 3.49274 13.4874 3.82093C13.1592 4.14912 12.7141 4.3335 12.25 4.3335C11.7859 4.3335 11.3408 4.14912 11.0126 3.82093C10.6844 3.49274 10.5 3.04763 10.5 2.5835ZM5.25 2.5835C5.25 2.11937 5.43437 1.67425 5.76256 1.34606C6.09075 1.01787 6.53587 0.833496 7 0.833496C7.46413 0.833496 7.90925 1.01787 8.23744 1.34606C8.56563 1.67425 8.75 2.11937 8.75 2.5835C8.75 3.04763 8.56563 3.49274 8.23744 3.82093C7.90925 4.14912 7.46413 4.3335 7 4.3335C6.53587 4.3335 6.09075 4.14912 5.76256 3.82093C5.43437 3.49274 5.25 3.04763 5.25 2.5835ZM0 2.5835C0 2.11937 0.184374 1.67425 0.512563 1.34606C0.840752 1.01787 1.28587 0.833496 1.75 0.833496C2.21413 0.833496 2.65925 1.01787 2.98744 1.34606C3.31563 1.67425 3.5 2.11937 3.5 2.5835C3.5 3.04763 3.31563 3.49274 2.98744 3.82093C2.65925 4.14912 2.21413 4.3335 1.75 4.3335C1.28587 4.3335 0.840752 4.14912 0.512563 3.82093C0.184374 3.49274 0 3.04763 0 2.5835Z"
        fill="#666666"
      />
    </svg>
  );
};

export default ThreeDots2;
