import { getTransformedScenariosForDex } from 'utils/transformations/tosca-dex';
import {
  HYPERTEST_TASK_LOG_ACTIONS_REQUEST,
  HYPERTEST_TASK_LOG_ACTIONS_SILENT_REQUEST,
  HYPERTEST_TASK_LOG_ACTIONS_RESPONSE,
  HYPERTEST_TASK_LOG_RESET,
  HYPERTEST_SET_ACTIVE_STAGE_LOG,
  HYPERTEST_SET_CURRENT_RUNNING_LOGTYPE,
} from '../constants/hypertestTaskLog';

const initialState = {
  hypertestTaskLog: {
    data: null,
    loading: true,
    error: false,
  },
  activeStageLog: 0,
  currentRunningLogType: '',
};

function hypertestTaskLogReducer(state = initialState, actions) {
  const { type, payload, error } = actions;
  switch (type) {
    case HYPERTEST_TASK_LOG_ACTIONS_REQUEST:
      return {
        ...state,
        hypertestTaskLog: {
          ...state.hypertestTaskLog,
          loading: true,
        },
      };
    case HYPERTEST_TASK_LOG_ACTIONS_RESPONSE:
      let dexData = getTransformedScenariosForDex(payload);

      return {
        ...state,
        hypertestTaskLog: {
          ...state.hypertestTaskLog,
          data:
            dexData?.length ||
            payload?.data?.hasOwnProperty('execution_list_results')
              ? { data: dexData }
              : payload,
          loading: false,
          error: error === undefined ? false : error,
        },
      };
    case HYPERTEST_TASK_LOG_ACTIONS_SILENT_REQUEST:
      return {
        ...state,
        hypertestTaskLog: {
          ...state.hypertestTaskLog,
          loading: false,
        },
      };
    case HYPERTEST_SET_ACTIVE_STAGE_LOG:
      return {
        ...state,
        activeStageLog: payload,
      };
    case HYPERTEST_SET_CURRENT_RUNNING_LOGTYPE:
      return {
        ...state,
        currentRunningLogType: payload,
      };
    case HYPERTEST_TASK_LOG_RESET:
      return initialState;
    default:
      return state;
  }
}

export default hypertestTaskLogReducer;
