import React from 'react';

const Aborted = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M5.33331 1.33331L1.33331 5.43731V10.6666L5.33331 14.6666H10.6666L14.6666 10.6666V5.43731L10.6666 1.33331H5.33331Z"
        fill="#E67300"
      />
      <path
        d="M10.6666 7.99997H5.33331"
        stroke="white"
        strokeWidth="1.47059"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Aborted;
