import React from 'react';

const Muted = ({ width = '20', height = '20', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 16 16'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="8" cy="8" r="7.5" fill="#666666" stroke="#666666" />
      <path
        d="M6.5 10.5V11C6.5 11.8285 7.17155 12.5 8 12.5C8.82845 12.5 9.5 11.8285 9.5 11V10.5M6.5 10.5H9.5M6.5 10.5L4.50011 10.5001C4.24369 10.5001 4.03225 10.3071 4.00337 10.0584L4 9.9999V9.70705C4 9.57445 4.05272 9.44745 4.14649 9.35365L4.40185 9.09815C4.46469 9.0353 4.5 8.95015 4.5 8.86125V6.99993C4.5 6.0791 4.8556 5.24134 5.43696 4.61646M9.5 10.5H11.4999M4.5 3.5L12.5 11.5M11.4997 8.4995L11.5002 7C11.5002 5.06701 9.93325 3.5 8.00025 3.5L7.8799 3.50203C7.4774 3.51563 7.09225 3.59702 6.73535 3.7354"
        stroke="white"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Muted;
