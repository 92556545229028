import React from 'react';

const PJClose = ({ width = '24', height = '24', className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <g clipPath="url(#clip0_4486_92585)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.41346 17.6569C3.28926 14.5327 3.28926 9.46734 6.41346 6.34315C9.53765 3.21895 14.603 3.21895 17.7272 6.34315C20.8514 9.46734 20.8514 14.5327 17.7272 17.6569C14.603 20.781 9.53765 20.781 6.41346 17.6569ZM10.1006 8.96967C9.80774 8.67678 9.33287 8.67678 9.03997 8.96967C8.74708 9.26256 8.74708 9.73744 9.03997 10.0303L11.0096 12L9.03998 13.9697C8.74708 14.2626 8.74708 14.7374 9.03998 15.0303C9.33287 15.3232 9.80774 15.3232 10.1006 15.0303L12.0703 13.0607L14.04 15.0303C14.3329 15.3232 14.8077 15.3232 15.1006 15.0303C15.3935 14.7374 15.3935 14.2626 15.1006 13.9697L13.131 12L15.1006 10.0303C15.3935 9.73744 15.3935 9.26257 15.1006 8.96968C14.8077 8.67678 14.3329 8.67678 14.04 8.96968L12.0703 10.9393L10.1006 8.96967Z"
          fill="#666666"
        />
      </g>
      <defs>
        <clipPath id="clip0_4486_92585">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4.07031 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PJClose;
