import React from 'react';

const Analysis = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M9.9974 1.3335H3.9974C3.64377 1.3335 3.30464 1.47397 3.05459 1.72402C2.80454 1.97407 2.66406 2.31321 2.66406 2.66683V13.3335C2.66406 13.6871 2.80454 14.0263 3.05459 14.2763C3.30464 14.5264 3.64377 14.6668 3.9974 14.6668H11.9974C12.351 14.6668 12.6902 14.5264 12.9402 14.2763C13.1903 14.0263 13.3307 13.6871 13.3307 13.3335V4.66683L9.9974 1.3335Z"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33594 1.3335V4.00016C9.33594 4.35378 9.47641 4.69292 9.72646 4.94297C9.97651 5.19302 10.3156 5.3335 10.6693 5.3335H13.3359"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6693 8.66699L8.33594 11.0003L7.0026 9.66699L5.33594 11.3337"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Analysis;
