import React from 'react';

const Copy = ({
  width = 16,
  height = 16,
  className,
  color = '#666666',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      {...rest}
      version="1.0"
      role="img"
    >
      <path
        d="M10.1774 12.5984V14.5339C10.1774 15.0684 9.74415 15.5016 9.20968 15.5016H1.46774C0.933273 15.5016 0.5 15.0684 0.5 14.5339V6.79196C0.5 6.25749 0.933273 5.82422 1.46774 5.82422H3.40323"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M14.5323 0.5H6.79037C6.2559 0.5 5.82263 0.933273 5.82263 1.46774V9.20968C5.82263 9.74415 6.2559 10.1774 6.79037 10.1774H14.5323C15.0668 10.1774 15.5001 9.74415 15.5001 9.20968V1.46774C15.5001 0.933273 15.0668 0.5 14.5323 0.5Z"
        stroke={color}
      />
    </svg>
  );
};

export { Copy };
