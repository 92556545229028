import React from 'react';

const OnboardMacos = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 12"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.42117 -3.05176e-05C7.50463 0.669207 7.19971 1.34034 6.74892 1.82373C6.29738 2.30635 5.55704 2.68219 4.83235 2.63308C4.73471 1.97698 5.10057 1.29308 5.51845 0.865174C5.98462 0.38182 6.77172 0.0231843 7.42117 -3.05176e-05ZM9.36467 4.19396C9.25577 4.26307 8.11865 4.98469 8.13214 6.44557C8.14764 8.17629 9.55075 8.79014 9.66506 8.84015C9.66925 8.84199 9.67171 8.84306 9.6723 8.84341C9.67167 8.84537 9.67055 8.84911 9.66891 8.85455C9.63652 8.96211 9.40436 9.73307 8.87734 10.5281C8.39884 11.2506 7.90353 11.97 7.1209 11.9847C6.74517 11.9918 6.49424 11.8796 6.23336 11.7631C5.9602 11.641 5.67612 11.514 5.22642 11.514C4.75594 11.514 4.45864 11.6447 4.17217 11.7707C3.92354 11.88 3.68308 11.9857 3.34464 11.9993C2.59007 12.0285 2.01546 11.2206 1.53285 10.5004C0.545604 9.02925 -0.207855 6.34255 0.805277 4.52887C1.30617 3.62732 2.20482 3.05847 3.1801 3.04238C3.60087 3.0343 4.00803 3.20137 4.36418 3.34751C4.63598 3.45903 4.87808 3.55837 5.07384 3.55837C5.25024 3.55837 5.48632 3.46231 5.76152 3.35033C6.1948 3.17403 6.72505 2.95827 7.27208 3.01579C7.6462 3.03151 8.69669 3.17092 9.37121 4.18978C9.36981 4.1907 9.36762 4.19209 9.36467 4.19396Z"
        fill={color ? color : '#666666'}
      />
    </svg>
  );
};

export { OnboardMacos };
