import React from 'react';

const PythonIcon = ({ width = 20, height = 20, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M9.93646 2.55078C6.16116 2.55078 6.39688 4.18798 6.39688 4.18798L6.40107 5.88416H10.0038V6.3934H4.97014C4.97014 6.3934 2.55432 6.11942 2.55432 9.92867C2.55432 13.738 4.6629 13.6029 4.6629 13.6029H5.9213V11.8352C5.9213 11.8352 5.85346 9.72666 7.99622 9.72666H11.5695C11.5695 9.72666 13.577 9.75909 13.577 7.78643V4.52473C13.577 4.52473 13.8819 2.55078 9.93646 2.55078ZM7.94993 3.69143C8.03506 3.69135 8.11938 3.70807 8.19805 3.74061C8.27671 3.77315 8.34819 3.82089 8.40839 3.88109C8.46859 3.94129 8.51633 4.01277 8.54887 4.09144C8.58142 4.17011 8.59813 4.25442 8.59805 4.33955C8.59813 4.42469 8.58142 4.509 8.54887 4.58767C8.51633 4.66634 8.46859 4.73782 8.40839 4.79802C8.34819 4.85822 8.27671 4.90595 8.19805 4.9385C8.11938 4.97104 8.03506 4.98775 7.94993 4.98768C7.8648 4.98775 7.78048 4.97104 7.70181 4.9385C7.62314 4.90595 7.55167 4.85822 7.49147 4.79802C7.43127 4.73782 7.38353 4.66634 7.35099 4.58767C7.31844 4.509 7.30173 4.42469 7.30181 4.33955C7.30173 4.25442 7.31844 4.17011 7.35099 4.09144C7.38353 4.01277 7.43127 3.94129 7.49147 3.88109C7.55167 3.82089 7.62314 3.77315 7.70181 3.74061C7.78048 3.70807 7.8648 3.69135 7.94993 3.69143Z"
        fill="#999999"
      />
      <path
        d="M10.0438 17.3457C13.8191 17.3457 13.5834 15.7085 13.5834 15.7085L13.5792 14.0124H9.9764V13.5032H15.01C15.01 13.5032 17.4259 13.7771 17.4259 9.96777C17.4259 6.15846 15.3173 6.29356 15.3173 6.29356H14.0589V8.0612C14.0589 8.0612 14.1267 10.1698 11.984 10.1698H8.41072C8.41072 10.1698 6.40317 10.1373 6.40317 12.1101V15.3718C6.40317 15.3718 6.09832 17.3457 10.0437 17.3457H10.0438ZM12.0303 16.2052C11.9452 16.2053 11.8609 16.1885 11.7822 16.156C11.7035 16.1235 11.632 16.0757 11.5718 16.0155C11.5116 15.9553 11.4639 15.8838 11.4314 15.8052C11.3988 15.7265 11.3821 15.6422 11.3822 15.5571C11.3821 15.4719 11.3988 15.3876 11.4313 15.3089C11.4639 15.2302 11.5116 15.1588 11.5718 15.0986C11.632 15.0384 11.7035 14.9906 11.7822 14.9581C11.8608 14.9255 11.9452 14.9088 12.0303 14.9089C12.1154 14.9088 12.1998 14.9255 12.2784 14.9581C12.3571 14.9906 12.4286 15.0383 12.4888 15.0985C12.549 15.1587 12.5967 15.2302 12.6292 15.3089C12.6618 15.3876 12.6785 15.4719 12.6784 15.557C12.6785 15.6421 12.6618 15.7264 12.6292 15.8051C12.5967 15.8838 12.549 15.9553 12.4888 16.0155C12.4286 16.0757 12.3571 16.1234 12.2784 16.1559C12.1998 16.1885 12.1154 16.2052 12.0303 16.2051V16.2052Z"
        fill="#999999"
      />
    </svg>
  );
};

export default PythonIcon;
