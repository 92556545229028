const HyperExecuteYamlCli = ({
  width = 17,
  height = 17,
  className,
  color = '#999999',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      role="img"
      className={className}
      aria-hidden="true"
      {...rest}
    >
      <path d="M3 4H17V16H3V4Z" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M7 12L9 10L7 8" strokeMiterlimit="10" />
      <path d="M11 9.99219H14" strokeMiterlimit="10" />
    </svg>
  );
};

export default HyperExecuteYamlCli;
