import React from 'react';

const Retry = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
      role="img"
      preserveAspectRatio="xMidYMid meet"
      aria-hidden="true"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#888888"
        stroke="none"
      >
        <path
          d="M2385 5003 c-209 -18 -465 -76 -658 -149 -824 -312 -1412 -1022
-1567 -1894 -31 -175 -39 -533 -16 -705 40 -288 107 -509 231 -760 249 -505
627 -884 1130 -1130 234 -115 476 -190 745 -232 161 -25 529 -25 690 0 310 48
564 134 845 286 494 267 892 716 1090 1231 45 115 46 159 4 194 -37 31 -96 35
-128 9 -12 -10 -45 -78 -75 -150 -120 -295 -274 -522 -507 -748 -129 -125
-190 -174 -343 -274 -273 -177 -597 -298 -931 -346 -158 -23 -437 -23 -600 -1
-499 70 -945 290 -1300 641 -346 343 -566 774 -642 1256 -24 159 -24 492 0
644 81 497 297 925 642 1270 344 346 783 563 1290 640 163 25 450 23 631 -4
487 -73 923 -291 1270 -636 114 -113 251 -278 236 -283 -5 -1 -110 29 -235 68
-277 86 -307 88 -342 20 -27 -52 -12 -111 34 -135 15 -7 196 -65 404 -129 280
-87 386 -116 407 -111 36 8 80 58 80 90 0 13 42 162 94 332 139 455 130 415
112 458 -29 69 -132 81 -172 20 -8 -12 -48 -132 -89 -266 -41 -133 -77 -249
-80 -256 -3 -9 -27 15 -65 66 -476 631 -1220 999 -2005 989 -77 -1 -158 -3
-180 -5z"
        />
      </g>
    </svg>
  );
};

export { Retry };
