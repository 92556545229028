import React from 'react';

const PJGoogle = ({ width = '16', height = '14', className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 16 14"
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <g clipPath="url(#clip0_4503_185699)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1854 4.10693L10.6979 4.11571L12.0904 2.72319L12.1579 2.13322C11.0143 1.11226 9.53465 0.548646 8.00168 0.54999C5.11669 0.54999 2.6817 2.51299 1.96094 5.172C2.11295 5.06597 2.43791 5.1455 2.43791 5.1455L5.22042 4.68801C5.22042 4.68801 5.36341 4.45128 5.43589 4.46304C6.09378 3.74021 7.02603 3.32842 8.00336 3.3288C8.82838 3.33053 9.58836 3.62203 10.1854 4.10429V4.10698H10.1854V4.10693Z"
          fill="#EA4335"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.045 5.1748C13.7217 3.98229 13.0555 2.92932 12.1562 2.13055L10.1845 4.10229C10.9745 4.73978 11.4813 5.71528 11.4813 6.80728V7.15528C12.4405 7.15528 13.2198 7.93629 13.2198 8.89379C13.2198 9.85302 12.4388 10.6323 11.4813 10.6323H8.00425L7.65625 10.9823V13.0688L8.00425 13.415H11.4813C13.9767 13.4115 15.9988 11.3895 16.0025 8.89408C16.0007 7.35534 15.2275 5.99407 14.045 5.17509V5.17485L14.045 5.1748Z"
          fill="#4285F4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.52074 13.4201H7.99574V10.6352H4.52074C4.27383 10.6352 4.02984 10.5822 3.80525 10.4797L3.30351 10.6334L1.91103 12.0259L1.78906 12.4959C2.57376 13.0946 3.53352 13.4187 4.52055 13.4182L4.52079 13.4201H4.52074Z"
          fill="#34A853"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.52126 4.375C2.02579 4.381 0.00350399 6.403 0 8.89851C0 10.3668 0.703295 11.6723 1.7915 12.4993L3.80745 10.4833C3.18422 10.2022 2.78327 9.58222 2.7827 8.89851C2.7827 7.93928 3.5637 7.16 4.52121 7.16C5.22618 7.16 5.83223 7.58499 6.10621 8.18504L8.12221 6.16904C7.29546 5.08079 5.98972 4.37754 4.52149 4.37754L4.52126 4.375Z"
          fill="#FBBC05"
        />
      </g>
      <defs>
        <clipPath id="clip0_4503_185699">
          <rect
            width="16"
            height="12.87"
            fill="white"
            transform="translate(0 0.549988)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PJGoogle;
