import React from 'react';

const CompletedGreen = ({ width = 18, height = 18, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M3.85938 6.1855L5.31503 7.45566C5.35643 7.49179 5.41923 7.48774 5.45565 7.4466L8.12823 4.42773"
        stroke="#2AB14F"
        strokeLinecap="round"
      />
      <circle cx="6" cy="6" r="5.5" stroke="#2AB14F" />
    </svg>
  );
};

export { CompletedGreen };
