import React from 'react';

const CompletedStep = ({
  width = 24,
  height = 24,
  color = '#0EBAC5',
  className,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="12" cy="10" r="6.5" fill="white" stroke={color} />
      <g filter="url(#filter0_d_16681_121196)">
        <circle cx="12" cy="10" r="3.5" fill={color} />
        <circle cx="12" cy="10" r="3" stroke={color} />
      </g>
      <defs>
        <filter
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.054902 0 0 0 0 0.729412 0 0 0 0 0.772549 0 0 0 0.54 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_16681_121196"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_16681_121196"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export { CompletedStep };
