import React from 'react';

const Plus = ({
  width = '10',
  height = '10',
  className,
  color = '#666666',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className={className}
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M9.08268 5.58317H5.58268V9.08317H4.41602V5.58317H0.916016V4.4165H4.41602V0.916504H5.58268V4.4165H9.08268V5.58317Z"
        fill={color}
      />
    </svg>
  );
};

export default Plus;
