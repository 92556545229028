import { CALL_API } from '../middleware/api';
import {
  HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_REQUEST,
  HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_REQUEST_SILENT,
  HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_RESPONSE,
  HYPERTEST_JOB_ARTEFACT_DELETE_ACTIONS_REQUEST,
  HYPERTEST_JOB_ARTEFACT_DELETE_ACTIONS_RESPONSE,
  HYPERTEST_JOB_ARTEFACT_DOWNLOAD_ACTIONS_REQUEST,
  HYPERTEST_JOB_ARTEFACT_DOWNLOAD_ACTIONS_RESPONSE,
  HYPERTEST_JOB_ARTEFACT_SET_CURRENT_ARTEFACT,
  HYPERTEST_JOB_ARTEFACT_CONTENT_ACTIONS_REQUEST,
  HYPERTEST_JOB_ARTEFACT_CONTENT_ACTIONS_RESPONSE,
  HYPERTEST_RESET_ARTEFACTS,
} from '../constants/artifacts';
import { isOnPremURL } from 'new-theme/utils/brandUrl';

const HYPERTEST_SENTINEL_API_ENDPOINT =
  process.env.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT ||
  (window._env_ && window._env_.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT);
const HYPERTEST_LOGISTIC_API_ENDPOINT =
  process.env.REACT_APP_HYPERTEST_LOGISTIC_API_ENDPOINT ||
  (window._env_ && window._env_.REACT_APP_HYPERTEST_LOGISTIC_API_ENDPOINT);

export const resetArtifacts = () => {
  return {
    type: HYPERTEST_RESET_ARTEFACTS,
  };
};

export const getHypertestJobArtifacts = ({ auth, jobId }) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;
  return {
    [CALL_API]: {
      types: [
        HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_REQUEST,
        HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_RESPONSE,
      ],
      endpoint: `${sentinelUrl}/v1.0/job/${jobId}/artefacts`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};
export const deleteJobArtefact = ({ auth, jobId, name }) => {
  let logisticUrl =
    (window.globalConfig && window.globalConfig.LOGISTICS_HOST) ||
    HYPERTEST_LOGISTIC_API_ENDPOINT;
  return {
    [CALL_API]: {
      types: [
        HYPERTEST_JOB_ARTEFACT_DELETE_ACTIONS_REQUEST,
        HYPERTEST_JOB_ARTEFACT_DELETE_ACTIONS_RESPONSE,
      ],
      endpoint: `${logisticUrl}/v1.0/artefacts/${jobId}/${name}/delete`,
      method: 'PUT',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};

export const getHypertestJobArtifactsSilent = ({ auth, jobId }) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;
  return {
    [CALL_API]: {
      types: [
        HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_REQUEST_SILENT,
        HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_RESPONSE,
      ],
      endpoint: `${sentinelUrl}/v1.0/job/${jobId}/artefacts`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};
export const downloadArtefact = ({ auth, jobId, name }) => {
  let logisticUrl =
    (window.globalConfig && window.globalConfig.LOGISTICS_HOST) ||
    HYPERTEST_LOGISTIC_API_ENDPOINT;
  return {
    [CALL_API]: {
      types: [
        HYPERTEST_JOB_ARTEFACT_DOWNLOAD_ACTIONS_REQUEST,
        HYPERTEST_JOB_ARTEFACT_DOWNLOAD_ACTIONS_RESPONSE,
      ],
      endpoint: `${logisticUrl}/v1.0/artefacts/${jobId}/download?name=${name}`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};

export const setCurrentArtefactClick = (artefact) => {
  return {
    type: HYPERTEST_JOB_ARTEFACT_SET_CURRENT_ARTEFACT,
    payload: artefact,
  };
};

export const fetchArtefactContent = ({
  auth,
  jobId,
  artefactName,
  taskID,
  filter,
  view,
}) => {
  let logisticUrl =
    (window.globalConfig && window.globalConfig.LOGISTICS_HOST) ||
    HYPERTEST_LOGISTIC_API_ENDPOINT;

  let endpoint = `${logisticUrl}/v1.0/artefact/view/${jobId}?artefactName=${artefactName}`;
  if (taskID) {
    endpoint += `&taskId=${taskID}`;
  }
  if (filter) {
    endpoint += `&filter=${filter}`;
  }
  if (view) {
    endpoint += `&view=${view}`;
  }
  return {
    [CALL_API]: {
      types: [
        HYPERTEST_JOB_ARTEFACT_CONTENT_ACTIONS_REQUEST,
        HYPERTEST_JOB_ARTEFACT_CONTENT_ACTIONS_RESPONSE,
      ],
      endpoint,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
      ...(isOnPremURL() ? {} : { withCredentials: true }),
    },
  };
};

export const downloadArtefactFile = ({ auth, downloadLink }) => {
  return {
    [CALL_API]: {
      types: [
        'HYPERTEST_JOB_ARTEFACT_CONTENT_DOWNLOAD_ACTIONS_REQUEST',
        'HYPERTEST_JOB_ARTEFACT_CONTENT_DOWNLOAD_ACTIONS_RESPONSE',
      ],
      endpoint: downloadLink,
      method: 'GET',
      appendBaseUrl: false,
      auth: auth,
    },
  };
};
