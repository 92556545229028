import React from 'react';

const Upload = ({ className, ...rest }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path
        d="M2.75 14C2.28587 14 1.84075 13.8156 1.51256 13.4874C1.18437 13.1592 1 12.7141 1 12.25V9.75C1 9.55109 1.07902 9.36032 1.21967 9.21967C1.36032 9.07902 1.55109 9 1.75 9C1.94891 9 2.13968 9.07902 2.28033 9.21967C2.42098 9.36032 2.5 9.55109 2.5 9.75V12.25C2.5 12.388 2.612 12.5 2.75 12.5H13.25C13.3163 12.5 13.3799 12.4737 13.4268 12.4268C13.4737 12.3799 13.5 12.3163 13.5 12.25V9.75C13.5 9.55109 13.579 9.36032 13.7197 9.21967C13.8603 9.07902 14.0511 9 14.25 9C14.4489 9 14.6397 9.07902 14.7803 9.21967C14.921 9.36032 15 9.55109 15 9.75V12.25C15 12.7141 14.8156 13.1592 14.4874 13.4874C14.1592 13.8156 13.7141 14 13.25 14H2.75Z"
        fill="#666666"
      />
      <path
        d="M11.7795 4.72C11.8491 4.7896 11.9043 4.87223 11.942 4.96316C11.9797 5.0541 11.9991 5.15157 11.9991 5.25C11.9991 5.34843 11.9797 5.44589 11.942 5.53683C11.9043 5.62777 11.8491 5.7104 11.7795 5.78C11.7099 5.8496 11.6273 5.90481 11.5364 5.94248C11.4454 5.98014 11.348 5.99953 11.2495 5.99953C11.1511 5.99953 11.0536 5.98014 10.9627 5.94248C10.8718 5.90481 10.7891 5.8496 10.7195 5.78L8.74953 3.811V9.5C8.74953 9.69891 8.67052 9.88968 8.52986 10.0303C8.38921 10.171 8.19845 10.25 7.99953 10.25C7.80062 10.25 7.60986 10.171 7.4692 10.0303C7.32855 9.88968 7.24953 9.69891 7.24953 9.5V3.811L5.27953 5.78C5.20993 5.8496 5.1273 5.90481 5.03637 5.94248C4.94543 5.98014 4.84796 5.99953 4.74953 5.99953C4.6511 5.99953 4.55364 5.98014 4.4627 5.94248C4.37176 5.90481 4.28913 5.8496 4.21953 5.78C4.14993 5.7104 4.09472 5.62777 4.05705 5.53683C4.01939 5.44589 4 5.34843 4 5.25C4 5.15157 4.01939 5.0541 4.05705 4.96316C4.09472 4.87223 4.14993 4.7896 4.21953 4.72L7.46953 1.47C7.5391 1.40033 7.62171 1.34507 7.71266 1.30736C7.8036 1.26965 7.90108 1.25024 7.99953 1.25024C8.09798 1.25024 8.19547 1.26965 8.28641 1.30736C8.37735 1.34507 8.45997 1.40033 8.52953 1.47L11.7795 4.72Z"
        fill="#666666"
      />
    </svg>
  );
};

export default Upload;
