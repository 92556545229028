import React from 'react';

const RedirectIcon = ({
  width = '16',
  height = '16',
  className = '',
  color = '#101010',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M3.75 2H7.25C7.44891 2 7.63968 2.07902 7.78033 2.21967C7.92098 2.36032 8 2.55109 8 2.75C8 2.94891 7.92098 3.13968 7.78033 3.28033C7.63968 3.42098 7.44891 3.5 7.25 3.5H3.75C3.6837 3.5 3.62011 3.52634 3.57322 3.57322C3.52634 3.62011 3.5 3.6837 3.5 3.75V12.25C3.5 12.388 3.612 12.5 3.75 12.5H12.25C12.3163 12.5 12.3799 12.4737 12.4268 12.4268C12.4737 12.3799 12.5 12.3163 12.5 12.25V8.75C12.5 8.55109 12.579 8.36032 12.7197 8.21967C12.8603 8.07902 13.0511 8 13.25 8C13.4489 8 13.6397 8.07902 13.7803 8.21967C13.921 8.36032 14 8.55109 14 8.75V12.25C14 12.7141 13.8156 13.1592 13.4874 13.4874C13.1592 13.8156 12.7141 14 12.25 14H3.75C3.28587 14 2.84075 13.8156 2.51256 13.4874C2.18437 13.1592 2 12.7141 2 12.25V3.75C2 2.784 2.784 2 3.75 2ZM10.604 1H14.75C14.8163 1 14.8799 1.02634 14.9268 1.07322C14.9737 1.12011 15 1.1837 15 1.25V5.396C15.0001 5.44551 14.9855 5.49393 14.958 5.53513C14.9305 5.57632 14.8915 5.60843 14.8457 5.62739C14.8 5.64635 14.7497 5.6513 14.7011 5.64162C14.6525 5.63194 14.608 5.60805 14.573 5.573L13.03 4.03L9.28 7.78C9.13774 7.91241 8.94969 7.98451 8.75537 7.98116C8.56105 7.9778 8.3756 7.89924 8.238 7.762C8.10076 7.6244 8.0222 7.43895 8.01884 7.24463C8.01549 7.05031 8.08759 6.86226 8.22 6.72L11.97 2.97L10.427 1.427C10.3919 1.39204 10.3681 1.34745 10.3584 1.2989C10.3487 1.25034 10.3536 1.20001 10.3726 1.15427C10.3916 1.10853 10.4237 1.06945 10.4649 1.04199C10.5061 1.01453 10.5545 0.999912 10.604 1Z"
        fill={color}
      />
    </svg>
  );
};

export default RedirectIcon;
