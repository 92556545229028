import {
  HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_REQUEST,
  HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_RESPONSE,
  HYPERTEST_JOB_ARTEFACT_SET_CURRENT_ARTEFACT,
  HYPERTEST_JOB_ARTEFACT_CONTENT_ACTIONS_REQUEST,
  HYPERTEST_JOB_ARTEFACT_CONTENT_ACTIONS_RESPONSE,
  HYPERTEST_RESET_ARTEFACTS,
} from '../constants/artifacts';

const initialState = {
  artifacts: {
    data: null,
    loading: false,
    error: false,
  },
  artefactDeletion: {
    data: null,
    loading: false,
    error: false,
  },
  artefactDownload: {
    data: null,
    loading: false,
    error: false,
  },
  artefactContentStructure: {
    data: null,
    loading: false,
    error: false,
  },
  currentArtefact: null,
};

function hypertestJobArtifactsReducer(state = initialState, actions) {
  const { type, payload, error } = actions;
  switch (type) {
    case HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_REQUEST:
      return {
        ...state,
        artifacts: {
          ...state.artifacts,
          loading: true,
        },
      };
    case HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_RESPONSE:
      return {
        ...state,
        artifacts: {
          ...state.artifacts,
          data: payload,
          loading: false,
          error: error === undefined ? false : error,
        },
      };
    case HYPERTEST_JOB_ARTEFACT_CONTENT_ACTIONS_REQUEST:
      return {
        ...state,
        artefactContentStructure: {
          ...state.artefactContentStructure,
          loading: true,
        },
      };
    case HYPERTEST_RESET_ARTEFACTS:
      return {
        ...initialState,
      };
    case HYPERTEST_JOB_ARTEFACT_CONTENT_ACTIONS_RESPONSE:
      return {
        ...state,
        artefactContentStructure: {
          ...state.artefactContentStructure,
          data: payload,
          loading: false,
          error: error === undefined ? false : error,
        },
      };
    // case HYPERTEST_JOB_ARTEFACT_DELETE_ACTIONS_REQUEST:
    //   return {
    //     ...state,
    //     artefactDeletion: {
    //       ...state.artefactDeletion,
    //       loading: true,
    //     },
    //   };
    // case HYPERTEST_JOB_ARTEFACT_DELETE_ACTIONS_RESPONSE:
    //   return {
    //     ...state,
    //     artefactDeletion: {
    //       ...state.artefactDeletion,
    //       data: payload,
    //       loading: false,
    //       error: error === undefined ? false : error,
    //     },
    //   };
    // case HYPERTEST_JOB_ARTEFACT_DOWNLOAD_ACTIONS_REQUEST:
    //   return {
    //     ...state,
    //     artefactDownload: {
    //       ...state.artefactDownload,
    //       loading: true,
    //     },
    //   };
    // case HYPERTEST_JOB_ARTEFACT_DOWNLOAD_ACTIONS_RESPONSE:
    //   return {
    //     ...state,
    //     artefactDownload: {
    //       ...state.artefactDownload,
    //       data: payload,
    //       loading: false,
    //       error: error === undefined ? false : error,
    //     },
    //   };
    case HYPERTEST_JOB_ARTEFACT_SET_CURRENT_ARTEFACT:
      const artefactContentStructure = state.artefactContentStructure;
      return {
        ...state,
        currentArtefact: payload,
        artefactContentStructure: !payload
          ? { ...initialState.artefactContentStructure }
          : artefactContentStructure,
      };
    default:
      return state;
  }
}

export default hypertestJobArtifactsReducer;
