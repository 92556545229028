import React from 'react';

const Docs = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 18"
      role="img"
      fill="none"
      aria-hidden="true"
    >
      <path
        d="M9.40018 1.08904C9.28969 0.990838 9.14494 0.935493 8.9941 0.933594H0.95296C0.791347 0.933594 0.636387 0.99504 0.522221 1.10437C0.407917 1.21384 0.34375 1.36224 0.34375 1.51687V16.4904C0.34375 16.645 0.407913 16.7934 0.522221 16.9029C0.636384 17.0122 0.791343 17.0737 0.95296 17.0737H13.0553C13.2169 17.0737 13.3718 17.0122 13.486 16.9029C13.6003 16.7935 13.6645 16.6451 13.6645 16.4904V5.15331C13.6625 4.98972 13.5887 4.83454 13.4614 4.72549L9.40018 1.08904ZM12.4461 15.8876H1.5621V2.10024H8.38488V5.15336C8.38488 5.308 8.44919 5.45639 8.56335 5.56586C8.67766 5.67519 8.83262 5.73678 8.99409 5.73678H12.4461L12.4461 15.8876Z"
        fill="#999999"
      />
      <path
        d="M3.43412 9.83203H10.5537C10.7503 9.83203 10.9097 10.1082 10.9097 10.332V10.582C10.9097 10.8581 10.7503 11.082 10.5537 11.082H3.43412C3.23753 11.082 3.07812 10.8059 3.07812 10.582V10.332C3.07812 10.0559 3.23749 9.83203 3.43412 9.83203Z"
        fill="#999999"
      />
      <path
        d="M3.42621 12.332H7.08092C7.27315 12.332 7.42901 12.6082 7.42901 12.832V13.082C7.42901 13.3581 7.27318 13.582 7.08092 13.582H3.42621C3.23399 13.582 3.07812 13.3059 3.07812 13.082V12.832C3.07812 12.5559 3.23395 12.332 3.42621 12.332Z"
        fill="#999999"
      />
    </svg>
  );
};

export { Docs };
