export const transformRepoDetail = (
  stageData = [],
  existingData,
  clearItems
) => {
  let processedData = {
    ids: { jobs: [], stages: [] },
    data: { jobs: {}, stages: {} },
  };
  let rows = [];
  let cols = new Set();
  let data = {};

  if (existingData?.rahul && !clearItems) {
    processedData = existingData;
    rows = existingData.rahul.rows;
    data = existingData.rahul.data;
    cols = new Set(existingData.rahul.cols.filter((c) => c !== -99));
  }

  //(stageData);
  for (const stage of stageData) {
    const { id: stageId, name = '', job_list: jobList = [] } = stage;
    rows.push({ stageId, name });

    // for columns
    for (const job of jobList) {
      const { job_number: jobNumber, job_id: jobId, status } = job;
      cols.add(jobNumber);
      if (data[stageId]) {
        data[stageId][jobNumber] = { jobId, status };
      } else {
        data[stageId] = {};
        data[stageId][jobNumber] = { jobId, status };
      }
    }
  }

  // for (const stage of stageData) {
  //   const { job_list, id, ...stageInfo } = stage;

  //   const stageId = id + Math.random().toString().replace('.', '');
  //   processedData.ids.stages.push(stageId);
  //   processedData.data.stages[stageId] = { id: stageId, ...stageInfo };

  //   for (const job of job_list) {
  //     const { job_id, ...jobInfo } = job;

  //     const jobId = job_id + Math.random().toString().replace('.', '');
  //     if (processedData.ids.jobs.includes(jobId)) {
  //       continue;
  //     }
  //     processedData.ids.jobs.push(jobId);
  //     processedData.data.jobs[jobId] = jobInfo;
  //     //   ids.add(jobInfo.job_number);
  //   }
  // }

  processedData.rahul = {
    rows,
    cols: [...cols],
    data,
  };
  //console.log({ processedData });
  return processedData;
};
