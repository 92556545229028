import {
  USER_INFO_LOADING,
  USER_INFO_SUCCESS,
  USER_INFO_SET_PLAN_EXPIRED_MODAL,
  USER_INFO_SET_LIMIT_EXHAUSTED_MODAL,
  USER_INFO_SET_LIMIT_EXHAUSTED_THRESHOLD_MODAL,
  USER_INFO_SET_TRIAL_FEATURE_MODAL,
  USER_CONSUMED_TIME_LOADING,
  USER_CONSUMED_TIME_SUCCESS,
  USER_INFO_SET_PLAN_INFO_VALUE,
  USER_INFO_SET_COMMON_UI_VISUAL,
  USER_ORG_PREFERENCE_LOADING,
  USER_ORG_PREFERENCE_SUCCESS,
  HYPERTEST_ONBOARDING_ENABLE_HYPEREXECUTE_PLAN_LOADING,
  HYPERTEST_ONBOARDING_ENABLE_HYPEREXECUTE_PLAN_SUCCESS,
  USER_TEAM_INFO_LOADING,
  USER_TEAM_INFO_SUCCESS,
  ENABLE_CONGRATULATION_MODAL,
} from '../constants/userInfo';
const initialState = {
  userInfo: {
    loading: false,
    data: null,
    error: null,
  },
  team: {
    loading: false,
    data: null,
    error: null,
  },
  consumedTime: {
    loading: false,
    data: null,
    error: null,
  },
  orgPreference: {
    loading: false,
    data: null,
    error: null,
  },
  visual: {
    isTrialUser: false,
    planExpired: false,
    limitExhausted: false,
    limitExhaustedThreshold: false,
    isLimitExhoustedThresholdNinty: false,
    isLimitExhoustedThresholdSixty: false,
    isLimitExhoustedThresholdTen: false,
    trialFeatureModal: false,
    limitExhaustedModalFirstTimeShow: false,
    tenPercentageThresholdModalFirstTimeShow: false,
    sixtyPercentageThresholdModalFirstTimeShow: false,
    nintyPercentageThresholdModalFirstTimeShow: false,
    planExpiredFirstTimeShow: false,
    isPaidPlanExpired: false,
    paidPlanExpiredFirstTimeShow: false,
    isPaidUsedPlanAboutToExpire: false,
    isPaidUsedPlanAboutToExpireFirstTimeShow: false,
    isNewUser: false,
    enableCongratulationModal: false,
  },
  commonUIVisual: {
    runInGitpod: false,
    runInReplit: false,
    continueToGitpodModal: false,
    existingUserGoToDashboard: false,
    onboardingJobCreatedSSElisten: false,
  },
  enableHypertestPlan: {
    loading: false,
    data: null,
    error: null,
  },
};

export default function userInfoReducer(state = initialState, actions = {}) {
  const { type, payload, error } = actions;
  switch (type) {
    case USER_INFO_LOADING:
      return { ...state, userInfo: { ...state.userInfo, loading: true } };

    case USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          loading: false,
          data: payload,
          error: error === undefined ? false : error,
        },
      };
    case USER_TEAM_INFO_LOADING:
      return { ...state, team: { ...state.team, loading: true } };

    case USER_TEAM_INFO_SUCCESS:
      return {
        ...state,
        team: {
          ...state.team,
          loading: false,
          data: payload,
          error: error === undefined ? false : error,
        },
      };
    case USER_INFO_SET_PLAN_EXPIRED_MODAL:
      return {
        ...state,
        visual: {
          ...state.visual,
          planExpired: payload,
        },
      };
    case USER_INFO_SET_LIMIT_EXHAUSTED_MODAL:
      return {
        ...state,
        visual: {
          ...state.visual,
          limitExhausted: payload,
        },
      };
    case USER_INFO_SET_LIMIT_EXHAUSTED_THRESHOLD_MODAL:
      return {
        ...state,
        visual: {
          ...state.visual,
          limitExhaustedThreshold: payload,
        },
      };
    case USER_INFO_SET_TRIAL_FEATURE_MODAL:
      return {
        ...state,
        visual: {
          ...state.visual,
          trialFeatureModal: payload,
        },
      };
    case USER_CONSUMED_TIME_LOADING:
      return {
        ...state,
        consumedTime: { ...state.consumedTime, loading: true },
      };

    case USER_CONSUMED_TIME_SUCCESS:
      return {
        ...state,
        consumedTime: {
          ...state.consumedTime,
          loading: false,
          data: payload,
          error: error === undefined ? false : error,
        },
      };
    case USER_INFO_SET_PLAN_INFO_VALUE:
      return {
        ...state,
        visual: {
          ...state.visual,
          ...payload,
        },
      };
    case ENABLE_CONGRATULATION_MODAL:
      return {
        ...state,
        visual: {
          ...state.visual,
          enableCongratulationModal: payload,
        },
      };
    case USER_INFO_SET_COMMON_UI_VISUAL:
      return {
        ...state,
        commonUIVisual: {
          ...state.commonUIVisual,
          ...payload,
        },
      };
    case USER_ORG_PREFERENCE_LOADING:
      return {
        ...state,
        orgPreference: { ...state.orgPreference, loading: true },
      };

    case USER_ORG_PREFERENCE_SUCCESS:
      let responsePayload = payload;
      if (!responsePayload) {
        responsePayload = 'not availble';
      }
      return {
        ...state,
        orgPreference: {
          ...state.orgPreference,
          success: true,
          loading: false,
          data: responsePayload,
          error: error === undefined ? false : error,
        },
      };
    case HYPERTEST_ONBOARDING_ENABLE_HYPEREXECUTE_PLAN_LOADING:
      return {
        ...state,
        enableHypertestPlan: { ...state.enableHypertestPlan, loading: true },
      };

    case HYPERTEST_ONBOARDING_ENABLE_HYPEREXECUTE_PLAN_SUCCESS:
      return {
        ...state,
        enableHypertestPlan: {
          ...state.enableHypertestPlan,
          loading: false,
          data: payload,
          error: error === undefined ? false : error,
        },
      };
    default:
      return state;
  }
}
