import React from 'react';

const Ignored = ({ width = 20, height = 20, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="presentation"
      className={className}
      {...rest}
    >
      <circle cx="9.99997" cy="10" r="8.88889" fill="#666666" />
      <path
        d="M6.38892 13.3333L9.72225 9.99999L6.38892 6.66666"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8334 13.3333L14.1667 9.99999L10.8334 6.66666"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Ignored;
