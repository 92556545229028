import React from 'react';

const Pending = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <circle
        cx="18.0077"
        cy="18.0077"
        r="18"
        transform="rotate(-0.024375 18.0077 18.0077)"
        fill="#A3ACBC"
      />
      <path
        d="M17.6157 14L17.5683 14C17.2526 14 17 14.32 17 14.72L17 19.44C17 19.79 17.1421 20.12 17.3868 20.3L20.6625 22.79C20.9309 22.99 21.2782 22.89 21.436 22.55C21.4757 22.469 21.502 22.3788 21.5136 22.2846C21.5251 22.1905 21.5215 22.0943 21.5031 22.002C21.4847 21.9096 21.4518 21.8228 21.4064 21.7469C21.361 21.671 21.3039 21.6074 21.2387 21.56L18.184 19.26L18.184 14.72C18.184 14.32 17.9314 14 17.6157 14Z"
        fill="white"
      />
      <circle cx="18" cy="18" r="11.25" stroke="white" strokeWidth="1.5" />
    </svg>
  );
};

export { Pending };
