import React from 'react';

const CheckedCircle = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="7.9979" cy="7.9987" r="6.66667" fill="#165C26" />
      <path
        d="M5.5 7.9974L7.20443 9.99726C7.24237 10.0418 7.31058 10.0434 7.35057 10.0007L11.1818 5.91406"
        stroke="white"
        strokeWidth="1.47059"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CheckedCircle;
