import React from 'react';

const PJDate = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M2.90909 2.875H13.0909C13.8353 2.875 14.375 3.4352 14.375 4.05V12.45C14.375 13.0648 13.8353 13.625 13.0909 13.625H2.90909C2.16475 13.625 1.625 13.0648 1.625 12.45V4.05C1.625 3.43521 2.16475 2.875 2.90909 2.875Z"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 6.25L14 6.25"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M4 3.5V1.5"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3.5V1.5"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PJDate;
