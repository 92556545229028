import React from 'react';

const OnboardWindow = ({
  width = 14,
  height = 14,
  className,
  color = '#666666',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      className={className}
      {...rest}
      version="1.0"
      role="img"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333344 11.8283L5.79775 12.5839V7.36908H0.333344V11.8283ZM0.333344 6.69498H5.79775V1.41351L0.333344 2.16905V6.69498ZM6.39847 12.6654L13.6667 13.6654V7.36908H6.39847V12.6654ZM6.39847 6.69498H13.6667V0.332031L6.39847 1.33202V6.69498Z"
        fill={color}
      />
    </svg>
  );
};

export { OnboardWindow };
