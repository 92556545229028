import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './reducers/index';
import api from './middleware/api';

const composeEnhancers = composeWithDevTools({ trace: true, limit: 4 }); // <<< set options here

const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(api)
    // other store enhancers if any
  )
);

export default store;
