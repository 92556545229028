import React from 'react';

const Error = ({ className, height = '9', width = '9' }) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 18 18"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M9 1.04993C7.41775 1.04993 5.87103 1.51912 4.55544 2.39817C3.23985 3.27722 2.21447 4.52665 1.60897 5.98846C1.00347 7.45027 0.84504 9.0588 1.15372 10.6106C1.4624 12.1625 2.22433 13.588 3.34315 14.7068C4.46197 15.8256 5.88743 16.5875 7.43928 16.8962C8.99113 17.2049 10.5997 17.0465 12.0615 16.441C13.5233 15.8355 14.7727 14.8101 15.6518 13.4945C16.5308 12.1789 17 10.6322 17 9.04993C17 6.92819 16.1571 4.89336 14.6569 3.39307C13.1566 1.89278 11.1217 1.04993 9 1.04993ZM8.3 4.39993C8.3 4.21428 8.37375 4.03623 8.50503 3.90495C8.6363 3.77368 8.81435 3.69993 9 3.69993C9.18566 3.69993 9.3637 3.77368 9.49498 3.90495C9.62625 4.03623 9.7 4.21428 9.7 4.39993V10.3999C9.7 10.5856 9.62625 10.7636 9.49498 10.8949C9.3637 11.0262 9.18566 11.0999 9 11.0999C8.81435 11.0999 8.6363 11.0262 8.50503 10.8949C8.37375 10.7636 8.3 10.5856 8.3 10.3999V4.39993ZM9 14.2999C8.822 14.2999 8.64799 14.2471 8.49999 14.1482C8.35199 14.0494 8.23663 13.9088 8.16851 13.7443C8.10039 13.5799 8.08257 13.3989 8.1173 13.2243C8.15202 13.0498 8.23774 12.8894 8.36361 12.7635C8.48947 12.6377 8.64984 12.5519 8.82442 12.5172C8.99901 12.4825 9.17997 12.5003 9.34442 12.5684C9.50887 12.6366 9.64943 12.7519 9.74833 12.8999C9.84722 13.0479 9.9 13.2219 9.9 13.3999C9.9 13.6386 9.80518 13.8675 9.6364 14.0363C9.46762 14.2051 9.2387 14.2999 9 14.2999Z"
        fill="#FF5050"
      />
    </svg>
  );
};

export { Error };
