export const Path = {
  ROOT: '/',
  ARTIFACT: {
    Path: '/artefact/view',
    WithParam: '/artefact/view/:jobId',
  },
  DESIGN_SYSTEM: '/design-system',
  HYPEREXECUTE: '/hyperexecute',
  INSIGHTS: {
    Path: '/hyperexecute/insights',
    WithParam: '/hyperexecute/insights/:type',
  },
  INSIGHTS_OVERVIEW: '/hyperexecute/insights/overview',
  JOBS: '/hyperexecute/jobs',
  ONBOARD: '/onboard',
  PRIVACY: '/privacy',
  PROJECT_LISTING: '/hyperexecute/projects',
  PROJECT_DETAILS: '/hyperexecute/projects/detail',
  QUICK_START: '/quickstart',
  QUICK_START_QUICK_RUN: {
    Path: '/quickstart/quick-run',
    WithParam: '/quickstart/quick-run/:frameworkName',
  },
  QUICK_START_CONFIGURE_TEST: {
    Path: '/quickstart/sample',
    WithParam: '/quickstart/sample/:frameworkName',
  },
  QUICK_START_YAML_BASED_TEST: {
    Path: '/quickstart/generateYAML',
    WithParam: '/quickstart/generateYAML/:frameworkName',
  },
  WORKFLOW: '/hyperexecute/workflows',
  TASK: '/hyperexecute/task',
  TRY_NOW: '/hyperexecute/try-now',
};
