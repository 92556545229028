const FullScreenArrow = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <rect
        x="0.999878"
        y="1"
        width="24"
        height="24"
        rx="4"
        stroke="#999999"
        strokeWidth="0.8"
      />
      <path
        d="M14.7142 7.85742H18.1428M18.1428 7.85742V11.286M18.1428 7.85742L14.4285 11.5717M11.2856 18.1431H7.85706M7.85706 18.1431V14.7146M7.85706 18.1431L9.7142 16.286L11.5713 14.4289"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { FullScreenArrow };
