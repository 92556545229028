import React from 'react';

const Failed = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="21" cy="21" r="19.5" stroke="#F45C47" strokeWidth="3" />
      <path
        d="M29 29L13 13M29 13L13 29"
        stroke="#F45C47"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Failed;
