export const HYPERTEST_ASSOCIATED_TEST_ACTIONS_REQUEST =
  'HYPERTEST_ASSOCIATED_TEST_ACTIONS_REQUEST';
export const HYPERTEST_ASSOCIATED_TEST_ACTIONS_RESPONSE =
  'HYPERTEST_ASSOCIATED_TEST_ACTIONS_RESPONSE';
export const HYPERTEST_ASSOCIATED_TEST_DETAIL_ACTIONS_REQUEST =
  'HYPERTEST_ASSOCIATED_TEST_DETAIL_ACTIONS_REQUEST';
export const HYPERTEST_ASSOCIATED_TEST_DETAIL_ACTIONS_RESPONSE =
  'HYPERTEST_ASSOCIATED_TEST_DETAIL_ACTIONS_RESPONSE';
export const RESET_HYPERTEST_ASSOCIATED_TEST_DETAIL =
  'RESET_HYPERTEST_ASSOCIATED_TEST_DETAIL';
export const HYPERTEST_ASSOCIATED_TEST_DETAIL_MORE_ACTIONS_REQUEST =
  'HYPERTEST_ASSOCIATED_TEST_DETAIL_MORE_ACTIONS_REQUEST';
export const HYPERTEST_ASSOCIATED_TEST_DETAIL_MORE_ACTIONS_RESPONSE =
  'HYPERTEST_ASSOCIATED_TEST_DETAIL_MORE_ACTIONS_RESPONSE';
export const HYPERTEST_ASSOCIATED_TEST_DETAIL_REAL_DEVICE_ACTIONS_RESPONSE =
  'HYPERTEST_ASSOCIATED_TEST_DETAIL_REAL_DEVICE_ACTIONS_RESPONSE';
