import React from 'react';

const Cancelled = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <circle cx="18" cy="18.0153" r="18" fill="#FF964A" />
      <path
        d="M18 9.26532C13.168 9.26532 9.25 13.1833 9.25 18.0153C9.25 22.8474 13.168 26.7653 18 26.7653C22.832 26.7653 26.75 22.8474 26.75 18.0153C26.75 13.1833 22.832 9.26532 18 9.26532ZM18 25.2809C13.9883 25.2809 10.7344 22.027 10.7344 18.0153C10.7344 16.277 11.3457 14.6794 12.3652 13.4294L22.5859 23.6501C21.3359 24.6696 19.7383 25.2809 18 25.2809ZM23.6348 22.6013L13.4141 12.3806C14.6641 11.361 16.2617 10.7497 18 10.7497C22.0117 10.7497 25.2656 14.0036 25.2656 18.0153C25.2656 19.7536 24.6543 21.3513 23.6348 22.6013Z"
        fill="white"
      />
    </svg>
  );
};

export { Cancelled };
