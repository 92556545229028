import React from 'react';

const PlayLarge = ({ width = '36', height = '42', className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36 42"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#FFF">
          <path
            d="M385.921 274.995l22.724 14.925c2.701 1.775 3.452 5.402 1.677 8.103-.438.667-1.008 1.238-1.675 1.676l-22.74 14.952c-2.701 1.776-6.33 1.026-8.106-1.674-.629-.955-.963-2.074-.963-3.218l.018-29.878c.002-3.231 2.623-5.85 5.855-5.847 1.141 0 2.257.334 3.21.96z"
            transform="translate(-376 -274)"
          />
        </g>
      </g>
    </svg>
  );
};

export default PlayLarge;
