const Bug = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M10.1172 10.3359C10.9071 10.9791 11.375 11.5957 11.375 12.6875"
        stroke="#666666"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.88281 10.3359C3.09285 10.9791 2.625 11.5954 2.625 12.6875"
        stroke="#666666"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 5.6875C11.29 5.04437 11.375 4.15434 11.375 3.0625"
        stroke="#666666"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 5.63281C2.71004 4.98969 2.625 4.15461 2.625 3.0625"
        stroke="#666666"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6875 7.87866H10.5"
        stroke="#666666"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 7.87866H1.3125"
        stroke="#666666"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 5.25V12.25"
        stroke="#666666"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 12.25C5.075 12.25 3.5 10.675 3.5 8.74996V6.12305C3.5 4.34379 5.075 3.49805 7 3.49805C8.925 3.49805 10.5 4.19805 10.5 6.12305V8.74996C10.5 10.675 8.925 12.25 7 12.25Z"
        stroke="#666666"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.90629 3.92438C4.85156 3.78714 4.81987 3.64182 4.8125 3.49426C4.81402 2.91609 5.04437 2.36203 5.4532 1.9532C5.86203 1.54437 6.41609 1.31402 6.99426 1.3125H7.00574C7.58391 1.31402 8.13797 1.54437 8.5468 1.9532C8.95563 2.36203 9.18598 2.91609 9.1875 3.49426C9.18222 3.62859 9.15334 3.76095 9.10219 3.88527"
        stroke="#666666"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Bug;
