export const RCA_LOADING_STATUS = 'RCA_LOADING_STATUS';

export const RCA_FETCH_SUCCESS = 'RCA_FETCH_SUCCESS';

export const RESET_RCA = 'RESET_RCA';

export const RCA_REMEDIATION_FETCH_STATUS = 'RCA_REMEDIATION_FETCH_STATUS';

export const RCA_REMEDIATION_FETCH_SUCCESS = 'RCA_REMEDIATION_FETCH_SUCCESS';

export const RCA_STACK_TRACE_LOADING = 'RCA_STACK_TRACE_LOADING';
export const RCA_STACK_TRACE_LOAD_SUCCESS = 'RCA_STACK_TRACE_LOAD_SUCCESS';
export const RCA_STACK_TRACE_LOAD_ERROR = 'RCA_STACK_TRACE_LOAD_ERROR';
export const RESET_ALL_RCA_ERRORS = 'RESET_ALL_RCA_ERRORS';
