import React from 'react';

const Abort = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle
        cx="21"
        cy="21"
        r="19.5"
        fill="white"
        stroke="#FF964A"
        strokeWidth="3"
      />
      <path
        d="M27 13.5H15C14.6022 13.5 14.2206 13.658 13.9393 13.9393C13.658 14.2206 13.5 14.6022 13.5 15V27C13.5 27.3978 13.658 27.7794 13.9393 28.0607C14.2206 28.342 14.6022 28.5 15 28.5H27C27.3978 28.5 27.7794 28.342 28.0607 28.0607C28.342 27.7794 28.5 27.3978 28.5 27V15C28.5 14.6022 28.342 14.2206 28.0607 13.9393C27.7794 13.658 27.3978 13.5 27 13.5Z"
        fill="#FF964A"
      />
    </svg>
  );
};

export default Abort;
