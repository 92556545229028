import React from 'react';

const Running = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <circle
        cx="10"
        cy="9.99998"
        r="9"
        transform="rotate(-0.024375 10 9.99998)"
        fill="#409FF6"
      />
      <path
        d="M16.0001 10C16.0001 8.81334 15.6482 7.6533 14.9889 6.66661C14.3297 5.67991 13.3926 4.91088 12.2962 4.45675C11.1999 4.00263 9.99347 3.88381 8.82958 4.11532C7.66569 4.34683 6.5966 4.91827 5.75748 5.75739C4.91837 6.59651 4.34692 7.6656 4.11541 8.82949C3.8839 9.99337 4.00272 11.1998 4.45684 12.2961C4.91097 13.3925 5.68001 14.3296 6.6667 14.9888C7.6534 15.6481 8.81343 16 10.0001 16"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { Running };
