export function updateAriaLive(updatedText) {
  // aria live element is added in index.html
  const ariaLiveElem = document.getElementById('lt-aria-live');
  if (ariaLiveElem) {
    ariaLiveElem.innerText = updatedText || '';
  } else {
    console.error('Aria Live Element Missing');
  }
}

export function addRoleToModalClose() {
  setTimeout(() => {
    const dialog = document.getElementsByClassName('lt-modal-wrapper');

    [...dialog].forEach((d) => {
      const button = d.getElementsByTagName('button');

      [...button].forEach((b) => {
        if (b.getAttribute('aria-label') === 'Close') {
          const svg = b.getElementsByTagName('svg')[0];
          svg.setAttribute('role', 'img');
          svg.setAttribute('aria-hidden', true);
        }
      });
    });
  }, 200);
}
