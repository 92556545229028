import React from 'react';

const ViewJobs = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M2.75 8.95635V3.2501V2.75L3.25 2.7501H12.75L13.25 2.7501V3.2501V12.7501V13.2501H12.75H8.475"
        stroke="#666666"
        strokeLinecap="square"
      />
      <path d="M8 9.5L4 13.5L2 11.5" stroke="#666666" strokeLinecap="square" />
    </svg>
  );
};

export default ViewJobs;
