import React from 'react';

const UnknownGray = ({ width = '12', height = '12', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="8" cy="8" r="8" fill="#666666" />
      <path
        d="M7.99997 12C8.47335 12 8.85711 11.6162 8.85711 11.1428C8.85711 10.6695 8.47335 10.2857 7.99997 10.2857C7.52658 10.2857 7.14282 10.6695 7.14282 11.1428C7.14282 11.6162 7.52658 12 7.99997 12Z"
        fill="white"
      />
      <path
        d="M8 8.57143V8C8.39556 8 8.78224 7.8827 9.11114 7.66294C9.44004 7.44318 9.69638 7.13082 9.84776 6.76537C9.99913 6.39992 10.0387 5.99778 9.96157 5.60982C9.8844 5.22186 9.69392 4.86549 9.41421 4.58579C9.13451 4.30608 8.77814 4.1156 8.39018 4.03843C8.00222 3.96126 7.60009 4.00087 7.23463 4.15224C6.86918 4.30362 6.55682 4.55996 6.33706 4.88886C6.1173 5.21776 6 5.60444 6 6"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UnknownGray;
