export const Role = {
  USER: 'User',
  ADMIN: 'Admin',
};

export const ParallelMessage = {
  OVER_LIMIT:
    'Congrats your offer has been applied. Now you&apos;ve got additional COUNT Parallels firepower for blazing fast speed.',
  UNDER_LIMIT: 'No. of Initiated and Running Tasks',
};

export const ExcludeOnPremURLS = [
  'https://dashboard.hyperexecute.cloud',
  'https://stage-dashboard.hyperexecute.cloud',
  'https://beta-dashboard.hyperexecute.cloud',
];

if (window?._env_?.REACT_APP_EXCLUDE_URL) {
  ExcludeOnPremURLS.push(window._env_.REACT_APP_EXCLUDE_URL);
}
