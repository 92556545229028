import React from 'react';

import { IconButton } from '@primer/react';

import { Variant } from './constants';
import { ToolTipDirection } from '../constants';
import {
  LTTooltip,
  LTButton as BTN,
} from '@lambdatestincprivate/lt-components';

import './style.css';

const Sizes = { SMALL: 'small', MEDIUM: 'medium', LARGE: 'large' };

const LTButton = ({
  block = false, // define if button will take full width of container
  children,
  className,
  disabled = false,
  forwardRef = null,
  isActive = false,
  icon,
  label = '',
  leadingIcon,
  loading = false,
  onKeyUp,
  onClick = () => {},
  role = 'button',
  size = Sizes.MEDIUM,
  textClass,
  trailingIcon,
  toolTipText = '',
  toolTipAriaHidden = false,
  toolTipDirection = 'top', //'n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w' | 'nw'
  variant = Variant.DEFAULT,
  ...rest
}) => {
  const classNameString = `${className} lt-hyp-btn-${variant}`;

  let buttonVariant = variant;

  const DEFAULT_VARIANTS = [
    Variant.INVISIBLE,
    Variant.SECONDARY,
    Variant.TERTIARY,
  ];

  if (DEFAULT_VARIANTS.includes(variant)) {
    buttonVariant = 'default';
  }

  const isEnterOrSpacePressed = (event) => {
    if (event?.key === 'Enter') {
      event?.preventDefault();
      props.onKeyUp ? props.onKeyUp() : onClick();
    }
  };

  let props = {
    block: block,
    className: classNameString,
    disabled: disabled,
    loading: loading,
    onKeyUp: isEnterOrSpacePressed,
    onClick: onClick,
    ref: forwardRef,
    role: role,
    size: size,
    variant: buttonVariant,
  };

  if (leadingIcon) {
    props = { ...props, leadingIcon: leadingIcon };
  }

  if (trailingIcon) {
    props = { ...props, trailingIcon: () => <span>{trailingIcon}</span> };
  }

  const showText = !!children || !!label;

  let buttonComponent = (
    <BTN {...props} {...rest} disabled={disabled || !!loading}>
      {showText && (
        <span className={textClass}>{label ? label : children}</span>
      )}
    </BTN>
  );

  if (toolTipText) {
    buttonComponent = (
      <LTTooltip text={toolTipText} direction={toolTipDirection}>
        <BTN {...props} {...rest}>
          {showText && (
            <span className={textClass}>{label ? label : children}</span>
          )}
        </BTN>
      </LTTooltip>
    );
  }

  if (icon) {
    const IconJsx = () => <span>{icon}</span>;
    buttonComponent = (
      <LTTooltip
        text={toolTipText}
        direction={toolTipDirection}
        className={!toolTipText && 'empty-tooltip'}
      >
        <IconButton icon={IconJsx} {...props} {...rest} />
      </LTTooltip>
    );
  }
  return buttonComponent;
};

LTButton.Variant = Variant;
LTButton.Size = Sizes;
LTButton.ToolTipDirection = ToolTipDirection;

export default LTButton;
