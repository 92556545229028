const GenerateYamlIcon = ({ width = 16, height = 16, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
    >
      <g clipPath="url(#clip0_1643_3626)">
        <path
          d="M4.80043 9.60156H5.57516L6.03081 10.4119L6.48646 9.60156H7.25643L6.37862 10.9962V12.0014H5.6795V10.9962L4.80023 9.60156H4.80043ZM8.57331 11.6052H7.77851L7.6692 12.0014H6.95647L7.80484 9.60157H8.56543L9.41436 12.0014H8.68434L8.57331 11.6052ZM8.4272 11.0862L8.17747 10.2235L7.92941 11.0862H8.4272ZM9.62978 9.60156H10.5475L10.9017 11.0615L11.2545 9.60156H12.1691V12.0014H11.5994V10.1714L11.1573 12.0014H10.6417L10.2013 10.1714V12.0014H9.62994V9.60156H9.62978ZM12.6138 9.60156H13.3117V11.4104H14.4002V12.0016H12.6137L12.6138 9.60156Z"
          fill="#666666"
        />
        <path
          d="M5.18049 8H14.0199C14.5649 8 15.0599 8.21614 15.4188 8.56452C15.7776 8.9129 16.0002 9.39354 16.0002 9.92271V11.6773C16.0002 12.2065 15.7776 12.6871 15.4188 13.0355C15.06 13.3839 14.5649 13.6 14.0199 13.6H5.18049C4.63547 13.6 4.14045 13.3839 3.78162 13.0355C3.42279 12.6871 3.2002 12.2065 3.2002 11.6773V9.92271C3.2002 9.39354 3.42281 8.91291 3.78162 8.56452C4.14044 8.21613 4.63547 8 5.18049 8ZM14.0199 8.70549H5.18049C4.8361 8.70549 4.52266 8.84253 4.29533 9.06328C4.06801 9.28404 3.92683 9.58835 3.92683 9.9227V11.6773C3.92683 12.0117 4.06797 12.316 4.29533 12.5367C4.5227 12.7574 4.83612 12.8945 5.18049 12.8945H14.0199C14.3643 12.8945 14.6777 12.7574 14.9051 12.5367C15.1324 12.3159 15.2736 12.0116 15.2736 11.6773V9.9227C15.2736 9.58832 15.1324 9.284 14.9051 9.06328C14.6777 8.84256 14.3643 8.70549 14.0199 8.70549Z"
          fill="#666666"
        />
        <path
          d="M12.8002 13.5C12.8002 13.9288 12.6122 14.4612 12.3092 14.7434C12.0062 15.0256 11.5878 15.2008 11.1276 15.2008H3.27282C2.81244 15.2008 2.39429 15.0257 2.09127 14.7434C1.78827 14.4612 1.60022 14.0716 1.60022 13.643V2.35858C1.60022 1.9298 1.78825 1.54035 2.09127 1.25813C2.39428 0.975924 2.8126 0.800781 3.27282 0.800781H11.1276C11.588 0.800781 12.0062 0.975905 12.3092 1.25813C12.6122 1.54034 12.8002 1.92995 12.8002 2.35858V8.60808H11.859L11.8592 2.65895C11.8592 2.40137 11.7458 2.16691 11.5631 1.99689C11.3804 1.82672 11.1288 1.72114 10.8523 1.72114H3.50671C3.23015 1.72114 2.97842 1.82672 2.79587 1.99689C2.61316 2.16706 2.4998 2.40138 2.4998 2.65895V13.4235C2.4998 13.681 2.61316 13.9155 2.79587 14.0855C2.97858 14.2557 3.23016 14.3613 3.50671 14.3613L10.8523 14.3613C11.1288 14.3613 11.3805 14.2557 11.5631 14.0855C11.7458 13.9154 11.8592 13.5381 11.8592 13.2805L12.8002 13.5Z"
          fill="#666666"
        />
        <path
          d="M4.92956 3.49023C4.79512 3.49023 4.68616 3.65811 4.68616 3.86523C4.68616 4.07236 4.79512 4.24023 4.92956 4.24023H9.44679C9.58123 4.24023 9.6902 4.07236 9.6902 3.86523C9.6902 3.65811 9.58123 3.49023 9.44679 3.49023H4.92956Z"
          fill="#666666"
        />
        <path
          d="M4.92956 5.49023C4.79512 5.49023 4.68616 5.65811 4.68616 5.86523C4.68616 6.07236 4.79512 6.24023 4.92956 6.24023H9.44679C9.58123 6.24023 9.6902 6.07236 9.6902 5.86523C9.6902 5.65811 9.58123 5.49023 9.44679 5.49023H4.92956Z"
          fill="#666666"
        />
      </g>
      <defs>
        <clipPath id="clip0_1643_3626">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GenerateYamlIcon;
