const HyperExecuteCliIcon = ({
  width = 23,
  height = 15,
  className,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M4.02717 7.18356C3.79227 6.93881 3.41187 6.93881 3.17697 7.18356C2.94227 7.42831 2.94227 7.82522 3.17697 8.06996L4.55391 9.49939L3.17718 10.9288L3.17698 10.929C3.06374 11.0463 3 11.2058 3 11.3721C3 11.5387 3.06374 11.6982 3.17698 11.8154C3.28942 11.9335 3.44254 12 3.60209 12C3.76163 12 3.91475 11.9335 4.02719 11.8154L5.82302 9.94271C5.93626 9.82545 6 9.66598 6 9.49939C6 9.33302 5.93626 9.17355 5.82302 9.05629L4.02717 7.18356Z"
        fill="url(#paint0_linear_15324_107541)"
      />
      <path
        d="M11.2499 11H6.74984C6.33574 11 6 11.2238 6 11.4999C6 11.7762 6.33575 12 6.74984 12H11.2499C11.6642 12 12 11.7762 12 11.4999C12 11.2238 11.6642 11 11.2499 11Z"
        fill="url(#paint1_linear_15324_107541)"
      />
      <path
        d="M20.6868 0.767578H2.31403C1.87377 0.767578 1.45134 0.942126 1.13944 1.25317C0.827706 1.56403 0.651763 1.98579 0.650391 2.42621V12.5424C0.651775 12.9828 0.827706 13.4046 1.13944 13.7154C1.45134 14.0265 1.87378 14.201 2.31403 14.201H20.6868C21.1271 14.201 21.5495 14.0265 21.8614 13.7154C22.1732 13.4046 22.3491 12.9828 22.3505 12.5424V2.42621C22.3491 1.98578 22.1732 1.56401 21.8614 1.25317C21.5495 0.942135 21.1271 0.767578 20.6868 0.767578ZM21.3172 12.5423C21.3143 12.8884 21.033 13.1674 20.6868 13.1674H2.31403C1.96787 13.1674 1.68659 12.8884 1.68366 12.5423V2.42608C1.68661 2.07992 1.96789 1.8009 2.31403 1.8009H20.6868C21.033 1.8009 21.3143 2.07993 21.3172 2.42608V12.5423Z"
        fill="url(#paint2_linear_15324_107541)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15324_107541"
          x1="3.03674"
          y1="6.95894"
          x2="7.62092"
          y2="9.96417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#224E82" />
          <stop offset="1" stopColor="#0EBAC5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15324_107541"
          x1="6.07348"
          y1="10.9918"
          x2="6.37156"
          y2="12.9459"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#224E82" />
          <stop offset="1" stopColor="#0EBAC5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_15324_107541"
          x1="0.916154"
          y1="0.657254"
          x2="12.4369"
          y2="20.9912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#224E82" />
          <stop offset="1" stopColor="#0EBAC5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HyperExecuteCliIcon;
