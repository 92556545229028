import useRemainingMinutes from 'hooks/useRemainingMinutes';

import './index.css';
import LTButton from 'new-theme/components/LTComponents/LTButton/LTButton';

const CongratulationsModalFooter = ({
  handleContinueTour,
  handleTalkToExpert,
  handleSkip,
  handleContinueTesting,
}) => {
  const { kFormatedMinutes } = useRemainingMinutes();
  return (
    <div className="modal-footer-section">
      <div className="remaining-minutes-container">
        * You now have access to {kFormatedMinutes} minutes of free trial
      </div>
      <div className="footer-button-container">
        <div className="footer-button-primary-container">
          <LTButton
            onClick={handleContinueTour}
            className="modal-footer-button footer-button-primary modal-footer-button-continue-tour"
            variant="primary"
          >
            Continue Tour
          </LTButton>
          <LTButton
            onClick={handleTalkToExpert}
            className="modal-footer-button footer-button-secondary modal-footer-button-expert"
          >
            Talk to an expert
          </LTButton>
          <button
            onClick={handleContinueTesting}
            className="modal-footer-button footer-button-secondary modal-footer-button-continue-testing modal-footer-button-hidden"
          >
            Continue Testing
          </button>
        </div>
        <LTButton
          variant="invisible"
          className="modal-footer-button footer-skip-now-button"
          onClick={handleSkip}
        >
          Skip for now
        </LTButton>
      </div>
    </div>
  );
};

export default CongratulationsModalFooter;
