import React from 'react';

const FailFast = ({ width = '17', height = '16', className = '', ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M7.5625 6.2L1 2V14L7.5625 9.8M7.5625 2V14L16 8L7.5625 2Z"
        stroke="#666666"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { FailFast };
