import React from 'react';

const Failed = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <circle cx="9" cy="9" r="9" fill="#F45C47" />
      <path
        d="M5.71715 11.7172C5.56094 11.8734 5.56095 12.1266 5.71716 12.2828C5.87338 12.4391 6.12664 12.439 6.28285 12.2828L5.71715 11.7172ZM12.2826 6.28284C12.4388 6.12662 12.4388 5.87336 12.2825 5.71715C12.1263 5.56094 11.8731 5.56095 11.7169 5.71716L12.2826 6.28284ZM6.28285 12.2828L12.2826 6.28284L11.7169 5.71716L5.71715 11.7172L6.28285 12.2828Z"
        fill="white"
      />
      <path
        d="M6.28327 5.71716C6.12706 5.56095 5.87379 5.56094 5.71758 5.71715C5.56137 5.87336 5.56136 6.12662 5.71757 6.28284L6.28327 5.71716ZM11.7173 12.2828C11.8735 12.439 12.1267 12.4391 12.283 12.2828C12.4392 12.1266 12.4392 11.8734 12.283 11.7172L11.7173 12.2828ZM5.71757 6.28284L11.7173 12.2828L12.283 11.7172L6.28327 5.71716L5.71757 6.28284Z"
        fill="white"
      />
    </svg>
  );
};

export { Failed };
