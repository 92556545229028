import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from 'utils/history';
import { updateQuickStartStatus } from '../store/actions/hypertestJobDetail';
import {
  setPlanExpiredModal,
  setLimitExhoustedModal,
  setLimitExhoustedThresholdModal,
  updatePlanInfoValue,
  setTrialFeatureModal,
  fetchConsumedMinutes,
  updateCommonUiVisual,
} from '../store/actions/userInfo';
import { getPlanAlertType } from 'utils/amplitude';
import {
  getCurrentViewPage,
  openZohoSalesIqChat,
  sendAmplitudeEvents,
} from '../utils/common';
import { getCookieData, setCookieData } from '../utils/cookieHandler';
import { EventNames } from '../utils/events';
import { Path } from 'constants/routes';
import axios from 'axios';
import { ANALTICS_CONDITION, SAMPLE_REPORT_BODY } from 'constants/common';

const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

const usePlan = (skipApis = false) => {
  const dispatch = useDispatch();
  const visual = useSelector((state) => state.userInfo?.visual);
  const userInfo = useSelector((state) => state.userInfo?.userInfo);
  const commonUiVisual = useSelector((state) => state.userInfo?.commonUIVisual);
  let setting = useSelector((state) => state.appSetting?.setting);
  const consumedTime = useSelector((state) => state.userInfo?.consumedTime);
  const currentTestType = useSelector(
    (state) => state.onboarding?.currentConfiguration?.testType
  );
  const orgPreference = useSelector((state) => state.userInfo?.orgPreference);
  let hypertestJobList = useSelector(
    (state) => state.hypertestJobList?.hypertestJobList
  );
  const isJobListFetchDone = hypertestJobList?.isJobListFetchDone;
  hypertestJobList = hypertestJobList?.data;
  const [fetchingDashboard, setFetchingDashboard] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const ANALYTICS_KEY = `showAnalyticsModal-${userInfo?.data?.id}`;

  // console.log('consumedTime', consumedTime);

  let isTrialUser = false;
  let remainingMinutes = '';
  let isAlert = false;
  let isLimitExhousted = false;
  let isLimitExhoustedThresholdNinty = false;
  let isLimitExhoustedThresholdSixty = false;
  let isLimitExhoustedThresholdTen = false;
  let thresholdValue = 0;
  let isTrialExpired = false;
  let isCustomTrial = false;
  let daysLeft = null;
  let daysPercentage = null;
  let isPaidPlanExpired = false;
  let isPaidUsedPlanAboutToExpire = false;

  let userPlan =
    userInfo &&
    userInfo.data &&
    userInfo.data.organization &&
    userInfo.data.organization.plan_attributes;
  let orgId =
    userInfo.data &&
    userInfo.data.organization &&
    userInfo.data.organization.id;

  if (
    userPlan &&
    userPlan.MAX_HYPEREXECUTE_MINUTES &&
    userPlan.MAX_HYPEREXECUTE_MINUTES !== -1 &&
    !(consumedTime && consumedTime.loading)
  ) {
    isTrialUser = true;
    if (userPlan?.MAX_HYPEREXECUTE_MINUTES !== 300) {
      isCustomTrial = true;
    }
    remainingMinutes =
      parseInt(userPlan?.MAX_HYPEREXECUTE_MINUTES) -
      parseInt(
        (consumedTime && consumedTime.data && consumedTime.data.data) || 0
      );
    // remainingMinutes = 10;
    // to show alert message
    let alertThresholdMinutes = parseInt(
      (60 * parseInt(userPlan?.MAX_HYPEREXECUTE_MINUTES)) / 100
    );
    // console.log('alertThresholdMinutes', alertThresholdMinutes);
    if (remainingMinutes < alertThresholdMinutes) {
      isAlert = true;
    }
    let usedMinutesPercentage = parseInt(
      (parseInt(consumedTime && consumedTime.data && consumedTime.data.data) /
        parseInt(userPlan?.MAX_HYPEREXECUTE_MINUTES)) *
        100
    );
    if (!isCustomTrial) {
      if (usedMinutesPercentage >= 90 && remainingMinutes > 0) {
        // let usedMinutesPercentage = 95;
        isLimitExhoustedThresholdNinty = true;
        thresholdValue = 90;
      } else if (usedMinutesPercentage >= 60 && remainingMinutes > 0) {
        isLimitExhoustedThresholdSixty = true;
        thresholdValue = 60;
      } else if (usedMinutesPercentage >= 10 && remainingMinutes > 0) {
        isLimitExhoustedThresholdTen = true;
        thresholdValue = 10;
      }
    }

    if (remainingMinutes <= 0) {
      if (isTrialUser) {
        isTrialExpired = true;
      } else {
        isPaidPlanExpired = true;
      }
    }

    daysPercentage = usedMinutesPercentage;
  }

  if (daysPercentage >= 90 && !isPaidPlanExpired) {
    isPaidUsedPlanAboutToExpire = true;
  }

  const checkIfDashboardAlreadyExist = async () => {
    const showAnalyticsPopup = localStorage.getItem(ANALYTICS_KEY);
    if (showAnalyticsPopup === 'true') {
      setShowAnalytics(true);
      return;
    }
    let cookieName = process.env.REACT_APP_COOKIE_NAME;
    let access_token = getCookieData(cookieName);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}insights/api/v3/getDashboards?searchText=&limit=20&offset=0`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (
        res?.status === 200 &&
        res?.data?.totalDashboards === ANALTICS_CONDITION.minDashboards &&
        hypertestJobList?.data?.length >= ANALTICS_CONDITION.minJobs &&
        hypertestJobList?.metadata?.hasmore &&
        showAnalyticsPopup == ANALTICS_CONDITION.analyticsPopup
      ) {
        localStorage.setItem(ANALYTICS_KEY, true);
        setShowAnalytics(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      hypertestJobList?.data?.length >= ANALTICS_CONDITION.minJobs &&
      hypertestJobList?.metadata?.hasmore
    ) {
      checkIfDashboardAlreadyExist();
    }
  }, [isJobListFetchDone]);

  useEffect(() => {
    if (orgId && orgPreference?.success && !skipApis) {
      dispatch(
        fetchConsumedMinutes({
          orgId: orgId,
          auth: {
            username: userInfo.data && userInfo.data.username,
            password: userInfo.data && userInfo.data.apiToken,
          },
          sentinel_url: orgPreference?.data?.SENTINEL_HOST,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, orgPreference?.success]);

  useEffect(() => {
    dispatch(setLimitExhoustedModal(isLimitExhousted));
    // dispatch(setLimitExhoustedThresholdModal(isLimitExhoustedThreshold));
    dispatch(
      updatePlanInfoValue({
        thresholdValue,
        planExpired: isTrialExpired,
        isLimitExhoustedThresholdNinty,
        isLimitExhoustedThresholdSixty,
        isLimitExhoustedThresholdTen,
        isTrialUser,
        isPaidPlanExpired,
        isPaidUsedPlanAboutToExpire,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLimitExhousted,
    isLimitExhoustedThresholdNinty,
    isLimitExhoustedThresholdSixty,
    isLimitExhoustedThresholdTen,
    isTrialExpired,
    isTrialUser,
    isPaidPlanExpired,
    isPaidUsedPlanAboutToExpire,
  ]);

  function closePopup(type) {
    setTimeout(function () {
      const e = document.getElementById('license-info-btn');
      e?.focus();
    }, 100);
    switch (type) {
      case 'CLOSE_ANALYTICS_POPUP':
        localStorage.setItem(ANALYTICS_KEY, false);
        setShowAnalytics(false);
        break;
      case 'PLAN_EXPIRED':
        dispatch(setPlanExpiredModal(false));
        dispatch(updatePlanInfoValue({ planExpiredFirstTimeShow: true }));
        break;
      case 'LIMIT_EXHAUSTED':
        dispatch(setLimitExhoustedModal(false));
        dispatch(
          updatePlanInfoValue({ limitExhaustedModalFirstTimeShow: true })
        );
        break;
      case 'LIMIT_EXHAUSTED_THRESHOLD':
        dispatch(setLimitExhoustedThresholdModal(false));
        break;
      case 'TEN_PER_LIMIT_EXHAUSTED_THRESHOLD':
        dispatch(
          updatePlanInfoValue({
            tenPercentageThresholdModalFirstTimeShow: true,
            isLimitExhoustedThresholdSixty: false,
          })
        );
        setCookieData(type, true, {
          path: '/',
          maxAge: 3600000,
          domain: COOKIE_DOMAIN,
        });
        break;
      case 'NINTY_PER_LIMIT_EXHAUSTED_THRESHOLD':
        dispatch(
          updatePlanInfoValue({
            nintyPercentageThresholdModalFirstTimeShow: true,
            isLimitExhoustedThresholdNinty: false,
          })
        );
        setCookieData(type, true, {
          path: '/',
          maxAge: 3600000,
          domain: COOKIE_DOMAIN,
        });
        break;
      case 'SIXTY_PER_LIMIT_EXHAUSTED_THRESHOLD':
        dispatch(
          updatePlanInfoValue({
            sixtyPercentageThresholdModalFirstTimeShow: true,
            isLimitExhoustedThresholdSixty: false,
          })
        );
        setCookieData(type, true, {
          path: '/',
          maxAge: 3600000,
          domain: COOKIE_DOMAIN,
        });
        break;
      case 'TRIAL_FEATURE':
        dispatch(setTrialFeatureModal(false));
        break;
      case 'PAID_PLAN_EXPIRED':
        dispatch(updatePlanInfoValue({ paidPlanExpiredFirstTimeShow: true }));
        break;
      case 'PAID_NINTY_PER_LIMIT_EXHAUSTED_THRESHOLD':
        dispatch(
          updatePlanInfoValue({
            isPaidUsedPlanAboutToExpireFirstTimeShow: true,
            isPaidUsedPlanAboutToExpire: false,
          })
        );
        setCookieData(type, true, {
          path: '/',
          maxAge: 3600000,
          domain: COOKIE_DOMAIN,
        });
        break;

      default:
        break;
    }
  }
  function openTrialFeaturePopUp() {
    dispatch(setTrialFeatureModal(true));
    sendAmplitudeEvents(EventNames.HYP_ONBOARDING_TRIAL_INFO_CLICKED, {
      view: getCurrentViewPage(),
    });
  }
  function handleUpgradeClick(type) {
    dispatch(setTrialFeatureModal(false));
    window.open(process.env.REACT_APP_LUMS_PRICING_URL, '_blank').focus();
    sendAmplitudeEvents(EventNames.HYP_ONBOARDING_UPGRADE_NOW_TRIAL, {
      cta: 'upgrade now',
      destination: process.env.REACT_APP_LUMS_PRICING_URL,
      view: getCurrentViewPage(),
      username: userInfo?.data?.username,
      'trial popup condition': getPlanAlertType(type),
    });
  }
  function handleContactSaleClick(type) {
    return () => {
      sendAmplitudeEvents(EventNames.HYP_ONBOARDING_UPGRADE_NOW_TRIAL, {
        cta: 'contact sales',
        view: getCurrentViewPage(),
        username: userInfo?.data?.username,
        'trial popup condition': getPlanAlertType(type),
      });
      openZohoSalesIqChat();
    };
  }
  function runTestExternal(type) {
    dispatch(updateCommonUiVisual(type));
  }

  useEffect(() => {
    if (commonUiVisual.onboardingJobCreatedSSElisten) {
      let isYamlPage = window.location.href.indexOf('custom') !== -1;
      let isSample = window.location.href.indexOf('sample') !== -1;
      let isQuickRunPage = window.location.href.indexOf('quick-run') !== -1;
      let eventName = EventNames.HYP_ONBOARDING_AUTOMATIC_REDIRECT_SUCCESS;
      if (isYamlPage) {
        eventName = EventNames.HYP_ONBOARDING_REDIRECT_SUCCESS_AUTO_YAML_PAGE;
      }
      if (isSample) {
        eventName = EventNames.HYP_ONBOARDING_REDIRECT_SUCCESS_AUTO_SAMPLE_PAGE;
      }
      sendAmplitudeEvents(eventName);
      if (visual && visual.isNewUser) {
        dispatch(updateQuickStartStatus(true));
        dispatch(
          updatePlanInfoValue({
            isNewUser: false,
          })
        );
        if (!isQuickRunPage) {
          history.push(Path.HYPEREXECUTE);
        }
      } else {
        dispatch(updateQuickStartStatus(false));
        /**
         * in case of quick-run we will go to dashboard by click of goToDashBoard button
         */
        if (currentTestType !== 'quick-run') {
          dispatch(updateCommonUiVisual({ existingUserGoToDashboard: true }));
        }
      }
      dispatch(
        updateCommonUiVisual({
          onboardingJobCreatedSSElisten: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonUiVisual.onboardingJobCreatedSSElisten]);

  async function handleCreateDashboard() {
    setFetchingDashboard(true);
    let cookieName = process.env.REACT_APP_COOKIE_NAME;
    let access_token = getCookieData(cookieName);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}insights/api/v3/dailyreport?templateId=7&dashboard_type=hye_report`,
        SAMPLE_REPORT_BODY,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res?.status === 200) {
        localStorage.setItem(ANALYTICS_KEY, false);
        window.location.href = `${process.env.REACT_APP_ANALYTICS_URL}dashboard/${res?.data?.dashboardId}`;
        setFetchingDashboard(false);
      }
    } catch (err) {
      window.location.href = `${process.env.REACT_APP_ANALYTICS_URL}dashboard`;
      setFetchingDashboard(false);
      localStorage.setItem(ANALYTICS_KEY, false);
      console.log('Error Occured while fetching analytics url');
    }
  }

  return {
    closePopup,
    visual,
    openTrialFeaturePopUp,
    isAlert,
    isTrialExpired,
    isLimitExhousted,
    isTrialUser,
    remainingMinutes,
    handleUpgradeClick,
    isCustomTrial,
    daysLeft,
    userPlan,
    daysPercentage,
    commonUiVisual,
    runTestExternal,
    userData: userInfo && userInfo.data,
    promoBannerHeight: setting.promoBannerHeight,
    handleContactSaleClick,
    showAnalytics,
    handleCreateDashboard,
    fetchingDashboard,
  };
};

export default usePlan;
