import React from 'react';

const PJPlay = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1ZM2.3125 8C2.3125 9.50842 2.91172 10.9551 3.97833 12.0217C5.04494 13.0883 6.49158 13.6875 8 13.6875C9.50842 13.6875 10.9551 13.0883 12.0217 12.0217C13.0883 10.9551 13.6875 9.50842 13.6875 8C13.6875 6.49158 13.0883 5.04494 12.0217 3.97833C10.9551 2.91172 9.50842 2.3125 8 2.3125C6.49158 2.3125 5.04494 2.91172 3.97833 3.97833C2.91172 5.04494 2.3125 6.49158 2.3125 8ZM6.58162 5.57363L10.3126 7.81275C10.3449 7.83222 10.3715 7.85969 10.39 7.8925C10.4086 7.9253 10.4183 7.96233 10.4183 8C10.4183 8.03767 10.4086 8.0747 10.39 8.1075C10.3715 8.14031 10.3449 8.16778 10.3126 8.18725L6.58162 10.4264C6.54845 10.4464 6.51056 10.4572 6.47183 10.4577C6.4331 10.4583 6.39492 10.4485 6.3612 10.4295C6.32747 10.4104 6.2994 10.3828 6.27987 10.3493C6.26033 10.3159 6.25002 10.2779 6.25 10.2391V5.76175C6.24987 5.72294 6.26006 5.6848 6.27953 5.65123C6.29901 5.61767 6.32706 5.58988 6.36081 5.57074C6.39457 5.55159 6.43281 5.54176 6.47161 5.54227C6.51041 5.54278 6.54838 5.5536 6.58162 5.57363Z"
        fill="white"
      />
    </svg>
  );
};

export default PJPlay;
