import React from 'react';

const PJCompleted = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M3 8L6.33796 11.9165C6.37589 11.961 6.4441 11.9627 6.4841 11.92L13.9091 4"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PJCompleted;
