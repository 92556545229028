import React from 'react';

const SearchIcon = ({ width = 16, height = 16, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.00008 3.99998C1.00008 2.34548 2.34558 0.999979 4.00008 0.999979C5.65458 0.999979 7.00008 2.34548 7.00008 3.99998C7.00008 5.65448 5.65458 6.99998 4.00008 6.99998C2.34558 6.99998 1.00008 5.65448 1.00008 3.99998ZM8.8535 8.1465L7.156 6.4485C7.6825 5.7715 8 4.923 8 4C8 1.7945 6.2055 0 4 0C1.7945 0 0 1.7945 0 4C0 6.2055 1.7945 8 4 8C4.923 8 5.7715 7.6825 6.4485 7.156L8.1465 8.8535C8.244 8.951 8.372 9 8.5 9C8.628 9 8.756 8.951 8.8535 8.8535C9.049 8.658 9.049 8.342 8.8535 8.1465Z"
        fill="#666666"
        className="lt-search-ic-path"
      />
    </svg>
  );
};

export { SearchIcon };
