const HyperExecuteYamlCli = ({
  width = 16,
  height = 16,
  className,
  color = '#999999',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      className={className}
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M1.77777 3.16602C1.77777 2.88987 2.00163 2.66602 2.27777 2.66602H13.7222C13.9984 2.66602 14.2222 2.88987 14.2222 3.16602V12.8327C14.2222 13.1088 13.9984 13.3327 13.7222 13.3327H2.27777C2.00163 13.3327 1.77777 13.1088 1.77777 12.8327V3.16602Z"
        stroke="#666666"
        strokeWidth="1.25"
        stroke-miterlimit="10"
      />
      <path
        d="M5.33331 9.77821L7.11109 8.00043L5.33331 6.22266"
        stroke="#666666"
        strokeWidth="1.25"
        stroke-miterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.77777 8L11.5555 8"
        stroke="#666666"
        strokeWidth="1.25"
        stroke-miterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HyperExecuteYamlCli;
