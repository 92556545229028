import React from 'react';

const Running = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 22 22"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M20.9974 11.0036C20.9974 9.02659 20.4111 7.09401 19.3128 5.45022C18.2144 3.80643 16.6533 2.52525 14.8268 1.76869C13.0003 1.01214 10.9905 0.81419 9.05153 1.19988C7.11255 1.58557 5.33148 2.53757 3.93355 3.9355C2.53562 5.33343 1.58361 7.1145 1.19792 9.05349C0.812237 10.9925 1.01019 13.0023 1.76674 14.8288C2.5233 16.6553 3.80448 18.2164 5.44827 19.3147C7.09206 20.4131 9.02464 20.9993 11.0016 20.9993"
        stroke="#409FF6"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Running;
