import React from 'react';

const CheckBoldIcon = ({ width = 12, height = 12, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M4.49953 10.2095L1.39453 7.10445L2.80953 5.68945L4.49953 7.38445L9.43953 2.43945L10.8545 3.85445L4.49953 10.2095Z"
        fill="#58BF86"
      />
    </svg>
  );
};

export { CheckBoldIcon };
