import React from 'react';

const Insight = ({ width = 17, height = 16, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      {...rest}
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M2.66667 15.0709C2.04444 15.0709 1.5 14.5497 1.5 13.9042C1.5 13.2586 2.04444 12.7375 2.66667 12.7375C2.97778 12.7375 3.28859 12.8697 3.52192 13.0797C3.67747 13.2897 3.83333 13.5853 3.83333 13.9042C3.83333 14.5497 3.28889 15.0709 2.66667 15.0709ZM6.55556 8.84863C7.17778 8.84863 7.72222 9.36974 7.72222 10.0153C7.72222 10.3342 7.56636 10.6298 7.41081 10.8398C7.17747 11.0498 6.86667 11.182 6.55556 11.182C6.24444 11.182 5.93364 11.0498 5.7003 10.8398C5.54475 10.6298 5.38889 10.3342 5.38889 10.0153C5.38889 9.36974 5.93333 8.84863 6.55556 8.84863ZM10.4444 15.0709C9.82222 15.0709 9.27778 14.5497 9.27778 13.9042C9.27778 13.5853 9.43364 13.2897 9.58919 13.0797C9.82253 12.8697 10.1333 12.7375 10.4444 12.7375C10.7556 12.7375 11.0664 12.8697 11.2997 13.0797C11.4553 13.2897 11.6111 13.5853 11.6111 13.9042C11.6111 14.5497 11.0667 15.0709 10.4444 15.0709ZM14.3333 11.182C14.0222 11.182 13.7114 11.0498 13.4781 10.8398C13.3225 10.6298 13.1667 10.3342 13.1667 10.0153C13.1667 9.36974 13.7111 8.84863 14.3333 8.84863C14.9556 8.84863 15.5 9.36974 15.5 10.0153C15.5 10.6609 14.9556 11.182 14.3333 11.182Z"
        stroke="white"
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 13.0769L5.67661 10.8389L3.5 13.0769ZM7.38571 10.8389L9.56232 13.0769L7.38571 10.8389ZM11.2714 13.0769L13.448 10.8389L11.2714 13.0769Z"
        fill="white"
      />
      <path
        d="M3.5 13.0769L5.67661 10.8389M7.38571 10.8389L9.56232 13.0769M11.2714 13.0769L13.448 10.8389"
        stroke="white"
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M2.67857 2.79928C2.63571 2.9 2.6142 2.95143 2.59277 2.99429C2.57134 3.03286 2.52849 3.06714 2.48563 3.09285C2.44277 3.12499 2.39983 3.14429 2.29269 3.18501L1.5 3.5L2.29269 3.81499C2.39983 3.85571 2.44277 3.87501 2.48563 3.90715C2.52849 3.93286 2.57134 3.96714 2.59277 4.00571C2.6142 4.04857 2.63571 4.1 2.67857 4.20072L3 5L3.32143 4.20072C3.36429 4.1 3.3858 4.04857 3.40723 4.00571C3.42866 3.96714 3.47151 3.93286 3.51437 3.90715C3.55723 3.87501 3.60017 3.85571 3.70731 3.81499L4.5 3.5L3.70731 3.18501C3.60017 3.14429 3.55723 3.12499 3.51437 3.09285C3.47151 3.06714 3.42866 3.03286 3.40723 2.99429C3.3858 2.95143 3.36429 2.9 3.32143 2.79928L3 2L2.67857 2.79928Z"
        stroke="white"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9286 1.54928C13.8857 1.65 13.8642 1.70143 13.8428 1.74429C13.8213 1.78286 13.7785 1.81714 13.7356 1.84285C13.6928 1.87499 13.6498 1.89429 13.5427 1.93501L12.75 2.25L13.5427 2.56499C13.6498 2.60571 13.6928 2.625 13.7356 2.65715C13.7785 2.68286 13.8213 2.71714 13.8428 2.75571C13.8642 2.79857 13.8857 2.85 13.9286 2.95072L14.25 3.75L14.5714 2.95072C14.6143 2.85 14.6358 2.79857 14.6572 2.75571C14.6787 2.71714 14.7215 2.68286 14.7644 2.65715C14.8072 2.625 14.8502 2.60571 14.9573 2.56499L15.75 2.25L14.9573 1.93501C14.8502 1.89429 14.8072 1.87499 14.7644 1.84285C14.7215 1.81714 14.6787 1.78286 14.6572 1.74429C14.6358 1.70143 14.6143 1.65 14.5714 1.54928L14.25 0.75L13.9286 1.54928Z"
        stroke="white"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.26786 3.94892C8.20357 4.09999 8.1713 4.17715 8.13916 4.24143C8.10702 4.29929 8.04273 4.35071 7.97845 4.38928C7.91416 4.43749 7.84975 4.46644 7.68903 4.52751L6.5 5L7.68903 5.47249C7.84975 5.53356 7.91416 5.56251 7.97845 5.61072C8.04273 5.64929 8.10702 5.70071 8.13916 5.75857C8.1713 5.82285 8.20357 5.90001 8.26786 6.05108L8.75 7.25L9.23214 6.05108C9.29643 5.90001 9.3287 5.82285 9.36084 5.75857C9.39298 5.70071 9.45727 5.64929 9.52155 5.61072C9.58584 5.56251 9.65025 5.53356 9.81097 5.47249L11 5L9.81097 4.52751C9.65025 4.46644 9.58584 4.43749 9.52155 4.38928C9.45727 4.35071 9.39298 4.29929 9.36084 4.24143C9.3287 4.17715 9.29643 4.09999 9.23214 3.94892L8.75 2.75L8.26786 3.94892Z"
        stroke="white"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Insight;
