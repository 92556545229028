import { CALL_API } from '../middleware/api';
import * as action from '../constants/rca';
import {
  getReceptionApi,
  getLogisticsApi,
} from 'new-theme/utils/onpremEndpoints';

export const resetRCA = () => {
  return {
    type: action.RESET_RCA,
  };
};

export const resetAllRCAErrors = () => {
  return {
    type: action.RESET_ALL_RCA_ERRORS,
  };
};

export const updateStackTraceLoading = (payload) => {
  return {
    type: action.RCA_STACK_TRACE_LOADING,
    payload,
  };
};

export const updateStackTraceSuccess = (payload) => {
  return {
    type: action.RCA_STACK_TRACE_LOAD_SUCCESS,
    payload,
  };
};

export const updateStackTraceError = (payload) => {
  return {
    type: action.RCA_STACK_TRACE_LOAD_ERROR,
    payload,
  };
};

// export const updateSelectedRepo = (selectedRepo) => {
//   return {
//     type: action.HYP_INSIGHT_OVERVIEW_UPDATE_SELECTED_REPO,
//     payload: selectedRepo,
//   };
// };

// export const fetchRepo = (auth) => {
//   let sentinelUrl = HYPERTEST_SENTINEL_API_ENDPOINT;
//   return {
//     [CALL_API]: {
//       types: [
//         action.HYP_INSIGHT_SET_REPO_LOADING_TO_TRUE,
//         action.HYP_INSIGHT_OVERVIEW_UPDATE_REPOS,
//       ],
//       endpoint: `${sentinelUrl}/v1.0/repo`,
//       method: 'GET',
//       appendBaseUrl: false,
//       // headers: {
//       //   Authorization:
//       //     'Basic ' +
//       //     'dmlrcmFudGt1bWFyczpJSk5GNGQ2d2pZcHd2aXMxQ3BMNlpXdjE2MVU4bm1FM0F5cHdyRDhLVFFTNWZvczE1UQ==',
//       // },
//       type: 'json',
//       auth,
//     },
//   };
// };

let projectDetailController;

export const fetchRCA = ({ auth, order, iteration, task }) => {
  let logisticsUrl = getLogisticsApi();

  let endpoint = `${logisticsUrl}/v1.0/categorizederrors?taskId=${task}&order=${order}&iteration=${iteration}`;

  let types = ['', action.RCA_FETCH_SUCCESS];

  if (projectDetailController) {
    projectDetailController.abort();
  }
  projectDetailController = new AbortController();

  return {
    [CALL_API]: {
      types,
      endpoint,
      method: 'GET',
      appendBaseUrl: false,
      extra: { key: `${order}${iteration}` },
      // signal: projectDetailController.signal,
      type: 'json',
      auth,
    },
  };
};

export const fetchRemediation = ({
  auth,
  errorIndex,
  params,
  stackTrace,
  order,
  iteration,
}) => {
  let endpoint = `${getReceptionApi()}/categorizederrors/rca`;

  let types = [
    action.RCA_REMEDIATION_FETCH_STATUS,
    action.RCA_REMEDIATION_FETCH_SUCCESS,
  ];

  if (projectDetailController) {
    projectDetailController.abort();
  }
  projectDetailController = new AbortController();

  const { stacktraceFileLink, ...rest } = params;

  return {
    [CALL_API]: {
      types,
      endpoint,
      method: 'POST',
      appendBaseUrl: false,
      extra: { errorIndex, order, iteration },
      signal: projectDetailController.signal,
      type: 'json',
      auth,
      body: { ...rest, stacktrace: stackTrace?.stackTrace },
    },
  };
};
