import React from 'react';

const ExpandExternal = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 10 10"
      role="presentaion"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M7.16377 6.18144L7.19531 1.10352L2.11738 1.07197M7.19531 1.10352L1.06395 7.15918L7.19531 1.10352Z"
        stroke={color ? color : '#0EBAC5'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { ExpandExternal };
