import { HYP_SET_ORG_PREFERENCES } from '../constants/preference';

const initialState = {
  loading: true,
  useSeparateStatusIcons: false,
};

const orgPreferencesReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case HYP_SET_ORG_PREFERENCES:
      return {
        ...state,
        loading: false,
        useSeparateStatusIcons:
          payload?.data?.prefs?.test_view?.auto_test_view_separately?.status ===
          'enabled',
        showTestSummary:
          payload?.data?.prefs?.test_view?.show_test_summary?.status ===
          'enabled',
      };
    default:
      return state;
  }
};

export default orgPreferencesReducer;
