import React from 'react';

const CrossMedium = ({
  width = 32,
  height = 32,
  className,
  color = '#666666',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect width={width} height={height} fill="white" />
      <g clip-path="url(#clip0_4327_45589)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7198 11.7192C11.8604 11.5787 12.0511 11.4998 12.2498 11.4998C12.4486 11.4998 12.6392 11.5787 12.7798 11.7192L15.9998 14.9392L19.2198 11.7192C19.2885 11.6455 19.3713 11.5864 19.4633 11.5454C19.5553 11.5044 19.6546 11.4824 19.7553 11.4806C19.856 11.4788 19.956 11.4973 20.0494 11.5351C20.1428 11.5728 20.2276 11.6289 20.2988 11.7001C20.3701 11.7714 20.4262 11.8562 20.4639 11.9496C20.5016 12.043 20.5202 12.143 20.5184 12.2437C20.5166 12.3444 20.4946 12.4437 20.4536 12.5357C20.4126 12.6277 20.3535 12.7105 20.2798 12.7792L17.0598 15.9992L20.2798 19.2192C20.3535 19.2879 20.4126 19.3707 20.4536 19.4627C20.4946 19.5547 20.5166 19.654 20.5184 19.7547C20.5202 19.8554 20.5016 19.9554 20.4639 20.0488C20.4262 20.1422 20.3701 20.227 20.2988 20.2982C20.2276 20.3695 20.1428 20.4256 20.0494 20.4633C19.956 20.501 19.856 20.5196 19.7553 20.5178C19.6546 20.516 19.5553 20.494 19.4633 20.453C19.3713 20.412 19.2885 20.3529 19.2198 20.2792L15.9998 17.0592L12.7798 20.2792C12.6376 20.4117 12.4496 20.4838 12.2553 20.4804C12.061 20.4769 11.8756 20.3982 11.7382 20.2608C11.6008 20.1234 11.5221 19.938 11.5186 19.7437C11.5152 19.5494 11.5873 19.3614 11.7198 19.2192L14.9398 15.9992L11.7198 12.7792C11.5794 12.6386 11.5005 12.4479 11.5005 12.2492C11.5005 12.0504 11.5794 11.8598 11.7198 11.7192Z"
          fill="#101010"
        />
      </g>
      <defs>
        <clipPath id="clip0_4327_45589">
          <rect width="32" height="32" rx="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CrossMedium;
