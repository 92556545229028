import React from 'react';

const ViewSVG = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      alt=""
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.3337 6.66536V4.66536L10.0003 1.33203H4.00033C3.6467 1.33203 3.30756 1.47251 3.05752 1.72256C2.80747 1.9726 2.66699 2.31174 2.66699 2.66536V13.332C2.66699 13.6857 2.80747 14.0248 3.05752 14.2748C3.30756 14.5249 3.6467 14.6654 4.00033 14.6654H6.66699"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33301 1.33203V3.9987C9.33301 4.35232 9.47348 4.69146 9.72353 4.94151C9.97358 5.19156 10.3127 5.33203 10.6663 5.33203H13.333"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6663 9.33203C10.3127 9.33203 9.97358 9.47251 9.72353 9.72256C9.47348 9.9726 9.33301 10.3117 9.33301 10.6654"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 9.33203C13.6866 9.33203 14.0258 9.47251 14.2758 9.72256C14.5259 9.9726 14.6663 10.3117 14.6663 10.6654"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 14.6654C13.6866 14.6654 14.0258 14.5249 14.2758 14.2748C14.5259 14.0248 14.6663 13.6857 14.6663 13.332"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6663 14.6654C10.3127 14.6654 9.97358 14.5249 9.72353 14.2748C9.47348 14.0248 9.33301 13.6857 9.33301 13.332"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ViewSVG;
