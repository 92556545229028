import React from 'react';

const ThunderLogo = ({ width = '56px', height = '56px', className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 85 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
    >
      <rect
        width="85"
        height="85"
        rx="42.5"
        fill="url(#paint0_linear_15310_111959)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.4245 22.5254L23.7891 46.526H42.361L40.2974 62.5264L60.9328 38.5258H42.361L44.4245 22.5254Z"
        stroke="#FAFAFA"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15310_111959"
          x1="1.00697e-06"
          y1="3.58146"
          x2="85"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00F388" />
          <stop offset="1" stopColor="#01CAF6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ThunderLogo;
