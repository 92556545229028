import React from 'react';

const TimelineIcon = ({
  width = '14px',
  height = '8px',
  color = '#666666',
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 8"
      fill="none"
      className="className"
      role="presentation"
      aria-hidden="true"
    >
      <line x1="14" y1="4" x2="10" y2="4" stroke={color} strokeWidth="2" />
      <line x1="4" y1="4" y2="4" stroke={color} strokeWidth="2" />
      <circle cx="7" cy="4" r="3" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default TimelineIcon;
