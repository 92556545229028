import React from 'react';

const ScrollUpArrow = ({ width, height, className, onClick, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M4 10L8 6L12 10"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { ScrollUpArrow };
