import React from 'react';

const RocketIcon = ({ width = 17, height = 16, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M12.3633 4.69615C12.3633 4.97609 12.252 5.24457 12.0541 5.44252C11.8561 5.64046 11.5877 5.75167 11.3078 5.75167C11.0278 5.75167 10.7593 5.64046 10.5614 5.44252C10.3634 5.24457 10.2522 4.97609 10.2522 4.69615C10.2522 4.4162 10.3634 4.14773 10.5614 3.94978C10.7593 3.75183 11.0278 3.64062 11.3078 3.64062C11.5877 3.64062 11.8561 3.75183 12.0541 3.94978C12.252 4.14773 12.3633 4.4162 12.3633 4.69615Z"
        fill="#333333"
      />
      <path
        d="M3.45469 14.786C2.68204 15.5586 0.991097 15.889 0.282842 15.9977C0.244659 16.0038 0.205568 16.001 0.168719 15.9892C0.131868 15.9775 0.098288 15.9573 0.0706819 15.9302C0.0435982 15.9025 0.0233765 15.8689 0.0116423 15.8321C-9.09098e-05 15.7953 -0.00300732 15.7562 0.00312843 15.718C0.111847 15.0098 0.442226 13.3188 1.21698 12.5483C1.36023 12.3859 1.53523 12.2546 1.73119 12.1624C1.92713 12.0702 2.13989 12.0192 2.35632 12.0125C2.57276 12.0057 2.78828 12.0433 2.9896 12.1231C3.19093 12.2028 3.37379 12.3229 3.52691 12.4761C3.68003 12.6291 3.80015 12.8121 3.87988 13.0133C3.95961 13.2147 3.99726 13.4302 3.99049 13.6467C3.98371 13.863 3.93269 14.0758 3.84053 14.2717C3.74839 14.4677 3.61707 14.6427 3.45469 14.786Z"
        fill="#333333"
      />
      <path
        d="M8.92812 2.74087C10.4408 1.30418 12.4493 0.500185 14.5409 0.5H14.541H14.7373C14.9396 0.5 15.134 0.580298 15.2773 0.723694L8.92812 2.74087ZM8.92812 2.74087H8.92218L8.77574 2.88732L8.29231 3.37075C7.46518 4.19788 6.72838 5.10989 6.09387 6.09156C6.09384 6.0916 6.09381 6.09165 6.09378 6.0917L4.5107 8.53703L4.29098 8.87642L4.57687 9.16231L6.83885 11.4243L7.12474 11.7102L7.46413 11.4905L9.90946 9.90738C10.891 9.27296 11.8031 8.5362 12.6292 7.70901C12.6294 7.70888 12.6295 7.70875 12.6296 7.70862L13.1135 7.22581L13.1139 7.22539C13.8709 6.46824 14.4713 5.5694 14.8809 4.58023L14.4555 4.40404L14.8809 4.58023C15.2906 3.59102 15.5013 2.53083 15.5012 1.46021C15.5012 1.46019 15.5012 1.46017 15.5012 1.46016V1.26388C15.5012 1.06112 15.4205 0.867047 15.2777 0.724034L8.92812 2.74087Z"
        stroke="#333333"
      />
      <path
        d="M7.08569 11.5568L8.14122 15.2511L11.3078 13.1401V8.91797"
        stroke="#333333"
        strokeLinejoin="round"
      />
      <path
        d="M7.08558 4.69531H2.86349L0.752441 7.86188L4.44677 8.9174"
        stroke="#333333"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { RocketIcon };
