import { useSelector } from 'react-redux';
import history from 'utils/history';

import DocumentationOptionTabs from './DocumentationOptionTabs/index';
import CongratulationsModalHeader from './CongratulationsModalHeader/index';
import CongratulationsModalFooter from './CongratulationsModalFooter/index';

import { getJobDetailPageUrl } from 'utils/redirects';
import { openZohoSalesIqChat } from 'utils/common';
import { sendAmplitudeEvents } from 'utils/common';
import { EventNames } from 'utils/events';
import { useEffect, useRef } from 'react';
import handleBackDropElementsAccessibility from 'utils/handleBackDropElementsAccessibility';
import trapFocus from 'utils/trapFocus';
import { isESCPressed } from 'utils/keyboard';
import { createPortal } from 'react-dom';

import './index.css';
import {
  hideSupportIcon,
  showSupportIcon,
} from 'utils/handleSupportIconVisibility';

const CongratulationsModal = () => {
  const containerRef = useRef(null);

  const USERFLOW_TOKEN = 'c5fc7c37-b640-4b71-9aa0-d16ee3a555ac';

  let jobList = useSelector(
    (state) => state.hypertestJobList?.hypertestJobList?.data?.data
  );

  useEffect(() => {
    const containerElement = containerRef.current;

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === 'class') {
          const classList = containerElement.classList;
          const isVisible = classList.contains(
            'congratulation-modal-container-visible'
          );
          if (isVisible) {
            const firstFocusableElement =
              document?.querySelector('.aria-heading');
            if (firstFocusableElement) {
              firstFocusableElement.focus();
            }
            document.addEventListener(
              'keydown',
              trapFocus('congratulation-modal-container')
            );
            handleBackDropElementsAccessibility(true);
            hideSupportIcon();
          } else {
            handleBackDropElementsAccessibility(false);
            showSupportIcon();
          }
        }
      }
    });

    observer.observe(containerElement, { attributes: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  function handleContinueTour() {
    if (window.userflow) {
      window.userflow.start(USERFLOW_TOKEN);
    }

    sendAmplitudeEvents(EventNames.HYP_CONTINUE_TOUR_MODAL);
    const url = jobList?.length ? getJobDetailPageUrl(jobList[0]?.id) : '';
    handleModalClosure();
    url && history.push(url);
  }

  function handleTalkToExpert() {
    sendAmplitudeEvents(EventNames.HYP_TALK_TO_EXPERT_MODAL);
    openZohoSalesIqChat();
  }

  function handleSkip() {
    sendAmplitudeEvents(EventNames.HYP_SKIP_FOR_NOW_MODAL);
    handleModalClosure();
  }

  function handleContinueTesting() {
    sendAmplitudeEvents(EventNames.HYP_CONTINUE_TESTING_MODAL);
    handleModalClosure();
  }
  /**
   * adding this so that modal is always in dom, and
   * userflow can toggle the visibility as needed
   *
   */
  function handleModalClosure() {
    document
      .querySelector('.congratulation-modal-container')
      ?.classList.remove('congratulation-modal-container-visible');
  }

  return createPortal(
    <div
      className={'congratulation-modal-container'}
      role="dialog"
      aria-modal={true}
      aria-describedby="IDREF3"
      aria-labelledby="IDREF3"
      ref={containerRef}
    >
      <h2 id="IDREF3" className="aria-heading" tabIndex="-1">
        modal
      </h2>
      <div
        className="congratulation-modal-container-backdrop"
        onClick={handleModalClosure}
        tabIndex={0}
        role="button"
        onKeyDown={(e) => {
          isESCPressed(e) && handleModalClosure(e);
        }}
        aria-label="congratulation modal backdrop"
      ></div>
      <div className="congratulation-modal-content-wrapper">
        <div className="congratulation-modal-content">
          <CongratulationsModalHeader />
          <DocumentationOptionTabs />
          <CongratulationsModalFooter
            handleContinueTour={handleContinueTour}
            handleTalkToExpert={handleTalkToExpert}
            handleSkip={handleSkip}
            handleContinueTesting={handleContinueTesting}
          />
        </div>
      </div>
    </div>,
    document.getElementById('root')
  );
};

export default CongratulationsModal;
