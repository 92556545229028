import React from 'react';

const Secret = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M4.66663 6.68669V5.33333C4.66663 3.49333 6.13329 2 7.99996 2C9.86663 2 11.3333 3.49333 11.3333 5.33333V6.68669M4.66663 6.68669C4.26663 6.70669 3.9997 6.76664 3.73303 6.88664C3.3997 7.0733 3.06715 7.38001 2.86715 7.76001C2.66715 8.18668 2.66663 8.7467 2.66663 9.8667V10.8C2.66663 11.92 2.66715 12.48 2.86715 12.9067C3.06715 13.2867 3.3997 13.5934 3.73303 13.78C4.1997 14 4.73381 14 5.86715 14H10.1328C11.2661 14 11.8002 14 12.2669 13.78C12.6002 13.5934 12.9328 13.2867 13.1328 12.9067C13.3328 12.48 13.3333 11.92 13.3333 10.8V9.8667C13.3333 8.7467 13.3328 8.18668 13.1328 7.76001C12.9328 7.38001 12.6002 7.0733 12.2669 6.88664C12.0002 6.76664 11.7333 6.70669 11.3333 6.68669M4.66663 6.68669C4.99996 6.66669 5.40048 6.66667 5.86715 6.66667H10.1328C10.5994 6.66667 11 6.66669 11.3333 6.68669M7.99996 9.66667V11"
        stroke="#333333"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Secret;
