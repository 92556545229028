import React from 'react';

const ThunderLTColor = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 18"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.82293 1.47168L1.11523 10.4363H8.05216L7.28139 16.4128L14.9891 7.44811H8.05216L8.82293 1.47168Z"
        fill="#0EBAC5"
        stroke="#0EBAC5"
        strokeWidth="1.47151"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { ThunderLTColor };
