import React from 'react';

const AbortInfo = ({ className, ...rest }) => {
  return (
    <svg
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M9.07307 38.7642L12.6749 36.3636C10.1049 33.5363 8.58479 29.9116 8.36915 26.0972C8.15347 22.2824 9.25558 18.5093 11.4908 15.4107C13.7257 12.3119 16.9579 10.0754 20.6458 9.07616C24.3336 8.07673 28.2529 8.37526 31.7465 9.92159C35.2401 11.4682 38.0964 14.1682 39.8365 17.5702C41.5761 20.9717 42.094 24.868 41.3033 28.6059C40.5123 32.3439 38.4606 35.6966 35.4919 38.1023C32.5234 40.5074 28.8184 41.8194 24.9976 41.818H9.99765C9.51746 41.818 9.06072 41.611 8.74429 41.2499C8.42788 40.8889 8.28252 40.4087 8.34558 39.9327C8.40863 39.4567 8.67349 39.0307 9.07307 38.7642ZM16.2438 37.9894L15.5016 38.4844H24.9973C28.1351 38.4885 31.1742 37.3876 33.5816 35.3747C35.9884 33.3618 37.6095 30.5653 38.1605 27.4761C38.7113 24.3871 38.1566 21.2027 36.5935 18.4819C35.0307 15.7609 32.5594 13.6774 29.6133 12.5971C26.6674 11.5164 23.4353 11.5081 20.484 12.5733C17.5326 13.6383 15.0504 15.7089 13.4734 18.4216C11.8961 21.1342 11.325 24.3156 11.8596 27.4073C12.3945 30.4991 14.001 33.3038 16.3975 35.3294C16.7965 35.6681 17.0136 36.1746 16.9834 36.6971C16.9533 37.2197 16.6794 37.6987 16.2438 37.9894ZM36.6638 40.1509C36.6638 40.5929 36.8393 41.017 37.1518 41.3295C37.4646 41.6423 37.8884 41.8178 38.3306 41.8175H39.9972C40.5926 41.8175 41.1429 41.5 41.4406 40.9844C41.7383 40.4688 41.7383 39.8332 41.4406 39.3175C41.1429 38.8019 40.5926 38.4844 39.9972 38.4844H38.3306C37.8884 38.4841 37.4646 38.6596 37.1518 38.9724C36.8393 39.2849 36.6638 39.7089 36.6638 40.1509Z"
        fill="#F2A954"
      />
      <path
        d="M26.6693 19.9993H23.3359V16.666H26.6693V19.9993Z"
        fill="#F2A954"
      />
      <path
        d="M26.6693 33.3327H23.3359V23.3327H26.6693V33.3327Z"
        fill="#F2A954"
      />
    </svg>
  );
};

export default AbortInfo;
