import { theme } from '@primer/react';
import deepmerge from 'deepmerge';

const LTTheme = deepmerge(theme, {
  colorSchemes: {
    light: {
      colors: {
        fg: {
          disabled: '#888',
          default: '#333',
          muted: '#666',
          subtle: '#dddddd33',
          onEmphasis: '#fff',
        },
        switchTrack: {
          bg: '#ededed',
          hoverBg: '#ededed',
          activeBg: '#ededed',
          disabledBg: '#666666',
          fg: '#656d76',
          disabledFg: '#ffffff',
          border: '#d4d4d4',
          checked: {
            bg: '#101010',
            hoverBg: '#101010',
            activeBg: '#101010',
            fg: '#ffffff',
            disabledFg: '#ffffff',
            border: '#101010',
          },
        },
        switchKnob: {
          bg: '#ffffff',
          disabledBg: '#ffffff',
          border: '#d4d4d4',
          checked: {
            bg: '#ffffff',
            disabledBg: '#ffffff',
            border: '#101010',
          },
        },
        text: {
          default: '#333333',
          muted: '#666666',
          danger: '#CF222E',
          yellow: '#947100',
          subtle: '#6E7781',
        },
        toggle: {
          bg: '#cbcbcb',
        },
        border: {
          default: '#D4D4D4',
          invisibleIconBtn: '#d4d4d4',
          invisibleBtn: 'transparent',
          danger: 'transparent',
          lightGray: '#ddd',
        },
        actionListItem: {
          default: {
            activeBg: '#F0F0F0',
            border: 'transparent',
            selectedBg: '#F0F0F0',
            selectedBorder: 'transparent',
            hoverBg: '#dddddd33',
          },
        },
        counterLabel: {
          defaultText: '#333',
        },
        focus: {
          default: '#D4D4D4',
          muted: '#0969da',
          outlineColor: '#101010',
        },
        flashDefault: {
          muted: '#54AEFF',
          subtle: '#DDF4FF',
        },
        success: {
          bg: '#176F2C',
          muted: '#4ac26b66',
          subtle: '#dafbe1',
        },
        attention: {
          muted: '#D4A72C',
          subtle: '#FFF8C5',
        },
        danger: {
          bg: '#CF222E',
          muted: '#FF8182',
          subtle: '#FFEBE9',
        },
        warning: {
          bg: '#9A6700',
        },
        default: {
          muted: '#54aeff66',
          subtle: '#DDF4FF',
        },
        slate: {
          bg: '#cbcbcb',
        },
        btn: {
          bg: '#f7f7f7',
          primary: {
            bg: '#101010',
            text: '#fff',
            hoverBg: '#333',
            disabledBg: 'rgba(16, 16, 16, 0.30)',
          },
          default: {
            bg: '#F3F3F3',
            text: '#101010',
            hoverBg: '#F3F3F3',
            disabledBg: '#F7F7F7',
            activeBg: '#F7F7F7',
          },
          danger: {
            bg: '#F7F7F7',
            disabledBg: '#F7F7F7',
            hoverBg: '#A40E26',
          },
          invisible: {
            hoverBg: '#dddddd33',
            hoverBorder: 'transparent',
            disabledBg: '#888888',
          },
          outline: {
            bg: '#333333',
            text: '#101010',
            hoverBorder: 'transparent',
            hoverBg: '#F3F3F3',
            hoverText: '#101010',
            disabledBg: '#F7F7F7',
          },
        },
        link: {
          default: '#0969DA',
          hover: '#0969DA',
        },
        accent: {
          fg: '#333333',
          muted: '#DDF4FF',
          emphasis: '#101010',
        },
        blueAccent: {
          emphasis: '#0969DA',
          fg: '#0969DA',
          muted: '#0862C9',
          bg: '#DDF4FF',
          hoverBg: '#c5eafc',
          border: '#ddebf1',
        },
        checked: {
          bg: {
            checked: '#101010',
          },
        },
        bg: {
          muted: '#F7F7F7',
          neutralEmphasis: 'rgba(175, 184, 193, 0.2)',
        },
        timeline: {
          badgeBg: '#eaeef2',
          badgeBorder: '#fff',
        },
        onHover: '#F7F7F7',
        onSelected: '#F0F0F0',
        highlight: '#ED5F00',
      },
      shadows: {
        btn: {
          insetShadow: '0px 1px 0px 0px #1B1F230A',
        },
      },
    },
    light_high_contrast: {
      colors: {
        fg: {
          disabled: '#888',
          default: '#333',
          muted: '#666',
          subtle: '#dddddd33',
          onEmphasis: '#fff',
        },
        switchTrack: {
          bg: '#ededed',
          hoverBg: '#ededed',
          activeBg: '#ededed',
          disabledBg: '#666666',
          fg: '#656d76',
          disabledFg: '#ffffff',
          border: '#888',
          checked: {
            bg: '#101010',
            hoverBg: '#101010',
            activeBg: '#101010',
            fg: '#ffffff',
            disabledFg: '#ffffff',
            border: '#101010',
          },
        },
        switchKnob: {
          bg: '#ffffff',
          disabledBg: '#ffffff',
          border: '#888888',
          checked: {
            bg: '#ffffff',
            disabledBg: '#ffffff',
            border: '#101010',
          },
        },
        toggle: {
          bg: '#888888',
        },
        text: {
          default: '#333333',
          muted: '#666666',
          danger: '#CF222E',
          yellow: '#947100',
          subtle: '#6E7781',
        },
        counterLabel: {
          defaultText: '#fff',
        },
        border: {
          default: '#888888',
          invisibleIconBtn: '#888',
          invisibleBtn: '#888',
          danger: 'FFEBE9',
          primaryBtn: 'transparent',
          primaryBtnDisabled: '#888888',
          lightGray: '#888888',
        },
        focus: {
          default: '#D4D4D4',
          muted: '#0969da',
          outlineColor: '#101010',
        },
        flashDefault: {
          muted: '#54AEFF',
          subtle: '#DDF4FF',
        },
        success: {
          bg: '#176F2C',
          muted: '#A0E5B2',
          subtle: '#DAFAE1',
        },
        attention: {
          muted: '#D4A72C',
          subtle: '#FFF8C5',
        },
        danger: {
          bg: '#CF222E',
          muted: '#FF8182',
          subtle: '#FFEBE9',
        },
        warning: {
          bg: '#9A6700',
        },
        default: {
          muted: '#54aeff66',
          subtle: '#DDF4FF',
        },
        slate: {
          bg: '#666666',
        },
        icon: {
          default: '#888888',
        },
        actionListItem: {
          default: {
            activeBg: '#F0F0F0',
            selectedBg: '#F0F0F0',
            selectedBorder: '#101010',
            border: '#888',
            hoverBg: '#dddddd33',
          },
          danger: {
            hoverBg: 'rgba(255,235,233,0.64)',
            hoverText: '#d1242f',
          },
        },
        btn: {
          bg: '#f7f7f7',
          hoverBg: 'rgb(243, 244, 246)',
          primary: {
            bg: '#101010',
            text: '#fff',
            hoverBg: '#333',
            disabledBg: 'rgba(16, 16, 16, 0.30)',
            activeBg: '#F7F7F7',
          },
          default: {
            bg: '#F3F3F3',
            text: '#101010',
            hoverBg: '#F3F3F3',
            disabledBg: '#F7F7F7',
          },
          danger: {
            bg: '#F7F7F7',
            disabledBg: '#F7F7F7',
            hoverBg: '#A40E26',
          },
          invisible: { disabledBg: '#888888', hoverBorder: '#888' },
          outline: {
            bg: '#333333',
            text: '#101010',
            hoverBg: '#F3F3F3',
            hoverBorder: '#888',
            hoverText: '#101010',
            disabledBg: '#F7F7F7',
          },
        },
        link: {
          default: '#0969DA',
          hover: '#0969DA',
        },
        accent: {
          fg: '#333333',
          muted: '#DDF4FF',
          emphasis: '#101010',
        },
        blueAccent: {
          emphasis: '#0969DA',
          fg: '#0969DA',
          bg: '#DDF4FF',
          hoverBg: '#c5eafc',
          border: '#ddebf1',
        },
        checked: {
          bg: {
            checked: '#101010',
          },
        },
        bg: {
          muted: '#F7F7F7',
          neutralEmphasis: '#666',
          light: '#DDDDDD',
        },
        tooltipText: {
          bg: '#24292F',
        },
        timeline: {
          badgeBg: '#e7ecf0',
          badgeBorder: '#888',
        },
        onHover: '#F7F7F7',
        onSelected: '#F0F0F0',
        highlight: '#ED5F00',
      },
      shadows: {
        btn: {
          insetShadow: '0px 1px 0px 0px #1B1F230A',
        },
      },
    },
    dark: {
      colors: {
        text: {
          default: '#e6edf3',
          muted: '#7d8590',
          onEmphasis: '#fff',
          subtle: '#6e7681',
        },
        fg: {
          muted: '#A5A09B',
        },
      },
    },
  },
});

export default LTTheme;
