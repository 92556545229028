import React from 'react';

const Edit = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      role="img"
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 13"
      fill="none"
      aria-hidden="true"
    >
      <path
        d="M7 12.3335H13"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 1.3334C10.2652 1.06819 10.6249 0.919189 11 0.919189C11.1857 0.919189 11.3696 0.955769 11.5412 1.02684C11.7128 1.09791 11.8687 1.20208 12 1.3334C12.1313 1.46472 12.2355 1.62063 12.3066 1.79221C12.3776 1.96379 12.4142 2.14769 12.4142 2.3334C12.4142 2.51912 12.3776 2.70302 12.3066 2.8746C12.2355 3.04618 12.1313 3.20208 12 3.3334L3.66667 11.6667L1 12.3334L1.66667 9.66674L10 1.3334Z"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { Edit };
