import React from 'react';

const OperaIcon = ({ width = 12, height = 13, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 21"
      className={className}
      aria-hidden="true"
      {...rest}
      role="img"
    >
      <path
        fill="#FA6758"
        fillRule="evenodd"
        d="M9.985 21C4.47 21 0 16.299 0 10.5S4.47 0 9.985 0s9.985 4.701 9.985 10.5S15.5 21 9.985 21zm0-1.78c2.947 0 3.566-3.904 3.566-8.72 0-4.816-.63-8.72-3.566-8.72S6.42 5.684 6.42 10.5c0 4.816.62 8.72 3.566 8.72z"
      />
    </svg>
  );
};

export default OperaIcon;
