import React from 'react';

const AbortArrow = ({ width = '6', height = '4', className, ...rest }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 6 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M1.0875 0.574219L3 2.48672L4.9125 0.574219L5.5 1.16589L3 3.66589L0.5 1.16589L1.0875 0.574219Z"
        fill="#F19A5A"
      />
    </svg>
  );
};

export default AbortArrow;
