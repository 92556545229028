import { CALL_API } from '../middleware/api';
import * as action from '../constants/preference';
import { getSentinelApi } from 'new-theme/utils/onpremEndpoints';

export const fetchPreferences = ({ auth }) => {
  const endpoint = `${getSentinelApi()}/v1.0/preferences`;

  return {
    [CALL_API]: {
      types: ['', action.HYP_SET_ORG_PREFERENCES],
      endpoint,
      method: 'POST',
      appendBaseUrl: false,
      type: 'json',
      auth,
    },
  };
};
