export const getLTMS_API = () => {
  const url =
    (window.globalConfig && window.globalConfig.LTMS_API_HOST) ||
    process.env.REACT_APP_API_BASE_URL ||
    window._env_.REACT_APP_API_BASE_URL;
  return url[url.length - 1] === '/' ? url : `${url}/`;
};

export const getSentinelApi = () => {
  const HYPERTEST_SENTINEL_API_ENDPOINT =
    process.env.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT ||
    (window._env_ && window._env_.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT);

  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;

  return sentinelUrl;
};

export const getLogisticsApi = () => {
  let HYPERTEST_LOGISTIC_API_ENDPOINT =
    window?._env_?.REACT_APP_HYPERTEST_LOGISTIC_API_ENDPOINT ||
    process.env.REACT_APP_HYPERTEST_LOGISTIC_API_ENDPOINT;

  const logisticsUrl =
    (window.globalConfig && window.globalConfig.LOGISTICS_HOST) ||
    HYPERTEST_LOGISTIC_API_ENDPOINT;
  return logisticsUrl;
};

export const getReceptionApi = () => {
  const HYPERTEST_RECEPTION_API_ENDPOINT =
    process.env.REACT_APP_HYPERTEST_RECEPTION_API_ENDPOINT ||
    (window._env_ && window._env_.REACT_APP_HYPERTEST_RECEPTION_API_ENDPOINT);

  let receptionUrl =
    (window.globalConfig && window.globalConfig.RECEPTION_HOST) ||
    HYPERTEST_RECEPTION_API_ENDPOINT;

  return receptionUrl;
};

export const getOrchestratorApi = () => {
  const HYPERTEST_ORCHESTRATOR_API_ENDPOINT =
    process.env.REACT_APP_HYPERTEST_ORCHESTRATOR_API_ENDPOINT ||
    (window._env_ &&
      window._env_.REACT_APP_HYPERTEST_ORCHESTRATOR_API_ENDPOINT);
  let orchestratorURL =
    (window.globalConfig && window.globalConfig.ORCHESTRATOR_HOST) ||
    HYPERTEST_ORCHESTRATOR_API_ENDPOINT ||
    (window._env_ && window._env_.HYPERTEST_ORCHESTRATOR_API_ENDPOINT);
  return orchestratorURL;
};

export const getLogisticsAPI = () => {
  const HYPERTEST_LOGISTIC_API_ENDPOINT =
    process.env.REACT_APP_HYPERTEST_LOGISTIC_MEDIA_API_ENDPOINT ||
    (window._env_ &&
      window._env_.REACT_APP_HYPERTEST_LOGISTIC_MEDIA_API_ENDPOINT);
  const logisticsURL =
    (window.globalConfig && window.globalConfig.LOGISTICS_HOST) ||
    HYPERTEST_LOGISTIC_API_ENDPOINT;

  return logisticsURL;
};
