const ChangesFound = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      role="img"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...rest}
    >
      <rect width="16" height="16" rx="8" fill="#FAEAEA" />
      <path
        d="M8.82031 11.63V8.27686H12.1734V11.63H8.82031Z"
        stroke="#FF9090"
        strokeWidth="0.5"
      />
      <path
        d="M4.20912 4.69974C4.26489 4.69974 4.31891 4.67709 4.35725 4.63875C4.39559 4.60041 4.41825 4.54639 4.41825 4.49062C4.41825 4.43659 4.39559 4.38083 4.35725 4.34249C4.31891 4.30415 4.26489 4.28149 4.20912 4.28149C4.15336 4.28149 4.09933 4.30415 4.06099 4.34249C4.02266 4.38083 4 4.43485 4 4.49062C4 4.54639 4.02266 4.60041 4.06099 4.63875C4.10108 4.67709 4.15336 4.69974 4.20912 4.69974Z"
        fill="#FF9090"
      />
      <path
        d="M5.92787 4.69974C6.04289 4.69974 6.137 4.60564 6.137 4.49062C6.137 4.3756 6.04289 4.28149 5.92787 4.28149C5.81286 4.28149 5.71875 4.3756 5.71875 4.49062C5.71875 4.60564 5.81111 4.69974 5.92787 4.69974Z"
        fill="#FF9090"
      />
      <path
        d="M5.0685 4.69974C5.18352 4.69974 5.27762 4.60564 5.27762 4.49062C5.27762 4.3756 5.18352 4.28149 5.0685 4.28149C4.95348 4.28149 4.85938 4.3756 4.85938 4.49062C4.85938 4.60564 4.95348 4.69974 5.0685 4.69974Z"
        fill="#FF9090"
      />
      <path
        d="M6.78725 4.69974C6.90227 4.69974 6.99637 4.60564 6.99637 4.49062C6.99637 4.3756 6.90227 4.28149 6.78725 4.28149C6.67223 4.28149 6.57812 4.3756 6.57812 4.49062C6.57812 4.60564 6.67223 4.69974 6.78725 4.69974Z"
        fill="#FF9090"
      />
      <path
        d="M7.64662 4.28149C7.5926 4.28149 7.53683 4.30415 7.49849 4.34249C7.46016 4.38083 7.4375 4.43485 7.4375 4.49062C7.4375 4.54639 7.46016 4.60041 7.49849 4.63875C7.53683 4.67709 7.59086 4.69974 7.64662 4.69974C7.70239 4.69974 7.75641 4.67709 7.79475 4.63875C7.83309 4.60041 7.85575 4.54639 7.85575 4.49062C7.85575 4.43485 7.83309 4.38083 7.79475 4.34249C7.75641 4.30415 7.70239 4.28149 7.64662 4.28149Z"
        fill="#FF9090"
      />
      <path
        d="M7.64662 6.85913C7.53161 6.85913 7.4375 6.95324 7.4375 7.06826C7.4375 7.18327 7.53161 7.27738 7.64662 7.27738C7.76164 7.27738 7.85575 7.18327 7.85575 7.06826C7.85575 6.95149 7.76339 6.85913 7.64662 6.85913Z"
        fill="#FF9090"
      />
      <path
        d="M7.64662 6C7.53161 6 7.4375 6.09411 7.4375 6.20912C7.4375 6.32414 7.53161 6.41825 7.64662 6.41825C7.76164 6.41825 7.85575 6.32414 7.85575 6.20912C7.85575 6.09236 7.76339 6 7.64662 6Z"
        fill="#FF9090"
      />
      <path
        d="M7.64662 5.14062C7.53161 5.14062 7.4375 5.23473 7.4375 5.34975C7.4375 5.46477 7.53161 5.55887 7.64662 5.55887C7.76164 5.55887 7.85575 5.46477 7.85575 5.34975C7.85575 5.23473 7.76339 5.14062 7.64662 5.14062Z"
        fill="#FF9090"
      />
      <path
        d="M7.64662 7.71631C7.59086 7.71631 7.53683 7.73896 7.49849 7.7773C7.46016 7.81564 7.4375 7.86967 7.4375 7.92543C7.4375 7.97946 7.46016 8.03522 7.49849 8.07356C7.53683 8.1119 7.59086 8.13456 7.64662 8.13456C7.70239 8.13456 7.75641 8.1119 7.79475 8.07356C7.83309 8.03522 7.85575 7.9812 7.85575 7.92543C7.85575 7.86967 7.83309 7.81564 7.79475 7.7773C7.75643 7.73896 7.70239 7.71631 7.64662 7.71631Z"
        fill="#FF9090"
      />
      <path
        d="M6.78725 8.13456C6.90227 8.13456 6.99637 8.04045 6.99637 7.92543C6.99637 7.81041 6.90227 7.71631 6.78725 7.71631C6.67223 7.71631 6.57812 7.81041 6.57812 7.92543C6.57812 8.04219 6.67223 8.13456 6.78725 8.13456Z"
        fill="#FF9090"
      />
      <path
        d="M5.92787 8.13456C6.04289 8.13456 6.137 8.04045 6.137 7.92543C6.137 7.81041 6.04289 7.71631 5.92787 7.71631C5.81286 7.71631 5.71875 7.81041 5.71875 7.92543C5.71875 8.04219 5.81111 8.13456 5.92787 8.13456Z"
        fill="#FF9090"
      />
      <path
        d="M5.0685 8.13456C5.18352 8.13456 5.27762 8.04045 5.27762 7.92543C5.27762 7.81041 5.18352 7.71631 5.0685 7.71631C4.95348 7.71631 4.85938 7.81041 4.85938 7.92543C4.85938 8.04219 4.95348 8.13456 5.0685 8.13456Z"
        fill="#FF9090"
      />
      <path
        d="M4.20912 8.13456C4.26489 8.13456 4.31891 8.1119 4.35725 8.07356C4.39559 8.03522 4.41825 7.9812 4.41825 7.92543C4.41825 7.86967 4.39559 7.81564 4.35725 7.7773C4.31891 7.73896 4.26489 7.71631 4.20912 7.71631C4.15336 7.71631 4.09933 7.73896 4.06099 7.7773C4.02266 7.81564 4 7.86967 4 7.92543C4 7.97946 4.02266 8.03522 4.06099 8.07356C4.10108 8.1119 4.15336 8.13456 4.20912 8.13456Z"
        fill="#FF9090"
      />
      <path
        d="M4.20912 7.27738C4.32414 7.27738 4.41825 7.18327 4.41825 7.06826C4.41825 6.95324 4.32414 6.85913 4.20912 6.85913C4.09411 6.85913 4 6.95324 4 7.06826C4 7.18327 4.09411 7.27738 4.20912 7.27738Z"
        fill="#FF9090"
      />
      <path
        d="M4.20912 6.41825C4.32414 6.41825 4.41825 6.32414 4.41825 6.20912C4.41825 6.09411 4.32414 6 4.20912 6C4.09411 6 4 6.09411 4 6.20912C4 6.32414 4.09411 6.41825 4.20912 6.41825Z"
        fill="#FF9090"
      />
      <path
        d="M4.20912 5.55887C4.32414 5.55887 4.41825 5.46477 4.41825 5.34975C4.41825 5.23473 4.32414 5.14062 4.20912 5.14062C4.09411 5.14062 4 5.23473 4 5.34975C4 5.46477 4.09411 5.55887 4.20912 5.55887Z"
        fill="#FF9090"
      />
      <path
        d="M9.50681 4L8.57031 4.9365L9.50681 5.87299"
        stroke="#FF9090"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.57031 4.93652H10.0687C11.1032 4.93652 11.9417 5.77506 11.9417 6.80951V6.99681"
        stroke="#FF9090"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.79007 11.8667L7.72656 10.9302L6.79007 9.99371"
        stroke="#FF9090"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.72656 10.9302L6.22817 10.9302C5.19372 10.9302 4.35518 10.0916 4.35518 9.05718L4.35518 8.86989"
        stroke="#FF9090"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChangesFound;
