import {
  HYPERTEST_CONCURRENCY_ESTIMATE_TIME_REQUEST,
  HYPERTEST_CONCURRENCY_ESTIMATE_TIME_RESET,
  HYPERTEST_CONCURRENCY_ESTIMATE_TIME_RESPONSE,
} from 'store/constants/concurrencyEstimateTime';

const InitailState = {
  loading: false,
  data: [],
  apiDone: false,
};
function concurrencyTimeEstimateReducer(state = InitailState, action) {
  switch (action.type) {
    case HYPERTEST_CONCURRENCY_ESTIMATE_TIME_REQUEST:
      return { ...state, loading: true };
    case HYPERTEST_CONCURRENCY_ESTIMATE_TIME_RESPONSE:
      return {
        ...state,
        loading: false,
        apiDone: true,
        data: action?.payload?.data,
      };
    case HYPERTEST_CONCURRENCY_ESTIMATE_TIME_RESET:
      return { ...InitailState };
    default:
      return state;
  }
}

export default concurrencyTimeEstimateReducer;
