export const messages = {
  SOMETHING_WENT_WRONG: {
    SOMETHING_WENT_WRONG: 'Something Went Wrong',
  },
  NODATA: {
    VISUAL_ERROR_DATA_NOT_FOUND_GROUPS: 'No Groups Found',
    LEARN_MORE: 'Learn More',
    DEFAULT: 'No Result Found in this Criteria !',
    ABORT_JOB_SUCCESS: 'Job abort successfully',
    ABORT_JOB_ERROR: 'Unable to abort job.',
  },
  META_DATA: {
    process_request: 'Please Wait while we process your request.',
    fetch_success: 'Request Processed Successfully. Downloading',
    fetch_failed: 'Please try again later.',
  },
  MESSAGE: {
    ABORT_JOB_SUCCESS: 'Job abort successfully',
    ABORT_ALL_JOB_SUCCESS: 'All Jobs aborted successfully',
    ABORT_SELECTED_JOB_SUCCESS: 'Selected Jobs aborted successfully',
    ABORT_JOB_ERROR: 'Unable to abort job.',
    ARTEFACT_DELETED_SUCCESS: 'Artefact deleted successfully.',
    ARTEFACT_DELETED_FAILURE: 'Failed to delete Artefact.',
    DOWNLOAD_STAGE_LOG_SUCCESS: 'Log file downloaded.',
    DOWNLOAD_STAGE_LOG_FAILED: 'Unable to download log file.',
    SECRET_DELETED_SUCCESS: 'Secret Key deleted successfully.',
    SECRET_DELETED_FAILED: 'Unable to delete secret key. Please try again.',
    SECRET_ADD_SUCCESS: 'Secret Key added successfully.',
    SECRET_ADD_FAILED: 'Unable to add secret key. Please try again.',
    SECRET_EDIT_SUCCESS: 'Secret key updated successfully.',
    SECRET_EDIT_FAILED: 'Unable to update secret key. Please try again.',
    JOB_ARCHIVED_SUCCESSFULLY: 'Job archived successfully',
    JOB_ARCHIVED_ERROR: 'Job archive failed',
    JOB_UNARCHIVED_SUCCESSFULLY: 'Job unarchived successfully',
    JOB_UNARCHIVED_ERROR: 'Job unarchive failed',
  },
};

export const HYPERTEST_FEATURES_LIST = [
  'Run automation tests at unmatched speeds',
  'Faster test execution with massively scalable parallel testing',
  'Smarter tests with auto splitting on HyperExecute cloud',
  'Runs on Windows, Mac and Linux containers',
  'Enterprise Grade Security, Privacy, and Compliances',
  'Seamlessly integrates with your existing CI',
];

export const HYPERTEST_TRIAL_FEATURELIST = [
  '2 Windows and Linux VMs to run your tests',
  '300 minutes of Blazing fast testing for 30 days',
  'Run automation tests at unmatched speeds',
  'Faster test execution with massively scalable parallel testing',
  'Smarter tests with auto splitting on HyperExecute cloud',
  'Enterprise Grade Security, Privacy, and Compliances',
  'Seamlessly integrates with your existing CI',
];

export const HYPERTEST_TRIAL_CUSTOM_FEATURELIST = [
  '5 concurrent Windows ,Linux and Mac VMs to run your tests',
  'TRIAL_DAYS',
  'Run automation tests at unmatched speeds',
  'Faster test execution with massively scalable parallel testing',
  'Smarter tests with auto splitting on HyperExecute cloud',
  'Enterprise Grade Security, Privacy, and Compliances',
  'Seamlessly integrates with your existing CI',
];
