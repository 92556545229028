import {
  HYPERTEST_STAGE_LOG_ACTIONS_REQUEST,
  HYPERTEST_STAGE_LOG_ACTIONS_RESPONSE,
  STAGE_LOG_CHUNK_RESPONSE,
  HYPERTEST_STAGE_LOG_RESET,
  STAGE_LOGS_VISUAL_UPADTE,
  HYPERTEST_STAGE_LOG_USING_CDN_ACTIONS_REQUEST,
  HYPERTEST_STAGE_LOG_USING_CDN_ACTIONS_RESPONSE,
} from '../constants/stageLog';

const CACHED_LOGS_MAX_LEN = 5;

const initialState = {
  hypertestStageLog: {
    data: null,
    loading: false,
    error: false,
  },
  hypertestStageLogCDN: {
    data: null,
    loading: false,
    error: false,
  },
  visual: {
    stageLogsFullMode: false,
  },
  cachedLogs: {},
  cachedLogsIds: [],
};

function hypertestStageLogReducer(state = initialState, actions) {
  const { type, payload, error, extra } = actions;
  switch (type) {
    case HYPERTEST_STAGE_LOG_ACTIONS_REQUEST:
      return {
        ...state,
        hypertestStageLog: {
          ...state.hypertestStageLog,
          loading: true,
        },
      };
    case HYPERTEST_STAGE_LOG_ACTIONS_RESPONSE: {
      const [prevCachedLogs, prevCachedLogsIds] = getCachedLogsAndId({
        cachedLogs: state.cachedLogs,
        cachedLogsIds: state.cachedLogsIds,
        extra,
        error,
        payload: payload?.data,
      });

      return {
        ...state,
        hypertestStageLog: {
          ...state.hypertestStageLog,
          data: payload,
          loading: false,
          error: error === undefined ? false : error,
        },
        cachedLogs: prevCachedLogs,
        cachedLogsIds: prevCachedLogsIds,
      };
    }

    case HYPERTEST_STAGE_LOG_USING_CDN_ACTIONS_REQUEST:
      return {
        ...state,
        hypertestStageLog: {
          ...state.hypertestStageLog,
          loading: payload,
        },
        ...(payload === 'RESET'
          ? {
              hypertestStageLog: { ...initialState.hypertestStageLog },
              hypertestStageLogCDN: { ...initialState.hypertestStageLog },
            }
          : {}),
      };
    case HYPERTEST_STAGE_LOG_USING_CDN_ACTIONS_RESPONSE:
      const [prevCachedLogs, prevCachedLogsIds] = getCachedLogsAndId({
        cachedLogs: state.cachedLogs,
        cachedLogsIds: state.cachedLogsIds,
        extra,
        error,
        payload,
      });
      return {
        ...state,
        hypertestStageLog: {
          ...state.hypertestStageLog,
          data: { data: payload },
          loading: false,
          error: extra?.error
            ? extra?.error
            : error === undefined
            ? false
            : error,
        },
        cachedLogs: prevCachedLogs,
        cachedLogsIds: prevCachedLogsIds,
      };
    case STAGE_LOG_CHUNK_RESPONSE:
      return {
        ...state,
        hypertestStageLog: {
          ...state.hypertestStageLog,
          data: {
            data:
              state.hypertestStageLog.data && state.hypertestStageLog.data.data
                ? state.hypertestStageLog.data.data.toString() +
                  payload.toString()
                : payload.toString(),
          },
          loading: false,
          error: error === undefined ? false : error,
        },
      };
    case STAGE_LOGS_VISUAL_UPADTE:
      return {
        ...state,
        visual: {
          ...state.visual,
          ...payload,
        },
      };
    case HYPERTEST_STAGE_LOG_RESET:
      return {
        ...state,
        hypertestStageLog: initialState.hypertestStageLog,
      };
    default:
      return state;
  }
}

function getCachedLogsAndId({
  cachedLogs,
  cachedLogsIds,
  extra,
  error,
  payload,
}) {
  const prevCachedLogs = { ...cachedLogs };
  let prevCachedLogsIds = [...cachedLogsIds];
  if (error || extra?.error) {
    return [prevCachedLogs, prevCachedLogsIds];
  }
  if (extra?.stageId && !prevCachedLogs[extra.stageId]) {
    if (prevCachedLogsIds.length < CACHED_LOGS_MAX_LEN) {
      prevCachedLogs[extra.stageId] = payload;
      prevCachedLogsIds = [extra.stageId, ...prevCachedLogsIds];
    } else {
      const idToDelete = prevCachedLogsIds.pop();
      delete prevCachedLogs[idToDelete];

      prevCachedLogs[extra.stageId] = payload;
      prevCachedLogsIds = [extra.stageId, ...prevCachedLogsIds];
    }
  }

  return [prevCachedLogs, prevCachedLogsIds];
}

export default hypertestStageLogReducer;
