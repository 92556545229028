import React from 'react';

const Queued = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <circle
        cx="21"
        cy="21"
        r="19.5"
        fill="white"
        stroke="#9B9FFF"
        strokeWidth="3"
      />
      <path
        d="M12 28.3106C12 27.6881 12.5046 27.1835 13.1271 27.1835H28.9067C29.5292 27.1835 30.0338 27.6881 30.0338 28.3106C30.0338 28.9331 29.5292 29.4377 28.9067 29.4377H13.1271C12.5046 29.4377 12 28.9331 12 28.3106Z"
        fill="#9B9FFF"
      />
      <path
        d="M12 23.2441C12 22.6216 12.5046 22.1169 13.1271 22.1169H28.9067C29.5292 22.1169 30.0338 22.6216 30.0338 23.2441C30.0338 23.8665 29.5292 24.3712 28.9067 24.3712H13.1271C12.5046 24.3712 12 23.8665 12 23.2441Z"
        fill="#9B9FFF"
      />
      <path
        d="M12 13.1271C12 12.5046 12.5046 12 13.1271 12H28.9067C29.5292 12 30.0338 12.5046 30.0338 13.1271V18.7627C30.0338 19.3852 29.5292 19.8898 28.9067 19.8898H13.1271C12.5046 19.8898 12 19.3852 12 18.7627V13.1271Z"
        fill="#9B9FFF"
      />
    </svg>
  );
};

export { Queued };
