export const HYP_INSIGHT_OVERVIEW_REPO_LOADING =
  'HYP_INSIGHT_OVERVIEW_REPO_LOADING';
export const HYP_INSIGHT_OVERVIEW_UPDATE_REPOS =
  'HYP_INSIGHT_OVERVIEW_UPDATE_REPOS';
export const HYP_INSIGHT_SET_REPO_LOADING_TO_TRUE =
  'HYP_INSIGHT_SET_REPO_LOADING_TO_TRUE';

  export const HYP_INSIGHT_OVERVIEW_REPO_DETAIL_LOADING =
    'HYP_INSIGHT_OVERVIEW_REPO_DETAIL_LOADING';
  export const HYP_INSIGHT_OVERVIEW_UPDATE_SELECTED_REPO =
    'HYP_INSIGHT_OVERVIEW_UPDATE_SELECTED_REPO';
  export const HYP_INSIGHT_OVERVIEW_REPO_DETAIL_FETCH_SUCCESS =
    'HYP_INSIGHT_OVERVIEW_REPO_DETAIL_FETCH_SUCCESS';
  export const HYP_INSIGHT_OVERVIEW_LOADING_MORE_REPO_DETAILS =
    'HYP_INSIGHT_OVERVIEW_LOADING_MORE_REPO_DETAILS';
