import React from 'react';

const Initiated = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="8" cy="8" r="8" fill="#033D8B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1645 3.90723C12.2989 3.93604 12.4141 4.05129 12.443 4.18574C12.4622 4.26257 12.8559 6.01043 11.2713 7.57582L10.1861 8.66103L10.0709 10.7354C10.0613 10.8411 10.0132 10.9371 9.93641 11.0043L8.98565 11.8014C8.91842 11.859 8.83199 11.8878 8.74556 11.8878C8.63992 11.8878 8.55348 11.859 8.47666 11.7822L7.50669 10.8122L5.52834 8.83389L4.55837 7.86393C4.42392 7.72948 4.41432 7.50859 4.53917 7.36454L5.33627 6.41378C5.40349 6.33695 5.49953 6.28893 5.60517 6.27933L7.67955 6.16408L8.76476 5.06927C8.78397 5.05487 8.80078 5.03806 8.81759 5.02125C8.83439 5.00445 8.8512 4.98764 8.87041 4.97323C9.9076 4.00327 11.012 3.84961 11.5882 3.84961C11.9244 3.84961 12.1356 3.89763 12.1645 3.90723ZM11.7513 4.60921C11.7801 5.01257 11.7225 5.83848 11.0887 6.67399L9.68652 5.27186C10.3972 4.73406 11.0983 4.59961 11.588 4.59961C11.6457 4.59961 11.7033 4.59961 11.7513 4.60921ZM9.13043 5.77148L10.5902 7.22163L7.77632 10.0259L6.32617 8.57575L9.13043 5.77148ZM8.77423 11.0226L9.34084 10.552L9.38886 9.4668L8.30365 10.552L8.77423 11.0226ZM5.6152 9.79359C5.47115 9.64953 5.23106 9.64953 5.087 9.79359L3.60804 11.2725C3.46399 11.4166 3.46399 11.6567 3.60804 11.8007C3.68487 11.8776 3.78091 11.916 3.87694 11.916C3.97298 11.916 4.06902 11.8776 4.14584 11.8103L5.6248 10.3314C5.75926 10.1777 5.75926 9.93764 5.6152 9.79359ZM6.02825 10.735L5.53846 11.2248C5.39441 11.3688 5.39441 11.6089 5.53846 11.753C5.61529 11.8298 5.71133 11.8586 5.80736 11.8586C5.9034 11.8586 5.99944 11.8202 6.07626 11.753L6.56605 11.2632C6.7101 11.1191 6.7101 10.879 6.56605 10.735C6.41239 10.5909 6.1723 10.5909 6.02825 10.735ZM6.88288 6.95312L5.79767 7.01075L5.32709 7.57736L5.79767 8.04794L6.88288 6.95312Z"
        fill="white"
      />
    </svg>
  );
};

export default Initiated;
