import {
  HYP_SCHEDULE_JOB_REQUEST,
  HYP_SCHEDULE_JOB_RESPONSE,
  HYP_SCHEDULE_SEARCH_TEXT,
  HYP_VALIDATE_REPO_URL_REQUEST,
  HYP_VALIDATE_REPO_URL_RESPONSE,
  HYP_VALIDATE_WORKFLOW_REQUEST,
  HYP_VALIDATE_WORKFLOW_RESPONSE,
  HYP_VALIDATION_RESET_ERRORS,
} from 'store/constants/scheduleJob';

const InitailState = {
  isLoading: false,
  jobs: [],
  searchedText: '',
  requestSucess: false,
  repoUrlError: {
    isLoading: false,
    error: '',
  },
  validationsError: {
    isLoading: false,
    error: {},
  },
};
function scheduleJobReducer(state = InitailState, action) {
  switch (action.type) {
    case HYP_SCHEDULE_JOB_REQUEST:
      return { ...state, loading: true };
    case HYP_SCHEDULE_JOB_RESPONSE:
      return {
        ...state,
        loading: false,
        jobs: action?.payload?.data,
        requestSucess: true,
      };
    case HYP_SCHEDULE_SEARCH_TEXT:
      return {
        ...state,
        searchedText: action?.payload,
      };
    case HYP_VALIDATE_REPO_URL_REQUEST:
      return {
        ...state,
        repoUrlError: {
          isLoading: true,
          error: '',
        },
      };
    case HYP_VALIDATE_REPO_URL_RESPONSE:
      const response = action?.payload?.response?.data;
      const error = response?.status === 'failed' ? response?.error : '';
      return {
        ...state,
        repoUrlError: {
          isLoading: false,
          error: error,
        },
      };
    case HYP_VALIDATE_WORKFLOW_REQUEST:
      return {
        ...state,
        validationsError: {
          isLoading: true,
          error: '',
        },
      };
    case HYP_VALIDATE_WORKFLOW_RESPONSE:
      const validationResponse = action?.payload?.response?.data;
      const validationError =
        validationResponse?.status === 'failed'
          ? validationResponse?.error
          : '';
      return {
        ...state,
        validationsError: {
          isLoading: false,
          error: validationError,
        },
      };
    case HYP_VALIDATION_RESET_ERRORS:
      return {
        ...state,
        repoUrlError: { ...InitailState.repoUrlError },
        validationsError: { ...InitailState.validationsError },
      };
    default:
      return state;
  }
}

export default scheduleJobReducer;
