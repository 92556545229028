const Approve = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M16.626 11.5875C16.866 11.2796 16.9989 10.9024 16.9989 10.5099C16.9989 9.88724 16.6403 9.29786 16.0632 8.96919C15.9146 8.88459 15.7454 8.84006 15.5732 8.84021H12.1475L12.2332 7.13585C12.2532 6.72397 12.1032 6.33289 11.8117 6.03473C11.6687 5.88777 11.4962 5.77084 11.3048 5.69118C11.1134 5.61151 10.9071 5.57079 10.6989 5.57154C9.95603 5.57154 9.29888 6.05692 9.10174 6.7517L7.8746 11.0646H7.87031V17.0001H14.6175C14.7489 17.0001 14.8775 16.9751 14.996 16.9252C15.676 16.6437 16.1146 15.9988 16.1146 15.2833C16.1146 15.1085 16.0889 14.9366 16.0375 14.7701C16.2775 14.4623 16.4103 14.0851 16.4103 13.6926C16.4103 13.5179 16.3846 13.3459 16.3332 13.1795C16.5732 12.8716 16.706 12.4944 16.706 12.102C16.7032 11.9272 16.6775 11.7539 16.626 11.5875ZM5.57031 11.5084V16.5563C5.57031 16.8018 5.7746 17.0001 6.02746 17.0001H6.95603V11.0646H6.02746C5.7746 11.0646 5.57031 11.2629 5.57031 11.5084Z"
        fill="#7AD87A"
      />
      <path
        d="M3.70674 13.6314H2.35337C1.60592 13.6314 1 13.0255 1 12.2781V2.80449C1 1.8079 1.8079 1 2.80449 1H12.6598C13.1964 1 13.6314 1.43502 13.6314 1.97165V2.9433V3.91495"
        stroke="#7AD87A"
        strokeWidth="1.42857"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Approve;
