const Skipped = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="42" cy="42" r="42" fill="#666666" />
      <path
        d="M27.0143 40.9889C25.7394 39.8764 23.804 40.0081 22.6916 41.283C21.5791 42.5579 21.7108 44.4933 22.9857 45.6057L27.0143 40.9889ZM37.2957 54.0262L35.2814 56.3347H35.2814L37.2957 54.0262ZM37.5829 54.0077L39.8768 56.0385L39.8768 56.0385L37.5829 54.0077ZM62.0159 31.0308C63.1375 29.7639 63.0197 27.8276 61.7528 26.7061C60.4859 25.5845 58.5497 25.7023 57.4281 26.9692L62.0159 31.0308ZM22.9857 45.6057L35.2814 56.3347L39.31 51.7178L27.0143 40.9889L22.9857 45.6057ZM39.8768 56.0385L62.0159 31.0308L57.4281 26.9692L35.289 51.9769L39.8768 56.0385ZM35.2814 56.3347C36.6344 57.5152 38.6865 57.383 39.8768 56.0385L35.289 51.9769C36.3305 50.8004 38.1261 50.6847 39.31 51.7178L35.2814 56.3347Z"
        fill="white"
      />
    </svg>
  );
};

export { Skipped };
