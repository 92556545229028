import axios from 'axios';
import { getLTMS_API } from 'new-theme/utils/onpremEndpoints';

export async function callApi({
  endpoint,
  method = 'GET',
  headers = {},
  query = {},
  body = null,
  type = null,
  token = null,
  transform = null,
  appendBaseUrl = true,
  signal, // for request cancellation
  auth = null, // For Basic auth
  withCredentials = false,
}) {
  const BASE_URL = getLTMS_API();
  if (typeof endpoint !== 'string') {
    throw new Error('Expected endpoint url to be string');
  }

  if (token) {
    headers['authorization'] = `Bearer ${token}`;
  }

  headers = {
    Accept: 'application/json',
    ...headers,
  };

  if (type === 'json') {
    headers['Content-Type'] = 'application/json';
  }
  let requestOptions = {
    url: appendBaseUrl ? endpoint : '',
    method: method,
    baseURL: appendBaseUrl ? BASE_URL : endpoint,
    // transformResponse: transform,
    headers: headers,
    params: query,
    data: body,
    signal,
    withCredentials: withCredentials,
  };
  if (auth) {
    requestOptions['auth'] = auth ? auth : null;
  }
  // console.log('requestOptions', requestOptions);
  return axios
    .request(requestOptions)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.data && error.data.message) {
        error.message = error.data.message;
      }
      throw error;
    });
}
