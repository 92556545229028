import React from 'react';

const ViewIcon = ({ width = '30', height = '30', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
    >
      <path
        d="M4.83706 14.2633C5.66189 13.2791 6.60509 12.4004 7.64506 11.6474C9.97306 9.94329 12.5171 9.03125 15.0131 9.03125C17.5091 9.03125 20.0531 9.94329 22.3811 11.6474C23.42 12.4018 24.363 13.2803 25.1891 14.2633L25.7891 15.0073L25.213 15.7513C24.3803 16.7365 23.4291 17.6152 22.3811 18.3672C20.0531 20.0713 17.5091 20.9833 15.0131 20.9833C12.5171 20.9833 9.9731 20.0713 7.6451 18.3672C6.60513 17.6142 5.66197 16.7355 4.8371 15.7513L4.2371 15.0073L4.83706 14.2633ZM5.58102 15.1513H5.58118C6.35244 16.0617 7.23084 16.8754 8.19714 17.5754C10.3571 19.1594 12.7331 20.0234 15.0131 20.0234C17.2931 20.0233 19.6451 19.1833 21.8291 17.5754C22.7969 16.8771 23.6753 16.063 24.4451 15.1513L24.5651 15.0073L24.4451 14.8633C23.6747 13.9523 22.7963 13.1382 21.8291 12.4393C19.6692 10.8552 17.2931 9.99122 15.0131 9.99122C12.7331 9.99122 10.3811 10.8313 8.19714 12.4393C7.23084 13.1392 6.35244 13.953 5.58118 14.8633L5.46115 15.0073L5.58102 15.1513Z"
        fill="black"
      />
      <path
        d="M15.0133 11.0703C16.0572 11.0703 17.0584 11.485 17.7964 12.2231C18.5345 12.9612 18.9492 13.9623 18.9492 15.0063C18.9492 16.0502 18.5345 17.0514 17.7964 17.7895C17.0584 18.5275 16.0572 18.9422 15.0133 18.9422C13.9693 18.9422 12.9682 18.5275 12.2301 17.7895C11.492 17.0514 11.0773 16.0502 11.0773 15.0063C11.0773 13.9623 11.492 12.9612 12.2301 12.2231C12.9682 11.485 13.9693 11.0703 15.0133 11.0703ZM15.0133 17.9823C15.8026 17.9823 16.5595 17.6688 17.1176 17.1106C17.6758 16.5525 17.9893 15.7956 17.9893 15.0062C17.9893 14.2169 17.6758 13.46 17.1176 12.9019C16.5595 12.3437 15.8026 12.0301 15.0133 12.0301C14.2239 12.0301 13.4671 12.3437 12.9089 12.9019C12.3508 13.46 12.0372 14.2169 12.0372 15.0062C12.0372 15.7956 12.3507 16.5524 12.9089 17.1106C13.467 17.6687 14.2239 17.9823 15.0133 17.9823Z"
        fill="black"
      />
    </svg>
  );
};

export default ViewIcon;
