import LoadingIcon from 'assets/images/onboarding/step-loader.gif';

export const SETTING_UP_ENVIRONMENT = '100';
export const PREPARING_FOR_TEST_DISCOVERY = '200';
export const DETECTING_TEST_DETAILS = '300';
export const FINALIZING_SETUP = '400';

export const FirstLoadInProgress = 'inprogress';
export const FirstLoadFailed = 'failed';
export const FirstLoadCompleted = 'completed';
export const FirstLoadInitiated = 'initiated';
export const FirstLoadUnInitiated = 'uninitiated';

export const FirstLoadProgress = {
  [FirstLoadInProgress]: {
    IMAGE: LoadingIcon,
  },
  [FirstLoadFailed]: {
    ICON: 'onboard/crossbold',
    CLASS: '',
  },
  [FirstLoadCompleted]: {
    ICON: 'check-bold',
  },
};

export const FirstLoadOrder = [
  SETTING_UP_ENVIRONMENT,
  PREPARING_FOR_TEST_DISCOVERY,
  DETECTING_TEST_DETAILS,
  FINALIZING_SETUP,
];

export const FirstLoadProjectStages = {
  [SETTING_UP_ENVIRONMENT]: {
    label: 'Setting Up The Environment',
    status: FirstLoadInProgress,
    id: 'SETTING_UP_ENVIRONMENT',
  },
  [PREPARING_FOR_TEST_DISCOVERY]: {
    label: 'Preparing For Test Discovery',
    status: '',
    id: 'PREPARING_FOR_TEST_DISCOVERY',
  },
  [DETECTING_TEST_DETAILS]: {
    label: 'Detecting Test Details',
    status: '',
    id: 'DETECTING_TEST_DETAILS',
  },
  [FINALIZING_SETUP]: {
    label: 'Finalising Setup',
    status: '',
    id: 'FINALIZING_SETUP',
  },
};
