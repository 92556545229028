import React from 'react';

const CheckBoldGreen = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.99937 13.6139L1.85938 9.47391L3.74604 7.58724L5.99937 9.84724L12.586 3.25391L14.4727 5.14057L5.99937 13.6139Z"
        fill="#176F2C"
      />
    </svg>
  );
};

export default CheckBoldGreen;
