import React from 'react';

const PJTIimeout = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <g id="Clock">
        <path
          id="Vector"
          d="M4.9983 9.00003C6.98646 9.00003 8.59817 7.38831 8.59817 5.40016C8.59817 3.41201 6.98646 1.80029 4.9983 1.80029C3.01015 1.80029 1.39844 3.41201 1.39844 5.40016C1.39844 7.38831 3.01015 9.00003 4.9983 9.00003Z"
          stroke="#333333"
          strokeWidth="0.980392"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M6.40054 4L5 5.40054"
          stroke="#333333"
          strokeWidth="0.980392"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M1 2.20047L2.20047 1"
          stroke="#333333"
          strokeWidth="0.980392"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M7.79688 1L8.99734 2.20047"
          stroke="#333333"
          strokeWidth="0.980392"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M5 1.7997L5 1"
          stroke="#333333"
          strokeWidth="0.980392"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default PJTIimeout;
