import { useSelector } from 'react-redux';

const useRemainingMinutes = () => {
  let remainingMinutes = '';
  const userInfo = useSelector((state) => state.userInfo?.userInfo);
  const consumedTime = useSelector((state) => state.userInfo?.consumedTime);
  let userPlan = userInfo?.data?.organization?.plan_attributes;

  remainingMinutes =
    parseInt(userPlan?.MAX_HYPEREXECUTE_MINUTES) -
    parseInt(consumedTime?.data?.data || 0);

  const minuteFormatter = (num) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(0) + 'k'
      : Math.sign(num) * Math.abs(num);
  };

  return {
    remainingMinutes,
    kFormatedMinutes: minuteFormatter(remainingMinutes),
  };
};

export default useRemainingMinutes;
