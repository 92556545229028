import React from 'react';

const Timeout = ({
  width,
  height,
  className,
  id = 'clip0_862_1927',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle
        cx="21.0077"
        cy="21.0076"
        r="19.5"
        transform="rotate(-0.024375 21.0077 21.0076)"
        stroke="#FF5D2A"
        strokeWidth="3"
      />
      <g clipPath={`url(#${id})`}>
        <path
          d="M21.5 29.25C25.8492 29.25 29.375 25.7242 29.375 21.375C29.375 17.0258 25.8492 13.5 21.5 13.5C17.1508 13.5 13.625 17.0258 13.625 21.375C13.625 25.7242 17.1508 29.25 21.5 29.25Z"
          stroke="#FF5D2A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.5625 18.3125L21.5 21.375"
          stroke="#FF5D2A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.625 26.625L28.5 29.25"
          stroke="#FF5D2A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.375 26.625L14.5 29.25"
          stroke="#FF5D2A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.75 14.375L15.375 11.75"
          stroke="#FF5D2A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.625 11.75L30.25 14.375"
          stroke="#FF5D2A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.5 13.5L21.5 11.75"
          stroke="#FF5D2A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect
            width="21"
            height="21"
            fill="white"
            transform="translate(11 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Timeout;
