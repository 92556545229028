const StageSkipped = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="47" cy="47" r="44" stroke="#666666" strokeWidth="6" />
      <path
        d="M32.3787 46.0059C31.1302 44.9166 29.2351 45.0456 28.1458 46.294C27.0565 47.5424 27.1854 49.4375 28.4338 50.5268L32.3787 46.0059ZM42.4055 58.7367L40.4331 60.9971L40.4331 60.9971L42.4055 58.7367ZM42.6928 58.7182L44.939 60.7067L44.939 60.7067L42.6928 58.7182ZM66.5478 36.2981C67.646 35.0575 67.5307 33.1615 66.2901 32.0633C65.0496 30.965 63.1536 31.0804 62.0553 32.3209L66.5478 36.2981ZM28.4338 50.5268L40.4331 60.9971L44.378 56.4762L32.3787 46.0059L28.4338 50.5268ZM44.939 60.7067L66.5478 36.2981L62.0553 32.3209L40.4465 56.7296L44.939 60.7067ZM40.4331 60.9971C41.7598 62.1547 43.7719 62.0251 44.939 60.7067L40.4465 56.7296C41.4648 55.5793 43.2205 55.4662 44.378 56.4762L40.4331 60.9971Z"
        fill="#666666"
      />
    </svg>
  );
};

export { StageSkipped };
