export function getRCA(data) {
  let rca = [];

  if (data?.rca && data?.rca?.toLowerCase() !== 'na') {
    rca.push({ title: 'RCA', data: data?.rca });
  }

  if (data?.remediation && data?.remediation?.toLowerCase() !== 'na') {
    rca.push({ title: 'Remediation', data: data?.remediation });
  }

  if (
    data?.additional_suggestions &&
    data?.additional_suggestions?.toLowerCase() !== 'na'
  ) {
    rca.push({
      title: 'Additional suggestions',
      data: data?.additional_suggestions,
    });
  }

  // if (data?.patch_diff && data?.patch_diff?.toLowerCase() !== 'na') {
  //   rca += `**#Patch Difference** \n\n ${data?.patch_diff} \n\n`;
  // }

  return rca.length ? rca : '';
}
