import React from 'react';

const Accesskey = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M6.66666 4.00001H14.6667V6.00001M6.66666 4.00001C6.66666 4.70725 6.38571 5.38553 5.88561 5.88563C5.38552 6.38573 4.70724 6.66668 3.99999 6.66668C3.29275 6.66668 2.61447 6.38573 2.11438 5.88563C1.61428 5.38553 1.33333 4.70725 1.33333 4.00001C1.33333 3.29277 1.61428 2.61449 2.11438 2.11439C2.61447 1.61429 3.29275 1.33334 3.99999 1.33334C4.70724 1.33334 5.38552 1.61429 5.88561 2.11439C6.38571 2.61449 6.66666 3.29277 6.66666 4.00001V4.00001Z"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { Accesskey };
