import React from 'react';

const Running = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="7.99998" cy="7.99998" r="6.66667" fill="#409FF6" />
      <path
        d="M11.9999 7.99996C11.9999 7.20883 11.7653 6.43548 11.3258 5.77768C10.8863 5.11989 10.2616 4.6072 9.53067 4.30445C8.79978 4.00171 7.99552 3.9225 7.2196 4.07684C6.44369 4.23119 5.73096 4.61216 5.17156 5.17157C4.61215 5.73098 4.23119 6.44372 4.07684 7.21964C3.9225 7.99556 4.00171 8.79983 4.30445 9.53073C4.60719 10.2616 5.11987 10.8863 5.77766 11.3259C6.43545 11.7654 7.2088 12 7.99991 12"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Running;
