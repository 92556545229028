import React from 'react';

const JavaIcon = ({ width = 20, height = 20, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M7.60577 14.9197C7.60577 14.9197 6.90846 15.3253 8.10204 15.4625C9.54806 15.6274 10.2871 15.6038 11.8806 15.3022C11.8806 15.3022 12.2996 15.5649 12.8846 15.7924C9.31242 17.3234 4.79996 15.7037 7.60577 14.9197ZM7.16925 12.9219C7.16925 12.9219 6.38715 13.5008 7.58163 13.6244C9.12629 13.7837 10.3461 13.7968 12.457 13.3903C12.457 13.3903 12.7489 13.6863 13.208 13.8482C8.88896 15.1111 4.07835 13.9478 7.16925 12.9219Z"
        fill="#666"
      />
      <path
        d="M10.8513 9.53333C11.7315 10.5467 10.62 11.4586 10.62 11.4586C10.62 11.4586 12.855 10.3049 11.8286 8.86006C10.8699 7.51279 10.1347 6.84327 14.1146 4.53516C14.1146 4.53516 7.86757 6.09535 10.8513 9.53327"
        fill="#666"
      />
      <path
        d="M15.5748 16.3977C15.5748 16.3977 16.0908 16.8228 15.0065 17.1518C12.9446 17.7764 6.4245 17.965 4.61324 17.1766C3.96216 16.8935 5.18316 16.5004 5.56721 16.4178C5.96775 16.331 6.19668 16.3472 6.19668 16.3472C5.47259 15.8371 1.51647 17.3488 4.18718 17.7817C11.4705 18.9628 17.464 17.2498 15.5748 16.3977ZM7.94216 10.8521C7.94216 10.8521 4.62571 11.6398 6.76777 11.9258C7.67218 12.0469 9.47515 12.0196 11.1546 11.8788C12.5271 11.7631 13.9053 11.5169 13.9053 11.5169C13.9053 11.5169 13.4213 11.7242 13.0712 11.9632C9.70334 12.849 3.19723 12.4369 5.07025 11.5309C6.65428 10.7652 7.94222 10.8521 7.94222 10.8521H7.94216ZM13.8916 14.1776C17.3153 12.3985 15.7323 10.6889 14.6274 10.9193C14.3566 10.9756 14.2359 11.0244 14.2359 11.0244C14.2359 11.0244 14.3364 10.867 14.5284 10.7988C16.7142 10.0303 18.3952 13.0652 13.8228 14.2672C13.8228 14.2673 13.8758 14.2199 13.8916 14.1776Z"
        fill="#666"
      />
      <path
        d="M11.8286 0.808647C11.8286 0.808647 13.7247 2.7054 10.0303 5.6219C7.06786 7.9615 9.35479 9.29546 10.0291 10.8196C8.29985 9.25938 7.03077 7.8859 7.8822 6.60763C9.13178 4.73111 12.5936 3.82136 11.8286 0.808594"
        fill="#666"
      />
      <path
        d="M8.27808 18.9998C11.5643 19.2102 16.6107 18.8831 16.7302 17.3281C16.7302 17.3281 16.5005 17.9176 14.0143 18.3858C11.2094 18.9136 7.75006 18.852 5.69824 18.5137C5.6983 18.5137 6.11827 18.8613 8.27814 18.9998"
        fill="#666"
      />
    </svg>
  );
};

export default JavaIcon;
