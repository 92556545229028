export const HYPERTEST_TASK_LOG_ACTIONS_REQUEST =
  'HYPERTEST_TASK_LOG_ACTIONS_REQUEST';
export const HYPERTEST_TASK_LOG_ACTIONS_RESPONSE =
  'HYPERTEST_TASK_LOG_ACTIONS_RESPONSE';
export const HYPERTEST_TASK_LOG_ACTIONS_SILENT_REQUEST =
  'HYPERTEST_TASK_LOG_ACTIONS_SILENT_REQUEST';
export const HYPERTEST_TASK_LOG_RESET = 'HYPERTEST_TASK_LOG_RESET';
export const HYPERTEST_SET_ACTIVE_STAGE_LOG = 'HYPERTEST_SET_ACTIVE_STAGE_LOG';
export const HYPERTEST_SET_CURRENT_RUNNING_LOGTYPE =
  'HYPERTEST_SET_CURRENT_RUNNING_LOGTYPE';
