import React from 'react';

const Github = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0.197266C3.58 0.197266 0 3.77727 0 8.19727C0 11.7373 2.29 14.7273 5.47 15.7873C5.87 15.8573 6.02 15.6173 6.02 15.4073C6.02 15.2173 6.01 14.5873 6.01 13.9173C4 14.2873 3.48 13.4273 3.32 12.9773C3.23 12.7473 2.84 12.0373 2.5 11.8473C2.22 11.6973 1.82 11.3273 2.49 11.3173C3.12 11.3073 3.57 11.8973 3.72 12.1373C4.44 13.3473 5.59 13.0073 6.05 12.7973C6.12 12.2773 6.33 11.9273 6.56 11.7273C4.78 11.5273 2.92 10.8373 2.92 7.77727C2.92 6.90727 3.23 6.18727 3.74 5.62727C3.66 5.42727 3.38 4.60727 3.82 3.50727C3.82 3.50727 4.49 3.29727 6.02 4.32727C6.66 4.14727 7.34 4.05727 8.02 4.05727C8.7 4.05727 9.38 4.14727 10.02 4.32727C11.55 3.28727 12.22 3.50727 12.22 3.50727C12.66 4.60727 12.38 5.42727 12.3 5.62727C12.81 6.18727 13.12 6.89726 13.12 7.77727C13.12 10.8473 11.25 11.5273 9.47 11.7273C9.76 11.9773 10.01 12.4573 10.01 13.2073C10.01 14.2773 10 15.1373 10 15.4073C10 15.6173 10.15 15.8673 10.55 15.7873C12.1381 15.2511 13.5181 14.2304 14.4958 12.8688C15.4734 11.5073 15.9995 9.87346 16 8.19727C16 3.77727 12.42 0.197266 8 0.197266Z"
        fill={color ? color : '#666666'}
      />
    </svg>
  );
};

export { Github };
