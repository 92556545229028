export const REAL_DEVICE_TASK = 'meta';

export const IOS_TEST_TYPE = 'ios/xcui';
export const ANDROID_TEST_TYPE = 'android/espresso';

export const REAL_DEVICE_TYPES = [IOS_TEST_TYPE, ANDROID_TEST_TYPE];

export const JobStatus = {
  COMPLETED: 'completed',
};

// In case of CDN Logs
export const LogsRetryStatuses = [
  'failed',
  'completed',
  'skipped',
  'cancelled',
  'aborted',
  'lambda_error',
  'timeout',
  'log-available',
];

export const HYPERTEST_LOGS_TYPE = [
  { id: 1, name: 'Pre Steps', key: 'prerun', order: 1 },
  { id: 2, name: 'Scenarios', key: 'scenario', order: 2 },
  { id: 3, name: 'Post Steps', key: 'postrun', order: 3 },
  { id: 4, name: 'Background Services', key: 'backgroundservice', order: 4 },
];

export const HYPERTEST_LOGS_TYPE_FOR_DEX = [
  { id: 2, name: 'Scenarios', key: 'scenario', order: 2 },
  { id: 4, name: 'Background Services', key: 'backgroundservice', order: 4 },
];

// key scenario is used under Tests to minimize the work effort.
// bcz everywhere we have added check like something === scenario.
// in future when we have time, we can address this.
export const HYPERTEST_REAL_DEVICES_LOGS_TYPE = [
  { id: 2, name: 'Tests', key: 'scenario', order: 2 },
];

export const STATUS_MESSAGE = {
  NO_FAILURE: 'No Failures',
  FAILURE: 'Failures',
  RUNNING: 'Running',
};

export const DOWNLOAD_LOG_TEXT = 'Download Logs';
export const LOGS_MINIMUM_SELECTION_ALLOWED = 100;
export const SELECTION_TIMEOUT_DELAY = 100;
