import React from 'react';

const CloseIcon = ({ width, height, className, onClick, fill = 'none' }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      viewBox="0 0 11 11"
      fill={fill}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path d="M6.78333 4.99994L10.8333 0.949942C10.9243 0.843663 10.9719 0.706954 10.9665 0.567134C10.9611 0.427314 10.9031 0.294681 10.8042 0.19574C10.7053 0.0967987 10.5726 0.0388359 10.4328 0.0334353C10.293 0.0280347 10.1563 0.0755938 10.05 0.166608L6 4.21661L1.95 0.161053C1.84372 0.0700382 1.70701 0.0224795 1.56719 0.0278801C1.42737 0.0332808 1.29474 0.091243 1.1958 0.190184C1.09686 0.289126 1.03889 0.421759 1.03349 0.561579C1.02809 0.701399 1.07565 0.838108 1.16667 0.944386L5.21667 4.99994L1.16111 9.04994C1.10295 9.09975 1.05572 9.16103 1.02237 9.22996C0.989027 9.29888 0.970288 9.37396 0.967333 9.45047C0.964378 9.52698 0.97727 9.60327 1.0052 9.67457C1.03313 9.74586 1.0755 9.81061 1.12964 9.86475C1.18378 9.91889 1.24853 9.96125 1.31982 9.98918C1.39111 10.0171 1.46741 10.03 1.54392 10.0271C1.62043 10.0241 1.6955 10.0054 1.76443 9.97201C1.83335 9.93867 1.89464 9.89143 1.94444 9.83328L6 5.78328L10.05 9.83328C10.1563 9.92429 10.293 9.97185 10.4328 9.96645C10.5726 9.96105 10.7053 9.90309 10.8042 9.80414C10.9031 9.7052 10.9611 9.57257 10.9665 9.43275C10.9719 9.29293 10.9243 9.15622 10.8333 9.04994L6.78333 4.99994Z" />
    </svg>
  );
};

export { CloseIcon };
