import React from 'react';

const TaskIcon = ({
  width = '14px',
  height = '16px',
  color = '#666666',
  className,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      className={className}
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M8.5 7.5C9.32843 7.5 10 6.82843 10 6C10 5.17157 9.32843 4.5 8.5 4.5C7.67157 4.5 7 5.17157 7 6C7 6.82843 7.67157 7.5 8.5 7.5Z"
        stroke={color}
        strokeWidth="1.2"
      />
      <path
        d="M10.5 14C11.3284 14 12 13.3284 12 12.5C12 11.6716 11.3284 11 10.5 11C9.67157 11 9 11.6716 9 12.5C9 13.3284 9.67157 14 10.5 14Z"
        stroke={color}
        strokeWidth="1.2"
      />
      <path
        d="M2.54827 1.85647L0.314452 1.85741C0.314452 1.85741 1.31457 1.85895 2.54827 1.85843C3.78198 1.85791 4.78209 1.85552 4.78209 1.85552L2.54827 1.85647Z"
        fill="#DDDDDD"
        stroke={color}
        strokeWidth="1.2"
      />
      <path
        d="M3 3V9.04545C3 10.6739 3 11.4882 3.45561 11.9941C3.91122 12.5 4.64452 12.5 6.11111 12.5H9.5"
        stroke={color}
        strokeWidth="1.2"
      />
      <path
        d="M3 2C3 3.88562 3 4.82843 3.45561 5.4142C3.91122 6 4.64452 6 6.11111 6H7"
        stroke={color}
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default TaskIcon;
