import React from 'react';

const Quickstart = ({
  width = '16px',
  height = '16px',
  className,
  color = '#666666',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill="none"
      className={className}
      {...rest}
      role="img"
      aria-hidden="true"
    >
      <path
        d="M10.7615 10.8231C10.9217 11.5008 10.9264 12.206 10.7754 12.8859C10.6243 13.5657 10.3213 14.2025 9.88916 14.7486C9.457 15.2948 8.90686 15.736 8.27996 16.0393C7.65306 16.3426 6.96565 16.5001 6.26923 16.5V12.8077M10.7615 10.8231C12.2307 9.75291 13.4258 8.35038 14.2494 6.73006C15.0729 5.10974 15.5014 3.31759 15.5 1.5C13.6825 1.49869 11.8905 1.9273 10.2704 2.75082C8.65019 3.57435 7.24778 4.76943 6.17769 6.23846M10.7615 10.8231C9.42454 11.801 7.89255 12.478 6.26923 12.8077M6.26923 12.8077C6.19 12.8238 6.11 12.8392 6.03 12.8538C5.33214 12.3005 4.70029 11.6686 4.14692 10.9708C4.16145 10.8906 4.17607 10.8106 4.19231 10.7308M6.17769 6.23846C5.49989 6.07815 4.7946 6.07332 4.11467 6.22434C3.43474 6.37536 2.7978 6.6783 2.25159 7.11047C1.70539 7.54264 1.26408 8.09283 0.96073 8.7198C0.657384 9.34677 0.499873 10.0343 0.5 10.7308H4.19231M6.17769 6.23846C5.19991 7.57524 4.52212 9.10773 4.19231 10.7308M2.47 12.5692C1.96398 12.9455 1.57056 13.4531 1.33233 14.0369C1.09409 14.6208 1.02014 15.2587 1.11846 15.8815C1.74141 15.9798 2.37936 15.9057 2.96321 15.6673C3.54707 15.429 4.05458 15.0354 4.43077 14.5292M11.4615 6.69231C11.4615 6.99833 11.34 7.29181 11.1236 7.5082C10.9072 7.72459 10.6137 7.84616 10.3077 7.84616C10.0017 7.84616 9.70819 7.72459 9.4918 7.5082C9.27541 7.29181 9.15384 6.99833 9.15384 6.69231C9.15384 6.38629 9.27541 6.09281 9.4918 5.87642C9.70819 5.66003 10.0017 5.53846 10.3077 5.53846C10.6137 5.53846 10.9072 5.66003 11.1236 5.87642C11.34 6.09281 11.4615 6.38629 11.4615 6.69231Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { Quickstart };
