const RubyIconColored = ({ width = 20, height = 20, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <rect width="20" height="20" fill="white" />
      <path
        d="M13.8955 12.2624L5.80078 17.0689L16.2826 16.36L17.0892 5.78906L13.8955 12.2624Z"
        fill="url(#paint0_linear_726_10794)"
      />
      <path
        d="M16.3 16.3505L15.3992 10.1328L12.9453 13.3736L16.3 16.3505Z"
        fill="url(#paint1_linear_726_10794)"
      />
      <path
        d="M5.84462 17.0567L7.49335 11.6553L3.86523 12.43L5.84462 17.0567Z"
        fill="url(#paint2_linear_726_10794)"
      />
      <path
        d="M12.9446 13.3924L11.4255 7.4502L7.08594 11.5188L12.9446 13.3924Z"
        fill="url(#paint3_linear_726_10794)"
      />
      <path
        d="M16.7444 7.5323L12.6429 4.18066L11.5 7.87713L16.7444 7.5323Z"
        fill="url(#paint4_linear_726_10794)"
      />
      <path
        d="M14.8252 2.986L12.4113 4.31977L10.8887 2.96777L14.8252 2.986Z"
        fill="url(#paint5_linear_726_10794)"
      />
      <path
        d="M2.91211 14.2439L3.92484 12.3997L3.10554 10.2031L2.91211 14.2439Z"
        fill="url(#paint6_linear_726_10794)"
      />
      <path
        d="M3.04883 10.1332L3.87218 12.4676L7.44813 11.6655L11.5305 7.8733L12.6829 4.21177L10.8686 2.93066L7.78436 4.08518C6.81214 4.98702 4.92643 6.77702 4.85858 6.80993C4.79073 6.84285 3.61343 9.07035 3.04883 10.1327V10.1332Z"
        fill="white"
      />
      <path
        d="M5.94011 5.93931C8.04609 3.85157 10.7602 2.61755 11.8028 3.67029C12.8454 4.72302 11.74 7.27612 9.63405 9.36386C7.52807 11.4516 4.84636 12.7514 3.80527 11.7002C2.76419 10.649 3.83414 8.02503 5.94011 5.93931V5.93931Z"
        fill="url(#paint7_linear_726_10794)"
      />
      <path
        d="M5.8457 17.0548L7.48177 11.6367L12.9146 13.3822C10.9504 15.2243 8.76541 16.7814 5.8457 17.0553V17.0548Z"
        fill="url(#paint8_linear_726_10794)"
      />
      <path
        d="M11.5396 7.86013L12.9341 13.3846C14.5747 11.6594 16.0477 9.80458 16.7688 7.51074L11.5391 7.86064L11.5396 7.86013Z"
        fill="url(#paint9_linear_726_10794)"
      />
      <path
        d="M16.753 7.53798C17.31 5.8533 17.4402 3.43641 14.8081 2.98828L12.6484 4.18077L16.753 7.53747V7.53798Z"
        fill="url(#paint10_linear_726_10794)"
      />
      <path
        d="M2.91211 14.2192C2.98958 16.9991 4.9953 17.0407 5.84903 17.065L3.8742 12.457L2.91211 14.2192V14.2192Z"
        fill="#9E1209"
      />
      <path
        d="M11.5469 7.86878C12.8077 8.64403 15.3492 10.1981 15.4003 10.2289C15.9931 9.40916 16.4416 8.49418 16.7265 7.52344L11.5469 7.86929V7.86878Z"
        fill="url(#paint11_radial_726_10794)"
      />
      <path
        d="M7.47852 11.6367L9.66551 15.8558C10.8567 15.1923 11.9457 14.36 12.8987 13.3847L7.48054 11.6367H7.47852Z"
        fill="url(#paint12_radial_726_10794)"
      />
      <path
        d="M3.86654 12.4619L3.55664 16.1518C4.14149 16.9503 4.9456 17.0197 5.78921 16.9574C5.17904 15.4383 3.9592 12.4001 3.86502 12.4619H3.86654Z"
        fill="url(#paint13_linear_726_10794)"
      />
      <path
        d="M12.6367 4.19057L16.9813 4.79821C16.7494 3.81586 16.0375 3.18189 14.8237 2.9834L12.6367 4.18855V4.19057Z"
        fill="url(#paint14_linear_726_10794)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_726_10794"
          x1="15.3307"
          y1="18.3493"
          x2="12.3541"
          y2="13.0079"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FB7655" />
          <stop offset="0.41" stop-color="#E42B1E" />
          <stop offset="0.99" stop-color="#990000" />
          <stop offset="1" stop-color="#990000" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_726_10794"
          x1="16.8545"
          y1="13.9147"
          x2="12.6568"
          y2="13.0879"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#871101" />
          <stop offset="0.99" stop-color="#911209" />
          <stop offset="1" stop-color="#911209" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_726_10794"
          x1="5.689"
          y1="12.0493"
          x2="6.93197"
          y2="15.7136"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.23" stop-color="#E57252" />
          <stop offset="0.46" stop-color="#DE3B20" />
          <stop offset="0.99" stop-color="#A60003" />
          <stop offset="1" stop-color="#A60003" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_726_10794"
          x1="9.82615"
          y1="8.38826"
          x2="10.0456"
          y2="12.3367"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.23" stop-color="#E4714E" />
          <stop offset="0.56" stop-color="#BE1A0D" />
          <stop offset="0.99" stop-color="#A80D00" />
          <stop offset="1" stop-color="#A80D00" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_726_10794"
          x1="13.4798"
          y1="4.69094"
          x2="13.7914"
          y2="7.54972"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.18" stop-color="#E46342" />
          <stop offset="0.4" stop-color="#C82410" />
          <stop offset="0.99" stop-color="#A80D00" />
          <stop offset="1" stop-color="#A80D00" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_726_10794"
          x1="11.3743"
          y1="3.80848"
          x2="11.8376"
          y2="1.82739"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.54" stop-color="#C81F11" />
          <stop offset="0.99" stop-color="#BF0905" />
          <stop offset="1" stop-color="#BF0905" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_726_10794"
          x1="3.22741"
          y1="11.0861"
          x2="4.45985"
          y2="11.8756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.31" stop-color="#DE4024" />
          <stop offset="0.99" stop-color="#BF190B" />
          <stop offset="1" stop-color="#BF190B" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_726_10794"
          x1="1.65277"
          y1="13.9995"
          x2="12.6086"
          y2="2.62763"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BD0012" />
          <stop offset="0.07" stop-color="white" />
          <stop offset="0.17" stop-color="white" />
          <stop offset="0.27" stop-color="#C82F1C" />
          <stop offset="0.33" stop-color="#820C01" />
          <stop offset="0.46" stop-color="#A31601" />
          <stop offset="0.72" stop-color="#B31301" />
          <stop offset="0.99" stop-color="#E82609" />
          <stop offset="1" stop-color="#E82609" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_726_10794"
          x1="9.98049"
          y1="15.2474"
          x2="6.84687"
          y2="13.9109"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8C0C01" />
          <stop offset="0.54" stop-color="#990C00" />
          <stop offset="0.99" stop-color="#A80D0E" />
          <stop offset="1" stop-color="#A80D0E" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_726_10794"
          x1="15.7144"
          y1="11.142"
          x2="12.5071"
          y2="8.88544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7E110B" />
          <stop offset="0.99" stop-color="#9E0C00" />
          <stop offset="1" stop-color="#9E0C00" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_726_10794"
          x1="16.7654"
          y1="6.31604"
          x2="15.2325"
          y2="4.78187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#79130D" />
          <stop offset="0.99" stop-color="#9E120B" />
          <stop offset="1" stop-color="#9E120B" />
        </linearGradient>
        <radialGradient
          id="paint11_radial_726_10794"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.2378 8.56689) scale(3.58334 1.87243)"
        >
          <stop stop-color="#A80D00" />
          <stop offset="0.99" stop-color="#7E0E08" />
          <stop offset="1" stop-color="#7E0E08" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_726_10794"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.27331 13.3366) scale(4.78571 3.72546)"
        >
          <stop stop-color="#A30C00" />
          <stop offset="0.99" stop-color="#800E08" />
          <stop offset="1" stop-color="#800E08" />
        </radialGradient>
        <linearGradient
          id="paint13_linear_726_10794"
          x1="4.77343"
          y1="17.0365"
          x2="2.09807"
          y2="14.8005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B2114" />
          <stop offset="0.43" stop-color="#9E100A" />
          <stop offset="0.99" stop-color="#B3100C" />
          <stop offset="1" stop-color="#B3100C" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_726_10794"
          x1="14.0328"
          y1="3.64865"
          x2="14.7893"
          y2="5.5678"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B31000" />
          <stop offset="0.44" stop-color="#910F08" />
          <stop offset="0.99" stop-color="#791C12" />
          <stop offset="1" stop-color="#791C12" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RubyIconColored;
