import React from 'react';

const PJBack = ({ width = '6', height = '10', className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 6 10"
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M5 1L1 5L5 9"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PJBack;
