import React from 'react';

const LeftChevron = ({ width = '8', height = '12', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.02744 11.0606L0.971436 6.00461L6.02744 0.948608L7.03859 1.95976L2.99379 6.00456L7.03859 10.0494L6.02744 11.0606Z"
        fill="#666666"
      />
    </svg>
  );
};

export default LeftChevron;
