import React, { useEffect, useLayoutEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import LoaderData from 'assets/lottie/loader.json';

import './style.css';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoaderData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const LOADER_SIZE = 90;
const SIDE_BAR_WIDTH = 180;
const SIDEBAR_COLLAPSE_IN = 1280;
const SIDEBAR_COLLAPSED_WIDTH = 52;

export default function Loader({
  fullSize = true,
  isSuspenseLoader = false,
  isOverlay = false,
  size = LOADER_SIZE,
}) {
  let className = fullSize ? 'full-size-loader' : 'lt-round-loader';
  if (isSuspenseLoader) {
    className += ' view-port-size';
  }
  if (isOverlay) {
    className += ' loader-overlay';
  }

  const loaderBoxRef = useRef();

  useEffect(() => {
    const elems = document.getElementsByClassName('full-size-loader');

    if (elems) {
      [...elems].forEach((e) => {
        e.childNodes[0].setAttribute('tabindex', '-1');
        e.childNodes[0].setAttribute('aria-hidden', 'true');
        e?.childNodes?.[0]?.childNodes?.[0]?.setAttribute('role', 'img');
        e?.childNodes?.[0]?.childNodes?.[0]?.setAttribute('alt', '');
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (isSuspenseLoader) {
      let sideBarWidth = SIDE_BAR_WIDTH;
      const sideBar = document.getElementById('left_sidebar_header');

      const availableWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      if (sideBar) {
        sideBarWidth = sideBar.offsetWidth;
      } else if (availableWidth <= SIDEBAR_COLLAPSE_IN) {
        sideBarWidth = SIDEBAR_COLLAPSED_WIDTH;
      }

      const loaderContainerWidth = availableWidth - sideBarWidth;

      loaderBoxRef.current.style.width = `${loaderContainerWidth}px`;
      loaderBoxRef.current.style.left = `${sideBarWidth}px`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className} ref={loaderBoxRef}>
      <Lottie options={defaultOptions} height={size} width={size} />
    </div>
  );
}
