import PlanInfo from 'components/Common/PlanInfo';

function CustomHeader() {
  return (
    <div className="common-header-hyp-info">
      <span className="common-header-title">HyperExecute</span>
      <PlanInfo />
    </div>
  );
}

export default CustomHeader;
