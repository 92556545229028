import React from 'react';

const Minus = ({ width = '10', height = '2', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 10 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M9.66536 1.66927H0.332031V0.335938H9.66536V1.66927Z"
        fill="#999999"
      />
    </svg>
  );
};

export default Minus;
