import React from 'react';

const ScrollDownArrow = ({
  width = 16,
  height = 16,
  className,
  onClick,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      aria-hidden="true"
      role="img"
      {...rest}
    >
      <path
        d="M12 6L8 10L4 6"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { ScrollDownArrow };
