import React from 'react';

const ScrollDownArrow = ({ width, height, className, onClick, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      viewBox="0 0 62 62"
      aria-hidden="true"
    >
      <circle
        cx="31"
        cy="31"
        r="29.5"
        transform="rotate(-180 31 31)"
        fill="#222222"
        stroke="#464746"
        strokeWidth="3"
      />
      <path
        d="M40.5772 29.6024C40.5779 29.8514 40.5305 30.0975 40.4383 30.3225C40.3462 30.5475 40.2117 30.7457 40.0447 30.9026L31.4091 38.954C31.1516 39.1992 30.8286 39.3333 30.4952 39.3333C30.1618 39.3333 29.8388 39.1992 29.5813 38.954L20.9457 30.6192C20.6518 30.3363 20.467 29.9297 20.4319 29.4889C20.3968 29.0481 20.5143 28.6092 20.7586 28.2688C21.0029 27.9284 21.354 27.7143 21.7345 27.6736C22.1151 27.633 22.494 27.7691 22.788 28.0521L30.5024 35.5034L38.2168 28.3021C38.4281 28.0983 38.6853 27.9688 38.9581 27.929C39.231 27.8892 39.5079 27.9407 39.7562 28.0775C40.0045 28.2143 40.2138 28.4306 40.3593 28.7008C40.5048 28.9711 40.5804 29.2839 40.5772 29.6024Z"
        fill="#919191"
      />
    </svg>
  );
};

export { ScrollDownArrow };
