import {
  HYPERTEST_JOB_LIST_ACTIONS_REQUEST,
  HYPERTEST_JOB_LIST_ACTIONS_RESPONSE,
  HYPERTEST_JOB_ABORT_ACTION_REQUEST,
  HYPERTEST_JOB_ABORT_ACTION_RESPONSE,
  HYPERTEST_UPDATE_JOB_LIST_ACTION,
  HYPERTEST_CLEAR_JOB_LIST,
  HYPERTEST_JOB_LIST_ACTIONS_REQUEST_SSE,
  HYPERTEST_JOB_INSERT_INTO_JOB_LIST_ACTION,
  HYPERTEST_SET_JOB_LIST_VISUAL,
  HYPERTEST_JOB_TAGS_LIST_ACTIONS_REQUEST,
  HYPERTEST_JOB_TAGS_LIST_ACTIONS_RESPONSE,
  HYPERTEST_SET_CURRENT_SELECTED_JOB_TAGS,
  HYPERTEST_REMOVE_JOB,
  HYPERTEST_UPDATE_CLICKED_JOB_ID,
} from '../constants/hypertestJobList';
import { filterTrack } from './filters';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import {
  ARCHIVE_JOB_REQUEST,
  ARCHIVE_JOB_RESET,
  ARCHIVE_JOB_RESPONSE,
  UNARCHIVE_JOB_REQUEST,
  UNARCHIVE_JOB_RESPONSE,
} from 'store/constants/archiveJobs';

const initialState = {
  hypertestJobList: {
    data: null,
    loading: false,
    error: false,
  },
  jobAbort: {
    data: null,
    loading: false,
    error: false,
  },
  jobTags: {
    data: null,
    loading: false,
    error: false,
  },
  visual: {
    isJobLabelPopoverOpen: false,
  },
  appliedJobTagsFilter: [],
  isFirstTestAvailable: false,
  archiveJobs: {
    loading: false,
    data: [],
  },
};

function hypertestJobListReducer(state = initialState, actions) {
  const { type, payload = {}, error, extra = {} } = actions;
  switch (type) {
    case HYPERTEST_JOB_LIST_ACTIONS_REQUEST:
      return {
        ...state,
        hypertestJobList: {
          ...state.hypertestJobList,
          loading: true,
          error: false,
        },
      };
    case HYPERTEST_JOB_LIST_ACTIONS_REQUEST_SSE:
      return {
        ...state,
        hypertestJobList: {
          ...state.hypertestJobList,
          loading: false,
        },
      };
    case HYPERTEST_JOB_LIST_ACTIONS_RESPONSE:
      let updatedList = {
        ...cloneDeep(state.hypertestJobList.data),
        ...payload,
      };

      if (
        state.hypertestJobList.data &&
        payload &&
        payload.data &&
        payload.data.length
      ) {
        updatedList.data = [...cloneDeep(state.hypertestJobList.data.data)];

        payload.data.forEach((item) => {
          const idx = updatedList.data.findIndex((job) => job.id === item.id);

          if (idx >= 0) {
            updatedList.data[idx] = { ...item };
          } else {
            updatedList.data.push({ ...item });
          }
        });
      }
      (updatedList.data || []).sort(function (a, b) {
        return b.job_number - a.job_number;
      });

      let hasMore = payload && payload.metadata && payload.metadata.hasmore;

      return {
        ...state,
        hypertestJobList: {
          ...state.hypertestJobList,
          data: updatedList,
          loading: false,
          error: error === undefined ? false : error,
          hasMore: hasMore,
          isJobListFetchDone: true,
        },
        isFirstTestAvailable: updatedList?.data?.length ? true : false,
      };
    case HYPERTEST_JOB_ABORT_ACTION_REQUEST:
      return {
        ...state,
        jobAbort: {
          ...state.jobAbort,
          loading: true,
        },
      };
    case HYPERTEST_JOB_ABORT_ACTION_RESPONSE:
      return {
        ...state,
        jobAbort: {
          ...state.jobAbort,
          data: payload,
          loading: false,
          error: error === undefined ? false : error,
        },
      };
    case HYPERTEST_UPDATE_JOB_LIST_ACTION: {
      const {
        userName = '',
        isAdminRole = false,
        isAbortAllJob = false,
        isAbortedSelected = false,
      } = payload;
      let jobList = cloneDeep(
        (state.hypertestJobList.data && state.hypertestJobList.data.data) || []
      );

      const isStatusFilterApplied =
        get(filterTrack, ['filters', 'jobFilters', 'status'], '').length > 0;

      const isSelectedJobFilterApplied =
        filterTrack?.filters?.jobFilters?.status.indexOf(payload.status) !== -1;

      if (isAbortAllJob) {
        if (isStatusFilterApplied && !isSelectedJobFilterApplied) {
          jobList = jobList.filter(
            (job) => job.status !== 'running' && job.status !== 'initiated'
          );
        } else {
          jobList = jobList.map((job) => {
            let isAborted =
              job.status === 'running' || job.status === 'initiated';

            if (job.user !== userName && !isAdminRole) {
              isAborted = false;
            }

            const status = isAborted ? payload.status : job.status;

            const newData = { status };
            if (isAborted) {
              newData.isAborted = isAborted;
            }
            return { ...job, ...newData };
          });
        }
      } else if (isAbortedSelected) {
        if (isStatusFilterApplied && !isSelectedJobFilterApplied) {
          Object.keys(payload.jobIds).forEach((jId) => {
            const idx = jobList.findIndex((job) => job.id === jId);
            if (idx >= 0) {
              jobList.splice(idx, 1);
            }
          });
        } else {
          jobList = jobList.map((j) => {
            const isAborted = !!payload.jobIds[j.id];
            const status = isAborted ? payload.status : j.status;
            const newData = { status };
            if (isAborted) {
              newData.isAborted = isAborted;
            }
            return {
              ...j,
              ...newData,
            };
          });
        }
      } else {
        let jobIndex = jobList.findIndex((job) => job.id === payload.jobId);
        if (isStatusFilterApplied) {
          if (isSelectedJobFilterApplied) {
            if (jobIndex !== -1) {
              jobList[jobIndex].status = payload.status;
            }
          } else {
            jobList.splice(jobIndex, 1);
          }
        } else {
          if (jobIndex !== -1) {
            jobList[jobIndex].status = payload.status;
          }
        }
      }
      return {
        ...state,
        hypertestJobList: {
          ...state.hypertestJobList,
          data: {
            ...(state?.hypertestJobList?.data || {}),
            data: jobList,
          },
        },
      };
    }
    case HYPERTEST_CLEAR_JOB_LIST:
      return {
        ...state,
        hypertestJobList: initialState.hypertestJobList,
      };
    case HYPERTEST_UPDATE_CLICKED_JOB_ID:
      return {
        ...state,
        jobListScrollOffset: payload,
      };
    case HYPERTEST_JOB_INSERT_INTO_JOB_LIST_ACTION: {
      const { data: newJob, isUpdateEvent = false } = payload;
      if (!state.hypertestJobList.isJobListFetchDone) {
        return { ...state };
      }
      let jobList = cloneDeep(
        get(state, ['hypertestJobList', 'data', 'data'], [])
      );

      let index = jobList.findIndex((job) => job.id === newJob.id);
      if (index === -1 && isUpdateEvent) {
        return { ...state };
      }
      if (newJob?.project?.id) {
        newJob.project_id = newJob.project.id;
      }
      if (
        get(filterTrack, ['filters', 'jobFilters', 'status'], '').length > 0
      ) {
        if (newJob.status === 'running' || newJob.status === 'initiated') {
          if (index !== -1) {
            /*
              ? Abort All API takes few seconds to update the job status.
              Due to this if we make jobs aborted, then from SSE we get job status as running.
              To fix this, when we abort all jobs, then in aborted job we set
              isAborted to true.
              And here before updating job data from SSE we simply check, if job has been aborted then do not updated its status from the SSE Job data.
            */
            if (jobList[index].isAborted) {
              newJob.status = jobList[index].status;
            }
            jobList[index] = newJob;
          } else {
            jobList.splice(0, 0, newJob);
          }
        } else {
          if (
            filterTrack.filters.jobFilters.status.indexOf(newJob.status) !== -1
          ) {
            if (index !== -1) {
              if (jobList[index].isAborted) {
                newJob.status = jobList[index].status;
              }
              jobList[index] = newJob;
            }
          } else {
            jobList.splice(index, 1);
          }
        }
      } else {
        if (index !== -1) {
          if (jobList[index].isAborted) {
            newJob.status = jobList[index].status;
          }
          jobList[index] = newJob;
        } else {
          jobList.splice(0, 0, newJob);
        }
      }

      (jobList || []).sort(function (a, b) {
        return b.job_number - a.job_number;
      });

      return {
        ...state,
        hypertestJobList: {
          ...state.hypertestJobList,
          data: {
            ...state.hypertestJobList.data,
            data: jobList,
          },
        },
      };
    }
    case HYPERTEST_SET_JOB_LIST_VISUAL:
      return {
        ...state,
        visual: {
          ...state.visual,
          ...payload,
        },
      };
    case HYPERTEST_JOB_TAGS_LIST_ACTIONS_REQUEST:
      return {
        ...state,
        jobTags: {
          ...state.jobTags,
          loading: true,
        },
      };
    case HYPERTEST_JOB_TAGS_LIST_ACTIONS_RESPONSE:
      const { isLoadMore } = extra;

      let data = [];
      if (state?.jobTags?.data?.data?.length && isLoadMore) {
        data = state?.jobTags?.data;
        data.metadata = payload.metadata;
        data?.data?.push(...payload?.data?.filter((d) => !!d?.id));
        data.status = payload.status;
      } else {
        data = {
          ...payload,
          data: payload?.data?.filter((d) => !!d?.id) || [],
        };
      }

      return {
        ...state,
        jobTags: {
          ...state.jobTags,
          data: data,
          loading: false,
          error: error === undefined ? false : error,
        },
      };
    case HYPERTEST_SET_CURRENT_SELECTED_JOB_TAGS:
      return {
        ...state,
        appliedJobTagsFilter: payload,
      };
    case HYPERTEST_REMOVE_JOB: {
      let jobList = cloneDeep(
        (state?.hypertestJobList?.data &&
          state?.hypertestJobList?.data?.data) ||
          []
      );
      jobList = jobList?.filter((job) => {
        return job?.id !== payload;
      });
      return {
        ...state,
        hypertestJobList: {
          ...state?.hypertestJobList,
          data: {
            ...(state?.hypertestJobList?.data || {}),
            data: jobList,
          },
        },
      };
    }
    case ARCHIVE_JOB_REQUEST:
      return { ...state, loading: true };
    case ARCHIVE_JOB_RESPONSE:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    case UNARCHIVE_JOB_REQUEST:
      return { ...state, loading: true };
    case UNARCHIVE_JOB_RESPONSE:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    case ARCHIVE_JOB_RESET:
      return {
        ...state,
        loading: false,
        data: [],
      };
    default:
      return state;
  }
}

export default hypertestJobListReducer;
