const File = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      {...rest}
      role="img"
      aria-hidden="true"
    >
      <path
        d="M4 3V3C3.44772 3 3 3.44772 3 4V14C3 14.5523 3.44772 15 4 15H12C12.5523 15 13 14.5523 13 14V4C13 3.44772 12.5523 3 12 3V3"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="5.5" y="1.5" width="5" height="3" rx="0.5" stroke="#666666" />
    </svg>
  );
};

export default File;
