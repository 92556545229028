import React from 'react';

const Cancelled = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden="true"
      {...rest}
    >
      <circle cx="7.99502" cy="7.99499" r="6.665" fill="#FDB022" />
      <circle
        cx="7.99502"
        cy="7.99499"
        r="3.115"
        stroke="white"
        strokeWidth="1.1"
      />
      <path
        d="M5.70441 6.1625L10.2857 9.8275"
        stroke="white"
        strokeWidth="1.1"
      />
    </svg>
  );
};

export default Cancelled;
