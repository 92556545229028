import React from 'react';

const Katalon = ({ width = 12, height = 12, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <g clipPath="url(#clip0_463_10887)">
        <path d="M4.50473 0H0.0136719V11.9772H4.50473V0Z" fill="#6CB645" />
        <path
          d="M8.24782 5.9935L4.50148 0H0.0136719L6.00392 9.58699L8.24782 5.9935Z"
          fill="#00A1DF"
        />
        <path
          d="M8.62281 6.5918L6.37891 10.1853L7.49761 11.9739H11.9887L8.62281 6.5918Z"
          fill="#00A1DF"
        />
        <path
          d="M0.0136719 11.974H4.50148L11.9877 0H7.4966L0.0136719 11.974Z"
          fill="#9ACA3C"
        />
      </g>
      <defs>
        <clipPath id="clip0_463_10887">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Katalon;
