import { CALL_API } from '../middleware/api';
import * as action from '../constants/projectDetail';
import {
  getReceptionApi,
  getSentinelApi,
} from 'new-theme/utils/onpremEndpoints';

const HYPERTEST_ORCHESTRATOR_API_ENDPOINT =
  process.env.REACT_APP_HYPERTEST_ORCHESTRATOR_API_ENDPOINT ||
  (window._env_ && window._env_.REACT_APP_HYPERTEST_ORCHESTRATOR_API_ENDPOINT);

export const fetchProjectDetail = ({ auth, projectId }) => {
  let sentinelUrl = getSentinelApi();

  const endpoint = `${sentinelUrl}/v1.0/project/${projectId}`;

  return {
    [CALL_API]: {
      types: ['', action.HYP_UPDATE_DETAIL_INFO],
      endpoint,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth,
    },
  };
};

let projectDetailController;

export const fetchExecutionList = ({
  isDex,
  auth,
  projectId,
  nextCursor,
  searchedText = '',
  clearExistingItems = false,
  isJMeter = false,
}) => {
  let sentinelUrl = getSentinelApi();

  let endpoint = `${sentinelUrl}/v1.0/project/${projectId}/discovery-results?per_page=20&search=${searchedText}`;

  if (isJMeter) {
    endpoint = `${sentinelUrl}/v1.0/project/${projectId}/files?per_page=20&search=${searchedText}`;
  }

  if (nextCursor) {
    endpoint = `${endpoint}&next_cursor=${nextCursor}`;
  }

  if (isDex) {
    endpoint += '&type=TestEvent';
  }

  if (projectDetailController) {
    projectDetailController.abort();
  }
  projectDetailController = new AbortController();

  return {
    [CALL_API]: {
      types: [
        action.HYP_EXECUTION_LIST_INFINITE_SCROLL_LOADING,
        action.HYP_UPDATE_DETAIL_EXECUTION_LIST,
      ],
      endpoint,
      method: 'GET',
      appendBaseUrl: false,
      extra: { clearExistingItems, isJMeter },
      signal: projectDetailController.signal,
      type: 'json',
      auth,
    },
  };
};

export const setExecutionListFetchStatus = (status) => {
  return {
    type: action.HYP_UPDATE_EXECUTION_LIST_FETCH_STATUS,
    payload: status,
  };
};

export const triggerDiscoveryOfProject = ({ auth, projectId }) => {
  // let sentinelUrl = HYPERTEST_SENTINEL_API_ENDPOINT;
  return {
    [CALL_API]: {
      types: ['', action.HYP_UPDATE_JOB_ID_FOR_DISCOVERED_PROJECT],
      endpoint: `${getReceptionApi()}/api/project/${projectId}/discover`,
      method: 'POST',
      headers: {
        redirect: 'follow',
      },
      body: {},
      appendBaseUrl: false,
      type: 'json',
      auth,
    },
  };
};

export const triggerExecution = ({
  auth,
  isDex,
  params,
  isJMeterJob,
  projectId,
  isTosca,
}) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.ORCHESTRATOR_HOST) ||
    HYPERTEST_ORCHESTRATOR_API_ENDPOINT;

  const path = isDex ? 'job' : 'trigger-job';

  let endpoint = `${sentinelUrl}/v1.0/${path}`;

  if (isJMeterJob || isTosca) {
    endpoint = `${getReceptionApi()}/api/project/${projectId}/trigger-job`;
  }

  return {
    [CALL_API]: {
      types: ['', action.HYP_UPDATE_PROJECT_EXECUTION_TRIGGER_STATUS],
      endpoint,
      method: 'POST',
      appendBaseUrl: false,
      type: 'json',
      auth,
      body: params,
    },
  };
};

export const setProjectDetailFetch = (status) => {
  return {
    type: action.HYP_UPDATE_DETAIL_FETCH_STATUS,
    payload: status,
  };
};

export const setProjectStages = ({ stage, status }) => {
  return {
    type: action.HYP_UPDATE_PROJECT_STAGE,
    payload: { stage, status },
  };
};

export const setProjectDetailInfo = (project) => {
  return {
    type: action.HYP_UPDATE_DETAIL_INFO,
    payload: project,
  };
};

export const setProjectNameFrameworkAndWorkSpace = (data) => {
  return {
    type: action.HYP_UPDATE_PROJECT_NAME_FRAMEWORK_WORKSPACE,
    payload: data,
  };
};

export const setSearchedText = (text) => {
  return {
    type: action.HYP_UPDATE_DETAIL_SEARCH_TEXT,
    payload: text,
  };
};

export const setError = (err) => {
  return {
    type: action.HYP_UPDATE_DETAIL_ERROR,
    payload: err,
  };
};

export const setFirstTimeLoad = (status) => {
  return {
    type: action.HYP_UPDATE_FIRST_TIME_LOAD_STATUS,
    payload: status,
  };
};

export const setExecutionRunningStatus = (status) => {
  return {
    type: action.HYP_UPDATE_PROJECT_EXECUTION_STATUS,
    payload: status,
  };
};

export const setExecutionSuccess = (status) => {
  return {
    type: action.HYP_UPDATE_PROJECT_EXECUTION_SUCCESS,
    payload: status,
  };
};

export const setSelectedProjectDetailItems = (items) => {
  return {
    type: action.HYP_UPDATE_DETAIL_ITEM_SELECTION,
    payload: items,
  };
};

export const resetProjectDetail = () => {
  return {
    type: action.HYP_RESET_PROJECT_DETAIL,
  };
};
