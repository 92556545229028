import React from 'react';

const Error = ({ width, height, className, ...rest }) => {
  return (
    <svg
      role="presentation"
      aria-hidden="true"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8 5.59961L8 9.59961"
        stroke="#CF222E"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M8 11.6086V11.5996"
        stroke="#CF222E"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M6.60159 2.51828L1.32084 12.0236C0.728366 13.0901 1.49951 14.4007 2.71949 14.4007H13.281C14.501 14.4007 15.2722 13.0901 14.6797 12.0236L9.39895 2.51828C8.78927 1.42099 7.21119 1.42099 6.60159 2.51828Z"
        stroke="#CF222E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Error;
