const FullScreenArrow = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <g clipPath="url(#clip0_1854_36199)">
        <path
          d="M1.33331 4.66732V2.33398C1.33331 1.7817 1.78103 1.33398 2.33331 1.33398H4.66665"
          stroke="#888888"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6666 4.66732V2.33398C14.6666 1.7817 14.2189 1.33398 13.6666 1.33398H11.3333"
          stroke="#888888"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66665 14.6673H2.33331C1.78103 14.6673 1.33331 14.2196 1.33331 13.6673V11.334"
          stroke="#888888"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3333 14.6673H13.6666C14.2189 14.6673 14.6666 14.2196 14.6666 13.6673V11.334"
          stroke="#888888"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1854_36199">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { FullScreenArrow };
