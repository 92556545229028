import {
  LT_FEATURE_FLAG_UPDATE,
  LT_FEATURE_FLAG_UPDATE_AMPLITUDE,
} from '../constants/featureFlag';

import { FeatureFlag } from 'constants/feature-flags';

const initialState = {
  isLoading: true,
};

function featureFlag(state = initialState, actions) {
  const { type, payload, extra = {} } = actions;
  switch (type) {
    case LT_FEATURE_FLAG_UPDATE: {
      const flags = payload?.data || [];

      const userId = extra?.userId;
      const featureFlags = { ...FeatureFlag };
      Object.keys(featureFlags).forEach((key) => {
        if (!Object.hasOwn(featureFlag, key)) {
          featureFlags[key] = flags.includes(featureFlags[key]);
        }
      });
      localStorage.setItem(
        `featureFlags${userId}`,
        JSON.stringify(featureFlags)
      );

      return {
        ...state,
        isLoading: !state.amplitudeFFFetched,
        normalFFFetched: true,
        ...featureFlags,
      };
    }
    case LT_FEATURE_FLAG_UPDATE_AMPLITUDE: {
      const flags = payload?.result || {};
      const userId = extra?.userId;

      const featureFlags = { ...state, isLoading: false };

      if (flags && typeof flags === 'object') {
        Object.keys(flags).forEach((key) => {
          const isON = flags[key]?.variant?.key === 'on';

          featureFlags[key] = isON;
        });
      }

      localStorage.setItem(
        `featureFlags${userId}`,
        JSON.stringify(featureFlags)
      );

      return {
        ...state,
        isLoading: !state.normalFFFetched,
        amplitudeFFFetched: true,
        ...featureFlags,
      };
    }
    default:
      return state;
  }
}

export default featureFlag;
