import { useSelector } from 'react-redux';
import { ParallelMessage } from 'constants/app';

import { ConcurrencyInfo, TOOLTIP_CONTAINER_ID } from './constants';

function useAppWithHeader() {
  const total =
    useSelector((state) => state.concurrency?.concurrency?.data?.data?.total) ||
    0;
  const queued =
    useSelector(
      (state) => state.concurrency?.concurrency?.data?.data?.queued
    ) || 0;
  const occupied =
    useSelector(
      (state) => state.concurrency?.concurrency?.data?.data?.occupied
    ) || 0;

  const msftPlatformConcurrency =
    useSelector(
      (state) =>
        state.userInfo?.userInfo?.data?.organization?.plan_attributes
          ?.MAX_HYPEREXECUTE_PLATFORM_MSFT_CONCURRENCY
    ) || 0;

  function getParallelTooltip() {
    return occupied > total && msftPlatformConcurrency > 0
      ? ParallelMessage.OVER_LIMIT.replace('COUNT', occupied - total)
      : ParallelMessage.UNDER_LIMIT;
  }

  const concurrencyToRender = ConcurrencyInfo.map((x) => ({ ...x }));
  // Set parallel info
  concurrencyToRender[0].runningCount = occupied;
  concurrencyToRender[0].total = total;
  concurrencyToRender[0].title = (
    <span data-tip={getParallelTooltip()} data-for={TOOLTIP_CONTAINER_ID}>
      Parallel
    </span>
  );
  // set queued info
  concurrencyToRender[1].runningCount = queued;
  concurrencyToRender[1].total = total;

  return {
    total,
    queued,
    occupied,
    msftPlatformConcurrency,
    concurrencyToRender,
  };
}

export default useAppWithHeader;
