const HyperExecuteYamlIcon = ({
  width = 17,
  height = 20,
  className,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M6.6369 12.0566H7.33551L7.74638 12.744L8.15726 12.0566H8.85157L8.06002 13.2397V14.0923H7.42959V13.2397L6.63672 12.0566H6.6369ZM10.0391 13.7562H9.32236L9.22379 14.0923H8.58109L9.3461 12.0566H10.032L10.7975 14.0923H10.1392L10.0391 13.7562ZM9.90731 13.316L9.68212 12.5842L9.45843 13.316H9.90731ZM10.9917 12.0566H11.8193L12.1387 13.2951L12.4568 12.0566H13.2816V14.0923H12.7678V12.54L12.3692 14.0923H11.9042L11.5071 12.54V14.0923H10.9919V12.0566H10.9917ZM13.6825 12.0566H14.3119V13.5909H15.2934V14.0924H13.6825L13.6825 12.0566Z"
        fill="url(#paint0_linear_15324_107531)"
      />
      <path
        d="M6.79704 10.3555H15.1323C15.6463 10.3555 16.1131 10.5654 16.4514 10.9037C16.7898 11.2421 16.9997 11.7089 16.9997 12.2228V13.9269C16.9997 14.4408 16.7898 14.9076 16.4514 15.246C16.1131 15.5843 15.6463 15.7942 15.1323 15.7942H6.79704C6.2831 15.7942 5.81632 15.5843 5.47795 15.246C5.13959 14.9076 4.92969 14.4408 4.92969 13.9269V12.2228C4.92969 11.7089 5.13961 11.2421 5.47795 10.9037C5.81631 10.5654 6.2831 10.3555 6.79704 10.3555ZM15.1323 11.0407H6.79704C6.47229 11.0407 6.17673 11.1737 5.96237 11.3881C5.74801 11.6025 5.61488 11.8981 5.61488 12.2228V13.9269C5.61488 14.2516 5.74797 14.5472 5.96237 14.7615C6.17677 14.9759 6.47232 15.109 6.79704 15.109H15.1323C15.4571 15.109 15.7526 14.9759 15.967 14.7615C16.1814 14.5471 16.3145 14.2516 16.3145 13.9269V12.2228C16.3145 11.8981 16.1814 11.6025 15.967 11.3881C15.7526 11.1738 15.4571 11.0407 15.1323 11.0407Z"
        fill="url(#paint1_linear_15324_107531)"
      />
      <path
        d="M13.623 15.4518V16.2613C13.623 16.7351 13.4295 17.1654 13.1176 17.4772C12.8058 17.789 12.3754 17.9825 11.9018 17.9825H3.81883C3.34508 17.9825 2.91479 17.789 2.60297 17.4772C2.29117 17.1654 2.09766 16.7349 2.09766 16.2613V3.79344C2.09766 3.31969 2.29115 2.8894 2.60297 2.57758C2.91478 2.26578 3.34525 2.07227 3.81883 2.07227H11.9018C12.3755 2.07227 12.8058 2.26576 13.1176 2.57758C13.4294 2.88939 13.623 3.31986 13.623 3.79344V10.6984H12.9378V3.79344C12.9378 3.50885 12.8211 3.24981 12.6331 3.06196C12.4451 2.87394 12.1862 2.75728 11.9016 2.75728H3.81867C3.53408 2.75728 3.27503 2.87394 3.08718 3.06196C2.89917 3.24997 2.78251 3.50886 2.78251 3.79344V16.2613C2.78251 16.5459 2.89917 16.805 3.08718 16.9928C3.2752 17.1808 3.53409 17.2975 3.81867 17.2975H11.9016C12.1862 17.2975 12.4452 17.1808 12.6331 16.9928C12.8211 16.8048 12.9378 16.5459 12.9378 16.2613V15.4518H13.623Z"
        fill="url(#paint2_linear_15324_107531)"
      />
      <path
        d="M13.623 15.4518V16.2613C13.623 16.7351 13.4295 17.1654 13.1176 17.4772C12.8058 17.789 12.3754 17.9825 11.9018 17.9825H3.81883C3.34508 17.9825 2.91479 17.789 2.60297 17.4772C2.29117 17.1654 2.09766 16.7349 2.09766 16.2613V3.79344C2.09766 3.31969 2.29115 2.8894 2.60297 2.57758C2.91478 2.26578 3.34525 2.07227 3.81883 2.07227H11.9018C12.3755 2.07227 12.8058 2.26576 13.1176 2.57758C13.4294 2.88939 13.623 3.31986 13.623 3.79344V10.6984H12.9378V3.79344C12.9378 3.50885 12.8211 3.24981 12.6331 3.06196C12.4451 2.87394 12.1862 2.75728 11.9016 2.75728H3.81867C3.53408 2.75728 3.27503 2.87394 3.08718 3.06196C2.89917 3.24997 2.78251 3.50886 2.78251 3.79344V16.2613C2.78251 16.5459 2.89917 16.805 3.08718 16.9928C3.2752 17.1808 3.53409 17.2975 3.81867 17.2975H11.9016C12.1862 17.2975 12.4452 17.1808 12.6331 16.9928C12.8211 16.8048 12.9378 16.5459 12.9378 16.2613V15.4518H13.623Z"
        fill="url(#paint3_linear_15324_107531)"
      />
      <path
        d="M4.6804 4.24219C4.49122 4.24219 4.33789 4.39552 4.33789 4.58469C4.33789 4.77387 4.49122 4.9272 4.6804 4.9272H11.0369C11.2261 4.9272 11.3794 4.77387 11.3794 4.58469C11.3794 4.39552 11.2261 4.24219 11.0369 4.24219H4.6804Z"
        fill="url(#paint4_linear_15324_107531)"
      />
      <path
        d="M12.1169 17.7988C11.0635 19.5512 9.83515 19.053 8.27926 18.4221C7.90506 18.2703 7.50963 18.11 7.11005 17.9821L7.31881 17.3317C7.75938 17.4728 8.15849 17.6347 8.53632 17.7879C9.79004 18.2963 10.7797 18.6977 11.5308 17.4484L12.1169 17.799L12.1169 17.7988ZM2.46551 17.0849L2.37872 17.0682C1.91308 16.9779 1.5271 16.7061 1.28036 16.3409C1.03377 15.9758 0.92574 15.5168 1.01585 15.0523L2.09815 9.46727L2.76986 9.59571L1.68756 15.1807C1.6333 15.4605 1.69868 15.7375 1.8477 15.9582C1.99655 16.1786 2.22837 16.3425 2.50714 16.3964L2.59393 16.4132L2.46551 17.0849ZM3.62644 2.21558C4.75768 0.447294 5.87664 0.929671 7.4239 1.5968C7.79462 1.7566 8.19303 1.92835 8.62137 2.07569L8.39917 2.72336C7.94746 2.56804 7.53628 2.39082 7.15361 2.22586C5.91485 1.69187 5.01875 1.30555 4.202 2.58247L3.62668 2.21574L3.62644 2.21558ZM13.339 2.98671C13.8046 3.07698 14.1906 3.34879 14.4373 3.71403C14.6839 4.07911 14.792 4.53796 14.7018 5.00258L13.6149 10.6123L12.9432 10.4838L14.0301 4.87414C14.0844 4.59436 14.019 4.31742 13.87 4.09669C13.7211 3.87631 13.4893 3.71236 13.2106 3.65845L13.339 2.98671Z"
        fill="url(#paint5_linear_15324_107531)"
      />
      <path
        d="M4.6804 6.18555C4.49122 6.18555 4.33789 6.33888 4.33789 6.52805C4.33789 6.71723 4.49122 6.87056 4.6804 6.87056H11.0369C11.2261 6.87056 11.3794 6.71723 11.3794 6.52805C11.3794 6.33888 11.2261 6.18555 11.0369 6.18555H4.6804Z"
        fill="url(#paint6_linear_15324_107531)"
      />
      <path
        d="M4.6804 8.12695C4.49122 8.12695 4.33789 8.28029 4.33789 8.46946C4.33789 8.65863 4.49122 8.81197 4.6804 8.81197H11.0369C11.2261 8.81197 11.3794 8.65863 11.3794 8.46946C11.3794 8.28029 11.2261 8.12695 11.0369 8.12695H4.6804Z"
        fill="url(#paint7_linear_15324_107531)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15324_107531"
          x1="6.74274"
          y1="12.0399"
          x2="7.58012"
          y2="15.9304"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#224E82" />
          <stop offset="1" stopColor="#0EBAC5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15324_107531"
          x1="5.07751"
          y1="10.3108"
          x2="8.91064"
          y2="19.6053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#224E82" />
          <stop offset="1" stopColor="#0EBAC5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_15324_107531"
          x1="2.23881"
          y1="1.9416"
          x2="17.7205"
          y2="14.1951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#224E82" />
          <stop offset="1" stopColor="#0EBAC5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_15324_107531"
          x1="2.23881"
          y1="1.9416"
          x2="17.7205"
          y2="14.1951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#224E82" />
          <stop offset="1" stopColor="#0EBAC5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_15324_107531"
          x1="4.42413"
          y1="4.23656"
          x2="4.54513"
          y2="5.59553"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#224E82" />
          <stop offset="1" stopColor="#0EBAC5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_15324_107531"
          x1="1.15276"
          y1="0.844256"
          x2="18.8689"
          y2="15.6192"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#224E82" />
          <stop offset="1" stopColor="#0EBAC5" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_15324_107531"
          x1="4.42413"
          y1="6.17992"
          x2="4.54513"
          y2="7.53889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#224E82" />
          <stop offset="1" stopColor="#0EBAC5" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_15324_107531"
          x1="4.42413"
          y1="8.12133"
          x2="4.54513"
          y2="9.4803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#224E82" />
          <stop offset="1" stopColor="#0EBAC5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HyperExecuteYamlIcon;
