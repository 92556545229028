import React from 'react';

const RedirectHorizontalArrow = ({
  width,
  height,
  className,
  color = '#0EBAC5',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 12"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M0.00799985 4.5989L-1.66226e-07 4.49992C-3.91858e-05 4.37122 0.0409712 4.24658 0.115889 4.1477C0.190807 4.04883 0.294886 3.98199 0.41 3.95882L0.5 3.95002L9.292 3.95002L6.611 1.00037C6.52817 0.90938 6.477 0.789312 6.4664 0.66109C6.4558 0.532867 6.48645 0.40462 6.553 0.298697L6.611 0.221711C6.69386 0.130785 6.80309 0.0747105 6.91967 0.063255C7.03626 0.0517995 7.1528 0.0856902 7.249 0.159023L7.318 0.22281L10.854 4.11059C10.9367 4.20172 10.9877 4.32185 10.9981 4.45007C11.0085 4.57829 10.9777 4.70646 10.911 4.81226L10.854 4.88925L7.318 8.77703C7.22981 8.87446 7.11164 8.93202 6.98678 8.93838C6.86191 8.94473 6.73941 8.89942 6.64342 8.81137C6.54742 8.72333 6.4849 8.59893 6.46816 8.46269C6.45142 8.32646 6.48169 8.18827 6.553 8.07536L6.611 7.99948L9.292 5.04982L0.5 5.04982C0.382974 5.04986 0.269643 5.00476 0.179741 4.92237C0.0898395 4.83997 0.0290644 4.72551 0.00799985 4.5989Z"
        fill={color}
      />
    </svg>
  );
};

export { RedirectHorizontalArrow };
