import React from 'react';

const AbortedGray = ({ width = '14', height = '14', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="6.5" cy="6" r="5.5" stroke="#C2C2C2" />
      <path
        d="M5.28404 2.85742L3.35547 4.83614V7.35742L5.28404 9.28599H7.85547L9.78404 7.35742V4.83614L7.85547 2.85742H5.28404Z"
        fill="#C2C2C2"
      />
      <path d="M7.85658 6.07227H5.28516" stroke="white" strokeLinecap="round" />
    </svg>
  );
};

export default AbortedGray;
