export const FF_TEST_HISTORY = 'hide-test-history';
export const FF__SCENARIO_TEST_GRAPH = 'hide-scenario-time-graph';
export const FF_ARCHIVE_JOBS = 'hide-archive-job';
export const FF_ASSOCIATED_NAME = 'show-test-id-on-scenarios-tab';
export const FF_WORKFLOW = 'schedule-build';
export const FF_PARALLEL_CONCURRENCY = 'hide-parallel-concurrency-details';
export const FF_PROJECTS = 'projects';
export const FF_INSIGHTS = 'insights';
export const FF_RCA = 'hide-rca';
export const FF_CATEGORIZED_ERROR = 'hide-categorizederrors';
export const FF_NEW_THEME = 'new-theme';
export const FF_TEST_MUTING = 'test-muting-fe';
export const FF_DISABLE_NEW_THEME = 'disable-new-theme';
export const FF_PERFORMANCE_TESTING = 'performance-testing';
export const FF_WCAG_HIGH_CONTRAST = 'high-contrast';
export const FF_ENABLE_METRICS_COLLECTOR = 'enable-metrics-collector';
export const FF_ML_SANCTUM_CONCURRENCY = 'ml_sanctum_concurrency';
export const FF_REDIRECT_TEST_STATUS_TO_AUTOMATION =
  'redirect_test_status_to_automation';
export const FE_SHOW_VIEW_YAML = 'view_yaml_fe';
export const FF_ACCESSIBILITY_TOOLS = 'lt_accessibility_flag';

export const FeatureFlag = {
  [FF_TEST_HISTORY]: FF_TEST_HISTORY,
  [FF__SCENARIO_TEST_GRAPH]: FF__SCENARIO_TEST_GRAPH,
  [FF_ARCHIVE_JOBS]: FF_ARCHIVE_JOBS,
  [FF_ASSOCIATED_NAME]: FF_ASSOCIATED_NAME,
  [FF_WORKFLOW]: FF_WORKFLOW,
  [FF_PARALLEL_CONCURRENCY]: FF_PARALLEL_CONCURRENCY,
  [FF_PROJECTS]: FF_PROJECTS,
  [FF_INSIGHTS]: FF_INSIGHTS,
  [FF_RCA]: FF_RCA,
  [FF_CATEGORIZED_ERROR]: FF_CATEGORIZED_ERROR,
  [FF_NEW_THEME]: FF_NEW_THEME,
  [FF_TEST_MUTING]: FF_TEST_MUTING,
  [FF_DISABLE_NEW_THEME]: FF_DISABLE_NEW_THEME,
  [FF_PERFORMANCE_TESTING]: FF_PERFORMANCE_TESTING,
  [FF_WCAG_HIGH_CONTRAST]: FF_WCAG_HIGH_CONTRAST,
  [FF_ENABLE_METRICS_COLLECTOR]: FF_ENABLE_METRICS_COLLECTOR,
  [FF_ML_SANCTUM_CONCURRENCY]: FF_ML_SANCTUM_CONCURRENCY,
  [FF_REDIRECT_TEST_STATUS_TO_AUTOMATION]:
    FF_REDIRECT_TEST_STATUS_TO_AUTOMATION,
  [FE_SHOW_VIEW_YAML]: FE_SHOW_VIEW_YAML,
  [FF_ACCESSIBILITY_TOOLS]: FF_ACCESSIBILITY_TOOLS,
};
