import {
  SCENARIO_TIME_GRAPH_REQUEST,
  SCENARIO_TIME_GRAPH_RESET,
  SCENARIO_TIME_GRAPH_RESPONSE,
} from 'store/constants/scenarioTimeGraph';

const InitailState = {
  loading: false,
  data: [],
};
function scenarioTimeGraph(state = InitailState, action) {
  switch (action.type) {
    case SCENARIO_TIME_GRAPH_REQUEST:
      return { ...state, loading: true };
    case SCENARIO_TIME_GRAPH_RESPONSE:
      return {
        ...state,
        loading: false,
        data: action?.payload?.data,
      };
    case SCENARIO_TIME_GRAPH_RESET:
      return { ...state, ...InitailState };
    default:
      return state;
  }
}

export default scenarioTimeGraph;
