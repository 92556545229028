import React from 'react';

const ThreeDots = ({ width = '12', height = '2', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <rect width="2" height="2" rx="1" fill="#C2C2C2" />
      <rect x="5" width="2" height="2" rx="1" fill="#C2C2C2" />
      <rect x="10" width="2" height="2" rx="1" fill="#C2C2C2" />
    </svg>
  );
};

export default ThreeDots;
