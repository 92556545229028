import React from 'react';

const MacOS = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      alt=""
    >
      <path
        d="M9.83257 8.40551C9.69412 8.72826 9.52306 9.03602 9.32207 9.32401C9.05357 9.70751 8.83307 9.97251 8.66407 10.12C8.40157 10.361 8.11957 10.485 7.81807 10.492C7.60207 10.492 7.34107 10.4305 7.03707 10.3055C6.73207 10.181 6.45207 10.12 6.19557 10.12C5.92707 10.12 5.63907 10.181 5.33057 10.3055C5.02257 10.4305 4.77357 10.496 4.58307 10.502C4.29457 10.5145 4.00607 10.3875 3.71857 10.12C3.53507 9.96001 3.30557 9.68501 3.03007 9.29601C2.73507 8.88151 2.49257 8.39901 2.30257 7.85051C2.09907 7.25701 1.99707 6.68301 1.99707 6.12701C1.99707 5.49051 2.13457 4.94101 2.41007 4.48101C2.61844 4.11975 2.91634 3.81823 3.27507 3.60551C3.6288 3.39335 4.03261 3.27911 4.44507 3.27451C4.67507 3.27451 4.97657 3.34551 5.35007 3.48551C5.72357 3.62551 5.96357 3.69651 6.06807 3.69651C6.14707 3.69651 6.41257 3.61301 6.86457 3.44751C7.29107 3.29401 7.65107 3.23051 7.94607 3.25551C8.74607 3.32001 9.34657 3.63501 9.74607 4.20301C9.03107 4.63651 8.67757 5.24301 8.68457 6.02151C8.69057 6.62801 8.91107 7.13251 9.34307 7.53301C9.53406 7.71578 9.7569 7.86203 10.0006 7.96451C9.94757 8.11801 9.89157 8.26451 9.83257 8.40551ZM7.99907 1.19001C7.99907 1.66501 7.82507 2.10901 7.47957 2.51951C7.06157 3.00751 6.55657 3.29001 6.00907 3.24551C6.00191 3.18578 5.9984 3.12567 5.99857 3.06551C5.99857 2.60901 6.19657 2.12101 6.55007 1.72151C6.72607 1.51951 6.95007 1.35101 7.22157 1.21701C7.49257 1.08501 7.74857 1.01201 7.98957 0.999512C7.99607 1.06351 7.99907 1.12701 7.99907 1.19001Z"
        fill="#666666"
      />
    </svg>
  );
};

export { MacOS };
