export const HYP_UPDATE_DETAIL_FETCH_STATUS = 'HYP_UPDATE_DETAIL_FETCH_STATUS';
export const HYP_UPDATE_DETAIL_INFO = 'HYP_UPDATE_DETAIL_INFO';
export const HYP_UPDATE_DETAIL_SEARCH_TEXT = 'HYP_UPDATE_DETAIL_SEARCH_TEXT';
export const HYP_UPDATE_DETAIL_ERROR = 'HYP_UPDATE_DETAIL_ERROR';
export const HYP_UPDATE_FIRST_TIME_LOAD_STATUS =
  'HYP_UPDATE_FIRST_TIME_LOAD_STATUS';
export const HYP_UPDATE_DETAIL_ITEM_SELECTION =
  'HYP_UPDATE_DETAIL_ITEM_SELECTION';

export const HYP_RESET_PROJECT_DETAIL = 'HYP_RESET_PROJECT_DETAIL';
export const HYP_UPDATE_PROJECT_NAME_FRAMEWORK_WORKSPACE =
  'HYP_UPDATE_PROJECT_NAME_FRAMEWORK_WORKSPACE';

  export const HYP_UPDATE_DETAIL_EXECUTION_LIST =
    'HYP_UPDATE_DETAIL_EXECUTION_LIST';

  export const HYP_UPDATE_JOB_ID_FOR_DISCOVERED_PROJECT =
    'HYP_UPDATE_JOB_ID_FOR_DISCOVERED_PROJECT';

  export const HYP_UPDATE_PROJECT_EXECUTION_STATUS =
    'HYP_UPDATE_PROJECT_EXECUTION_STATUS';

  export const HYP_UPDATE_PROJECT_EXECUTION_SUCCESS =
    'HYP_UPDATE_PROJECT_EXECUTION_SUCCESS';

  export const HYP_UPDATE_PROJECT_EXECUTION_TRIGGER_STATUS =
    'HYP_UPDATE_PROJECT_EXECUTION_TRIGGER_STATUS';

      export const HYP_UPDATE_PROJECT_STAGE = 'HYP_UPDATE_PROJECT_STAGE';
      export const HYP_EXECUTION_LIST_INFINITE_SCROLL_LOADING =
        'HYP_EXECUTION_LIST_INFINITE_SCROLL_LOADING';
      export const HYP_UPDATE_EXECUTION_LIST_FETCH_STATUS =
        'HYP_UPDATE_EXECUTION_LIST_FETCH_STATUS';
