import React from 'react';

const Tunnel = ({ width = 24, height = 24, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M6 18V12C6 10.6797 6.17018 9.30943 6.96208 8.25303C7.85087 7.06737 9.33245 6 12 6C14.1873 6 15.7424 7.12571 16.7206 8.18275C17.7178 9.26044 18 10.7612 18 12.2295V18"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { Tunnel };
