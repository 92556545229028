import React from 'react';

const TimeoutGray = ({ width = '12', height = '12', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="6" cy="6" r="5.5" stroke="#C2C2C2" />
      <g clipPath="url(#clip0_14016_83814)">
        <path
          d="M6.07129 8.99023C7.52104 8.99023 8.69629 7.81498 8.69629 6.36523C8.69629 4.91549 7.52104 3.74023 6.07129 3.74023C4.62154 3.74023 3.44629 4.91549 3.44629 6.36523C3.44629 7.81498 4.62154 8.99023 6.07129 8.99023Z"
          stroke="#C2C2C2"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.09212 5.34375L6.07129 6.36458"
          stroke="#C2C2C2"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.11816 8.11523L8.40983 8.99023"
          stroke="#C2C2C2"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.03483 8.11523L3.74316 8.99023"
          stroke="#C2C2C2"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.15723 4.03125L4.03223 3.15625"
          stroke="#C2C2C2"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.11816 3.15625L8.99316 4.03125"
          stroke="#C2C2C2"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.07129 3.73958L6.07129 3.15625"
          stroke="#C2C2C2"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14016_83814">
          <rect
            width="7"
            height="7"
            fill="white"
            transform="translate(2.57129 2.57227)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TimeoutGray;
