const UserApproved = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <rect opacity="0.2" width="16" height="16" rx="8" fill="#7AD87A" />
      <path
        d="M11.8369 9.63196C11.9419 9.49727 12 9.33224 12 9.16054C12 8.88812 11.8431 8.63027 11.5906 8.48647C11.5256 8.44946 11.4516 8.42998 11.3763 8.43005H9.8775L9.915 7.68439C9.92375 7.50419 9.85813 7.33309 9.73063 7.20265C9.66805 7.13835 9.59256 7.0872 9.50882 7.05234C9.42508 7.01749 9.33486 6.99968 9.24375 7C8.91875 7 8.63125 7.21236 8.545 7.51632L8.00812 9.40323H8.00625V12H10.9581C11.0156 12 11.0719 11.9891 11.1238 11.9672C11.4212 11.8441 11.6131 11.5619 11.6131 11.2489C11.6131 11.1724 11.6019 11.0972 11.5794 11.0244C11.6844 10.8897 11.7425 10.7247 11.7425 10.553C11.7425 10.4765 11.7313 10.4013 11.7088 10.3285C11.8138 10.1938 11.8719 10.0288 11.8719 9.85706C11.8706 9.78061 11.8594 9.70477 11.8369 9.63196ZM7 9.59738V11.8058C7 11.9132 7.08937 12 7.2 12H7.60625V9.40323H7.2C7.08937 9.40323 7 9.48999 7 9.59738Z"
        fill="#7AD87A"
      />
      <path
        d="M6.1842 10.5263H5.5921C5.26509 10.5263 5 10.2612 5 9.93416V5.78946C5 5.35346 5.35346 5 5.78946 5H10.1012C10.3359 5 10.5263 5.19032 10.5263 5.4251V5.85019V6.27529"
        stroke="#7AD87A"
        strokeWidth="0.625"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserApproved;
