import React from 'react';

const SmartUI = ({ width = '11', height = '12', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M4.55295 1.09091H1.77517C1.16406 1.09091 0.664062 1.58182 0.664062 2.18182V9.81818C0.664062 10.4182 1.16406 10.9091 1.77517 10.9091H4.55295V12H5.66406V0H4.55295V1.09091ZM4.55295 9.27273H1.77517L4.55295 6V9.27273ZM9.55295 1.09091H6.77517V2.18182H9.55295V9.27273L6.77517 6V10.9091H9.55295C10.1641 10.9091 10.6641 10.4182 10.6641 9.81818V2.18182C10.6641 1.58182 10.1641 1.09091 9.55295 1.09091Z"
        fill="#999999"
      />
    </svg>
  );
};

export default SmartUI;
