import React from 'react';

const ThumbsDown = ({ width = '18', height = '18', className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      className={className}
      fill="none"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M8.76282 18L9.29999 17.1989L13.5629 10.7997H18L18 -0.00041008H5.21393C4.55472 -0.00041008 3.91063 0.197138 3.36462 0.566706C2.81866 0.936058 2.39593 1.46072 2.15089 2.07256L0.272915 6.77058C-0.201077 7.95522 -0.0512409 9.29854 0.672401 10.3495C1.39584 11.4004 2.59704 12.0198 3.87291 11.9998H5.19298L5.14192 12.1977C4.91403 13.1143 4.79915 14.0554 4.79998 14.9998C4.79998 15.7955 5.11597 16.5587 5.67867 17.1212C6.24118 17.6839 7.00416 17.9999 7.80002 17.9999L8.76282 18ZM16.1997 1.79978L16.1997 8.99988H13.7996L13.7998 1.79978H16.1997ZM6.59957 15C6.60271 14.2026 6.70337 13.4087 6.89966 12.6359L7.49962 10.1998L3.87262 10.1998C3.17934 10.2102 2.52705 9.87228 2.13549 9.29993C1.77095 8.74873 1.69917 8.05395 1.94359 7.43975L3.82157 2.74174C3.9331 2.46362 4.12521 2.22527 4.3732 2.05746C4.62139 1.88963 4.91396 1.79985 5.21364 1.79985H11.9996L11.9996 9.89996L7.79968 16.2C7.48138 16.2 7.17604 16.0737 6.95109 15.8487C6.72592 15.6235 6.59952 15.3184 6.59952 15.0001L6.59957 15Z"
        fill="#999999"
      />
    </svg>
  );
};

export default ThumbsDown;
