import * as action from '../constants/rca';
import cloneDeep from 'lodash/cloneDeep';

import { getRCA } from 'utils/transformations/rca';

const initialState = {
  scenarioIds: {},
  errors: [],
  isRemediationGenerated: {},
  isGeneratingRemediation: {},
  isRemediationError: {},
  summary: {},
  remediation: {},
  stackTraces: {},
  isLoadingStackTrace: false,
  isFetchDone: false,
};

function rcaReducer(state = initialState, actions) {
  const { type, payload, extra } = actions;

  switch (type) {
    case action.RCA_FETCH_SUCCESS: {
      if (payload?.status !== 'success' || !Array.isArray(payload?.data)) {
        return state;
      }

      const summary = {};
      payload?.data?.forEach((er) => {
        if (summary[er?.errorType]) {
          summary[er?.errorType] = summary[er?.errorType] + 1;
        } else {
          summary[er?.errorType] = 1;
        }
      });
      return {
        ...state,
        errors: { ...state.errors, [extra.key]: payload?.data || [] },
        summary: { ...state.summary, [extra.key]: summary },
        remediation: { ...state.remediation },
        stackTraces: { ...state.stackTraces },
        isLoadingStackTrace: false,
        isRemediationGenerated: {},
        isGeneratingRemediation: {},
        isRemediationError: {},
      };
    }

    case action.RCA_STACK_TRACE_LOAD_SUCCESS: {
      const newST = cloneDeep(state.stackTraces);
      const idx = payload?.errorIndex;
      newST[idx] = { stackTrace: payload?.stackTrace, error: false };

      return {
        ...state,
        stackTraces: newST,
        isLoadingStackTrace: false,
        isFetchDone: true,
      };
    }

    case action.RCA_STACK_TRACE_LOAD_ERROR: {
      const newST = cloneDeep(state.stackTraces);
      const idx = payload;
      newST[idx] = { error: true };

      return {
        ...state,
        stackTraces: newST,
        isLoadingStackTrace: false,
        isFetchDone: true,
      };
    }

    case action.RCA_STACK_TRACE_LOADING:
      return {
        ...state,
        isLoadingStackTrace: payload,
      };

    case action.RCA_REMEDIATION_FETCH_STATUS:
      return {
        ...state,
        isRemediationGenerated: {
          ...state.isRemediationGenerated,
          [extra.errorIndex]: false,
        },
        isGeneratingRemediation: {
          ...state.isGeneratingRemediation,
          [extra.errorIndex]: true,
        },
        isRemediationError: {
          ...state.isRemediationError,
          [extra.errorIndex]: false,
        },
      };

    case action.RCA_REMEDIATION_FETCH_SUCCESS: {
      if (payload?.data?.status !== 'success' || !payload?.data?.rca) {
        return {
          ...state,
          isGeneratingRemediation: {
            ...state.isGeneratingRemediation,
            [extra.errorIndex]: false,
          },
          isRemediationError: {
            ...state.isRemediationError,
            [extra.errorIndex]: true,
          },
        };
      }

      if (payload?.data?.rca?.toLowerCase() === 'na') {
        const { errorIndex } = extra;
        const existingRemediation = { ...state.remediation };
        existingRemediation[errorIndex] = [
          { title: 'No Data', data: 'No suggestions to show.' },
        ];
        return {
          ...state,
          isRemediationGenerated: {
            ...state.isRemediationGenerated,
            [extra.errorIndex]: true,
          },
          isGeneratingRemediation: {
            ...state.isGeneratingRemediation,
            [extra.errorIndex]: false,
          },
          isRemediationError: {
            ...state.isRemediationError,
            [extra.errorIndex]: false,
          },
          remediation: { ...existingRemediation },
        };
      }

      if (
        payload?.data?.message ===
        'RCA for categorized errors updated successfully'
      ) {
        const { errorIndex, order, iteration } = extra;
        const existingRemediation = { ...state.remediation };
        const errors = cloneDeep(state.errors);
        errors[`${order}${iteration}`][errorIndex] = {
          ...errors[`${order}${iteration}`][errorIndex],
          ...payload?.data,
        };
        existingRemediation[errorIndex] = getRCA(payload?.data);
        return {
          ...state,
          errors,
          isRemediationGenerated: {
            ...state.isRemediationGenerated,
            [extra.errorIndex]: true,
          },
          isGeneratingRemediation: {
            ...state.isRemediationGenerated,
            [extra.errorIndex]: false,
          },
          isRemediationError: {
            ...state.isRemediationGenerated,
            [extra.errorIndex]: false,
          },
          remediation: { ...existingRemediation },
        };
      }

      return state;
    }

    case action.RESET_RCA:
      return {
        ...state,
        isRemediationGenerated: {},
        isGeneratingRemediation: {},
        isRemediationError: {},
        remediation: {},
        stackTraces: {},
        isLoadingStackTrace: false,
        isFetchDone: false,
      };

    case action.RESET_ALL_RCA_ERRORS: {
      return { ...initialState };
    }

    default:
      return state;
  }
}

export default rcaReducer;
