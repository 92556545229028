import * as action from '../constants/projectDetail';
import cloneDeep from 'lodash/cloneDeep';
import { FirstLoadProjectStages } from 'constants/project-detail';

const initialState = {
  isFetchingProjectDetail: false,
  project: {},
  error: '',
  searchedText: '',
  selectedItems: [],
  isFirstTimeLoad: false,
  executionList: [],
  errorGettingExecutionList: '',
  discoveryJobId: '',
  discoveryError: '',
  executingProject: false,
  executingProjectSuccess: false,
  executingProjectFailure: false,
  stages: cloneDeep(FirstLoadProjectStages),
  nextCursor: '',
  isFetchingMore: false,
  isFetchingExecutionList: false,
};

function projectDetailReducer(state = initialState, actions) {
  const { type, payload, extra } = actions;
  const oldState = cloneDeep(state);
  switch (type) {
    case action.HYP_UPDATE_DETAIL_FETCH_STATUS:
      return {
        ...oldState,
        isFetchingProjectDetail: payload,
      };
    case action.HYP_UPDATE_PROJECT_STAGE:
      const { stage, status } = payload;
      const oldStages = oldState.stages;
      oldStages[stage].status = status;
      return {
        ...oldState,
        stages: oldStages,
      };
    case action.HYP_UPDATE_DETAIL_INFO:
      if (
        !payload ||
        payload?.error ||
        (payload?.response?.status !== 200 && payload?.status !== 'success')
      ) {
        return { ...oldState, error: payload };
      }
      return {
        ...oldState,
        project: {
          artifacts: payload?.data?.artifacts,
          projectName: payload?.data?.name,
          framework: payload?.data?.type,
          username: payload?.data?.workspace?.user,
          license: payload?.data?.license,
          workspace: payload?.data?.workspace,
          db: payload?.data?.db,
          projectId: payload?.data?.id,
          dexAddress: payload?.data?.dexAddress,
          projectRootName: payload?.data?.projectRootName,
          createdBy: payload?.data?.created_by,
          createdAt: payload?.data?.created_at,
          language: payload?.data?.language,
        },
        isFirstTimeLoad: false,
        isFetchingProjectDetail: false,
        discoveryJobId: '',
        discoveryError: '',
        stages: cloneDeep(FirstLoadProjectStages),
      };
    case action.HYP_UPDATE_PROJECT_NAME_FRAMEWORK_WORKSPACE:
      return {
        ...oldState,
        project: {
          ...oldState.project,
          projectName: payload.projectName,
          framework: payload.framework,
          username: payload.username,
        },
      };
    case action.HYP_UPDATE_DETAIL_ERROR:
      return {
        ...oldState,
        error: payload,
      };
    case action.HYP_UPDATE_PROJECT_EXECUTION_STATUS:
      return {
        ...oldState,
        executingProject: payload,
        executingProjectFailure: false,
      };
    case action.HYP_UPDATE_PROJECT_EXECUTION_TRIGGER_STATUS:
      const isSuccess = payload?.response?.data?.status !== 400;
      return {
        ...oldState,
        executingProjectSuccess: isSuccess,
        executingProjectFailure: !isSuccess,
        executingProject: true,
      };
    case action.HYP_UPDATE_PROJECT_EXECUTION_SUCCESS:
      return {
        ...oldState,
        executingProjectSuccess: payload,
        executingProjectFailure: payload,
        executingProject: false,
      };

    case action.HYP_UPDATE_DETAIL_SEARCH_TEXT:
      return {
        ...oldState,
        searchedText: payload,
      };

    case action.HYP_UPDATE_FIRST_TIME_LOAD_STATUS:
      return {
        ...oldState,
        isFirstTimeLoad: payload,
      };
    case action.HYP_UPDATE_DETAIL_ITEM_SELECTION:
      return {
        ...oldState,
        selectedItems: payload,
      };
    case action.HYP_UPDATE_JOB_ID_FOR_DISCOVERED_PROJECT:
      if (!payload || payload?.error || !payload?.jobId) {
        return { ...oldState, discoveryError: payload };
      }
      return {
        ...oldState,
        discoveryJobId: payload?.jobId || '',
      };
    case action.HYP_RESET_PROJECT_DETAIL:
      return {
        ...initialState,
      };
    case action.HYP_EXECUTION_LIST_INFINITE_SCROLL_LOADING:
      return {
        ...oldState,
        isFetchingMore: true,
      };
    case action.HYP_UPDATE_EXECUTION_LIST_FETCH_STATUS:
      return {
        ...oldState,
        isFetchingExecutionList: payload,
      };
    case action.HYP_UPDATE_DETAIL_EXECUTION_LIST:
      if (!payload || payload?.error || !payload.data) {
        return {
          ...oldState,
          errorGettingExecutionList: payload,
          ...(extra?.clearExistingItems
            ? { executionList: [], selectedItems: [] }
            : {}),
          ...(payload?.message !== 'canceled'
            ? { isFetchingExecutionList: false }
            : {}),
        };
      }
      let counter = oldState.executionList.length;

      let newItems = [
        ...(payload?.data?.map((d = '', index) => {
          if (extra?.isJMeter) {
            return {
              id: d.id,
              order: extra?.clearExistingItems ? index + 1 : ++counter,
              name: d.name,
              path: d?.link,
            };
          }

          return {
            id: d.result,
            order: extra?.clearExistingItems ? index + 1 : ++counter,
            name: d.result.slice(d.result.lastIndexOf('/') + 1, d.length),
            path: d.result.slice(0, d.result.lastIndexOf('/')),
          };
        }) || []),
      ];

      let executionList = [...oldState.executionList, ...newItems];

      if (extra?.clearExistingItems) {
        executionList = [...newItems];
      }

      let filteredSelectedItems = oldState.selectedItems.filter((itemId) => {
        let idExists = false;

        idExists = executionList.some((d) => d.id === itemId);

        return idExists;
      });

      filteredSelectedItems = filteredSelectedItems.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );

      executionList = executionList.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );

      return {
        ...oldState,
        nextCursor: payload?.metadata?.next_cursor,
        isFetchingMore: false,
        isFetchingExecutionList: false,
        executionList,
        selectedItems: filteredSelectedItems,
      };
    default:
      return state;
  }
}

export default projectDetailReducer;
