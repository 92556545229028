import {
  VR_SET_CURRENT_TEST_ID,
  VR_SET_CURRENT_ASSOCITED_VR_INFO,
  HYPERTEST_ASSOCIATED_VR_ACTIONS_REQUEST,
  HYPERTEST_ASSOCIATED_VR_ACTIONS_RESPONSE,
} from '../constants/vrscreenshots';

const initialState = {
  associatedVRs: {
    data: null,
    loading: false,
    error: false,
  },
  currentTestId: '',
  currentAssocitedVR: null,
};

function hypertestVRReducer(state = initialState, actions) {
  const { type, payload, error } = actions;
  switch (type) {
    case VR_SET_CURRENT_TEST_ID:
      return {
        ...state,
        currentTestId: payload,
      };
    case HYPERTEST_ASSOCIATED_VR_ACTIONS_REQUEST:
      return {
        ...state,
        associatedVRs: {
          ...state.associatedVRs,
          loading: true,
        },
      };
    case HYPERTEST_ASSOCIATED_VR_ACTIONS_RESPONSE:
      return {
        ...state,
        associatedVRs: {
          ...state.associatedVRs,
          data: payload,
          loading: false,
          error: error === undefined ? false : error,
        },
      };
    case VR_SET_CURRENT_ASSOCITED_VR_INFO:
      return {
        ...state,
        currentAssocitedVR: {
          ...state.currentAssocitedVR,
          ...payload,
        },
      };
    default:
      return state;
  }
}

export default hypertestVRReducer;
