import React from 'react';

const ChatSupport = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 15"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8887 11.5303C12.7924 11.5303 12.7142 11.6085 12.7142 11.7049V13.1476C12.7142 13.4696 12.5368 13.7649 12.2533 13.9173C11.9691 14.0688 11.6248 14.0528 11.3573 13.874L8.06186 11.6771C7.91794 11.5814 7.7497 11.5304 7.57785 11.5304H3.11215C2.41735 11.5304 1.75179 11.2547 1.26018 10.7636C0.769288 10.2727 0.493372 9.60647 0.493372 8.91167V2.62659C0.493372 1.18031 1.66587 0.0078125 3.11215 0.0078125H12.889C14.3353 0.0078125 15.5078 1.18031 15.5078 2.62659V8.91167C15.5078 9.60647 15.232 10.2727 14.741 10.7636C14.2494 11.2545 13.5838 11.5304 12.889 11.5304L12.8887 11.5303ZM11.6667 12.8216V11.705C11.6667 11.0304 12.2135 10.4829 12.8887 10.4829C13.3056 10.4829 13.7051 10.3174 13.9998 10.0228C14.2946 9.72804 14.46 9.32869 14.46 8.91166V2.62658C14.46 1.75853 13.7568 1.05532 12.8887 1.05532H3.11185C2.24379 1.05532 1.54058 1.75853 1.54058 2.62658V8.91166C1.54058 9.32857 1.70613 9.72803 2.00074 10.0228C2.29547 10.3175 2.69482 10.4829 3.11185 10.4829H7.57695C7.95621 10.4829 8.32694 10.5954 8.64257 10.8056L11.6666 12.8217L11.6667 12.8216Z"
        fill="#999999"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1872 3.84829H7.29889C7.00977 3.84829 6.77514 3.61366 6.77514 3.32454C6.77514 3.03542 7.00977 2.80078 7.29889 2.80078H12.1872C12.4763 2.80078 12.7109 3.03542 12.7109 3.32454C12.7109 3.61366 12.4763 3.84829 12.1872 3.84829Z"
        fill="#999999"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1872 6.29361H7.99713C7.70801 6.29361 7.47338 6.05897 7.47338 5.76985C7.47338 5.48073 7.70801 5.24609 7.99713 5.24609H12.1872C12.4763 5.24609 12.7109 5.48073 12.7109 5.76985C12.7109 6.05897 12.4763 6.29361 12.1872 6.29361Z"
        fill="#999999"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.90593 6.29361H3.81091C3.52179 6.29361 3.28715 6.05897 3.28715 5.76985C3.28715 5.48073 3.52179 5.24609 3.81091 5.24609H5.90593C6.19505 5.24609 6.42969 5.48073 6.42969 5.76985C6.42969 6.05897 6.19505 6.29361 5.90593 6.29361Z"
        fill="#999999"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1872 8.73501H10.7905C10.5013 8.73501 10.2667 8.50038 10.2667 8.21126C10.2667 7.92214 10.5013 7.6875 10.7905 7.6875H12.1872C12.4763 7.6875 12.7109 7.92214 12.7109 8.21126C12.7109 8.50038 12.4763 8.73501 12.1872 8.73501Z"
        fill="#999999"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.69499 8.73501H3.8067C3.51758 8.73501 3.28295 8.50038 3.28295 8.21126C3.28295 7.92214 3.51758 7.6875 3.8067 7.6875H8.69499C8.98411 7.6875 9.21875 7.92214 9.21875 8.21126C9.21875 8.50038 8.98411 8.73501 8.69499 8.73501Z"
        fill="#999999"
      />
    </svg>
  );
};

export { ChatSupport };
