import React from 'react';

const DownloadIcon = ({
  width = 16,
  height = 16,
  className,
  color,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M1.5 10.25V13.25C1.5 13.8023 1.94772 14.25 2.5 14.25H13.5C14.0523 14.25 14.5 13.8023 14.5 13.25V10.25"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M4.5 6.25L8 9.75L11.5 6.25M8 9.4V1.75"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { DownloadIcon };
