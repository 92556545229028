const quickRunConfigConverter = (config) => {
  if (config && config.yamlCode) {
    let yamlCode = config.yamlCode;
    let output = yamlCode.replace(
      /(concurrency: \d{1,2})/i,
      `concurrency: CONCURRENCY
maxRetries: MAXRETRIES
testSuiteTimeout: TIMEOUT`
    );
    output = output.replace(/(maxRetries: \d)/i, '');
    output = output.replace(/(testSuiteTimeout: \d{1,3})/i, '');
    output = output.replace(/mode: static/i, 'mode: dynamic');

    output = output.replace(
      /type: automatic/i,
      `type: automatic
  mode: dynamic`
    );

    output = output.replace(/(version: 0.1)/i, 'version: "0.1"');
    output =
      output +
      `
sourcePayload:
      platform: git
      link: ${config.setupGit?.clone}
      ref: main`;
    output = output.replace(/(git clone )/i, '');
    config.yamlCode = output;
  }
  return config;
};

export default quickRunConfigConverter;
