import React from 'react';

const Tunnel = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <rect width="20" height="20" rx="3" fill="#F5F6FB" />
      <path
        d="M6.31494 13.8621V9.86326C6.31494 8.71704 6.40515 7.5058 7.10409 6.59733C7.66521 5.86799 8.54534 5.26563 9.99915 5.26563C11.1582 5.26563 12.0282 5.86624 12.6309 6.51753C13.4992 7.45594 13.6834 8.7882 13.6834 10.0667V13.8621"
        stroke="#999999"
        strokeWidth="1.35"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { Tunnel };
