import { CALL_API } from '../middleware/api';
import {
  HYPERTEST_CONCURRENCY_ACTIONS_REQUEST,
  HYPERTEST_CONCURRENCY_ACTIONS_RESPONSE,
} from '../constants/concurrency';

const HYPERTEST_SENTINEL_API_ENDPOINT =
  process.env.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT || (window._env_ && window._env_.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT);
export const getHypertestConcurrency = ({ auth, orgId }) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;
  return {
    [CALL_API]: {
      types: [
        HYPERTEST_CONCURRENCY_ACTIONS_REQUEST,
        HYPERTEST_CONCURRENCY_ACTIONS_RESPONSE,
      ],
      endpoint: `${sentinelUrl}/v1.0/concurrency/${orgId}`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};
