import { getSentinelApi } from 'new-theme/utils/onpremEndpoints';
import {
  LT_FEATURE_FLAG_UPDATE,
  LT_FEATURE_FLAG_UPDATE_AMPLITUDE,
} from '../constants/featureFlag';
import { CALL_API } from '../middleware/api';
import { getCookieData } from 'utils/cookieHandler';

export const setFeatureFlags = ({ userId, auth }) => {
  const cookieName = process.env.REACT_APP_COOKIE_NAME;
  const access_token = getCookieData(cookieName);
  const headers = {
    Authorization: `Bearer ${access_token}`,
    Accept: 'application/json',
  };

  // getAmplitudeFeatureFlags({ userId, auth, orgName: 121431 });

  let sentinelUrl = getSentinelApi();
  return {
    [CALL_API]: {
      types: ['', LT_FEATURE_FLAG_UPDATE],
      endpoint: `${sentinelUrl}/v1.0/featureflags`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      headers,
      auth,
      extra: { userId },
    },
  };
};

export const getAmplitudeFeatureFlags = ({ userId, orgId, auth }) => {
  const cookieName = process.env.REACT_APP_COOKIE_NAME;
  const access_token = getCookieData(cookieName);
  const headers = {
    Authorization: `Bearer ${access_token}`,
    Accept: 'application/json',
  };

  // let sentinelUrl = getSentinelApi();
  return {
    [CALL_API]: {
      types: ['', LT_FEATURE_FLAG_UPDATE_AMPLITUDE],
      endpoint: process.env.REACT_APP_FEATURE_FLAGS,
      method: 'POST',
      appendBaseUrl: false,
      type: 'json',
      headers,
      auth,
      extra: { userId },
      data: {
        user_id: userId,
        org_id: orgId,
      },
    },
  };
};
