import store from 'store';

import { CALL_API } from '../middleware/api';
import {
  HYPERTEST_JOB_DETAIL_ACTIONS_REQUEST,
  HYPERTEST_JOB_DETAIL_ACTIONS_RESPONSE,
  HYPERTEST_JOB_DETAIL_ACTIONS_SILENT_REQUEST,
  HYPERTEST_JOB_DETAIL_RESET,
  HYPERTEST_SET_ARTIFECT_MODAL_IS_OPEN_STATUS,
  HYPERTEST_JOB_REPORT_REQUEST,
  HYPERTEST_JOB_REPORT_RESPONSE,
  HYPERTEST_SET_DOWNLOAD_REPORT_BUTTON_STATUS,
  HYPERTEST_SET_QUICK_START_STATUS,
  HYPERTEST_SET_HELP_MENU_STATUS,
  HYPERTEST_SET_SUPPORT_ALERT_STATUS,
  HYPERTEST_SET_JOB_ABORT_STATUS,
} from '../constants/hypertestJobDetail';

const HYPERTEST_SENTINEL_API_ENDPOINT =
  process.env.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT || (window._env_ && window._env_.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT);
const HYPERTEST_LOGISTIC_API_ENDPOINT =
  process.env.REACT_APP_HYPERTEST_LOGISTIC_API_ENDPOINT || (window._env_ && window._env_.REACT_APP_HYPERTEST_LOGISTIC_API_ENDPOINT);

export const getHypertestJobDetail = ({ auth, jobId, showTestSummary }) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;
  return {
    [CALL_API]: {
      types: [
        HYPERTEST_JOB_DETAIL_ACTIONS_REQUEST,
        HYPERTEST_JOB_DETAIL_ACTIONS_RESPONSE,
      ],
      endpoint: `${sentinelUrl}/v1.0/job/${jobId}?is_cursor_base_pagination=true&limit=0&show_test_summary=${showTestSummary}`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};

export const getHypertestJobDetail_SSE = ({ auth, jobId }) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;

  const storeState = store.getState();
  const showTestSummary = storeState?.preferences?.showTestSummary;

  return {
    [CALL_API]: {
      types: [
        HYPERTEST_JOB_DETAIL_ACTIONS_SILENT_REQUEST,
        HYPERTEST_JOB_DETAIL_ACTIONS_RESPONSE,
      ],
      endpoint: `${sentinelUrl}/v1.0/job/${jobId}?is_cursor_base_pagination=true&limit=0&show_test_summary=${showTestSummary}`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};
export const checkReportIsCreated = ({ auth, jobId }) => {
  // let job = '96697e14-4b5a-43dc-b9e2-b1834d08fb4e';
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;
  return {
    [CALL_API]: {
      types: [HYPERTEST_JOB_REPORT_REQUEST, HYPERTEST_JOB_REPORT_RESPONSE],
      endpoint: `${sentinelUrl}/v2.0/job/${jobId}/report`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};
export const getReportLink = ({ auth, jobId, openInNewTab, reportType }) => {
  let logisticUrl =
    (window.globalConfig && window.globalConfig.LOGISTICS_HOST) ||
    HYPERTEST_LOGISTIC_API_ENDPOINT;

  let endpoint = `${logisticUrl}/v1.0/report/${jobId}/download`;

  if (openInNewTab) {
    endpoint += `?open=${true}`;
  }

  if (reportType) {
    endpoint += openInNewTab ? `&type=${reportType}` : `?type=${reportType}`;
  }
  return {
    [CALL_API]: {
      types: [
        'HYPERTEST_JOB_REPORT_LINK_REQUEST',
        'HYPERTEST_JOB_REPORT_LINK_RESPONSE',
      ],
      endpoint,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};

export const resetJobDetail = () => {
  return {
    type: HYPERTEST_JOB_DETAIL_RESET,
  };
};

export const setArtefectIsOpenStatus = (isOpen) => {
  return {
    type: HYPERTEST_SET_ARTIFECT_MODAL_IS_OPEN_STATUS,
    payload: isOpen,
  };
};
export const setReportDownloadButtonStatus = (status) => {
  return {
    type: HYPERTEST_SET_DOWNLOAD_REPORT_BUTTON_STATUS,
    payload: status,
  };
};

export const setAbortedJobStatus = (status) => {
  return {
    type: HYPERTEST_SET_JOB_ABORT_STATUS,
    payload: status,
  };
};

export const updateQuickStartStatus = (isEnabled) => {
  return {
    type: HYPERTEST_SET_QUICK_START_STATUS,
    payload: isEnabled,
  };
};
export const updateShowSupportAlertStatus = (isEnabled) => {
  return {
    type: HYPERTEST_SET_SUPPORT_ALERT_STATUS,
    payload: isEnabled,
  };
};
export const openHelpMenuOption = (isEnabled) => {
  return {
    type: HYPERTEST_SET_HELP_MENU_STATUS,
    payload: isEnabled,
  };
};
