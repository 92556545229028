import React from 'react';

const RunningGray = ({ width = '12', height = '12', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M13.5032 6.99965C13.5032 5.81302 13.1513 4.65304 12.4921 3.66639C11.8328 2.67975 10.8958 1.91076 9.79952 1.45666C8.70323 1.00256 7.4969 0.883753 6.33308 1.11526C5.16926 1.34677 4.10023 1.91819 3.26116 2.75727C2.42209 3.59635 1.85067 4.6654 1.61916 5.82923C1.38766 6.99306 1.50647 8.1994 1.96056 9.2957C2.41465 10.392 3.18364 11.329 4.17027 11.9883C5.1569 12.6475 6.31687 12.9994 7.50349 12.9994"
        stroke="#C2C2C2"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default RunningGray;
