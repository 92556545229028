import React from 'react';

const Cancelled = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <g clipPath="url(#clip0_2632_26364)">
        <circle cx="8" cy="8" r="8" fill="#C24E00" />
        <circle cx="8" cy="8" r="3.95" stroke="white" strokeWidth="1.1" />
        <path
          d="M5.1875 5.75195L10.8125 10.252"
          stroke="white"
          strokeWidth="1.1"
        />
      </g>
      <defs>
        <clipPath id="clip0_2632_26364">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { Cancelled };
