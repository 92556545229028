export const HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_REQUEST =
  'HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_REQUEST';
export const HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_RESPONSE =
  'HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_RESPONSE';
export const HYPERTEST_JOB_ARTEFACT_DELETE_ACTIONS_REQUEST =
  'HYPERTEST_JOB_ARTEFACT_DELETE_ACTIONS_REQUEST';
export const HYPERTEST_JOB_ARTEFACT_DELETE_ACTIONS_RESPONSE =
  'HYPERTEST_JOB_ARTEFACT_DELETE_ACTIONS_RESPONSE';
export const HYPERTEST_JOB_ARTEFACT_DOWNLOAD_ACTIONS_REQUEST =
  'HYPERTEST_JOB_ARTEFACT_DOWNLOAD_ACTIONS_REQUEST';
export const HYPERTEST_JOB_ARTEFACT_DOWNLOAD_ACTIONS_RESPONSE =
  'HYPERTEST_JOB_ARTEFACT_DOWNLOAD_ACTIONS_RESPONSE';
export const HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_REQUEST_SILENT =
  'HYPERTEST_JOB_ARTIFACTS_LIST_ACTIONS_REQUEST_SILENT';
export const HYPERTEST_JOB_ARTEFACT_SET_CURRENT_ARTEFACT =
  'HYPERTEST_JOB_ARTEFACT_SET_CURRENT_ARTEFACT';
export const HYPERTEST_JOB_ARTEFACT_CONTENT_ACTIONS_REQUEST =
  'HYPERTEST_JOB_ARTEFACT_CONTENT_ACTIONS_REQUEST';
  export const HYPERTEST_RESET_ARTEFACTS = 'HYPERTEST_RESET_ARTEFACTS';
export const HYPERTEST_JOB_ARTEFACT_CONTENT_ACTIONS_RESPONSE =
  'HYPERTEST_JOB_ARTEFACT_CONTENT_ACTIONS_RESPONSE';
