const SystemApproved = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <rect width="16" height="16" rx="8" fill="#E6F7EF" />
      <path
        d="M5.6 4.70312C5.10604 4.70312 4.70312 5.10604 4.70312 5.6V10.4C4.70312 10.8953 5.10467 11.2969 5.6 11.2969H10.4C10.8953 11.2969 11.2969 10.8953 11.2969 10.4V5.6C11.2969 5.10467 10.8953 4.70312 10.4 4.70312H5.6Z"
        stroke="#37E291"
        strokeWidth="0.59375"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.4668 8.79613L7.65656 9L9.32838 7.20387L9.13862 7L7.65774 8.59099L6.85382 7.72729L6.66406 7.93116L7.46798 8.79486L7.4668 8.79613Z"
        fill="#37E291"
      />
      <path
        d="M7.65656 9L7.51168 9.13485C7.54913 9.17507 7.6016 9.19792 7.65656 9.19792C7.71151 9.19792 7.76399 9.17507 7.80143 9.13485L7.65656 9ZM7.4668 8.79613L7.32192 8.66129C7.2512 8.73727 7.2512 8.85499 7.32193 8.93098L7.4668 8.79613ZM9.32838 7.20387L9.47325 7.33871C9.54398 7.26273 9.54398 7.14501 9.47325 7.06902L9.32838 7.20387ZM9.13862 7L9.2835 6.86515C9.24605 6.82493 9.19358 6.80208 9.13862 6.80208C9.08367 6.80208 9.03119 6.82493 8.99375 6.86515L9.13862 7ZM7.65774 8.59099L7.51287 8.72583C7.55031 8.76606 7.60279 8.78891 7.65774 8.78891C7.71269 8.78891 7.76517 8.76606 7.80261 8.72583L7.65774 8.59099ZM6.85382 7.72729L6.99869 7.59245C6.96125 7.55223 6.90877 7.52938 6.85382 7.52938C6.79887 7.52938 6.74639 7.55223 6.70895 7.59245L6.85382 7.72729ZM6.66406 7.93116L6.51919 7.79632C6.44846 7.8723 6.44846 7.99002 6.51919 8.06601L6.66406 7.93116ZM7.46798 8.79486L7.61286 8.9297C7.68358 8.85372 7.68358 8.736 7.61285 8.66001L7.46798 8.79486ZM7.80143 8.86515L7.61167 8.66129L7.32193 8.93098L7.51168 9.13485L7.80143 8.86515ZM9.18351 7.06902L7.51168 8.86515L7.80143 9.13485L9.47325 7.33871L9.18351 7.06902ZM8.99375 7.13485L9.18351 7.33871L9.47325 7.06902L9.2835 6.86515L8.99375 7.13485ZM7.80261 8.72583L9.2835 7.13485L8.99375 6.86515L7.51287 8.45614L7.80261 8.72583ZM6.70895 7.86214L7.51287 8.72583L7.80261 8.45614L6.99869 7.59245L6.70895 7.86214ZM6.80893 8.06601L6.99869 7.86214L6.70895 7.59245L6.51919 7.79632L6.80893 8.06601ZM7.61285 8.66001L6.80893 7.79632L6.51919 8.06601L7.32311 8.9297L7.61285 8.66001ZM7.61167 8.93098L7.61286 8.9297L7.32311 8.66001L7.32192 8.66129L7.61167 8.93098Z"
        fill="#37E291"
      />
    </svg>
  );
};

export default SystemApproved;
