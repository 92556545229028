export const HYP_UPDATE_LISTING_FETCH_STATUS =
  'HYP_UPDATE_LISTING_FETCH_STATUS';
export const HYP_UPDATE_LISTING_PROJECTS = 'HYP_UPDATE_LISTING_PROJECTS';
export const HYP_UPDATE_LISTING_SEARCH_TEXT = 'HYP_UPDATE_LISTING_SEARCH_TEXT';
export const HYP_UPDATE_LISTING_ERROR = 'HYP_UPDATE_LISTING_ERROR';

export const HYP_UPDATE_ADD_PROJECT_SUCCESS = 'HYP_UPDATE_ADD_PROJECT_SUCCESS';
export const HYP_RESET_PROJECT_LISTING = 'HYP_RESET_PROJECT_LISTING';
export const HYP_RESET_ADD_PROJECT_ERROR = 'HYP_RESET_ADD_PROJECT_ERROR';
export const HYP_REMOVE_DELETED_PROJECT = 'HYP_REMOVE_DELETED_PROJECT';
export const HYP_INFINITE_SCROLL_LOADING = 'HYP_INFINITE_SCROLL_LOADING';
export const HYP_UPDATED_EDITED_PROJECT = 'HYP_UPDATED_EDITED_PROJECT';
export const HYP_SHOW_PROJECT_LOADING = 'HYP_SHOW_PROJECT_LOADING';
