import React from 'react';

const UpArrow = ({ className, ...rest }) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M0.220317 5.77993C0.0798662 5.6393 0.000976563 5.44868 0.000976563 5.24993C0.000976562 5.05118 0.0798662 4.86055 0.220317 4.71993L4.47032 0.469928C4.61094 0.329477 4.80157 0.250587 5.00032 0.250587C5.19907 0.250587 5.38969 0.329477 5.53032 0.469928L9.78032 4.71993C9.91273 4.86219 9.98483 5.05024 9.98147 5.24456C9.97812 5.43887 9.89956 5.62432 9.76232 5.76193C9.62471 5.89917 9.43926 5.97773 9.24495 5.98108C9.05063 5.98444 8.86258 5.91234 8.72032 5.77993L5.00032 2.05993L1.28032 5.77993C1.13969 5.92038 0.949067 5.99927 0.750317 5.99927C0.551566 5.99927 0.360942 5.92038 0.220317 5.77993Z"
        fill="#666666"
      />
    </svg>
  );
};

export default UpArrow;
