import documentationTabDetails from './data';
import { Icon } from 'components/Icons';
import './index.css';
import { sendAmplitudeEvents } from 'utils/common';
import { EventNames } from 'utils/events';

const DocumentationOptionTabs = () => {
  function handleTabClick(link, eventName) {
    sendAmplitudeEvents(EventNames.HYP_SELECT_A_CTA_MODAL, {
      'cta selected': eventName,
    });
    window.open(link, '_blank');
  }
  return (
    <div className="documentation-option-tabs">
      {documentationTabDetails.map((tab, index) => {
        return (
          <button
            aria-labelledby={`tb${index}`}
            className="documentation-option-tab"
            onClick={() => handleTabClick(tab.link, tab.eventName)}
            key={index}
          >
            <div className="tab-heading">
              <Icon name={tab.logo} className="tab-heading-logo" />
              <div id={`tb${index}`} className="tab-name">
                {tab.name}
              </div>
            </div>
            <div className="tab-description">{tab.description}</div>
          </button>
        );
      })}
    </div>
  );
};

export default DocumentationOptionTabs;
