const StageTimeoutV2 = ({
  width = '20',
  height = '20',
  className,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <circle
        cx="10.0034"
        cy="9.9966"
        r="8"
        transform="rotate(-0.024375 10.0034 9.9966)"
        fill="#ED5F00"
      />
      <path
        d="M10.0028 14.0052C11.9922 14.0052 13.605 12.3924 13.605 10.403C13.605 8.41354 11.9922 6.80078 10.0028 6.80078C8.01333 6.80078 6.40057 8.41354 6.40057 10.403C6.40057 12.3924 8.01333 14.0052 10.0028 14.0052Z"
        stroke="white"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4041 9.00195L10.0027 10.4034"
        stroke="white"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 7.20124L7.20124 6"
        stroke="white"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8042 6L14.0054 7.20124"
        stroke="white"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0027 6.80021L10.0027 6"
        stroke="white"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StageTimeoutV2;
