import React, { memo } from 'react';

const Typography = (props) => {
  const {
    variant,
    children,
    customStyle,
    mt = '0',
    ml = '0',
    mb = '0',
    mr = '0',
    pb = '0',
    pt = '0',
    pl = '0',
    pr = '0',
    px = '0',
    mx = '0',
    py = '0',
    my = '0',
    align = 'left',
    opacity = '100',
    decoration = 'no-underline',
    transform = 'normal-case',
    cursor = 'auto',
    color = '',
    fontType = 'ltw-font-medium',
    fontSize = 'ltw-text-12',
    lineHeight = 'ltw-leading-15',
    extraClasses = '',
    wordBreak = 'ltw-break-normal',
    className = '',
    ...rest
  } = props;
  let Component = null;
  let commonProps = {
    ...rest,
  };
  let commonClasses = `
		ltw-text-${transform}
		ltw-text-${decoration}
		ltw-text-${opacity}
		ltw-text-${align}
		ltw-mt-${mt}
		ltw-mb-${mb}
		ltw-mr-${mr}
		ltw-ml-${ml}
		ltw-pt-${pt}
		ltw-pb-${pb}
		ltw-pr-${pr}
		ltw-pl-${pl}
		ltw-mx-${mx}
		ltw-my-${my}
		ltw-px-${px}
		ltw-py-${py}
		ltw-cursor-${cursor}
		ltw-antialiased ${extraClasses}`;
  switch (variant) {
    case 'h1':
      Component = 'h1';
      commonProps = {
        ...commonProps,
        className: `${commonClasses} ${className} ${
          fontType ? fontType : 'ltw-font-semibold'
        } ltw-text-16 ${color ? color : 'ltw-text-secondary'} ltw-leading-19`,
      };
      break;
    case 'h2':
      Component = 'h2';
      commonProps = {
        ...commonProps,
        className: `${commonClasses} ${className}  ${
          fontType ? fontType : 'ltw-font-medium'
        } ${fontSize ? fontSize : 'ltw-text-12'} ${
          color ? color : 'ltw-text-secondary'
        } ${
          lineHeight ? lineHeight : 'ltw-leading-15'
        }   ${wordBreak} ltw-antialiased`,
      };
      break;
    case 'h3':
      Component = 'h3';
      commonProps = {
        ...commonProps,
        className: `${className} ltw-font-regular ltw-text-12 ltw-text-gray-secondary ltw-leading-15 ltw-antialiased ${
          color ? color : 'ltw-text-secondary'
        }`,
      };
      break;
    case 'p':
      Component = 'p';
      commonProps = {
        ...commonProps,
        className: `${commonClasses} ${className} ltw-font-medium ${
          fontSize ? fontSize : 'ltw-text-12'
        } ltw-text-black-regular ltw-leading-15 ltw-antialiased ${
          color ? color : 'ltw-text-secondary'
        } ${lineHeight ? lineHeight : 'ltw-leading-15'}`,
      };
      break;
    case 'subtext-1':
      Component = 'p';
      commonProps = {
        ...commonProps,
        className: `${commonClasses} ${className}  ${
          fontType ? fontType : 'ltw-font-regular'
        } ${fontSize ? fontSize : 'ltw-text-10'} ${
          color ? color : 'ltw-text-black-secondary'
        } ${lineHeight ? lineHeight : 'ltw-leading-121'} ltw-antialiased`,
      };
      break;
    case 'number-text':
      Component = 'h1';
      commonProps = {
        ...commonProps,
        className: `${commonClasses} ${className}  ltw-font-light ltw-text-32 ltw-text-secondary ltw-leading-3873 ltw-antialiased`,
      };
      break;
    case 'button-primary':
      Component = 'p';
      commonProps = {
        ...commonProps,
        className: `${commonClasses} ${className}  ltw-font-regular ltw-text-center ltw-text-14 ltw-text-white ltw-leading-20 ltw-antialiased`,
      };
      break;
    case 'button-secondary':
      Component = 'p';
      commonProps = {
        ...commonProps,
        className: `${commonClasses} ${className}  ltw-font-regular ltw-text-14 ltw-text-black-regular ltw-leading-20 ltw-antialiased`,
      };
      break;
    case 'tag':
      Component = 'span';
      commonProps = {
        ...commonProps,
        className: `${commonClasses} ${className}  ${
          fontType ? fontType : 'ltw-font-medium'
        } ltw-font-regular ltw-text-10 ${
          color ? color : 'ltw-text-tag-color'
        } ltw-leading-10 ltw-antialiased`,
      };
      break;
    case 'h5':
      Component = 'h5';
      commonProps = {
        ...commonProps,
        className: `${className} ltw-font-regular ltw-text-10 ltw-text-secondary ltw-leading-121 ltw-antialiased`,
      };
      break;
    case 'custom-style':
      Component = 'div';
      commonProps = {
        ...commonProps,
        className: `${customStyle} ${className}`,
      };
      break;
    default:
      break;
  }
  if (Component) return <Component {...commonProps}>{children}</Component>;
  return null;
};

export default memo(Typography);
