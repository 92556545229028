import React from 'react';

const Abort = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      className={className}
      {...rest}
      role="presentation"
      aria-hidden="true"
    >
      <path
        d="M5.66666 0.666016L0.666656 5.79601V12.3327L5.66666 17.3327H12.3333L17.3333 12.3327V5.79601L12.3333 0.666016H5.66666Z"
        fill="#E67300"
      />
      <path
        d="M12.3333 9H5.66667"
        stroke="white"
        strokeWidth="1.47059"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { Abort };
