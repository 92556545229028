import React from 'react';

const PJAzure = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M5.56028 1.12912H9.85069L5.39683 14.3256C5.35086 14.4611 5.26366 14.5788 5.14742 14.6622C5.03119 14.7457 4.89177 14.7906 4.74869 14.7908H1.40961C1.03492 14.7908 0.726562 14.4826 0.726562 14.1079C0.726562 14.0337 0.73869 13.96 0.762418 13.8896L4.91204 1.5945C4.95797 1.45893 5.04517 1.34115 5.16143 1.25765C5.27768 1.17415 5.41715 1.12913 5.56028 1.12891V1.12912Z"
        fill="url(#paint0_linear_4503_185704)"
      />
      <path
        d="M11.7985 9.98047H4.99456C4.82184 9.98047 4.67969 10.1226 4.67969 10.2954C4.67969 10.3827 4.71606 10.4662 4.77988 10.5257L9.15178 14.6062C9.27904 14.7249 9.44661 14.791 9.62066 14.7909H13.4732L11.7985 9.98047Z"
        fill="#0078D4"
      />
      <path
        d="M5.55933 1.12891C5.41466 1.12856 5.27364 1.17437 5.15679 1.25968C5.03994 1.34499 4.95335 1.46535 4.90962 1.60325L0.766636 13.8781C0.740089 13.952 0.726531 14.03 0.726563 14.1085C0.726563 14.4827 1.0345 14.7906 1.40876 14.7906H4.83579C4.96333 14.7678 5.08252 14.7115 5.18122 14.6276C5.27992 14.5436 5.35461 14.435 5.39767 14.3128L6.22382 11.8779L9.17503 14.6306C9.2987 14.7328 9.4538 14.7893 9.61426 14.7906H13.4524L11.7691 9.98022L6.86183 9.98138L9.86524 1.12891H5.55933Z"
        fill="url(#paint1_linear_4503_185704)"
      />
      <path
        d="M11.0383 1.59355C10.9924 1.45824 10.9053 1.34068 10.7892 1.25736C10.6731 1.17404 10.5339 1.12912 10.391 1.12891H5.60938C5.75226 1.12914 5.89149 1.17406 6.00757 1.25738C6.12364 1.3407 6.21074 1.45825 6.25667 1.59355L10.4064 13.8892C10.4301 13.9596 10.4423 14.0333 10.4423 14.1077C10.4423 14.4825 10.1339 14.7908 9.7591 14.7908H14.5409C14.9156 14.7907 15.2239 14.4824 15.2239 14.1077C15.2239 14.0333 15.2118 13.9596 15.188 13.8892L11.0383 1.59355Z"
        fill="url(#paint2_linear_4503_185704)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4503_185704"
          x1="7.12402"
          y1="2.14151"
          x2="2.66823"
          y2="15.3048"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#114A8B" />
          <stop offset="1" stop-color="#0669BC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4503_185704"
          x1="8.51493"
          y1="8.27576"
          x2="7.48425"
          y2="8.62426"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-opacity="0.3" />
          <stop offset="0.07" stop-opacity="0.2" />
          <stop offset="0.32" stop-opacity="0.1" />
          <stop offset="0.62" stop-opacity="0.05" />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4503_185704"
          x1="7.94697"
          y1="1.75733"
          x2="12.8381"
          y2="14.788"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3CCBF4" />
          <stop offset="1" stop-color="#2892DF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PJAzure;
