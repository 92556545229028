const FirstTestIcon = ({ width = 17, height = 16, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.263672 1.6366C0.263672 1.26891 0.263672 0.250235 0.263672 0.250235C0.263672 0.250235 1.33571 0.250235 1.72265 0.250235H6.46435C6.85129 0.250235 7.89055 0.25 7.89055 0.25C7.89055 0.25 7.92333 1.26891 7.92333 1.6366V6.14228C7.92333 6.50997 7.92333 7.52864 7.92333 7.52864C7.92333 7.52864 6.85129 7.52864 6.46435 7.52864H4.64062V10.345C4.64062 10.894 5.11041 11.3411 5.68963 11.3411H8.65282V9.60819C8.65282 9.2405 8.65282 8.22183 8.65282 8.22183C8.65282 8.22183 9.72486 8.22183 10.1118 8.22183H14.8535C15.2404 8.22183 16.3125 8.22183 16.3125 8.22183C16.3125 8.22183 16.3125 9.2405 16.3125 9.60819V14.1139C16.3125 14.4816 16.3125 15.5002 16.3125 15.5002C16.3125 15.5002 15.2404 15.5002 14.8535 15.5002H10.1118C9.72486 15.5002 8.65282 15.5002 8.65282 15.5002C8.65282 15.5002 8.65282 14.4816 8.65282 14.1139V12.3809H5.6889C5.1206 12.3807 4.57564 12.1661 4.17387 11.7842C3.77209 11.4023 3.54638 10.8844 3.54638 10.3443V7.52864H1.72265C1.33571 7.52864 0.263706 7.52864 0.263706 7.52864C0.263706 7.52864 0.263672 6.50997 0.263672 6.14228V1.6366ZM1.72265 1.29001C1.62592 1.29001 1.53314 1.32652 1.46474 1.39152C1.39634 1.45652 1.35791 1.54468 1.35791 1.6366V6.14228C1.35791 6.2342 1.39634 6.32236 1.46474 6.38736C1.53314 6.45236 1.62592 6.48887 1.72265 6.48887H6.46435C6.56108 6.48887 6.65386 6.45236 6.72226 6.38736C6.79067 6.32236 6.82909 6.2342 6.82909 6.14228V1.6366C6.82909 1.54468 6.79067 1.45652 6.72226 1.39152C6.65386 1.32652 6.56108 1.29001 6.46435 1.29001H1.72265ZM10.1118 9.2616C10.0151 9.2616 9.92229 9.29811 9.85389 9.36311C9.78549 9.42811 9.74706 9.51627 9.74706 9.60819V14.1139C9.74706 14.2058 9.78549 14.2939 9.85389 14.3589C9.92229 14.4239 10.0151 14.4605 10.1118 14.4605H14.8535C14.9502 14.4605 15.043 14.4239 15.1114 14.3589C15.1798 14.2939 15.2182 14.2058 15.2182 14.1139V9.60819C15.2182 9.51627 15.1798 9.42811 15.1114 9.36311C15.043 9.29811 14.9502 9.2616 14.8535 9.2616H10.1118Z"
        fill="url(#paint0_linear_18995_132885)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_18995_132885"
          x1="0.460223"
          y1="0.124755"
          x2="15.5599"
          y2="17.4867"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#224E82" />
          <stop offset="1" stopColor="#0EBAC5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FirstTestIcon;
