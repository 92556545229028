import React from 'react';

const Passed = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      role="presentation"
      aria-hidden="true"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <circle cx="8" cy="8" r="8" fill="#176F2C" />
      <path
        d="M4.59998 8L6.6581 10.4149C6.69679 10.4603 6.76637 10.4619 6.80716 10.4184L11.4182 5.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { Passed };
