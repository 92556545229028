import React from 'react';

const Warning = ({ className, height = '9', width = '9' }) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 18 18"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M16.7994 15.0469L9.48687 2.39062C9.37789 2.20254 9.1898 2.10938 8.99996 2.10938C8.81011 2.10938 8.62027 2.20254 8.51304 2.39062L1.20054 15.0469C0.984332 15.423 1.25504 15.8906 1.68746 15.8906H16.3125C16.7449 15.8906 17.0156 15.423 16.7994 15.0469ZM8.43746 7.3125C8.43746 7.23516 8.50074 7.17188 8.57808 7.17188H9.42183C9.49918 7.17188 9.56246 7.23516 9.56246 7.3125V10.5469C9.56246 10.6242 9.49918 10.6875 9.42183 10.6875H8.57808C8.50074 10.6875 8.43746 10.6242 8.43746 10.5469V7.3125ZM8.99996 13.5C8.77916 13.4955 8.56893 13.4046 8.41438 13.2469C8.25982 13.0891 8.17325 12.8771 8.17325 12.6562C8.17325 12.4354 8.25982 12.2234 8.41438 12.0656C8.56893 11.9079 8.77916 11.817 8.99996 11.8125C9.22075 11.817 9.43098 11.9079 9.58554 12.0656C9.74009 12.2234 9.82666 12.4354 9.82666 12.6562C9.82666 12.8771 9.74009 13.0891 9.58554 13.2469C9.43098 13.4046 9.22075 13.4955 8.99996 13.5Z"
        fill="#FBC930"
      />
    </svg>
  );
};

export { Warning };
