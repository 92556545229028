import React from 'react';

const CheckboxChecked = ({
  width = '14',
  height = '14',
  className,
  ...rest
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M4.66667 9.33333L1.33333 6L2.27333 5.05333L4.66667 7.44667L9.72667 2.38667L10.6667 3.33333M10.6667 0H1.33333C0.593333 0 0 0.593333 0 1.33333V10.6667C0 11.0203 0.140476 11.3594 0.390524 11.6095C0.640573 11.8595 0.979711 12 1.33333 12H10.6667C11.0203 12 11.3594 11.8595 11.6095 11.6095C11.8595 11.3594 12 11.0203 12 10.6667V1.33333C12 0.593333 11.4 0 10.6667 0Z"
        fill="#101010"
      />
    </svg>
  );
};

export { CheckboxChecked };
