import React from 'react';

const Key = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      role="img"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.51325 0.818298C9.11808 0.941568 9.67787 1.22735 10.1324 1.64493C10.587 2.06251 10.9192 2.59608 11.0933 3.1883C11.2545 3.7313 11.2785 4.3058 11.163 4.8608C10.9961 5.62118 10.5759 6.30237 9.97139 6.79286C9.36685 7.28336 8.61371 7.55411 7.83525 7.5608C7.50675 7.5608 7.17975 7.51355 6.8655 7.4183L6.2655 8.1218L5.98125 8.25305H5.25V9.37805L4.875 9.75305H3.75V10.878L3.375 11.253H1.125L0.75 10.878V9.1478L0.8595 8.88305L4.59 5.15255C4.48413 4.81154 4.43349 4.4558 4.44 4.0988C4.44684 3.59932 4.56377 3.10751 4.78247 2.65841C5.00118 2.20931 5.31626 1.81399 5.70526 1.50063C6.09426 1.18726 6.54761 0.96357 7.03297 0.845499C7.51834 0.727428 8.02377 0.717884 8.51325 0.817548V0.818298ZM9.50175 6.2183C9.97289 5.83678 10.3005 5.30659 10.431 4.71455L10.434 4.7183C10.5269 4.2854 10.5102 3.83614 10.3854 3.41135C10.2605 2.98656 10.0315 2.59969 9.71913 2.28591C9.40676 1.97212 9.02094 1.74136 8.59671 1.6146C8.17249 1.48784 7.72331 1.46908 7.29 1.56005C6.70559 1.68976 6.18137 2.01126 5.80081 2.47336C5.42026 2.93547 5.20524 3.51161 5.19 4.11005C5.1825 4.45205 5.2425 4.79105 5.36925 5.10755L5.28675 5.5148L1.5 9.30305V10.503H3V9.37805L3.375 9.00305H4.5V7.87805L4.875 7.50305H5.80875L6.46575 6.74105L6.8865 6.63605C7.18979 6.75456 7.51263 6.81511 7.83825 6.81455C8.44427 6.8099 9.03076 6.59968 9.50175 6.2183ZM8.87325 4.1693C8.93238 4.08758 8.97443 3.99478 8.99687 3.89645C9.01931 3.79811 9.02168 3.69625 9.00385 3.59698C8.98601 3.4977 8.94833 3.40304 8.89307 3.31867C8.8378 3.23429 8.76609 3.16192 8.68221 3.1059C8.59833 3.04988 8.50401 3.01136 8.4049 2.99263C8.30579 2.97391 8.20392 2.97537 8.10538 2.99692C8.00685 3.01848 7.91367 3.05969 7.83143 3.11809C7.74919 3.17648 7.67958 3.25087 7.62675 3.3368C7.52513 3.50209 7.49152 3.70033 7.53299 3.88988C7.57446 4.07942 7.68777 4.24552 7.84912 4.35329C8.01047 4.46105 8.20731 4.50209 8.39828 4.46778C8.58925 4.43347 8.7595 4.32648 8.87325 4.1693Z"
        fill="#666666"
      />
    </svg>
  );
};

export { Key };
