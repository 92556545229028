import React from 'react';

const CrossBoldIcon = ({ width = 12, height = 12, className, onClick }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      viewBox="0 0 12 12"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M10 3.455L8.545 2L6 4.545L3.455 2L2 3.455L4.545 6L2 8.545L3.455 10L6 7.455L8.545 10L10 8.545L7.455 6L10 3.455Z"
        fill="#CF370E"
      />
    </svg>
  );
};

export default CrossBoldIcon;
