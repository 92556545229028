import React from 'react';

const Created = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="7.99502" cy="7.99499" r="6.665" fill="#9B9FFF" />
      <path
        d="M5.13837 10.5838C5.13837 10.3821 5.30193 10.2185 5.50369 10.2185H10.6182C10.8199 10.2185 10.9835 10.3821 10.9835 10.5838V10.5838C10.9835 10.7856 10.8199 10.9491 10.6182 10.9491H5.50369C5.30193 10.9491 5.13837 10.7856 5.13837 10.5838V10.5838Z"
        fill="white"
      />
      <path
        d="M5.13837 8.94168C5.13837 8.73991 5.30193 8.57635 5.50369 8.57635H10.6182C10.8199 8.57635 10.9835 8.73991 10.9835 8.94168V8.94168C10.9835 9.14344 10.8199 9.307 10.6182 9.307H5.50369C5.30193 9.307 5.13837 9.14344 5.13837 8.94168V8.94168Z"
        fill="white"
      />
      <path
        d="M5.13837 6.29721C5.13837 5.74493 5.58608 5.29721 6.13837 5.29721H9.98351C10.5358 5.29721 10.9835 5.74493 10.9835 6.29721V6.85446C10.9835 7.40674 10.5358 7.85446 9.98351 7.85446H6.13837C5.58608 7.85446 5.13837 7.40674 5.13837 6.85446V6.29721Z"
        fill="white"
      />
    </svg>
  );
};

export default Created;
