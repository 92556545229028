import React from 'react';

const AssociatedIgnored = ({
  width,
  height,
  className,
  id = 'clip0_1021_32076',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect width={width} height={height} rx="8" fill="#FF6E40" />
      <g clipPath={`url(#${id})`}>
        <path
          d="M7 11.005C7 10.8737 7.02587 10.7436 7.07612 10.6223C7.12637 10.501 7.20003 10.3908 7.29289 10.2979C7.38575 10.205 7.49599 10.1314 7.61732 10.0811C7.73864 10.0309 7.86868 10.005 8 10.005C8.13132 10.005 8.26136 10.0309 8.38268 10.0811C8.50401 10.1314 8.61425 10.205 8.70711 10.2979C8.79997 10.3908 8.87362 10.501 8.92388 10.6223C8.97413 10.7436 9 10.8737 9 11.005C9 11.2702 8.89464 11.5246 8.70711 11.7121C8.51957 11.8996 8.26522 12.005 8 12.005C7.73478 12.005 7.48043 11.8996 7.29289 11.7121C7.10536 11.5246 7 11.2702 7 11.005ZM7.098 5C7.08468 4.87384 7.09804 4.74629 7.1372 4.62563C7.17636 4.50496 7.24045 4.39388 7.32532 4.29959C7.41018 4.20529 7.51392 4.12989 7.62981 4.07828C7.7457 4.02667 7.87114 4 7.998 4C8.12486 4 8.2503 4.02667 8.36619 4.07828C8.48208 4.12989 8.58582 4.20529 8.67068 4.29959C8.75555 4.39388 8.81964 4.50496 8.8588 4.62563C8.89796 4.74629 8.91132 4.87384 8.898 5L8.548 8.507C8.53378 8.64296 8.4697 8.76884 8.36813 8.86034C8.26657 8.95184 8.13471 9.00248 7.998 9.00248C7.8613 9.00248 7.72944 8.95184 7.62787 8.86034C7.5263 8.76884 7.46222 8.64296 7.448 8.507L7.098 5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="2" height="8" fill="white" transform="translate(7 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AssociatedIgnored;
