import React from 'react';

const Blocked = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <circle cx="8" cy="8" r="8" fill="#666666" />
      <path
        d="M7.25 10.9C7.25 10.6239 7.47386 10.4 7.75 10.4H8.25C8.52614 10.4 8.75 10.6239 8.75 10.9V11.5C8.75 11.7761 8.52614 12 8.25 12H7.75C7.47386 12 7.25 11.7761 7.25 11.5V10.9ZM7.25 4.5C7.25 4.22386 7.47386 4 7.75 4H8.25C8.52614 4 8.75 4.22386 8.75 4.5V8.3C8.75 8.57614 8.52614 8.8 8.25 8.8H7.75C7.47386 8.8 7.25 8.57614 7.25 8.3V4.5Z"
        fill="white"
      />
    </svg>
  );
};

export default Blocked;
