export const HYPERTEST_STAGE_LOG_ACTIONS_REQUEST =
  'HYPERTEST_STAGE_LOG_ACTIONS_REQUEST';
export const HYPERTEST_STAGE_LOG_ACTIONS_RESPONSE =
  'HYPERTEST_STAGE_LOG_ACTIONS_RESPONSE';
export const STAGE_LOG_CHUNK_REQUEST = 'STAGE_LOG_CHUNK_REQUEST';
export const STAGE_LOG_CHUNK_RESPONSE = 'STAGE_LOG_CHUNK_RESPONSE';
export const HYPERTEST_STAGE_LOG_RESET = 'HYPERTEST_STAGE_LOG_RESET';
export const STAGE_LOGS_VISUAL_UPADTE = 'STAGE_LOGS_VISUAL_UPADTE';
export const HYPERTEST_STAGE_LOG_USING_CDN_ACTIONS_REQUEST =
  'HYPERTEST_STAGE_LOG_USING_CDN_ACTIONS_REQUEST';
export const HYPERTEST_STAGE_LOG_USING_CDN_ACTIONS_RESPONSE =
  'HYPERTEST_STAGE_LOG_USING_CDN_ACTIONS_RESPONSE';
