export const USER_CLOSED_TRY_NOW_MODAL = 'userClosedTryNowModal';
export const SideBarMenuClasses = {
  JOBS: 'aside__menu__secondary__item__jobs',
  PROJECTS: 'aside__menu__secondary__item__he-projects',
  WORKFLOW: 'aside__menu__secondary__item__he-workflows',
  INSIGHTS: 'aside__menu__secondary__item__he-insights',
};

export const SAMPLE_REPORT_BODY = {
  DashboardName: 'hyperexecute_report',
  TemplateId: '7',
  Filters: {
    hyperexecute: {
      builds: [],
      status: [],
      os: [],
      browsers: [],
      users: [],
      projectName: [],
      testName: [],
      build_tags: [],
      test_tags: [],
      job_labels: null,
    },
  },
};

export const ANALTICS_CONDITION = {
  minDashboards: 0,
  analyticsPopup: null,
  minJobs: 10,
};
