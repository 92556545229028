import { CALL_API } from '../middleware/api';
import {
  HYPERTEST_TASKS_LIST_ACTIONS_REQUEST,
  HYPERTEST_TASKS_LIST_ACTIONS_RESPONSE,
  HYPERTEST_TASKS_LIST_RESET,
  HYPERTEST_TASKS_LIST_UPDATE_SSE_EVENT,
  HYPERTEST_TASK_DETAIL_ACTIONS_REQUEST,
  HYPERTEST_TASK_DETAIL_ACTIONS_RESPONE,
  HYPERTEST_TASK_DETAIL_ACTIONS_RESPONEE,
  HYPERTEST_UPDATE_TASK_LIST_FOR_ABORT_ACTION,
} from '../constants/hypertestTasksList';

const HYPERTEST_SENTINEL_API_ENDPOINT =
  process.env.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT ||
  (window._env_ && window._env_.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT);
const HYPERTEST_ORCHESTRATOR_API_ENDPOINT =
  process.env.REACT_APP_HYPERTEST_ORCHESTRATOR_API_ENDPOINT ||
  (window._env_ && window._env_.REACT_APP_HYPERTEST_ORCHESTRATOR_API_ENDPOINT);

let tasksListApiController;
export const getHypertestTasksList = ({
  auth,
  cursor,
  jobId,
  pageSize = 20,
  status,
  scenario_search_text,
  showTestSummary,
}) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;

  let queryParams = `limit=${pageSize}&is_cursor_base_pagination=true&status=${
    status || ''
  }&cursor=${
    cursor || ''
  }&scenario_search_text=${scenario_search_text}&show_test_summary=${showTestSummary}`;

  if (tasksListApiController) {
    tasksListApiController.abort();
  }
  tasksListApiController = new AbortController();
  return {
    [CALL_API]: {
      types: [
        HYPERTEST_TASKS_LIST_ACTIONS_REQUEST,
        HYPERTEST_TASKS_LIST_ACTIONS_RESPONSE,
      ],
      endpoint: `${sentinelUrl}/v1.0/job/${jobId}?${queryParams}`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
      signal: tasksListApiController.signal,
      extra: {
        clearJobList: !cursor,
      },
    },
  };
};

export const getHypertestTaskDetails = ({ auth, taskId, activeStatus }) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;

  return {
    [CALL_API]: {
      types: [
        HYPERTEST_TASK_DETAIL_ACTIONS_REQUEST,
        HYPERTEST_TASK_DETAIL_ACTIONS_RESPONE,
      ],
      endpoint: `${sentinelUrl}/v1.0/task/${taskId}`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
      extra: {
        taskId,
        activeStatus,
      },
    },
  };
};

export const startSseLogStream = ({
  auth,
  taskId,
  history,
  jobId,
  stageId = '',
}) => {
  const orchestratorUrl =
    (window.globalConfig &&
      window.globalConfig.REACT_APP_HYPERTEST_ORCHESTRATOR_API_ENDPOINT) ||
    HYPERTEST_ORCHESTRATOR_API_ENDPOINT ||
    (window._env_ && window._env_.HYPERTEST_ORCHESTRATOR_API_ENDPOINT);

  let endpoint = history
    ? `${orchestratorUrl}/v1.0/logstream/history?taskId=${taskId}&jobId=${jobId}&stageId=${stageId}`
    : `${orchestratorUrl}/v1.0/logstream/start?taskId=${taskId}&jobId=${jobId}`;
  return {
    [CALL_API]: {
      types: [HYPERTEST_TASK_DETAIL_ACTIONS_RESPONEE],
      endpoint: endpoint,
      method: 'POST',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};

export const stopSseLogStream = ({ auth, taskId, jobId }) => {
  const orchestratorUrl =
    (window.globalConfig &&
      window.globalConfig.REACT_APP_HYPERTEST_ORCHESTRATOR_API_ENDPOINT) ||
    HYPERTEST_ORCHESTRATOR_API_ENDPOINT ||
    (window._env_ && window._env_.HYPERTEST_ORCHESTRATOR_API_ENDPOINT);

  return {
    [CALL_API]: {
      types: [''],
      endpoint: `${orchestratorUrl}/v1.0/logstream/stop?taskId=${taskId}&jobId=${jobId}`,
      method: 'POST',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};

export const getHypertestSSEEvent = ({ eventData }) => {
  return {
    type: HYPERTEST_TASKS_LIST_UPDATE_SSE_EVENT,
    payload: { eventData },
  };
};

export function resetHypertestTasksList() {
  return {
    type: HYPERTEST_TASKS_LIST_RESET,
  };
}

export function updateTaskListForAbort() {
  return {
    type: HYPERTEST_UPDATE_TASK_LIST_FOR_ABORT_ACTION,
  };
}
