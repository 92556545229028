import React from 'react';

const VisibilityEye = ({ width = '16', height = '8', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M8 0C4.7833 0 1.90843 1.55635 0 4C1.90862 6.44379 4.78338 8 8 8C11.2165 8 14.0916 6.44379 16 4C14.0914 1.55635 11.2166 0 8 0ZM8 6.2491C6.63694 6.2491 5.53182 5.24221 5.53182 4.00007C5.53182 2.75793 6.63698 1.75104 8 1.75104C9.36322 1.75104 10.4682 2.75793 10.4682 4.00007C10.4682 5.24221 9.36318 6.2491 8 6.2491Z"
        fill="#999999"
      />
    </svg>
  );
};

export default VisibilityEye;
