import React from 'react';

const CompletedIcon = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle
        cx="21"
        cy="21"
        r="19.5"
        fill="#818181"
        stroke="#818181"
        strokeWidth="3"
      />
      <path
        d="M12.9823 21.8698C12.3581 21.3251 11.4105 21.3896 10.8659 22.0138C10.3212 22.638 10.3857 23.5856 11.0099 24.1302L12.9823 21.8698ZM18.0161 28.2529L19.0023 27.1227H19.0023L18.0161 28.2529ZM18.1567 28.2438L19.2798 29.2381L19.2798 29.2381L18.1567 28.2438ZM30.1192 16.9943C30.6683 16.374 30.6107 15.426 29.9904 14.8769C29.3701 14.3278 28.4221 14.3854 27.873 15.0057L30.1192 16.9943ZM11.0099 24.1302L17.0299 29.3831L19.0023 27.1227L12.9823 21.8698L11.0099 24.1302ZM19.2798 29.2381L30.1192 16.9943L27.873 15.0057L17.0336 27.2496L19.2798 29.2381ZM17.0299 29.3831C17.6923 29.9612 18.6971 29.8964 19.2798 29.2381L17.0336 27.2496C17.5435 26.6736 18.4227 26.6169 19.0023 27.1227L17.0299 29.3831Z"
        fill="white"
      />
    </svg>
  );
};

export { CompletedIcon };
