const GuidedTour = ({
  width = 20,
  height = 20,
  className,
  color = 'url(#paint0_linear_15323_107352)',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M9.99179 1.00007C9.8425 1.00158 9.69975 1.06231 9.59529 1.16911C9.49083 1.27577 9.43307 1.41959 9.43483 1.56891V17.8737H7.30909C7.28963 17.8727 7.27029 17.8727 7.25083 17.8737C7.04982 17.8844 6.86967 18.0012 6.77825 18.1806C6.68685 18.3598 6.6979 18.5741 6.80738 18.7429C6.91686 18.9118 7.10808 19.0096 7.30908 18.9993H12.6867C12.8361 18.9994 12.9795 18.9402 13.0852 18.8346C13.1909 18.7291 13.2503 18.5859 13.2503 18.4365C13.2503 18.2872 13.1909 18.144 13.0852 18.0383C12.9794 17.9328 12.8361 17.8736 12.6867 17.8737H10.5609V1.56889C10.5627 1.41742 10.5033 1.27173 10.3962 1.16468C10.2891 1.05764 10.1433 0.998281 9.9918 1.00004L9.99179 1.00007ZM12.1924 2.79648C12.0532 2.81041 11.9243 2.87566 11.8308 2.97932C11.7371 3.0831 11.6855 3.21788 11.6859 3.35767V6.76493C11.6852 6.91477 11.7446 7.0587 11.8506 7.16461C11.9567 7.27052 12.1006 7.32988 12.2505 7.32925H16.3987C16.5307 7.32862 16.6583 7.28157 16.759 7.19636L18.798 5.49433V5.49446C18.926 5.38767 19 5.22955 19 5.06288C19 4.89621 18.926 4.73825 18.798 4.6313L16.759 2.92613C16.6578 2.84217 16.5302 2.79624 16.3987 2.79649H12.2505C12.2311 2.79549 12.2117 2.79548 12.1924 2.79648ZM12.8108 3.92201H16.1911L17.5566 5.06286L16.1911 6.20371H12.8108V3.92201ZM3.60132 6.20368V6.20381C3.46936 6.20318 3.34142 6.24911 3.23985 6.33331L1.20202 8.03861C1.07395 8.14541 1 8.30353 1 8.47019C1 8.63672 1.07395 8.79482 1.20202 8.90164L3.23985 10.6069C3.34143 10.6911 3.46936 10.7371 3.60132 10.7366H7.7495C7.89803 10.736 8.0404 10.6766 8.14535 10.5714C8.25031 10.4663 8.30945 10.324 8.3097 10.1754V6.76491C8.30945 6.61633 8.25031 6.47402 8.14535 6.36886C8.04039 6.2637 7.89801 6.20434 7.7495 6.20384L3.60132 6.20368ZM3.80458 7.32921H7.18491V9.61101H3.80426L2.43876 8.47016L3.80458 7.32921Z"
        fill={color}
      />
    </svg>
  );
};

export default GuidedTour;
