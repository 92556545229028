import { combineReducers } from 'redux';
import hypertestJobListReducer from './hypertestJobList';
import hypertestJobDetailReducer from './hypertestJobDetail';
import hypertestTaskLogReducer from './hypertestTaskLog';
import hypertestTasksListReducer from './hypertestTasksList';
import hypertestStageLogReducer from './stageLog';
import associatedTestReducer from './associatedTest';
import { filterReducer } from './filters';
import userInfoReducer from './userInfo';
import hypertestConcurrencyReducer from './concurrency';
import hypertestJobArtifactsReducer from './artifacts';
import secretReducer from './secrets';
import hypertestOnboardingReducer from './onboarding';
import hypertestVRReducer from './vrscreenshots';
import {
  GetTestComparisonListsReducer,
  GetTestComparisonResultReducer,
} from './TestComparisonReducer';
import settingReducer from './setting';
import testHistoryReducer from './testHistory';
import featureFlag from './featureFlag';
import scenarioTimeGraph from './scenarioTimeGraph';
import projectDetailReducer from './projectDetail';
import projectListingReducer from './projectListing';
import scheduleJobReducer from './scheduleJob';
import concurrencyTimeEstimateReducer from './concurrencyTimeEstimate';
import insightOverviewReducer from './insightOverview';
import rcaReducer from './rca';
import userList from './userList';
import stages from './stages';
import preferences from './preferences';

const reducers = combineReducers({
  hypertestJobList: hypertestJobListReducer,
  hypertestJobDetail: hypertestJobDetailReducer,
  hypertestTaskLog: hypertestTaskLogReducer,
  hypertestTasksList: hypertestTasksListReducer,
  hypertestStageLog: hypertestStageLogReducer,
  associatedTest: associatedTestReducer,
  filters: filterReducer,
  userInfo: userInfoReducer,
  concurrency: hypertestConcurrencyReducer,
  artifacts: hypertestJobArtifactsReducer,
  secrets: secretReducer,
  onboarding: hypertestOnboardingReducer,
  visualRegression: hypertestVRReducer,
  GetTestComparisonListsReducer: GetTestComparisonListsReducer,
  GetTestComparisonResultReducer: GetTestComparisonResultReducer,
  appSetting: settingReducer,
  testHistory: testHistoryReducer,
  scenarioTimeGraph,
  featureFlag,
  projectListing: projectListingReducer,
  projectDetail: projectDetailReducer,
  scheduleJobs: scheduleJobReducer,
  concurrencyTimeEstimate: concurrencyTimeEstimateReducer,
  insightOverview: insightOverviewReducer,
  rca: rcaReducer,
  userList,
  stages,
  preferences,
});

export default reducers;
