import React from 'react';

const PJBLocked = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M7 11.5C7 11.2239 7.22386 11 7.5 11H8.375C8.65114 11 8.875 11.2239 8.875 11.5V12.5C8.875 12.7761 8.65114 13 8.375 13H7.5C7.22386 13 7 12.7761 7 12.5V11.5ZM7 3.5C7 3.22386 7.22386 3 7.5 3H8.375C8.65114 3 8.875 3.22386 8.875 3.5V8.5C8.875 8.77614 8.65114 9 8.375 9H7.5C7.22386 9 7 8.77614 7 8.5V3.5Z"
        fill="white"
      />
    </svg>
  );
};

export default PJBLocked;
