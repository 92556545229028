import React from 'react';

const VideoGrey = ({ width = '17', height = '16', className, ...rest }) => {
  return (
    <svg
      role="presentation"
      aria-hidden="true"
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <rect
        x="1.375"
        y="3.375"
        width="10.25"
        height="9.25"
        rx="1.125"
        stroke="#666666"
        strokeWidth="1.25"
      />
      <path
        d="M12.0999 6.51986C11.9574 6.63861 11.875 6.81451 11.875 7V9C11.875 9.18549 11.9574 9.36139 12.0999 9.48014L15.0999 11.9801C15.2862 12.1354 15.5455 12.1689 15.7651 12.066C15.9847 11.9631 16.125 11.7425 16.125 11.5V4.5C16.125 4.25749 15.9847 4.03687 15.7651 3.93401C15.5455 3.83114 15.2862 3.86461 15.0999 4.01986L12.0999 6.51986Z"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoGrey;
