import React from 'react';

const SearchCross = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5921_38900)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.41443 17.6569C3.29024 14.5327 3.29024 9.46734 6.41443 6.34315C9.53863 3.21895 14.6039 3.21895 17.7281 6.34315C20.8523 9.46734 20.8523 14.5327 17.7281 17.6569C14.6039 20.781 9.53863 20.781 6.41443 17.6569ZM10.1016 8.96967C9.80872 8.67678 9.33384 8.67678 9.04095 8.96967C8.74806 9.26256 8.74806 9.73744 9.04095 10.0303L11.0106 12L9.04095 13.9697C8.74806 14.2626 8.74806 14.7374 9.04095 15.0303C9.33385 15.3232 9.80872 15.3232 10.1016 15.0303L12.0713 13.0607L14.0409 15.0303C14.3338 15.3232 14.8087 15.3232 15.1016 15.0303C15.3945 14.7374 15.3945 14.2626 15.1016 13.9697L13.1319 12L15.1016 10.0303C15.3945 9.73744 15.3945 9.26257 15.1016 8.96968C14.8087 8.67678 14.3338 8.67678 14.041 8.96968L12.0713 10.9393L10.1016 8.96967Z"
          fill="#666666"
        />
      </g>
      <defs>
        <clipPath id="clip0_5921_38900">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4.07129 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchCross;
