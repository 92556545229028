export const Role = {
  USER: 'User',
  ADMIN: 'Admin',
};

export const ParallelMessage = {
  OVER_LIMIT:
    'Congrats your offer has been applied. Now you&apos;ve got additional COUNT Parallels firepower for blazing fast speed.',
  UNDER_LIMIT: 'No. of Initiated and Running Tasks',
};

export const ExcludeOnPremURLS = [
  'https://dashboard.hyperexecute.cloud',
  'https://stage-dashboard.hyperexecute.cloud',
];

export const JOB_DETAIL_TOOLTIP = 'lt-new-jd-tooltip';
export const PERFORMANCE_TOOOLTIP = 'performance-tooltip';
export const PROJECT_DETAIL_TOOLTIP = 'project-detail-page-tooltip';
