export const HYPERTEST_TASK_DETAIL_ACTIONS_REQUEST =
  'HYPERTEST_TASK_DETAIL_ACTIONS_REQUEST,';
export const HYPERTEST_TASK_DETAIL_ACTIONS_RESPONE =
  'HYPERTEST_TASK_DETAIL_ACTIONS_RESPONE,';
export const HYPERTEST_TASKS_LIST_UPDATE_SSE_EVENT =
  'HYPERTEST_TASKS_LIST_UPDATE_SSE_EVENT,';
export const HYPERTEST_TASKS_LIST_ACTIONS_REQUEST =
  'HYPERTEST_TASKS_LIST_ACTIONS_REQUEST,';
export const HYPERTEST_TASKS_LIST_ACTIONS_RESPONSE =
  'HYPERTEST_TASKS_LIST_ACTIONS_RESPONSE,';
export const HYPERTEST_TASKS_LIST_RESET = 'HYPERTEST_TASKS_LIST_RESET,';
export const HYPERTEST_UPDATE_TASK_LIST_FOR_ABORT_ACTION =
  'HYPERTEST_UPDATE_TASK_LIST_FOR_ABORT_ACTION';
  export const HYPERTEST_TASK_DETAIL_ACTIONS_RESPONEE =
    'HYPERTEST_TASK_DETAIL_ACTIONS_RESPONEE,';
