import { useEffect } from 'react';

import { LTModal } from '@lambdatestincprivate/lt-components';
import { addRoleToModalClose } from '../../new-theme/utils/wcag';

function getModalMinWidth(type) {
  if (type === 'gitpod') {
    return '880px';
  } else if (type === 'allowPopUp') {
    return '572px';
  } else {
    return 'auto';
  }
}
function getModalWidth(type) {
  if (type === 'gitpod' || type === 'allowPopUp') {
    return 'auto';
  } else {
    return '572px';
  }
}
export default function Modal({
  isOpen,
  setIsOpen,
  type,
  children,
  promoBannerHeight,
  showClose = true,
  className = '',
  style = {},
  innerDivStyle = {},
  ariaLabelledBy = 'lt-modal-title',
}) {
  useEffect(() => {
    if (isOpen) {
      addRoleToModalClose();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <LTModal
      extraStyle={{
        width: getModalWidth(type),
        minWidth: getModalMinWidth(type),
        ...style,
      }}
      onClose={() => setIsOpen(false)}
      aria-labelledby={ariaLabelledBy}
    >
      <div
        style={{
          width: getModalWidth(type),
          minWidth: getModalMinWidth(type),
          ...innerDivStyle,
        }}
      >
        {children}
      </div>
    </LTModal>
  );

  // return (
  //   <Transition appear show={isOpen} as={Fragment}>
  //     <Dialog
  //       as="div"
  //       className="ltw-fixed ltw-inset-0 ltw-overflow-y-auto"
  //       style={{ zIndex: 99999 }}
  //       onClose={() => setIsOpen(false)}
  //     >
  //       <div className="ltw-min-h-screen ltw-px-4 ltw-text-center">
  //         <Transition.Child
  //           as={Fragment}
  //           enter="ease-out duration-300"
  //           enterFrom="opacity-0"
  //           enterTo="opacity-100"
  //           leave="ease-in duration-200"
  //           leaveFrom="opacity-100"
  //           leaveTo="opacity-0"
  //         >
  //           <Dialog.Overlay className="ltw-fixed ltw-inset-0 ltw-bg-black-darkest ltw-opacity-70" />
  //         </Transition.Child>

  //         {/* This element is to trick the browser into centering the modal contents. */}
  //         <span
  //           className={`ltw-inline-block ltw-h-screen ${
  //             type === 'allowPopUp' ? 'ltw-align-top' : 'ltw-align-middle'
  //           }`}
  //           aria-hidden="true"
  //         >
  //           &#8203;
  //         </span>
  //         <Transition.Child
  //           as={Fragment}
  //           enter="ease-out duration-300"
  //           enterFrom="opacity-0 scale-95"
  //           enterTo="opacity-100 scale-100"
  //           leave="ease-in duration-200"
  //           leaveFrom="opacity-100 scale-100"
  //           leaveTo="opacity-0 scale-95"
  //         >
  //           <div
  //             className={`ltw-inline-block ${getModalWidth(
  //               type
  //             )} ${getModalMinWidth(type)} ltw-p-6 ${
  //               type === 'allowPopUp' ? 'ltw-my-20' : 'ltw-my-14'
  //             } ${
  //               promoBannerHeight > 0 ? 'modalBannerPadding' : ''
  //             } ltw-overflow-hidden ltw-text-left ltw-align-middle ltw-transition-all ltw-transform ltw-bg-white ltw-rounded-8px ltw-position-relative ${className}`}
  //           >
  //             {showClose && (
  //               <div
  //                 className="ltw-absolute ltw-top-20px ltw-right-20px ltw-justify-end lt-btn-focus-visible"
  //                 onClick={() => setIsOpen(false)}
  //                 tabIndex="0"
  //                 role="button"
  //                 onKeyDown={(e) =>
  //                   isEnterOrSpacePressed(e) && setIsOpen(false)
  //                 }
  //               >
  //                 <Icon
  //                   name={'close'}
  //                   width="15"
  //                   height="15"
  //                   className="ltw-fill-current ltw-cursor-pointer ltw-text-black"
  //                 />
  //               </div>
  //             )}
  //             {children}
  //           </div>
  //         </Transition.Child>
  //       </div>
  //     </Dialog>
  //   </Transition>
  // );
}
