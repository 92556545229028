import React from 'react';

const Failed = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
    >
      <circle cx="8" cy="8" r="8" fill="#CF222E" />
      <path
        d="M5.5 5.5L10.5005 10.5005"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M5.5 10.5L10.5005 5.49947"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { Failed };
