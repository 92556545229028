import React from 'react';

const DownloadCodeIcon = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M2.75 14C2.28587 14 1.84075 13.8156 1.51256 13.4874C1.18437 13.1592 1 12.7141 1 12.25V9.75C1 9.55109 1.07902 9.36032 1.21967 9.21967C1.36032 9.07902 1.55109 9 1.75 9C1.94891 9 2.13968 9.07902 2.28033 9.21967C2.42098 9.36032 2.5 9.55109 2.5 9.75V12.25C2.5 12.388 2.612 12.5 2.75 12.5H13.25C13.3163 12.5 13.3799 12.4737 13.4268 12.4268C13.4737 12.3799 13.5 12.3163 13.5 12.25V9.75C13.5 9.55109 13.579 9.36032 13.7197 9.21967C13.8603 9.07902 14.0511 9 14.25 9C14.4489 9 14.6397 9.07902 14.7803 9.21967C14.921 9.36032 15 9.55109 15 9.75V12.25C15 12.7141 14.8156 13.1592 14.4874 13.4874C14.1592 13.8156 13.7141 14 13.25 14H2.75Z"
        fill="#666666"
      />
      <path
        d="M7.24999 7.689V2C7.24999 1.80109 7.32901 1.61032 7.46966 1.46967C7.61031 1.32902 7.80108 1.25 7.99999 1.25C8.1989 1.25 8.38967 1.32902 8.53032 1.46967C8.67097 1.61032 8.74999 1.80109 8.74999 2V7.689L10.72 5.72C10.7896 5.6504 10.8722 5.59519 10.9632 5.55752C11.0541 5.51985 11.1516 5.50047 11.25 5.50047C11.3484 5.50047 11.4459 5.51985 11.5368 5.55752C11.6278 5.59519 11.7104 5.6504 11.78 5.72C11.8496 5.7896 11.9048 5.87223 11.9425 5.96317C11.9801 6.0541 11.9995 6.15157 11.9995 6.25C11.9995 6.34843 11.9801 6.4459 11.9425 6.53683C11.9048 6.62777 11.8496 6.7104 11.78 6.78L8.52999 10.03C8.46043 10.0997 8.37781 10.1549 8.28687 10.1926C8.19593 10.2303 8.09844 10.2498 7.99999 10.2498C7.90154 10.2498 7.80406 10.2303 7.71311 10.1926C7.62217 10.1549 7.53956 10.0997 7.46999 10.03L4.21999 6.78C4.15039 6.7104 4.09518 6.62777 4.05751 6.53683C4.01985 6.4459 4.00046 6.34843 4.00046 6.25C4.00046 6.15157 4.01985 6.0541 4.05751 5.96317C4.09518 5.87223 4.15039 5.7896 4.21999 5.72C4.28959 5.6504 4.37222 5.59519 4.46316 5.55752C4.55409 5.51985 4.65156 5.50047 4.74999 5.50047C4.84842 5.50047 4.94589 5.51985 5.03682 5.55752C5.12776 5.59519 5.21039 5.6504 5.27999 5.72L7.24999 7.689Z"
        fill="#666666"
      />
    </svg>
  );
};

export { DownloadCodeIcon };
