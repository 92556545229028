import React from 'react';

const FileLocation = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      role="presentation"
      aria-hidden="true"
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.66797 14.6673H12.0013C12.3549 14.6673 12.6941 14.5268 12.9441 14.2768C13.1942 14.0267 13.3346 13.6876 13.3346 13.334V5.00065L9.66797 1.33398H4.0013C3.64768 1.33398 3.30854 1.47446 3.05849 1.72451C2.80844 1.97456 2.66797 2.3137 2.66797 2.66732V5.50098"
        stroke="#666666"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.33203 1.33398V5.33398H13.332"
        stroke="#666666"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 13C6.65685 13 8 11.6569 8 10C8 8.34315 6.65685 7 5 7C3.34315 7 2 8.34315 2 10C2 11.6569 3.34315 13 5 13Z"
        stroke="#666666"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 10H6.5"
        stroke="#666666"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.5 10H2.5"
        stroke="#666666"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 8.5V7.5"
        stroke="#666666"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 12.5V11.5"
        stroke="#666666"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FileLocation;
