const DropdownIcon = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M4.42675 7.42678L7.82323 10.8232C7.92086 10.9209 8.07915 10.9209 8.17678 10.8232L11.5732 7.42678C11.7307 7.26928 11.6192 7 11.3964 7H4.60353C4.3808 7 4.26926 7.26929 4.42675 7.42678Z"
        fill="#666666"
      />
    </svg>
  );
};

export default DropdownIcon;
