import React from 'react';

const FilterIcon = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.125 1.75C12.6773 1.75 13.125 2.19772 13.125 2.75V2.7825C13.125 3.05628 13.0127 3.31809 12.8144 3.50685L9.06056 7.07978C8.86225 7.26853 8.75 7.53034 8.75 7.80413V11.25C8.75 11.8023 8.30228 12.25 7.75 12.25H6.25C5.69772 12.25 5.25 11.8023 5.25 11.25V7.80407C5.25 7.53032 5.13778 7.26854 4.93951 7.07979L1.18549 3.50596C0.987222 3.31721 0.875 3.05543 0.875 2.78169V2.75C0.875 2.19771 1.32272 1.75 1.875 1.75H12.125ZM5.81451 6.70441C6.01278 6.89317 6.125 7.15494 6.125 7.42869V10.5C6.125 10.9832 6.51675 11.375 7 11.375C7.48325 11.375 7.875 10.9832 7.875 10.5V7.42869C7.875 7.15494 7.98722 6.89317 8.18549 6.70441L12.2044 2.87845C12.2335 2.8507 12.25 2.81223 12.25 2.77199C12.25 2.69081 12.1842 2.625 12.103 2.625H1.89699C1.81581 2.625 1.75 2.69081 1.75 2.77199C1.75 2.81223 1.7665 2.8507 1.79564 2.87845L5.81451 6.70441Z"
        fill="#666666"
      />
    </svg>
  );
};

export { FilterIcon };
