const BackgroundServiceIcon = ({
  width = '16',
  height = '16',
  classname,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={classname}
      {...rest}
      role="img"
      aria-hidden="true"
    >
      <g clipPath="url(#clip0_1930_57621)">
        <path
          d="M5.63934 15.9995C4.74005 15.9995 4.00312 15.4249 3.85324 14.6006L2.82904 14.7255C2.76659 14.738 2.69165 14.738 2.6167 14.738C1.74239 14.738 1.0929 14.1134 1.0929 13.3016V3.0346C1.0929 2.21025 1.75488 1.58573 2.6292 1.58573C2.69165 1.58573 2.76659 1.58573 2.82904 1.59822L3.85324 1.71064C4.01561 0.89877 4.75254 0.324219 5.65183 0.324219C5.73927 0.324219 5.81421 0.324219 5.88915 0.336709L13.3333 1.14857C14.1952 1.2485 14.8946 2.06036 14.8821 2.95966V13.2891C14.8821 14.2009 14.1827 15.0127 13.3208 15.1127L5.87666 15.987C5.80172 15.987 5.71428 15.9995 5.63934 15.9995ZM5.45199 1.01118C5.12724 1.01118 4.76503 1.18605 4.76503 1.57324V14.738C4.76503 15.1127 5.11475 15.2875 5.42701 15.2875C5.46448 15.2875 5.51444 15.2875 5.55191 15.275L13.5582 14.3383C13.8454 14.3008 14.0952 13.9885 14.0952 13.6638V2.54748C14.0952 2.23523 13.8454 1.92297 13.5706 1.8855L5.5644 1.01118C5.52693 1.02367 5.48946 1.01118 5.45199 1.01118ZM2.242 2.36013C1.96061 2.36013 1.99988 2.79967 1.99988 2.99951V13.6638C1.99988 13.9136 2.17955 13.9386 2.22951 13.9386H2.32943L3.86573 13.8012V2.49752L2.34192 2.36013H2.242Z"
          fill="#666666"
        />
        <path
          d="M6.63 9.5H6.3675C6.1575 9.5 6 9.38461 6 9.23077V6.76923C6 6.61538 6.1575 6.5 6.3675 6.5H6.63C6.83999 6.5 6.99749 6.61538 6.99749 6.76923V9.23077C7.02374 9.38461 6.83999 9.5 6.63 9.5Z"
          fill="#666666"
        />
      </g>
      <defs>
        <clipPath id="clip0_1930_57621">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BackgroundServiceIcon;
