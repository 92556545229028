export const Variant = {
  DANGER: 'danger',
  OUTLINED: 'outlined',
  DEFAULT: 'default',
  PRIMARY: 'primary' /** default */,
  PRIMARY_TRANSPARENT: 'primary-transparent',
  SECONDARY: 'secondary',
  SECONDARY_TRANSPARENT: 'secondary-transparent',
  UNDERLINED: 'underlined',
  INVISIBLE: 'invisible',
  TERTIARY: 'tertiary',
  PLAIN: 'plain',
};
