import React from 'react';

const EdgeIcon = ({ width = 12, height = 12, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 22"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        fill="#1278DE"
        fillRule="nonzero"
        d="M7.498 5.179c-.84.911-1.261 2.034-1.401 3.226h7.428v-.561c-.07-.772-.21-1.543-.63-2.245-.561-.911-1.472-1.402-2.524-1.542-1.541-.28-3.013 0-4.414.56A12.237 12.237 0 0 0 .7 8.336c-.21.28-.42.63-.701.981 0-.21 0-.28.07-.42.28-1.964.981-3.857 2.313-5.4C3.924 1.672 5.886.55 8.269.129c2.313-.35 4.485 0 6.517 1.193 1.892 1.052 3.154 2.665 3.925 4.698.42 1.122.63 2.385.7 3.577V12.191H6.238c0 1.053.35 2.035 1.051 2.806.841.912 1.962 1.332 3.084 1.543 2.522.35 4.835-.07 7.007-1.333.07 0 .14-.07.14-.07h.07v4.208c0 .14-.07.21-.21.28-1.541.772-3.223 1.263-4.975 1.333-1.121.07-2.243.07-3.294-.14-3.293-.702-5.956-3.086-6.587-6.522-.7-3.928 1.191-7.504 4.905-9.117h.07z"
      />
    </svg>
  );
};

export default EdgeIcon;
