import React from 'react';

const UserDemark = ({
  width,
  height,
  className,
  onClick,
  id = 'clip0_2826_2408',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      role="img"
      aria-hidden="true"
    >
      <g clipPath="url(#clip0_2826_2408)">
        <path
          d="M6.125 4.375C5.69235 4.375 5.26942 4.50329 4.90969 4.74366C4.54996 4.98403 4.26958 5.32567 4.10401 5.72538C3.93845 6.12509 3.89513 6.56493 3.97953 6.98926C4.06394 7.41359 4.27228 7.80337 4.57821 8.1093C4.88413 8.41522 5.27391 8.62356 5.69824 8.70797C6.12257 8.79237 6.56241 8.74905 6.96212 8.58349C7.36184 8.41792 7.70348 8.13754 7.94384 7.77781C8.18421 7.41808 8.3125 6.99515 8.3125 6.5625C8.3125 5.98234 8.08203 5.42594 7.6718 5.0157C7.26156 4.60547 6.70516 4.375 6.125 4.375ZM6.125 7.875C5.86541 7.875 5.61165 7.79802 5.39582 7.6538C5.17998 7.50958 5.01175 7.3046 4.91241 7.06477C4.81307 6.82494 4.78708 6.56104 4.83772 6.30644C4.88836 6.05184 5.01337 5.81798 5.19692 5.63442C5.38048 5.45087 5.61435 5.32586 5.86895 5.27522C6.12355 5.22458 6.38745 5.25057 6.62727 5.34991C6.8671 5.44925 7.07209 5.61747 7.21631 5.83331C7.36053 6.04915 7.4375 6.30291 7.4375 6.5625C7.43715 6.91049 7.29876 7.24413 7.0527 7.49019C6.80663 7.73626 6.47299 7.87465 6.125 7.875Z"
          fill="#666666"
        />
        <path
          d="M6.125 1.75C4.91359 1.75 3.72939 2.10923 2.72213 2.78225C1.71488 3.45527 0.929827 4.41187 0.466241 5.53106C0.00265386 6.65026 -0.118642 7.88179 0.117693 9.06993C0.354027 10.2581 0.937377 11.3494 1.79397 12.206C2.65057 13.0626 3.74194 13.646 4.93007 13.8823C6.11821 14.1186 7.34974 13.9973 8.46894 13.5338C9.58814 13.0702 10.5447 12.2851 11.2178 11.2779C11.8908 10.2706 12.25 9.08641 12.25 7.875C12.2481 6.25112 11.6022 4.69428 10.454 3.54602C9.30573 2.39776 7.74889 1.75185 6.125 1.75ZM3.5 12.4149V11.8125C3.50035 11.4645 3.63874 11.1309 3.88481 10.8848C4.13088 10.6387 4.46451 10.5003 4.8125 10.5H7.4375C7.78549 10.5003 8.11913 10.6387 8.3652 10.8848C8.61126 11.1309 8.74965 11.4645 8.75 11.8125V12.4149C7.95339 12.8801 7.04748 13.1252 6.125 13.1252C5.20253 13.1252 4.29662 12.8801 3.5 12.4149ZM9.6215 11.7801C9.61278 11.2063 9.37898 10.6588 8.97046 10.2557C8.56195 9.85258 8.01142 9.62608 7.4375 9.625H4.8125C4.23858 9.62608 3.68806 9.85258 3.27954 10.2557C2.87103 10.6588 2.63722 11.2063 2.6285 11.7801C1.83512 11.0717 1.27563 10.139 1.02412 9.10554C0.772601 8.07207 0.840921 6.98659 1.22003 5.99281C1.59914 4.99904 2.27116 4.14385 3.1471 3.5405C4.02304 2.93714 5.06159 2.61407 6.12522 2.61407C7.18885 2.61407 8.2274 2.93714 9.10334 3.5405C9.97928 4.14385 10.6513 4.99904 11.0304 5.99281C11.4095 6.98659 11.4778 8.07207 11.2263 9.10554C10.9748 10.139 10.4153 11.0717 9.62194 11.7801H9.6215Z"
          fill="#666666"
        />
        <circle
          cx="11.375"
          cy="2.625"
          r="3.125"
          fill="#2AB14F"
          stroke="#F6F8FC"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { UserDemark };
