import React from 'react';

const Artifect = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M10.8494 3.66387L8.11738 0.931836C8.04121 0.855664 7.93838 0.8125 7.83047 0.8125H2.4375C2.21279 0.8125 2.03125 0.994043 2.03125 1.21875V11.7812C2.03125 12.006 2.21279 12.1875 2.4375 12.1875H10.5625C10.7872 12.1875 10.9688 12.006 10.9688 11.7812V3.95205C10.9688 3.84414 10.9256 3.74004 10.8494 3.66387ZM7.64258 1.74941L10.0318 4.13867H7.64258V1.74941ZM10.0547 11.2734H2.94531V1.72656H3.75781V2.53906H4.57031V1.72656H6.7793V4.46875C6.7793 4.61016 6.83547 4.74579 6.93547 4.84578C7.03546 4.94578 7.17109 5.00195 7.3125 5.00195H10.0547V11.2734Z"
        fill="#666666"
      />
      <path
        d="M3.75781 4.97656H4.57031V5.78906H3.75781V4.97656ZM3.75781 3.35156H4.57031V4.16406H3.75781V3.35156ZM3.75781 7.38867V9.41992H5.38281V7.38867H4.57031V6.60156H3.75781V7.38867ZM4.36719 8.02344V7.99805H4.77344V8.81055H4.36719V8.02344ZM4.57031 2.53906H5.38281V3.35156H4.57031V2.53906ZM4.57031 5.78906H5.38281V6.60156H4.57031V5.78906ZM4.57031 4.16406H5.38281V4.97656H4.57031V4.16406Z"
        fill="#666666"
      />
    </svg>
  );
};

export { Artifect };
