import React from 'react';

const Clock = ({
  id = 'clip0_12802_305',
  width = 18,
  height = 18,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M4.9987 8.5C6.83203 8.5 8.5 6.83398 8.5 5.00065C8.5 3.16732 6.83203 1.5 4.9987 1.5C3.16536 1.5 1.5 3.16667 1.5 5C1.5 6.83333 3.16536 8.5 4.9987 8.5ZM4.9987 0.833984C7.29036 0.833984 9.16536 2.70898 9.16536 5.00065C9.16536 7.29232 7.29036 9.16732 4.9987 9.16732C2.70703 9.16732 0.832031 7.29232 0.832031 5.00065C0.832031 2.70898 2.70703 0.833984 4.9987 0.833984Z"
          fill="#999999"
        />
        <path
          d="M4.67234 3.06978C4.71754 3.02485 4.77875 2.99973 4.8425 3C4.97448 3.00048 5.08131 3.1076 5.08158 3.23959V5.45393L6.30923 5.95175C6.43191 6.00117 6.49144 6.14059 6.44235 6.26347C6.41858 6.32287 6.37211 6.37028 6.31325 6.39532C6.25438 6.4203 6.18795 6.42077 6.12876 6.3966L4.75203 5.83832C4.66163 5.80196 4.60217 5.71451 4.60156 5.61708V3.23953C4.6017 3.17578 4.62714 3.11471 4.67234 3.06978Z"
          fill="#999999"
        />
      </g>
      <defs>
        <clipPath id={`url(#${id})`}>
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { Clock };
