import React from 'react';

const PJUser = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      viewBox="0 0 16 16"
      {...rest}
    >
      <g id="User">
        <path
          id="Vector"
          d="M3.33594 14C3.33594 11.4227 5.42528 9.33333 8.0026 9.33333C10.5799 9.33333 12.6693 11.4227 12.6693 14M10.6693 4.66667C10.6693 6.13943 9.47534 7.33333 8.0026 7.33333C6.52984 7.33333 5.33594 6.13943 5.33594 4.66667C5.33594 3.19391 6.52984 2 8.0026 2C9.47534 2 10.6693 3.19391 10.6693 4.66667Z"
          stroke="#666666"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default PJUser;
