import React from 'react';

const FilterRightArrow = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 06 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M1 1L5 5L1 9"
        stroke="#4C4C4C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { FilterRightArrow };
