import React from 'react';

const Hybrid = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      role="img"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M1.3679 13.8153V2.9446H4.16761V3.95455H2.58665V12.8054H4.16761V13.8153H1.3679ZM14.0529 2.9446V13.8153H11.2532V12.8054H12.8342V3.95455H11.2532V2.9446H14.0529Z"
        fill="#666666"
      />
      <rect x="4" y="8" width="1" height="1" fill="#666666" />
      <rect x="10" y="8" width="1" height="1" fill="#666666" />
      <rect x="4" y="10" width="1" height="1" fill="#666666" />
      <rect x="10" y="10" width="1" height="1" fill="#666666" />
      <path
        d="M8.74687 5.37188L9.37687 6.00188L8.11687 7.26188L8.73812 7.88313L9.99812 6.62313L10.6281 7.25313C10.659 7.28336 10.6981 7.30379 10.7405 7.31183C10.7829 7.31988 10.8268 7.31518 10.8666 7.29834C10.9063 7.2815 10.9402 7.25326 10.964 7.21719C10.9877 7.18111 11.0002 7.13881 11 7.09563V5.21875C11 5.09625 10.9037 5 10.7812 5H8.90437C8.86118 4.99975 8.81889 5.01229 8.78281 5.03603C8.74674 5.05978 8.7185 5.09367 8.70166 5.13344C8.68482 5.17321 8.68012 5.21707 8.68817 5.2595C8.69621 5.30194 8.71664 5.34104 8.74687 5.37188V5.37188ZM6.09562 5H4.21875C4.09625 5 4 5.09625 4 5.21875V7.09563C4 7.2925 4.23625 7.38875 4.37187 7.24875L5.00187 6.61875L7.0625 8.675V11.5625C7.0625 11.8031 7.25937 12 7.5 12C7.74062 12 7.9375 11.8031 7.9375 11.5625V8.5C7.9375 8.38625 7.88937 8.2725 7.81062 8.18938L5.62312 5.9975L6.25312 5.3675C6.38875 5.23625 6.2925 5 6.09562 5V5Z"
        fill="#666666"
      />
    </svg>
  );
};

export { Hybrid };
