export const TEST_COMAPARISON_LIST_REQUEST = 'TEST_COMAPARISON_LIST_REQUEST';
export const TEST_COMAPARISON_LIST_SUCCESS = 'TEST_COMAPARISON_LIST_SUCCESS';
export const TEST_COMAPARISON_LIST_FAILED = 'TEST_COMAPARISON_LIST_FAILED';

export const TEST_COMPARISON_RESULT_REQUEST = 'TEST_COMPARISON_RESULT_REQUEST';
export const TEST_COMPARISON_RESULT_SUCCESS = 'TEST_COMPARISON_RESULT_SUCCESS';
export const TEST_COMPARISON_RESULT_FAILED = 'TEST_COMPARISON_RESULT_FAILED';
export const TEST_COMPARISON_RESULT_EMPTY = 'TEST_COMPARISON_RESULT_EMPTY';
export const TEST_INFO_RESULT_EMPTY = 'TEST_INFO_RESULT_EMPTY';
export const TEST_COMPARISON_RESULT_UNMOUNT = 'TEST_COMPARISON_RESULT_UNMOUNT';

export const CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_FAILED = 'CHANGE_STATUS_FAILED';

export const TEST_FILTER_REQUEST = 'TEST_FILTER_REQUEST';
export const TEST_FILTER_SUCCESS = 'TEST_FILTER_SUCCESS';
export const TEST_FILTER_FAILED = 'TEST_FILTER_FAILED';
