import React from 'react';

const Appium = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
      role="img"
      aria-hidden="true"
    >
      <g clipPath="url(#clip0_720_17383)">
        <path
          d="M12.6051 1.58044C10.6812 -0.0737266 6.4242 -0.893186 3.23407 1.48171C0.241262 3.47994 0.0932617 7.13221 0.0932617 7.13221C0.603012 9.13043 2.62564 11.0467 6.52282 10.1131C6.06245 7.42703 6.96482 0.63057 12.6051 1.5805V1.58044Z"
          fill="#00ACD2"
        />
        <path
          d="M10.6012 8.07684C8.49593 9.81704 2.08761 12.4724 0.093426 7.13232C-0.382949 9.61896 0.938988 13.7577 4.59899 15.3219C7.83286 16.9043 11.203 15.0717 11.203 15.0717C12.6855 13.6329 13.3616 10.9717 10.6012 8.07684Z"
          fill="#C8E9EB"
        />
        <path
          d="M6.79883 5.636C9.36458 6.58189 14.8493 10.6814 11.2031 15.0716C13.6035 14.2391 16.4181 11.054 15.9483 7.11494C15.7073 3.53432 12.6055 1.58048 12.6055 1.58048C10.6133 1.0211 7.9357 1.80763 6.79883 5.636Z"
          fill="#662D91"
        />
      </g>
      <defs>
        <clipPath id="clip0_720_17383">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Appium;
