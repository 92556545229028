import React from 'react';

const Passed = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <circle cx="9" cy="9" r="9" fill="#2AB14F" />
      <path
        d="M6.38423 9.13448C6.20745 8.92235 5.89217 8.89368 5.68003 9.07047C5.46789 9.24725 5.43923 9.56253 5.61601 9.77467L6.38423 9.13448ZM7.97234 11.8212L7.58823 12.1413L7.58823 12.1413L7.97234 11.8212ZM8.12288 11.8248L7.7543 11.4869L7.7543 11.4869L8.12288 11.8248ZM12.9142 7.33789C13.1008 7.13433 13.087 6.81805 12.8834 6.63145C12.6799 6.44486 12.3636 6.45861 12.177 6.66217L12.9142 7.33789ZM5.61601 9.77467L7.58823 12.1413L8.35645 11.5011L6.38423 9.13448L5.61601 9.77467ZM8.49146 12.1627L12.9142 7.33789L12.177 6.66217L7.7543 11.4869L8.49146 12.1627ZM7.58823 12.1413C7.82077 12.4204 8.246 12.4304 8.49146 12.1627L7.7543 11.4869C7.91794 11.3084 8.20142 11.3151 8.35645 11.5011L7.58823 12.1413Z"
        fill="white"
      />
    </svg>
  );
};

export { Passed };
