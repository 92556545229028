import mitt from 'mitt';

let emitter = mitt();
let emitter2 = mitt();
let chunkNumberForSEEEvent = 0;
let chunkNumberStruct = {};
let chunkTracker = {};

function resetEmitter() {
  emitter.all.clear();
  emitter = mitt();
}
function emitEvent(event, data) {
  emitter.emit(event, data);
}

function getChunkNumberForSSEEvent(id) {
  return chunkNumberStruct[id]?.num || 0;
}

function removeChunkFromRecordWithId(chunkN, id) {
  /*
  Once a chunk number is loaded, we increase the chunk Number an save it in chunkNumberStruct along with its stageId/order/iteration

  triggerNewChunk will only be called if we haven't made any call before for that particular chunknumber,
  Reason being we emit newChunk event from multiple places like useAppJS due to SSE and useStageLog

  When we close the stage row panel, we have to reduce the chunk number, so that if user open it again, we can make the last chunk request.
  */

  if (chunkTracker[id]) {
    chunkTracker[id] = chunkTracker[id]?.filter((n) => n !== chunkN);
  }
}

/*
 isChunkNumberAlreadyExecuted - Check if we have already made a API request for passed chunkNumber or not.
*/
function isChunkNumberAlreadyExecuted(id, chunkN) {
  let emitUpdateEvent = false;
  if (chunkTracker[id] && chunkTracker[id].includes(chunkN)) {
    emitUpdateEvent = true;
  }

  return emitUpdateEvent;
}

function addChunkNumberInTrackingWithId(chunkN, id) {
  /*
  We keep the track of every request made for each chunk number.
  Because we emit newChunk event from multiple places (useApp/useStageLog) this method will prevent the race condition and prevent duplicate calls
  */
  if (chunkTracker[id] && chunkTracker[id].includes(chunkN)) {
    chunkTracker[id].push(chunkN);
  } else {
    if (chunkTracker[id]) {
      chunkTracker[id].push(chunkN);
    } else {
      chunkTracker[id] = [chunkN];
    }
  }
}

function setChunkNumberForSEEEvent(chunkNumber, id, data) {
  chunkNumberForSEEEvent = chunkNumber;
  if (chunkNumberStruct[id]) {
    chunkNumberStruct[id].num = chunkNumber;
    chunkNumberStruct[id].data = data;
  } else {
    chunkNumberStruct[id] = {};
    chunkNumberStruct[id].num = chunkNumber;
    chunkNumberStruct[id].data = data;
  }
}
export {
  emitter,
  emitter2,
  resetEmitter,
  emitEvent,
  chunkNumberForSEEEvent,
  setChunkNumberForSEEEvent,
  getChunkNumberForSSEEvent,
  addChunkNumberInTrackingWithId,
  isChunkNumberAlreadyExecuted,
  removeChunkFromRecordWithId,
};
