import React from 'react';

const DownloadCodeIcon = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <line
        x1="4.30078"
        y1="2.18557e-08"
        x2="4.30078"
        y2="8.8"
        stroke="white"
      />
      <line x1="7.64844" y1="11.5" x2="1.04844" y2="11.5" stroke="white" />
      <line
        x1="0.853553"
        y1="5.14645"
        x2="4.15355"
        y2="8.44645"
        stroke="white"
      />
      <line
        x1="4.54488"
        y1="8.44625"
        x2="7.84488"
        y2="5.14625"
        stroke="white"
      />
    </svg>
  );
};

export { DownloadCodeIcon };
