import React from 'react';

const ThumbsUp = ({ width = '18', height = '18', className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      className={className}
      fill="none"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M9.23718 0L8.70001 0.801065L4.43706 7.20026H0V18.0004H12.7861C13.4453 18.0004 14.0894 17.8029 14.6354 17.4333C15.1813 17.0639 15.6041 16.5393 15.8491 15.9274L17.7271 11.2294C18.2011 10.0448 18.0512 8.70146 17.3276 7.65053C16.6042 6.59961 15.403 5.98015 14.1271 6.00024H12.807L12.8581 5.80228C13.086 4.88571 13.2009 3.94461 13.2 3.00019C13.2 2.20453 12.884 1.44134 12.3213 0.878835C11.7588 0.316113 10.9958 0.000144688 10.2 0.000144688L9.23718 0ZM1.80029 16.2002V9.00012H4.20038L4.20017 16.2002H1.80029ZM11.4004 3.00004C11.3973 3.79736 11.2966 4.5913 11.1003 5.36413L10.5004 7.80021H14.1274C14.8207 7.78975 15.473 8.12772 15.8645 8.70007C16.2291 9.25127 16.3008 9.94605 16.0564 10.5603L14.1784 15.2583C14.0669 15.5364 13.8748 15.7747 13.6268 15.9425C13.3786 16.1104 13.086 16.2001 12.7864 16.2001H6.00037V8.10004L10.2003 1.79995C10.5186 1.79995 10.824 1.92635 11.0489 2.15131C11.2741 2.37648 11.4005 2.68159 11.4005 2.99989L11.4004 3.00004Z"
        fill="#999999"
      />
    </svg>
  );
};

export default ThumbsUp;
