import React from 'react';

const InfoBlue = ({ width, height, color, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 13 13"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M6.5 0.8125C3.35918 0.8125 0.8125 3.35918 0.8125 6.5C0.8125 9.64082 3.35918 12.1875 6.5 12.1875C9.64082 12.1875 12.1875 9.64082 12.1875 6.5C12.1875 3.35918 9.64082 0.8125 6.5 0.8125ZM6.5 11.2227C3.89238 11.2227 1.77734 9.10762 1.77734 6.5C1.77734 3.89238 3.89238 1.77734 6.5 1.77734C9.10762 1.77734 11.2227 3.89238 11.2227 6.5C11.2227 9.10762 9.10762 11.2227 6.5 11.2227Z"
        fill={color ? color : '0a7bff'}
      />
      <path
        d="M5.89062 4.26562C5.89063 4.42724 5.95483 4.58224 6.06911 4.69652C6.18339 4.8108 6.33838 4.875 6.5 4.875C6.66162 4.875 6.81661 4.8108 6.93089 4.69652C7.04517 4.58224 7.10938 4.42724 7.10938 4.26562C7.10938 4.10401 7.04517 3.94901 6.93089 3.83473C6.81661 3.72045 6.66162 3.65625 6.5 3.65625C6.33838 3.65625 6.18339 3.72045 6.06911 3.83473C5.95483 3.94901 5.89063 4.10401 5.89062 4.26562ZM6.80469 5.6875H6.19531C6.13945 5.6875 6.09375 5.7332 6.09375 5.78906V9.24219C6.09375 9.29805 6.13945 9.34375 6.19531 9.34375H6.80469C6.86055 9.34375 6.90625 9.29805 6.90625 9.24219V5.78906C6.90625 5.7332 6.86055 5.6875 6.80469 5.6875Z"
        fill={color ? color : '0a7bff'}
      />
    </svg>
  );
};

export { InfoBlue };
