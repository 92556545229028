import { callApi as callApiService } from '../../utils/apiService';

// Action key that carries API call info interpreted by this middleware.
export const CALL_API = Symbol('Call API');

// This function is responsible for checking the schema of the CALL_API.
// If the schema is valid, this makes the call to the api
// Fetches the response and dispatch the next corresponding action along with the response
async function callApiAction({ endpoint, ...props }, store, next) {
	const { types, headers = {}, extra = null, preReqData, ...rest } = props;

	if (typeof endpoint === 'function') {
		endpoint = endpoint(store.getState(), preReqData);
	}

	if (typeof rest?.query === 'function') {
		rest.query = rest.query(store.getState(), preReqData);
	}

	if (!Array.isArray(types) || types.length > 2) {
		throw new Error('Expected maximum two action types in types array');
	}

	if (!types.every((type) => typeof type === 'string')) {
		throw new Error('Expected action type to be string');
	}

	const actionWith = (data) => {
		const finalAction = { ...props, ...data };
		delete finalAction[CALL_API];
		return finalAction;
	};

	const [requestType, mainActionType] = types;

	next(actionWith({ type: requestType }));

	try {
		const response = await callApiService({
			endpoint,
			headers,
			...rest,
		});
		return mainActionType
			? next(
					actionWith({
						payload: response,
						extra: extra,
						type: mainActionType,
					})
			  )
			: response;
	} catch (error) {
		if (!error.response || error.response?.status >= 500) {
			console.log('CallApi Action Error :: 500 Server Error');
		}
		const isUnauthorisedResponse =
			error && error.response && error.response.status === 400;
		if (isUnauthorisedResponse) {
			console.log('CallApi Action Error :: Unauthorised Error');
		}
		const payload = error || new Error('Something went wrong');
		return mainActionType
			? next(
					actionWith({
						payload,
						type: mainActionType,
						error: true,
					})
			  )
			: { payload, error: true };
	}
}

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
const api = (store) => (next) => async (action) => {
	let callApi = action[CALL_API];

	if (typeof callApi === 'undefined') {
		return next(action);
	}

	// Multiple API calls
	const hasMultiple = typeof callApi.actions !== 'undefined';
	const actions = hasMultiple ? callApi.actions : [callApi];
	const postActions =
		hasMultiple && typeof callApi.postActions !== 'undefined'
			? callApi.postActions
			: [];
	let responses = await Promise.all(
		actions.map((action) => callApiAction(action, store, next))
	);

	if (hasMultiple) {
		if (responses.some(({ error }) => error === true)) {
			// In case if any of them throws an error, fail all
			return null;
		}

		let payload = responses.reduce(
			(action, curr) => ({ ...action, ...curr }),
			{}
		);

		if (postActions.length > 0) {
			let postActionsResponse = await Promise.all(
				callApi.postActions.map((action) =>
					callApiAction({ ...action, preReqData: payload }, store, next)
				)
			);

			if (postActionsResponse.some(({ error }) => error === true)) {
				// In case if any of them throw error, fail all responses.
				return null;
			}

			payload = postActionsResponse.reduce(
				(action, curr) => ({ ...action, ...curr }),
				payload
			);
		}

		return callApi.finalType
			? next({
					type: callApi.finalType,
					payload: payload,
					extra: callApi.extra,
			  })
			: responses;
	} else {
		return responses[0];
	}
};

export default api;
