import React from 'react';

const ChatSupport = ({
  width = 16,
  height = 16,
  className,
  color,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.11127 12.5303C3.2076 12.5303 3.28581 12.6085 3.28581 12.7049V14.1476C3.28581 14.4696 3.46316 14.7649 3.74666 14.9173C4.03087 15.0688 4.37516 15.0528 4.64267 14.874L7.93814 12.6771C8.08206 12.5814 8.2503 12.5304 8.42215 12.5304H12.8878C13.5826 12.5304 14.2482 12.2547 14.7398 11.7636C15.2307 11.2727 15.5066 10.6065 15.5066 9.91167V3.62659C15.5066 2.18031 14.3341 1.00781 12.8878 1.00781H3.11097C1.66468 1.00781 0.492188 2.18031 0.492188 3.62659V9.91167C0.492188 10.6065 0.767976 11.2727 1.259 11.7636C1.75061 12.2545 2.41647 12.5303 3.11127 12.5303ZM4.33332 13.6337V12.705C4.33332 12.0304 3.96107 11.295 3.28581 11.295C2.8689 11.295 2.2949 11.1294 2.00016 10.8348C1.70542 10.5401 1.71454 10.1408 1.71454 9.72372V3.8141C1.71454 2.94604 2.41775 2.24283 3.28581 2.24283H12.6626C13.5307 2.24283 14.2339 2.94604 14.2339 3.8141V9.72372C14.2339 10.1406 14.0683 10.5401 13.7737 10.8348C13.479 11.1296 13.0796 11.295 12.6626 11.295H8.42305C8.04379 11.295 7.67306 11.4075 7.35743 11.6177L4.33332 13.6337Z"
        fill="#666666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.81282 4.84829H8.70111C8.99023 4.84829 9.22486 4.61366 9.22486 4.32454C9.22486 4.03542 8.99023 3.80078 8.70111 3.80078H3.81282C3.5237 3.80078 3.28906 4.03542 3.28906 4.32454C3.28906 4.61366 3.5237 4.84829 3.81282 4.84829Z"
        fill="#666666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.81282 7.29361H8.00287C8.29199 7.29361 8.52662 7.05897 8.52662 6.76985C8.52662 6.48073 8.29199 6.24609 8.00287 6.24609H3.81282C3.5237 6.24609 3.28906 6.48073 3.28906 6.76985C3.28906 7.05897 3.5237 7.29361 3.81282 7.29361Z"
        fill="#666666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0941 7.29361H12.1891C12.4782 7.29361 12.7128 7.05897 12.7128 6.76985C12.7128 6.48073 12.4782 6.24609 12.1891 6.24609H10.0941C9.80495 6.24609 9.57031 6.48073 9.57031 6.76985C9.57031 7.05897 9.80495 7.29361 10.0941 7.29361Z"
        fill="#666666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.81282 9.73501H5.20954C5.49866 9.73501 5.7333 9.50038 5.7333 9.21126C5.7333 8.92214 5.49866 8.6875 5.20954 8.6875H3.81282C3.5237 8.6875 3.28906 8.92214 3.28906 9.21126C3.28906 9.50038 3.5237 9.73501 3.81282 9.73501Z"
        fill="#666666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.30501 9.73501H12.1933C12.4824 9.73501 12.7171 9.50038 12.7171 9.21126C12.7171 8.92214 12.4824 8.6875 12.1933 8.6875H7.30501C7.01589 8.6875 6.78125 8.92214 6.78125 9.21126C6.78125 9.50038 7.01589 9.73501 7.30501 9.73501Z"
        fill="#666666"
      />
    </svg>
  );
};

export { ChatSupport };
