export const TOOLTIP_CONTAINER_ID = 'custom-nav-header';

export const ConcurrencyInfo = [
  {
    color: 'green',
    runningCount: '20',
    title: (
      <span
        data-tip="No. of Queued Tasks"
        data-for={TOOLTIP_CONTAINER_ID}
      ></span>
    ),
    total: '200',
  },
  {
    color: 'orange',
    runningCount: '100',
    title: (
      <span data-tip="No. of Queued Tasks" data-for={TOOLTIP_CONTAINER_ID}>
        Queued
      </span>
    ),
    total: '200',
    hideTotal: true,
  },
];

export const toasterStyle = { top: 24, right: 24, zIndex: 9999999 };
