const ENTER_KEY_CODE = 13;
const ESC_KEY_CODE = 27;

export function isEnterPressed(e) {
  const code = e?.keyCode ? e?.keyCode : e?.which;
  return !isNaN(code) && parseInt(code) === parseInt(ENTER_KEY_CODE);
}

export function isESCPressed(e) {
  const code = e?.keyCode ? e?.keyCode : e?.which;
  return !isNaN(code) && parseInt(code) === parseInt(ESC_KEY_CODE);
}
