import React from 'react';

const ManSecret = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 203 150"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M168.825 0.00909038C156.055 -0.397773 154.257 12.9762 167.88 15.378L168.287 36.5612L175.506 36.2856V30.8257L171.267 31.0751L171.017 28.0958L173.55 27.2296L173.301 23.9747L170.637 23.8434L170.505 15.9818C182.514 16.7036 183.079 0.468453 168.825 0.00909038ZM168.93 13.055C159.034 11.5063 159.651 2.58152 168.93 2.84401C179.758 3.159 178.826 14.5906 168.93 13.055Z"
        fill="#FFCB56"
      />
      <path
        d="M151.934 31.8363C150.884 32.7025 149.834 33.5687 148.77 34.4349C148.245 34.8681 148.994 35.6162 149.519 35.183C150.569 34.3168 151.619 33.4506 152.682 32.5844C153.22 32.1513 152.459 31.4031 151.934 31.8363Z"
        fill="#F674B4"
      />
      <path
        d="M190.599 0.836017C189.102 2.12223 187.593 3.39532 186.097 4.68154C185.572 5.12778 186.333 5.87588 186.845 5.42965C188.341 4.14343 189.851 2.87033 191.347 1.58412C191.872 1.15101 191.11 0.402904 190.599 0.836017Z"
        fill="#F674B4"
      />
      <path
        d="M202.188 28.3847C198.592 26.4685 194.996 24.5523 191.413 22.6361C190.809 22.3211 190.271 23.2267 190.875 23.5548C194.471 25.471 198.067 27.3872 201.65 29.3034C202.254 29.6184 202.792 28.6997 202.188 28.3847Z"
        fill="#F674B4"
      />
      <path
        d="M102.597 39.4485C102.361 39.4485 102.151 39.291 102.085 39.0548C102.007 38.766 102.177 38.4773 102.453 38.3985C103.49 38.1098 104.264 37.5979 104.54 37.0204C104.697 36.6923 104.684 36.338 104.5 35.9573C104.107 35.1174 102.02 35.1436 101.075 35.3011C100.812 35.3405 100.55 35.183 100.484 34.9205L99.1062 29.8938C99.0274 29.605 99.1981 29.3163 99.4737 29.2375C99.7493 29.1588 100.051 29.3294 100.13 29.605L101.39 34.1855C102.44 34.0805 104.789 34.028 105.472 35.5111C105.773 36.1805 105.787 36.8498 105.498 37.4667C105.091 38.346 104.107 39.0416 102.729 39.4223C102.689 39.4485 102.637 39.4485 102.597 39.4485Z"
        fill="#000200"
      />
      <path
        d="M102.926 27.8593L103.228 28.9618C103.608 30.3793 105.813 29.7755 105.419 28.3581L105.118 27.2556C104.737 25.8381 102.532 26.4419 102.926 27.8593Z"
        fill="#000200"
      />
      <path
        d="M89.6956 31.4685L89.9974 32.571C90.378 33.9885 92.583 33.3847 92.1893 31.9673L91.8874 30.8648C91.5068 29.4605 89.315 30.0642 89.6956 31.4685Z"
        fill="#000200"
      />
      <path
        d="M88.6592 26.0613C87.8323 26.4944 87.0448 26.9669 86.3099 27.5444C85.7455 28.0038 86.5592 28.7913 87.1236 28.345C87.7667 27.8332 88.5148 27.4263 89.2367 27.0326C89.8929 26.6913 89.3154 25.7201 88.6592 26.0613Z"
        fill="#000200"
      />
      <path
        d="M103.883 21.6776C103.227 21.4545 102.518 21.3495 101.875 21.0739C101.205 20.7851 100.641 21.7826 101.31 22.0582C102.045 22.3732 102.833 22.5176 103.594 22.767C104.29 23.0163 104.578 21.9139 103.883 21.6776Z"
        fill="#000200"
      />
      <path
        d="M97.2425 46.3125C96.3893 46.4044 95.195 46.3387 94.8669 45.3675C94.6306 44.6719 93.5282 44.9738 93.7644 45.6694C94.2632 47.1393 95.8119 47.6118 97.2425 47.4412C97.9643 47.3625 97.9643 46.2206 97.2425 46.3125Z"
        fill="#000200"
      />
      <path
        d="M108.53 24.2763C108.031 22.5832 107.506 20.8114 106.981 18.974C106.64 17.7927 106.22 16.7296 105.734 15.7715C106.693 14.4722 106.693 12.5823 105.682 11.086C103.385 7.71302 98.2927 7.26678 94.631 7.80489C92.0454 8.1855 89.578 9.09111 87.1762 10.0886C86.3624 10.4298 85.4306 10.7448 84.5775 11.191C79.4064 12.9235 75.9283 15.2597 73.9728 18.3046C71.9385 21.4676 71.5972 25.3657 72.9228 30.2218L75.3246 39.0022C75.4034 39.2909 75.6921 39.4484 75.9808 39.3697C76.2171 39.304 76.3615 39.094 76.3746 38.8709C77.5164 38.4378 78.3433 38.2803 79.3539 38.0572C79.5901 38.3853 80.4695 38.2016 80.5745 37.8078C80.6007 37.4272 80.2595 34.3035 80.1151 32.453C80.4301 30.7336 81.1257 29.0931 82.1494 27.2687C83.16 25.4707 84.1444 23.4495 83.7769 21.3101C83.7638 21.2576 83.7506 21.2051 83.7375 21.1526C90.2998 20.9164 102.204 18.1996 104.999 16.6771C105.38 17.4646 105.708 18.3309 105.984 19.2758C106.522 21.1133 107.034 22.8851 107.546 24.5782C112.27 40.6165 115.132 50.2894 110.643 54.8305C106.798 58.7154 98.424 60.4741 98.3321 60.4872C98.1615 60.5266 98.004 60.6447 97.9384 60.8153C97.8727 60.9859 97.899 61.1697 98.004 61.3141C100.353 64.5558 103.91 65.5271 104.054 65.5664C104.094 65.5796 104.146 65.5796 104.186 65.5796C104.304 65.5796 104.422 65.5402 104.514 65.4746C104.645 65.3696 104.724 65.2121 104.724 65.0546V59.621C107.086 58.676 109.646 57.3373 111.391 55.5655C116.287 50.5912 113.373 40.6821 108.53 24.2763Z"
        fill="#000200"
      />
      <path
        d="M103.7 64.4245L103.634 68.4669C101.075 75.4754 96.4419 76.2892 93.0033 75.7248C86.7428 74.7011 81.0598 68.7031 80.968 64.687L80.3773 46.26C80.3773 46.0894 80.2855 45.945 80.1542 45.84C80.023 45.7481 79.8392 45.7219 79.6817 45.7612C77.7918 46.365 76.1906 46.3387 75.0225 45.7087C73.9725 45.1313 73.5919 44.2519 73.4607 43.7663C73.2507 42.9788 73.3557 42.2307 73.7625 41.5351C74.6288 40.1045 76.8206 38.9758 80.128 38.2408C80.4167 38.1752 80.6005 37.8996 80.5348 37.6108C80.4692 37.3221 80.1805 37.1384 79.9049 37.204C76.2693 38.0046 73.8938 39.2777 72.8569 40.9839C72.1351 42.1782 72.2269 43.3069 72.4369 44.0419C72.7388 45.1444 73.4738 46.0762 74.5106 46.6275C75.4162 47.1262 76.9649 47.5462 79.3274 46.9425L79.9049 64.687C80.023 69.635 86.5459 75.7248 92.8326 76.7485C95.9825 77.2604 101.705 76.8929 104.658 68.7162C104.684 68.6637 104.684 68.5981 104.684 68.5325V66.3407C104.356 65.7763 104.067 64.9757 103.7 64.4245Z"
        fill="#000200"
      />
      <path
        d="M46.201 149.945C43.6942 149.945 40.8724 149.853 37.7093 149.656C21.7629 148.658 9.29449 142.201 3.48027 131.951C-1.57271 123.039 -1.11335 112.277 4.79274 100.806C19.7417 71.8136 58.5906 65.4087 80.4169 64.1619C80.7057 64.1357 80.955 64.3719 80.9813 64.6606C80.9944 64.9494 80.7713 65.1988 80.4825 65.225C58.8794 66.4456 20.4373 72.7585 5.73771 101.292C0.00224448 112.421 -0.457119 122.842 4.399 131.426C10.0295 141.361 22.196 147.608 37.775 148.593C61.9505 150.115 64.8642 145.613 67.9354 140.862C68.4735 140.022 69.0378 139.156 69.7072 138.329C74.1433 132.883 75.4033 131.806 78.5401 132.935C79.5901 133.316 80.1413 133.237 80.2332 133.106C80.3513 132.935 80.2857 132.016 78.5663 130.021C77.8051 129.129 76.9258 128.696 75.8889 128.683C75.8758 128.683 75.8627 128.683 75.8495 128.683C73.8283 128.683 71.2034 130.349 68.4604 133.394C68.2635 133.618 67.9222 133.631 67.7123 133.434C67.4891 133.237 67.476 132.896 67.6729 132.686C69.7597 130.376 72.8834 127.62 75.8495 127.62C75.8758 127.62 75.8889 127.62 75.902 127.62C77.2408 127.633 78.4088 128.21 79.3669 129.326C81.1256 131.36 81.7031 132.83 81.1125 133.709C80.7975 134.182 80.0232 134.602 78.1857 133.946C75.7314 133.066 74.9439 133.591 70.5341 139.012C69.8909 139.799 69.3528 140.639 68.8279 141.453C66.1111 145.627 63.3155 149.945 46.201 149.945Z"
        fill="#000200"
      />
      <path
        d="M65.0869 131.006C64.9425 131.006 64.7981 130.953 64.6931 130.835C64.4962 130.612 64.5094 130.284 64.7325 130.087C72.7779 122.737 75.0616 121.661 78.264 123.682C80.0489 124.811 81.0333 126.045 81.1908 127.37C81.322 128.473 80.8627 129.575 79.839 130.638C79.629 130.848 79.3008 130.848 79.0908 130.651C78.8809 130.441 78.8809 130.113 79.0777 129.903C79.8783 129.09 80.2327 128.276 80.1408 127.502C80.0227 126.53 79.1827 125.52 77.6996 124.588C75.3503 123.105 73.7885 123.275 65.4412 130.875C65.3493 130.953 65.2181 131.006 65.0869 131.006Z"
        fill="#000200"
      />
      <path
        d="M62.2138 128.328C62.0694 128.328 61.9251 128.276 61.8201 128.158C61.6232 127.935 61.6363 127.606 61.8594 127.41C67.5687 122.199 71.4667 119.299 74.446 119.824C75.9028 120.086 77.084 121.149 78.0552 123.078C78.1865 123.341 78.0815 123.656 77.819 123.787C77.5565 123.918 77.2415 123.813 77.1103 123.551C76.2965 121.937 75.3516 121.057 74.2491 120.86C71.716 120.388 67.8968 123.315 62.5682 128.184C62.4632 128.276 62.3451 128.328 62.2138 128.328Z"
        fill="#000200"
      />
      <path
        d="M73.1604 119.364C69.2886 117.159 66.9524 116.359 60.4032 123.682C59.1039 125.139 58.1195 126.097 57.4895 126.675L57.1877 120.874C56.8333 120.992 56.4921 121.11 56.1377 121.241L56.4789 127.817C56.4921 128.013 56.6102 128.184 56.7808 128.276C56.8464 128.315 56.9252 128.328 57.0039 128.328C57.122 128.328 57.227 128.289 57.3189 128.223C57.3714 128.184 58.7233 127.147 61.1776 124.404C67.4643 117.369 69.2886 118.406 72.6222 120.296C72.8716 120.441 73.1997 120.349 73.3441 120.099C73.5016 119.837 73.4097 119.509 73.1604 119.364Z"
        fill="#000200"
      />
      <path
        d="M55.7167 111.161C55.8874 111.398 56.2155 111.463 56.4517 111.293C63.5521 106.266 61.8853 100.583 61.8197 100.334C61.7541 100.124 61.5572 99.9661 61.3341 99.9661C43.0121 99.3492 32.0136 105.124 29.5856 116.766C29.0475 119.364 29.5856 121.622 31.1999 123.485C35.5048 128.473 46.687 129.287 50.4406 129.287C50.8081 129.287 51.11 129.273 51.32 129.26C52.6849 129.182 53.1837 128.118 54.1812 126.019C54.798 124.706 55.6511 122.921 57.0686 120.559C57.2129 120.309 57.1342 119.981 56.8848 119.837C56.6355 119.679 56.3073 119.771 56.163 120.021C54.7193 122.422 53.8662 124.247 53.2362 125.572C52.3043 127.541 51.9762 128.171 51.2675 128.21C51.1231 128.223 36.9091 128.46 32.0136 122.79C30.6356 121.189 30.1631 119.233 30.6356 116.976C33.1292 105.058 44.6658 101.541 55.6905 101.042C55.6905 101.042 56.1367 110.23 55.8611 110.426C55.5986 110.584 55.5461 110.925 55.7167 111.161Z"
        fill="#000200"
      />
      <path
        d="M43.8259 103.037C43.7997 103.037 43.7603 103.037 43.734 103.024C43.4453 102.972 43.2484 102.696 43.3009 102.407C43.8259 99.3098 45.8077 83.4683 33.2606 75.8298C33.0112 75.6723 32.9325 75.3573 33.0768 75.0948C33.2343 74.8454 33.5493 74.7667 33.8118 74.9111C43.9965 81.1059 45.9259 93.2593 44.3509 102.578C44.2984 102.853 44.0753 103.037 43.8259 103.037Z"
        fill="#000200"
      />
      <path
        d="M170.531 49.1082C172.198 47.8088 174.258 46.1814 173.878 43.8189C173.838 43.5696 173.707 42.7952 173.025 42.5327C171.87 42.0996 169.927 43.3464 163.575 48.5963C155.018 55.6836 150.188 72.5356 149.335 85.1747C149.19 87.3796 149.072 89.4139 148.954 91.2645C148.363 101.252 148.061 106.253 145.2 106.515C142.444 106.791 138.966 99.2443 135.619 91.9732C134.753 90.0702 133.861 88.1277 132.942 86.2246C127.784 75.5281 120.736 65.4877 104.199 64.5034C104.055 64.4902 103.91 64.5427 103.805 64.6477C103.766 64.6871 103.726 64.7396 103.7 64.779C104.041 65.2908 104.37 65.8027 104.698 66.3277V65.6058C120.29 66.7477 127.036 76.4074 131.984 86.684C132.903 88.5871 133.795 90.5295 134.661 92.4195C138.546 100.872 141.63 107.578 145.056 107.578C145.135 107.578 145.213 107.578 145.292 107.565C149.072 107.237 149.361 102.25 150.017 91.3301C150.122 89.4795 150.253 87.4584 150.398 85.2534C151.238 72.8244 155.923 56.3136 164.244 49.4231C166.869 47.2445 171.751 43.1889 172.644 43.5302C172.696 43.5433 172.775 43.6746 172.828 43.9895C173.103 45.7351 171.463 47.0213 169.875 48.2682C168.588 49.2788 167.368 50.2369 167.276 51.4443C167.21 52.3106 167.722 53.0324 168.273 53.7937C169.481 55.4736 170.977 57.5473 167.538 62.679C167.381 62.9284 167.447 63.2565 167.683 63.414C167.932 63.5846 168.26 63.5059 168.418 63.2696C172.263 57.5342 170.373 54.9092 169.127 53.1768C168.654 52.5337 168.287 52.0087 168.326 51.5362C168.392 50.7881 169.428 49.9744 170.531 49.1082Z"
        fill="#000200"
      />
      <path
        d="M168.273 53.4131C168.024 53.4131 167.801 53.2425 167.748 52.98C167.696 52.6912 167.879 52.4156 168.168 52.3631C171.41 51.72 174.376 50.8931 176.988 50.145C181.91 48.7538 185.48 47.7564 187.225 49.1476C188.013 49.7776 188.367 50.8275 188.288 52.35C188.275 52.6387 188 52.875 187.724 52.8487C187.435 52.8356 187.212 52.5862 187.225 52.2844C187.291 51.1294 187.068 50.3682 186.569 49.9613C185.243 48.8982 181.726 49.8957 177.29 51.1556C174.652 51.9037 171.659 52.7437 168.378 53.3868C168.352 53.4131 168.313 53.4131 168.273 53.4131Z"
        fill="#000200"
      />
      <path
        d="M175.086 56.7861C174.876 56.7861 174.679 56.6548 174.587 56.4448C174.482 56.1692 174.613 55.8674 174.889 55.7624C184.837 51.8906 188.84 51.0112 190.179 52.4287C190.783 53.0718 190.756 54.0562 190.113 55.4605C189.995 55.723 189.667 55.8411 189.405 55.723C189.142 55.6049 189.024 55.2899 189.142 55.0143C189.562 54.0955 189.654 53.4262 189.391 53.1506C188.066 51.7593 179.102 55.2505 175.256 56.7467C175.217 56.773 175.151 56.7861 175.086 56.7861Z"
        fill="#000200"
      />
      <path
        d="M177.264 59.9885C177.04 59.9885 176.83 59.8441 176.752 59.6078C176.673 59.3322 176.83 59.0304 177.106 58.9516C180.059 58.0591 182.474 57.2323 184.417 56.5629C188.931 55.0011 190.992 54.2792 192.029 55.6573C192.475 56.2611 192.659 56.8779 192.554 57.4817C192.396 58.4004 191.648 58.9122 191.569 58.9647C191.32 59.1222 190.992 59.0566 190.834 58.8072C190.677 58.5579 190.743 58.2298 190.992 58.0723C190.992 58.0723 191.438 57.7704 191.517 57.2848C191.569 56.9829 191.451 56.6417 191.189 56.2873C190.624 55.5392 188.42 56.3004 184.771 57.5604C182.815 58.2298 180.4 59.0697 177.421 59.9622C177.369 59.9885 177.316 59.9885 177.264 59.9885Z"
        fill="#000200"
      />
      <path
        d="M147.471 138.881C146.028 138.881 144.545 138.762 143.062 138.526C133.507 137.017 125.146 130.888 120.697 122.147C120.566 121.884 120.671 121.569 120.933 121.438C121.196 121.307 121.511 121.412 121.642 121.674C125.947 130.113 134.019 136.033 143.232 137.489C150.897 138.697 158.312 136.597 163.063 131.846C173.629 121.28 172.369 91.9731 171.896 80.9353C171.555 72.8243 175.099 70.6719 178.866 68.3751C179.351 68.0863 179.837 67.7844 180.335 67.4694C181.884 66.472 184.05 65.5401 186.137 64.6214C188.985 63.3877 192.213 61.9833 192.594 60.8415C192.646 60.684 192.633 60.5528 192.541 60.3821C191.806 59.1091 191.583 59.1353 187.646 60.7365C185.808 61.4846 183.289 62.5083 179.692 63.7289C179.417 63.8208 179.115 63.6764 179.023 63.4008C178.931 63.1252 179.076 62.8233 179.351 62.7315C182.921 61.524 185.415 60.5003 187.239 59.7522C191.071 58.1903 192.226 57.7178 193.447 59.8572C193.683 60.2771 193.736 60.7234 193.591 61.1696C193.066 62.7577 190.048 64.0702 186.557 65.5795C184.509 66.472 182.383 67.3907 180.9 68.3357C180.401 68.6507 179.902 68.9657 179.404 69.2544C175.611 71.5643 172.631 73.3755 172.946 80.8566C173.419 92.0125 174.692 121.661 163.798 132.555C159.756 136.663 153.85 138.881 147.471 138.881Z"
        fill="#000200"
      />
      <path
        d="M124.607 126.097C124.358 126.097 124.148 125.927 124.095 125.677C122.665 119.049 126.234 113.576 128.492 110.899C132.219 106.489 137.522 103.562 141.407 103.707C141.695 103.72 141.931 103.969 141.918 104.258C141.905 104.547 141.656 104.783 141.367 104.77C137.784 104.573 132.836 107.421 129.306 111.594C127.179 114.114 123.793 119.272 125.132 125.467C125.198 125.756 125.014 126.032 124.725 126.097C124.673 126.097 124.633 126.097 124.607 126.097Z"
        fill="#000200"
      />
      <path
        d="M130.592 132.227C127.035 128.539 125.749 123.118 124.122 116.241C122.993 111.49 121.72 106.109 119.423 100.321C119.331 100.084 119.095 99.9531 118.845 99.9925C118.596 100.032 118.412 100.242 118.399 100.491L117.927 111.253C117.927 111.319 117.927 111.372 117.953 111.424C117.953 111.424 118.111 111.936 118.386 112.763C118.439 112.933 118.609 113.078 118.793 113.117C118.964 113.157 119.134 113.196 119.305 113.222L119.528 113.248C119.79 113.248 120.079 113.17 120.355 113.091C120.434 113.065 120.539 113.038 120.644 113.012C120.827 115.427 120.985 119.758 120.867 121.911C120.867 122.003 120.88 122.094 120.919 122.173C122.835 125.992 126.484 131.032 129.949 133.027C130.028 133.08 130.12 133.093 130.211 133.093C130.369 133.093 130.526 133.027 130.631 132.883C130.789 132.712 130.776 132.41 130.592 132.227Z"
        fill="#000200"
      />
      <path
        d="M56.7802 123.853C56.5702 123.853 56.3602 123.722 56.2814 123.512C56.1764 123.236 56.3208 122.934 56.5964 122.829C62.2794 120.729 66.5186 113.603 67.083 112.605C67.083 109.993 66.9518 72.3125 63.7887 66.6821C63.6443 66.4196 63.7362 66.1046 63.9856 65.9602C64.235 65.8158 64.5631 65.9077 64.7074 66.1571C68.1067 72.2075 68.133 111.083 68.133 112.736C68.133 112.828 68.1067 112.907 68.0673 112.986C67.8836 113.327 63.395 121.438 56.9508 123.814C56.8983 123.84 56.8458 123.853 56.7802 123.853Z"
        fill="#000200"
      />
      <path
        d="M113.374 113.261H72.8975C72.7531 113.261 72.6219 113.209 72.5169 113.104C72.4119 112.999 72.3594 112.868 72.3594 112.723C72.5432 98.2993 72.1625 69.9501 69.4457 65.9209C69.2882 65.6715 69.3407 65.3434 69.5901 65.1859C69.8264 65.0153 70.1676 65.0809 70.3251 65.3302C73.7113 70.3701 73.475 107.08 73.4225 112.198H112.862C113.02 107.605 114.096 73.6381 111.615 66.5771C111.523 66.3015 111.668 65.9996 111.943 65.9077C112.219 65.8159 112.521 65.9602 112.626 66.2358C115.329 73.9663 113.965 111.175 113.912 112.75C113.899 113.038 113.663 113.261 113.374 113.261Z"
        fill="#000200"
      />
      <path
        d="M121.156 134.405C120.868 134.405 120.631 134.182 120.631 133.893L120.001 113.261H118.374C118.23 113.261 118.085 113.196 117.993 113.091C117.901 112.986 117.836 112.841 117.849 112.697C117.875 112.33 119.765 75.5411 116.195 68.4276C116.064 68.1651 116.169 67.8501 116.431 67.7189C116.694 67.5876 117.009 67.6926 117.14 67.9551C120.618 74.9243 119.161 107.342 118.925 112.198H120.513C120.802 112.198 121.038 112.421 121.038 112.71L121.681 133.854C121.694 134.143 121.458 134.392 121.169 134.405H121.156Z"
        fill="#000200"
      />
      <path
        d="M72.805 113.261H67.5289C67.2401 113.261 67.0039 113.025 67.0039 112.736C67.0039 112.448 67.2401 112.211 67.5289 112.211H72.805C73.0937 112.211 73.33 112.448 73.33 112.736C73.3431 113.025 73.1069 113.261 72.805 113.261Z"
        fill="#000200"
      />
      <path
        d="M118.583 113.261H113.307C113.018 113.261 112.782 113.025 112.782 112.736C112.782 112.448 113.018 112.211 113.307 112.211H118.583C118.872 112.211 119.108 112.448 119.108 112.736C119.108 113.025 118.872 113.261 118.583 113.261Z"
        fill="#000200"
      />
      <path
        d="M83.5148 136.859C83.423 136.859 83.3442 136.833 83.2655 136.794C83.003 136.649 82.9111 136.334 83.0555 136.072L88.2528 126.635C88.3972 126.373 88.7122 126.281 88.9747 126.425C89.2372 126.57 89.3291 126.885 89.1847 127.147L83.9873 136.584C83.8823 136.768 83.6986 136.859 83.5148 136.859Z"
        fill="#000200"
      />
      <path
        d="M57.187 120.887L56.1501 100.491C56.137 100.203 55.8745 99.9401 55.5989 99.9926C55.3102 100.006 55.087 100.255 55.1002 100.544L56.1633 121.255C56.4914 121.123 56.8326 120.992 57.187 120.887Z"
        fill="#000200"
      />
    </svg>
  );
};

export { ManSecret };
