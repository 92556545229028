import React from 'react';

const OnboardWindow = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 12"
      fill="none"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M6.63712 2.24296V5.62852H11.1137V1.50116L6.63712 2.24296ZM6.63712 9.76877L11.1137 10.4977V6.37266H6.63712V9.76877ZM1.90625 5.62735H5.69024V2.36601L1.90625 2.99296V5.62735ZM1.90625 9.03048L5.69024 9.64689V6.37266H1.90625V9.03048Z"
        fill={color ? color : '#666666'}
      />
    </svg>
  );
};

export { OnboardWindow };
