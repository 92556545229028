import {
  HYPERTEST_JOB_DETAIL_ACTIONS_REQUEST,
  HYPERTEST_JOB_DETAIL_ACTIONS_RESPONSE,
  HYPERTEST_JOB_DETAIL_ACTIONS_SILENT_REQUEST,
  HYPERTEST_JOB_DETAIL_RESET,
  HYPERTEST_SET_ARTIFECT_MODAL_IS_OPEN_STATUS,
  HYPERTEST_JOB_REPORT_REQUEST,
  HYPERTEST_JOB_REPORT_RESPONSE,
  HYPERTEST_SET_DOWNLOAD_REPORT_BUTTON_STATUS,
  HYPERTEST_SET_JOB_ABORT_STATUS,
  HYPERTEST_SET_QUICK_START_STATUS,
  HYPERTEST_SET_HELP_MENU_STATUS,
  HYPERTEST_SET_SUPPORT_ALERT_STATUS,
} from '../constants/hypertestJobDetail';

const initialState = {
  hypertestJobDetail: {
    data: null,
    loading: false,
    error: false,
  },
  visual: {
    artifectModal: false,
    downlaodReportBtnStatus: '',
    quickStart: false,
    helpMenu: false,
    showSupportAlert: true,
  },
  report: {
    data: null,
    loading: false,
    error: false,
  },
  reportURL: '',
  reportURLLoading: false,
  reportURLError: false,
};

function hypertestJobDetailReducer(state = initialState, actions) {
  const { type, payload, error } = actions;
  switch (type) {
    case 'HYPERTEST_JOB_REPORT_LINK_REQUEST':
      return {
        ...state,
        reportURL: '',
        reportURLLoading: true,
        reportURLError: false,
      };
    case 'HYPERTEST_JOB_REPORT_LINK_RESPONSE': {
      let url = '',
        err = false;
      console.log(payload);
      if (
        payload?.data &&
        !payload.data.includes('no report found') &&
        payload?.status === 'success'
      ) {
        url = payload?.data;
      } else {
        err = true;
      }
      return {
        ...state,
        reportURL: url,
        reportURLLoading: false,
        reportURLError: err,
      };
    }

    case HYPERTEST_JOB_DETAIL_ACTIONS_REQUEST:
      return {
        ...state,
        hypertestJobDetail: {
          ...state.hypertestJobDetail,
          error: false,
          loading: true,
        },
      };
    case HYPERTEST_JOB_DETAIL_ACTIONS_RESPONSE: {
      return {
        ...state,
        hypertestJobDetail: {
          ...state.hypertestJobDetail,
          data: payload,
          loading: false,
          error: error === undefined ? false : error,
        },
      };
    }
    case HYPERTEST_SET_JOB_ABORT_STATUS: {
      return {
        ...state,
        hypertestJobDetail: {
          ...state.hypertestJobDetail,
          data: {
            ...state.hypertestJobDetail.data,
            data: {
              ...(state.hypertestJobDetail?.data?.data || {}),
              status: 'aborted',
              remark: 'job has been aborted by user',
            },
          },
        },
      };
    }
    case HYPERTEST_JOB_DETAIL_ACTIONS_SILENT_REQUEST:
      return {
        ...state,
        hypertestJobDetail: {
          ...state.hypertestJobDetail,
          loading: false,
        },
      };
    case HYPERTEST_JOB_DETAIL_RESET:
      return initialState;
    case HYPERTEST_SET_ARTIFECT_MODAL_IS_OPEN_STATUS:
      return {
        ...state,
        visual: {
          artifectModal: payload,
        },
      };
    case HYPERTEST_JOB_REPORT_REQUEST:
      return {
        ...state,
        report: {
          ...state.report,
          loading: true,
        },
      };
    case HYPERTEST_JOB_REPORT_RESPONSE:
      return {
        ...state,
        report: {
          ...state.report,
          data: payload,
          loading: false,
          error: error === undefined ? false : error,
        },
      };
    case HYPERTEST_SET_DOWNLOAD_REPORT_BUTTON_STATUS:
      return {
        ...state,
        visual: {
          ...state.visual,
          downlaodReportBtnStatus: payload,
        },
      };
    case HYPERTEST_SET_QUICK_START_STATUS:
      return {
        ...state,
        visual: {
          ...state.visual,
          quickStart: payload,
        },
      };
    case HYPERTEST_SET_SUPPORT_ALERT_STATUS:
      return {
        ...state,
        visual: {
          ...state.visual,
          showSupportAlert: payload,
        },
      };
    case HYPERTEST_SET_HELP_MENU_STATUS:
      return {
        ...state,
        visual: {
          ...state.visual,
          helpMenu: payload,
        },
      };
    default:
      return state;
  }
}

export default hypertestJobDetailReducer;
