import React from 'react';

const LogAvailable = ({ width = 18, height = 18, className = '' }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <circle
        cx="8"
        cy="8.03421"
        r="7.4966"
        transform="rotate(-0.024375 8 8.03421)"
        fill="white"
        stroke="#666666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8061 8.07105C13.8061 11.2876 11.2151 13.9072 8.00396 13.9072C4.79286 13.9072 2.20177 11.2876 2.20176 8.07101C2.20175 4.85444 4.79283 2.23483 8.00392 2.23484C11.215 2.23485 13.8061 4.85447 13.8061 8.07105ZM8.00395 12.9072C10.6561 12.9072 12.8061 10.742 12.8061 8.07104C12.8061 5.40009 10.6561 3.23484 8.00393 3.23484C5.35176 3.23483 3.20175 5.40006 3.20176 8.07101C3.20177 10.742 5.35178 12.9072 8.00395 12.9072Z"
        fill="#5F6877"
      />
      <path
        d="M7.20016 6.10082L7.67002 9.07589C7.72036 9.36536 8.10632 9.36536 8.15666 9.07589L8.60974 6.10082C8.69364 4.94296 7.11626 4.94296 7.20016 6.10082"
        fill="#666666"
      />
      <path
        d="M7.90172 10.9573C8.29096 10.9573 8.6065 10.6549 8.6065 10.2819C8.6065 9.90884 8.29096 9.60645 7.90172 9.60645C7.51247 9.60645 7.19693 9.90884 7.19693 10.2819C7.19693 10.6549 7.51247 10.9573 7.90172 10.9573Z"
        fill="#666666"
      />
    </svg>
  );
};

export { LogAvailable };
