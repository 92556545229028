import uniq from 'lodash/uniq';
import * as ACTION_TYPE from '../constants/hypertestTasksList';

import { REAL_DEVICE_TASK } from 'constants/job-detail';

const initialState = {
  listData: {
    data: [],
    loading: false,
    error: false,
    metadata: null,
  },
};

function hypertestTasksListReducer(state = initialState, actions) {
  const { type, payload, error, extra } = actions;
  switch (type) {
    case ACTION_TYPE.HYPERTEST_TASKS_LIST_ACTIONS_REQUEST: {
      const { clearJobList } = extra;
      return {
        ...state,
        listData: {
          ...(clearJobList
            ? { data: [], error: false, metadata: null }
            : state.listData),
          loading: true,
        },
        isUnmounted: false,
      };
    }
    case ACTION_TYPE.HYPERTEST_UPDATE_TASK_LIST_FOR_ABORT_ACTION: {
      const data = state.listData.data.map((d) => {
        const newData = {};

        if (d.status === 'queued') {
          newData.status = 'cancelled';
        } else if (d.status === 'running' || d.status === 'initiated') {
          newData.status = 'aborted';
        } else {
          newData.status = d.status;
        }

        return { ...d, ...newData };
      });
      return {
        ...state,
        listData: {
          ...state.listData,
          data,
        },
      };
    }
    case ACTION_TYPE.HYPERTEST_TASKS_LIST_ACTIONS_RESPONSE: {
      const { clearJobList } = extra;

      const isExpired = state.isUnmounted;
      if (isExpired) {
        return {
          ...state,
          isUnmounted: false,
        };
      }

      function filterRealDevicesTask({ type = '' }) {
        return type !== REAL_DEVICE_TASK;
      }

      const newListData = [
        ...(clearJobList ? [] : state.listData.data),
        ...(payload?.data?.Tasks || []),
      ].filter(filterRealDevicesTask);

      uniq(newListData, 'id');

      return {
        ...state,
        listData: {
          ...state.listData,
          data: [...newListData],
          loading: false,
          error: error === undefined ? false : error,
          metadata: payload?.metadata,
        },
      };
    }
    case ACTION_TYPE.HYPERTEST_TASK_DETAIL_ACTIONS_REQUEST: {
      return {
        ...state,
        eventData: null,
      };
    }
    case ACTION_TYPE.HYPERTEST_TASK_DETAIL_ACTIONS_RESPONE: {
      const { activeStatus } = extra;
      const task = payload?.data;

      if (!task) return state;

      // meta is for RealDevices
      // Right now we aren't showing meta tasks in the UI
      if (task.type === REAL_DEVICE_TASK) return state;

      const existingTask = state.listData.data.find((t) => t.id === task.id);

      let newListData = state.listData.data;

      if (existingTask) {
        if (!activeStatus) {
          // update if All Statuses are shown
          newListData = newListData.map((t) => (t.id === task.id ? task : t));
        } else if (task.status !== activeStatus) {
          // remove if filter applied and status is not same
          newListData = newListData.filter((t) => t.id !== task.id);
        } else {
          // update if filter applied and task status is same
          newListData = newListData.map((t) => (t.id === task.id ? task : t));
        }
      } else if (activeStatus === task.status) {
        // add if filter is applied and fetched task belongs to same list
        newListData = [...newListData, task].sort(
          (taskA, taskB) => taskA.group_number - taskB.group_number
        );
      }

      uniq(newListData, 'id');

      return {
        ...state,
        listData: {
          ...state.listData,
          data: [...newListData],
        },
      };
    }
    case ACTION_TYPE.HYPERTEST_TASKS_LIST_UPDATE_SSE_EVENT: {
      const { eventData } = payload;

      return {
        ...state,
        eventData,
      };
    }
    case ACTION_TYPE.HYPERTEST_TASKS_LIST_RESET:
      return { initialState, isUnmounted: true };
    default:
      return state;
  }
}

export default hypertestTasksListReducer;
