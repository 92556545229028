import React from 'react';

const PJAborted = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M13 8H3"
        stroke="white"
        strokeWidth="1.47059"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PJAborted;
