const Question = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      {...rest}
    >
      <g id="Group 7901">
        <path
          id="Vector"
          d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
          stroke="#666666"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_2"
          d="M8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z"
          fill="#666666"
        />
        <path
          id="Vector_3"
          d="M8 9V8.375C8.39556 8.375 8.78224 8.24671 9.11114 8.00634C9.44004 7.76597 9.69638 7.42433 9.84776 7.02462C9.99913 6.62491 10.0387 6.18507 9.96157 5.76074C9.8844 5.33641 9.69392 4.94663 9.41421 4.64071C9.13451 4.33478 8.77814 4.12644 8.39018 4.04203C8.00222 3.95763 7.60009 4.00095 7.23463 4.16651C6.86918 4.33208 6.55682 4.61246 6.33706 4.97219C6.1173 5.33192 6 5.75485 6 6.1875"
          stroke="#666666"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default Question;
