import React from 'react';

const Success = ({ className, height = '9', width = '9' }) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 13 13"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.22391 12.3152 9.87721 11.0962 11.0962C9.87721 12.3152 8.22391 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5ZM6.12907 9.282L9.5723 4.97755C9.73746 4.7711 9.70398 4.46985 9.49753 4.30469C9.33062 4.17116 9.08708 4.1982 8.95353 4.36509L6.00427 8.05047L4.07682 6.44451C3.893 6.29135 3.61984 6.31619 3.46667 6.5C3.3135 6.6838 3.33832 6.95697 3.52212 7.11016L6.12907 9.28287V9.282Z"
        fill="#45C286"
      />
    </svg>
  );
};

export { Success };
