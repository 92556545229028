import React from 'react';

const ExpandArrow = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M1.62744 0.750007C1.73949 0.749627 1.85019 0.774356 1.95143 0.822377C2.05266 0.870398 2.14185 0.940492 2.21244 1.02751L5.83494 5.52751C5.94525 5.66171 6.00555 5.83004 6.00555 6.00376C6.00555 6.17748 5.94525 6.34581 5.83494 6.48001L2.08494 10.98C1.95763 11.1332 1.7747 11.2295 1.57638 11.2478C1.37806 11.2661 1.1806 11.2048 1.02744 11.0775C0.874276 10.9502 0.777958 10.7673 0.759674 10.5689C0.741389 10.3706 0.802635 10.1732 0.929939 10.02L4.28244 6.00001L1.04244 1.98001C0.950727 1.86992 0.89247 1.73586 0.87456 1.5937C0.85665 1.45154 0.879838 1.30722 0.94138 1.17783C1.00292 1.04843 1.10024 0.939372 1.22182 0.863555C1.34341 0.787736 1.48416 0.748334 1.62744 0.750007Z"
        fill="#999999"
      />
    </svg>
  );
};

export { ExpandArrow };
