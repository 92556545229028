const { useSelector } = require('react-redux');

const useFeatureFlags = () => {
  const featureFlags = useSelector((state) => state?.featureFlag);
    const userId = useSelector((state) => state.userInfo?.userInfo?.data?.id);

    const featureFlagsFromStorage = localStorage?.getItem(
      `featureFlags${userId}`
    );


  if (featureFlags?.isLoading) {
    return featureFlagsFromStorage ? JSON.parse(featureFlagsFromStorage) : {};
  } else {
    return featureFlags;
  }
};

export default useFeatureFlags;
