const UnderReview = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <rect width="16" height="16" rx="7.96596" fill="#EAF5FA" />
      <path
        d="M9.99544 6.31323C10.0838 6.5475 10.1322 6.80137 10.1322 7.06657C10.1322 7.5115 9.9961 7.9247 9.76313 8.26657C9.61225 8.48817 9.42073 8.67977 9.19922 8.8307C8.85748 9.06377 8.44444 9.1999 7.99969 9.1999C6.82188 9.1999 5.86719 8.24483 5.86719 7.06657C5.86719 6.80163 5.91557 6.5479 6.00393 6.31363"
        stroke="#50BEFF"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9999 8.00007C11.5533 7.25647 10.9058 6.64753 10.1323 6.24887C9.8785 6.11793 9.61114 6.0098 9.33284 5.92687C8.91048 5.801 8.46319 5.7334 7.9999 5.7334C6.30123 5.7334 4.81541 6.6422 4 8.00007"
        stroke="#50BEFF"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 8C4.81541 9.35787 6.30137 10.2667 8.00003 10.2667C8.46319 10.2667 8.91061 10.1991 9.33284 10.0732C9.66045 9.9756 9.97273 9.84307 10.2658 9.67973C10.6862 9.44533 11.0666 9.14787 11.3942 8.8C11.6233 8.55653 11.8268 8.2884 11.9999 8"
        stroke="#50BEFF"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66562 12H4.53312C4.23871 12 4 11.7612 4 11.4666V10.1333"
        stroke="#50BEFF"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0016 10.1333V11.4666C12.0016 11.7612 11.7629 12 11.4684 12H9.33594"
        stroke="#50BEFF"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33594 4H11.4684C11.7629 4 12.0016 4.2388 12.0016 4.53333V5.86667"
        stroke="#50BEFF"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 5.86667V4.53333C4 4.2388 4.23871 4 4.53312 4H6.66562"
        stroke="#50BEFF"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.15442 7.51999C6.96316 7.63039 6.71872 7.56492 6.60836 7.37359C6.49787 7.18225 6.56345 6.93772 6.75471 6.82732C6.94596 6.71692 7.1904 6.78239 7.30089 6.97372C7.41125 7.16492 7.34567 7.40959 7.15442 7.51999Z"
        fill="#50BEFF"
      />
    </svg>
  );
};

export default UnderReview;
