import { CALL_API } from '../middleware/api';
import { getCookieData } from '../../utils/cookieHandler';
import {
  USER_INFO_LOADING,
  USER_INFO_SUCCESS,
  USER_INFO_SET_PLAN_EXPIRED_MODAL,
  USER_INFO_SET_LIMIT_EXHAUSTED_MODAL,
  USER_INFO_SET_LIMIT_EXHAUSTED_THRESHOLD_MODAL,
  USER_INFO_SET_TRIAL_FEATURE_MODAL,
  USER_CONSUMED_TIME_LOADING,
  USER_CONSUMED_TIME_SUCCESS,
  USER_INFO_SET_PLAN_INFO_VALUE,
  USER_INFO_SET_COMMON_UI_VISUAL,
  USER_ORG_PREFERENCE_LOADING,
  USER_ORG_PREFERENCE_SUCCESS,
  HYPERTEST_ONBOARDING_ENABLE_HYPEREXECUTE_PLAN_LOADING,
  HYPERTEST_ONBOARDING_ENABLE_HYPEREXECUTE_PLAN_SUCCESS,
  USER_TEAM_INFO_LOADING,
  USER_TEAM_INFO_SUCCESS,
  ENABLE_CONGRATULATION_MODAL,
} from '../constants/userInfo';
const mockApiEndpoint =
  process.env.REACT_APP_USER_INFO_URL ||
  (window._env_ && window._env_.REACT_APP_USER_INFO_URL);
const mockApiEndpointLumsAuth =
  process.env.REACT_APP_LUMS_AUTH_URL ||
  (window._env_ && window._env_.REACT_APP_LUMS_AUTH_URL);
const mockApiEndpointLumsBilling = process.env.REACT_APP_LUMS_BILLING_API_URL;
const HYPERTEST_SENTINEL_API_ENDPOINT =
  process.env.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT ||
  window?._env_?.REACT_APP_HYPERTEST_SENTINEL_API_ENDPOINT;

let cookieName = process.env.REACT_APP_COOKIE_NAME;
let access_token = getCookieData(cookieName);
const header = {
  Authorization: `Bearer ${access_token}`,
  Accept: 'application/json',
};
export const fetchUserInfo = () => {
  return {
    [CALL_API]: {
      types: [USER_INFO_LOADING, USER_INFO_SUCCESS],
      endpoint: `${mockApiEndpoint}api/user`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      headers: header,
    },
  };
};
export const fetchUserTeamMember = (auth) => {
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    HYPERTEST_SENTINEL_API_ENDPOINT;
  return {
    [CALL_API]: {
      types: [USER_TEAM_INFO_LOADING, USER_TEAM_INFO_SUCCESS],
      endpoint: `${sentinelUrl}/v1.0/org/members`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      headers: header,
      auth: auth,
    },
  };
};
export const fetchOrgPreferences = ({ orgId, auth }) => {
  return {
    [CALL_API]: {
      types: [USER_ORG_PREFERENCE_LOADING, USER_ORG_PREFERENCE_SUCCESS],
      endpoint: `${mockApiEndpointLumsAuth}/api/org_preferences/${orgId}`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};
export const fetchConsumedMinutes = ({ orgId, auth, sentinel_url }) => {
  // we are not getting SENTINEL_HOST from window because at this time it is currently not updated inside window, so using sentinel_url directly from reducer--
  let sentinelUrl =
    (window.globalConfig && window.globalConfig.SENTINEL_HOST) ||
    sentinel_url ||
    HYPERTEST_SENTINEL_API_ENDPOINT;
  return {
    [CALL_API]: {
      types: [USER_CONSUMED_TIME_LOADING, USER_CONSUMED_TIME_SUCCESS],
      endpoint: `${sentinelUrl}/v1.0/orgId/${orgId}/total-execution-time`,
      method: 'GET',
      appendBaseUrl: false,
      type: 'json',
      auth: auth,
    },
  };
};

export const setPlanExpiredModal = (isOpened) => {
  return {
    type: USER_INFO_SET_PLAN_EXPIRED_MODAL,
    payload: isOpened,
  };
};

export const enableCongratulationModal = (isEnable) => {
  return {
    type: ENABLE_CONGRATULATION_MODAL,
    payload: isEnable,
  };
};

export const setLimitExhoustedModal = (isOpened) => {
  return {
    type: USER_INFO_SET_LIMIT_EXHAUSTED_MODAL,
    payload: isOpened,
  };
};

export const setLimitExhoustedThresholdModal = (isOpened) => {
  return {
    type: USER_INFO_SET_LIMIT_EXHAUSTED_THRESHOLD_MODAL,
    payload: isOpened,
  };
};

export const setTrialFeatureModal = (isOpened) => {
  return {
    type: USER_INFO_SET_TRIAL_FEATURE_MODAL,
    payload: isOpened,
  };
};

export const updatePlanInfoValue = (value) => {
  return {
    type: USER_INFO_SET_PLAN_INFO_VALUE,
    payload: value,
  };
};

export const updateCommonUiVisual = (value) => {
  return {
    type: USER_INFO_SET_COMMON_UI_VISUAL,
    payload: value,
  };
};

export const enableHyperexecutePlan = ({ data }) => {
  return {
    [CALL_API]: {
      types: [
        HYPERTEST_ONBOARDING_ENABLE_HYPEREXECUTE_PLAN_LOADING,
        HYPERTEST_ONBOARDING_ENABLE_HYPEREXECUTE_PLAN_SUCCESS,
      ],
      endpoint: `${mockApiEndpointLumsBilling}/api/v1/organization`,
      method: 'PUT',
      appendBaseUrl: false,
      type: 'json',
      headers: header,
      body: data,
    },
  };
};
