import React from 'react';

const DocumentationIcon = ({ width = 12, height = 16, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      role="img"
      fill="none"
      aria-hidden="true"
      viewBox="0 0 12 16"
      className={className}
      {...rest}
    >
      <path
        d="M1.45925e-06 2.5C1.45925e-06 1.83696 0.263394 1.20107 0.732234 0.732233C1.20108 0.263392 1.83696 0 2.5 0L11.25 0C11.4489 0 11.6397 0.0790176 11.7803 0.21967C11.921 0.360322 12 0.551088 12 0.75V13.25C12 13.4489 11.921 13.6397 11.7803 13.7803C11.6397 13.921 11.4489 14 11.25 14H8.75C8.55109 14 8.36032 13.921 8.21967 13.7803C8.07902 13.6397 8 13.4489 8 13.25C8 13.0511 8.07902 12.8603 8.21967 12.7197C8.36032 12.579 8.55109 12.5 8.75 12.5H10.5V10.5H2.5C2.30308 10.5 2.11056 10.5582 1.94657 10.6672C1.78257 10.7762 1.65442 10.9312 1.57816 11.1128C1.50191 11.2943 1.48096 11.4943 1.51793 11.6878C1.5549 11.8812 1.64816 12.0594 1.786 12.2C1.85495 12.2704 1.90935 12.3537 1.94611 12.4451C1.98287 12.5365 2.00126 12.6343 2.00024 12.7328C1.99922 12.8313 1.9788 12.9287 1.94016 13.0193C1.90151 13.1099 1.84539 13.1921 1.775 13.261C1.70461 13.3299 1.62133 13.3843 1.52992 13.4211C1.43851 13.4579 1.34075 13.4763 1.24222 13.4752C1.1437 13.4742 1.04634 13.4538 0.955711 13.4152C0.865078 13.3765 0.782945 13.3204 0.714001 13.25C0.255713 12.7829 -0.000705812 12.1544 1.45925e-06 11.5V2.5ZM10.5 1.5H2.5C2.23478 1.5 1.98043 1.60536 1.79289 1.79289C1.60536 1.98043 1.5 2.23478 1.5 2.5V9.208C1.81534 9.0702 2.15586 8.99937 2.5 9H10.5V1.5ZM3 12.25C3 12.1837 3.02634 12.1201 3.07322 12.0732C3.12011 12.0263 3.1837 12 3.25 12H6.75C6.81631 12 6.87989 12.0263 6.92678 12.0732C6.97366 12.1201 7 12.1837 7 12.25V15.5C7 15.5464 6.98707 15.5919 6.96266 15.6314C6.93826 15.6709 6.90333 15.7028 6.8618 15.7236C6.82028 15.7444 6.77379 15.7532 6.72755 15.749C6.68131 15.7448 6.63714 15.7279 6.6 15.7L5.15 14.613C5.10679 14.5804 5.05413 14.5627 5 14.5627C4.94587 14.5627 4.89321 14.5804 4.85 14.613L3.4 15.7C3.36286 15.7279 3.31869 15.7448 3.27245 15.749C3.22621 15.7532 3.17972 15.7444 3.1382 15.7236C3.09667 15.7028 3.06175 15.6709 3.03734 15.6314C3.01293 15.5919 3 15.5464 3 15.5V12.25Z"
        fill="#666666"
      />
    </svg>
  );
};

export { DocumentationIcon };
