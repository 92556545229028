import React from 'react';

const Archived = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="presentation"
      aria-hidden="true"
      {...rest}
    >
      <path
        d="M14.0002 2H2.00016C1.63197 2 1.3335 2.29848 1.3335 2.66667V4.66667C1.3335 5.03486 1.63197 5.33333 2.00016 5.33333H14.0002C14.3684 5.33333 14.6668 5.03486 14.6668 4.66667V2.66667C14.6668 2.29848 14.3684 2 14.0002 2Z"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.6665 5.3335V12.6668C2.6665 13.0205 2.80698 13.3596 3.05703 13.6096C3.30708 13.8597 3.64622 14.0002 3.99984 14.0002H11.9998C12.3535 14.0002 12.6926 13.8597 12.9426 13.6096C13.1927 13.3596 13.3332 13.0205 13.3332 12.6668V5.3335"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6665 8H9.33317"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Archived;
