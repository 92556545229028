export const HYPERTEST_JOB_DETAIL_ACTIONS_REQUEST =
  'HYPERTEST_JOB_DETAIL_ACTIONS_REQUEST';
export const HYPERTEST_JOB_DETAIL_ACTIONS_RESPONSE =
  'HYPERTEST_JOB_DETAIL_ACTIONS_RESPONSE';
export const HYPERTEST_JOB_DETAIL_ACTIONS_SILENT_REQUEST =
  'HYPERTEST_JOB_DETAIL_ACTIONS_SILENT_REQUEST';
export const HYPERTEST_JOB_DETAIL_RESET = 'HYPERTEST_JOB_DETAIL_RESET';
export const HYPERTEST_SET_ARTIFECT_MODAL_IS_OPEN_STATUS =
  'HYPERTEST_SET_ARTIFECT_MODAL_IS_OPEN_STATUS';
export const HYPERTEST_JOB_REPORT_REQUEST = 'HYPERTEST_JOB_REPORT_REQUEST';
export const HYPERTEST_JOB_REPORT_RESPONSE = 'HYPERTEST_JOB_REPORT_RESPONSE';
export const HYPERTEST_SET_DOWNLOAD_REPORT_BUTTON_STATUS =
  'HYPERTEST_SET_DOWNLOAD_REPORT_BUTTON_STATUS';
export const HYPERTEST_SET_QUICK_START_STATUS =
  'HYPERTEST_SET_QUICK_START_STATUS';
export const HYPERTEST_SET_HELP_MENU_STATUS = 'HYPERTEST_SET_HELP_MENU_STATUS';
export const HYPERTEST_SET_SUPPORT_ALERT_STATUS =
  'HYPERTEST_SET_SUPPORT_ALERT_STATUS';
export const HYPERTEST_SET_JOB_ABORT_STATUS = 'HYPERTEST_SET_JOB_ABORT_STATUS';
