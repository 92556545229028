/* eslint-disable */
import quickRunConcurrencyHandler from './quickRunConcurrencyHandler';
import quickRunConfigConverter from './quickRunConfigConverter';

let pyunitConfiguaration = {
  setup: 'cd Java-TestNG-Selenium mvn Compile\nmvn compile',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/pyunit-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/pyunit-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
autosplit: true

maxRetries: 1
concurrency: 2

env:
#  PAT: \${{ .secrets.testKey }}
 TARGET_OS: Windows 11

# Dependency caching for Windows
cacheKey: '{{ checksum "requirements.txt" }}'
cacheDirectories:
  - CacheDir
pre:
  - pip3 install -r requirements.txt --cache-dir CacheDir
post:
  - cat yaml/win/pyunit_hyperexecute_autosplit_sample.yaml

testDiscovery:
  type: raw
  mode: dynamic
  command: grep -nri 'HyperTestPyUnit' tests -ir --include=\*.py | sed 's/:.*//'

testRunnerCommand: python3 $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};

let pyunitConfiguarationQuickRun = {
  setup: 'cd Java-TestNG-Selenium mvn Compile\nmvn compile',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/pyunit-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/pyunit-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
autosplit: true

maxRetries: MAXRETRIES
concurrency: CONCURRENCY
testSuiteTimeout: TIMEOUT

env:
#  PAT: \${{ .secrets.testKey }}
 TARGET_OS: Windows 10

# Dependency caching for Windows
cacheKey: '{{ checksum "requirements.txt" }}'
cacheDirectories:
  - CacheDir
pre:
  - pip3 install -r requirements.txt --cache-dir CacheDir

testDiscovery:
  type: raw
  mode: dynamic
  command: grep -nri 'HyperTestPyUnit' tests -ir --include=*.py | sed 's/:.*//'

testRunnerCommand: python3 -s $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};

let pyunitConfiguarationYamlBased = {
  setup: 'cd Java-TestNG-Selenium mvn Compile\nmvn compile',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/pyunit-selenium-hypertest-sample',
    downloadZip:
      'https://github.com/LambdaTest/pyunit-selenium-hypertest-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

env:
#  PAT: \${{ .secrets.testKey }}
 TARGET_OS: Windows 11

# Dependency caching for Windows
cacheKey: '{{ checksum "requirements.txt" }}'
cacheDirectories:
  - CacheDir
pre:
  - pip3 install -r requirements.txt --cache-dir CacheDir
post:
  - cat yaml/win/pyunit_hyperexecute_autosplit_sample.yaml

testDiscovery:
  type: raw
  mode: dynamic
  command: grep -nri 'HyperTestPyUnit' tests -ir --include=\*.py | sed 's/:.*//'

testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'tests',
  testRunnerCmd: 'python3 -s $test',
};

let pytestConfiguaration = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/pytest-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/pytest-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
autosplit: true

maxRetries: 1
concurrency: 2

env:
#  PAT: \${{ .secrets.testKey }}
 TARGET_OS: Windows 10

# Dependency caching for Windows
cacheKey: '{{ checksum "requirements.txt" }}'
cacheDirectories:
  - CacheDir
pre:
  - pip3 install -r requirements.txt --cache-dir CacheDir

testDiscovery:
  type: raw
  mode: dynamic
  command: grep -nri 'class' tests -ir --include=*.py | sed 's/:.*//'

testRunnerCommand: pytest -s  --verbose --html=reports/report.html $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};

let pytestConfiguarationYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/pyunit-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/pyunit-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

env:
#  PAT: \${{ .secrets.testKey }}
 TARGET_OS: Windows 10

# Dependency caching for Windows
cacheKey: '{{ checksum "requirements.txt" }}'
cacheDirectories:
  - CacheDir
pre:
  - pip3 install -r requirements.txt --cache-dir CacheDir

testDiscovery:
  type: raw
  mode: dynamic
  command: grep -nri 'class' TEST_DISCOVERY -ir --include=*.py | sed 's/:.*//'

testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'tests',
  testRunnerCmd: 'pytest -s  --verbose --html=reports/report.html $test',
};

let behaveConfiguaration = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/behave-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/behave-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
autosplit: true
maxRetries: 1
concurrency: 2

env:
#  PAT: \${{ .secrets.testKey }}
 TARGET_OS: Windows 10

# Dependency caching for Windows
cacheKey: '{{ checksum "requirements.txt" }}'
cacheDirectories:
  - pip_cache
pre:
  - pip3 install -r requirements.txt --cache-dir pip_cache

upload:
  - reports/test_report.json

# Details about HTML Formatter at https://pypi.org/project/behave-html-formatter/
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -nri 'Feature' features -ir --include=\*.feature | sed 's/:.*//'

# Reports are generated in the pretty JSON format
testRunnerCommand: behave -f json.pretty -o reports/test_report.json $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};

let behaveConfiguarationYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/behave-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/behave-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

env:
#  PAT: \${{ .secrets.testKey }}
 TARGET_OS: Windows 10

# Dependency caching for Windows
cacheKey: '{{ checksum "requirements.txt" }}'
cacheDirectories:
  - pip_cache
pre:
  - pip3 install -r requirements.txt --cache-dir pip_cache

upload:
  - reports/test_report.json

# Details about HTML Formatter at https://pypi.org/project/behave-html-formatter/
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -nri 'Feature' TEST_DISCOVERY -ir --include=\*.feature | sed 's/:.*//'

# Reports are generated in the pretty JSON format
testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'features',
  testRunnerCmd: 'behave -f json.pretty -o reports/test_report.json $test',
};

let robotConfiguaration = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/robot-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/robot-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
autosplit: true
maxRetries: 1
concurrency: 2

# Dependency caching for Windows
cacheKey: '{{ checksum "requirements.txt" }}'
cacheDirectories:
  - pip_cache
  - poetry_cache
pre:
  # Robot Framework and Robot Selenium Library need to be installed globally
  # Rest of the packages can be installed in venv
  - pip3 install -r requirements.txt --cache-dir pip_cache
  - poetry config virtualenvs.path poetry_cache
  - poetry install

testDiscovery:
  type: raw
  mode: dynamic
  command: grep 'test_windows' Makefile | sed 's/:.*//'

testRunnerCommand: make $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};

let robotConfiguarationYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/robot-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/robot-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

# Dependency caching for Windows
cacheKey: '{{ checksum "requirements.txt" }}'
cacheDirectories:
  - pip_cache
  - poetry_cache
pre:
  # Robot Framework and Robot Selenium Library need to be installed globally
  # Rest of the packages can be installed in venv
  - pip3 install -r requirements.txt --cache-dir pip_cache
  - poetry config virtualenvs.path poetry_cache
  - poetry install

testDiscovery:
  type: raw
  mode: dynamic
  command: grep 'TEST_DISCOVERY' Makefile | sed 's/:.*//'

testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'test_windows',
  testRunnerCmd: 'make $test',
};

let testRunnerCmdTestNgWin =
  'mvn test `-Dplatname=win `-Dmaven.repo.local=m2_cache_dir `-DselectedTests=$test';
let testRunnerCmdTestNgLinux =
  'mvn test -Dplatname=linux -Dmaven.repo.local=m2_cache_dir -DselectedTests=$test';
let testNgConfiguaration = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/testng-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/testng-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
globalTimeout: 150
testSuiteTimeout: 150
testSuiteStep: 150

runson: win

autosplit: true
retryOnFailure: true

maxRetries: 1
concurrency: 4

# shell: bash

env:
  CACHE_DIR: m2_cache_dir

cacheKey: '{{ checksum "pom.xml" }}'
cacheDirectories:
  - $CACHE_DIR

pre:
  # Create the Cache directory
  - mkdir -p m2_cache_dir
  # Download and install packages in the CACHE_DIR.
  # Skip execution of the tests in the pre step
  - mvn -Dmaven.repo.local=$CACHE_DIR -Dmaven.test.skip=true clean install

testDiscovery:
  type: raw
  mode: dynamic
  command: grep 'test name' xml/testng_win.xml | awk '{print$2}' | sed 's/name=//g' | sed 's/\\x3e//g'

testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testRunnerCmdWin: testRunnerCmdTestNgWin,
  testRunnerCmdLinux: testRunnerCmdTestNgLinux,
};
let testNgConfiguarationYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/testng-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/testng-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

# shell: bash

env:
  CACHE_DIR: m2_cache_dir

cacheKey: '{{ checksum "pom.xml" }}'
cacheDirectories:
  - $CACHE_DIR

pre:
  # Create the Cache directory
  - mkdir -p m2_cache_dir
  # Download and install packages in the CACHE_DIR.
  # Skip execution of the tests in the pre step
  - mvn -Dmaven.repo.local=$CACHE_DIR -Dmaven.test.skip=true clean install

testDiscovery:
  type: raw
  mode: dynamic
  command: grep 'test name' TEST_DISCOVERY | awk '{print$2}' | sed 's/name=//g' | sed 's/\\x3e//g'

testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'xml/testng_linux.xml',
  testRunnerCmd: 'mvn -Dmaven.repo.local=$CACHE_DIR -Dtest=$test',
  testRunnerCmdWin: testRunnerCmdTestNgWin,
  testRunnerCmdLinux: testRunnerCmdTestNgLinux,
};

let testRunnerCmdJunitWin =
  'mvn `-Dplatname=win `-Dmaven.repo.local=m2_cache_dir `-Dtest=$test test site surefire-report:report';
let testRunnerCmdJunitLinux =
  'mvn -Dplatname=linux -Dmaven.repo.local=m2_cache_dir -Dtest=$test test site surefire-report:report';
let jUnitConfiguaration = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/junit-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/junit-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
autosplit: true
retryOnFailure: true

maxRetries: 1
concurrency: 4

env:
  CACHE_DIR: m2_cache_dir
  TARGET_OS: Windows 10

# Dependency caching for Windows
cacheKey: '{{ checksum "pom.xml" }}'
cacheDirectories:
  - $CACHE_DIR

# shell: bash

pre:
  # Create the Cache directory
  - mkdir -p m2_cache_dir
  # Download and install packages in the CACHE_DIR.
  # Skip execution of the tests in the pre step
  - mvn -Dmaven.repo.local=$CACHE_DIR -Dmaven.test.skip=true clean install

testDiscovery:
  type: raw
  mode: dynamic
  command: grep 'public class' src/test/java/hyperexecute/*.java | awk '{print$3}'

testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testRunnerCmdWin: testRunnerCmdJunitWin,
  testRunnerCmdLinux: testRunnerCmdJunitLinux,
};
let jUnitConfiguarationYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/junit-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/junit-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

env:
  CACHE_DIR: m2_cache_dir
  TARGET_OS: Windows 10

# Dependency caching for Windows
cacheKey: '{{ checksum "pom.xml" }}'
cacheDirectories:
  - $CACHE_DIR

# shell: bash

pre:
  # Create the Cache directory
  - mkdir -p m2_cache_dir
  # Download and install packages in the CACHE_DIR.
  # Skip execution of the tests in the pre step
  - mvn -Dmaven.repo.local=$CACHE_DIR -Dmaven.test.skip=true clean install

testDiscovery:
  type: raw
  mode: dynamic
  command: grep 'public class' TEST_DISCOVERY | awk '{print$3}'

testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'src/test/java/hyperexecute/*.java',
  testRunnerCmd:
    'mvn -Dmaven.repo.local=$CACHE_DIR -Dtest=$test test site surefire-report:report',
  testRunnerCmdWin: testRunnerCmdJunitWin,
  testRunnerCmdLinux: testRunnerCmdJunitLinux,
};

let testRunnerCmdCucumberWin =
  'mvn test `-Dplatname=win `-Dmaven.repo.local=m2_cache_dir `-Dcucumber.options="--tags $test"';
let testRunnerCmdCucumberLinux =
  'mvn test -Dplatname=linux -Dmaven.repo.local=m2_cache_dir -Dcucumber.options="--tags $test"';
let cucumberConfiguaration = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/cucumber-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/cucumber-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
autosplit: true
retryOnFailure: true

maxRetries: 1
concurrency: 5

env:
  CACHE_DIR: m2_cache_dir

# Dependency caching for Windows
cacheKey: '{{ checksum "pom.xml" }}'
cacheDirectories:
  - $CACHE_DIR

pre:
  # Create the Cache directory
  - mkdir -p m2_cache_dir
  # Download and install packages in the CACHE_DIR.
  # Skip execution of the tests in the pre step
  - mvn -Dmaven.repo.local=$CACHE_DIR -Dmaven.test.skip=true clean install

testDiscovery:
  type: raw
  mode: dynamic
  #Parallel execution at feature level
  #command: grep -rni 'Features' -e 'Feature:' | sed 's/.*://'
  command: grep -nri '@' src/main/java/Features --include=\*.feature | sed 's/^.*://'

testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testRunnerCmdWin: testRunnerCmdCucumberWin,
  testRunnerCmdLinux: testRunnerCmdCucumberLinux,
};
let cucumberConfiguarationYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/cucumber-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/cucumber-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

env:
  CACHE_DIR: m2_cache_dir

# Dependency caching for Windows
cacheKey: '{{ checksum "pom.xml" }}'
cacheDirectories:
  - $CACHE_DIR

pre:
  # Create the Cache directory
  - mkdir -p m2_cache_dir
  # Download and install packages in the CACHE_DIR.
  # Skip execution of the tests in the pre step
  - mvn -Dmaven.repo.local=$CACHE_DIR -Dmaven.test.skip=true clean install

testDiscovery:
  type: raw
  mode: dynamic
  #Parallel execution at feature level
  #command: grep -rni 'Features' -e 'Feature:' | sed 's/.*://'
  command: grep -rni 'TEST_DISCOVERY' -e '@' --include=\\*.feature | sed 's/.*@//' | sed 's/^/@/'

testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'src/main/java/Features',
  testRunnerCmd:
    'mvn test -Dmaven.repo.local=$CACHE_DIR -Dcucumber.options="--tags $test"',
  testRunnerCmdWin: testRunnerCmdCucumberWin,
  testRunnerCmdLinux: testRunnerCmdCucumberLinux,
};
let nUnitConfiguaration = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/nunit-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/nunit-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
autosplit: true
maxRetries: 1
concurrency: 2

project: NUnitHyperTestDemo/NUnitHyperTestDemo.csproj
solution: NUnitHyperTestDemo.sln

env:
  NUGET_PACKAGES: 'C:\\nuget_global_cache'
  NUGET_HTTP_CACHE_PATH: 'C:\\nuget_http_cache'
  NUGET_PLUGINS_CACHE_PATH: 'C:\\nuget_plugins_cache'

pre:
 # https://docs.microsoft.com/en-us/dotnet/core/tools/dotnet-list-package
 - dotnet list $project package > packages.txt
 - nuget locals all -clear
 - dotnet build -c Release

testDiscovery:
  type: raw
  mode: dynamic
  # command: grep 'class' HyperTestDemo -ir --include=*.cs --exclude=DriverFactory.cs --exclude=HyperTestDemo.AssemblyInfo.cs | awk '{print$4}'
  # command: dotnet run --project HyperTestDiscoverer/HyperTestDiscoverer.csproj NUnitHyperTestDemo/bin/Debug/netcoreapp3.1/NUnitHyperTestDemo.dll
  command: grep 'Category' NUnitHyperTestDemo -ir --include=\\*.cs --exclude=DriverFactory.cs --exclude=HyperTestDemo.AssemblyInfo.cs | awk '{print$2}' | grep -o '".*"'

testRunnerCommand: dotnet test $solution --filter TestCategory=$test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};

let nUnitConfiguarationYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/nunit-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/nunit-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

project: NUnitHyperTestDemo/NUnitHyperTestDemo.csproj
solution: NUnitHyperTestDemo.sln

env:
  NUGET_PACKAGES: 'C:\\nuget_global_cache'
  NUGET_HTTP_CACHE_PATH: 'C:\\nuget_http_cache'
  NUGET_PLUGINS_CACHE_PATH: 'C:\\nuget_plugins_cache'

pre:
 # https://docs.microsoft.com/en-us/dotnet/core/tools/dotnet-list-package
 - dotnet list $project package > packages.txt
 - nuget locals all -clear
 - dotnet build -c Release

testDiscovery:
  type: raw
  mode: dynamic
  # command: grep 'class' HyperTestDemo -ir --include=*.cs --exclude=DriverFactory.cs --exclude=HyperTestDemo.AssemblyInfo.cs | awk '{print$4}'
  # command: dotnet run --project HyperTestDiscoverer/HyperTestDiscoverer.csproj NUnitHyperTestDemo/bin/Debug/netcoreapp3.1/NUnitHyperTestDemo.dll
  command: grep 'Category' TEST_DISCOVERY -ir --include=\\*.cs --exclude=DriverFactory.cs --exclude=HyperTestDemo.AssemblyInfo.cs | awk '{print$2}' | grep -o '".*"'

testRunnerCommand: dotnet test $solution --filter TestCategory=$test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'NUnitHyperTestDemo',
  testRunnerCmd: 'dotnet test $solution --filter TestCategory=$test ',
};

let specflowConfiguaration = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/specflow-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/specflow-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
autosplit: true
maxRetries: 1
concurrency: 10

# Further details about overriding default Nuget package download locations
# https://docs.microsoft.com/en-us/nuget/consume-packages/managing-the-global-packages-and-cache-folders

env:
  NUGET_PACKAGES: 'C:\\nuget_global_cache'
  NUGET_HTTP_CACHE_PATH: 'C:\\nuget_http_cache'
  NUGET_PLUGINS_CACHE_PATH: 'C:\\nuget_plugins_cache'
  # Secrets in Vault

pre:
 # https://docs.microsoft.com/en-us/dotnet/core/tools/dotnet-list-package
 - dotnet list OnlySpecTest.csproj package > packages.txt
 - nuget locals all -clear
 - dotnet build -c Release

# Dependency caching for Windows
cacheKey: '{{ checksum "packages.txt" }}'

post:
- cat yaml/win/specflow_hyperexecute_autosplit_sample.yaml

# The below testDiscovery command will give the feature name in the respective feature files
testDiscovery:
  type: raw
  mode: dynamic
  #Parallel execution at feature level
  #command: grep -rni 'Features' -e 'Feature:' | sed 's/.*://'

  command: grep -rni 'Features' -e '@' --include=\*.feature | sed 's/.*@//'

#Parallel execution at feature level
#testRunnerCommand: dotnet test --filter "(Name~$test)"
testRunnerCommand: dotnet test --filter "(Category=$test)"`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};

let specflowConfiguarationYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/specflow-selenium-hyperexecute-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/specflow-selenium-hyperexecute-sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

# Further details about overriding default Nuget package download locations
# https://docs.microsoft.com/en-us/nuget/consume-packages/managing-the-global-packages-and-cache-folders

env:
  NUGET_PACKAGES: 'C:\\nuget_global_cache'
  NUGET_HTTP_CACHE_PATH: 'C:\\nuget_http_cache'
  NUGET_PLUGINS_CACHE_PATH: 'C:\\nuget_plugins_cache'
  # Secrets in Vault

pre:
 # https://docs.microsoft.com/en-us/dotnet/core/tools/dotnet-list-package
 - dotnet list OnlySpecTest.csproj package > packages.txt
 - nuget locals all -clear
 - dotnet build -c Release

# Dependency caching for Windows
cacheKey: '{{ checksum "packages.txt" }}'

# The below testDiscovery command will give the feature name in the respective feature files
testDiscovery:
  type: raw
  mode: dynamic
  #Parallel execution at feature level
  #command: grep -rni 'Features' -e 'Feature:' | sed 's/.*://'

  command: grep -rni 'TEST_DISCOVERY' -e '@' --include=*.feature | sed 's/.*@//'

#Parallel execution at feature level
#testRunnerCommand: dotnet test --filter "(Name~$test)"
testRunnerCommand: dotnet test --filter "(Category=$test)"`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'Features',
  testRunnerCmd: 'dotnet test --filter "(Category=$test)"',
};

let capybaraConfiguaration = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/Capybara-HyperExecute-Sample.git',
    downloadZip:
      'https://github.com/LambdaTest/Capybara-HyperExecute-Sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: "0.1"
runson: win
autosplit: true
maxRetries: 1
concurrency: 2
env:
   CONFIG_NAME: "win"
testDiscovery:
  type: raw
  mode: dynamic
  command: snooper --featureFilePaths=features/ --frameWork=java
testRunnerCommand: bundle exec cucumber "$test" --format html --out cucumber_results.html
pre:
  - bundle install --deployment
cacheKey: '{{ checksum "Gemfile.lock" }}'
cacheDirectories:
  - ./vendor/bundle
report: true
partialReports:
 location: cucumber_results.html
 type: html`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let capybaraConfiguarationYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/Capybara-HyperExecute-Sample.git',
    downloadZip:
      'https://github.com/LambdaTest/Capybara-HyperExecute-Sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: "0.1"
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY
env:
   CONFIG_NAME: "win"
testDiscovery:
  type: raw
  mode: dynamic
  command: snooper --featureFilePaths=TEST_DISCOVERY --frameWork=java
testRunnerCommand: bundle exec cucumber "$test" --format html --out cucumber_results.html
pre:
  - bundle install --deployment
cacheKey: '{{ checksum "Gemfile.lock" }}'
cacheDirectories:
  - ./vendor/bundle
report: true
partialReports:
 location: cucumber_results.html
 type: html`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'features/',
  testRunnerCmd:
    'bundle exec cucumber "$test" --format html --out cucumber_results.html',
};

let rubyConfiguaration = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/Ruby-HyperExecute-Sample.git',
    downloadZip:
      'https://github.com/LambdaTest/Ruby-HyperExecute-Sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: "0.1"
runson: win
autosplit: true
maxRetries: 1
concurrency: 2
env:
   CONFIG_NAME: "win"
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -nri -E 'it ".*." do' spec/*.rb | awk '{print$1}' | sed 's/\\(.*\\):/\\1 /'
testRunnerCommand: bundle exec rspec "$test" --format html --out rspec_results.html
pre:
  - bundle install --deployment
cacheKey: '{{ checksum "Gemfile.lock" }}'
cacheDirectories:
  - /vendor
report: true
partialReports:
 location: rspec_results.html
 type: html`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};

let rubyConfiguarationYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/Ruby-HyperExecute-Sample.git',
    downloadZip:
      'https://github.com/LambdaTest/Ruby-HyperExecute-Sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: "0.1"
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY
env:
   CONFIG_NAME: "win"
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -nri -E 'it ".*." do' TEST_DISCOVERY | awk '{print$1}' | sed 's/\\(.*\\):/\\1 /'
testRunnerCommand: bundle exec rspec "$test" --format html --out rspec_results.html
pre:
  - bundle install --deployment
cacheKey: '{{ checksum "Gemfile.lock" }}'
cacheDirectories:
  - /vendor
report: true
partialReports:
 location: rspec_results.html
 type: html`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'spec/*.rb',
  testRunnerCmd:
    'bundle exec rspec "$test" --format html --out rspec_results.html',
};

let webdriverIoConfiguaration = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/WebdriverIO-Cucumber-HyperExecute-Sample.git',
    downloadZip:
      'https://github.com/LambdaTest/WebdriverIO-Cucumber-HyperExecute-Sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
concurrency: 2
autosplit: true
pre:
  - npm install

cacheKey: '{{ checksum "package-lock.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: automatic
  args:
    featureFilePaths: Features/
    frameWork: java
    specificTags: ["@ToDoOne", "@ToDoTwo", "@ToDoThree"]

testRunnerCommand: npx wdio wdio.conf.js --spec=$test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let webdriverIoConfiguarationYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/WebdriverIO-Cucumber-HyperExecute-Sample.git',
    downloadZip:
      'https://github.com/LambdaTest/WebdriverIO-Cucumber-HyperExecute-Sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY
pre:
  - npm install

cacheKey: '{{ checksum "package-lock.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: automatic
  args:
    featureFilePaths: TEST_DISCOVERY
    frameWork: java
    specificTags: ["@ToDoOne", "@ToDoTwo", "@ToDoThree"]

testRunnerCommand: npx wdio TEST_RUNNER --spec=$test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'Features/',
  testRunnerCmd: 'wdio.conf.js',
};
let nightWatchConfiguaration = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/Hyperexecute-Nightwatch-Sample.git',
    downloadZip:
      'https://github.com/LambdaTest/Hyperexecute-Nightwatch-Sample/archive/refs/heads/main.zip',
  },
  yamlCode: `version: 0.1
runson: win
maxRetries: 1
concurrency: 3
autosplit: true
pre:
  - npm install

cacheKey: '{{ checksum "package-lock.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -B1 'desiredCapabilities' nightwatch.json | sed 's/-//g' | grep -vE 'desiredCapabilities' | grep -vE 'skip_testcases_on_fail' | awk '{print$1}' | sed 's/://g' | sed 's/"//g'
testRunnerCommand: ./node_modules/.bin/nightwatch -e $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let nightWatchConfiguarationYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/Hyperexecute-Nightwatch-Sample.git',
    downloadZip:
      'https://github.com/LambdaTest/Hyperexecute-Nightwatch-Sample/archive/refs/heads/main.zip',
  },
  yamlCode: `version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY
pre:
  - npm install

cacheKey: '{{ checksum "package-lock.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -B1 'desiredCapabilities' TEST_DISCOVERY | sed 's/-//g' | grep -vE 'desiredCapabilities' | grep -vE 'skip_testcases_on_fail' | awk '{print$1}' | sed 's/://g' | sed 's/"//g'
testRunnerCommand: TEST_RUNNER -e $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'nightwatch.json',
  testRunnerCmd: './node_modules/.bin/nightwatch',
};
let protractorConfiguaration = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/Protractor-HyperExecute-Sample.git',
    downloadZip:
      'https://github.com/LambdaTest/Protractor-HyperExecute-Sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
concurrency: 3
autosplit: true
pre:
  - npm install -g protractor
  - npm install

cacheKey: '{{ checksum "package-lock.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -nri 'describe' specs -ir --include=*.js | sed 's/:.*//'
testRunnerCommand: protractor conf/single.conf.js --specs=$test --browser=chrome`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let protractorConfiguarationYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/Protractor-HyperExecute-Sample.git',
    downloadZip:
      'https://github.com/LambdaTest/Protractor-HyperExecute-Sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY
pre:
  - npm install -g protractor
  - npm install

cacheKey: '{{ checksum "package-lock.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -nri 'describe' TEST_DISCOVERY -ir --include=*.js | sed 's/:.*//'
testRunnerCommand: protractor TEST_RUNNER --specs=$test --browser=chrome`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'specs',
  testRunnerCmd: 'conf/single.conf.js',
};

let vanillaJSPlayWright = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/HyperExecute-Playwright-Vanilla-Javascript.git',
    downloadZip:
      'https://github.com/LambdaTest/HyperExecute-Playwright-Vanilla-Javascript/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
concurrency: 2
autosplit: true
pre:
  - npm install
  - npx playwright install
cacheKey: '{{ checksum "package-lock.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'describe' tests
testRunnerCommand: npx playwright test $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let vanillaJSPlayWrightYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/HyperExecute-Playwright-Vanilla-Javascript.git',
    downloadZip:
      'https://github.com/LambdaTest/HyperExecute-Playwright-Vanilla-Javascript/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: "0.1"
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY
pre:
  - npm install
  - npx playwright install
cacheKey: '{{ checksum "package-lock.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'describe' TEST_DISCOVERY
testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'tests',
  testRunnerCmd: 'npx playwright test $test',
};
let codeceptPlayWright = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/HyperExecute-Playwright-CodeceptJs.git',
    downloadZip:
      'https://github.com/LambdaTest/HyperExecute-Playwright-CodeceptJs/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
concurrency: 2
autosplit: true
pre:
  - npm install
  - npx playwright install
cacheKey: '{{ checksum "package-lock.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'Scenario' *test.js
testRunnerCommand: npx codeceptjs run $test --steps`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let codeceptPlayWrightYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/HyperExecute-Playwright-CodeceptJs.git',
    downloadZip:
      'https://github.com/LambdaTest/HyperExecute-Playwright-CodeceptJs/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: "0.1"
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY
pre:
  - npm install
  - npx playwright install
cacheKey: '{{ checksum "package-lock.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'Scenario' TEST_DISCOVERY
testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: '*test.js',
  testRunnerCmd: 'npx codeceptjs run $test --steps',
};

let jestPlayWright = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/HyperExecute-Playwright-Jest.git',
    downloadZip:
      'https://github.com/LambdaTest/HyperExecute-Playwright-Jest/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
concurrency: 2
autosplit: true
pre:
  - npm install
  - npx playwright install
cacheKey: '{{ checksum "package-lock.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'describe' *.test.js
testRunnerCommand: npm test -- $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let jestPlayWrightYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/HyperExecute-Playwright-Jest.git',
    downloadZip:
      'https://github.com/LambdaTest/HyperExecute-Playwright-Jest/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: "0.1"
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY
pre:
  - npm install
  - npx playwright install
cacheKey: '{{ checksum "package-lock.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'describe' TEST_DISCOVERY
testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: '*.test.js',
  testRunnerCmd: 'npm test -- $test',
};
let dotNetPlayWright = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/dotnet_playwright_hyperexecute_sample',
    downloadZip:
      'https://github.com/LambdaTest/dotnet_playwright_hyperexecute_sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
concurrency: 3
autosplit: true

env:
  NUGET_PACKAGES: 'C:\\nuget_global_cache'
  NUGET_HTTP_CACHE_PATH: 'C:\\nuget_http_cache'
  NUGET_PLUGINS_CACHE_PATH: 'C:\\nuget_plugins_cache'

pre:
 - npm install playwright@1.25.0 --save-exact
 - dotnet list PlaywrightDotnetTests.csproj package > packages.txt
 - nuget locals all -clear
 - dotnet build -c Release

# Dependency caching for Windows
cacheKey: '{{ checksum "packages.txt" }}'

runtime:
  language: dotnet
  version: "6.0.303"

testDiscovery:
  type: raw
  mode: static
  command: grep "case" Program.cs | awk '{print$2}' | sed 's/://g'

post:
  - cat yaml/win/dotnet_playwright_hyperexecute_autosplit_sample.yaml

testRunnerCommand: dotnet run $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let dotNetPlayWrightYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/dotnet_playwright_hyperexecute_sample',
    downloadZip:
      'https://github.com/LambdaTest/dotnet_playwright_hyperexecute_sample/archive/refs/heads/master.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

env:
  NUGET_PACKAGES: 'C:\\nuget_global_cache'
  NUGET_HTTP_CACHE_PATH: 'C:\\nuget_http_cache'
  NUGET_PLUGINS_CACHE_PATH: 'C:\\nuget_plugins_cache'

pre:
 - npm install playwright@1.25.0 --save-exact
 - dotnet list PlaywrightDotnetTests.csproj package > packages.txt
 - nuget locals all -clear
 - dotnet build -c Release

# Dependency caching for Windows
cacheKey: '{{ checksum "packages.txt" }}'

runtime:
  language: dotnet
  version: "6.0.303"

testDiscovery:
  type: raw
  mode: static
  command: grep "case" Program.cs | awk '{print$2}' | sed 's/://g'

post:
  - cat yaml/win/dotnet_playwright_hyperexecute_autosplit_sample.yaml

testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'Program.cs',
  testRunnerCmd: 'dotnet run $test',
};
let testRunnerCmdJavaPlaywrightWin =
  'mvn test `-Dplatname=win `-Dmaven.repo.local=m2_cache_dir `-Dtest=$test surefire-report:report';
let testRunnerCmdJavaPlaywrightLinux =
  'mvn test -Dplatname=linux -Dmaven.repo.local=m2_cache_dir -Dtest=$test surefire-report:report';
let junitPlaywright = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-java-playwright-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-java-playwright-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
concurrency: 3
autosplit: true

env:
  # PAT: \${{ .secrets.testKey }}
  CACHE_DIR: m2_cache_dir
  TARGET_OS: Windows 10

# Dependency caching for Windows
cacheKey: '{{ checksum "pom.xml" }}'
cacheDirectories:
  - $CACHE_DIR

# shell: bash

pre:
 # install playwright version to be used.
  - npm install playwright@1.23.0 --save-exact
  # Download and install packages in the CACHE_DIR.
  # Skip execution of the tests in the pre step
  - mvn -Dmaven.repo.local=$CACHE_DIR -Dmaven.test.skip=true clean install

post:
  - cat yaml/win/junit_hyperexecute_autosplit_sample.yaml

mergeArtifacts: true

uploadArtefacts:
 - name: Surefire-Report
   path:
    - target/surefire-reports/**

testDiscovery:
  type: raw
  mode: dynamic
  command: grep 'public class' src/test/java/SampleTest.java | awk '{print$3}'

testRunnerCommand:  TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testRunnerCmdWin: testRunnerCmdJavaPlaywrightWin,
  testRunnerCmdLinux: testRunnerCmdJavaPlaywrightLinux,
};
let junitPlaywrightYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-java-playwright-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-java-playwright-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

env:
  # PAT: \${{ .secrets.testKey }}
  CACHE_DIR: m2_cache_dir
  TARGET_OS: Windows 10

# Dependency caching for Windows
cacheKey: '{{ checksum "pom.xml" }}'
cacheDirectories:
  - $CACHE_DIR

# shell: bash

pre:
 # install playwright version to be used.
  - npm install playwright@1.23.0 --save-exact
  # Download and install packages in the CACHE_DIR.
  # Skip execution of the tests in the pre step
  - mvn -Dmaven.repo.local=$CACHE_DIR -Dmaven.test.skip=true clean install

post:
  - cat yaml/win/junit_hyperexecute_autosplit_sample.yaml

mergeArtifacts: true

uploadArtefacts:
 - name: Surefire-Report
   path:
    - target/surefire-reports/**

testDiscovery:
  type: raw
  mode: dynamic
  command: grep 'public class' src/test/java/SampleTest.java | awk '{print$3}'

testRunnerCommand:  TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'src/test/java/SampleTest.java',
  testRunnerCmdWin: testRunnerCmdJavaPlaywrightWin,
  testRunnerCmdLinux: testRunnerCmdJavaPlaywrightLinux,
};
let pythonPlayWright = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-playwright-python-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-playwright-python-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: "0.1"
runson: win
concurrency: 3
autosplit: true

env:
 TARGET_OS: "Windows 10"
cacheKey: '{{ checksum "requirement.txt" }}'
cacheDirectories:
  - CacheDir
pre:
  - pip3 install -r requirement.txt --cache-dir CacheDir
  - playwright install
  - npm install playwright@1.23.0 --save-exact
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'def' *.py
testRunnerCommand: python $test
post:
  - cat yaml/win/.hyperexecute_matrix.yaml`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let pythonPlayWrightYaml = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-playwright-pypi-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-playwright-pypi-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: "0.1"
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

env:
 TARGET_OS: "Windows 10"
cacheKey: '{{ checksum "requirement.txt" }}'
cacheDirectories:
  - CacheDir
pre:
  - pip3 install -r requirement.txt --cache-dir CacheDir
  - playwright install
  - npm install playwright@1.23.0 --save-exact
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'def' *.py
testRunnerCommand: python $test
post:
  - cat yaml/win/.hyperexecute_matrix.yaml`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: '*.py',
  testRunnerCmd: 'pytest $test',
};
let pytestPlayWright = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-playwright-pypi-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-playwright-pypi-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: "0.1"
runson: win
concurrency: 3
autosplit: true

env:
 TARGET_OS: "Windows 10"
cacheKey: '{{ checksum "requirement.txt" }}'
cacheDirectories:
  - CacheDir
pre:
  - npm install playwright@1.24.0 --save-exact
  - pip install -r requirement.txt --cache-dir CacheDir
  - playwright install
testDiscovery:
  type: raw
  mode: static
  command: grep -lr 'def' *.py
testRunnerCommand: pytest $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let pytestPlayWrightYaml = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-playwright-pypi-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-playwright-pypi-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: "0.1"
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

env:
 TARGET_OS: "Windows 10"
cacheKey: '{{ checksum "requirement.txt" }}'
cacheDirectories:
  - CacheDir
pre:
  - npm install playwright@1.24.0 --save-exact
  - pip install -r requirement.txt --cache-dir CacheDir
  - playwright install
testDiscovery:
  type: raw
  mode: static
  command: grep -lr 'def' *.py
testRunnerCommand: pytest $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: '*.py',
  testRunnerCmd: 'pytest $test',
};
let cypressV9 = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-cypress-v9-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-cypress-v9-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
cypress: true
concurrency: 2
autosplit: true

cacheKey: '{{ checksum "package.json" }}'
cacheDirectories:
  - node_modules
pre:
  - npm install
testDiscovery:
  mode: static
  type: raw
  command: ls cypress/integration
testRunnerCommand: npx cypress run  --spec ./cypress/integration/$test --browser=chrome-95.0 --headed --config video=false
cypressOps:
 Build: "Hyperexecute Cypress Sample Build"
 Tags: ["Hyperexecute","Cypress", "Windows", "Autosplit", "V9"]
 BuildTags: ["Hyperexecute-Cypress"]
post:
  - cat ./yaml/win/.hyperexecute_matrix.yaml`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let cypressV9YamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-cypress-v9-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-cypress-v9-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
cypress: true
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

cacheKey: '{{ checksum "package.json" }}'
cacheDirectories:
  - node_modules
pre:
  - npm install
testDiscovery:
  mode: static
  type: raw
  command: ls cypress/integration
testRunnerCommand: npx cypress run  --spec ./cypress/integration/$test --browser=chrome-95.0 --headed --config video=false
cypressOps:
 Build: "Hyperexecute Cypress Sample Build"
 Tags: ["Hyperexecute","Cypress", "Windows", "Autosplit", "V9"]
 BuildTags: ["Hyperexecute-Cypress"]
post:
  - cat ./yaml/win/.hyperexecute_matrix.yaml`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'cypress/integration',
  testRunnerCmd:
    'npx cypress run  --spec ./cypress/integration/$test --browser=chrome-95.0 --headed --config video=false',
};
let cypressV10 = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-cypress-v10-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-cypress-v10-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
cypress: true
concurrency: 2
autosplit: true

pre:
  - npm install
  - npm install cypress --save-dev
cacheKey: '{{ checksum "package.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  mode: static
  type: raw
  command: ls cypress/e2e/2-advanced-examples
testRunnerCommand: npx cypress run  --spec ./cypress/e2e/2-advanced-examples/$test --browser=chrome-95.0 --headed --config video=false
cypressOps:
 Build: "Hyperexecute Cypress Sample Build"
 Tags: ["Hyperexecute","Cypress", "Windows", "Autosplit"]
 BuildTags: ["Hyperexecute-Cypress"]
post:
  - cat yaml/win/.hyperexecute_autosplit.yaml`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let cypressV10YamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-cypress-v10-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-cypress-v10-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
cypress: true
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY

pre:
  - npm install
  - npm install cypress --save-dev
cacheKey: '{{ checksum "package.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  mode: static
  type: raw
  command: ls cypress/e2e/2-advanced-examples
testRunnerCommand: npx cypress run  --spec ./cypress/e2e/2-advanced-examples/$test --browser=chrome-95.0 --headed --config video=false
cypressOps:
 Build: "Hyperexecute Cypress Sample Build"
 Tags: ["Hyperexecute","Cypress", "Windows", "Autosplit"]
 BuildTags: ["Hyperexecute-Cypress"]
post:
  - cat yaml/win/.hyperexecute_autosplit.yaml`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'cypress/e2e/2-advanced-examples',
  testRunnerCmd:
    'npx cypress run  --spec ./cypress/e2e/2-advanced-examples/$test --browser=chrome-95.0 --headed --config video=false',
};
let mochaPuppeteer = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-puppeteer-mocha-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-puppeteer-mocha-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
autosplit: true
concurrency: 2
pre:
  - npm install
cacheKey: '{{ checksum "package.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'describe' specs/*.spec.js
testRunnerCommand: npm test $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let mochaPuppeteerYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-puppeteer-mocha-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-puppeteer-mocha-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY
pre:
  - npm install
cacheKey: '{{ checksum "package.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'describe' TEST_DISCOVERY
testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'specs/*.spec.js',
  testRunnerCmd: 'npm test $test',
};
let jestPuppeteer = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-puppeteer-jest-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-puppeteer-jest-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
autosplit: true
concurrency: 2
pre:
  - npm install
cacheKey: '{{ checksum "package.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'describe' specs/*.spec.js
testRunnerCommand: npm test -- $test`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let jestPuppeteerYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-puppeteer-jest-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-puppeteer-jest-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: 0.1
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY
pre:
  - npm install
cacheKey: '{{ checksum "package.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'describe' TEST_DISCOVERY
testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: 'specs/*.spec.js',
  testRunnerCmd: 'npm test -- $test',
};
let codeceptPuppeteer = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-puppeteer-codecept-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-puppeteer-codecept-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: "0.1"
runson: win
autosplit: true
concurrency: 2
pre:
  - npm install
cacheKey: '{{ checksum "package.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'Scenario' *test.js
testRunnerCommand: npx codeceptjs run $test --steps`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
};
let codeceptPuppeteerYamlBased = {
  setup: '',
  setupGit: {
    clone:
      'git clone https://github.com/LambdaTest/hyperexecute-puppeteer-codecept-sample.git',
    downloadZip:
      'https://github.com/LambdaTest/hyperexecute-puppeteer-codecept-sample/archive/refs/heads/main.zip',
  },
  yamlCode: `---
version: "0.1"
runson: win
testSuiteTimeout: TIMEOUT
autosplit: true
retryOnFailure: RETRYONFAILURE
maxRetries: RETRIES
concurrency: CONCURRENCY
pre:
  - npm install
cacheKey: '{{ checksum "package.json" }}'
cacheDirectories:
  - node_modules
testDiscovery:
  type: raw
  mode: dynamic
  command: grep -lr 'Scenario' TEST_DISCOVERY
testRunnerCommand: TEST_RUNNER`,
  runTestCommand: `CONCIERGE --user USERNAME --key ACCESSKEY --config CONFIG ${process.env.REACT_APP_ENV_TYPE === 'stage' ? '--env stage' : ''
    }`,
  testDiscoveryCmd: '*test.js',
  testRunnerCmd: 'npx codeceptjs run $test --steps',
};
let conciergeDownloadLink = {
  linux: process.env.REACT_APP_HYPERTEST_CONCIERGE_LINK_LINUX || (window._env_ && window._env_.REACT_APP_HYPERTEST_CONCIERGE_LINK_LINUX),
  windows: process.env.REACT_APP_HYPERTEST_CONCIERGE_LINK_WINDOWS || (window._env_ && window._env_.REACT_APP_HYPERTEST_CONCIERGE_LINK_WINDOWS),
  mac: process.env.REACT_APP_HYPERTEST_CONCIERGE_LINK_MAC || (window._env_ && window._env_.REACT_APP_HYPERTEST_CONCIERGE_LINK_MAC),
};

function getFrameworkConfiguration(framework, frameworkType = 'Selenium') {
  switch (framework) {
    case 'PyUnit':
      return pyunitConfiguaration;
    case 'Python':
      if (frameworkType === 'PlayWright') {
        return pythonPlayWright;
      }
      break;
    case 'PyTest':
      if (frameworkType === 'Puppeteer') {
        return pytestConfiguaration;
      } else if (frameworkType === 'PlayWright') {
        return pytestPlayWright;
      } else {
        return pytestConfiguaration;
      }
    case 'Behave':
      return behaveConfiguaration;
    case 'Robot':
      if (frameworkType === 'Puppeteer') {
        return robotConfiguaration;
      } else if (frameworkType === 'PlayWright') {
        return robotConfiguaration;
      } else {
        return robotConfiguaration;
      }
    case 'JUnit':
      if (frameworkType === 'PlayWright') {
        return junitPlaywright;
      } else {
        return jUnitConfiguaration;
      }
    case 'TestNG':
      return testNgConfiguaration;
    case 'Cucumber':
      return cucumberConfiguaration;
    case 'NUnit':
      return nUnitConfiguaration;
    case 'SpecFlow':
      return specflowConfiguaration;
    case 'Capybara':
      return capybaraConfiguaration;
    case 'RSpec':
      return rubyConfiguaration;
    case 'NightwatchJS':
      return nightWatchConfiguaration;
    case 'WebdriverIO':
      return webdriverIoConfiguaration;
    case 'Protractor':
      return protractorConfiguaration;
    case 'CodeceptJS':
      if (frameworkType === 'Puppeteer') {
        return codeceptPuppeteer;
      } else if (frameworkType === 'PlayWright') {
        return codeceptPlayWright;
      } else {
        return protractorConfiguaration;
      }
    case 'VanillaJS':
      if (frameworkType === 'PlayWright') {
        return vanillaJSPlayWright;
      }
      break;
    case 'Jest':
      if (frameworkType === 'PlayWright') {
        return jestPlayWright;
      } else if (frameworkType === 'Puppeteer') {
        return jestPuppeteer;
      }
      break;
    case 'Mocha':
      if (frameworkType === 'Puppeteer') {
        return mochaPuppeteer;
      }
      break;
    case '.Net':
      return dotNetPlayWright;
    case 'Cypress-v9':
      return cypressV9;
    case 'Cypress-v10':
      return cypressV10;
    default:
      return pyunitConfiguaration;
  }
}
function getFrameworkConfigurationQuickRun(
  framework,
  frameworkType = 'Selenium'
) {
  let config = getFrameworkConfiguration(framework, frameworkType);
  const yamlCode = config?.yamlCode;
  const concurrency = yamlCode
    ?.match(/(concurrency: \d{1,2})/i)[0]
    ?.match(/\d/g)[0];
  quickRunConcurrencyHandler(concurrency);
  config = quickRunConfigConverter({ ...config });

  return config;
}
function getFrameworkConfigurationYamlBased(
  framework,
  frameworkType = 'Selenium'
) {
  switch (framework) {
    case 'PyUnit':
      return pyunitConfiguarationYamlBased;
    case 'Python':
      if (frameworkType === 'PlayWright') {
        return pythonPlayWrightYaml;
      }
      break;
    case 'PyTest':
      if (frameworkType === 'PlayWright') {
        return pytestPlayWrightYaml;
      } else {
        return pytestConfiguarationYamlBased;
      }
    case 'Behave':
      return behaveConfiguarationYamlBased;
    case 'Robot':
      return robotConfiguarationYamlBased;
    case 'JUnit':
      if (frameworkType === 'PlayWright') {
        return junitPlaywrightYamlBased;
      } else {
        return jUnitConfiguarationYamlBased;
      }
    case 'TestNG':
      return testNgConfiguarationYamlBased;
    case 'Cucumber':
      return cucumberConfiguarationYamlBased;
    case 'NUnit':
      return nUnitConfiguarationYamlBased;
    case 'SpecFlow':
      return specflowConfiguarationYamlBased;
    case 'Capybara':
      return capybaraConfiguarationYamlBased;
    case 'RSpec':
      return rubyConfiguarationYamlBased;
    case 'NightwatchJS':
      return nightWatchConfiguarationYamlBased;
    case 'WebdriverIO':
      return webdriverIoConfiguarationYamlBased;
    case 'Protractor':
      return protractorConfiguarationYamlBased;
    case 'VanillaJS':
      if (frameworkType === 'PlayWright') {
        return vanillaJSPlayWrightYamlBased;
      }
      break;
    case 'CodeceptJS':
      if (frameworkType === 'Puppeteer') {
        return codeceptPuppeteerYamlBased;
      } else if (frameworkType === 'PlayWright') {
        return codeceptPlayWrightYamlBased;
      } else {
        return protractorConfiguaration;
      }
    case 'Jest':
      if (frameworkType === 'PlayWright') {
        return jestPlayWrightYamlBased;
      } else if (frameworkType === 'Puppeteer') {
        return jestPuppeteerYamlBased;
      }
      break;
    case 'Mocha':
      if (frameworkType === 'Puppeteer') {
        return mochaPuppeteerYamlBased;
      }
      break;
    case '.Net':
      return dotNetPlayWrightYamlBased;
    case 'Cypress-v9':
      return cypressV9YamlBased;
    case 'Cypress-v10':
      return cypressV10YamlBased;
    default:
      return pyunitConfiguarationYamlBased;
  }
}

const javaFrameworks = ['JUnit', 'TestNG', 'Cucumber'];
const onlyWindowsSupportedFramework = ['SpecFlow', 'NUnit', '.Net'];
const staticTestRunnerCmdFramework = [
  'JUnit-Selenium',
  'JUnit-PlayWright',
  'TestNG-Selenium',
  'Cucumber-Selenium',
  'SpecFlow-Selenium',
  'NUnit-Selenium',
  'Capybara-Selenium',
  'RSpec-Selenium',
  'PyUnit-Selenium',
  'PyTest-Selenium',
  'Behave-Selenium',
  'Robot-Selenium',
  'VanillaJS-PlayWright',
  'CodeceptJS-Selenium',
  'CodeceptJS-Puppeteer',
  'Jest-Selenium',
  'Jest-Puppeteer',
  'Mocha-Selenium',
  'Mocha-Puppeteer',
  '.Net-PlayWright',
  'Cypress-v9-Cypress',
  'Cypress-v10-Cypress',
  'Python-PlayWright',
  'PyTest-PlayWright',
];
const staticDiscoveryCmdFramework = [
  'VanillaJS-PlayWright',
  'CodeceptJS-PlayWright',
  'Jest-PlayWright',
  'Mocha-Puppeteer',
  'JUnit-PlayWright',
  'Python-PlayWright',
  'PyTest-PlayWright',
  '.Net-PlayWright',
  'Cypress-v9-Cypress',
  'Cypress-v10-Cypress',
];
function getShellOption(plateform, framework) {
  switch (framework) {
    case 'JUnit':
    case 'TestNG':
    case 'Cucumber':
      if (plateform === 'linux') {
        return 'shell: bash';
      } else {
        return '';
      }
    default:
      return '';
  }
}
function getTestRunnerCmd(plateform, framework, frameworkType) {
  switch (framework) {
    case 'JUnit':
      if (plateform === 'linux') {
        if (frameworkType === 'PlayWright') {
          return junitPlaywright.testRunnerCmdLinux;
        } else {
          return jUnitConfiguaration.testRunnerCmdLinux;
        }
      } else {
        if (frameworkType === 'PlayWright') {
          return junitPlaywright.testRunnerCmdWin;
        } else {
          return jUnitConfiguaration.testRunnerCmdWin;
        }
      }
    case 'TestNG':
      if (plateform === 'linux') {
        return testNgConfiguaration.testRunnerCmdLinux;
      } else {
        return testNgConfiguaration.testRunnerCmdWin;
      }
    case 'Cucumber':
      if (plateform === 'linux') {
        return cucumberConfiguaration.testRunnerCmdLinux;
      } else {
        return cucumberConfiguaration.testRunnerCmdWin;
      }
    default:
      return '';
  }
}
export {
  pyunitConfiguaration,
  pyunitConfiguarationYamlBased,
  pyunitConfiguarationQuickRun,
  conciergeDownloadLink,
  getFrameworkConfiguration,
  getFrameworkConfigurationYamlBased,
  getFrameworkConfigurationQuickRun,
  onlyWindowsSupportedFramework,
  staticTestRunnerCmdFramework,
  staticDiscoveryCmdFramework,
  getShellOption,
  getTestRunnerCmd,
  javaFrameworks,
};
