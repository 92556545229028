import React from 'react';

const Edit = ({ width = '16', height = '16', className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      role="img"
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      aria-hidden="true"
    >
      <g clipPath="url(#clip0_8541_62169)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.367 1.14645C12.5622 0.951184 12.8788 0.951185 13.0741 1.14645L14.7432 2.81563C14.9385 3.01089 14.9385 3.32748 14.7432 3.52274L12.7205 5.54547L10.3442 3.16918L12.367 1.14645ZM9.63711 3.87629L2.06544 11.448L1.11492 14.7748L4.44173 13.8242L12.0134 6.25258L9.63711 3.87629ZM13.7812 0.439339C13.1954 -0.146447 12.2456 -0.146446 11.6598 0.43934L1.26672 10.8325L1.1751 10.9241L1.13951 11.0487L0.0297758 14.9327C-0.13187 15.4985 0.391192 16.0216 0.956959 15.8599L4.84103 14.7502L4.96561 14.7146L5.05722 14.623L15.4503 4.22984C16.0361 3.64406 16.0361 2.69431 15.4503 2.10852L13.7812 0.439339Z"
          fill="#666666"
        />
      </g>
    </svg>
  );
};

export { Edit };
