import React from 'react';

const Matrix = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M1.3679 13.8153H4.16761V12.8054H2.58665V3.95455H4.16761V2.9446H1.3679V13.8153ZM14.0529 2.9446H11.2532V3.95455H12.8342V12.8054H11.2532V13.8153H14.0529V2.9446Z"
        fill="#666666"
      />
      <rect x="4" y="6" width="1" height="1" fill="#666666" />
      <rect x="7" y="6" width="1" height="1" fill="#666666" />
      <rect x="10" y="6" width="1" height="1" fill="#666666" />
      <rect x="4" y="8" width="1" height="1" fill="#666666" />
      <rect x="7" y="8" width="1" height="1" fill="#666666" />
      <rect x="10" y="8" width="1" height="1" fill="#666666" />
      <rect x="4" y="10" width="1" height="1" fill="#666666" />
      <rect x="7" y="10" width="1" height="1" fill="#666666" />
      <rect x="10" y="10" width="1" height="1" fill="#666666" />{' '}
    </svg>
  );
};

export { Matrix };
