import React from 'react';

const HistoryIcon = ({ width = 16, height = 16, className, ...rest }) => {
  return (
    <svg
      role="img"
      aria-hidden="true"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path
        d="M7.50781 5.08984V8.16633L9.5588 9.53366"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C5.52003 1.5 3.36444 2.88885 2.2686 4.93119"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M1.5 3.5V5.5H3.5"
        stroke="#666666"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HistoryIcon;
