import { useDispatch } from 'react-redux';

import {
  setFeatureFlags,
  getAmplitudeFeatureFlags,
} from 'store/actions/featureFlag';

function useFeatureFlag() {
  const dispatch = useDispatch();

  function fetchFeatureFlags({ userId, auth, orgId }) {
    dispatch(setFeatureFlags({ userId, auth, orgId }));
    dispatch(getAmplitudeFeatureFlags({ userId, auth, orgId }));
  }

  return { fetchFeatureFlags };
}

export default useFeatureFlag;
