import React from 'react';

const GitpodSupport = ({ width, height, className, color, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      {...rest}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 16"
      role="img"
      fill="none"
      aria-hidden="true"
    >
      <path
        d="M8.31768 0.796319C8.5178 1.15996 8.57083 1.58996 8.46533 1.99347C8.35982 2.39698 8.10425 2.74162 7.7538 2.95296L3.22973 5.64627C3.17047 5.68161 3.1213 5.73238 3.08717 5.79347C3.05304 5.85455 3.03514 5.92381 3.03528 5.99426V10.2222C3.03512 10.2926 3.05302 10.3618 3.08716 10.4227C3.1213 10.4837 3.17048 10.5344 3.22973 10.5695L6.81009 12.7022C6.86781 12.7365 6.93332 12.7546 7 12.7546C7.06668 12.7546 7.13219 12.7365 7.18991 12.7022L10.7722 10.5695C10.8311 10.5342 10.88 10.4836 10.914 10.4228C10.948 10.3619 10.9659 10.293 10.966 10.2229V7.59291L7.74537 9.48489C7.57168 9.58672 7.38004 9.652 7.18159 9.67693C6.98315 9.70186 6.78186 9.68595 6.58945 9.63011C6.39704 9.57428 6.21735 9.47964 6.06084 9.3517C5.90432 9.22376 5.77411 9.06508 5.67778 8.8849C5.47994 8.51993 5.42954 8.08958 5.53746 7.68673C5.64538 7.28388 5.90296 6.94082 6.25463 6.73159L10.8617 4.02362C12.2649 3.19963 14 4.25562 14 5.9336V10.5429C14.0012 11.0713 13.8675 11.5908 13.6121 12.0493C13.3567 12.5079 12.9887 12.8893 12.5449 13.1555L8.43241 15.6035C7.99703 15.8632 7.50266 16 6.99935 16C6.49605 16 6.00168 15.8632 5.5663 15.6035L1.45445 13.1555C1.01086 12.8893 0.64303 12.5079 0.38778 12.0495C0.13253 11.5911 -0.00118875 11.0718 7.96247e-06 10.5435V5.67293C-0.00112276 5.14462 0.132694 4.62526 0.388061 4.16686C0.643428 3.70846 1.01139 3.32711 1.4551 3.06096L6.24685 0.207659C6.42002 0.104854 6.61133 0.0385049 6.80966 0.0124733C7.008 -0.0135583 7.20938 0.00124767 7.4021 0.0560294C7.59481 0.110811 7.77501 0.204474 7.93219 0.331567C8.08938 0.458659 8.22042 0.616642 8.31768 0.796319Z"
        fill="#999999"
      />
    </svg>
  );
};

export { GitpodSupport };
