import React from 'react';

const Thunder = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      alt=""
      aria-hidden="true"
    >
      <path
        d="M11.5932 4.9123H8.58128L12.2897 0.490234C12.3457 0.417773 12.2951 0.3125 12.2035 0.3125H6.96038C6.9221 0.3125 6.88519 0.333008 6.86604 0.367188L2.32366 7.48535C2.28128 7.55781 2.33323 7.64941 2.418 7.64941H4.80237L3.58011 12.5385C3.55413 12.6451 3.68265 12.7203 3.76194 12.6438L11.6684 5.09961C11.7395 5.03262 11.6916 4.9123 11.5932 4.9123Z"
        fill="#FFD231"
      />
    </svg>
  );
};

export { Thunder };
