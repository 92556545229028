import React from 'react';

const DesktopIcon = ({ width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 11 10"
      role="img"
      fill="none"
      className={className}
      aria-hidden="true"
    >
      <path
        d="M5.89286 7.69231V9.23077H8.25C8.35419 9.23077 8.45412 9.27129 8.52779 9.34342C8.60147 9.41555 8.64286 9.51338 8.64286 9.61538C8.64286 9.71739 8.60147 9.81522 8.52779 9.88735C8.45412 9.95948 8.35419 10 8.25 10H2.75C2.64581 10 2.54588 9.95948 2.47221 9.88735C2.39853 9.81522 2.35714 9.71739 2.35714 9.61538C2.35714 9.51338 2.39853 9.41555 2.47221 9.34342C2.54588 9.27129 2.64581 9.23077 2.75 9.23077H5.10714V7.69231H1.57143C1.15466 7.69231 0.754961 7.53022 0.460261 7.2417C0.165561 6.95319 0 6.56187 0 6.15385V1.53846C0 1.13044 0.165561 0.739122 0.460261 0.450605C0.754961 0.162087 1.15466 0 1.57143 0H9.42857C9.84534 0 10.245 0.162087 10.5397 0.450605C10.8344 0.739122 11 1.13044 11 1.53846V6.15385C11 6.56187 10.8344 6.95319 10.5397 7.2417C10.245 7.53022 9.84534 7.69231 9.42857 7.69231H5.89286ZM1.57143 0.769231C1.36304 0.769231 1.16319 0.850274 1.01584 0.994533C0.868495 1.13879 0.785714 1.33445 0.785714 1.53846V6.15385C0.785714 6.35786 0.868495 6.55352 1.01584 6.69777C1.16319 6.84203 1.36304 6.92308 1.57143 6.92308H9.42857C9.63696 6.92308 9.8368 6.84203 9.98415 6.69777C10.1315 6.55352 10.2143 6.35786 10.2143 6.15385V1.53846C10.2143 1.33445 10.1315 1.13879 9.98415 0.994533C9.8368 0.850274 9.63696 0.769231 9.42857 0.769231H1.57143Z"
        fill="#999999"
      />
    </svg>
  );
};

export default DesktopIcon;
