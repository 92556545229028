import React from 'react';

const Timeout = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-hidden="true"
      {...rest}
    >
      <circle
        cx="7.99998"
        cy="7.99999"
        r="6.66383"
        transform="rotate(-0.024375 7.99998 7.99999)"
        fill="#FFB302"
      />
      <path
        d="M7.99735 11.7733C9.92953 11.7733 11.4959 10.207 11.4959 8.27482C11.4959 6.34264 9.92953 4.77631 7.99735 4.77631C6.06518 4.77631 4.49884 6.34264 4.49884 8.27482C4.49884 10.207 6.06518 11.7733 7.99735 11.7733Z"
        stroke="white"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.35836 6.91428L7.99725 8.27539"
        stroke="white"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.1098 5.16551L5.27647 3.99884"
        stroke="white"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7181 3.99884L11.8848 5.16551"
        stroke="white"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99725 4.77602L7.99725 3.99884"
        stroke="white"
        strokeWidth="0.980392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Timeout;
