import React from 'react';

const Queued = ({ width, height, className, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...rest}
    >
      <circle
        cx="20"
        cy="20"
        r="18.75"
        fill="white"
        stroke="#9B9FFF"
        strokeWidth="1.5"
      />
      <path
        d="M11 27.3106C11 26.6881 11.5046 26.1835 12.1271 26.1835H27.9067C28.5292 26.1835 29.0338 26.6881 29.0338 27.3106C29.0338 27.9331 28.5292 28.4377 27.9067 28.4377H12.1271C11.5046 28.4377 11 27.9331 11 27.3106Z"
        fill="#9B9FFF"
      />
      <path
        d="M11 22.2441C11 21.6216 11.5046 21.1169 12.1271 21.1169H27.9067C28.5292 21.1169 29.0338 21.6216 29.0338 22.2441C29.0338 22.8665 28.5292 23.3712 27.9067 23.3712H12.1271C11.5046 23.3712 11 22.8665 11 22.2441Z"
        fill="#9B9FFF"
      />
      <path
        d="M11 12.1271C11 11.5046 11.5046 11 12.1271 11H27.9067C28.5292 11 29.0338 11.5046 29.0338 12.1271V17.7627C29.0338 18.3852 28.5292 18.8898 27.9067 18.8898H12.1271C11.5046 18.8898 11 18.3852 11 17.7627V12.1271Z"
        fill="#9B9FFF"
      />
    </svg>
  );
};

export default Queued;
