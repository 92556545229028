function hideSupportIcon() {
  const elem = document.getElementById('zsiq_float');
  if (elem) {
    elem.parentElement.style.display = 'none';
    elem.style.display = 'none';
  }
}

function showSupportIcon() {
  const elem = document.getElementById('zsiq_float');
  if (elem) {
    elem.parentElement.style.display = 'block';
    elem.style.display = 'block';
  }
}

function hideSupportIconRecursively(interval = 100) {
  const intervalId = setInterval(() => {
    const elem = document.getElementById('zsiq_float');
    if (elem) {
      elem.parentElement.style.display = 'none';
      elem.style.display = 'none';
      clearInterval(intervalId);
    }
  }, interval);
}

export { hideSupportIcon, showSupportIcon, hideSupportIconRecursively };
